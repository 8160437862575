import { TableItem } from '../../styledComponents/ProjectsNew';
import {
  ClientLocationsTableHeaderContainer,
  ClientLocationsTableRowContainer,
} from '../../styledComponents/clients';
import {
  CommonGrid,
  CommonText,
  TableText,
} from '../../styledComponents/common';
import {
  SmallCardLabel,
  StatusDot,
  StatusWrapper,
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
} from '../../styledComponents/dashboard';
import ComponentCircleLoader from '../Loaders/ComponentCircleLoader';
import {
  DataDiv,
  DataDivHeaderWrapper,
  SmallCardContainer,
} from './cardStyles';

const RecentInvoicesCard = ({ recentInvoicesSummary, isLoading, history }) => {
  return (
    <SmallCardContainer laptopGridColumn="span 2" tabletGridColumn="span 1">
      {isLoading ? (
        <ComponentCircleLoader padding="7vh" />
      ) : (
        <>
          {recentInvoicesSummary ? (
            <>
              <DataDiv>
                <DataDivHeaderWrapper style={{ marginBottom: '10px' }}>
                  <SmallCardLabel fontSize="16px" style={{ fontWeight: '600' }}>
                    Recent Invoices
                  </SmallCardLabel>
                  <CommonText
                    fontSize="13px"
                    color="#2266EF"
                    fontWeight="550"
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      // paddingRight: '20px',
                    }}
                    onClick={() => history.push(`/user/invoices/`)}
                  >
                    View All
                  </CommonText>
                </DataDivHeaderWrapper>

                <ClientLocationsTableHeaderContainer columns={4}>
                  <TableItem>
                    <TableText>Number</TableText>
                  </TableItem>
                  <TableItem>
                    <TableText>Customer</TableText>
                  </TableItem>
                  <TableItem>
                    <TableText>Paid/Total Amount</TableText>
                  </TableItem>
                  <TableItem>
                    <TableText>Status</TableText>
                  </TableItem>
                </ClientLocationsTableHeaderContainer>

                {Array.from({ length: 4 }).map((_, index) => {
                  const item = recentInvoicesSummary[index];
                  return (
                    <ClientLocationsTableRowContainer columns={4} key={index}>
                      <TableItem>
                        <CommonText>
                          {item ? (
                            item.invoice_number
                          ) : (
                            <div style={{ paddingLeft: '30px' }}>-</div>
                          )}
                        </CommonText>
                      </TableItem>
                      <TableItem>
                        <CommonText>
                          {item ? (
                            item.client_name
                          ) : (
                            <div style={{ paddingLeft: '32px' }}>-</div>
                          )}
                        </CommonText>
                      </TableItem>
                      <TableItem style={{ paddingLeft: '20px' }}>
                        <CommonText>
                          {item ? (
                            `$${item.paid_amount} / $${item.total_amount}`
                          ) : (
                            <div style={{ paddingLeft: '44px' }}>-</div>
                          )}
                        </CommonText>
                      </TableItem>

                      <TableItem>
                        <CommonText>
                          {item ? (
                            <StatusWrapper>
                              <StatusDot status={item.status} />
                              <div >
                                {item.status}
                              </div>
                            </StatusWrapper>
                          ) : (
                            <div style={{ paddingLeft: '25px' }}>-</div>
                          )}
                        </CommonText>
                      </TableItem>
                    </ClientLocationsTableRowContainer>
                  );
                })}
              </DataDiv>
            </>
          ) : null}
        </>
      )}
    </SmallCardContainer>
  );
};

export default RecentInvoicesCard;
