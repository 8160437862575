import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";

import avatar5 from "../../assets/img/avatar_5.svg";
import crossIcon from "../../assets/img/icons/cross_black.svg";
import deleteIcon from "../../assets/img/icons/delete_white.svg";
import editIcon from "../../assets/img/icons/edit-white-shift.svg";
import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";
import RoundImageNameComp from "../../components/RoundImageName/RoundImageNameComp";
import {
  ColoredButton,
  PrimaryButton,
  SecondaryButton,
  WhiteButton,
} from "../../styledComponents/buttons";
import {
  CardTitle,
  CommonGrid,
  CommonText,
  InputLabel,
  StyledLink,
} from "../../styledComponents/common";
import { getRandomColor } from "../../utils/getRandomColor";
import { formatDurationToHoursMinutes, getFullName } from "../../utils/helper";

const SlotContainer = styled.div`
  display: grid;
  gap: 20px;
  margin-top: 20px;
`;

const ColoredCircle = styled.div`
  position: absolute;
  top: -12px;
  right: ${(props) => (props.edit ? `52px` : `20px`)};
  display: none;
  z-index: 5;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: ${(props) => (props.edit ? `#7F9DBF` : `#FE5969`)};
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const TimeDiv = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadii.button};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.font.inputLabel.size};
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  background: ${({ bgColor }) => bgColor};
  padding: 15px;
  &:hover ${ColoredCircle} {
    display: grid;
  }
`;
const TextSection = styled.div`
  display: grid;
  gap: 5px;
`;
const ClosePopup = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
  cursor: pointer;
`;
const ButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: space-between;
  margin-top: 30px;
`;

const ShiftDetailsPopup = ({
  selectedOrganization,
  isOpen,
  toggle,
  shift,
  deleteToggle,
  editToggle,
  history,
}) => {
  const handleShiftDelete = () => {
    toggle();
    setTimeout(deleteToggle, 300);
  };

  const handleShiftEdit = () => {
    toggle();
    setTimeout(editToggle, 300);
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ marginTop: `15vh`, maxWidth: `355px` }}
    >
      <ModalBody style={{ padding: `20px` }}>
        <CardTitle margin="0 0 20px 10px">Shift Details</CardTitle>
        <ClosePopup onClick={() => toggle()}>
          <img src={crossIcon} alt="" width="30px" />
        </ClosePopup>
        {shift && Object.keys(shift).length > 0 && (
          <SlotContainer>
            <TimeDiv
              bgColor={
                shift && shift.theme_color ? shift.theme_color : `#D2E4FE`
              }
            >
              <TextSection>
                <CardTitle fontSize="14px" fontWeight="600">
                  {shift.name}
                </CardTitle>
                <CommonText name fontSize="13px">
                  <span style={{ fontWeight: `600` }}>Type: </span>
                  {shift.work_order && shift.work_order.id
                    ? "Job Schedule"
                    : "Office Schedule"}
                </CommonText>
                <CommonText name fontSize="13px" margin="15px 0 auto 0">
                  <span style={{ fontWeight: `600` }}>Slot: </span>{" "}
                  {shift.start_time &&
                    moment(shift.start_time).format("h:mm a")}{" "}
                  - {shift.end_time && moment(shift.end_time).format("h:mm a")}{" "}
                  <span style={{ fontWeight: `600`, marginLeft: `20px` }}>
                    duration:{" "}
                  </span>
                  <span>
                    {shift.start_time &&
                      shift.end_time &&
                      formatDurationToHoursMinutes(
                        moment
                          .duration(
                            moment(shift.end_time).diff(
                              moment(shift.start_time)
                            )
                          )
                          .asSeconds()
                      )}
                  </span>
                </CommonText>
                <CommonText name fontSize="13px">
                  {" "}
                  {shift.start_time &&
                    moment(shift.start_time).format("dddd, D MMM YYYY")}
                </CommonText>
                {shift.work_order && shift.work_order.id && (
                  <>
                    <CommonText name fontSize="13px" margin="15px 0 auto 0">
                      <span style={{ fontWeight: `600` }}>Work Order: </span>
                      {shift.work_order.name}
                    </CommonText>
                    <CommonText name fontSize="13px">
                      <span style={{ fontWeight: `600` }}>Customer: </span>
                      {shift.work_order.client_name &&
                        shift.work_order.client_name}
                    </CommonText>
                    {shift.location &&
                      shift.location.id &&
                      shift.location.name && (
                        <>
                          <CommonText
                            name
                            fontSize="13px"
                            margin="15px 0 auto 0"
                          >
                            <span style={{ fontWeight: `600` }}>
                              Location:{" "}
                            </span>
                            {shift.location.name}
                          </CommonText>
                          <CommonText name fontSize="13px">
                            <span style={{ fontWeight: `600` }}>Address: </span>
                            {shift.location.address}
                          </CommonText>
                        </>
                      )}
                  </>
                )}
              </TextSection>
            </TimeDiv>
            <CommonText name fontSize="13px">
              Assigned Member
            </CommonText>
            {shift.assignees &&
              shift.assignees.length > 0 &&
              shift.assignees.map((assignee, i) => (
                <div key={i}>
                  <RoundImageNameComp
                    imgSource={assignee.avatar ? assignee.avatar : avatar5}
                    name={assignee ? getFullName(assignee) : "User"}
                    imageSize="30px"
                    fontSize="13px"
                    fill
                  />
                </div>
              ))}
            <ButtonContainer>
              {shift.work_order && shift.work_order.id && (
                <PrimaryButton
                  padding="0 20px"
                  onClick={() =>
                    history.push(
                      `/user/job-details?id=${shift.id}&workOrder_id=${shift.work_order.id}`
                    )
                  }
                >
                  Job Details
                </PrimaryButton>
              )}
              {selectedOrganization &&
              selectedOrganization.role &&
              (selectedOrganization.role === "admin" ||
                selectedOrganization.role === "owner") &&
              (!shift.work_order ||
                (shift.status &&
                  (shift.status === "unscheduled" ||
                    shift.status === "ready to dispatch" ||
                    shift.status === "totally missed"))) ? (
                <ColoredButton
                  type="delete"
                  padding="0 20px"
                  onClick={() => handleShiftDelete()}
                >
                  Delete
                </ColoredButton>
              ) : null}
              {/* <WhiteButton color="#434343" padding="0 20px" onClick={() => handleShiftEdit()}>
                Edit
              </WhiteButton> */}
            </ButtonContainer>
          </SlotContainer>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ShiftDetailsPopup;
