import { connect } from "react-redux";

// Component
import JobDetails from "./JobDetails";
import {
  getOrganizationMembersList,
} from "../../Projects/projectsActions";

import {
  deleteJob,
  getJobDetails,
} from '../jobsActions';

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  // job Details
  getJobIsLoading: state.jobs.getJobIsLoading,
  jobDetails: state.jobs.jobDetails,

  //Deleting Job
  jobDeleteLoading: state.jobs.jobDeleteLoading,

});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getJobDetails: details => dispatch(getJobDetails({ ...details })),
  deleteJob: details => dispatch(deleteJob({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(JobDetails);
