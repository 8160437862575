import { useState, useEffect } from "react";

import styled from "styled-components";

import { validateEmail } from "../../utils/helper";

import {
  AuthPageContainer,
  AuthPageRightContainer,
  AuthCard,
  AuthCardTitle,
  AuthCardSubtitle,
  AuthForm,
  AuthFormAlert,
  AuthInputContainer,
  ForgotPasswordDiv,
  ForgotPasswordText,
  AuthSubmitButton,
} from "../../styledComponents/authLayout";

import TextInput from "../../components/FloatingLabelInput/FloatingLabelInput";

import AuthPageLeftSection from "../../components/AuthLayout/AuthPageLeftSection";
import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";

import SuccessIcon from "../../assets/img/icons/tick_signup.svg";

import { useTranslation } from "react-i18next";

const Card = styled(AuthCard)`
  min-height: 45vh;
  padding: ${(props) => (props.successMessage ? `25px 10%` : `25px 7%`)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  animation: shrink 1s 1;
  @keyframes shrink {
    0% {
      min-height: 80vh;
    }
    100% {
      min-height: 45vh;
    }
  }
`;

const Form = styled(AuthForm)`
  margin-top: 20px;
`;

const SuccessImage = styled.img``;
const SuccessMessage = styled.p`
  margin: 25px 0 0 0;
  font-size: 20px;
`;
const SuccessEmail = styled(SuccessMessage)`
  font-weight: 600;
  margin: 0;
`;
const SuccessSubtitle = styled.p`
  margin-top: 25px;
  color: rgba(47, 57, 78, 0.7);
  font-size: 16px;
`;

const ForgotPassword = ({
  forgot,
  forgotPassword,
  history,
  clearForgotPasswordLinkSent,
}) => {
  const [email, setEmail] = useState("");
  const [linkSent, setLinkSent] = useState(false);
  const [saveError, setSaveError] = useState({});
  const methods = {
    setEmail,
  };

  useEffect(() => {
    let token = localStorage.getItem("access_token");
    if (token != null) {
      history.push("/user/jobs");
    }
    return () => {
      clearForgotPasswordLinkSent();
    };
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    let isTrue = false;
    if (forgot.linkSent) {
      isTrue = true;
    }
    setLinkSent(isTrue);
  }, [forgot.linkSent]);

  useEffect(() => {
    let saveError = {};
    if (
      forgot.errorMessage &&
      forgot.errorMessage === "No user with this email"
    ) {
      saveError["email"] = "There is no account with this email!";
    }
    setSaveError(saveError);
  }, [forgot.errorMessage]);

  const onTextChange = (e) => {
    const { name, value } = e.target;
    setSaveError({});
    methods[name](value);
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    if (checkError()) {
      let payload = {
        email: email,
      };
      forgotPassword(payload);
    }
  };

  const checkError = () => {
    let saveError = {};
    let formIsValid = true;
    if (email && !validateEmail(email)) {
      formIsValid = false;
      saveError["email"] = "Please enter a valid email address.";
    }
    setSaveError(saveError);
    return formIsValid;
  };
  const { t } = useTranslation();
  return (
    <AuthPageContainer>
      <AuthPageLeftSection />
      <AuthPageRightContainer>
        <Card successMessage={linkSent}>
          {linkSent ? (
            <>
              <SuccessImage src={SuccessIcon} alt="" />
              <SuccessMessage>
                {t("we_have_sent_a_verification_link_to_your_email_address")}{" "}
                <SuccessEmail>{email}</SuccessEmail>
              </SuccessMessage>
              <SuccessSubtitle>
                {t("just_click_on_the_link_in_your_email_and_you_are_done")}
              </SuccessSubtitle>
            </>
          ) : (
            <>
              <AuthCardTitle>{t("forgot_your_password?")}</AuthCardTitle>
              <AuthCardSubtitle>
                {t("we_need_your_email_to_reset_your_password")}
              </AuthCardSubtitle>
              <Form onSubmit={(e) => handleForgotPassword(e)}>
                <AuthInputContainer>
                  <TextInput
                    id="email"
                    name="setEmail"
                    label={t("email")}
                    type="text"
                    onChange={(e) => onTextChange(e)}
                    value={email}
                    autoFocus
                  />
                </AuthInputContainer>
                {saveError && saveError !== {} && saveError.email && (
                  <AuthFormAlert style={{ marginTop: "10px" }}>
                    {saveError.email}
                  </AuthFormAlert>
                )}
                <AuthSubmitButton
                  type="submit"
                  disabled={!email}
                  isDisabled={!email}
                >
                  {forgot.isLoading ? (
                    <ButtonTextLoader loadingText="Sending Email" />
                  ) : (
                    t("get_recovery_link")
                  )}
                </AuthSubmitButton>
                <ForgotPasswordDiv
                  style={{ justifyContent: "center" }}
                  marginTop="15px"
                >
                  <ForgotPasswordText
                    to={{
                      pathname: "/auth/login",
                      state: {
                        from: "forgotPassword",
                      },
                    }}
                  >
                    {t("back_to_sign_in")}
                  </ForgotPasswordText>
                </ForgotPasswordDiv>
              </Form>
            </>
          )}
        </Card>
      </AuthPageRightContainer>
    </AuthPageContainer>
  );
};

export default ForgotPassword;
