import styled from 'styled-components';

export const ClockInOutTableHeader = styled.div`
  display: grid;
  grid-template-columns: 220px 120px 120px 120px 120px 200px 120px;
  grid-column-gap: 20px;
  justify-content: start;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};
  @media (max-width: 1200px) {
    grid-template-columns: 160px 100px 100px 100px 100px 150px 100px;
    grid-column-gap: 10px;
  }
`;

export const ClockInOutTableRow = styled.div`
  display: grid;
  grid-template-columns: 220px 120px 120px 120px 120px 200px 120px;
  grid-column-gap: 20px;
  justify-content: start;
  padding: 0 20px;
  :nth-of-type(even) {
    background-color: #f8f9fc;
  }
  @media (max-width: 1200px) {
    grid-template-columns: 160px 100px 100px 100px 100px 150px 100px;
    grid-column-gap: 10px;
  }
`;
export const ClockInOutTableHeaderNew = styled.div`
  display: grid;
  grid-template-columns: 200px 300px;
  grid-column-gap: 20px;
  justify-content: start;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};
  /* @media (max-width: 1250px) {
    grid-template-columns: 250px 200px 200px 150px;
    grid-column-gap: 10px;
  } */
`;
