import styled from "styled-components";

export const PricingPlanContainer = styled.div``;
export const PricingPlanHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  padding-right: 4%;
  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 20px;
  }
`;

export const PricingCardContainer = styled.div`
  display: grid;
  grid-template-columns: min(24%, 330px) min(24%, 330px) min(24%, 330px) min(
      24%,
      330px
    );
  grid-column-gap: 1%;
  justify-content: center;
  padding-right: 4%;
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;
export const SinglePricingCard = styled.div`
  display: flex;
  flex-direction: column;
  height: 820px;
  width: 100%;
  background-color: ${({ plan, theme }) =>
    plan === "elite"
      ? theme.colors.main.blueLight
      : plan === "premium"
      ? "#f2f2fb"
      : plan === "standard"
      ? theme.colors.main.playGrayLight
      : theme.colors.main.primaryLight};
  border-radius: 20px;
  padding: 30px 0px;
  @media (max-width: 1450px) {
    height: 780px;
  }
`;

export const TopSectionContainer = styled.div`
  border-bottom: 1px solid rgba(93, 104, 126, 0.1);
`;

export const PlanNameAndTagContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const PlanName = styled.div`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  padding: 0px 10px 0px 30px;
  color: ${({ plan, theme }) =>
    plan === "elite"
      ? theme.colors.main.blue
      : plan === "premium"
      ? theme.colors.main.purpleMedium
      : plan === "standard"
      ? theme.colors.main.planGray
      : theme.colors.main.primary};
  @media screen and (max-width: 1450px) {
    padding: 0 10px 0 25px;
  }
`;
export const PlanNameTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  padding: 0px 12px;
  border-radius: 13px;
  font-size: 11px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  background: ${({ plan }) =>
    plan === "elite"
      ? "#b1d0fd"
      : plan === "premium"
      ? "#dddcfb"
      : plan === "standard"
      ? "#d5e4f0"
      : "#eaf9f7"};
  color: ${({ plan, theme }) =>
    plan === "elite"
      ? theme.colors.main.blue
      : plan === "premium"
      ? theme.colors.main.purpleMedium
      : plan === "standard"
      ? theme.colors.main.planGray
      : theme.colors.main.primary};

  @media (max-width: 1450px) {
    height: 20px;
    border-radius: 10px;
    padding: 0px 8px;
  }
`;

export const PlanPriceContainer = styled.div`
  display: flex;
  align-items: baseline;
  padding: 0px 30px;
  margin-top: 30px;
  @media screen and (max-width: 1450px) {
    padding: 0 25px;
    margin-top: 10px;
  }
`;
export const PlanPrice = styled.span`
  font-size: 36px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.main.textMain};
  @media (max-width: 1450px) {
    font-size: 34px;
  }
`;
export const PlanPriceText = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.main.textSecondary};
  margin-left: 20px;
  @media (max-width: 1450px) {
    font-size: 13px;
    margin-left: 10px;
  }
`;
export const PlanDescription = styled.div`
  border-left: ${({ plan, theme }) =>
    `4px solid ${
      plan === "elite"
        ? theme.colors.main.blue
        : plan === "premium"
        ? theme.colors.main.purpleMedium
        : plan === "standard"
        ? theme.colors.main.planGray
        : theme.colors.main.primary
    }`};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.main.textMain};
  padding: 0px 30px;
  margin-top: 20px;
  @media (max-width: 1450px) {
    padding: 0 25px;
    font-size: 14px;
  }
`;
export const PlanFeaturesContainer = styled.div`
  margin: ${({ margin }) => margin || "0 0 15px 0"};
  padding: 0 30px;
  @media (max-width: 1450px) {
    padding: 0 25px;
  }
`;
export const SinglePlanFeature = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;
`;
export const FeatureTickIcon = styled.img`
  @media (max-width: 1450px) {
    width: 17px;
  }
`;
export const FeatureText = styled.span`
  color: ${({ theme }) => theme.colors.main.labelText};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  margin-left: 12px;
  @media (max-width: 1450px) {
    font-size: ${({ fontSize }) => fontSize || "13px"};
  }
`;

export const PlanSelectButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 60px);
  height: 46px;
  margin: 20px 30px;
  background-color: ${({ plan, theme }) =>
    plan === "elite"
      ? theme.colors.main.blue
      : plan === "premium"
      ? theme.colors.main.purpleMedium
      : plan === "standard"
      ? theme.colors.main.planGray
      : theme.colors.main.primary};
  color: ${({ plan, theme }) => theme.colors.main.white};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  align-self: center;
  border-radius: ${({ theme }) => theme.button.borderRadius};
  cursor: pointer;
  &:hover {
    background-color: ${({ plan, theme }) =>
      plan === "elite"
        ? theme.colors.hover.blue
        : plan === "premium"
        ? theme.colors.hover.purpleMedium
        : plan === "standard"
        ? theme.colors.hover.planGray
        : theme.colors.hover.primary};
  }

  @media (max-width: 1450px) {
    width: calc(100% - 50px);
    margin: 20px 25px;
  }
`;

export const EverythingInPrev = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 0px 10px;
  border-radius: 12px;
  background: ${({ plan }) =>
    plan === "elite"
      ? "rgba(77, 148, 255, 0.25)"
      : plan === "premium"
      ? "rgba(154, 150, 249, 0.25)"
      : "rgba(102, 134, 162, 0.25)"};
  margin: 25px auto 0 30px;
  @media (max-width: 1450px) {
    margin: 20px auto 0 25px;
  }
`;
export const ArrowLeft = styled.img``;

export const PlusAddonDiv = styled.div`
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.main.textSecondary};
  margin-top: 20px;
  padding-left: 40px;
  @media (max-width: 1450px) {
    padding-left: 25px;
  }
`;

// toggle Switch
export const TypeTogglerContainer = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 500px) {
    margin-top: 30px;
  }
`;
export const TypeText = styled.span`
  position: relative;
  font-size: 18px;
  font-weight: ${({ active }) => (active ? 600 : 400)};
  color: ${({ active }) => (active ? `#20bead` : `#5D687E`)};
  cursor: pointer;
`;
export const ToggleSwitchContainer = styled.div`
  position: relative;
  width: 38px;
  height: 22px;
  display: inline-block;
  margin: 0px 10px;
`;
export const ToggleSwitchWrapper = styled.div`
  position: relative;
  width: 38px;
  height: 22px;
`;
export const Slider = styled.div`
  border-radius: 11px;
  background: ${({ theme }) => theme.colors.main.primary};
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.3s ease;
  &::before {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    position: absolute;
    background: ${({ theme }) => theme.colors.main.white};
    content: "";
    margin: 0;
    padding: 0;
    top: 50%;
    left: ${({ active }) => (active ? "calc(100% - 4px - 12px)" : "4px")};
    transform: translateY(-50%);
    transition: 0.3s;
    cursor: pointer;
    box-shadow: ${({ active }) =>
      active ? "none" : "0 4px 4px rgba( 25, 40, 61, 0.1)"};
  }
`;
