import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { TableItem } from '../../styledComponents/ProjectsNew';
import {
  ClientLocationsTableHeaderContainer,
  ClientLocationsTableRowContainer,
} from '../../styledComponents/clients';
import {
  CommonGrid,
  CommonText,
  TableText,
} from '../../styledComponents/common';
import {
  SmallCardLabel,
  StatusDot,
  StatusWrapper,
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
} from '../../styledComponents/dashboard';
import ComponentCircleLoader from '../Loaders/ComponentCircleLoader';
import {
  DataDiv,
  DataDivHeaderWrapper,
  SmallCardContainer,
} from './cardStyles';

const CurrentJobsCard = props => {
  const { currentJobsSummary, isLoading } = props;
  const { t, i18n } = useTranslation();
  const capitalizeFirstLetter = string =>
    string.charAt(0).toUpperCase() + string.slice(1);

  return (
    <SmallCardContainer laptopGridColumn="span 2" tabletGridColumn="span 1">
      {isLoading ? (
        <ComponentCircleLoader padding="7vh" />
      ) : (
        <>
          {currentJobsSummary ? (
            <>
              <DataDiv>
                <DataDivHeaderWrapper style={{ marginBottom: '10px' }}>
                  <SmallCardLabel fontSize="16px" style={{ fontWeight: '600' }}>
                    Current Jobs
                  </SmallCardLabel>
                  <CommonText
                    fontSize="13px"
                    color="#2266EF"
                    fontWeight="550"
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      // paddingRight: '20px',
                    }}
                    onClick={() => props.history.push(`/user/jobs/`)}
                  >
                    View All
                  </CommonText>
                </DataDivHeaderWrapper>

                <ClientLocationsTableHeaderContainer columns={4}>
                  <TableItem>
                    <TableText>Last Sync</TableText>
                  </TableItem>
                  <TableItem>
                    <TableText>Name</TableText>
                  </TableItem>
                  <TableItem>
                    <TableText>Job's Name</TableText>
                  </TableItem>
                  <TableItem>
                    <TableText>Status</TableText>
                  </TableItem>
                </ClientLocationsTableHeaderContainer>

                <>
                  {Array.from({ length: 4 }).map((_, index) => {
                    const item = currentJobsSummary[index];
                    return (
                      <ClientLocationsTableRowContainer columns={4}>
                        <TableItem>
                          <CommonText>
                            {item ? (
                              moment(item.date_time).format('hh:mm A')
                            ) : (
                              <div style={{ paddingLeft: '30px' }}>-</div>
                            )}
                          </CommonText>
                        </TableItem>
                        <TableItem>
                          <CommonText>
                            {item ? (
                              `${capitalizeFirstLetter(
                                item.user.first_name,
                              )} ${capitalizeFirstLetter(item.user.last_name)}`
                            ) : (
                              <div style={{ paddingLeft: '20px' }}>-</div>
                            )}
                          </CommonText>
                        </TableItem>
                        <TableItem>
                          <CommonText>
                            {item ? (
                              item.job.name
                            ) : (
                              <div style={{ paddingLeft: '35px' }}>-</div>
                            )}
                          </CommonText>
                        </TableItem>
                        <TableItem>
                          {item ? (
                            <StatusWrapper>
                              <StatusDot status={item.job.status} />
                              <CommonText>
                                {item.job.status === 'unscheduled'
                                  ? t('Unscheduled')
                                  : item.job.status === 'ready to dispatch'
                                  ? t('Ready to dispatch')
                                  : item.job.status === 'completed'
                                  ? t('completed')
                                  : item.job.status === 'partially complete'
                                  ? t('partially_completed')
                                  : t('totally_missed')}
                              </CommonText>
                            </StatusWrapper>
                          ) : (
                            <div style={{ paddingLeft: '22px' }}>-</div>
                          )}
                        </TableItem>
                      </ClientLocationsTableRowContainer>
                    );
                  })}
                </>
              </DataDiv>
            </>
          ) : null}
        </>
      )}
    </SmallCardContainer>
  );
};

export default CurrentJobsCard;
