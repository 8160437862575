import { connect } from "react-redux";

import OnboardingSteps from "./OnboardingSteps";

import { getOrganizationPlans } from "../../Organization/OrganizationActions";

import {
  checkOnboardingApplicable,
  saveOnboardingData,
} from "./onboardingStepsActions";

const mapStateToProps = (state) => ({
  isLoading: state.onboarding.isLoading,
  redirect: state.onboarding.redirect,
  showOnboarding: state.auth.show_onboard,
  subscriptionPlans: state.organization.allPlans,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getOrganizationPlans: () => dispatch(getOrganizationPlans()),
  checkOnboardingApplicable: () => dispatch(checkOnboardingApplicable()),
  saveOnboardingData: (data) => dispatch(saveOnboardingData(data)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(OnboardingSteps);
