import {
  CREATE_WORK_ORDER,
  WORK_ORDER_LIST,
  GET_WORK_ORDER,
  UPDATE_WORK_ORDER,
  CREATE_JOB,
  DELETE_WORK_ORDER_JOB,
  UPDATE_JOB,
  GET_JOB_DETAILS,
  GET_JOBS_UNDER_WORKORDER,
  DELETE_WORK_ORDER,
  DUPLICATE_WORK_ORDER,
  GET_WORKORDER_BUDGET_AND_BILLABLE,
  CREATE_WORK_ORDER_BILL,
  GET_WORK_ORDER_BILL,
  UPDATE_WORK_ORDER_BILL,
  CREATE_WORK_ORDER_BUDGET,
  GET_WORK_ORDER_BUDGET,
  UPDATE_WORK_ORDER_BUDGET,
  CLEAR_WORK_ORDER_BUDGET,
  CLEAR_WORK_ORDER_BILLING,
  WORK_ORDER_SHORT,
  CLEAR_WORK_ORDER_ID,
} from '../../modules/constants';

export function getWorkOrderShortList(value) {
  return {
    type: WORK_ORDER_SHORT,
    payload: value,
  };
}

export function clearWorkOrderBudget() {
  return {
    type: CLEAR_WORK_ORDER_BUDGET,
  };
}

export function clearWorkOrderBilling() {
  return {
    type: CLEAR_WORK_ORDER_BILLING,
  };
}

export function clearWorkOrderId() {
  return {
    type: CLEAR_WORK_ORDER_ID,
  };
}

export function getJobsUnderWorkOrderRequest(value) {
  return {
    type: GET_JOBS_UNDER_WORKORDER,
    payload: value,
  };
}

export function createWorkOrderBill(value) {
  return {
    type: CREATE_WORK_ORDER_BILL,
    payload: value,
  };
}

export function getWorkOrderBill(value) {
  return {
    type: GET_WORK_ORDER_BILL,
    payload: value,
  };
}

export function updateWorkOrderBill(value) {
  return {
    type: UPDATE_WORK_ORDER_BILL,
    payload: value,
  };
}

export function createWorkOrderBudget(value) {
  return {
    type: CREATE_WORK_ORDER_BUDGET,
    payload: value,
  };
}

export function getWorkOrderBudget(value) {
  return {
    type: GET_WORK_ORDER_BUDGET,
    payload: value,
  };
}

export function updateWorkOrderBudget(value) {
  return {
    type: UPDATE_WORK_ORDER_BUDGET,
    payload: value,
  };
}


export function getDuplicateWorkOrderequest(value) {
  return {
    type: DUPLICATE_WORK_ORDER,
    payload: value,
  };
}



export function updateJobRequest(value) {
  return {
    type: UPDATE_JOB,
    payload: value,
  };
}

export function createJobRequest(value) {
  return {
    type: CREATE_JOB,
    payload: value,
  };
}

export function deleteWorkOrderJob(value) {
  return {
    type: DELETE_WORK_ORDER_JOB,
    payload: value,
  };
}

export function getWorkOrderRequest(value) {
  return {
    type: WORK_ORDER_LIST,
    payload: value,
  };
}

export function deleteWorkOrderRequest(value) {
  return {
    type: DELETE_WORK_ORDER,
    payload: value,
  };
}

export function createWorkOrderRequest(value) {
  return {
    type: CREATE_WORK_ORDER,
    payload: value,
  };
}

export function getWorkOrder(value) {
    return {
      type: GET_WORK_ORDER,
      payload: value,
    };
  }

export function updateWorkOrder(value) {
return {
    type: UPDATE_WORK_ORDER,
    payload: value,
};
}

export function getWorkOrderBudgetAndBillable(value) {
  return {
    type: GET_WORKORDER_BUDGET_AND_BILLABLE,
    payload: value,
  };
}