import moment from 'moment';
import { useEffect, useState } from 'react';

import CurrentJobsCard from '../../components/DashboardCards/CurrentJobsCard';
import EmployeeActivityCard from '../../components/DashboardCards/EmployeeActivityCard';
import EmployeesStatusCard from '../../components/DashboardCards/EmployeesStatusCard';
import JobTodayCard from '../../components/DashboardCards/JobTodayCard';
import JobsApproval from '../../components/DashboardCards/JobsApprovalCard';
import JobsStatsCard from '../../components/DashboardCards/JobsStatsCard';
import LateJobsCard from '../../components/DashboardCards/LateJobsCard';
import LowPerformanceCard from '../../components/DashboardCards/LowPerformanceCard';
import RecentInvoicesCard from '../../components/DashboardCards/RecentInvoicesCard';
import TaskProgressOverview from '../../components/DashboardCards/TaskProgressOverview';
import TopPerformanceCard from '../../components/DashboardCards/TopPerformanceCard';
import TopProjectsCard from '../../components/DashboardCards/TopProjectsCard';
import TopUsersActivityCard from '../../components/DashboardCards/TopUsersActivityCard';
import TopUsersTimeCard from '../../components/DashboardCards/TopUsersTimeCard';
import TopWorkOrdersCard from '../../components/DashboardCards/TopWorkOrdersCard';
import TotalCompletedTask from '../../components/DashboardCards/TotalCompletedTask';
import TotalIncompleteTask from '../../components/DashboardCards/TotalIncompleteTask';
import TotalLateTask from '../../components/DashboardCards/TotalLateTask';
import TotalMissedjobs from '../../components/DashboardCards/TotalMissedjobs';
import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import { ColoredText, PageTitle } from '../../styledComponents/common';
import {
  BillingTooltip,
  BillingTooltipText,
} from '../../styledComponents/createProject';
import DashboardModeSelector from './DashboardModeSelector';
import {
  DashboardCardContainer,
  DashboardPageContent,
  DashboardPageHeader,
  DashboardPerformanceReportContainer,
  DateRangeContainer,
  DateRangeTooltip,
  SmallCardContainer,
  TitleAndModeSelectorDiv,
} from './dashboardStyles';

const getTime = time => {
  let timeInSeconds = parseInt(time);
  let minutes = Math.floor(timeInSeconds / 60) % 60;
  let hours = Math.floor(timeInSeconds / 3600);

  return `${hours}h ${minutes}m`;
};

const Dashboard = props => {
  const {
    selectedOrganization,
    getDashboardStatus,
    getDashboardTaskProgress,
    getDashboardPerformance,
    // dashboardData,
    // employeeStatusIsloading,
    employee_status,
    performanceComparisonIsLoading,
    performance_comparison,
    //new states
    getDashboardJobsToday,
    jobsTodayIsloading,
    jobsToday,

    getDashboardEmployeeStatus,
    employeeStatusIsloading,
    employeStatus,

    getDashboardTopWorkOder,
    topWorkOrderIsloading,
    topWorkOrder,

    getDashboardJobStatus,
    jobStatusIsloading,
    job_status,

    getDashboardPreviousWeekJobStatus,
    jobStatusPreviousWeekIsloading,
    jobStatusPreviousWeek,

    getDashboardCurrentJobs,
    currentJobsIsloading,
    currentJobs,

    getDashboardRecentInvoices,
    recentInvoicesIsloading,
    recentInvoices,
  } = props;

  const startOfWeek = moment()
    .subtract(1, 'day')
    .startOf('isoWeek')
    .format('YYYY-MM-DD');
  const endOfWeek = moment()
    .subtract(1, 'day')
    .endOf('isoWeek')
    .format('YYYY-MM-DD');

  const startOfPreviousWeek = moment()
    .subtract(1, 'week')
    .startOf('isoWeek')
    .format('YYYY-MM-DD');
  const endOfPreviousWeek = moment()
    .subtract(1, 'week')
    .endOf('isoWeek')
    .format('YYYY-MM-DD');


  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      const userRole = localStorage.getItem('user_role');
      //if (userRole === 'admin' || userRole === 'owner') {
      const payload = {
        date: moment(new Date()),
      };
      getDashboardJobsToday({ organization_id: selectedOrganization.id });
      getDashboardEmployeeStatus({ organization_id: selectedOrganization.id });
      getDashboardTopWorkOder({ organization_id: selectedOrganization.id });
      getDashboardJobStatus({
        organization_id: selectedOrganization.id,
        min_start_date: startOfWeek,
        max_start_date: endOfWeek,
      });
      getDashboardPreviousWeekJobStatus({
        organization_id: selectedOrganization.id,
        min_start_date: startOfPreviousWeek,
        max_start_date: endOfPreviousWeek,
      });
      getDashboardCurrentJobs({ organization_id: selectedOrganization.id });
      getDashboardRecentInvoices({organization_id: selectedOrganization.id});

      // }
    }
  }, [selectedOrganization]);

  return (
    <div className="content">
      <DashboardPageHeader>
        <TitleAndModeSelectorDiv>
          <PageTitle style={{ marginBottom: '0' }}>Dashboard</PageTitle>
        </TitleAndModeSelectorDiv>
      </DashboardPageHeader>
      <DashboardPageContent marginTop="20px">
        <DashboardCardContainer
          columns="1fr 1fr 1fr"
          laptopColumns="1fr 1fr 1fr"
          tabletColumns="1fr 1fr"
          marginTop="20px"
        >
          <JobTodayCard
            jobTodaySummary={jobsToday}
            isLoading={jobsTodayIsloading}
          />
          <EmployeesStatusCard
            employee_status={employeStatus}
            isLoading={employeeStatusIsloading}
          />
          <TopWorkOrdersCard
            workOrdersSummary={topWorkOrder}
            isLoading={topWorkOrderIsloading}
          />
        </DashboardCardContainer>
        <DashboardCardContainer
          columns="1fr 1fr 1fr 1fr 1fr"
          tabletColumns="1fr 1fr"
          mobileColumns="1fr"
          marginTop="20px"
        >
          {jobStatusIsloading || jobStatusPreviousWeekIsloading ? (
            <>
              <SmallCardContainer>
                <ComponentCircleLoader padding="5vh" />
              </SmallCardContainer>
              <SmallCardContainer>
                <ComponentCircleLoader padding="5vh" />
              </SmallCardContainer>
              <SmallCardContainer>
                <ComponentCircleLoader padding="5vh" />
              </SmallCardContainer>
              <SmallCardContainer>
                <ComponentCircleLoader padding="5vh" />
              </SmallCardContainer>
              <SmallCardContainer>
                <ComponentCircleLoader padding="5vh" />
              </SmallCardContainer>
            </>
          ) : (
            job_status &&
            jobStatusPreviousWeek && (
              <>
                <JobsStatsCard
                  cardTitle="Approved Jobs"
                  datasets={{
                    current_week: job_status.approved,
                    previous_week: jobStatusPreviousWeek.approved,
                  }}
                />

                <JobsStatsCard
                  cardTitle="Pending Jobs"
                  datasets={{
                    current_week: job_status.pending,
                    previous_week: jobStatusPreviousWeek.pending,
                  }}
                />

                <JobsStatsCard
                  cardTitle="Rejected Jobs"
                  datasets={{
                    current_week: job_status.rejected,
                    previous_week: jobStatusPreviousWeek.rejected,
                  }}
                />

                <JobsStatsCard
                  cardTitle="Late Jobs"
                  datasets={{
                    current_week: job_status.late,
                    previous_week: jobStatusPreviousWeek.late,
                  }}
                />

                <JobsStatsCard
                  cardTitle="Missed Jobs"
                  datasets={{
                    current_week: job_status.missed,
                    previous_week: jobStatusPreviousWeek.missed,
                  }}
                />
              </>
            )
          )}
        </DashboardCardContainer>
        <DashboardCardContainer
          columns="1fr 1fr"
          laptopColumns="1fr 1fr"
          tabletColumns="1fr"
          marginTop="20px"
        >
           <CurrentJobsCard
            currentJobsSummary={currentJobs}
            isLoading={currentJobsIsloading}
            history={props.history}
          />
          
          <RecentInvoicesCard
            recentInvoicesSummary={recentInvoices}
            isLoading={recentInvoicesIsloading}
            history={props.history}
          />
        </DashboardCardContainer>
      </DashboardPageContent>
    </div>
  );
};

export default Dashboard;
