import { all, put, takeLatest } from 'redux-saga/effects';
import { defaultApi } from 'utils/axiosApi';

import {
  SEND_GHL_AUTHENTICATION_CODE,
  SEND_GHL_AUTHENTICATION_CODE_FAILURE,
  SEND_GHL_AUTHENTICATION_CODE_SUCCESS,
} from '../../modules/constants';

export function* sendGHLAuthenticationCode({ payload }) {
  try {
    const url = 'authentication/ghl-install/';
    const method = 'POST';
    const response = yield defaultApi(url, method, payload);

    yield put({
      type: SEND_GHL_AUTHENTICATION_CODE_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: SEND_GHL_AUTHENTICATION_CODE_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(SEND_GHL_AUTHENTICATION_CODE, sendGHLAuthenticationCode),
  ]);
}
