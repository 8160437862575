import { Fragment, useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

import CreateProjectDropdown from '../../../components/DropdownStyle/CreateProjectDropdown';
import ButtonTextLoader from '../../../components/Loaders/ButtonTextLoader';
import Select from '../../../components/ReactSelectDropdown';
import InfoTooltip from '../../../components/Tooltip/InfoTooltip';
import { ColoredButton, WhiteButton } from '../../../styledComponents/buttons';
import { CommonText, PageTitle } from '../../../styledComponents/common';
import {
  AdditionalContainer,
  AssigneesManageImgTextGrid,
  ButtonSection,
  CollapseContainer,
  CollapseRotate,
  DropDownGrid,
  HourlyRateDropdownSection,
  IconTitleSection,
  InputField,
  PersonHourlyRateContainer,
  ProjectBillingSection,
  ProjectBillingTabContainer,
  ProjectBillingtab,
  ProjectBudgetUpperColumn,
  ProjectBudgetUpperGrid,
  ProjectHourlyRateInputSection,
  SetRateButton,
  SetRateContainer,
  SliderContainer,
  StyledSlider,
  StyledThumb,
  StyledTrack,
  TitleContainer,
} from '../../../styledComponents/createProject';
import { ModalButtonSection } from '../../../styledComponents/members';
import { currencySymbolMap } from '../../../utils/currencies';

const BillingModal = ({
  isOpen,
  toggle,
  currency,
  isLoading,
  organization_id,
  workorder_id,
  //billing and budgeting
  createWorkOrderBill,
  getWorkOrderBill,
  updateWorkOrderBill,

  //billing and budgeting states
  saveBillingLoading,
  WorkOrderBilling,
  getBillingLoading,
  clearWorkOrderBilling,
}) => {
  const [workOrderBillingType, setWorkOrderBillingType] =
    useState('hourly_basis');
  const [hourlyRatesType, setHourlyRatesType] = useState('');
  const [personHourlyRates, setPersonHourlyRates] = useState([]);
  const [saveErrors, setSaveErrors] = useState({}); //STATE FOR SAVING THE ERRORS
  const [workOrderHourlyRate, setWorkOrderHourlyRate] = useState(''); //HOURLY BASIS > HOURLY RATES > PROJECT HOURLY RATE INPUT

  const hourlyRatesOption = [
    { value: 'work_order_hourly', label: 'Work Order Hourly Rate' },
    { value: 'person_hourly', label: 'Person Hourly Rate' },
  ]; //HOURLY BASIS > HOURLY RATES > DROPDOWN OPTION
  const [fixedRateValue, setFixedRateValue] = useState(''); //HOURLY BASIS > FIXED RATE INPUT
  const changeBillingType = value => {
    setWorkOrderBillingType(value);
  };

  useEffect(() => {
    if (organization_id && workorder_id) {
      getWorkOrderBill({
        organization_id: organization_id,
        workorder_id: workorder_id,
      });
    }
  }, [workorder_id]);

  useEffect(() => {
    return () => {
      clearWorkOrderBilling();
    };
  }, []);

  const clearBillingState = () => {
    setWorkOrderBillingType('hourly_basis');
    setHourlyRatesType('');
    setWorkOrderHourlyRate('');
    setFixedRateValue('');
  };

  useEffect(() => {
    if (WorkOrderBilling && WorkOrderBilling !== null) {
      if (WorkOrderBilling.billable_type) {
        setWorkOrderBillingType(WorkOrderBilling.billable_type);
        if (WorkOrderBilling.billable_type === 'hourly_basis') {
          setFixedRateValue('');
          if (WorkOrderBilling.hourly_rate_based_on) {
            if (WorkOrderBilling.hourly_rate_based_on === 'work_order_hourly') {
              setHourlyRatesType({
                value: 'work_order_hourly',
                label: 'Work Order Hourly Rate',
              });
              if (WorkOrderBilling.work_order_hourly) {
                setWorkOrderHourlyRate(
                  (WorkOrderBilling.work_order_hourly.rate / 100).toFixed(2),
                );
              }
            } else if (
              WorkOrderBilling.hourly_rate_based_on === 'person_hourly'
            ) {
              setHourlyRatesType({
                value: 'person_hourly',
                label: 'Person Hourly Rate',
              });
            }
          }
        } else if (WorkOrderBilling.billable_type === 'fixed_price') {
          setHourlyRatesType('');
          setWorkOrderHourlyRate('');
          if (WorkOrderBilling.billable_amount) {
            setFixedRateValue(
              (WorkOrderBilling.billable_amount / 100).toFixed(2),
            );
          }
        }
      }
    } else if (!WorkOrderBilling) {
      clearBillingState();
    }
  }, [WorkOrderBilling]);

  //SELECT HOURLY RATES TYPE
  const handleHourlyRatesType = e => {
    setHourlyRatesType(e);
    if (e.value === 'person_hourly') {
      setPersonHourlyRates(e);
    }
  };

  const handleWorkOrderHourlyRate = e => {
    const { value } = e.target;
    setSaveErrors({});
    setWorkOrderHourlyRate(value);
  };

  //HOURLY BASIS > FIXED RATE INPUT
  const fixedRateFunc = e => {
    const { value } = e.target;
    setSaveErrors({});
    setFixedRateValue(value);
  };

  const checkBillingError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (workOrderBillingType === 'hourly_basis' && hourlyRatesType === '') {
      saveErrors['hourlyRate'] = 'Please select the hourly rate type first.';
      formIsValid = false;
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const handleAddBilling = () => {
    if (checkBillingError()) {
      const payload = {
        organization_id: organization_id,
        workorder_id: workorder_id,
        billable_type: workOrderBillingType,
        hourly_rate_based_on:
          workOrderBillingType === 'hourly_basis'
            ? hourlyRatesType.value
            : null,
      };

      if (workOrderBillingType === 'hourly_basis') {
        if (hourlyRatesType.value === 'work_order_hourly') {
          payload.work_order_hourly = {
            rate: parseInt(workOrderHourlyRate * 100),
            currency: currency || 'USD',
          };
        }
      }
      if (workOrderBillingType === 'fixed_price') {
        payload.billable_amount = parseInt(fixedRateValue * 100);
      }
      if (WorkOrderBilling && WorkOrderBilling !== null) {
        updateWorkOrderBill(payload);
      } else if (!WorkOrderBilling) {
        createWorkOrderBill(payload);
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ marginTop: `25vh`, maxWidth: '700px' }}
    >
      <ModalBody style={{ padding: `20px` }}>
        <PageTitle
          fontSize="16px"
          style={{ paddingLeft: `10px`, paddingBottom: `15px` }}
        >
          {WorkOrderBilling && WorkOrderBilling.id
            ? 'Update Work Order Billing'
            : 'Add Work Order Billing'}
        </PageTitle>
        <>
          <ProjectBillingTabContainer style={{ paddingLeft: `10px` }}>
            <ProjectBillingtab
              onClick={() => changeBillingType('hourly_basis')}
              index={'hourly_basis'}
              workOrderBillingType={workOrderBillingType}
            >
              Hourly Basis
            </ProjectBillingtab>
            <ProjectBillingtab
              onClick={() => changeBillingType('fixed_price')}
              index={'fixed_price'}
              workOrderBillingType={workOrderBillingType}
            >
              Fixed Fee
            </ProjectBillingtab>
          </ProjectBillingTabContainer>

          <InfoTooltip
            padding="0 0 20px 10px"
            label
            mainText={
              workOrderBillingType === 'hourly_basis'
                ? `Hourly Rates`
                : `Work Order Fee`
            }
            toolTipText={
              workOrderBillingType === 'hourly_basis'
                ? `We need hourly rates to track your Work Orderʼs billable amount.`
                : `Enter the amount you plan to invoice.`
            }
            // padding="10px 25px"
            marginTop="10px"
          />

          {workOrderBillingType === 'hourly_basis' ? (
            <>
              <HourlyRateDropdownSection
                style={{ paddingLeft: `10px`, paddingBottom: `15px` }}
              >
                <Select
                  isSearchable
                  value={hourlyRatesType}
                  options={hourlyRatesOption}
                  placeholder="Select hourly rate type"
                  onChange={e => {
                    handleHourlyRatesType(e);
                  }}
                  styles={CreateProjectDropdown(null)}
                />
                {hourlyRatesType &&
                  hourlyRatesType.value === 'work_order_hourly' && (
                    //HOURLY BASIS > PROJECT HOURLY RATE ---
                    <ProjectHourlyRateInputSection>
                      <CommonText>
                        {currency} ({currencySymbolMap[currency]})
                      </CommonText>
                      <InputField
                        type="text"
                        placeholder="00.00"
                        value={workOrderHourlyRate}
                        onChange={e => handleWorkOrderHourlyRate(e)}
                      />
                    </ProjectHourlyRateInputSection>
                    // --- HOURLY BASIS > PROJECT HOURLY RATE
                  )}
              </HourlyRateDropdownSection>
            </>
          ) : (
            <>
              <HourlyRateDropdownSection
                style={{ paddingLeft: `10px`, paddingBottom: `15px` }}
              >
                <CommonText>Set Work Order fee</CommonText>
                <ProjectHourlyRateInputSection>
                  <CommonText>
                    {currency} ({currencySymbolMap[currency]})
                  </CommonText>
                  <InputField
                    type="text"
                    placeholder="00.00"
                    value={fixedRateValue}
                    onChange={e => fixedRateFunc(e)}
                  />
                </ProjectHourlyRateInputSection>
              </HourlyRateDropdownSection>
            </>
          )}
        </>

        <ModalButtonSection
          style={{ justifyContent: 'start', paddingLeft: `10px` }}
        >
          <WhiteButton type="cancel" onClick={toggle}>
            Cancel
          </WhiteButton>
          <ColoredButton
            padding={saveBillingLoading && '0 24px'}
            onClick={() => handleAddBilling()}
            disabled={
              hourlyRatesType.value === 'work_order_hourly' &&
              !workOrderHourlyRate
            }
          >
            {saveBillingLoading ? (
              <ButtonTextLoader loadingText="Saving" fontSize="13px" />
            ) : (
              'Save Billing'
            )}
          </ColoredButton>
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default BillingModal;
