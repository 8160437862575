import styled, { css } from "styled-components";

export const PlanSection = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 25px;
  align-items: start;
  padding: 20px 30px;
`;

export const CapsuleAndPriceContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || `auto`};
  align-items: center;
  grid-gap: 15px;
`;

export const PlanCapsule = styled.div`
  border-radius: 14px;
  padding: 6px 12px;
  font-size: 12px;
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight || theme.fontWeights.semibold};
  text-transform: capitalize;
  text-align: center;
  color: ${({ status }) =>
    status === "trialing" ||
    status === "suspended" ||
    status === "paused" ||
    status === "cancelled"
      ? `#FE5151`
      : `#82C4BB`};
  background-color: ${({ status }) =>
    status === "trialing" ||
    status === "suspended" ||
    status === "paused" ||
    status === "cancelled"
      ? `#FFDCE0`
      : `#EAF9F7`};
`;
export const MemeberInfoSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px 35px;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.main.primaryLight};
  padding: 20px 30px;
  margin: ${({ margin }) => margin || "10px 30px 0px"};
  min-width: 600px;
  width: fit-content;
`;
export const MemeberBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;
export const UpgradeUpperSection = styled.div`
  display: grid;
  grid-template-columns: 180px auto 180px;
  justify-content: space-between;
  align-items: ${({ alignItems }) => alignItems || " start"};
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`;

export const BillingContainer = styled.div`
  display: grid;
  background-color: ${({ theme }) => theme.colors.main.white};
  border-radius: ${({ theme }) => theme.card.borderRadius};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  margin-top: 30px;
  padding: 40px;
  grid-template-columns: auto;
  justify-content: center;
  @media (max-width: 1250px) {
    max-width: 100vw;
    overflow: auto;
  }
  @media (max-width: 767px) {
    padding: 30px 25px;
  }
`;

export const PricingMainContainer = styled.div`
  display: grid;
  background-color: white;
  border-radius: ${({ theme }) => theme.card.borderRadius};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  margin-top: 20px;
  padding: 40px;
  grid-template-columns: auto;
  justify-content: center;
  @media (max-width: 1450px) {
    padding: 40px 20px;
  }
`;
export const ToggleSection = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  align-items: center;
  ${({ isCheckout }) =>
    isCheckout &&
    css`
      margin-top: 30px;
    `}
`;
export const ToggleText = styled.p`
  position: relative;
  font-size: 16px;
  margin: 0;
  color: ${({ active, theme }) =>
    active ? theme.colors.main.primary : theme.colors.main.labelText};
  font-weight: ${({ active, theme }) =>
    active ? theme.fontWeights.semibold : theme.fontWeights.regular};
  cursor: pointer;
`;
export const SaveTag = styled.div`
  color: ${({ theme }) => theme.colors.main.warning};
  font-size: 12px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  padding: 4px 12px;
  background-color: ${({ theme }) => theme.colors.main.warningLight};
`;
export const UpgradeLowerSection = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, 400px) minmax(300px, 400px);
  justify-content: center;
  align-items: start;
  grid-gap: 30px 4vw;
  padding-top: 40px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;
export const PaymentCard = styled.div`
  border-radius: ${({ theme }) => theme.card.borderRadius};
  box-shadow: 0px 0px 36px #c2cce142;
  background-color: ${({ theme }) => theme.colors.main.white};
`;
export const UserNumberBox = styled.div`
  padding: 0px 12px;
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: ${({ theme }) => theme.input.borderRadius};
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  background-color: ${({ theme }) => theme.colors.main.whiteBg};
  color: ${({ theme }) => theme.colors.main.textMain};
  font-size: ${({ theme }) => theme.input.fontSize};
  margin-top: 15px;
`;
export const AdditionalUserInputSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 55px 55px;
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  border-radius: ${({ theme }) => theme.input.borderRadius};
  background-color: ${({ theme }) => theme.colors.main.whiteBg};
  margin-top: 15px;
  &:hover {
    border-color: ${({ hoverBorderColor, theme }) =>
      hoverBorderColor || theme.input.borderColor.hover};
    box-shadow: ${({ theme }) => theme.input.focusShadow};
  }
  &:focus-within {
    border-color: ${({ focusBorderColor, theme }) =>
      focusBorderColor || theme.input.borderColor.focus};
    box-shadow: ${({ theme }) => theme.input.focusShadow};
  }
`;
export const AdditionalUserInput = styled.input`
  border: none;
  border-radius: 6px;
  color: ${({ textColor, theme }) => textColor || theme.input.textColor};
  font-size: ${({ fontSize, theme }) => fontSize || theme.input.fontSize};
  padding: 5px 10px;
  min-height: ${({ height, theme }) => height || theme.input.minHeight};
  background-color: ${({ theme }) => theme.colors.main.whiteBg};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: ${({ fontSize, theme }) =>
      fontSize || theme.input.placeholder.size};
    color: ${({ theme }) => theme.input.placeholder.color};
  }
  :-ms-input-placeholder {
    font-size: ${({ fontSize, theme }) =>
      fontSize || theme.input.placeholder.size};
    color: ${({ theme }) => theme.input.placeholder.color};
  }
  &:focus {
    outline: none;
  }
`;
export const InputButton = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  display: grid;
  justify-content: center;
  align-items: center;
  &:hover {
    background: ${({ theme }) => theme.colors.main.primaryLight};
  }
  cursor: pointer;
`;

export const OrderSummarySection = styled.div`
  display: grid;
  grid-template-columns: 10px 1fr;
  grid-gap: 10px;
  padding: 20px;
  background-color: #f2f6fc;
  border-radius: 6px;
  margin-top: 15px;
`;
export const ColoredDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-top: 4px;
  background-color: ${({ color, theme }) => color || theme.colors.main.primary};
`;
export const LinkSpan = styled.a`
  color: ${({ theme }) => theme.colors.main.blue} !important;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  padding-left: 10px;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.hover.blue};
  }
  &:focus {
    color: ${({ theme }) => theme.colors.hover.blue};
  }
`;

export const PopupInnerBox = styled.div`
  position: relative;
  padding: 20px 17px;
  border-radius: ${({ theme }) => theme.card.borderRadius};
  background-color: ${({ monthly, theme }) =>
    monthly ? `#F2F3F5` : theme.colors.main.primaryLight};
  display: grid;
  grid-gap: 8px;
`;

export const NotifyBox = styled.div`
  position: absolute;
  top: -12px;
  left: 26%;
  color: ${({ theme }) => theme.colors.main.white};
  font-size: 11px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  padding: 4px 10px;
  background-color: ${({ theme }) => theme.colors.main.primary};
`;

export const CancelCross = styled.div`
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
`;

// ----- PRICING PAGE -----
// Plan Cards
export const PricingCardContainer = styled.div`
  display: grid;
  grid-template-columns: min(24%, 330px) min(24%, 330px) min(24%, 330px) min(
      24%,
      330px
    );
  grid-column-gap: 1%;
  justify-content: center;
  margin-top: ${({ isYearly }) => (isYearly ? `50px` : `10px`)};
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

export const SinglePricingCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: ${({ height }) => height || "650px"};
  width: 100%;
  background-color: ${({ plan, theme }) =>
    plan === "elite"
      ? theme.colors.main.blueLight
      : plan === "premium"
      ? "#f2f2fb"
      : plan === "standard"
      ? theme.colors.main.playGrayLight
      : theme.colors.main.primaryLight};
  border-radius: 20px;
  padding: 30px 0px;
  @media (max-width: 1450px) {
    min-height: 600px;
    padding: 20px 0px;
  }
`;

export const TopSectionContainer = styled.div`
  border-bottom: 1px solid rgba(93, 104, 126, 0.1);
`;

export const PlanNameAndTagContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const PlanName = styled.div`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  padding: 0px 10px 0px 40px;
  color: ${({ plan, theme }) =>
    plan === "elite"
      ? theme.colors.main.blue
      : plan === "premium"
      ? theme.colors.main.purpleMedium
      : plan === "standard"
      ? theme.colors.main.planGray
      : theme.colors.main.primary};
  @media (max-width: 1450px) {
    padding: 0 7px 0 20px;
    font-size: 14px;
  }
`;
export const PlanNameTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  padding: 0px 12px;
  border-radius: 13px;
  font-size: 11px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  background: ${({ plan }) =>
    plan === "elite"
      ? "#b1d0fd"
      : plan === "premium"
      ? "#dddcfb"
      : plan === "standard"
      ? "#d5e4f0"
      : "#eaf9f7"};
  color: ${({ plan, theme }) =>
    plan === "elite"
      ? theme.colors.main.blue
      : plan === "premium"
      ? theme.colors.main.purpleMedium
      : plan === "standard"
      ? theme.colors.main.planGray
      : theme.colors.main.primary};

  @media (max-width: 1450px) {
    height: 20px;
    border-radius: 10px;
    font-size: 10.5px;
    padding: 0px 8px;
  }
`;

export const PlanPriceContainer = styled.div`
  display: flex;
  align-items: baseline;
  padding: 0px 40px;
  margin-top: 20px;
  @media (max-width: 1450px) {
    padding: 0 20px;
    margin-top: 10px;
  }
`;
export const PlanPrice = styled.span`
  font-size: 36px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.main.textMain};
  @media (max-width: 1450px) {
    font-size: 32px;
  }
`;
export const PlanPriceText = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.main.textSecondary};
  margin-left: 20px;
  @media (max-width: 1450px) {
    font-size: 13px;
    margin-left: 10px;
  }
`;
export const PlanDescription = styled.div`
  border-left: ${({ plan, theme }) =>
    `4px solid ${
      plan === "elite"
        ? theme.colors.main.blue
        : plan === "premium"
        ? theme.colors.main.purpleMedium
        : plan === "standard"
        ? theme.colors.main.planGray
        : theme.colors.main.primary
    }`};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.main.textMain};
  padding: 0px 40px;
  margin-top: 20px;
  @media (max-width: 1450px) {
    padding: 0 20px;
    font-size: 12.5px;
    margin-top: 15px;
  }
`;
export const PlanFeaturesContainer = styled.div`
  margin: ${({ margin }) => margin || "0 0 15px 0"};
  padding: 0 40px;
  @media (max-width: 1450px) {
    padding: 0 20px;
  }
`;
export const SinglePlanFeature = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;
`;
export const FeatureTickIcon = styled.img`
  margin-top: 3px;
`;
export const FeatureText = styled.span`
  color: ${({ theme }) => theme.colors.main.labelText};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  margin-left: 12px;
  @media (max-width: 1450px) {
    font-size: ${({ fontSize }) => fontSize || "13px"};
  }
`;

export const PlanSelectButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 80px);
  height: 46px;
  margin: auto 40px 0 40px;
  cursor: pointer;
  background-color: ${({ plan, theme }) =>
    plan === "elite"
      ? theme.colors.main.blue
      : plan === "premium"
      ? theme.colors.main.purpleMedium
      : plan === "standard"
      ? theme.colors.main.planGray
      : theme.colors.main.primary};
  color: ${({ plan, theme }) => theme.colors.main.white};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  align-self: center;
  border-radius: ${({ theme }) => theme.button.borderRadius};
  ${({ isCurrent }) =>
    isCurrent &&
    css`
      display: none;
    `}
  &:hover {
    background-color: ${({ plan, theme }) =>
      plan === "elite"
        ? theme.colors.hover.blue
        : plan === "premium"
        ? theme.colors.hover.purpleMedium
        : plan === "standard"
        ? theme.colors.hover.planGray
        : theme.colors.hover.primary};
  }
  @media (max-width: 1450px) {
    width: calc(100% - 40px);
  }
`;

export const EverythingInPrev = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 0px 10px;
  border-radius: 12px;
  background: ${({ plan }) =>
    plan === "elite"
      ? "rgba(77, 148, 255, 0.25)"
      : plan === "premium"
      ? "rgba(154, 150, 249, 0.25)"
      : "rgba(102, 134, 162, 0.25)"};
  margin: 25px auto 0 40px;
  @media (max-width: 1450px) {
    margin: 20px auto 0 20px;
  }
`;
export const ArrowLeft = styled.img``;

export const PlusAddonDiv = styled.div`
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.main.textSecondary};
  margin-top: 20px;
  padding-left: 40px;
  @media (max-width: 1450px) {
    padding-left: 20px;
  }
`;

export const SeeFullComparisonDiv = styled.div`
  display: grid;
  justify-content: center;
  margin-top: 40px;
`;
export const SeeFullComparisonButton = styled.a`
  width: 310px;
  height: 56px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.main.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.main.primary};
  border: 2px solid ${({ theme }) => theme.colors.main.primary};
  border-radius: ${({ theme }) => theme.button.borderRadius};
  &:hover {
    background-color: ${({ theme }) => theme.colors.main.primary};
    color: ${({ theme }) => theme.colors.main.white};
    text-decoration: none;
  }
  &:focus {
    color: ${({ theme }) => theme.colors.main.white};
  }
`;

// Toggle Switch

export const TypeTogglerContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const TypeText = styled.span`
  font-size: 18px;
  font-weight: ${({ active }) => (active ? 600 : 400)};
  color: ${({ active }) => (active ? `#20bead` : `#5D687E`)};
  margin-top: 5px;
`;
export const ToggleSwitchContainer = styled.div`
  position: relative;
  width: 38px;
  height: 22px;
  display: inline-block;
  margin: 0px 10px;
`;
export const ToggleSwitchWrapper = styled.div`
  position: relative;
  width: 38px;
  height: 22px;
`;

export const TickImage = styled.img`
  @media (max-width: 1450px) {
    width: 17px;
  }
`;
