import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

const AudioPlayerModal = ({ isOpen, toggle, audio }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{
        marginTop: '35vh',
        width: '70vw',
        maxWidth: '612px',
      }}
    >
      <ModalBody style={{ padding: '10px', display: 'grid' }}>
        <Button
          close
          onClick={toggle}
          style={{ position: 'absolute', top: 0, right: 0, padding: '5px' }}
        >
          <span aria-hidden>&times;</span>
        </Button>
        <audio controls style={{ width: '100%', height: '70px', padding: '10px' }}>
          <source src={audio} type="audio/mp4" />
        </audio>
      </ModalBody>
    </Modal>
  );
};

export default AudioPlayerModal;
