import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import arrowDown from '../../assets/img/Sidebar/sidebar-arrow-down.svg';
import arrowUp from '../../assets/img/Sidebar/sidebar-arrow-up.svg';
import albaitLogo from '../../assets/img/albait-logo.png';
import avatar from '../../assets/img/avatar_5.svg';
import arrowWhite from '../../assets/img/icons/arrow-white.svg';
import hamburgerIcon from '../../assets/img/icons/hamburger.svg';
import logo from '../../assets/img/logo/logo_icon.svg';
import logoTxt from '../../assets/img/logo/logo_text.svg';
import CustomScrollbars from '../../components/CustomScrollbars/CustomScrollbars';
import routes from '../../routes';
import { CenteredTooltip, RoundMiniImage } from '../../styledComponents/common';
// import ProfilePopup from "./ProfilePopup";
import OrgAndProfilePopup from './OrgAndProfilePopup';
import {
  SeeMoreView,
  generateGroupedLinks,
  getCollapsedStates,
  getLinkView,
} from './sidebarHelpers';
import {
  LinkGroup,
  LinkGroupTitle,
  LogoContainer,
  LogoIcon,
  LogoIconDiv,
  LogoLink,
  LogoText,
  NameAndOrgDiv,
  OrgAndProfileContainer,
  OrgIcon,
  OrgName,
  PictureTooltipContainer,
  PopupArrowDiv,
  PopupArrowIcon,
  ProfilePicture,
  SidebarArrowIcon,
  SidebarCollapseButton,
  SidebarCollapseButtonIcon,
  SidebarContainer,
  SidebarLinksWrapper,
  SidebarNav,
  UserName,
} from './sidebarStyles';

const OrgAndProfileSection = props => {
  const [orgAndProfilePopupOpen, setOrgAndProfilePopupOpen] = useState(false);

  const [activeOrgsList, setActiveOrgsList] = useState([]);
  const [archivedOrgsList, setArchivedOrgsList] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState(
    localStorage.getItem('selected_organization_id'),
  );
  const [selectedOrgName, setSelectedOrgName] = useState(
    localStorage.getItem('selected_organization'),
  );
  const [selectedOrgColor, setSelectedOrgColor] = useState('');

  const {
    selectedOrganization,
    selectOrganization,
    organizations,
    history,
    loginProfileCreatedReset,
    sidebarMini,
    clearNavOpen,
    profile,
  } = props;

  const selectFirstActiveOrg = list => {
    const orgList =
      list &&
      list.length &&
      list.filter(organization => organization.is_active === true);
    if (orgList && orgList.length > 0) {
      selectOrg(orgList[0]);
    }
  };

  useEffect(() => {
    if (organizations && organizations.list) {
      if (!organizations.selectedOrganization) {
        const selectedOrgId = localStorage.getItem('selected_organization_id');
        if (selectedOrgId) {
          let selectedOrg;
          if (organizations.list && organizations.list.length) {
            organizations.list.some(organization => {
              if (organization.id === selectedOrgId) {
                selectedOrg = organization;
                return true;
              }
              return false;
            });
          }
          if (selectedOrg) {
            selectOrg(selectedOrg);
          } else {
            selectFirstActiveOrg(organizations.list);
          }
        } else {
          selectFirstActiveOrg(organizations.list);
        }
      }
      if (organizations.list.length > 0) {
        const activeList = [];
        const archivedList = [];
        organizations.list.map((org, index) => {
          if (org.is_active) {
            activeList.push(org);
          } else {
            archivedList.push(org);
          }
        });
        setActiveOrgsList(activeList);
        setArchivedOrgsList(archivedList);
      } else if (organizations.list.length === 0) {
        setActiveOrgsList([]);
        setArchivedOrgsList([]);
      }
    }
    if (
      organizations &&
      organizations.selectedOrganization &&
      (!selectedOrgName ||
        !selectedOrgColor ||
        selectedOrgId !== organizations.selectedOrganization.id ||
        selectedOrgName !== organizations.selectedOrganization.name) &&
      organizations.selectedOrganization.id
    ) {
      localStorage.setItem(
        'selected_organization',
        organizations.selectedOrganization.name,
      );
      localStorage.setItem(
        'selected_organization_id',
        organizations.selectedOrganization.id,
      );
      setSelectedOrgId(organizations.selectedOrganization.id);
      setSelectedOrgName(organizations.selectedOrganization.name);
      setSelectedOrgColor(organizations.selectedOrganization.color);
    }
  }, [organizations]);

  let userFirstName;
  const userFullName = localStorage.getItem('user_fullName');
  if (userFullName) {
    userFirstName = userFullName.split(' ')[0];
  }
  const userEmail = localStorage.getItem('email');

  const selectOrg = item => {
    localStorage.setItem('selected_organization', item.name);
    localStorage.setItem('selected_organization_id', item.id);
    localStorage.setItem('selected_organization_role', item.role);
    selectOrganization(item);
  };

  const closePopup = () => {
    setOrgAndProfilePopupOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_id');
    localStorage.removeItem('user_role');
    localStorage.removeItem('viewer_role');
    localStorage.removeItem('user_profile');
    localStorage.removeItem('user_fullName');
    localStorage.removeItem('selected_organization');
    localStorage.removeItem('selected_organization_id');
    localStorage.removeItem('selected_organization_role');
    localStorage.removeItem('selected_organization_plan');
    localStorage.removeItem('email');
    localStorage.removeItem('created_at');
    localStorage.removeItem('is_owner');
    localStorage.removeItem('user_timezone');
    history.push('/auth/login');
    loginProfileCreatedReset();
  };
  return (
    <OrgAndProfileContainer sidebarMini={sidebarMini}>
      <OrgAndProfilePopup
        closePopup={closePopup}
        popupOpen={orgAndProfilePopupOpen}
        sidebarMini={sidebarMini}
        history={history}
        handleLogout={handleLogout}
        clearNavOpen={clearNavOpen}
        profilePicture={
          (profile && profile.profileData && profile.profileData.thumbnail) ||
          avatar
        }
        userTimezone={
          profile && profile.profileData && profile.profileData.timezone
        }
        userFullName={userFullName}
        userEmail={userEmail}
        selectedOrganization={selectedOrganization}
        selectOrg={selectOrg}
        selectedOrgId={selectedOrgId}
        activeOrgsList={activeOrgsList}
        archivedOrgsList={archivedOrgsList}
      />
      {selectedOrganization && selectedOrganization.id && (
        <OrgIcon
          bgColor={selectedOrganization && selectedOrganization.color}
          active={selectedOrganization && selectedOrganization.is_active}
          margin="0 -10px 0 0"
          $size={sidebarMini && '30px'}
          onClick={() => setOrgAndProfilePopupOpen(true)}
        >
          {/* <RoundMiniImage src={albaitLogo} alt="" size="40px" /> */}
          <CenteredTooltip left="0px" top="-35px">
            {selectedOrgName}
          </CenteredTooltip>
          {selectedOrgName && selectedOrgName.charAt(0).toUpperCase()}
        </OrgIcon>
      )}

      <PictureTooltipContainer>
        <ProfilePicture
          $size={sidebarMini && '30px'}
          cursor="pointer"
          src={
            (profile && profile.profileData && profile.profileData.thumbnail) ||
            avatar
          }
          alt=""
          onClick={() => setOrgAndProfilePopupOpen(true)}
        />
        <CenteredTooltip left="0px" top="-35px">
          {userFullName}
        </CenteredTooltip>
      </PictureTooltipContainer>

      <PopupArrowDiv onClick={() => setOrgAndProfilePopupOpen(true)}>
        <PopupArrowIcon
          marginLeft="0"
          src={arrowWhite}
          alt=""
          popupOpen={orgAndProfilePopupOpen}
        />
      </PopupArrowDiv>
    </OrgAndProfileContainer>
  );
};

const Sidebar = props => {
  const [windowWidth, setWindowWidth] = useState(0);
  const [sidebarLinks, setSidebarLinks] = useState(null);
  const [collapseState, setCollapseState] = useState(
    getCollapsedStates(routes),
  );

  const {
    selectedOrganization,
    selectOrganization,
    getOrganizationList,
    organizations,
    profile,
    clearNavOpen,
    history,
    sidebarMini,
    loginProfileCreatedReset,
    location,
    handleMiniClick,
    navOpen,
  } = props;

  useEffect(() => {
    updateSize();
    window.addEventListener('resize', updateSize);

    getOrganizationList();

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  useEffect(() => {
    setSidebarLinks(generateGroupedLinks(routes, selectedOrganization));
  }, [props.routes, selectedOrganization]);

  const updateSize = () => {
    setWindowWidth(window.innerWidth);
  };

  const { t } = useTranslation();

  return (
    <SidebarContainer sidebarMini={sidebarMini} navOpen={navOpen}>
      {windowWidth >= 992 && (
        // <HamburgerIconContainer>
        //   <HamburgerIcon
        //     src={hamburgerIcon}
        //     alt=""
        //     onClick={() => handleMiniClick()}
        //   />
        // </HamburgerIconContainer>
        <SidebarCollapseButton onClick={() => handleMiniClick()}>
          <SidebarCollapseButtonIcon
            sidebarMini={sidebarMini}
            src={arrowDown}
            alt=""
          />
        </SidebarCollapseButton>
      )}
      <LogoContainer>
        <LogoLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.fieldservicely.com"
        >
          <LogoIconDiv>
            <LogoIcon src={logo} alt="" />
          </LogoIconDiv>
        </LogoLink>
        <LogoLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.fieldservicely.com"
        >
          <LogoText src={logoTxt} alt="" />
        </LogoLink>
      </LogoContainer>
      <CustomScrollbars
        style={{
          height: `calc(100vh - 137px)`,
          zIndex: 4,
        }}
      >
        <SidebarLinksWrapper sidebarMini={sidebarMini}>
          {sidebarLinks &&
            Object.keys(sidebarLinks).map((group, index) => {
              return (
                <LinkGroup key={index}>
                  {!sidebarMini && group !== 'Common' && (
                    <LinkGroupTitle>{t(group)}</LinkGroupTitle>
                  )}
                  <SidebarNav>
                    {sidebarLinks[group] &&
                      sidebarLinks[group].length > 0 &&
                      sidebarLinks[group].map((link, index) => {
                        return getLinkView(
                          selectedOrganization,
                          link,
                          index,
                          collapseState,
                          setCollapseState,
                          location,
                          sidebarMini,
                          t,
                        );
                      })}
                  </SidebarNav>
                </LinkGroup>
              );
            })}
          <SeeMoreView
            organization={selectedOrganization}
            location={location}
          />
        </SidebarLinksWrapper>
      </CustomScrollbars>
      <OrgAndProfileSection
        sidebarMini={sidebarMini}
        history={history}
        profile={profile}
        loginProfileCreatedReset={loginProfileCreatedReset}
        clearNavOpen={clearNavOpen}
        organizations={organizations}
        selectedOrganization={selectedOrganization}
        selectOrganization={selectOrganization}
      />
    </SidebarContainer>
  );
};

export default Sidebar;
