import Papa from 'papaparse';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import downloadIcon from '../../../assets/img/icons/download.svg';
import upArrow from '../../../assets/img/icons/up_arrow_blue.svg';
import BackButtonComponent from '../../../components/BackButtonComponent/BackButtonComponent';
import ProjectDropdown from '../../../components/CommonFilter/ProjectDropdown';
import MembersDropdownStyle from '../../../components/DropdownStyle/MembersDropdownStyle';
import ButtonTextLoader from '../../../components/Loaders/ButtonTextLoader';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import {
  ButtonIcon,
  ColoredButtonWithIcon,
  DeleteButton,
  EditButton,
  PrimaryButton,
  WhiteButton,
  WhiteButtonWithIcon,
} from '../../../styledComponents/buttons';
import {
  GeneralInfoItem,
  GeneralInfoSection,
} from '../../../styledComponents/clients';
import {
  CardTitle,
  CommonFlex,
  CommonGrid,
  CommonText,
  Container,
  ContentHeader,
  InputField,
  InputLabel,
  InputTextArea,
  PageTitle,
} from '../../../styledComponents/common';
import {
  BottomLeft,
  BottomRight,
  BottomRow,
  DottedRoundedBorder,
  EmailBox,
  EmailEntry,
  InputEmail,
  InvalidEmailContainer,
  MemberInvitationContainer,
  RedStar,
  ValidEmailContainer,
} from '../../../styledComponents/members';
import { validateEmail } from '../../../utils/helper';

const MemberInvitationCSV = props => {
  const {
    selectedOrganization,
    clearMemberInvited,
    projectList,
    memberInvited,
    inviteMember,
    inviteMemberBulk,
    history,
    projectIsLoading,
    inviteMemberLoading,
  } = props;

  const [emails, setEmails] = useState([]);
  const [validEmails, setValidEmails] = useState([]);
  const [invalidEmails, setInvalidEmails] = useState([]);
  const fileInputRef = useRef(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [extraRow, setExtraRow] = useState(false);

  const handleFileSelect = () => {
    // Trigger the click event of the hidden file input
    fileInputRef.current.click();
  };

  useEffect(() => {
    clearMemberInvited();

    return () => {
      clearMemberInvited();
    };
  }, []);

  useEffect(() => {
    if (memberInvited) {
      setEmails([]);
      setValidEmails('');
      setInvalidEmails([]);
      setFileUploaded(false);
      setIsDragOver(false);
      setErrorMessage('');
      setExtraRow(false);
      setTimeout(() => {
        history.push('/user/members');
      }, 50);
    }
  }, [memberInvited]);

  // const handleFileChange = event => {
  //   const selectedFile = event.target.files[0];

  //   if (selectedFile) {
  //     // Use FileReader to read the contents of the file
  //     const reader = new FileReader();
  //     reader.onload = e => {
  //       const fileContent = e.target.result;
  //       // Split the file content into an array of emails (assuming one email per line)
  //       const emailArray = fileContent.split('\n').map(email => email.trim());
  //       // Exclude the first element (header) from the array
  //       emailArray.shift();
  //       // remove the last row if founded blank
  //       if (emailArray.length > 0 && emailArray[emailArray.length - 1] === '') {
  //         emailArray.pop();
  //       }

  //       // Separate valid and invalid emails
  //       const validEmailArray = [];
  //       const invalidEmailArray = [];

  //       emailArray.forEach(email => {
  //         if (validateEmail(email)) {
  //           validEmailArray.push(email);
  //         } else {
  //           invalidEmailArray.push(email);
  //         }
  //       });

  //       // Update the states with the separated email arrays
  //       setFileUploaded(true);
  //       setEmails(emailArray);
  //       setValidEmails(validEmailArray);
  //       setInvalidEmails(invalidEmailArray);
  //     };
  //     reader.readAsText(selectedFile);
  //   }
  // };

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      // No file selected
      return;
    }

    if (selectedFile) {
      // Use FileReader to read the contents of the file
      const reader = new FileReader();
      reader.onload = e => {
        const fileContent = e.target.result;
        setFileUploaded(true);
        setExtraRow(false);

        const fileName = selectedFile.name;
        const extension = fileName.split('.').pop().toLowerCase();
        const fileSizeInMB = selectedFile.size / (1024 * 1024); // Convert bytes to MB
        // setFileName(fileName);

        // Check if the file extension is allowed
        if (extension !== 'csv' && extension !== 'xlsx') {
          setErrorMessage(
            'Invalid file format. Please select a CSV or XLSX file.',
          );
          return;
        }

        if (fileSizeInMB > 2) {
          setErrorMessage('File size must be less than 2MB');
          return;
        }

        // Use Papa Parse to parse the CSV content
        Papa.parse(fileContent, {
          header: true, // Specify that the CSV file has a header row
          skipEmptyLines: true,
          delimiter: ',', // Specify the delimiter used in your CSV file
          complete: result => {
            if (result.errors.length > 0) {
              // Handle parsing errors
              setErrorMessage('Error parsing CSV: ' + result.errors[0].message);
            } else if (result.data.length === 0) {
              setErrorMessage('No Data in the CSV File');
              setEmails([]);
              setValidEmails('');
              setInvalidEmails([]);
            } else {
              // Extract the 'EmailAddress' column
              // const emailArray = result.data.map(item =>
              //   item && item['Email Address']
              //     ? item['Email Address'].trim()
              //     : '',
              // );

              const emailColumn = Object.keys(result.data[0]).find(header =>
                header.toLowerCase().includes('email'),
              );

              // If an email column is found, extract the emails
              const emailArray = emailColumn
                ? result.data.map(item =>
                    item && item[emailColumn] ? item[emailColumn].trim() : '',
                  )
                : [];

              // Separate valid and invalid emails
              const validEmailArray = [];
              const invalidEmailArray = [];

              emailArray.forEach(email => {
                if (validateEmail(email)) {
                  validEmailArray.push(email);
                } else if (email !== '') {
                  invalidEmailArray.push(email);
                } else if (email === '') {
                  setExtraRow(true);
                }
              });

              // Update the states with the separated email arrays

              setEmails(emailArray);
              setValidEmails(validEmailArray);
              setInvalidEmails(invalidEmailArray);

              // Clear any previous error message
              setErrorMessage('');
            }
          },
          error: error => {
            // Set the error message in the state
            setErrorMessage('Error parsing CSV: ' + error.message);
          },
        });
      };
      reader.readAsText(selectedFile);
    }
  };

  const handleDownloadTemplate = () => {
    // Define the CSV content with headers
    const csvContent = 'Email\n';

    // Create a Blob from the data
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a download link
    const url = URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = url;
    link.download = 'template.csv'; // Set the desired file name

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger the click event to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);

    // Revoke the Blob URL to free up resources
    URL.revokeObjectURL(url);
  };

  // Drag and Drop Feature

  const handleDragOver = e => {
    e.preventDefault();
    setIsDragOver(true);
  };
  const handleDragEnter = e => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = e => {
    e.preventDefault();
    setIsDragOver(false);
  };
  const handleDrop = e => {
    e.preventDefault();
    setIsDragOver(false);

    const droppedFiles = e.dataTransfer.files;
    // Create a synthetic event to mimic the behavior of the file input event
    const syntheticEvent = { target: { files: droppedFiles } };

    // Process the dropped file by calling handleFileChange
    handleFileChange(syntheticEvent);
  };

  const sendInvitaion = () => {
    if (validEmails.length > 0) {
      if (selectedOrganization && selectedOrganization.id) {
        let csvContent = '';
        validEmails.forEach(row => {
          csvContent += `${row}\n`;
        });
        // Create a Blob from the data
        const blob = new Blob([csvContent], { type: 'text/csv' });
        // Create a File object with the Blob and specify the filename
        const file = new File([blob], 'valid_rows.csv', { type: 'text/csv' });

        let payload = {
          organization_id: selectedOrganization.id,
          file: file, // Sending the File directly as part of the payload
        };
        inviteMemberBulk(payload);
      }
    }
  };

  return (
    <div className="content">
      <PageTitle>Member Invitation</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => history.push('/user/members')}
          subTitle="Back to all members"
        />
      </ContentHeader>
      <Container style={{ padding: `30px` }}>
        <CardTitle margin="0 0 25px">
          Upload a CSV file from your computer
        </CardTitle>

        {fileUploaded ? (
          emails.length === 0 ? (
            <>
              <InvalidEmailContainer>
                <CommonText>No email address found</CommonText>
              </InvalidEmailContainer>
              <span
                style={{
                  color: '#2268EF',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontWeight: '600',
                  marginTop: '25px',
                }}
                onClick={handleFileSelect}
              >
                <ButtonIcon size="15px" src={upArrow} alt="" /> Upload CSV again
              </span>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              {/* Display the error message if it exists */}
              {errorMessage && (
                <div style={{ color: 'red', marginTop: '10px' }}>
                  {errorMessage}
                </div>
              )}
            </>
          ) : (
            <>
              <ValidEmailContainer>
                <CommonText color="#18B54D">
                  {validEmails.length === 0 || validEmails.length === 1
                    ? `${validEmails.length} email address found`
                    : `${validEmails.length} email addresses found`}
                </CommonText>
              </ValidEmailContainer>
              <CommonFlex gap="12px" margin="30px 0 0 0">
                <PrimaryButton
                  disabled={validEmails.length === 0 ? true : false}
                  padding={inviteMemberLoading && '0 25px'}
                  onClick={() => sendInvitaion()}
                >
                  {inviteMemberLoading ? (
                    <ButtonTextLoader
                      loadingText="Sending Invitation"
                      fontSize="13px"
                    />
                  ) : (
                    'Send Invitation'
                  )}
                </PrimaryButton>
              </CommonFlex>
              {/* Display the error message if it exists */}
              {errorMessage && (
                <div style={{ color: 'red', marginTop: '10px' }}>
                  {errorMessage}
                </div>
              )}
              {/* Display the list of invalid emails */}
              {extraRow && (
                <CardTitle margin="20px 0 25px 0">
                  Emails of extra columns won't be counted
                </CardTitle>
              )}
              {invalidEmails && invalidEmails.length > 0 && (
                <div>
                  <CardTitle fontSize="14px" margin="20px 0 25px 0">
                    Invalid Emails:
                  </CardTitle>

                  {invalidEmails.map((email, index) => (
                    <InvalidEmailContainer key={index}>
                      {email}
                    </InvalidEmailContainer>
                  ))}
                </div>
              )}

              <>
                <span
                  style={{
                    color: '#2268EF',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    fontWeight: '600',
                    marginTop: '25px',
                  }}
                  onClick={handleFileSelect}
                >
                  <ButtonIcon size="15px" src={upArrow} alt="" /> Upload CSV
                  again
                </span>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              </>
            </>
          )
        ) : (
          <>
            <DottedRoundedBorder
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <CommonText margin="20px 0 0 0">
                Drag & drop your CSV file here
              </CommonText>
              <CommonText margin="0px">
                or{' '}
                <span
                  style={{
                    color: '#2268EF',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    fontWeight: '600',
                  }}
                  onClick={handleFileSelect}
                >
                  select a file from your computer
                </span>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              </CommonText>

              <BottomRow>
                <BottomLeft>
                  <CommonText margin="0px 0 0 15px">
                    Required Column:
                  </CommonText>
                  <WhiteButton bgColor="#EFF4F9" margin="10px 0 0 15px">
                    Email
                  </WhiteButton>
                </BottomLeft>
                <BottomRight>
                  <CommonText margin="0px 15px">
                    Template:{' '}
                    <span
                      style={{
                        color: '#2268EF',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        fontWeight: '600',
                      }}
                      onClick={handleDownloadTemplate}
                    >
                      <ButtonIcon size="15px" src={downloadIcon} alt="" />{' '}
                      template.csv
                    </span>
                  </CommonText>
                </BottomRight>
              </BottomRow>
            </DottedRoundedBorder>
          </>
        )}
      </Container>
    </div>
  );
};

export default MemberInvitationCSV;
