import { css } from '@emotion/core';
import queryString from 'query-string';
import { useEffect } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import styled from 'styled-components';

const customCss = css`
  margin: 5px;
  text-align: center;
`;

const VerifyContainer = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.main.whiteBg};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VerifyText = styled.p`
  font-size: 36px;
`;

const TextLoader = props => {
  return (
    <div className="sweet-loading">
      <PulseLoader
        size={8}
        color={'#2f394e'}
        loading={props.loading}
        css={customCss}
      />
    </div>
  );
};

const VerifyUser = props => {
  useEffect(() => {
    let token = localStorage.getItem('access_token');

    if (token != null) {
      props.history.push('/user/jobs');
    }
    if (!props.location.search) {
      props.history.push('/auth/login');
    } else {
      const queryParsed = queryString.parse(props.location.search);
      let payload = {
        email: queryParsed.email,
        code: queryParsed.code,
      };
      props.verify(payload);
    }
  }, []);

  useEffect(() => {
    if (props.verifyUser && props.verifyUser.redirect) {
      if (props.verifyUser.token && props.verifyUser.userData) {
        localStorage.setItem('access_token', props.verifyUser.token);
        localStorage.setItem('user_id', props.verifyUser.userData.user_id);
        localStorage.setItem(
          'user_profile',
          props.verifyUser.userData.user_profile.id,
        );
        localStorage.setItem(
          'user_fullName',
          `${props.verifyUser.userData.user_profile.first_name}${
            props.verifyUser.userData.user_profile.last_name
              ? ` ${props.verifyUser.userData.user_profile.last_name}`
              : ``
          }`,
        );
        localStorage.setItem('email', props.verifyUser.userData.email);
        setTimeout(() => {
          props.history.push('/setup/add-or-join');
        }, 400);
      } else {
        props.history.push('/auth/login');
      }
      setTimeout(() => {
        props.resetVerify();
      }, 500);
    } else if (
      props.verifyUser &&
      props.verifyUser.failure &&
      props.location.search
    ) {
      const queryParsed = queryString.parse(props.location.search);
      props.history.push(
        `/auth/verify-code?email=${encodeURIComponent(queryParsed.email)}`,
      );
      props.resetVerify();
    }
  }, [props.verifyUser]);

  return (
    <VerifyContainer>
      <VerifyText>Verifying</VerifyText>
      <TextLoader loading />
    </VerifyContainer>
  );
};

export default VerifyUser;
