import moment from 'moment-timezone';
import styled from 'styled-components';

import { getRandomColor } from '../../../utils/getRandomColor';

const TimeDiv = styled.div`
  height: 36px;
  width: 90px;
  border-radius: ${({ theme }) => theme.borderRadii.button};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.font.pageText.size};
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  background: ${({ backgroundColor }) => backgroundColor};
  margin-top: 15px;
  @media screen and (max-width: 1600px) {
    margin-top: 0px;
    max-height: 28px;
    font-size: 12.5px;
  }
`;

const TimeBlankDiv = styled.div`
  height: 36px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.font.pageText.size};
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  background: ${({ theme }) => theme.colors.main.white};
  margin: 10px auto;
`;

const TimeSpan = ({
  event,
  selectedMember,
  getTimesheetDetails,
  handleSetUserData,
  backgroundColor,
}) => {
  return (
    <>
      {event.title === '0h 00m' ? (
        <TimeBlankDiv>-</TimeBlankDiv>
      ) : (
        <TimeDiv
          style={{ cursor: 'pointer' }}
          backgroundColor={backgroundColor}
          onClick={() => {
            getTimesheetDetails(
              moment(event.event && event.event.start),
              selectedMember
                ? selectedMember.id
                : localStorage.getItem('user_id'),
            );
            handleSetUserData(
              moment(event.event && event.event.start),
              event.title,
            );
          }}
        >
          {event.title}
        </TimeDiv>
      )}
    </>
  );
};

export default TimeSpan;
