import { Link } from 'react-router-dom';
import { Card, Progress } from 'reactstrap';
import styled from 'styled-components';

export const DashboardModeSelect = styled.div`
  width: 185px;
  display: flex;
  justify-content: space-between;
  height: 34px;
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: 6px;
  background: #fff;
  margin-left: 15px;
  padding: 3px;
`;

//dashboard card
export const ReportCard = styled(Card)`
  border: 1px solid #ccebda;
  border-radius: 10px;
  background: #fff;
  box-shadow: none;
  margin-bottom: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap ?? '25px'};
`;
//dashboard card
export const DashboardCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: 10px;
  background: #fff;
  box-shadow: none;
  margin-bottom: 0;
`;
export const DashboardCardTitle = styled.span`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.main.textMain};
`;
export const DashboardCardSubTitle = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.main.labelText};
  margin-left: 15px;
`;
export const LinkToReport = styled(Link)`
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ type, theme }) =>
    type && type === 'success'
      ? theme.colors.main.success
      : type && type === 'error'
      ? theme.colors.main.error
      : type && type === 'blue'
      ? theme.colors.main.blue
      : type && type === 'warning'
      ? theme.colors.main.warning
      : theme.colors.main.primary};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.hover.primary};
    text-decoration: none;
  }
`;

//small card
export const DashboardSmallCard = styled(Card)`
  height: 13.5rem;
  overflow: hidden !important;
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: 10px;
  background: #fff;
  margin-bottom: 0;
  box-shadow: none;
`;
export const SmallCardLabel = styled.span`
  font-size: ${({ fontSize }) => fontSize || `14px`};
  color: ${({ theme }) => theme.colors.main.labelText};
`;
export const SmallCardPercentage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 5px;
  font-size: 11px;
  background: ${({ status, theme }) =>
    status === 'increase'
      ? theme.colors.main.success
      : status === 'decrease'
      ? theme.colors.main.error
      : `#e8eaee`};
  border-radius: 10px;
  color: #fff;
`;
export const SmallCardContent = styled.span`
  font-size: 22px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.main.textMain};
  margin-top: ${({ marginTop }) => marginTop || '0'};
`;

//activity card
export const ActivityCardDate = styled.div`
  background: ${({ theme, bgColor }) => bgColor || theme.colors.main.white};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: 12px;
  padding: 12px 10px;
  color: ${({ color, theme }) => color || theme.colors.main.labelText};
  text-align: center;
  font-size: 12px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.main.primary};
    border-color: ${({ theme }) => theme.colors.main.primary};
    color: ${({ theme }) => theme.colors.main.white};
  }
  @media (max-width: 1400px) {
    padding: 8px 8px;
  }
`;

//top projects card
export const PieChartContainer = styled.div`
  width: 100%;
  // background: #f7f8f9;
  // border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: 6px;
  // padding: 15px 15px;
  margin-top: 15px;
`;

export const ColorCircle = styled.div`
  width: ${({ size }) => size || '14px'};
  height: ${({ size }) => size || '14px'};
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  background: ${({ background, theme }) =>
    background || theme.colors.main.primary};
`;

export const ColorSquare = styled.div`
  width: ${({ size }) => size || '14px'};
  height: ${({ size }) => size || '14px'};
  flex-shrink: 0;
  border-radius: ${({ theme }) => theme.borderRadii.square};
  background: ${({ background, theme }) =>
    background || theme.colors.main.primary};
`;

export const ImageCircle = styled.div`
  width: ${({ size }) => size || '40px'};
  height: ${({ size }) => size || '40px'};
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  flex-shrink: 0;
  background: ${({ background, theme }) =>
    background || theme.colors.main.primary};
  position: relative;
  img {
    position: absolute;
    inset: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: ${({ theme }) => theme.borderRadii.circle};
  }
`;

//timesheet card
export const TimesheetTime = styled.div`
  width: 100%;
  min-height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${({ borderColor, theme }) => borderColor || theme.colors.border.secondary};
  margin-top: 10px;
  background: ${({ background }) => background || '#fff'};
  border-radius: 8px;
`;

export const EmployeeActivity = styled.div`
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: ${({ direction }) => direction || `column`};
  align-items: ${({ alignItems }) => alignItems || `center`};
  text-align: ${({ textAlign }) => textAlign ?? 'initial'};
  justify-content: ${({ justifyContent }) => justifyContent || `center`};
  // background: ${({ theme }) => theme.colors.main.successLight};
  background: ${({ active }) => (active ? `#CCEBDA` : `#F3F5F9`)};
  border: 1px solid ${({ active }) => (active ? `#41CB8F` : `#CDE2EA`)};
  padding: ${({ padding }) => padding || `50px 10px`};
  border-radius: ${({ borderRadius }) => borderRadius || `6px`};
  font-size: ${({ fontSize }) => fontSize || `14px`};
  color: ${({ theme }) => theme.colors.main.textMain};
  position: relative;
  &:before {
    display: inline-flex;
    content: '';
    width: 16px;
    height: 16px;
    background: ${({ active }) => (active ? `#41CB8F` : `#8DADBA`)};
    border: 2px solid #ffffff;
    border-radius: 50%;
    margin-bottom: 15px;
  }
`;

// Array of background colors
const colors = ['#ffb8b8', '#fab1a0', '#a29bfe', '#81ecec'];

export const FragmentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ index }) => colors[index]};
  color: ${({ theme }) => theme.colors.main.textMain};
  border-radius: 10px;
  padding: 5px 10px;
  margin-bottom: 6px;
  height: 40px;
`;

export const FragmentContainerTopWorkOrder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ index, workOrdersSummary }) => workOrdersSummary[index] ? colors[index] : `rgba(${colors[index].replace('#', '').match(/.{1,2}/g).map(hex => parseInt(hex, 16)).join(', ')}, 0.3)`};
  color: ${({ theme }) => theme.colors.main.textMain};
  border-radius: 10px;
  padding: 5px 10px;
  margin-bottom: 6px;
  height: 40px;
`;

export const Value = styled.span`
  background-color: #efe6fd;
  color: ${({ theme }) => theme.colors.main.textMain};
  border-radius: 15px;
  padding: 5px 20px;
  display: inline-block;
  margin-right: 15px;
`;

export const SmallCardContentJobToday = styled.div`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.main.textMain};
  margin-top: ${({ marginTop }) => marginTop || '0'};
`;

export const Table = styled.table`
  border-collapse: collapse;
`;

export const TableHeaderCell = styled.th`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  padding: 8px 0px;
  text-align: left;
  width: ${({ width }) => width};
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

export const TableCell = styled.td`
  padding: 8px 0px;
  text-align: left;
  width: ${({ width }) => width};
  position: relative;
  font-size: ${({ fontSize, name, theme }) =>
    fontSize ? fontSize : !name ? "13px" : "13.5px"};
  /* font-weight: ${({ fontWeight, name, theme }) =>
    fontWeight
      ? fontWeight
      : !name
      ? theme.fontWeights.semibold
      : theme.font.pageText.weight}; */
  ${({ hoverPointer }) =>
    hoverPointer &&
    css`
      cursor: pointer;
    `}
  &:hover {
    ${({ hoverUnderline }) =>
      hoverUnderline &&
      css`
        text-decoration: underline;
      `}
  }
`;

export const StatusWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const StatusDot = styled.span`
    width: 8px;
    height: 8px;
    background-color: ${({ bgColor, status, theme }) =>
        bgColor
        ? bgColor
        : status && (status === "partially complete" || status === "partially paid")
        ? theme.colors.main.warning
        : status && (status === "unscheduled" || status === "draft")
        ? theme.colors.main.gray
        : status && (status === "ready to dispatch" || status === "sent")
        ? theme.colors.main.readyToDispatch
        : status && (status === "completed" || status === "paid")
        ? theme.colors.main.success
        : status && status === "totally missed"
        ? theme.colors.main.error
        : theme.colors.main.gray};
    border-radius: 50%; 
    margin-right: 5px;
`;