import queryString from 'query-string';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import hidePassword from '../../assets/img/common/password_hide.svg';
import viewPassword from '../../assets/img/common/password_show.svg';
import GoogleLogo from '../../assets/img/icons/google_logo.svg';
import AuthPageLeftSection from '../../components/AuthLayout/AuthPageLeftSection';
import TextInput from '../../components/FloatingLabelInput/FloatingLabelInput';
import ButtonTextLoader from '../../components/Loaders/ButtonTextLoader';
import {
  AuthCard,
  AuthForm,
  AuthFormAlert,
  AuthInputContainer,
  AuthPageContainer,
  AuthPageRightContainer,
  AuthSubmitButton,
  ForgotPasswordDiv,
  ForgotPasswordText,
  GoogleButtonText,
  GoogleIcon,
  GoogleSignInButton,
  LoginActiveTab,
  LoginInactiveTab,
  LoginTabContainer,
  LoginTermsAndConditionsCheckbox,
  LoginTermsAndConditionsLabel,
  LoginTermsAndConditionsSection,
  ShowPasswordIcon,
  TermsAndConditionsLink,
} from '../../styledComponents/authLayout';
import { CommonText } from '../../styledComponents/common';
import { LoginLoaderContainer } from '../../styledComponents/login';
import api from '../../utils/api';
import LoginLoader from './LoginLoader';

const Login = ({ login, history, location, auth, loginRedirectReset }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [saveErrors, setSaveErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [passwordIsFocused, setPasswordIsFocused] = useState(false);
  const [redirectLocation, setRedirectLocation] = useState(null);
  const [fromLocation, setFromLocation] = useState(null);
  const [isLoginLoaderOpen, setIsLoginLoaderOpen] = useState(false);
  const passwordFieldRef = useRef(null);
  const methods = {
    setEmail,
    setPassword,
  };

  useEffect(() => {
    let token = localStorage.getItem('access_token');
    let redirect;
    if (location.search) {
      redirect = queryString.parse(location.search);
      setRedirectLocation(redirect);
    }
    if (token != null) {
      setIsLoginLoaderOpen(true);
      if (window.innerWidth < 992) {
        document.documentElement.classList.remove('nav-open');
      }

      setTimeout(() => {
        setIsLoginLoaderOpen(false);
        history.push('/user/jobs');
      }, 500);
    }
    if (location.state && location.state.from) {
      setFromLocation(location.state.from);
    }
    return () => {
      //document.body.classList.toggle("login-page");
      setFromLocation(null);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Moving cursor to the end
    passwordFieldRef.current.selectionStart =
      passwordFieldRef.current.value.length;
    passwordFieldRef.current.selectionEnd =
      passwordFieldRef.current.value.length;
  }, [showPassword]);

  useEffect(() => {
    const saveErrors = {};
    if (auth.errorMessage) {
      if (
        auth.errorMessage === 'Account with this email/username does not exists'
      ) {
        saveErrors['email'] = "Couldn't find your account!";
      } else if (
        auth.errorMessage === 'Unable to log in with provided credentials.'
      ) {
        saveErrors['password'] = 'Wrong password!';
      }
      if (auth.errorMessage === 'provided email address is not verified') {
        saveErrors['email'] = 'Provided email address is not verified!';
      }
    }
    setSaveErrors(saveErrors);
    if (auth.redirect) {
      if (auth.notVerified) {
        history.push(
          '/auth/verify-code?email=' +
            encodeURIComponent(auth.auth.email) +
            '&name=' +
            encodeURIComponent(auth.auth.user_profile.first_name),
        );
        loginRedirectReset();
      } else if (auth.auth.user_profile !== 'undefined') {
        localStorage.setItem('access_token', auth.auth.token);
        localStorage.setItem('user_id', auth.auth.user_id);
        if (auth.auth.user_profile && auth.auth.user_profile.id) {
          localStorage.setItem('user_profile', auth.auth.user_profile.id);
          localStorage.setItem(
            'user_fullName',
            `${auth.auth.user_profile.first_name}${
              auth.auth.user_profile.last_name
                ? ` ${auth.auth.user_profile.last_name}`
                : ``
            }`,
          );
        }
        localStorage.setItem('email', auth.auth.email);
        localStorage.setItem('created_at', auth.auth.created_at);
        localStorage.setItem('user_role', auth.auth.role);
        if (auth.auth.viewer_role) {
          localStorage.setItem('viewer_role', auth.auth.viewer_role);
        }

        if (
          auth.auth.user_profile.timezone &&
          auth.auth.user_profile.timezone.tz
        ) {
          localStorage.setItem(
            'user_timezone',
            JSON.stringify(auth.auth.user_profile.timezone),
          );
        }

        if (auth.auth.organizations) {
          // eslint-disable-next-line
          auth.auth.organizations.map(organization => {
            if (
              organization.role === 'owner' &&
              organization.is_active === true
            ) {
              localStorage.setItem('is_owner', true);
            }
          });
        }

        if (window.innerWidth < 992) {
          document.documentElement.classList.remove('nav-open');
        }
        // history.push('/user/tasks');

        if (auth.auth.show_onboard) {
          history.push('/setup/add-or-join');
        } else {
          if (window.innerWidth < 992) {
            document.documentElement.classList.remove('nav-open');
          }
          history.push('/user/jobs');
        }
        loginRedirectReset();
      } else {
        history.push('/user/user-profile');
        loginRedirectReset();
      }
    }
  }, [auth, history, loginRedirectReset]);

  const onTextChange = e => {
    const { name, value } = e.target;
    setSaveErrors({});
    methods[name](value);
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!email.trim() || !password) {
      if (!email.trim()) {
        formIsValid = false;
        saveErrors['email'] = 'Email Required';
      }
      if (!password) {
        formIsValid = false;
        saveErrors['password'] = 'Password Required';
      }
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const handleLogin = e => {
    e.preventDefault();
    if (checkError()) {
      let payload = {
        username: email.trim(),
        password,
      };
      login(payload);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
    passwordFieldRef.current.focus();
  };

  if (isLoginLoaderOpen) {
    return (
      <LoginLoaderContainer>
        <LoginLoader />
      </LoginLoaderContainer>
    );
  } else {
    return (
      <AuthPageContainer>
        <AuthPageLeftSection />
        <AuthPageRightContainer>
          <AuthCard from={fromLocation}>
            <LoginTabContainer>
              <LoginActiveTab>{t('sign_in')}</LoginActiveTab>
              <LoginInactiveTab to="/auth/register">
                {t('sign_up')}
              </LoginInactiveTab>
            </LoginTabContainer>
            <AuthForm onSubmit={e => handleLogin(e)}>
              <AuthInputContainer>
                <TextInput
                  id="email"
                  name="setEmail"
                  label={t('email')}
                  type="text"
                  onChange={e => onTextChange(e)}
                  value={email}
                  autoFocus
                />
              </AuthInputContainer>
              <AuthInputContainer>
                <TextInput
                  refs={passwordFieldRef}
                  id="password"
                  name="setPassword"
                  label={t('password')}
                  type={showPassword ? 'text' : 'password'}
                  onChange={e => onTextChange(e)}
                  onFocus={() => setPasswordIsFocused(true)}
                  onBlur={() => setPasswordIsFocused(false)}
                  value={password}
                />
                <ShowPasswordIcon
                  src={showPassword ? hidePassword : viewPassword}
                  alt=""
                  active={passwordIsFocused}
                  onClick={e => toggleShowPassword(e)}
                />
              </AuthInputContainer>
              <ForgotPasswordDiv
                alert={
                  saveErrors &&
                  Object.keys(saveErrors).length !== 0 &&
                  (saveErrors.email || saveErrors.password)
                }
              >
                {saveErrors &&
                  Object.keys(saveErrors).length !== 0 &&
                  (saveErrors.email || saveErrors.password) && (
                    <AuthFormAlert>
                      {saveErrors.email
                        ? saveErrors.email
                        : saveErrors.password}
                    </AuthFormAlert>
                  )}
                <ForgotPasswordText to="/auth/forgot-password">
                  {t('forgot_password?')}
                </ForgotPasswordText>
              </ForgotPasswordDiv>
              <AuthSubmitButton
                type="submit"
                disabled={!email || !password}
                isDisabled={!email || !password}
              >
                {auth.isLoading ? (
                  <ButtonTextLoader loadingText={t('sign_in')} />
                ) : (
                  t('sign_in')
                )}
              </AuthSubmitButton>
              {/* <GoogleSignInButton>
              <GoogleIcon src={GoogleLogo} alt="" />
              <GoogleButtonText>Sign In With Google</GoogleButtonText>
            </GoogleSignInButton> */}
            </AuthForm>
          </AuthCard>
        </AuthPageRightContainer>
      </AuthPageContainer>
    );
  }
};

export default Login;
