import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Plus from '../../assets/img/icons/plus_white.svg';
import FilterDropDownStyle from '../../components/DropdownStyle/FilterDropDownStyle';
import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import BottomPagination from '../../components/Pagination/BottomPagination/BottomPagination';
import TopPagination from '../../components/Pagination/TopPagination/TopPagination';
import Select from '../../components/ReactSelectDropdown';
import SearchWithButton from '../../components/Search/SearchWithButton';
import {
  ProjectRightButtonContainer,
  TabSearchGrid,
} from '../../styledComponents/ProjectsNew';
import {
  ButtonIcon,
  ColoredButtonWithIcon,
 
} from '../../styledComponents/buttons';
import {
  Container,
  FilterLabel,
  HeaderContainer,
  InputWithLabelSection,
  PageTitle,
} from '../../styledComponents/common';
import WorkOrderDeleteModal from './WorkOrderDeleteModal';
import WorkOrdersList from './WorkOrderList';
import { ProjectListContainer } from '../Projects/projectsStyles';

const WorkOrder = props => {

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [workOrderDeleteId, setWorkOrderDeleteId] = useState('');

  const [clientOptions, setClientOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  const [expandedWorkOrderId, setExpandedWorkOrderId] = useState(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState('');

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const { t } = useTranslation();

  const {
    selectedOrganization,
    getClientShortList,
    clientShortList,
    history,   
    // WorkOrder
    WorkOrderList,
    getWorkOrderListIsLoading,
    getWorkOrderDuplicateListIsLoading,
    pageSizeWorkOrder,
    totalWorkOrderCount,
    getWorkOrderRequest,
    deleteWorkOrderRequest,
    getDuplicateWorkOrderequest,
    duplicateWorkOrderId,
    //Billing and Budgeting
    getWorkOrderBudgetAndBillable,
    workOrderBudgetAndBillable,
    workOrderBudgetAndBillableIsLoading,
  } = props;



  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      setCurrentPageNumber(1);
      getClientShortList({ organization_id: selectedOrganization.id });
      getWorkOrderRequest({ organization_id: selectedOrganization.id });
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (clientShortList && clientShortList.length > 0) {
      const options = [];
      clientShortList.map(item => {
        options.push({
          value: item.id,
          label: item.name,
        });
      });
      // options.unshift({ label: 'All Clients', value: '' });
      setClientOptions(options);
    } else if (clientShortList && clientShortList.length === 0) {
      setClientOptions([]);
    }
  }, [clientShortList]);

  useMemo(() => {
    if (selectedOrganization && selectedOrganization.id && expandedWorkOrderId) {
      const payload = {
        organization_id: selectedOrganization.id,
        workorder_id: expandedWorkOrderId,
      };

      // getProjectBudgetAndBillable(payload);
      getWorkOrderBudgetAndBillable(payload)
    }
  }, [expandedWorkOrderId]);

  const handleSearchByName = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        page: 1,
      };
      if (searchTerm && searchTerm.trim()) {
        payload.name = searchTerm.toLowerCase().trim();
        setCurrentSearchedTerm(searchTerm);
      } else {
        setCurrentSearchedTerm('');
      }
      if (selectedClient && selectedClient.value) {
        payload.client_id = selectedClient.value;
      }

      getWorkOrderRequest(payload);

      setCurrentPageNumber(1);
    }
  };
  const handleClearSearch = () => {
    if (
      currentSearchedTerm &&
      selectedOrganization &&
      selectedOrganization.id
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        page: 1,
      };
      if (selectedClient && selectedClient.value) {
        payload.client_id = selectedClient.value;
      }
      getWorkOrderRequest(payload);

      setCurrentPageNumber(1);
    }
    setTimeout(() => {
      setSearchTerm('');
      setCurrentSearchedTerm('');
    }, 100);
  };

  const onClientSelect = e => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        page: 1,
      };
      if (searchTerm && searchTerm.trim()) {
        payload.name = searchTerm.toLowerCase().trim();
      }
      if (e && e.value) {
        payload.client_id = e.value;
        setSelectedClient(e);
      } else {
        setSelectedClient(null);
      }
      getWorkOrderRequest(payload);

      setCurrentPageNumber(1);
    }
  };

  const handlePageChange = page => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        page: page,
      };
      if (searchTerm && searchTerm.trim()) {
        payload.name = searchTerm.toLowerCase().trim();
      }
      if (selectedClient && selectedClient.value) {
        payload.client_id = selectedClient.value;
      }
      getWorkOrderRequest(payload);
    }
    setCurrentPageNumber(page);
  };

  const selectProjectType = value => {
    if (selectedOrganization && selectedOrganization.id) {
      setCurrentPageNumber(1);
      const payload = {
        organization_id: selectedOrganization.id,
        is_active: value,
      };
      if (selectedClient && selectedClient.value) {
        payload.client_id = selectedClient.value;
      }
      getWorkOrderRequest(payload);
    }
    setSearchTerm('');
    setCurrentSearchedTerm('');
    setWorkOrderType(value);
  };

  const onSearchTermChange = e => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const handleDeleteProject = () => {
    if (selectedOrganization && selectedOrganization.id && workOrderDeleteId) {
      const payload = {
        organization_id: selectedOrganization.id,
        workorder_id: workOrderDeleteId,
      };
      deleteWorkOrderRequest(payload);
      setTimeout(() => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
      }, 300);
    }
  };

 

  const toggleDeleteModal = workorderId => {
    if (!isDeleteModalOpen) {
      setWorkOrderDeleteId(workorderId);
    }
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  return (
    <div className="content">
      
      <WorkOrderDeleteModal
        isOpen={isDeleteModalOpen}
        toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        handleDelete={handleDeleteProject}
        isLoading={props.workOrderDeleteLoading}
      />

      <PageTitle>{t('work_orders')}</PageTitle>
      <HeaderContainer>
        <TabSearchGrid columns="230px 280px">
          {selectedOrganization && selectedOrganization.role !== 'member' && (
            <InputWithLabelSection>
              <FilterLabel>{t('customer')}</FilterLabel>
              <Select
                value={selectedClient}
                options={clientOptions}
                onChange={e => onClientSelect(e)}
                placeholder="Select Customer..."
                styles={FilterDropDownStyle({ height: '40px' })}
              />
            </InputWithLabelSection>
          )}

          <SearchWithButton
            itemName=""
            searchTermName="work order"
            searchInput={searchTerm}
            onInputChange={onSearchTermChange}
            handleSearch={handleSearchByName}
            handleClearSearch={handleClearSearch}
          />
        </TabSearchGrid>
        {selectedOrganization && selectedOrganization.role !== 'member' && (
          <ProjectRightButtonContainer>
            <ColoredButtonWithIcon
              onClick={() => history.push('/user/create-work-order')}
            >
              <ButtonIcon src={Plus} alt="" />
              Create Work Order
            </ColoredButtonWithIcon>
          </ProjectRightButtonContainer>
        )}
      </HeaderContainer>
      {totalWorkOrderCount && totalWorkOrderCount > 0 && pageSizeWorkOrder ? (
        <TopPagination
          itemName="Work Orders"
          totalCount={totalWorkOrderCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={pageSizeWorkOrder}
        />
      ) : null}

      {getWorkOrderListIsLoading ? (
        <Container>
          <ComponentCircleLoader />
        </Container>
      ) : (
        <ProjectListContainer>
          <WorkOrdersList
            selectedOrganization={selectedOrganization}
            isLoading={getWorkOrderListIsLoading}
            searchTerm={searchTerm}
            selectedClient={selectedClient}
            WorkOrderList={WorkOrderList}
            history={history}
            toggleDeleteModal={toggleDeleteModal}
            duplicateWorkOrder = {getDuplicateWorkOrderequest}
            duplicateWorkOrderIsLoading={getWorkOrderDuplicateListIsLoading}
            duplicateProjectId={duplicateWorkOrderId}
            getProjectBudgetAndBillable={getWorkOrderBudgetAndBillable}
            workOrderBudgetAndBillableIsLoading={
              workOrderBudgetAndBillableIsLoading
            }
            workOrderBudgetAndBillable={workOrderBudgetAndBillable}
            expandedWorkOrderId={expandedWorkOrderId}
            setExpandedWorkOrderId={setExpandedWorkOrderId}
          />
        </ProjectListContainer>
      )}
      {totalWorkOrderCount && totalWorkOrderCount > 0 && pageSizeWorkOrder ? (
        <BottomPagination
          currentPage={currentPageNumber}
          totalCount={totalWorkOrderCount}
          onPageChange={page => handlePageChange(page)}
          pageSize={pageSizeWorkOrder}
        />
      ) : null}
    </div>
  );
};

export default WorkOrder;
