import styled, { css } from "styled-components";
import { BillingTooltip } from "../../styledComponents/createProject";

export const DashboardPageHeader = styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px) {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  @media screen and (max-width: 620px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const TitleAndModeSelectorDiv = styled.div`
  display: flex;
  align-items: center;
`;

// date range sections
export const DateRangeContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.main.white};
  border: 1px solid ${({ theme }) => theme.colors.main.blue};
  border-radius: ${({ theme }) => theme.borderRadii.card};
  padding: 5px 10px;
  width: max-content;
  justify-self: flex-end;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 10px;
  @media screen and (max-width: 768px) {
    margin-top: 20px;
  }
`;

export const DateRangeTooltip = styled.div`
  position: relative;
  border-radius: 50%;
  color: ${({ color, theme }) => color || theme.colors.main.white};

  background-color: ${({ bgColor, theme }) =>
    bgColor || theme.colors.main.blue};
  font-size: 11px;
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  ${BillingTooltip} {
    display: none;
  }
  &:hover {
    ${BillingTooltip} {
      display: block;
    }
  }
`;

export const DateRangeDiv = styled.div`
  text-align: right;
  @media screen and (max-width: 768px) {
    margin-top: 15px;
    text-align: left;
  }
`;
export const DateRangeText = styled.span`
  font-size: 15px;
  color: ${({ theme }) => theme.colors.main.labelText};
`;

export const ModeSelectDiv = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.main.primary : theme.colors.main.white};
  border: 1px solid
    ${({ active, theme }) =>
      active ? theme.colors.main.primary : theme.colors.main.white};
  border-radius: 6px;
  padding: 8px;
  cursor: pointer;
  &:hover {
    border-color: ${({ theme }) => theme.colors.border.primary};
  }
`;
export const ModeSelectIcon = styled.img`
  height: 12px;
  width: 12px;
  margin-right: 7px;
`;
export const ModeSelectText = styled.span`
  color: ${({ active, theme }) =>
    active ? theme.colors.main.white : theme.colors.main.textMain};
  font-size: 12px;
`;

export const DashboardPageContent = styled.div`
  margin-top: ${({ marginTop }) => marginTop || "40px"};
`;
export const DashboardPerformanceReportContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: 10px;
  background: #fff;
  box-shadow: none;
  display: grid;
  grid-template-columns: ${({ columns }) => columns || "1fr 1fr"};
  column-gap: ${({ gutter }) => gutter || "2%"};
  row-gap: 20px;
  padding: ${({ padding }) => padding || "30px"};
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop};
    `}
  @media screen and (max-width: 1366px) {
    grid-template-columns: ${({ laptopColumns, columns }) =>
      laptopColumns ? laptopColumns : columns ? columns : "auto auto"};
  }
`;

export const DashboardCardContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || "49.5% 49.5%"};
  grid-column-gap: ${({ columnGap }) => columnGap || "1%"};
  grid-row-gap: 20px;
  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop};
    `}
  @media screen and (max-width: 1366px) {
    grid-template-columns: ${({ laptopColumns, columns }) =>
      laptopColumns ? laptopColumns : columns ? columns : "auto auto"};
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: ${({ tabletColumns, laptopColumns, columns }) =>
      tabletColumns
        ? tabletColumns
        : laptopColumns
        ? laptopColumns
        : columns
        ? columns
        : "auto auto"};
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: ${({
      mobileColumns,
      tabletColumns,
      laptopColumns,
      columns,
    }) =>
      mobileColumns
        ? mobileColumns
        : tabletColumns
        ? tabletColumns
        : laptopColumns
        ? laptopColumns
        : columns
        ? columns
        : "auto auto"};
  }
`;

export const SmallCardContainer = styled.div``;
export const SmallCardLabel = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.main.labelText};
  margin-left: 10px;
  margin-top: 10px;
  @media screen and (max-width: 1400px) {
    margin-top: 8px;
  }
`;
