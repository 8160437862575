import {
  GoogleMap,
  InfoWindow,
  Marker,
  Polyline,
} from '@react-google-maps/api';
import moment from 'moment-timezone';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import checkedIn from '../../../../assets/img/icons/checked-in.svg';
import checkedOut from '../../../../assets/img/icons/checked-out.svg';
import clockedIn from '../../../../assets/img/icons/clocked-in.svg';
import clockedOut from '../../../../assets/img/icons/clocked-out.svg';
import routeEnd from '../../../../assets/img/icons/route-end.svg';
import routeMarker from '../../../../assets/img/icons/route-marker.svg';
import routeStart from '../../../../assets/img/icons/route-start.svg';
import {
  CommonFlex,
  CommonText,
  DividerLine,
  LabelWithTextSection,
} from '../../../../styledComponents/common';
import { MarkerInfoContainer, TimeInfoDiv } from '../routeMapStyles';

const options = {
  streetViewControl: false,
  fullscreenControl: false,
  mapTypeControl: false,
  disableDoubleClickZoom: true,
  gestureHandling: 'cooperative',
  mapId: process.env.REACT_APP_JOB_SITES_MAP_ID,
};

const containerStyle = {
  width: '100%',
  height: '80vh',
  // borderLeft: "1px solid #e4e7eb",
  borderRadius: '10px',
  // marginTop: "10px",
};
const center = {
  lat: 21.424090674479555,
  lng: 39.822141261515604,
};

const routeColors = ['#6fb1fc', '#ff9999', '#82e0aa', '#b19cd9'];

const getMarkerIcon = marker => {
  const icon = {
    url: marker.start ? routeStart : marker.end ? routeEnd : marker.icon,
  };
  if (marker.start || marker.end) {
    icon.size = new window.google.maps.Size(20, 20);
    icon.anchor = new window.google.maps.Point(10, 10);
  }

  return icon;
};

const ClockInOutMarker = props => {
  const { marker, activeClockInOutMarker, setActiveClockInOutMarker } = props;

  const { t } = useTranslation();

  const handleMarkerClick = () => {
    if (
      !activeClockInOutMarker ||
      (activeClockInOutMarker && activeClockInOutMarker !== marker.timestamp)
    ) {
      setActiveClockInOutMarker(marker.timestamp);
    } else {
      setActiveClockInOutMarker(null);
    }
  };

  return (
    <Marker
      key={marker.timestamp}
      icon={{
        url: marker.event === 'clock_in' ? clockedIn : clockedOut,
        anchor: new window.google.maps.Point(15, 15),
      }}
      position={marker.location}
      onClick={() => handleMarkerClick()}
    >
      {activeClockInOutMarker &&
        activeClockInOutMarker === marker.timestamp && (
          <InfoWindow onCloseClick={() => setActiveClockInOutMarker(null)}>
            <MarkerInfoContainer>
              <LabelWithTextSection padding="10px 15px 15px" gap="8px">
                <CommonText $label fontSize="12px" fontWeight="500">
                  {t(marker.event)}
                </CommonText>
                <TimeInfoDiv>
                  {moment.unix(marker.timestamp).format('h:mm A')}
                </TimeInfoDiv>
              </LabelWithTextSection>
            </MarkerInfoContainer>
          </InfoWindow>
        )}
    </Marker>
  );
};

const TaskMarker = props => {
  const { marker, activeTaskMarker, setActiveTaskMarker } = props;

  const { t } = useTranslation();

  const handleMarkerClick = () => {
    if (
      !activeTaskMarker ||
      (activeTaskMarker && activeTaskMarker !== marker.timestamp)
    ) {
      setActiveTaskMarker(marker.timestamp);
    } else {
      setActiveTaskMarker(null);
    }
  };

  return (
    <Marker
      key={marker.timestamp}
      icon={{
        url: marker.event === 'checkin' ? checkedIn : checkedOut,
        anchor: new window.google.maps.Point(15, 1),
      }}
      position={marker.location}
      onClick={() => handleMarkerClick()}
    >
      {activeTaskMarker && activeTaskMarker === marker.timestamp && (
        <InfoWindow onCloseClick={() => setActiveTaskMarker(null)}>
          <MarkerInfoContainer>
            <LabelWithTextSection padding="10px 15px 15px" gap="8px">
              <CommonText $label fontSize="12px" fontWeight="500">
                {marker.event === 'checkin'
                  ? t('checked_in')
                  : t('checked_out')}
              </CommonText>
              <TimeInfoDiv>
                {moment.unix(marker.timestamp).format('h:mm A')}
              </TimeInfoDiv>
            </LabelWithTextSection>
          </MarkerInfoContainer>
        </InfoWindow>
      )}
    </Marker>
  );
};

const RouteMapMarker = props => {
  const { marker, timesheet, activeMarkerId, setActiveMarkerId } = props;

  const handleMarkerClick = () => {
    if (!activeMarkerId || (activeMarkerId && activeMarkerId !== marker.id)) {
      setActiveMarkerId(marker.id);
    } else {
      setActiveMarkerId(null);
    }
  };

  return (
    <Marker
      key={marker.id}
      // icon={getMarkerIcon(marker)}
      icon={
        marker.icon
          ? {
              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              ...marker.icon,
            }
          : null
      }
      position={marker.location}
      onClick={() => handleMarkerClick()}
    >
      {activeMarkerId && activeMarkerId === marker.id && (
        <InfoWindow onCloseClick={() => setActiveMarkerId(null)}>
          <MarkerInfoContainer>
            <LabelWithTextSection padding="10px 15px 15px" gap="8px">
              <CommonText $label fontSize="12px" fontWeight="500">
                {moment.unix(marker.timestamp).format('ddd, MMMM DD, YYYY')}
              </CommonText>
              <TimeInfoDiv>
                {moment.unix(marker.timestamp).format('h:mm A')}
              </TimeInfoDiv>
            </LabelWithTextSection>
          </MarkerInfoContainer>
        </InfoWindow>
      )}
    </Marker>
  );
};

const RouteMapGoogleMap = props => {
  const [activeMarkerId, setActiveMarkerId] = useState(null);
  const [activeClockInOutMarker, setActiveClockInOutMarker] = useState(null);
  const [activeTaskMarker, setActiveTaskMarker] = useState(null);

  const {
    markers,
    // linePoints,
    routes,
    setMap,
    map,
    // selectedTimesheet,
    taskMarkers,
    clockInOutMarkers,
  } = props;

  useMemo(() => {
    if (map && routes && routes.length) {
      const bounds = new window.google.maps.LatLngBounds();
      routes.forEach(route => {
        let points = JSON.parse(JSON.stringify(route.points));
        points.forEach(line => {
          const position = new window.google.maps.LatLng(
            parseFloat(line.lat),
            parseFloat(line.lng),
          );
          bounds.extend(position);
        });
      });

      map.fitBounds(bounds);
    }
  }, [map, routes]);

  return (
    <GoogleMap
      options={{
        ...options,
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.LEFT_BOTTOM,
        },
      }}
      mapContainerStyle={containerStyle}
      center={center}
      zoom={13}
      onLoad={map => setMap(map)}
      clickableIcons={false}
    >
      {markers && markers.length
        ? markers.map(marker => {
            return (
              <RouteMapMarker
                marker={marker}
                // timesheet={selectedTimesheet}
                activeMarkerId={activeMarkerId}
                setActiveMarkerId={setActiveMarkerId}
              />
            );
          })
        : null}
      {clockInOutMarkers && clockInOutMarkers.length
        ? clockInOutMarkers.map(marker => {
            return (
              <ClockInOutMarker
                marker={marker}
                activeClockInOutMarker={activeClockInOutMarker}
                setActiveClockInOutMarker={setActiveClockInOutMarker}
              />
            );
          })
        : null}
      {taskMarkers && taskMarkers.length
        ? taskMarkers.map(marker => {
            return (
              <TaskMarker
                marker={marker}
                activeTaskMarker={activeTaskMarker}
                setActiveTaskMarker={setActiveTaskMarker}
              />
            );
          })
        : null}
      {/* {linePoints && linePoints.length ? (
        <Polyline
          path={linePoints}
          options={{
            geodesic: false,
            strokeColor: '#4d94fb',
            strokeOpacity: 1.0,
            strokeWeight: 6,
          }}
        />
      ) : null} */}
      {routes && routes.length > 0
        ? routes.map((route, index) => {
            if (route.points && route.points.length > 0) {
              return (
                <Polyline
                  key={index}
                  path={route.points}
                  options={{
                    geodesic: false,
                    strokeColor:
                      routeColors[index % routeColors.length] || routeColors[0],
                    strokeOpacity: 1.0,
                    strokeWeight: 6,
                  }}
                />
              );
            }
            return null;
          })
        : null}
    </GoogleMap>
  );
};

export default RouteMapGoogleMap;
