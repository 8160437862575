import queryString from 'query-string';
import { useEffect, useState } from 'react';

import { defaultApi } from '../../utils/axiosApi';
import FilterDropDownStyle from '../DropdownStyle/FilterDropDownStyle';
import PaginatedDropdown from '../ReactSelectPaginatedDropdown';

const getJobOptions = async (search, prevOptions, page, pageSize, url) => {
  try {
    if (url) {
      const queryParams = { page };
      if (search.trim()) {
        queryParams.name = search;
      }
      const finalUrl = queryString.stringifyUrl({ url, query: queryParams });
      const response = await defaultApi(finalUrl, 'GET');
      const { status, data } = response;
     
      const options = [];
      let hasMore = false;

      if (status === 200 && data && data.length > 0) {
        data.forEach(item => {
          options.push({
            value: item.id,
            label: item.name,
            workorder_id: item.work_order,
          });
        });
        // if (data.next) {
        //   hasMore = true;
        // }
      }
      
      return { options, hasMore };
    }
  } catch (err) {
    // console.log(err);
    return {
      options: [],
      hasMore: false,
    };
  }
};

const JobDropdown = props => {
  const [requestUrl, setRequestUrl] = useState('');
  const {
    selectedOrganization,
    selectedJob,
    selectJob,
    selectedMember,
    style,
  } = props;

  // useEffect(() => {
  //   if (
  //     selectedOrganization &&
  //     selectedOrganization.id
  //   ) {
  //     const url = `${selectedOrganization.id}/workorder/jobs/all/short/?scheduled=True`;
  //     if (selectedMember && selectedMember.id) {
  //       setRequestUrl(
  //         queryString.stringifyUrl({
  //           url,
  //           query: { assignees: selectedMember.id },
  //         })
  //       );
  //     } else {
  //       setRequestUrl(url);
  //     }
  //   }
  // }, [selectedOrganization]);

  // useEffect(() => {
  //   if (requestUrl) {
  //     if (selectedMember && selectedMember.id) {
  //       setRequestUrl(
  //         queryString.stringifyUrl({
  //           url: requestUrl,
  //           query: { assignees: selectedMember.id },
  //         })
  //       );
  //     } else {
  //       const parsedUrl = queryString.parseUrl(requestUrl);
  //       setRequestUrl(parsedUrl.url);
  //     }
  //   }
  // }, [selectedMember]);
  useEffect(() => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      selectedMember &&
      selectedMember.id
    ) {
      let url = `${selectedOrganization.id}/workorder/jobs/all/short/`;

      url = queryString.stringifyUrl({
        url,
        query: { assignees: selectedMember.id,
                 multiple_status:'ready to dispatch,missed,completed'        
        },
      });

      setRequestUrl(url);
    }
  }, [selectedOrganization, selectedMember]);

  return (
    <PaginatedDropdown
      isSearchable
      isClearable
      //   isDisabled={
      //     !selectedProject || (selectedProject && !selectedProject.value)
      //   }
      placeholder="Select Job..."
      value={selectedJob}
      url={requestUrl}
      getOptions={getJobOptions}
      onChange={e => selectJob(e)}
      styles={style || FilterDropDownStyle({ minHeight: '40px' })}
    />
  );
};

export default JobDropdown;
