import moment from 'moment';

import {
  CLEAR_ONE_TIME_PAYMENT_DATA,
  EXPORT_PAYABLES_DATA,
  EXPORT_PAYABLES_DATA_FAILURE,
  EXPORT_PAYABLES_DATA_SUCCESS,
  GET_HOURLY_PAYMENT_HISTORY,
  GET_HOURLY_PAYMENT_HISTORY_FAILURE,
  GET_HOURLY_PAYMENT_HISTORY_SUCCESS,
  GET_ONETIME_PAYMENT_HISTORY,
  GET_ONETIME_PAYMENT_HISTORY_FAILURE,
  GET_ONETIME_PAYMENT_HISTORY_SUCCESS,
  GET_PAYABLES_DATA,
  GET_PAYABLES_DATA_FAILURE,
  GET_PAYABLES_DATA_SUCCESS,
  LOGIN_PROFILE_CREATED_RESET,
  SEND_HOURLY_PAYMENT,
  SEND_HOURLY_PAYMENT_FAILURE,
  SEND_HOURLY_PAYMENT_SUCCESS,
  SEND_ONE_TIME_PAYMENT,
  SEND_ONE_TIME_PAYMENT_FAILURE,
  SEND_ONE_TIME_PAYMENT_SUCCESS,
} from '../../modules/constants';
import { createNotification } from '../../modules/notificationManager';

// ------------------------------------
// Action handler methods
// ------------------------------------

export const handlePayablesDataRequest = (state, action) => {
  return {
    ...state,
    payablesIsLoading: true,
  };
};
export const handlePayablesDataSuccess = (state, action) => {
  let newData = [...action.payload.data.data];
  // eslint-disable-next-line
  newData.map(item => {
    item.checked = false;
    item.paid = false;
  });
  return {
    ...state,
    payablesIsLoading: false,
    payablesData: newData,
  };
};
export const handlePayablesDataFailure = (state, action) => {
  createNotification('error', 'Please try again!', 3000);
  return {
    ...state,
    payablesIsLoading: false,
    payablesData: null,
  };
};
export const handleExportPayablesDataRequest = (state, action) => {
  return {
    ...state,
    payablesIsLoading: true,
  };
};
export const handleExportPayablesDataSuccess = (state, action) => {
  const {
    payload: { data, start, end },
  } = action;

  if (data.status === 200) {
    try {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `payroll_${moment(start).format('D_MM_YYYY')}_${moment(end).format(
          'D_MM_YYYY',
        )}.csv`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log(
        '🚀 ~ file: payrollReducer.js:82 ~ handleExportPayablesDataSuccess ~ error:',
        error,
      );
    }
  }
  return {
    ...state,
    payablesIsLoading: false,
  };
};
export const handleExportPayablesDataFailure = (state, action) => {
  createNotification('error', 'Please try again!', 3000);
  return {
    ...state,
    payablesIsLoading: false,
  };
};

export const handleOneTimePaymentRequest = (state, action) => {
  return {
    ...state,
    paymentIsLoading: true,
  };
};
export const handleOneTimePaymentRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    paymentIsLoading: false,
    oneTimePaymentData: data.data,
  };
};
export const handleOneTimePaymentRequestFailure = (state, action) => {
  createNotification('error', 'Please try again!', 3000);
  return {
    ...state,
    paymentIsLoading: false,
    oneTimePaymentData: null,
  };
};

export const handleSendHourlyPaymentRequest = (state, action) => {
  return {
    ...state,
    paymentIsLoading: true,
  };
};
export const handleSendHourlyPaymentRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    if (
      data.data &&
      data.data.successful_payments &&
      data.data.successful_payments.length > 0
    ) {
      const payables = [...state.payablesData];
      return {
        ...state,
        paymentIsLoading: false,
        payablesData: payables.filter(payable => {
          return data.data.successful_payments.every(payment => {
            return payment.id !== payable.id;
          });
        }),
      };
    }
  }
  return {
    ...state,
    paymentIsLoading: false,
  };
};
export const handleSendHourlyPaymentRequestFailure = (state, action) => {
  createNotification('error', 'An error occured. Please try again!', 3000);
  return {
    ...state,
    paymentIsLoading: false,
  };
};

export const handleHourlyPaymentHistoryRequest = (state, action) => {
  return {
    ...state,
    paymentHistoryIsLoading: true,
  };
};
export const handleHourlyPaymentHistoryRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      paymentHistoryIsLoading: false,
      hourlyPaymentHistory: data.data,
    };
  }
};
export const handleHourlyPaymentHistoryRequestFailure = (state, action) => {
  createNotification('error', 'Please try again!', 3000);
  return {
    ...state,
    paymentHistoryIsLoading: false,
    hourlyPaymentHistory: [],
  };
};

export const handleOnetimePaymentHistoryRequest = (state, action) => {
  return {
    ...state,
    paymentHistoryIsLoading: true,
  };
};
export const handleOnetimePaymentHistoryRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      paymentHistoryIsLoading: false,
      onetimePaymentHistory: data.data,
    };
  }
};
export const handleOnetimePaymentHistoryRequestFailure = (state, action) => {
  createNotification('error', 'Please try again!', 3000);
  return {
    ...state,
    paymentHistoryIsLoading: false,
    onetimePaymentHistory: [],
  };
};

export const clearOneTimePaymentDataRequest = (state, action) => {
  return {
    ...state,
    oneTimePaymentData: null,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_PAYABLES_DATA]: handlePayablesDataRequest,
  [GET_PAYABLES_DATA_SUCCESS]: handlePayablesDataSuccess,
  [GET_PAYABLES_DATA_FAILURE]: handlePayablesDataFailure,

  [EXPORT_PAYABLES_DATA]: handleExportPayablesDataRequest,
  [EXPORT_PAYABLES_DATA_SUCCESS]: handleExportPayablesDataSuccess,
  [EXPORT_PAYABLES_DATA_FAILURE]: handleExportPayablesDataFailure,

  [SEND_ONE_TIME_PAYMENT]: handleOneTimePaymentRequest,
  [SEND_ONE_TIME_PAYMENT_SUCCESS]: handleOneTimePaymentRequestSuccess,
  [SEND_ONE_TIME_PAYMENT_FAILURE]: handleOneTimePaymentRequestFailure,

  [SEND_HOURLY_PAYMENT]: handleSendHourlyPaymentRequest,
  [SEND_HOURLY_PAYMENT_SUCCESS]: handleSendHourlyPaymentRequestSuccess,
  [SEND_HOURLY_PAYMENT_FAILURE]: handleSendHourlyPaymentRequestFailure,

  [GET_HOURLY_PAYMENT_HISTORY]: handleHourlyPaymentHistoryRequest,
  [GET_HOURLY_PAYMENT_HISTORY_SUCCESS]:
    handleHourlyPaymentHistoryRequestSuccess,
  [GET_HOURLY_PAYMENT_HISTORY_FAILURE]:
    handleHourlyPaymentHistoryRequestFailure,

  [GET_ONETIME_PAYMENT_HISTORY]: handleOnetimePaymentHistoryRequest,
  [GET_ONETIME_PAYMENT_HISTORY_SUCCESS]:
    handleOnetimePaymentHistoryRequestSuccess,
  [GET_ONETIME_PAYMENT_HISTORY_FAILURE]:
    handleOnetimePaymentHistoryRequestFailure,

  [CLEAR_ONE_TIME_PAYMENT_DATA]: clearOneTimePaymentDataRequest,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  paymentIsLoading: false,
  payablesIsLoading: false,
  paymentHistoryIsLoading: false,
  payablesData: null,
  oneTimePaymentData: null,
  hourlyPaymentHistory: [],
  onetimePaymentHistory: [],
};

export default function paymentReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
