import { Fragment, useState, useEffect } from "react";
import Select from "../../components/ReactSelectDropdown";
import FilterDropDownStyle from "../../components/DropdownStyle/FilterDropDownStyle";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
import RoundImageNameComp from "../../components/RoundImageName/RoundImageNameComp";
import RecordPaymentModal from "./RecordPaymentModal";
import SendMailPopup from "./SendMailPopup";
import DeleteInvoicePopup from "./DeleteInvoicePopup";
import moment from "moment";
import { Container, TableText } from "../../styledComponents/common";
import {
  InvoiceTableContainer,
  TableItem,
  StatusBox,
} from "../../styledComponents/invoice";
import ComponentCircleLoader from "../../components/Loaders/ComponentCircleLoader";

import { currencySymbolMap } from "../../utils/currencies";

const InvoiceTable = (props) => {
  const [selectedActions, setSelectedActions] = useState("");
  const drafOptions = [
    { value: "editInvoice", label: "Edit Invoice" },
    { value: "sendMail", label: "Send Mail" },
    { value: "preview", label: "Preview Invoice" },
    { value: "deleteInvoice", label: "Delete Invoice" },
  ];
  const sentOptions = [
    { value: "recordPayment", label: "Record Payment" },
    { value: "sendMail", label: "Send Mail" },
    { value: "preview", label: "Preview Invoice" },
    { value: "deleteInvoice", label: "Delete Invoice" },
  ];
  const paidOptions = [
    { value: "preview", label: "Preview Invoice" },
    { value: "deleteInvoice", label: "Delete Invoice" },
  ];
  const [popupOpen, setPopupOpen] = useState(false);
  const [sendEmailOpen, setSendEmailOpen] = useState(false);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const {
    history,
    isLoading,
    invoiceList,
    selectedOrganization,
    recordPaymentLoading,
    recordPaymentInvoice,
    onRecordPay,
    deleteInvoice,
    onDelete,
    deleteInvoiceLoading,
    clientDetails,
    sendMailLoading,
    senderName,
    updateInvoiceSendLog,
    createInvoiceUpdateSendLog,
    sendInvoiceEmail,
    onSend,
    getClientDetails,
    selectedClient,
    selectedStatus,
  } = props;

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };

  const sendEmailToggle = () => {
    setSendEmailOpen(!sendEmailOpen);
  };

  const deletePopupToggle = () => {
    setDeletePopupOpen(!deletePopupOpen);
  };

  const onActionSelect = (e, invoiceId, clientId, index) => {
    if (e.value === "editInvoice") {
      history.push(`/user/edit-invoice?id=${invoiceId}`);
    } else if (e.value === "recordPayment") {
      popupToggle();
    } else if (e.value === "sendMail") {
      const payload = {
        organization_id: selectedOrganization.id,
        client_id: clientId,
      };
      getClientDetails(payload);
      sendEmailToggle();
    } else if (e.value === "preview") {
      history.push(`/user/invoice-preview?id=${invoiceId}`);
    } else if (e.value === "deleteInvoice") {
      deletePopupToggle();
    }
    setSelectedIndex(index);
  };

  const gotoPreview = (invoiceId) => {
    history.push(`/user/invoice-preview?id=${invoiceId}`);
  };

  console.log("selected organizaiton ", selectedOrganization);

  return (
    <Container style={{ paddingBottom: `20px` }}>
      {isLoading ? (
        <ComponentCircleLoader />
      ) : invoiceList && invoiceList.length > 0 ? (
        <>
          {selectedIndex >= 0 &&
            invoiceList[selectedIndex] &&
            invoiceList[selectedIndex].id &&
            selectedOrganization &&
            selectedOrganization.id && (
              <Fragment>
                <RecordPaymentModal
                  isOpen={popupOpen}
                  isLoading={recordPaymentLoading}
                  toggle={popupToggle}
                  invoice={invoiceList[selectedIndex]}
                  selectedOrganization={selectedOrganization}
                  recordPaymentInvoice={recordPaymentInvoice}
                  localCallback
                  onRecordPay={onRecordPay}
                />
                <DeleteInvoicePopup
                  isOpen={deletePopupOpen}
                  toggle={deletePopupToggle}
                  invoice_id={invoiceList[selectedIndex].id}
                  organization_id={selectedOrganization.id}
                  deleteInvoice={deleteInvoice}
                  onDelete={onDelete}
                  isLoading={deleteInvoiceLoading}
                />
              </Fragment>
            )}
          {selectedIndex >= 0 &&
            invoiceList[selectedIndex] &&
            invoiceList[selectedIndex].id &&
            selectedOrganization &&
            selectedOrganization.id &&
            clientDetails &&
            clientDetails.email && (
              <SendMailPopup
                isOpen={sendEmailOpen}
                isLoading={sendMailLoading}
                toggle={sendEmailToggle}
                organization_id={selectedOrganization.id}
                organization_name={selectedOrganization.name}
                invoice_id={invoiceList[selectedIndex].id}
                senderName={senderName}
                invoiceNumber={invoiceList[selectedIndex].invoice_number}
                issuedDate={invoiceList[selectedIndex].issued_date}
                dueDate={
                  invoiceList[selectedIndex].due_date
                    ? invoiceList[selectedIndex].due_date
                    : null
                }
                email={clientDetails.email}
                log={updateInvoiceSendLog}
                createInvoiceUpdateSendLog={createInvoiceUpdateSendLog}
                sendInvoiceEmail={sendInvoiceEmail}
                onSend={onSend}
              />
            )}

          <InvoiceTableContainer heading>
            <TableItem>
              <TableText>Invoice Number</TableText>
            </TableItem>
            <TableItem>
              <TableText>Client Name</TableText>
            </TableItem>
            <TableItem>
              <TableText>Issued Date</TableText>
            </TableItem>
            <TableItem>
              <TableText>Paid Amount / Total Amount</TableText>
            </TableItem>
            <TableItem>
              <TableText>Status</TableText>
            </TableItem>
            <TableItem>
              <TableText>Actions</TableText>
            </TableItem>
          </InvoiceTableContainer>

          {invoiceList.map((invoice, index) => (
            <Fragment key={index}>
              <InvoiceTableContainer key={index}>
                <TableItem>
                  <TableText
                    name
                    hoverUnderline
                    hoverPointer
                    onClick={() => gotoPreview(invoice.id)}
                  >
                    {invoice.invoice_number}
                  </TableText>
                </TableItem>
                <TableItem
                  buttonPadding
                  onClick={() =>
                    history.push(`/user/customer-details?id=${invoice.client_id}`)
                  }
                >
                  {/* <RoundImageNameComp
                      imgSource={null}
                      name={invoice.client_name}
                      index={index}
                      imageSize="36px"
                      size="14px"
                      fill
                    /> */}
                  <TableText name hoverPointer hoverUnderline>
                    {invoice.client_name}
                  </TableText>
                </TableItem>

                <TableItem>
                  <TableText name>
                    {moment(invoice.issued_date).format("MMM DD, YYYY")}
                  </TableText>
                </TableItem>
                <TableItem>
                  <TableText name>
                    <span style={{ whiteSpace: `nowrap` }}>{`${
                      currencySymbolMap[invoice.currency]
                    } ${invoice.paid_amount.toFixed(2)}`}</span>{" "}
                    /{" "}
                    <span style={{ whiteSpace: `nowrap` }}>{`${
                      currencySymbolMap[invoice.currency]
                    } ${invoice.discounted_amount.toFixed(2)}`}</span>
                  </TableText>
                </TableItem>
                <TableItem>
                  <StatusBox status={invoice.status}>
                    {invoice.status === "partial"
                      ? "Partially Paid"
                      : invoice.status}
                  </StatusBox>
                </TableItem>
                <TableItem buttonPadding>
                  <Select
                    isSearchable={false}
                    value={selectedActions}
                    options={
                      invoice.status === "draft"
                        ? drafOptions
                        : invoice.status === "paid"
                        ? paidOptions
                        : sentOptions
                    }
                    placeholder="Actions"
                    onChange={(e) => {
                      onActionSelect(e, invoice.id, invoice.client_id, index);
                    }}
                    styles={FilterDropDownStyle({
                      height: "39px",
                      width: `150px`,
                      menuWidth: `150px`,
                    })}
                  />
                </TableItem>
              </InvoiceTableContainer>
            </Fragment>
          ))}
        </>
      ) : (
        <NoDataComponent
          title={
            selectedClient && selectedClient.value
              ? "Sorry! no invoice found for this client"
              : selectedStatus && selectedStatus.value
              ? `No invoice found with status "${selectedStatus.label}"`
              : "You haven’t created any invoice yet."
          }
        />
      )}
    </Container>
  );
};

export default InvoiceTable;
