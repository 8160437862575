import React, { useTransition } from "react";
import { ProfileModalBody, ProfileModal, ModalDiv } from "./profileStyles";

import { CommonText, CommonFlex } from "../../styledComponents/common";
import { ColoredButton, WhiteButton } from "../../styledComponents/buttons";
import { useTranslation } from 'react-i18next'

const UserProfileDeleteModal = (props) => {
  const toggle = () => {
    props.toggle();
  };
  const confirmEmailDelete = () => {
    props.confirmEmailDelete();
  };
  const {t} = useTranslation();

  return (
    <ProfileModal
      isOpen={props.isOpen}
      toggle={toggle}
      confirmEmailDelete={confirmEmailDelete}
    >
      <ProfileModalBody>
        <ModalDiv email>
          <CommonText>{t('Are you sure you want to delete this Email?')}</CommonText>
          <CommonFlex margin="30px 0 0 auto" gap="12px">
            <WhiteButton type="cancel" padding="0 30px" onClick={toggle}>
              {t('Cancel')}
            </WhiteButton>
            <ColoredButton
              type="delete"
              padding="0 35px"
              onClick={() => confirmEmailDelete()}
            >
              {t('Delete')}
            </ColoredButton>
          </CommonFlex>
        </ModalDiv>
      </ProfileModalBody>
    </ProfileModal>
  );
};

export default UserProfileDeleteModal;
