import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import avatar5 from "../../assets/img/avatar_5.svg";
import ProjectDropdown from "../../components/CommonFilter/ProjectDropdown";
import AddSingleMemberDropdown from "../../components/CustomDropdown/AddSingleMemberDropdown";
import CreateProjectDropdown from "../../components/DropdownStyle/CreateProjectDropdown";
import FilterDropDownStyle from "../../components/DropdownStyle/FilterDropDownStyle";
import Select from "../../components/ReactSelectDropdown";
import { filterOption } from "../../components/ReactSelectDropdown/ReactSelectDropdown";
import SearchWithButton from "../../components/Search/SearchWithButton";
import DatePicker from "../../components/SingleDatePicker/SingleDatePicker";
import {
  CommonFlex,
  FilterLabel,
  IconArrow,
  InputField,
  InputWithLabelSection,
} from "../../styledComponents/common";
import { FilterContainer } from "../../styledComponents/tasks";

const JobFilter = (props) => {
  const { t } = useTranslation();
  const [officeOptions, setOfficeOptions] = useState([
    {
      label: t("all_offices"),
      value: null,
    },
  ]);
  const [workOrderOptions, setWorkOrderOptions] = useState([
    {
      label: t("all_workorders"),
      value: null,
    },
  ]);

  const [memberOptions, setMemberOptions] = useState([]);
  const statusOptions = [
    {
      label: t("all_status without unscheduled"),
      value: "all",
    },
    {
      value: "unscheduled",
      label: t("unscheduled"),
    },
    {
      value: "ready to dispatch",
      label: t("ready_to_dispatch"),
    },
    {
      value: "completed",
      label: t("completed"),
    },
    {
      value: "missed",
      label: t("missed"),
    },
  ];

  const {
    selectedOrganization,
    getOrganizationMembersList,
    organizationMembersList,
    selectedMember,
    selectProject,
    selectMember,
    clearMember,
    taskStatus,
    handleStatusChange,
    selectedDate,
    handleDateChange,
    handleSearchByName,
    handleClearSearch,
    searchTerm,
    onSearchTermChange,
    workOrderShortList,
    getWorkOrderShortList,
    selectedWorkOrder,
    setSelectedWorkOrder,
    selectWorkOrder,
    selectToday,
    singleDateChange,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      getWorkOrderShortList({ organization_id: selectedOrganization.id });
      getOrganizationMembersList({ organization_id: selectedOrganization.id });
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (workOrderShortList && workOrderShortList.length > 0) {
      let newArr = [
        {
          label: t("all_workorders"),
          value: null,
        },
      ];
      workOrderShortList.forEach((workorder, i) => {
        newArr.push({
          label: workorder.name,
          value: workorder.id,
        });
      });
      setWorkOrderOptions(newArr);
    }
  }, [workOrderShortList]);

  const onMemberSelect = (person) => {
    selectMember(person);
  };

  const clearSelectedMember = () => {
    clearMember();
  };

  // console.log('taskStatus?.value:', taskStatus?.value);
  return (
    <FilterContainer
      admin={
        selectedOrganization &&
        selectedOrganization.role &&
        (selectedOrganization.role === "admin" ||
          selectedOrganization.role === "owner")
      }
    >
      <InputWithLabelSection>
        <FilterLabel>{t("work_order")}</FilterLabel>
        <Select
          isSearchable
          value={selectedWorkOrder}
          options={workOrderOptions}
          placeholder="Select WorkOrder..."
          onChange={(e) => {
            selectWorkOrder(e);
          }}
          styles={CreateProjectDropdown()}
          filterOption={filterOption}
        />
      </InputWithLabelSection>

      {selectedOrganization &&
        selectedOrganization.role &&
        (selectedOrganization.role === "admin" ||
          selectedOrganization.role === "owner") && (
          <InputWithLabelSection>
            <FilterLabel>{t("assignee")}</FilterLabel>
            <div style={{ width: `40px` }}>
              <AddSingleMemberDropdown
                assigneeManage={selectedMember}
                updateState={onMemberSelect}
                membersList={organizationMembersList}
                clearAssignee={() => clearMember()}
              />
            </div>
          </InputWithLabelSection>
        )}

      <SearchWithButton
        itemName={t("job")}
        searchTermName={t("job_name")}
        searchInput={searchTerm}
        onInputChange={onSearchTermChange}
        handleSearch={handleSearchByName}
        handleClearSearch={handleClearSearch}
      />
      <InputWithLabelSection>
        <FilterLabel>{t("select_date")}</FilterLabel>
        <DatePicker
          id="createIssuedDate"
          // disabled={taskStatus?.value === 'unscheduled'}
          date={selectedDate}
          onDateChange={(date) => handleDateChange(date)}
          dateDisplayFormat={"DD/MM/YYYY"}
          placeholder={"Select date"}
          allowPreviousDates
        />
      </InputWithLabelSection>

      <InputWithLabelSection style={{ width: "115%" }}>
        <FilterLabel>{t("status")}</FilterLabel>
        <Select
          isSearchable={false}
          value={taskStatus}
          options={statusOptions}
          placeholder="Select Status..."
          onChange={(e) => {
            handleStatusChange(e);
          }}
          styles={FilterDropDownStyle({
            height: "40px",
            width: "150px",
            menuWidth: "130px",
          })}
        />
      </InputWithLabelSection>
    </FilterContainer>
  );
};

export default JobFilter;
