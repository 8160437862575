import { connect } from 'react-redux';

// Actions
import { getOrganizationMembersShortList } from '../../Projects/projectsActions';
import {
  editShift,
  editShiftAssigneesAdd,
  editShiftAssigneesRemove,
  getShiftDetails,
} from '../shiftScheduleActions';
// Component
import EditShift from './EditShift';

// Store props to map with current state
const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,
  orgMembersShortList: state.project.orgMembersShortList,
  memberShortListIsLoading: state.project.memberShortListIsLoading,

  detailsIsLoading: state.schedule.detailsIsLoading,
  editIsLoading: state.schedule.editIsLoading,
  editAssigneesIsLoading: state.schedule.editAssigneesIsLoading,
  
  shiftDetails: state.schedule.shiftDetails,
  editedAssigneeList: state.schedule.editedAssigneeList,
  overlap: state.schedule.overlap,
  editCallback: state.schedule.editCallback,
  jobShortList: state.jobs.jobShortList,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getOrganizationMembersShortList: params =>
    dispatch(getOrganizationMembersShortList(params)),
  getShiftDetails: params => dispatch(getShiftDetails(params)),
  editShiftAssigneesAdd: params => dispatch(editShiftAssigneesAdd(params)),
  editShiftAssigneesRemove: params => dispatch(editShiftAssigneesRemove(params)),
  editShift: params => dispatch(editShift(params)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(EditShift);
