import { connect } from 'react-redux';

// Actions
import {
  addClientLocation,
  getClientList,
  getClientLocation,
  getClientShortList,
  updateClientLocation,
} from '../../ClientsNew/clientsActions';
import {
  createJobRequest,
  createWorkOrderRequest,
  deleteWorkOrderRequest,
  getDuplicateWorkOrderequest,
  getJobsUnderWorkOrderRequest,
  getWorkOrder,
  updateJobRequest,
  updateWorkOrder,
} from '../workOrderActions';
import DetailsWorkOrder from './DetailsWorkOrder';

// Store props to map with current state
const mapStateToProps = state => ({
  clientList: state.client.clientList,
  clientShortList: state.client.clientShortList,
  selectedOrganization: state.organization.selectedOrganization,
  createWorkOrderIsLoading: state.workorder.createWorkOrderIsLoading,
  createOrUpdateJobIsLoading: state.workorder.createOrUpdateJobIsLoading,
  createWorkOrderIsLoading: state.workorder.createWorkOrderIsLoading,
  getJobsUnderWorkOrderIsLoading:
    state.workorder.getJobsUnderWorkOrderIsLoading,
  jobsUnderWorkOrderList: state.workorder.jobsUnderWorkOrderList,
  totalJobsCount: state.workorder.totalJobsCount,
  pageSizeJobs: state.workorder.pageSizeJobs,
  UpdateWorkOrderLoading: state.workorder.UpdateWorkOrderLoading,
  clientLocationList: state.client.clientLocationList,
  getclientLocalMapLoading: state.client.getclientLocalMapLoading,
  addOrUpdateclientLocalMapLoading:
    state.client.addOrUpdateclientLocalMapLoading,
  createdClientLocationInfo: state.client.createdClientLocationInfo,
  createdJobId: state.workorder.createdJobId,
  createdJob: state.workorder.createdJob,
  jobUpdate: state.workorder.jobUpdate,
  getWorkOrderIsLoading: state.workorder.getWorkOrderIsLoading,
  workOrderDetails: state.workorder.workOrderDetails,
  workOrderDeleteLoading: state.workorder.workOrderDeleteLoading,
  duplicateWorkOrderId: state.workorder.duplicateWorkOrderId,
  getWorkOrderDuplicateListIsLoading: state.workorder.getWorkOrderDuplicateListIsLoading,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getClientList: details => dispatch(getClientList({ ...details })),
  getClientShortList: details => dispatch(getClientShortList({ ...details })),
  createWorkOrderRequest: details =>
    dispatch(createWorkOrderRequest({ ...details })),
  createJobRequest: details => dispatch(createJobRequest({ ...details })),
  getJobsUnderWorkOrderRequest: details =>
    dispatch(getJobsUnderWorkOrderRequest({ ...details })),
  updateJobRequest: details => dispatch(updateJobRequest({ ...details })),
  updateWorkOrder: details => dispatch(updateWorkOrder({ ...details })),
  getClientLocation: details => dispatch(getClientLocation({ ...details })),
  addClientLocation: details => dispatch(addClientLocation({ ...details })),
  updateClientLocation: details =>
    dispatch(updateClientLocation({ ...details })),
  getWorkOrder: details => dispatch(getWorkOrder({ ...details })),
  deleteWorkOrderRequest: details =>
    dispatch(deleteWorkOrderRequest({ ...details })),
  getDuplicateWorkOrderequest: details =>
    dispatch(getDuplicateWorkOrderequest({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(DetailsWorkOrder);
