import { connect } from 'react-redux';

import { getOfficesShortList } from '../../Offices/officesActions';
import { getOrganizationMembersList } from '../../Projects/projectsActions';
import { getWorkOrderShortList } from '../../WorkOrderNew/workOrderActions';
import { updateJobApproval } from '../jobsActions';
import { getTasksList, updateTaskApproval } from '../tasksActions';
import { getJobShiftsList } from '../../ShiftSchedule/shiftScheduleActions';
// Component
import JobApproval from './JobApproval';

// Store props to map with current state
const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,
  orgMembersList: state.project.orgMembersList,
  officesShortList: state.office.officesShortList,
  duplicateIsLoading: state.task.duplicateIsLoading,
  deleteIsLoading: state.task.deleteIsLoading,
  deletedCallback: state.task.deletedCallback,
  tasksList: state.task.tasksList,
  createdTaskInfo: state.task.createdTaskInfo,
  duplicateTaskInfo: state.task.duplicateTaskInfo,
  isLoading: state.task.isLoading,
  loadingTaskAssigneeId: state.project.loadingTaskAssigneeId,
  // pagination
  totalTasksCount: state.task.tasksListCount,
  pageSize: state.task.taskListPageSize,
  // jobs
  isLoadingJobs: state.schedule.jobListIsLoading,
  totaljobsCount: state.schedule.totaljobsCount,
  pageSizeJob: state.schedule.pageSizeJob,
  jobList: state.schedule.jobShiftsList,
  //workordershort
  workOrderShortList: state.workorder.workOrderShortList,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getOrganizationMembersList: params =>
    dispatch(getOrganizationMembersList(params)),
  getTasksList: params => dispatch(getTasksList(params)),
  getOfficesShortList: params => dispatch(getOfficesShortList(params)),
  updateTaskApproval: params => dispatch(updateTaskApproval(params)),
  //
  getWorkOrderShortList: params => dispatch(getWorkOrderShortList(params)),
  getjobsList: params => dispatch(getJobShiftsList(params)),
  updateJobApproval: params => dispatch(updateJobApproval(params)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(JobApproval);
