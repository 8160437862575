import { connect } from "react-redux";

import AwaitingApprovals from "./AwaitingApprovals";

import {
  approveOrRejectTimesheets,
  clearLoadMoreTimesheetApprovalList,
} from "../timesheetApprovalActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  organizationMembersList: state.project.orgMembersShortList,

  awaitingList: state.timesheetApproval.awaitingList,

  // loading
  isLoading: state.timesheetApproval.isLoading,
  seeMoreIsLoading: state.timesheetApproval.seeMoreIsLoading,
  approveIsLoading: state.timesheetApproval.approveIsLoading,
  rejectIsLoading: state.timesheetApproval.rejectIsLoading,

  // pagination
  paginationSize: state.timesheetApproval.paginationSize,
  allLoadedDates: state.timesheetApproval.allLoadedDates,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  approveOrRejectTimesheets: (payload) =>
    dispatch(approveOrRejectTimesheets(payload)),
  clearLoadMoreTimesheetApprovalList: () =>
    dispatch(clearLoadMoreTimesheetApprovalList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AwaitingApprovals);
