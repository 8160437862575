import styled, { css } from 'styled-components';

export const CommonCardContainer = styled.div`
  border: 1px solid #c2cce1;
  border-radius: 10px;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1400px) {
    padding: 15px;
  }
`;

export const SmallCardContainer = styled.div`
  ${props => props?.gridColumn && `grid-column: ${props?.gridColumn};`}
  min-height: 155px;
  border: 1px solid #c2cce1;
  border-radius: 10px;
  background: #fff;
  padding: 20px;
  display: grid;
  align-content: space-between;
  @media screen and (max-width: 1366px) {
    grid-column: ${({ laptopGridColumn, gridColumn }) =>
      laptopGridColumn ? laptopGridColumn : gridColumn ? gridColumn : 'auto'};
  }
  @media screen and (max-width: 768px) {
    grid-column: ${({ tabletGridColumn, laptopGridColumn, gridColumn }) =>
      tabletGridColumn
        ? tabletGridColumn
        : laptopGridColumn
        ? laptopGridColumn
        : gridColumn
        ? gridColumn
        : 'auto'};
  }
`;
export const DataDiv = styled.div``;
export const DataDivHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const HourPercentageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const PercentageArrowIcon = styled.img`
  width: 10px;
  height: 14px;
`;
export const BarChartCanvasContainer = styled.div`
  height: ${({ height }) => height || '100px'};
  width: 100%;
  position: relative;
  overflow: hidden;
  ${({ small }) =>
    small &&
    css`
      @media screen and (min-width: 1400px) {
        width: 70%;
        margin-left: auto;
      }
    `}
`;

export const ColorCard = styled.div`
  height: ${({ height }) => height || '24px'};
  min-width: ${({ width }) => width || '24px'};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: ${({ borderRadius }) => borderRadius || '4px'};
  position: relative;
  color: #fff;
  font-size: ${({ fontSize }) => fontSize || '13px'};
  background-color: ${({ colorTheme, dataValue }) =>
    dataValue && dataValue != 0
      ? colorTheme || `#c2cce1`
      : `${colorTheme}4d` || `#c2cce1`};
`;

export const CardText = styled.p`
  margin: ${({ margin }) => margin || `auto 0`};
  color: ${({ color, name, label, theme }) =>
    color
      ? color
      : name
      ? theme.font.pageText.colorMain
      : label
      ? theme.font.otherLabel.color
      : theme.font.pageText.colorSecondary};
  font-size: ${({ fontSize }) => fontSize || '15px'};
  font-weight: ${({ fontWeight, title, label, theme }) =>
    fontWeight
      ? fontWeight
      : label
      ? theme.font.otherLabel.weight
      : title
      ? theme.fontWeights.semibold
      : theme.font.pageText.weight};
  white-space: ${({ nowrap }) => nowrap && `nowrap`};
  @media screen and (max-width: 500px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize || '13px'};
  }
`;

export const TopProjectsCardContainer = styled.div`
  min-height: 200px;
  // max-height: 230px;
  overflow: hidden;
  border: 1px solid #c2cce1;
  border-radius: 10px;
  background: ${({ background, theme }) =>
    background ?? theme?.colors?.main?.secondary};
  padding: 20px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1400px) {
    padding: 15px;
  }
`;
export const TopProjectsLoaderContainer = styled.div`
  display: grid;
  justify-content: 'center';
  padding-left: 30px;
`;
export const GraphAndDataContainer = styled.div`
  display: grid;
  grid-template-columns: 42% 47%;
  grid-column-gap: 1%;
  padding: 10px 0;
  justify-content: space-between;
`;
export const DougnutGraphDiv = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
`;
export const ProjectsDataContainer = styled.div`
  display: grid;
  justify-content: space-between;
`;
export const ProjectInfoDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ProjectNameDiv = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
export const ProjectTimeDiv = styled.div`
  display: flex;
  align-items: center;
`;
export const ProjectData = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.main.labelText};
  @media screen and (max-width: 1400px) {
    text-overflow: ellipsis;
  }
`;
export const CardTitle = styled.div`
  font-size: ${({ fontSize }) => fontSize ?? `16px`};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.main.textMain};
`;
export const BarAndDateDiv = styled.div`
  display: grid;
  grid-row-gap: 10px;
`;
export const TodayActivityProgressBarDiv = styled.div`
  margin: 0 0 10px;
  width: 80%;
`;

export const WeeklyActivityTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ padding }) => (padding ? padding : `5px 0 0 5px`)};
  ${props => props.margin && `margin: ${props.margin}`}
`;
export const CardTitleAndSubtitleContainer = styled.div``;
export const VerticalBarsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  margin-top: 20px;
`;
export const SingleBarContainer = styled.div`
  display: grid;
  grid-row-gap: 15px;
  justify-items: center;
`;
export const CardLoaderContainer = styled.div`
  padding: ${({ padding }) => padding || '0 40px'};
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;
export const TimsheetSectionContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ full }) =>
    full ? 'repeat(auto-fill, minmax(100px, 1fr))' : '23.5% 23.5% 23.5% 23.5%'};
  margin: 20px 0 10px;
  grid-row-gap: 10px;
  ${({ full }) =>
    full
      ? css`
          grid-column-gap: 10px;
          @media screen and (min-width: 1400px) {
            grid-template-columns: auto auto auto auto auto auto auto auto;
          }
        `
      : css`
          justify-content: space-between;
        `}

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 5px;
  }
`;
export const TimesheetSingleSection = styled.div``;
export const TimesheetDate = styled.div`
  color: ${({ theme, color }) => color || theme.colors.main.labelText};
  font-size: 13px;
  font-weight: ${({ fontWeight, theme }) =>
    fontWeight || theme.fontWeights.regular};
  margin-left: 5px;
`;

export const OngoingTasksContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ length }) => (length <= 2 ? '100%' : '48% 48%')};
  justify-content: space-between;
  grid-row-gap: 20px;
  margin-top: 20px;
`;
export const SingleOngoingTaskDiv = styled.div`
  display: grid;
  grid-row-gap: 15px;
  padding: 15px 20px;
  background: ${({ background }) => background || '#E7F0FE'};
  border-radius: 10px;
`;
export const OngoingTaskName = styled.div`
  font-size: 14px;
`;
export const OngoingTaskProjectAndTimeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const OngoingTaskData = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.main.labelText};
`;

// project hour and activity card
export const BarGraphAndInfoContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || '60% 40%'};
  align-items: flex-end;
`;
export const ProjectLastWeekInfo = styled.div`
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  background: #f3f5f9;
  border-radius: 6px;
  margin-bottom: 8px;
  @media screen and (max-width: 1400px) {
    padding: 10px;
  }
`;
export const ProjectLastWeekInfoTitle = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.main.labelText};
`;
export const ProjectLastWeekInfoData = styled.div`
  font-size: 15px;
  margin-top: 5px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;

// no data
export const NoDataContainer = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
  margin-top: ${({ marginTop }) => marginTop || '15px'};
`;
export const NoDataImg = styled.img`
  height: ${({ height }) => height || '120px'};
  width: ${({ width }) => width || '120px'};
`;
export const NoDataText = styled.div`
  font-size: 14px;
  color: ${({ color, theme }) => color || theme.colors.main.labelText};
  margin-top: 5px;
`;
