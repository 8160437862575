import base64 from 'base-64';
import queryString from 'query-string';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import hidePassword from '../../assets/img/common/password_hide.svg';
import viewPassword from '../../assets/img/common/password_show.svg';
import GoogleLogo from '../../assets/img/icons/google_logo.svg';
import AuthPageLeftSection from '../../components/AuthLayout/AuthPageLeftSection';
import TextInput from '../../components/FloatingLabelInput/FloatingLabelInput';
import ButtonTextLoader from '../../components/Loaders/ButtonTextLoader';
import PasswordStrengthChecker from '../../components/PasswordStrengthChecker/PasswordStrengthChecker';
import { createNotification } from '../../modules/notificationManager';
import {
  AuthCard,
  AuthForm,
  AuthFormAlert,
  AuthInputContainer,
  AuthPageContainer,
  AuthPageRightContainer,
  AuthSubmitButton,
  GoogleButtonText,
  GoogleIcon,
  GoogleSignInButton,
  LoginActiveTab,
  LoginInactiveTab,
  LoginTabContainer,
  ShowPasswordIcon,
  SignUpNameContainer,
  TermsAndConditionsLink,
  TermsAndConditionsSection,
} from '../../styledComponents/authLayout';
import {
  checkHtml,
  checkUrl,
  password_validate,
  validateEmail,
} from '../../utils/helper';

const Card = styled(AuthCard)`
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 576px) {
    padding: 0;
  }
`;

const CardUpperContainer = styled.div`
  padding: 80px 7% 25px;
  @media screen and (max-width: 576px) {
    padding: 50px 5% 25px;
  }
`;

const SignUp = props => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [timezone, setTimezone] = useState('');
  const [invitedEmail, setInvitedEmail] = useState('');
  const [emailGiven, setEmailGiven] = useState(false);
  const [invitedKey, setInvitedKey] = useState('');
  const [invitedRequireSignUp, setInvitedRequireSignUp] = useState(false);
  const [saveErrors, setSaveErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [passwordIsFocused, setPasswordIsFocused] = useState(false);
  const passwordFieldRef = useRef(null);
  const methods = {
    setFirstName,
    setLastName,
    setEmail,
    setPassword,
  };

  const loadRecaptcha = () => {
    const script = document.createElement('script');
    // script.src = `https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    script.id = 'recaptcha-script';
    script.async = true;

    document.body.append(script);
  };

  const removeRecaptcha = () => {
    const script = document.getElementById('recaptcha-script');
    if (script) {
      script.remove();
    }

    const recaptchaElements =
      document.getElementsByClassName('grecaptcha-badge');
    if (recaptchaElements.length > 0) {
      recaptchaElements[0].remove();
    }
  };

  useEffect(() => {
    let token = localStorage.getItem('access_token');
    if (token != null) {
      props.history.push('/user/jobs');
    }
    if (
      props.location &&
      props.location.search &&
      props.location.search.length > 0
    ) {
      const queryParsed = queryString.parse(props.location.search);
      if (queryParsed.key) {
        try {
          let key = queryParsed.key;
          let payload = JSON.parse(base64.decode(key.split('.')[1]));
          if (payload && payload.signup) {
            //window.location.href = "/activate-membership/" + encodedSring;
            props.history.push(`/accept-invitation?key=${key}`);
          } else {
            setInvitedRequireSignUp(true);
            setInvitedEmail(payload.email);
            setEmail(payload.email);
            setInvitedKey(key);
          }
        } catch (error) {
          createNotification(
            'error',
            'Malformed url. You will not be able to join your desired organization.',
            5000,
          );
        }
      } else if (queryParsed.email) {
        setEmail(queryParsed.email);
        setEmailGiven(true);
      }
    }
    setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);

    // load recaptcha
    loadRecaptcha();

    return () => {
      removeRecaptcha();
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.signup.redirect) {
      if (invitedRequireSignUp) {
        if (props.signup.token && props.signup.userData) {
          localStorage.setItem('access_token', props.signup.token);
          localStorage.setItem('user_id', props.signup.userData.user_id);
          localStorage.setItem(
            'user_profile',
            props.signup.userData.profile_id,
          );
          localStorage.setItem(
            'user_fullName',
            `${props.signup.userData.first_name}${
              props.signup.userData.last_name
                ? ` ${props.signup.userData.last_name}`
                : ``
            }`,
          );
          localStorage.setItem('email', props.signup.userData.email);
          setTimeout(() => {
            props.history.push({
              pathname: '/user/jobs',
              state: { message: 'user-onboard' },
            });
          }, 1000);
        } else {
          props.history.push(`/auth/login`);
          createNotification('success', 'Please login to continue.', 3000);
        }

        props.resetSignUp();
      } else {
        props.history.push(
          `/auth/verify-code?email=${encodeURIComponent(
            email,
          )}&name=${encodeURIComponent(firstName)}`,
        );
        props.resetSignUp();
      }
    }
    // eslint-disable-next-line
  }, [props.signup]);

  useEffect(() => {
    // Moving cursor to the end
    passwordFieldRef.current.selectionStart =
      passwordFieldRef.current.value.length;
    passwordFieldRef.current.selectionEnd =
      passwordFieldRef.current.value.length;
  }, [showPassword]);

  const onTextChange = e => {
    const { name, value } = e.target;
    let saveErrors = {};
    if (name === 'email') {
      if (!invitedRequireSignUp) {
        if (!validateEmail(value.trim())) {
          saveErrors['email'] = 'Please enter a valid email address';
        }
      } else {
        return;
      }
    }
    methods[name](value);
    setSaveErrors(saveErrors);
  };
  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!firstName.trim()) {
      formIsValid = false;
      saveErrors['name'] = 'First Name Required';
    } else if (firstName.length > 128) {
      formIsValid = false;
      saveErrors['name'] = 'First name can not be more than 128 characters.';
    } else if (checkUrl(firstName)) {
      formIsValid = false;
      saveErrors['name'] = 'First name can not be url.';
    } else if (checkHtml(firstName)) {
      formIsValid = false;
      saveErrors['name'] = 'Invalid first name.';
    }
    if (lastName && lastName.length > 128) {
      formIsValid = false;
      saveErrors['name'] = 'Last name can not be more than 128 characters.';
    } else if (checkUrl(lastName)) {
      formIsValid = false;
      saveErrors['name'] = 'Last name can not be url.';
    } else if (checkHtml(lastName)) {
      formIsValid = false;
      saveErrors['name'] = 'Invalid last name.';
    }
    if (!password) {
      formIsValid = false;
      saveErrors['password'] = 'Password Required';
    } else if (password.length > 32) {
      formIsValid = false;
      saveErrors['password'] = 'Password can not be more than 32 characters.';
    } else if (password_validate(password) === false) {
      formIsValid = false;
    }
    if (!email && !invitedRequireSignUp) {
      formIsValid = false;
      saveErrors['email'] = 'Email Required';
    } else if (!invitedEmail) {
      if (!validateEmail(email.trim())) {
        formIsValid = false;
        saveErrors['email'] = 'Please enter a valid email address';
      }
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const handleSignUp = async e => {
    e.preventDefault();
    if (checkError()) {
      grecaptcha.ready(function () {
        grecaptcha
          .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
            action: 'signup',
          })
          .then(function (token) {
            if (invitedRequireSignUp) {
              let payload = {
                first_name: firstName,
                email: invitedEmail,
                password: password,
                key: invitedKey,
                recaptcha_token: token,
              };
              if (lastName) {
                payload.last_name = lastName;
              }
              if (timezone) {
                payload.timezone = timezone;
              }
              props.signUp(payload);
            } else {
              let payload = {
                first_name: firstName,
                email: email,
                password: password,
                recaptcha_token: token,
              };
              if (lastName) {
                payload.last_name = lastName;
              }
              if (timezone) {
                payload.timezone = timezone;
              }
              props.signUp(payload);
            }
          })
          .catch(err => {
            const errors = { ...saveErrors };
            errors['email'] = 'Please try again!';

            setSaveErrors({ ...errors });
          });
      });
    }
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
    passwordFieldRef.current.focus();
  };
  return (
    <AuthPageContainer>
      <AuthPageLeftSection />
      <AuthPageRightContainer>
        <Card>
          <CardUpperContainer>
            <LoginTabContainer>
              <LoginInactiveTab to="/auth/login">Sign In</LoginInactiveTab>
              <LoginActiveTab>Sign Up</LoginActiveTab>
            </LoginTabContainer>
            <AuthForm page="signUp" onSubmit={e => handleSignUp(e)}>
              <AuthInputContainer>
                <SignUpNameContainer>
                  <TextInput
                    width="45%"
                    id="firstName"
                    name="setFirstName"
                    label="First name"
                    type="text"
                    onChange={e => onTextChange(e)}
                    value={firstName}
                    required
                    autoFocus
                  />
                  <TextInput
                    width="45%"
                    id="lastName"
                    name="setLastName"
                    label="Last name"
                    type="text"
                    onChange={e => onTextChange(e)}
                    value={lastName}
                  />
                </SignUpNameContainer>
              </AuthInputContainer>
              <TextInput
                id="email"
                name="setEmail"
                label="Email"
                type="text"
                disabled={invitedEmail ? true : false}
                onChange={e => onTextChange(e)}
                value={invitedEmail ? invitedEmail : email}
                invitedEmail={invitedEmail || emailGiven}
              />
              <AuthInputContainer>
                <TextInput
                  refs={passwordFieldRef}
                  id="password"
                  name="setPassword"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={e => onTextChange(e)}
                  onFocus={() => setPasswordIsFocused(true)}
                  onBlur={() => setPasswordIsFocused(false)}
                  value={password}
                />
                <ShowPasswordIcon
                  src={showPassword ? hidePassword : viewPassword}
                  alt=""
                  active={passwordIsFocused}
                  onClick={e => toggleShowPassword(e)}
                />
              </AuthInputContainer>
              {((password && password.length > 0) || passwordIsFocused) && (
                <PasswordStrengthChecker password={password} />
              )}
              {saveErrors &&
                Object.keys(saveErrors).length > 0 &&
                (saveErrors.name ||
                  saveErrors.email ||
                  saveErrors.password) && (
                  <AuthFormAlert style={{ marginTop: '15px' }}>
                    {saveErrors.name || saveErrors.email || saveErrors.password}
                  </AuthFormAlert>
                )}
              <AuthSubmitButton
                type="submit"
                disabled={!firstName || !email || !password_validate(password)}
                isDisabled={
                  !firstName || !email || !password_validate(password)
                }
                marginTop={
                  saveErrors &&
                  Object.keys(saveErrors).length > 0 &&
                  (saveErrors.name || saveErrors.email || saveErrors.password)
                    ? '11px'
                    : null
                }
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {/* <img src="https://ct.capterra.com/capterra_tracker.gif?vid=2141233&vkey=cc9a1795f4d22f3d1da1023cae876a05" /> */}
                {props.signup.isLoading ? (
                  <ButtonTextLoader loadingText="Creating your account" />
                ) : (
                  "Let's Go!"
                )}
              </AuthSubmitButton>
              {/* <GoogleSignInButton>
                <GoogleIcon src={GoogleLogo} alt="" />
                <GoogleButtonText>Sign Up With Google</GoogleButtonText>
              </GoogleSignInButton> */}
            </AuthForm>
          </CardUpperContainer>
          <TermsAndConditionsSection>
            <p style={{ margin: '0' }}>
              By clicking the button above, you agree to our{' '}
              <TermsAndConditionsLink
                href="https://fieldservicely.com/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                terms of services{' '}
              </TermsAndConditionsLink>{' '}
              and{' '}
              <TermsAndConditionsLink
                href="https://fieldservicely.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                privacy policies{' '}
              </TermsAndConditionsLink>
            </p>
          </TermsAndConditionsSection>
        </Card>
      </AuthPageRightContainer>
    </AuthPageContainer>
  );
};

export default SignUp;
