import { EXPORT_CLOCK_IN_OUT_LIST, GET_CLOCK_IN_OUT_LIST } from "../../modules/constants";

export function getClockInOutList(payload) {
  return {
    type: GET_CLOCK_IN_OUT_LIST,
    payload,
  };
}
export function exportClockInOutList(payload) {
  return {
    type: EXPORT_CLOCK_IN_OUT_LIST,
    payload,
  };
}
