import EvidenceModal from 'custom_modules/TasksNew/TaskDetails/EvidenceModal';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import avatar5 from '../../assets/img/avatar_5.svg';
import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent';
import InfoTooltip from '../../components/Tooltip/InfoTooltip';
import {
  CommonFlex,
  CommonImage,
  CommonText,
  Container,
  RoundMiniImage,
  TableText,
} from '../../styledComponents/common';
import { MemberNameDiv } from '../../styledComponents/members';
import { TableItem } from '../../styledComponents/teams';
import { getHours, toHHMM, toHHMMSS } from '../../utils/helper';
import { CommImageNameComp } from '../Members/CommonComponents';
import { ClockInOutTableHeader, ClockInOutTableRow } from './clockInOutStyles';

const ClockInOutTable = props => {
  const { isLoading, history, clockInOutList } = props;
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const { t } = useTranslation();

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };
  return (
    <Container style={{ paddingBottom: '20px' }}>
      {selectedImage && (
        <EvidenceModal
          isOpen={popupOpen}
          toggle={popupToggle}
          evidence={selectedImage}
        />
      )}
      {isLoading ? (
        <ComponentCircleLoader />
      ) : clockInOutList?.length > 0 ? (
        <>
          <ClockInOutTableHeader>
            <TableItem>
              <TableText>{t('member_name')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('date')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('clock_in')}</TableText>
            </TableItem>
            <TableItem>
              {/* <InfoTooltip
                mainText={t('clock_out')}
                toolTipText="Clock out occurs when the user stops the timer at the end of the day"
                title
                label
                top="-55px"
                tooltipPadding="5px 8px"
                infoSize="17px"
              /> */}
              <TableText>{t('clock_out')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('duration')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('office')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('image')}</TableText>
            </TableItem>
          </ClockInOutTableHeader>

          {clockInOutList?.map((instance, index) => {
            return (
              <ClockInOutTableRow key={index}>
                <CommonFlex direction="column" alignItems="start">
                  <div
                    style={{
                      position: 'sticky',
                      top: '0',
                    }}
                  >
                    <TableItem>
                      <CommImageNameComp
                        imgSource={instance.employee.avatar || avatar5}
                        name={`${instance.employee.first_name}${
                          instance.employee.last_name
                            ? ` ${instance.employee.last_name}`
                            : ``
                        }`}
                        size="36px"
                        index={index}
                      />
                    </TableItem>
                  </div>
                </CommonFlex>
                {/* <TableItem>
                    <TableText name>
                      {instance.employee && instance.employee.user_code
                        ? instance.employee.user_code
                        : '-'}
                    </TableText>
                  </TableItem> */}
                <TableItem>
                  <CommonFlex direction="column">
                    {instance?.attendances.map((attendance, index) => (
                      <TableText
                        name
                        key={index}
                        height="46px"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {attendance.date
                          ? moment(attendance.date).format('DD-MM-yyyy')
                          : '-'}
                      </TableText>
                    ))}
                  </CommonFlex>
                </TableItem>
                <TableItem>
                  <CommonFlex direction="column">
                    {instance?.attendances.map((attendance, index) => (
                      <TableText
                        name
                        key={index}
                        height="46px"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {attendance.clockin_timestamp
                          ? moment
                              .unix(attendance.clockin_timestamp)
                              .format('LT')
                          : '-'}
                      </TableText>
                    ))}
                  </CommonFlex>
                </TableItem>

                <TableItem>
                  <CommonFlex direction="column">
                    {instance?.attendances.map((attendance, index) => (
                      <TableText
                        name
                        key={index}
                        height="46px"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {attendance.clockout_timestamp
                          ? moment
                              .unix(attendance.clockout_timestamp)
                              .format('LT')
                          : '-'}
                      </TableText>
                    ))}
                  </CommonFlex>
                </TableItem>
                <TableItem>
                  <CommonFlex direction="column">
                    {instance?.attendances.map((attendance, index) => (
                      <TableText
                        name
                        key={index}
                        height="46px"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {attendance.duration
                          ? getHours(toHHMM(attendance.duration))
                          : '-'}
                      </TableText>
                    ))}
                  </CommonFlex>
                </TableItem>
                <TableItem>
                  <CommonFlex direction="column">
                    {instance?.attendances.map((attendance, index) => (
                      <TableText
                        name
                        key={index}
                        height="46px"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {attendance.office[0].office_name
                          ? attendance.office[0].office_name
                          : '-'}
                      </TableText>
                    ))}
                  </CommonFlex>
                </TableItem>
                <TableItem>
                  <CommonFlex direction="column">
                    {instance?.attendances.map((attendance, index) => (
                      <CommonImage
                        height="46px"
                        width="36px"
                        style={{ cursor: `pointer` }}
                        onClick={() => {
                          setSelectedImage(attendance.selfie[0]);
                          popupToggle();
                        }}
                        src={attendance.selfie[0]}
                      />
                    ))}
                  </CommonFlex>
                </TableItem>
              </ClockInOutTableRow>
            );
          })}
        </>
      ) : (
        <NoDataComponent
          title="No Attendance In This Day!"
          padding="5vh 0"
          imageHeight="140px"
        />
      )}
    </Container>
  );
};

export default ClockInOutTable;
