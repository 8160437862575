import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import BackButtonComponent from '../../../components/BackButtonComponent/BackButtonComponent';
import ProjectDropdown from '../../../components/CommonFilter/ProjectDropdown';
import MembersDropdownStyle from '../../../components/DropdownStyle/MembersDropdownStyle';
import ButtonTextLoader from '../../../components/Loaders/ButtonTextLoader';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import { PrimaryButton, WhiteButton } from '../../../styledComponents/buttons';
import {
  GeneralInfoItem,
  GeneralInfoSection,
} from '../../../styledComponents/clients';
import {
  CardTitle,
  CommonFlex,
  CommonGrid,
  CommonText,
  Container,
  ContentHeader,
  InputField,
  InputLabel,
  InputTextArea,
  PageTitle,
} from '../../../styledComponents/common';
import {
  EmailBox,
  EmailEntry,
  InputEmail,
  InvalidEmailContainer,
  MemberInvitationContainer,
  RedStar,
} from '../../../styledComponents/members';
import { validateEmail } from '../../../utils/helper';

const ButtonContainer = styled.div`
  display: flex;
`;
const EmailAlert = styled.p`
  margin: 10px 0px;
  color: ${({ theme }) => theme.colors.main.error};
  font-size: ${({ theme }) => theme.font.pageText.size};
`;
const EmailText = styled.div`
  font-size: 13px;
  height: 40px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
`;
const ProjectNameContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const ProjectNameDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 109px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.main.whiteBg};
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  border-radius: ${({ theme }) => theme.input.borderRadius};
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
  font-size: 13px;
  margin-right: 8px;
`;
const InvitationSentText = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  color: ${({ theme }) => theme.colors.main.primary};
  font-size: ${({ theme }) => theme.font.pageText.size};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;

const UpgradeSectionForSolo = ({ history }) => {
  return (
    <CommonFlex direction="column" alignItems="center" padding="60px 30px">
      <CommonText title name fontSize="24px" margin="0 0 20px">
        Upgrade your plan to invite more members
      </CommonText>
      <CommonText
        $label
        fontWeight="500"
        margin="0 150px 40px"
        fontSize="16px"
        style={{ textAlign: 'center' }}
      >
        You can not have more than one member in Solo plan. Please, upgrade your
        plan to add your team members.
      </CommonText>
      <PrimaryButton onClick={() => history.push('/user/subscription')}>
        Upgrade
      </PrimaryButton>
    </CommonFlex>
  );
};

const MemberInvitation = props => {
  const [invitationList, setInvitationList] = useState([]);
  const [emailInput, setEmailInput] = useState('');
  const [invalidEmailInput, setInvalidEmailInput] = useState([]);
  const [saveErrors, setSaveErrors] = useState({});
  const inputRef = useRef(null);

  const {
    selectedOrganization,
    clearMemberInvited,
    projectList,
    memberInvited,
    inviteMember,
    inviteMemberBulk,
    history,
    projectIsLoading,
    inviteMemberLoading,
  } = props;

  useEffect(() => {
    clearMemberInvited();

    return () => {
      clearMemberInvited();
    };
  }, []);

  useEffect(() => {
    if (memberInvited) {
      setInvitationList([]);
      setEmailInput('');
      setInvalidEmailInput([]);
      setSaveErrors({});
      setTimeout(() => {
        history.push('/user/members');
      }, 50);
    }
  }, [memberInvited]);

  const sendInvitaion = () => {
    if (invitationList.length === 0) {
      if (checkError(emailInput)) {
        if (selectedOrganization && selectedOrganization.id) {
          const payload = {
            organization_id: selectedOrganization.id,
            email: emailInput,
          };
          inviteMember(payload);
        }
      }
      else{
        saveErrors['email'] = 'Please give a valid email address.';
      }
    }
    if (invitationList.length === 1) {
      if (selectedOrganization && selectedOrganization.id) {
        if (emailInput.length > 0 && checkError(emailInput)) {
          let csvContent = '';
          csvContent += `${invitationList[0]}\n`;
          csvContent += `${emailInput}\n`;
          const blob = new Blob([csvContent], { type: 'text/csv' });
          // Create a File object with the Blob and specify the filename
          const file = new File([blob], 'valid_rows.csv', { type: 'text/csv' });

          let payload = {
            organization_id: selectedOrganization.id,
            file: file, // Sending the File directly as part of the payload
          };
          inviteMemberBulk(payload);
        } else {
          const payload = {
            organization_id: selectedOrganization.id,
            email: invitationList[0],
          };
          inviteMember(payload);
        }
      }
    }
    if (invitationList.length > 1) {
      if (selectedOrganization && selectedOrganization.id) {
        let csvContent = '';
        invitationList.forEach(row => {
          csvContent += `${row}\n`;
        });
        if (checkError(emailInput)) {
          csvContent += `${emailInput}\n`;
        }
        // Create a Blob from the data
        const blob = new Blob([csvContent], { type: 'text/csv' });
        // Create a File object with the Blob and specify the filename
        const file = new File([blob], 'valid_rows.csv', { type: 'text/csv' });

        let payload = {
          organization_id: selectedOrganization.id,
          file: file, // Sending the File directly as part of the payload
        };
        inviteMemberBulk(payload);
      }
    }
  };

  const checkError = item => {
    let saveErrors = {};
    let isValid = true;
    if (!item) {
      saveErrors['email'] = 'Please type an email address.';
      isValid = false;
    } else if (!validateEmail(item)) {
      saveErrors['email'] = 'Please give a valid email address.';
      isValid = false;
    } else if (invitationList.includes(item.trim())) {
      saveErrors['email'] = 'This email is already in the list.';
      isValid = false;
    }
    setSaveErrors(saveErrors);
    return isValid;
  };

  const handleInputChange = event => {
    setEmailInput(event.target.value);
  };

  const handleInputKeyDown = event => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault();
      setInvalidEmailInput('');
      // Split the input value into an array of emails
      const emails = emailInput.split(/,|\n/);

      // Process each email
      emails.forEach(email => {
        // Trim and validate the email (you can add your own validation logic)
        const trimmedEmail = email.trim();
        if (trimmedEmail) {
          addEmail(trimmedEmail);
        }
      });

      // setEmailInput('');
    }
  };

  const addEmail = newEmail => {
    if (newEmail.trim() !== '') {
      if (checkError(newEmail)) {
        setEmailInput('');
        setInvitationList(prevList => [...prevList, newEmail.trim()]);
      } else {
        setInvalidEmailInput(prevInvalidEmailInput => [
          ...prevInvalidEmailInput,
          newEmail,
        ]);
      }
    }
  };

  const removeEmail = index => {
    const updatedEmailList = [...invitationList];
    updatedEmailList.splice(index, 1);
    setInvitationList(updatedEmailList);
  };


  return (
    <div className="content">
      <PageTitle>Member Invitation</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => history.push('/user/members')}
          subTitle="Back to all members"
        />
      </ContentHeader>
      <Container style={{ padding: `30px` }}>
        {selectedOrganization &&
        selectedOrganization.plan &&
        selectedOrganization.plan.name === 'Solo' ? (
          <UpgradeSectionForSolo history={history} />
        ) : projectIsLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            <CardTitle margin="0 0 25px">Add members email addresses</CardTitle>
            <div>
              <InputLabel margin="0 0 15px">
                Email Address <RedStar>*</RedStar>
              </InputLabel>
              <EmailBox onClick={() => inputRef.current.focus()}>
                {invitationList.map((email, index) => (
                  <EmailEntry key={index}>
                    {email}
                    <button onClick={() => removeEmail(index)}>x</button>
                  </EmailEntry>
                ))}
                <InputEmail
                  ref={inputRef}
                  placeholder={
                    invitationList && invitationList.length > 0
                      ? 'Add more email...'
                      : 'Type email here...'
                  }
                  value={emailInput}
                  onChange={handleInputChange}
                  onKeyDown={handleInputKeyDown}
                />
              </EmailBox>
            </div>

            <GeneralInfoItem
              margin="15px 0 0 0"
              minHeight="35px"
              width="40%"
              backColor={8}
            >
              <CommonText>
                You can add multiple email addresses by separating with comma
              </CommonText>
            </GeneralInfoItem>

            {invalidEmailInput &&
              invalidEmailInput.length < 2 &&
              saveErrors &&
              saveErrors !== {} &&
              saveErrors.email && <EmailAlert>{saveErrors.email}</EmailAlert>}
            {invalidEmailInput && invalidEmailInput.length > 1 && (
              <div>
                <CardTitle margin="20px 0 25px 0">
                  Some email addresses are found invalid
                </CardTitle>

                {invalidEmailInput.map((email, index) => (
                  <InvalidEmailContainer key={index}>
                    {email}
                  </InvalidEmailContainer>
                ))}
              </div>
            )}

            <CommonFlex gap="12px" margin="30px 0 0 0">
              <PrimaryButton
                padding={inviteMemberLoading && '0 25px'}
                onClick={!inviteMemberLoading? () => sendInvitaion(): ''}
              >
                {inviteMemberLoading ? (
                  <ButtonTextLoader
                    loadingText="Sending Invitation"
                    fontSize="13px"
                  />
                ) : (
                  'Send Invitation'
                )}
              </PrimaryButton>
            </CommonFlex>

            <CommonText margin="30px 0 0 0">
              or you can{' '}
              <span
                style={{
                  color: '#2268EF',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontWeight: '600',
                }}
                // onClick={() => history.push('/user/member-invitation')}
                onClick={() => history.push('/user/member-invitationCSV')}
              >
                Upload a CSV
              </span>
            </CommonText>
          </>
        )}
      </Container>
    </div>
  );
};

export default MemberInvitation;
