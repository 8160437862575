import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DownloadIcon from '../../assets/img/icons/download-curved.svg';
import Plus from '../../assets/img/icons/plus_white.svg';
import BottomPagination from '../../components/Pagination/BottomPagination/BottomPagination';
import TopPagination from '../../components/Pagination/TopPagination/TopPagination';
import SearchWithButton from '../../components/Search/SearchWithButton';
import {
  ButtonIcon,
  ColoredButtonWithIcon,
  TabButton,
  TabButtonContainer,
} from '../../styledComponents/buttons';
import { TabLeftGrid } from '../../styledComponents/clients';
import {
  CommonGrid,
  FilterLabel,
  HeaderContainer,
  InputField,
  InputWithLabelSection,
  PageTitle,
} from '../../styledComponents/common';
import ClientsCards from './ClientsCards';
import ImportCustomerModal from './ImportCustomerModal';

const Clients = props => {
  const { t } = useTranslation();
  const [clientList, setClientList] = useState([]);
  const [clientLocationList, setClientLocationList] = useState([]);
  const [clientType, setClientType] = useState('active');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCustomerAddModalOpen, setIsCustomerAddModalOpen] = useState(false);
  //pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const {
    ghlSyncContactIsLoading,
    ghlContactList,
    getGHLContactList,

  } = props;

  console.log("ghlContactList ", ghlContactList);

  useEffect(() => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        page: 1,
      };
      props.getClientList(payload);
    }
  }, [props.selectedOrganization]);

  useEffect(() => {
    if (props.clientList && props.clientList.length > 0) {
      setClientList(props.clientList);
    } else if (props.clientList && props.clientList.length === 0) {
      setClientList([]);
    }
  }, [props.clientList]);

  const handleSearchByName = () => {
    const payload = {
      page: 1,
    };

    if (searchTerm && searchTerm.trim()) {
      payload.name = searchTerm.toLowerCase().trim();
      setCurrentSearchedTerm(searchTerm);
    } else {
      setCurrentSearchedTerm('');
    }
    if (props.selectedOrganization && props.selectedOrganization.id) {
      payload.organization_id = props.selectedOrganization.id;
      props.getClientList(payload);
    }

    setCurrentPageNumber(1);
  };

  const handleClearSearch = () => {
    if (currentSearchedTerm) {
      const payload = {
        page: 1,
      };

      if (props.selectedOrganization && props.selectedOrganization.id) {
        payload.organization_id = props.selectedOrganization.id;
        props.getClientList(payload);
      }

      setCurrentPageNumber(1);
    }
    setTimeout(() => {
      setSearchTerm('');
      setCurrentSearchedTerm('');
    }, 100);
  };

  const onTabSwitch = value => {
    setClientType(value);
  };
  const onSearchTermChange = e => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const toggle = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };
  const customerAddToggle = () => {
    setIsCustomerAddModalOpen(!isCustomerAddModalOpen);
  };

  const handleClientArchive = (clientId, action, PageNumber) => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      clientId
    ) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        client_id: clientId,
        is_active: action === 'archive' ? false : true,
        getList: true,
        page: PageNumber,
      };
      props.updateClient(payload);
    }
  };

  const handleClientDelete = clientId => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        client_id: clientId,
      };
      props.deleteClient(payload);
      setIsDeleteModalOpen(!isDeleteModalOpen);
    }
  };

  const handlePageChange = page => {
    // console.log(page);
    if (props.selectedOrganization && props.selectedOrganization.id) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        page: page,
      };
      props.getClientList(payload);
    }

    setCurrentPageNumber(page);
  };

  const ghlContactsFetch = () =>{
    if(props.selectedOrganization && props.selectedOrganization.id){
      const payload = {
        organization_id: props.selectedOrganization.id,
      };
      getGHLContactList(payload);
    }
  }

  return (
    <div className="content">
      <PageTitle>{t('customers')}</PageTitle>
      <ImportCustomerModal
        isOpen={isCustomerAddModalOpen}
        toggle={customerAddToggle}
        selectedOrganization={
          props.selectedOrganization && props.selectedOrganization.id
            ? props.selectedOrganization
            : ''
        }
        createCustomerBulk={props.createCustomerBulk}
        bulkCustomerCreated={props.bulkCustomerCreated}
        isLoading={props.isBulkCustomerLoading}
      />
      <HeaderContainer>
        <TabLeftGrid>
          <InputWithLabelSection>
            <FilterLabel>{t('type')}</FilterLabel>
            <TabButtonContainer>
              <TabButton
                onClick={() => onTabSwitch('active')}
                selected={clientType}
                index="active"
              >
                {t('active')}
              </TabButton>
              <TabButton
                onClick={() => onTabSwitch('archived')}
                selected={clientType}
                index="archived"
              >
                {t('archive')}
              </TabButton>
            </TabButtonContainer>
          </InputWithLabelSection>

          <SearchWithButton
            itemName={t('customer')}
            searchTermName={t('customer_name')}
            searchInput={searchTerm}
            onInputChange={onSearchTermChange}
            handleSearch={handleSearchByName}
            handleClearSearch={handleClearSearch}
          />
        </TabLeftGrid>
        <CommonGrid
          // columns={
          //   props.selectedOrganization && props.selectedOrganization.ghl_id
          //     ? '1fr 1fr 1fr'
          //     : '1fr 1fr'
          // }
        >
          {/* {props.selectedOrganization &&
          (props.selectedOrganization.role === 'owner' ||
            props.selectedOrganization.role === 'admin') &&
          props.selectedOrganization.ghl_id ? (
            <ColoredButtonWithIcon
              style={{ background: '#606CFF' }}
              onClick={() => ghlContactsFetch()}
            >
              <ButtonIcon src={DownloadIcon} alt="" />
              Sync Customer
            </ColoredButtonWithIcon>
          ) : null} */}

          <ColoredButtonWithIcon
            style={{ background: '#606CFF' }}
            onClick={() => customerAddToggle()}
          >
            <ButtonIcon src={DownloadIcon} alt="" />
            {t('import_customers')}
          </ColoredButtonWithIcon>

          <ColoredButtonWithIcon
            onClick={() => props.history.push('/user/add-customer')}
          >
            <ButtonIcon src={Plus} alt="" />
            {t('add_customer')}
          </ColoredButtonWithIcon>
        </CommonGrid>
      </HeaderContainer>

      {props.totalClientsCount &&
      props.totalClientsCount > 0 &&
      props.pageSize ? (
        <TopPagination
          itemName={t('customers')}
          totalCount={props.totalClientsCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={props.pageSize}
        />
      ) : null}

      <ClientsCards
        searchTerm={searchTerm}
        clientList={clientList}
        clientType={clientType}
        history={props.history}
        modalToggle={toggle}
        isModalOpen={isDeleteModalOpen}
        handleArchive={handleClientArchive}
        handleDelete={handleClientDelete}
        isLoading={props.isLoading}
        clientUpdateLoading={props.clientUpdateLoading}
        updatingClientId={props.updatingClientId}
        clientDeleteLoading={props.clientDeleteLoading}
        deletingClientId={props.deletingClientId}
        PageNumber={currentPageNumber}
      />

      {props.totalClientsCount &&
      props.totalClientsCount > 0 &&
      props.pageSize ? (
        <BottomPagination
          totalCount={props.totalClientsCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={props.pageSize}
        />
      ) : null}
    </div>
  );
};

export default Clients;
