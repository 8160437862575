import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import avatar5 from '../../assets/img/avatar_5.svg';
import Plus from '../../assets/img/icons/plus_white.svg';
import refresh_white from '../../assets/img/icons/refresh_white.svg'
import ProjectDropdown from '../../components/CommonFilter/ProjectDropdown';
import AddSingleMemberDropdown from '../../components/CustomDropdown/AddSingleMemberDropdown';
import CreateProjectDropdown from '../../components/DropdownStyle/CreateProjectDropdown';
import FilterDropDownStyle from '../../components/DropdownStyle/FilterDropDownStyle';
import Select from '../../components/ReactSelectDropdown';
import { filterOption } from '../../components/ReactSelectDropdown/ReactSelectDropdown';
import SearchWithButton from '../../components/Search/SearchWithButton';
import DatePicker from '../../components/SingleDatePicker/SingleDatePicker';
import {
  ButtonIcon,
  ColoredButtonWithIcon,
} from '../../styledComponents/buttons';
import {
  CommonGrid,
  FilterLabel,
  HeaderContainer,
  InputField,
  InputWithLabelSection,
  PageTitle,
} from '../../styledComponents/common';
import { TabLeftGrid } from '../../styledComponents/offices';
import { FilterContainer } from '../../styledComponents/tasks';

const MembersFilter = props => {
  const { t } = useTranslation();

  const {
    selectedOrganization,
    getOfficesShortList,
    officesShortList,
    selectedOffice,
    selectOffice,
    handleSearchByName,
    handleClearSearch,
    searchTerm,
    onSearchTermChange,
    history,
    ghlCustomerFetch,
    userCodeSearchTerm,
    onUserCodeSearchTermChange,
    handleSearchByUserCode,
    handleClearUserCodeSearch,
    selectedRole,
    onRoleChange,
  } = props;


  return (
    <div className="content">
      <HeaderContainer>
        <TabLeftGrid>
          <SearchWithButton
            itemName={t('member')}
            searchTermName={`${t('member_name')} `}
            searchInput={searchTerm}
            onInputChange={onSearchTermChange}
            handleSearch={handleSearchByName}
            handleClearSearch={handleClearSearch}
            fontSize="12.5px"
          />
        </TabLeftGrid>
        {/* {localStorage.getItem('user_role') &&
          localStorage.getItem('user_role') === 'admin' &&
          !localStorage.getItem('viewer_role') && (
            <ColoredButtonWithIcon
              // onClick={() => history.push('/user/member-add-update')}
              onClick={() => history.push('/user/member-invitation')}
            >
              <ButtonIcon src={Plus} />
              {t('invite_member')}
            </ColoredButtonWithIcon>
          )} */}
        <CommonGrid>
          {selectedOrganization &&
          (selectedOrganization.role === 'owner' ||
            selectedOrganization.role === 'admin') &&
          selectedOrganization.ghl_id ? (
            <ColoredButtonWithIcon
              style={{ background: '#606CFF' }}
              onClick={() => ghlCustomerFetch()}
            >
              <ButtonIcon size="20px" src={refresh_white} alt="" />
              Sync Member
            </ColoredButtonWithIcon>
          ) : null}
          {selectedOrganization &&
          (selectedOrganization.role === 'owner' ||
            selectedOrganization.role === 'admin') ? (
            <ColoredButtonWithIcon
              onClick={() => history.push('/user/member-invitation')}
            >
              <ButtonIcon src={Plus} alt="" />
              Invite Member
            </ColoredButtonWithIcon>
          ) : null}
        </CommonGrid>
      </HeaderContainer>
    </div>
  );
};

export default MembersFilter;
