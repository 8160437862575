import { connect } from 'react-redux';

import {
  getOfficeDetails,
  getOfficesShortList,
} from '../../OfficesNew/officesActions';
import {
  getOrganizationMembersList,
  getOrganizationMembersShortList,
} from '../../Projects/projectsActions';
// Actions
import {
  clearTimesheetList,
  exportWeeklyTimeSheet,
  getDailyTimesheets,
  getOtherTimesheets,
  getWeeklyTimesheetDetails,
} from '../timesheetActions';
// Component
import TimesheetWeekly from './TimesheetWeekly';

// Store props to map with current state
const mapStateToProps = state => ({
  isLoading: state.timesheet.isLoading,
  dailyTimesheetLoading: state.timesheet.dailyTimesheetLoading,

  dailyTimesheets: state.timesheet.dailyList,
  timesheet: state.timesheet.list,
  timesheetDetails: state.timesheet.weeklyTimesheetDetails,

  selectedOrganization: state.organization.selectedOrganization,
  organizationMembersList: state.project.orgMembersList,
  projects: state.project,
  projectAssigneeList: state.project.projectAssigneeList,
  taskAssigneeList: state.project.taskAssigneeList,
  profileData: state.profile.profileData,
  //office
  officesShortList: state.office.officesShortList,
  officeDetails: state.office.officeDetails,
  officeDetailsLoading: state.office.isLoading,
  //member short list
  orgMembersShortList: state.project.orgMembersShortList,
  memberShortListIsLoading: state.project.memberShortListIsLoading,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getDailyTimesheets: details => dispatch(getDailyTimesheets({ ...details })),
  getOtherTimesheets: details => dispatch(getOtherTimesheets({ ...details })),
  getWeeklyTimesheetDetails: details =>
    dispatch(getWeeklyTimesheetDetails({ ...details })),
  exportWeeklyTimeSheet: details =>
    dispatch(exportWeeklyTimeSheet({ ...details })),
  getOrganizationMembersList: details =>
    dispatch(getOrganizationMembersList({ ...details })),
  getOrganizationMembersShortList: params =>
    dispatch(getOrganizationMembersShortList(params)),
  clearTimesheetList: () => dispatch(clearTimesheetList()),
  getOfficesShortList: params => dispatch(getOfficesShortList(params)),
  getOfficeDetails: payload => dispatch(getOfficeDetails(payload)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(TimesheetWeekly);
