import { css } from '@emotion/core';
import { useEffect } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import styled from 'styled-components';

const customCss = css`
  margin: 5px;
  text-align: center;
`;

const VerifyContainer = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.main.whiteBg};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VerifyText = styled.p`
  font-size: 28px;
`;

const TextLoader = props => {
  return (
    <div className="sweet-loading">
      <PulseLoader
        size={8}
        color={'#2f394e'}
        loading={props.loading}
        css={customCss}
      />
    </div>
  );
};
const OnboardingLoader = props => {
  useEffect(() => {
    setTimeout(() => {
      props.history.push({
        pathname: '/user/jobs',
        state: { message: 'onboard' },
      });
    }, 5000);
  }, []);
  return (
    <VerifyContainer>
      <VerifyText>Sit tight, we are getting things ready for you</VerifyText>
      <TextLoader loading />
    </VerifyContainer>
  );
};

export default OnboardingLoader;
