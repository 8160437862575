import moment from "moment";
import { useEffect, useState } from "react";

import avatar5 from "../../assets/img/avatar_5.svg";
import "../../assets/scss/reportDateRange.css";
import { filterOption } from "../../components/ReactSelectDropdown/ReactSelectDropdown";
import AddSingleMemberDropdown from "../../components/CustomDropdown/AddSingleMemberDropdown";
import CreateProjectDropdown from "../../components/DropdownStyle/CreateProjectDropdown";
import MembersDropdownStyle from "../../components/DropdownStyle/MembersDropdownStyle";
import ReactDateRangePicker from "../../components/ReactDateRangePicker/ReactDateRangePicker";
import Select from "../../components/ReactSelectDropdown";
import { TodayButton } from "../../styledComponents/common";
import {
  FilterLabel,
  InputWithLabelSection,
} from "../../styledComponents/common";
import { SelectSection } from "../../styledComponents/payroll";

const PayrollFilter = (props) => {
  const [memberOptions, setMemberOptions] = useState([]);
  const sortOptions = [
    { value: "oldToNew", label: "Date (Old to New)" },
    { value: "newToOld", label: "Date (New to Old)" },
  ];

  const {
    selectedOrganization,
    selectedOffice,
    setSelectedOffice,
    officeOptions,
    selectedMember,
    setSelectedMember,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      let payload = {
        organization_id: selectedOrganization.id,
        start: moment(props.startDate).format("YYYY-MM-DD"),
        end: moment(props.endDate).format("YYYY-MM-DD"),
      };
      if (props.tabSelect === "hourly") {
        props.getPayablesData(payload);
      } else if (props.tabSelect === "history") {
        setTimeout(() => {
          let historyPayload = {
            organization_id: selectedOrganization.id,
            start_date: moment(props.startDate).format("YYYY-MM-DD"),
            end_date: moment(props.endDate).format("YYYY-MM-DD"),
          };
          console.log(selectedMember);
          console.log(selectedOffice);
          if (selectedOffice && selectedOffice.value) {
            historyPayload.office_id = selectedOffice.value;
          }
          if (selectedMember && selectedMember.id) {
            historyPayload.user_ids = [selectedMember.id];
          }
          if (props.historyType && props.historyType === "hourly_basis") {
            props.getHourlyPaymentHistory(historyPayload);
          } else if (
            props.historyType &&
            props.historyType === "fixed_amount"
          ) {
            props.getOnetimePaymentHistory(historyPayload);
          }
        }, 200);
      }
      props.getOrganizationMembersShortList({
        organization_id: selectedOrganization.id,
      });
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (props.orgMembersShortList && props.orgMembersShortList.length > 0) {
      setMemberOptions(props.orgMembersShortList);
    } else if (
      props.orgMembersShortList &&
      props.orgMembersShortList.length === 0
    ) {
      setMemberOptions([]);
    }
  }, [props.orgMembersShortList]);

  useEffect(() => {
    if (props.officeMembersList && props.officeMembersList.length > 0) {
      let assigneeList = [];
      props.officeMembersList.map((assignee) => {
        assigneeList.push({
          id: assignee.user.id,
          name: `${assignee.user.first_name} ${assignee.user.last_name}`,
          avatar: assignee.user.avatar ? assignee.user.avatar : avatar5,
        });
      });
      setMemberOptions(assigneeList);
    } else if (
      props.officeMembersList &&
      props.officeMembersList.length === 0
    ) {
      setMemberOptions([]);
    }
  }, [props.officeMembersList]);

  useEffect(() => {
    if (props.historyType && props.historyType === "fixed_amount") {
      if (selectedOrganization && selectedOrganization.id) {
        let payload = {
          organization_id: selectedOrganization.id,
          start_date: moment(props.startDate).format("YYYY-MM-DD"),
          end_date: moment(props.endDate).format("YYYY-MM-DD"),
        };
        props.getOnetimePaymentHistory(payload);
      }
    }
  }, [props.historyType]);

  const onOfficeChange = (e) => {
    let organizationId = selectedOrganization ? selectedOrganization.id : "";
    if (organizationId) {
      if (props.tabSelect === "hourly") {
        const payload = {
          organization_id: organizationId,
          start: moment(props.startDate).format("YYYY-MM-DD"),
          end: moment(props.endDate).format("YYYY-MM-DD"),
        };
        if (selectedMember && e && e.value) {
          payload.user_ids = [selectedMember.id];
        }
        if (e && e.value) {
          payload.office_id = e.value;
        }
        props.getPayablesData(payload);
      } else if (props.tabSelect === "history") {
        let historyPayload = {
          organization_id: organizationId,
          start_date: moment(props.startDate).format("YYYY-MM-DD"),
          end_date: moment(props.endDate).format("YYYY-MM-DD"),
        };
        if (selectedMember && e && e.value) {
          historyPayload.user_ids = [selectedMember.id];
        }
        if (e && e.value) {
          historyPayload.office_id = e.value;
        }
        if (props.historyType && props.historyType === "hourly_basis") {
          props.getHourlyPaymentHistory(historyPayload);
        } else if (props.historyType && props.historyType === "fixed_amount") {
          props.getOnetimePaymentHistory(historyPayload);
        }
      }
      if (e && e.value) {
        let payload = {
          organization_id: selectedOrganization.id,
          office_id: e.value,
        };
        props.getOfficeDetails(payload);
      } else if (e.value === null) {
        props.getOrganizationMembersShortList({
          organization_id: selectedOrganization.id,
        });
      }
    }

    setSelectedOffice(e);
  };

  const onMemberSelect = (e) => {
    if (e && e.id) {
      const {
        startDate,
        endDate,
        tabSelect,
        historyType,
        getPayablesData,
        selectedOrganization,
        getHourlyPaymentHistory,
        getOnetimePaymentHistory,
      } = props;
      let organizationId = selectedOrganization ? selectedOrganization.id : "";
      if (organizationId) {
        if (tabSelect === "hourly") {
          const payload = {
            organization_id: organizationId,
            start: moment(startDate).format("YYYY-MM-DD"),
            end: moment(endDate).format("YYYY-MM-DD"),
            user_ids: [e.id],
          };
          if (selectedOffice && selectedOffice.value) {
            payload.office_id = selectedOffice.value;
          }
          getPayablesData(payload);
        } else if (tabSelect === "history") {
          const historyPayload = {
            organization_id: organizationId,
            start_date: moment(startDate).format("YYYY-MM-DD"),
            end_date: moment(endDate).format("YYYY-MM-DD"),
            user_ids: [e.id],
          };
          if (selectedOffice && selectedOffice.value) {
            historyPayload.office_id = selectedOffice.value;
          }
          if (historyType && historyType === "hourly_basis") {
            getHourlyPaymentHistory(historyPayload);
          } else if (
            props.historyType &&
            props.historyType === "fixed_amount"
          ) {
            getOnetimePaymentHistory(historyPayload);
          }
        }
        setSelectedMember(e);
      }
    }
  };

  const clearSelectedMember = () => {
    const {
      getPayablesData,
      selectedOrganization,
      getHourlyPaymentHistory,
      getOnetimePaymentHistory,
      tabSelect,
      startDate,
      endDate,
      historyType,
    } = props;
    let organizationId = selectedOrganization ? selectedOrganization.id : "";
    if (organizationId) {
      if (tabSelect === "hourly") {
        const payload = {
          organization_id: organizationId,
          start: moment(startDate).format("YYYY-MM-DD"),
          end: moment(endDate).format("YYYY-MM-DD"),
        };
        if (selectedOffice && selectedOffice.value) {
          payload.office_id = selectedOffice.value;
        }
        getPayablesData(payload);
      } else if (tabSelect === "history") {
        let historyPayload = {
          organization_id: organizationId,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
        };
        if (selectedOffice && selectedOffice.value) {
          historyPayload.office_id = selectedOffice.value;
        }
        if (historyType && historyType === "hourly_basis") {
          getHourlyPaymentHistory(historyPayload);
        } else if (historyType && historyType === "fixed_amount") {
          getOnetimePaymentHistory(historyPayload);
        }
      }
    }
    setSelectedMember("");
  };

  return (
    <SelectSection columns={"230px 40px 280px 90px 230px"} gap="20px">
      <InputWithLabelSection>
        <FilterLabel>Offices</FilterLabel>
        <Select
          isSearchable
          value={selectedOffice}
          options={officeOptions}
          placeholder="Select Office..."
          isDisabled={selectedMember ? true : false}
          onChange={(e) => onOfficeChange(e)}
          styles={CreateProjectDropdown()}
          // clearable
          filterOption={filterOption}
        />
      </InputWithLabelSection>

      <InputWithLabelSection>
        <FilterLabel>For</FilterLabel>
        <AddSingleMemberDropdown
          assigneeManage={selectedMember}
          updateState={onMemberSelect}
          membersList={memberOptions}
          clearAssignee={() => clearSelectedMember()}
        />
      </InputWithLabelSection>

      <InputWithLabelSection>
        <FilterLabel>Date Range</FilterLabel>
        <ReactDateRangePicker
          startDate={props.startDate}
          startDateId="custom-date-range-start-date"
          endDate={props.endDate}
          endDateId="custom-date-range-end-date"
          onDatesChange={props.changeDateRange}
          dateDisplayFormat="DD/MM/YYYY"
          onDateSelect={props.onDateSelect}
          selectLastWeek={props.selectLastWeek}
          selectLastMonth={props.selectLastMonth}
          disableFutureDates={true}
        />
      </InputWithLabelSection>

      <InputWithLabelSection>
        <div style={{ height: "18px" }} />
        <TodayButton onClick={() => props.onDateSelect(true)}>
          Today
        </TodayButton>
      </InputWithLabelSection>

      <InputWithLabelSection>
        <FilterLabel>Sort By</FilterLabel>
        <Select
          isSearchable
          value={props.sortType}
          options={sortOptions}
          onChange={(e) => {
            props.changeSortType(e);
          }}
          styles={MembersDropdownStyle({ minHeight: "40px" })}
        />
      </InputWithLabelSection>
    </SelectSection>
  );
};

export default PayrollFilter;
