import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

import downArrowIcon from '../../assets/img/icons/down_arrow.svg';
import upArrowIcon from '../../assets/img/icons/up_arrow.svg';
import { CommonText } from '../../styledComponents/common';
import {
  SmallCardContent,
  SmallCardLabel,
  SmallCardPercentage,
} from '../../styledComponents/dashboard';
import {
  DataDiv,
  DataDivHeaderWrapper,
  PercentageArrowIcon,
  SmallCardContainer,
} from './cardStyles';

const JobsStatsCard = ({ cardTitle, datasets }) => {
  const [dataIncrease, setDataIncrease] = useState('equal');
  const [cardPercentage, setCardPercentage] = useState('0.00%');
  const [status, setStatus] = useState('equal');

  const startOfWeek = moment()
    .subtract(1, 'day')
    .startOf('isoWeek')
    .format('MMM DD/YY');
  const endOfWeek = moment()
    .subtract(1, 'day')
    .endOf('isoWeek')
    .format('MMM DD/YY');

  useEffect(() => {
    if(cardTitle === 'Approved Jobs'){
      parseInt(datasets.current_week) > parseInt(datasets.previous_week)
      ? setDataIncrease('increase')
      : parseInt(datasets.current_week) === parseInt(datasets.previous_week)
      ? setDataIncrease('equal')
      : setDataIncrease('decrease');
    }
    else{
      parseInt(datasets.current_week) < parseInt(datasets.previous_week)
      ? setDataIncrease('increase')
      : parseInt(datasets.current_week) === parseInt(datasets.previous_week)
      ? setDataIncrease('equal')
      : setDataIncrease('decrease');
    }

    let percentage = 0;
    if (parseInt(datasets.current_week) === parseInt(datasets.previous_week)) {
      percentage = 0;
    } else if (
      parseInt(datasets.previous_week) === 0 ||
      parseInt(datasets.current_week) === 0
    ) {
      percentage = 100;
    } else {
      percentage = Math.abs(
        (parseInt(datasets.current_week) / parseInt(datasets.previous_week)) *
          100 -
          100,
      ).toFixed(1);
    }
    setCardPercentage(`${percentage}%`);
  }, [datasets]);

  useEffect(() => {
    if (dataIncrease === 'equal') {
      setStatus('equal');
    } else if (dataIncrease === 'increase') {
      setStatus('increase');
    } else if (dataIncrease === 'decrease') {
      setStatus('decrease');
    }
  }, [dataIncrease]);



  return (
    <SmallCardContainer>
      <DataDiv>
        <DataDivHeaderWrapper>
          <SmallCardLabel
            fontSize="16px"
            style={{ fontWeight: '600', marginBottom: '10px' }}
          >
            {cardTitle ? cardTitle : 'Comparison Card'}
          </SmallCardLabel>
        </DataDivHeaderWrapper>

        <CommonText>
          {startOfWeek} - {endOfWeek}
        </CommonText>
        <DataDivHeaderWrapper style={{ marginTop: '20px' }}>
          <SmallCardContent>{datasets.current_week}</SmallCardContent>
          {cardPercentage !== 'NaN%' && (
            <SmallCardPercentage status={status} percentage={cardPercentage}>
              {dataIncrease !== 'equal' ? (
                <PercentageArrowIcon
                  alt=""
                  src={
                    dataIncrease === 'increase' ? upArrowIcon : downArrowIcon
                  }
                />
              ) : null}
              {cardPercentage}
            </SmallCardPercentage>
          )}
        </DataDivHeaderWrapper>
      </DataDiv>
    </SmallCardContainer>
  );
};

export default JobsStatsCard;
