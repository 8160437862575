export const planFeatures = {
  solo: [
    "Time Tracking",
    "Task Management",
    "Projects & Budgeting",
    "Client & Invoice",
    "Reporting & Dashboard",
    "Schedules (Coming Soon)",
    "Desktop App",
    "Mobile App",
    "Apps & Devices",
  ],
  standard: [
    "Integrations",
    "Team & Admin (Team, Settings, Profile, Organisation)",
    "Payroll & Members",
    "Attendance & Leaves [Coming Soon]",
  ],
  premium: [
    "Employee Gps Location",
    "Geofencing Attendance",
    "Enforce Location & Policies",
    "Project & Member-Wise View",
    "Location Notes",
  ],
  elite: [
    "Optional Screenshots",
    "Apps Usage Tracking",
    "Urls Tracking",
    "Activity & Engagement Analytics",
    "Desktop Monitoring",
  ],
};
