//Effects
import { all, put, select, takeLatest } from 'redux-saga/effects';

import {
  ADD_TASK_LOCATION,
  ADD_TASK_LOCATION_FAILURE,
  ADD_TASK_LOCATION_SUCCESS,
  CREATE_TASK,
  CREATE_TASK_FAILURE,
  CREATE_TASK_SUCCESS,
  DELETE_TASK,
  DELETE_TASK_FAILURE,
  DELETE_TASK_SUCCESS,
  DUPLICATE_TASK,
  DUPLICATE_TASK_FAILURE,
  DUPLICATE_TASK_SUCCESS,
  EDIT_TASK,
  EDIT_TASK_FAILURE,
  EDIT_TASK_SUCCESS,
  GET_TASKS_LIST,
  GET_TASKS_LIST_FAILURE,
  GET_TASKS_LIST_SUCCESS,
  GET_TASK_DETAILS,
  GET_TASK_DETAILS_FAILURE,
  GET_TASK_DETAILS_SUCCESS,
  GET_TASK_LOCATIONS,
  GET_TASK_LOCATIONS_FAILURE,
  GET_TASK_LOCATIONS_SUCCESS,
  GET_TASK_TYPE,
  GET_TASK_TYPE_FAILURE,
  GET_TASK_TYPE_SUCCESS,
  UPDATE_TASK_STATUS,
  UPDATE_TASK_STATUS_FAILURE,
  UPDATE_TASK_STATUS_SUCCESS,
} from '../../modules/constants';
//Service
import { defaultApi } from '../../utils/axiosApi';
import details from '../IntegrationsNew/Details';

export function* handleGetTasksList({ payload }) {
  const {
    date,
    page,
    task_name,
    office_id,
    assignee_id,
    status,
    approval_status,
  } = payload;
  try {
    const url = `/tasks/?required_start_date=${date}${
      page ? `&page=${page}` : ''
    }${task_name ? `&name=${task_name}` : ''}${
      office_id ? `&office_id=${office_id}` : ''
    }${assignee_id ? `&assignees=${assignee_id}` : ''}${
      status ? `&status=${status}` : ''
    }${approval_status ? `&approval_status=${approval_status}` : ''}`;
    const response = yield defaultApi(url, 'GET', payload);
    yield put({
      type: GET_TASKS_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_TASKS_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* handleCreateTaskList({ payload }) {
  try {
    const { ...details } = payload;
    const url = `/tasks/`;
    const method = 'POST';
    const response = yield defaultApi(url, method, details);
    yield put({
      type: CREATE_TASK_SUCCESS,
      payload: { data: response, notify: 'POST' },
    });
  } catch (err) {
    yield put({
      type: CREATE_TASK_FAILURE,
      payload: err,
    });
  }
}

export function* handleGetTaskDetails({ payload }) {
  try {
    const { task_id } = payload;
    const url = `/tasks/${task_id}/`;
    const method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_TASK_DETAILS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_TASK_DETAILS_FAILURE,
      payload: err,
    });
  }
}

export function* handleEditTask({ payload }) {
  try {
    const { task_id, ...details } = payload;
    const url = `/tasks/${task_id}/`;
    const response = yield defaultApi(url, 'PUT', details);
    yield put({
      type: EDIT_TASK_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: EDIT_TASK_FAILURE,
      payload: err,
    });
  }
}

export function* handleDuplicateTask({ payload }) {
  try {
    const { organization_id, ...details } = payload;
    const url = `/tasks/duplicate-task`;
    const method = 'POST';
    const response = yield defaultApi(url, method, details);

    yield put({
      type: DUPLICATE_TASK_SUCCESS,
      payload: { data: response, notify: 'POST' },
    });
  } catch (err) {
    yield put({
      type: DUPLICATE_TASK_FAILURE,
      payload: err,
    });
  }
}

export function* handleDeleteTask({ payload }) {
  try {
    const { task_id } = payload;
    const url = `/tasks/${task_id}/`;
    const method = 'DELETE';
    const response = yield defaultApi(url, method);
    yield put({
      type: DELETE_TASK_SUCCESS,
      payload: { data: response, task_id, notify: 'DELETE' },
    });
  } catch (err) {
    yield put({
      type: DELETE_TASK_FAILURE,
      payload: err,
    });
  }
}

export function* handleGetTaskLocations({ payload }) {
  try {
    let office_id;
    if (payload && payload.office_id) {
      office_id = payload.office_id;
    }
    const url = `/task_locations/${office_id ? `?office_id=${office_id}` : ''}`;
    const response = yield defaultApi(url, 'GET');
    yield put({
      type: GET_TASK_LOCATIONS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_TASK_LOCATIONS_FAILURE,
      payload: err,
    });
  }
}

export function* handleAddTaskLocation({ payload }) {
  try {
    const { ...details } = payload;
    const url = `/task_locations/`;
    const method = 'POST';
    const response = yield defaultApi(url, method, details);

    yield put({
      type: ADD_TASK_LOCATION_SUCCESS,
      payload: { data: response, notify: 'POST' },
    });
  } catch (err) {
    yield put({
      type: ADD_TASK_LOCATION_FAILURE,
      payload: err,
    });
  }
}
export function* handleTaskApproval({ payload }) {
  const { task_id, approval_id, details } = payload;
  try {
    const url = `tasks/${task_id}/approval/${approval_id}/`;
    const method = 'PUT';
    const response = yield defaultApi(url, method, details);
    yield put({
      type: UPDATE_TASK_STATUS_SUCCESS,
      payload: { data: response, task_id },
    });
  } catch (err) {
    yield put({
      type: UPDATE_TASK_STATUS_FAILURE,
      payload: err,
    });
  }
}

export function* handleGetTaskType({}) {
  try {
    const url = `/tasks/task-type/`;
    const response = yield defaultApi(url, 'GET', null);
    yield put({
      type: GET_TASK_TYPE_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_TASK_TYPE_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    // Get tasks list
    takeLatest(GET_TASKS_LIST, handleGetTasksList),
    // Create task
    takeLatest(CREATE_TASK, handleCreateTaskList),
    //Get task details
    takeLatest(GET_TASK_DETAILS, handleGetTaskDetails),
    //Update task
    takeLatest(EDIT_TASK, handleEditTask),
    //Duplicate task
    takeLatest(DUPLICATE_TASK, handleDuplicateTask),
    //Delete task
    takeLatest(DELETE_TASK, handleDeleteTask),
    // Get task location
    takeLatest(GET_TASK_LOCATIONS, handleGetTaskLocations),
    // Add task location
    takeLatest(ADD_TASK_LOCATION, handleAddTaskLocation),

    takeLatest(UPDATE_TASK_STATUS, handleTaskApproval),
    // Get task type
    takeLatest(GET_TASK_TYPE, handleGetTaskType),
  ]);
}
