import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Avatar5 from '../../assets/img/avatar_5.svg';
import editIcon from '../../assets/img/icons/edit-pen.svg';
import createProjectDropdown from '../../components/DropdownStyle/CreateProjectDropdown';
import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent';
import ProjectListPopup from '../../components/ProjectListPopup/ProjectListPopup';
import Select from '../../components/ReactSelectDropdown';
import TaskActionTooltip from '../../components/Tooltip/TaskActionTooltip';
import {
  AssigneeMoreText,
  ColoredText,
  CommonFlex,
  CommonImage,
  CommonText,
  Container,
  ResendInvite,
  RoundLetterSection,
  StyledLink,
  TableText,
} from '../../styledComponents/common';
import {
  ToggleButton,
  ToggleButtonRound,
} from '../../styledComponents/invoice';
import {
  InviteHeadSection,
  InviteLink,
  InviteProjectSection,
  InviteTableContainer,
  MemberNameDiv,
  MembersTableContainer,
  MembersTableHeadContainer,
  RadioButton,
  TeamSection,
  UserSettings,
} from '../../styledComponents/members';
import { AssignedProjectBox, TableItem } from '../../styledComponents/teams';
import { RoundIcon } from '../TasksNew/tasksStyles';
import {
  ApprovalSettingsSection,
  CommFirstRoundLetterComp,
  CommImageInviteComp,
  CommImageNameComp,
} from './CommonComponents';

import { currencySymbolMap } from "../../utils/currencies";

const MembersTable = props => {
  const { t } = useTranslation();
  const [role, setRole] = useState(null);
  const { selectedOrganization } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.role) {
      setRole(selectedOrganization.role);
    }
  }, [selectedOrganization]);

  const updateTimesheetApprovalSettings = (memberId, value) => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      memberId
    ) {
      const payload = {
        organization_id: props.selectedOrganization.id,
        settings_id: memberId,
        require_timesheet_approval: value,
      };
      props.updateMemberwiseTimesheetApprovalSettings(payload);
    }
  };

  return (
    <Container style={{ paddingBottom: `20px` }}>
      {props.isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <>
          <MembersTableHeadContainer
            style={{ borderBottom: `1px solid #C2CCE1` }}
          >
            <TableItem>
              <TableText>{t('member_name')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('email')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('role')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('contact_number')}</TableText>
            </TableItem>
              <TableItem>
                <TableText>{t('payment')}</TableText>
              </TableItem>
            {role && role !== 'member' ? (
              <TableItem>
                <TableText>{t('actions')}</TableText>
              </TableItem>
            ) : (
              <></>
            )}
          </MembersTableHeadContainer>
          {props.membersList && props.membersList.length > 0 ? (
            props.membersList.map((member, index) => (
              <MembersTableContainer key={index}>
                <TableItem>
                  <MemberNameDiv
                    onClick={() =>
                      props.history.push(
                        `/user/member-details?id=${member.user.id}`,
                      )
                    }
                  >
                    <CommImageNameComp
                      imgSource={
                        member.user.avatar ? member.user.avatar : Avatar5
                      }
                      name={`${member.user.first_name} ${member.user.last_name}`}
                      size="36px"
                      fontSize="13px"
                    />
                  </MemberNameDiv>
                </TableItem>
                <TableItem fontSize="12.5px" style={{overflowWrap: `anywhere`}} >{member.user.email || `-`}</TableItem>
                <TableItem>{member.role ? member.role : `-`}</TableItem>
                <TableItem fontSize="12.5px">{member.phone ? member.phone : `-`}</TableItem>
                <TableItem>
                <TableText fontSize="12.5px" name>
                  {!member.payment
                    ? "No pay/bill rate set"
                    : `${currencySymbolMap[member.payment.currency]} ${(
                      member.payment.pay_rate / 100
                    ).toFixed(2)} / hour` }
                </TableText>
                {role !== "member" && !member.payment && (
                    <ResendInvite
                      margin="3px 0 0 0"
                      onClick={() =>
                        props.history.push(
                          `/user/member-details?id=${member.user.id}`
                        )
                      }
                    >
                      Set Pay Rate
                    </ResendInvite>
                  )}
                  <ApprovalSettingsSection
                  memberId={member.id}
                  active={member.require_timesheet_approval}
                  handleChange={updateTimesheetApprovalSettings}
                  isAdmin={
                    props.selectedOrganization &&
                    props.selectedOrganization.role !== "member"
                  }
                  fontSize="12.5px"
                />
              </TableItem>

                {role === "owner" || (role === 'admin' && member.role !== 'owner')  ? (
                  <TableItem>
                    <RoundIcon
                      type="edit"
                      onClick={() =>
                        props.history.push(
                          `/user/member-details?id=${member.user.id}`
                        )
                      }
                    >
                      <CommonImage src={editIcon} alt="" size="16px" />
                      <TaskActionTooltip toolTipText={t('edit_member')} />
                    </RoundIcon>
                  </TableItem>
                ): <></>}
              </MembersTableContainer>
            ))
          ) : (
            <NoDataComponent
              title="No Members Found!"
              padding="5vh 0"
              imageHeight="140px"
            />
          )}
        </>
      )}
    </Container>
  );
};

export default MembersTable;
