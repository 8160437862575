import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import avatar5 from '../../assets/img/avatar_5.svg';
import officeIcon from '../../assets/img/icons/black-site-icon.svg';
import Chat from '../../assets/img/icons/chat.svg';
import Bean from '../../assets/img/icons/delete.svg';
import deleteWhiteIcon from '../../assets/img/icons/delete_white.svg';
import editWhiteIcon from '../../assets/img/icons/edit-white.svg';
import File from '../../assets/img/icons/file.svg';
import Pencil from '../../assets/img/icons/pencil.svg';
import DotLoader from '../../components/DropdownLoader/DropdownLoader';
import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent';
import SearchWithButton from '../../components/Search/SearchWithButton';
import AssigneeTooltip from '../../components/Tooltip/AssigneeTooltip';
import {
  ButtonIcon,
  ColoredButtonWithIcon,
  PrimaryButton,
} from '../../styledComponents/buttons';
import {
  ClientLocationsTableHeaderContainer,
  ClientLocationsTableRowContainer,
  HoveredSection2,
  InnerDivTooltip,
  RoundHover,
  ToolTipContainer,
  TooltipText,
} from '../../styledComponents/clients';
import {
  AssignListText,
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonImage,
  CommonText,
  Container,
  HeaderContainer,
  InputField,
  InputWithLabelSection,
  ShowMoreAssigneeList,
  TableText,
} from '../../styledComponents/common';
import {
  AssigneeRoundImage,
  AssigneeRoundImageSection,
  RoundImageCross,
  RoundPersonImage,
} from '../../styledComponents/createProject';
import { MemberNameDiv } from '../../styledComponents/members';
import { TableItem } from '../../styledComponents/teams';
import { TeamMemberAvatar } from '../../styledComponents/teams';
import {
  DeleteButton,
  EditButton,
  HoveredSection,
  ListHeaderContainer,
  ListItemsContainer,
  NoDataArtwork,
  NoDataContainer,
  OfficesListContainer,
  SingleSiteItem,
} from '../FieldServices/JobSites/jobSitesStyles';
import { CommImageNameComp } from '../Members/CommonComponents';
import DeleteOfficeModal from './DeleteOfficeModal';
import {
  InfoMapSection,
  InfoSection,
  Tabsection,
  TitleTag,
} from './officesStyles';

const OfficeList = props => {
  const { t } = useTranslation();
  const [officeDeleteId, setOfficeDeleteId] = useState('');
  const [officeList, setOfficeList] = useState([]);
  const [selectIndex, setSelectIndex] = useState(-1);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [toolTip, setToolTip] = useState(-1);
  const [moreAssignee, setMoreAssignee] = useState(false);
  const [visibility, setVisibility] = useState(0);
  const moreRef1 = useRef(null);
  const moreRef2 = useRef(null);

  useEffect(() => {
    if (!props.isModalOpen) {
      setOfficeDeleteId('');
    }
  }, [props.isModalOpen]);

  const toolTipChange = (value, index) => {
    setToolTip(value);
    setSelectIndex(index);
  };

  const toggle = id => {
    setOfficeDeleteId(id);
    props.modalToggle();
  };

  const showMoreAssignee = index => {
    setMoreAssignee(true);
    setSelectedRow(index);
  };

  const visibilityChange = state => {
    setVisibility(state);
  };

  //function
  useOutsideClickHandler(moreRef1, moreRef2);
  function useOutsideClickHandler(moreRef1, moreRef2) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          moreRef1.current &&
          !moreRef1.current.contains(event.target) &&
          moreRef2.current &&
          !moreRef2.current.contains(event.target)
        ) {
          setMoreAssignee(false);
          visibilityChange(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [moreRef1, moreRef2, moreAssignee]);
  }

  return (
    <Container>
      {props.isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <>
          <DeleteOfficeModal
            id={officeDeleteId ? officeDeleteId : ''}
            isOpen={props.isModalOpen}
            toggle={props.modalToggle}
            handleDelete={props.handleDelete}
          />

          {props.officeList && props.officeList.length > 0 && (
            <>
              {/* table code */}
              <ClientLocationsTableHeaderContainer
                columns={
                  props.selectedOrganization &&
                  (props.selectedOrganization.role === 'admin' ||
                    props.selectedOrganization.role === 'owner')
                    ? 6
                    : 5
                }
              >
                <TableItem>
                  <TableText>{t('office_name')}</TableText>
                </TableItem>
                <TableItem>
                  <TableText>{t('manager')}</TableText>
                </TableItem>
                <TableItem>
                  <TableText>{t('office_area')}</TableText>
                </TableItem>
                <TableItem>
                  <TableText>{t('location_radius')}</TableText>
                </TableItem>
                <TableItem>
                  <TableText>{t('assignees')}</TableText>
                </TableItem>
                {props.selectedOrganization &&
                  (props.selectedOrganization.role === 'admin' ||
                    props.selectedOrganization.role === 'owner') && (
                    <TableItem>
                      <TableText></TableText>
                    </TableItem>
                  )}
              </ClientLocationsTableHeaderContainer>
              {props.officeList.map((office, index) => {
                let managerFound = false;

                return (
                  <>
                    {(props.officeUpdateLoading &&
                      props.updatingOfficeId === office.id) ||
                    (props.officeDeleteLoading &&
                      props.deletingOfficeId === office.id) ? (
                      <DotLoader loading />
                    ) : (
                      <ClientLocationsTableRowContainer
                        columns={
                          props.selectedOrganization &&
                          (props.selectedOrganization.role === 'admin' ||
                            props.selectedOrganization.role === 'owner')
                            ? 6
                            : 5
                        }
                        key={office.id}
                      >
                        <TableItem
                        // style={{ cursor: 'pointer' }}
                        // onClick={() =>
                        //   props.history.push(
                        //     `/user/customer-details?id=${client.id}`
                        // )}
                        >
                          <CommonText>{office.name}</CommonText>
                        </TableItem>
                        <TableItem
                          style={{ cursor: 'pointer' }}
                          // onClick={() =>
                          //   props.history.push(
                          //     `/user/customer-details?id=${client.id}`,
                          //   )
                          // }
                        >
                          {office.employees.map(employee => {
                            if (!managerFound && employee.can_manage) {
                              managerFound = true;

                              return (
                                <div key={employee.id}>
                                  <MemberNameDiv
                                    onClick={() =>
                                      props.history.push(
                                        `/user/member-details?id=${employee.user}`,
                                      )
                                    }
                                  >
                                    <CommImageNameComp
                                      imgSource={employee.avatar || avatar5}
                                      name={employee.name}
                                      size="36px"
                                    />
                                  </MemberNameDiv>
                                </div>
                              );
                            }
                            return null;
                          })}

                          {/* Display message if no manager is found in the office */}
                          {!managerFound && <CommonText>-</CommonText>}
                        </TableItem>
                        <TableItem>
                          <CommonText>
                            {office.location &&
                            office.location[0] &&
                            office.location[0].address
                              ? office.location[0].address
                              : ''}
                          </CommonText>
                        </TableItem>

                        <TableItem>
                          <CommonText>
                            {office.location &&
                            office.location[0] &&
                            office.location[0].radius
                              ? office.location[0].radius
                              : ''}
                          </CommonText>
                        </TableItem>
                        <TableItem style={{ cursor: 'pointer' }}>
                          <AssigneeRoundImageSection
                            position={office.employees.length}
                            style={{ marginRight: `auto` }}
                          >
                            {office.employees.length > 3 && (
                              <AssigneeRoundImage
                                index="more"
                                onClick={() => {
                                  showMoreAssignee(index);
                                  visibilityChange(true);
                                }}
                                ref={moreRef2}
                                style={{
                                  border: `1px solid #fff`,
                                  paddingLeft: '3px',
                                }}
                              >
                                {`+${office.employees.length - 3}`}
                              </AssigneeRoundImage>
                            )}
                            <>
                              {office.employees.length > 0 ? (
                                <>
                                  {office.employees
                                    .filter((person, index) => index < 3)
                                    .map((filteredPerson, index) => (
                                      <AssigneeRoundImage
                                        key={index}
                                        index={
                                          office.employees.length < 4
                                            ? index + 1
                                            : index + 2
                                        }
                                      >
                                        <AssigneeTooltip
                                          toolTipText={filteredPerson.name}
                                        />
                                        <RoundPersonImage
                                          src={
                                            filteredPerson.avatar
                                              ? filteredPerson.avatar
                                              : avatar5
                                          }
                                          alt=""
                                        />
                                      </AssigneeRoundImage>
                                    ))}
                                  {moreAssignee && selectedRow === index && (
                                    <ShowMoreAssigneeList ref={moreRef1}>
                                      {office.employees.map((member, index) => (
                                        <Fragment key={index}>
                                          <AssigneeRoundImage
                                            index={0}
                                            size="30px"
                                          >
                                            <RoundPersonImage
                                              src={
                                                member.avatar
                                                  ? member.avatar
                                                  : avatar5
                                              }
                                              alt=""
                                              size="30px"
                                              borderColor="#c2cce1"
                                            />
                                          </AssigneeRoundImage>
                                          <AssignListText>
                                            {member.name}
                                          </AssignListText>
                                        </Fragment>
                                      ))}
                                    </ShowMoreAssigneeList>
                                  )}
                                </>
                              ) : (
                                <CommonText>
                                  No assignee is set for this office.
                                </CommonText>
                              )}
                            </>
                          </AssigneeRoundImageSection>
                        </TableItem>
                        {props.selectedOrganization &&
                          (props.selectedOrganization.role === 'admin' ||
                            props.selectedOrganization.role === 'owner') && (
                            <TableItem>
                              <HoveredSection2>
                                <RoundHover
                                  onMouseEnter={() => toolTipChange(1, index)}
                                  onMouseLeave={() => toolTipChange(-1)}
                                  onClick={() =>
                                    props.history.push(
                                      `/user/office-details?id=${office.id}`,
                                    )
                                  }
                                >
                                  <img src={Pencil} alt="pencil" width="15px" />
                                  {selectIndex === index && toolTip === 1 && (
                                    <ToolTipContainer>
                                      <InnerDivTooltip>
                                        <img
                                          src={Chat}
                                          alt="chat"
                                          width="64px"
                                        />
                                        <TooltipText>{t('edit')}</TooltipText>
                                      </InnerDivTooltip>
                                    </ToolTipContainer>
                                  )}
                                </RoundHover>
                                <RoundHover
                                  onMouseEnter={() => toolTipChange(3, index)}
                                  onMouseLeave={() => toolTipChange(-1)}
                                  onClick={() => toggle(office.id)}
                                >
                                  <ButtonIcon src={Bean} alt="" />
                                  {selectIndex === index && toolTip === 3 && (
                                    <ToolTipContainer>
                                      <InnerDivTooltip>
                                        <img
                                          src={Chat}
                                          alt="chat"
                                          width="64px"
                                        />
                                        <TooltipText>{t('delete')}</TooltipText>
                                      </InnerDivTooltip>
                                    </ToolTipContainer>
                                  )}
                                </RoundHover>
                              </HoveredSection2>
                            </TableItem>
                          )}
                      </ClientLocationsTableRowContainer>
                    )}
                  </>
                );
              })}
            </>
          )}
          {props.officeList && props.officeList.length === 0 && (
            <NoDataComponent
              title={
                props.searchTerm ? (
                  t('no_office_found_with_this_name')
                ) : (
                  <>
                    <CommonText
                      fontSize="15px"
                      fontWeight="600"
                      margin="20px 0 0"
                    >
                      No Offices!
                    </CommonText>
                    <CommonText
                      $label
                      fontSize="13px"
                      fontWeight="500"
                      margin="15px 0 0"
                    >
                      Add your very first office
                    </CommonText>
                  </>
                )
              }
            />
          )}
        </>
      )}
    </Container>
  );
};

export default OfficeList;
