import { connect } from 'react-redux';

// Component
import Dashboard from './Dashboard';
// Actions
import {
  getDashboardCurrentJobs,
  getDashboardEmployeeStatus,
  getDashboardJobStatus,
  getDashboardJobsToday,
  getDashboardLateCount,
  getDashboardPerformance,
  getDashboardPreviousWeekJobStatus,
  getDashboardRecentInvoices,
  getDashboardStatus,
  getDashboardTaskProgress,
  getDashboardTopLowPerformers,
  getDashboardTopWorkOder,
  getDashboardUserAnalytics,
} from './dashboardActions';

// Store props to map with current state
const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,
  dashboardData: state.dashboard,
  // employeeStatusIsloading: state.dashboard.employeeStatusIsloading,
  employee_status: state.dashboard.employee_status,
  taskProgressIsLoading: state.dashboard.taskProgressIsLoading,
  task_progress_overview: state.dashboard.task_progress_overview,
  performanceComparisonIsLoading:
    state.dashboard.performanceComparisonIsLoading,
  performance_comparison: state.dashboard.performance_comparison,
  lateCountIsloading: state.dashboard.lateCountIsloading,
  late_count_comparison: state.dashboard.late_count_comparison,
  topLowPerformersIsLoading: state.dashboard.topLowPerformersIsLoading,
  top_low_performers: state.dashboard.top_low_performers,
  //new states
  jobsTodayIsloading: state.dashboard.jobsTodayIsloading,
  jobsToday: state.dashboard.jobsToday,
  employeeStatusIsloading: state.dashboard.employeeStatusIsloading,
  employeStatus: state.dashboard.employeStatus,
  topWorkOrderIsloading: state.dashboard.topWorkOrderIsloading,
  topWorkOrder: state.dashboard.topWorkOrder,
  jobStatusIsloading: state.dashboard.jobStatusIsloading,
  job_status: state.dashboard.job_status,
  jobStatusPreviousWeekIsloading: state.dashboard.jobStatusPreviousWeekIsloading,
  jobStatusPreviousWeek: state.dashboard.jobStatusPreviousWeek,
  currentJobsIsloading: state.dashboard.currentJobsIsloading,
  currentJobs: state.dashboard.currentJobs,
  recentInvoicesIsloading: state.dashboard.recentInvoicesIsloading,
  recentInvoices: state.dashboard.recentInvoices,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getDashboardLateCount: details =>
    dispatch(getDashboardLateCount({ ...details })),
  getDashboardPerformance: details =>
    dispatch(getDashboardPerformance({ ...details })),
  getDashboardStatus: details => dispatch(getDashboardStatus({ ...details })),
  getDashboardTaskProgress: details =>
    dispatch(getDashboardTaskProgress({ ...details })),
  getDashboardTopLowPerformers: details =>
    dispatch(getDashboardTopLowPerformers({ ...details })),
  //new

  getDashboardJobsToday: details =>
    dispatch(getDashboardJobsToday({ ...details })),
  getDashboardEmployeeStatus: details =>
    dispatch(getDashboardEmployeeStatus({ ...details })),
  getDashboardTopWorkOder: details =>
    dispatch(getDashboardTopWorkOder({ ...details })),
  getDashboardJobStatus: details =>
    dispatch(getDashboardJobStatus({ ...details })),
  getDashboardPreviousWeekJobStatus: details =>
    dispatch(getDashboardPreviousWeekJobStatus({ ...details })),
  getDashboardCurrentJobs: details =>
    dispatch(getDashboardCurrentJobs({ ...details })),
  getDashboardRecentInvoices: details =>
    dispatch(getDashboardRecentInvoices({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
