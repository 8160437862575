import moment from "moment/moment";
import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import avatar5 from "../../../assets/img/avatar_5.svg";
import audioIcon from "../../../assets/img/icons/Audio.svg";
import imageIcon from "../../../assets/img/icons/Image.svg";
import phoneIcon from "../../../assets/img/icons/call.svg";
import Chat from "../../../assets/img/icons/chat.svg";
import plusIcon from "../../../assets/img/icons/plus_white.svg";
import tick from "../../../assets/img/icons/tick-white-bold.svg";
import { CardText } from "../../../components/DashboardCards/cardStyles";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";
import TaskActionTooltip from "../../../components/Tooltip/TaskActionTooltip";
import { ListUpperSection } from "../../../styledComponents/ProjectsNew";
import { ColoredButton, WhiteButton } from "../../../styledComponents/buttons";
import {
  ClientLocationsTableHeaderContainer,
  ClientLocationsTableRowContainer,
  GeneralInfoItem,
  GeneralInfoSection,
  HoveredSection2,
  InnerDivTooltip,
  RoundHover,
  ToolTipContainer,
  TooltipText,
} from "../../../styledComponents/clients";
import {
  CommonImage,
  CommonText,
  Container,
  InputField,
  InputLabel,
  InputWithLabelSection,
  LabelWithTextSection,
  TableText,
} from "../../../styledComponents/common";
import { MemberNameDiv } from "../../../styledComponents/members";
import {
  ButtonSection,
  ReviewSection,
} from "../../../styledComponents/taskApproval";
import { TableItem } from "../../../styledComponents/teams";
import { toHHMMSS } from "../../../utils/helper";
import StaticTaskLocationModal from "../../ClientsNew/ClientDetails/StaticTaskLocationModal";
import {
  CardBlock,
  GreenCircle,
  InfoSectionCard,
  LateTag,
} from "../../FieldServices/RouteMapNew/routeMapStyles";
import { CommImageNameComp } from "../../Members/CommonComponents";
import { TaskStatusButton } from "../tasksStyles";
import AudioPlayerModal from "./AudioPlayer";
import EvidenceModal from "./EvidenceModal";
import SearchBox from "./SearchBox";
import {
  CallSection,
  EmployeeInfoBlock,
  EvidenceIcon,
  EvidenceIconSection,
  EvidenceThumbnail,
  LabelTextBlock,
  NoteSection,
  StatusBar,
  TaskInfoSection,
} from "./taskApprovalStyle";

const ApprovalTasksList = (props) => {
  const { t, i18n } = useTranslation();

  const [jobsList, setJobsList] = useState([]);

  const [supervisorNote, setSupervisorNote] = useState("");
  const [staticLocationModalOpen, setStaticLocationModalOpen] = useState(false);
  const [selectedStaticTaskLocation, setSelectedStaticTaskLocation] = useState(
    null
  );
  const [selectedNoteCard, setSelectedNoteCard] = useState(null);

  //Image Modal
  const [popupOpen, setPopupOpen] = useState(false);
  const [evidenceImage, setEvidenceImage] = useState(null);

  //Audio Modal
  const [popupOpenAudio, setPopupOpenAudio] = useState(false);
  const [evidenceAudio, setEvidenceAudio] = useState(null);

  // ToolTip
  const [selectIndex, setSelectIndex] = useState(-1);
  const [toolTip, setToolTip] = useState(-1);

  const {
    history,
    handleClearSearch,
    handleSearchByName,
    onSearchTermChange,
    searchTerm,
    isLoading,
    jobList,
    updateTaskStatusRequest,
    selectedTab,
  } = props;

  useEffect(() => {
    if (jobList && jobList.length > 0) {
      jobList.forEach((item) => {
        const assigneesData = [...item.assignees];

        assigneesData.forEach((assignee, index) => {
          const checkedin = item.checkinout.find(
            (obj) => obj.event === "checkin" && obj.user === assignee.id
          );
          const checkedout = item.checkinout.find(
            (obj) => obj.event === "checkout" && obj.user === assignee.id
          );
          const evidence = item.evidences.find(
            (obj) => obj.created_by === assignee.id
          );

          if (checkedin) {
            assigneesData[index].checkedin = checkedin;
          }
          if (checkedout) {
            assigneesData[index].checkedout = checkedout;
          }
          /* 
          +------------+-------------+---------------+-----------------+-------------+
          | Checked In | Checked Out | Job End Time  | Assignee Status | Results     |
          +------------+-------------+---------------+-----------------+-------------+
          | True       |             |               | False           | In Progress |
          +------------+-------------+---------------+-----------------+-------------+
          | True       | True        |               | True            | Completed   |
          +------------+-------------+---------------+-----------------+-------------+
          | False      | False       | In Range      | False           | To Do       |
          +------------+-------------+---------------+-----------------+-------------+
          | False      | False       | crossed       | false           | Missed      |
          +------------+-------------+---------------+-----------------+-------------+
    */
          if (assignee.assignee_status) {
            if (checkedin && checkedout) {
              assigneesData[index].status = "Completed";
            }
          } else {
            if (checkedin) {
              if (!checkedout) {
                assigneesData[index].status = "In Progress";
              }
            } else {
              if (
                !checkedout &&
                moment().unix() < moment(item.required_end_date).unix()
              ) {
                assigneesData[index].status = "To Do";
              } else {
                assigneesData[index].status = "Missed";
              }
            }
          }

          if (evidence) {
            assigneesData[index].evidence = evidence;
          }
        });
      });
    }
    setJobsList(jobList);
  }, [jobList]);

  const toolTipChange = (value, index) => {
    setToolTip(value);
    setSelectIndex(index);
  };

  const selectedCard = (id) => {
    if (id !== selectedNoteCard) {
      setSupervisorNote("");
      setSelectedNoteCard(id);
    }
  };

  const onSupervisorNoteChange = (e) => {
    const { value } = e.target;
    setSupervisorNote(value);
  };

  const popupToggle = (image) => {
    setEvidenceImage(image);
    setPopupOpen(!popupOpen);
  };

  const popupToggleAudio = (audio) => {
    setEvidenceAudio(audio);
    setPopupOpenAudio(!popupOpenAudio);
  };

  const handleShowLocation = (location) => {
    setSelectedStaticTaskLocation(location);

    setTimeout(() => {
      setStaticLocationModalOpen(true);
    }, 300);
  };

  return (
    <>
      <EvidenceModal
        isOpen={popupOpen}
        toggle={popupToggle}
        evidence={evidenceImage ? evidenceImage : ""}
      />
      <AudioPlayerModal
        isOpen={popupOpenAudio}
        toggle={popupToggleAudio}
        audio={evidenceAudio ? evidenceAudio : ""}
      />
      <StaticTaskLocationModal
        isOpen={staticLocationModalOpen}
        toggle={() => {
          setStaticLocationModalOpen(false);
          setSelectedStaticTaskLocation(null);
        }}
        taskLocation={selectedStaticTaskLocation}
      />
      {isLoading ? (
        <ComponentCircleLoader />
      ) : jobsList && jobsList.length > 0 ? (
        jobsList.map((job) => (
          <Container padding="30px" gap="20px" key={job.id}>
            <ListUpperSection style={{ gridTemplateColumns: `1fr auto` }}>
              <CommonText name title fontSize="16px">
                Job: {job.name}
              </CommonText>
            </ListUpperSection>
            <TaskInfoSection>
              {job && job.required_start_date && (
                <LabelTextBlock>
                  <>
                    <InputLabel>Start-End:</InputLabel>
                    <CommonText name>
                      {job
                        ? moment(job.required_start_date).format("YYYY-MM-DD")
                        : ""}{" "}
                      {job ? moment(job.start_time).format("h:mm A") : ""}
                      {"-"}
                      {job ? moment(job.end_time).format("h:mm A") : ""}
                    </CommonText>
                  </>
                </LabelTextBlock>
              )}

              <LabelTextBlock>
                <InputLabel>Work Order:</InputLabel>
                <CommonText
                  $name
                  onClick={() =>
                    history.push(
                      `/user/details-work-order?id=${job.work_order.id}`
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  {job.work_order && job.work_order.id
                    ? job.work_order.name
                    : ""}
                </CommonText>
              </LabelTextBlock>
              <LabelTextBlock>
                <InputLabel>Customer:</InputLabel>
                <CommonText
                  name
                  onClick={() =>
                    props.history.push(
                      `/user/customer-details?id=${job.work_order.client_id}`
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  {job.work_order && job.work_order.client_id
                    ? job.work_order.client_name
                    : ""}
                </CommonText>
              </LabelTextBlock>
              <LabelTextBlock>
                <InputLabel>Job progress:</InputLabel>
                <CommonText name>{job.percentage_complete}%</CommonText>
              </LabelTextBlock>
            </TaskInfoSection>
            <CommonText>
              Description: {job.description ? job.description : ""}
            </CommonText>
            {job.location && job.location.id && (
              <CommonText
                $title
                style={{ color: "#F6A019", cursor: "pointer" }}
                onClick={() => handleShowLocation(job.location)}
              >
                See Location
              </CommonText>
            )}

            {job.assignees && job.assignees.length > 0 && (
              <>
                <CommonText>Assignee List</CommonText>
                <ClientLocationsTableHeaderContainer columns={6}>
                  <TableItem>
                    <TableText>{t("assignees")}</TableText>
                  </TableItem>
                  <TableItem>
                    <TableText>{t("checked_in")}</TableText>
                  </TableItem>
                  <TableItem>
                    <TableText>{t("checked_out")}</TableText>
                  </TableItem>
                  <TableItem>
                    <TableText>{t("worked")}</TableText>
                  </TableItem>
                  <TableItem>
                    <TableText>{t("status")}</TableText>
                  </TableItem>
                  <TableItem>
                    <TableText>{t("evidence")}</TableText>
                  </TableItem>
                </ClientLocationsTableHeaderContainer>

                {job.assignees.map((assignee, index) => {
                  return (
                    <>
                      <ClientLocationsTableRowContainer columns={6}>
                        <TableItem>
                          <div key={assignee.id}>
                            <MemberNameDiv
                              onClick={() =>
                                props.history.push(
                                  `/user/member-details?id=${assignee.id}`
                                )
                              }
                            >
                              <CommImageNameComp
                                imgSource={assignee.avatar || avatar5}
                                name={`${assignee.first_name} ${assignee.last_name}`}
                                size="36px"
                              />
                            </MemberNameDiv>
                          </div>
                        </TableItem>
                        <TableItem>
                          <CommonText>
                            {assignee.checkedin && assignee.checkedin.timestamp
                              ? moment
                                  .unix(assignee.checkedin.timestamp)
                                  .format("h:mm a")
                              : "-"}
                            {assignee.checkedin &&
                              assignee.checkedin.timestamp &&
                              (moment
                                .unix(assignee.checkedin.timestamp)
                                .isAfter(moment(job.start_time)) ? (
                                <LateTag>{t("late")}</LateTag>
                              ) : (
                                <GreenCircle>
                                  <img src={tick} alt="" width="100%" />
                                </GreenCircle>
                              ))}
                          </CommonText>
                        </TableItem>
                        <TableItem>
                          <CommonText>
                            {assignee.checkedout &&
                            assignee.checkedout.timestamp
                              ? moment
                                  .unix(assignee.checkedout.timestamp)
                                  .format("h:mm a")
                              : "-"}
                            {assignee.checkedout &&
                              assignee.checkedout.timestamp &&
                              (moment
                                .unix(assignee.checkedout.timestamp)
                                .isAfter(moment(job.end_time)) ? (
                                <LateTag>{t("late")}</LateTag>
                              ) : (
                                <GreenCircle>
                                  <img src={tick} alt="" width="100%" />
                                </GreenCircle>
                              ))}
                          </CommonText>
                        </TableItem>
                        <TableItem>
                          <CommonText>
                            {assignee.checkedin &&
                            assignee.checkedin.timestamp &&
                            assignee.checkedout &&
                            assignee.checkedout.timestamp
                              ? toHHMMSS(
                                  assignee.checkedout.timestamp -
                                    assignee.checkedin.timestamp
                                )
                              : "-"}
                          </CommonText>
                        </TableItem>
                        <TableItem
                          style={{ wordBreak: `initial`, wordWrap: `initial` }}
                        >
                          <StatusBar
                            status={
                              assignee.status === "Completed"
                                ? "success"
                                : assignee.status === "Missed"
                                ? "error"
                                : "warning"
                            }
                            style={{
                              width: "60%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {assignee.status ? assignee.status : "-"}
                          </StatusBar>
                        </TableItem>
                        <TableItem>
                          <EvidenceIconSection>
                            {assignee.evidence && assignee.evidence.image && (
                              <EvidenceIcon
                                onClick={() =>
                                  popupToggle(assignee.evidence.image)
                                }
                                onMouseEnter={() => toolTipChange(1, index)}
                                onMouseLeave={() => toolTipChange(-1)}
                              >
                                <CommonImage
                                  src={imageIcon}
                                  alt=""
                                  size="28px"
                                />
                                {selectIndex === index && toolTip === 1 && (
                                  <ToolTipContainer
                                    style={{
                                      position: "relative",
                                      top: "-60px",
                                    }}
                                  >
                                    <InnerDivTooltip>
                                      <img src={Chat} alt="chat" width="64px" />
                                      <TooltipText>Image</TooltipText>
                                    </InnerDivTooltip>
                                  </ToolTipContainer>
                                )}
                              </EvidenceIcon>
                            )}
                            {assignee.evidence && assignee.evidence.audio && (
                              <EvidenceIcon
                                onClick={() =>
                                  popupToggleAudio(assignee.evidence.audio)
                                }
                                onMouseEnter={() => toolTipChange(2, index)}
                                onMouseLeave={() => toolTipChange(-1)}
                              >
                                <CommonImage
                                  src={audioIcon}
                                  alt=""
                                  size="25px"
                                />
                                {selectIndex === index && toolTip === 2 && (
                                  <ToolTipContainer
                                    style={{
                                      position: "relative",
                                      top: "-57px",
                                    }}
                                  >
                                    <InnerDivTooltip>
                                      <img src={Chat} alt="chat" width="64px" />
                                      <TooltipText>Audio</TooltipText>
                                    </InnerDivTooltip>
                                  </ToolTipContainer>
                                )}
                              </EvidenceIcon>
                            )}
                          </EvidenceIconSection>
                        </TableItem>
                      </ClientLocationsTableRowContainer>
                    </>
                  );
                })}
              </>
            )}

            {/* <EmployeeInfoBlock>
              <CommImageNameComp
                imgSource={
                  (job.assignees.length > 0 && job.assignees[0].avatar) ||
                  avatar5
                }
                name={
                  job.assignees.length > 0 &&
                  `${job.assignees[0].first_name} ${job.assignees[0].last_name}`.trim()
                }
                size="50px"
              />
              <CallSection>
                <img src={plusIcon} alt="" width="100%" />
                <CommonImage src={phoneIcon} alt="" size="18px" />
                <CommonText name style={{ whiteSpace: `nowrap` }}>
                  {job.assignees.length > 0 && job.assignees[0].phone}
                </CommonText>
              </CallSection>
            </EmployeeInfoBlock> */}

            {/* <InfoSectionCard style={{ maxWidth: `650px` }}>
              <CardBlock>
                <CardText name fontSize="13px" title nowrap>
                  {job.required_start_date &&
                    moment(job.required_start_date).format('h:mm A')}{' '}
                  -{' '}
                  {job.required_end_date &&
                    moment(job.required_end_date).format('h:mm A')}
                </CardText>
                <CardText fontSize="12px" label title>
                  {t('start_end')}
                </CardText>
              </CardBlock>
              <CardBlock>
                <CardText name fontSize="13px" title nowrap>
                  {job.checkinout &&
                  job.checkinout.length > 0 &&
                  job.checkinout.filter(i => i.event === 'checkin').length > 0
                    ? job.checkinout
                        .filter(i => i.event === 'checkin')
                        .map((item, i) => {
                          if (i === 0) {
                            return (
                              <React.Fragment key={i}>
                                {moment.unix(item.timestamp).format('h:mm a')}
                                {moment
                                  .unix(item.timestamp)
                                  .isAfter(moment(job.required_start_date)) ? (
                                  <LateTag>{t('late')}</LateTag>
                                ) : (
                                  <GreenCircle>
                                    <img src={tick} alt="" width="100%" />
                                  </GreenCircle>
                                )}
                              </React.Fragment>
                            );
                          }
                          return null;
                        })
                    : `-`}
                </CardText>
                <CardText fontSize="12px" label title>
                  {t('checked_in')}
                </CardText>
              </CardBlock>
              <CardBlock>
                <CardText name fontSize="13px" title nowrap>
                  {job.checkinout &&
                  job.checkinout.length > 1 &&
                  job.checkinout.filter(i => i.event === 'checkout').length > 0
                    ? job.checkinout
                        .filter(i => i.event === 'checkout')
                        .map((item, i) => {
                          if (i === 0) {
                            return (
                              <React.Fragment key={i}>
                                {moment.unix(item.timestamp).format('h:mm a')}
                                {moment
                                  .unix(item.timestamp)
                                  .isAfter(moment(job.required_end_date)) ? (
                                  <LateTag>{t('late')}</LateTag>
                                ) : (
                                  <GreenCircle>
                                    <img src={tick} alt="" width="100%" />
                                  </GreenCircle>
                                )}
                              </React.Fragment>
                            );
                          }
                          return null;
                        })
                    : `-`}
                </CardText>
                <CardText fontSize="12px" label title>
                  {t('checked_out')}
                </CardText>
              </CardBlock>
              <CardBlock>
                <CardText name fontSize="13px" title nowrap>
                  {job.checkinout &&
                  job.checkinout.length > 1 &&
                  job.checkinout.filter(i => i.event === 'checkin').length >
                    0 &&
                  job.checkinout.filter(i => i.event === 'checkout').length > 0
                    ? toHHMMSS(
                        job.checkinout.filter(i => i.event === 'checkout')[0]
                          .timestamp -
                          job.checkinout.filter(i => i.event === 'checkin')[0]
                            .timestamp,
                      )
                    : `-`}
                </CardText>
                <CardText fontSize="12px" label title>
                  {t('worked')}
                </CardText>
              </CardBlock>
            </InfoSectionCard> */}
            {/* <CommonText name title fontSize="16px">
              {t('evidence')}
            </CommonText> */}
            {/* <EvidenceThumbnail
              onClick={() =>
                popupToggle(
                  job.taskevidences.length > 0 && job.taskevidences[0].image,
                )
              }
            >
              {job.taskevidences.length > 0 && job.taskevidences[0].image ? (
                <img
                  src={job.taskevidences[0].image}
                  alt=""
                  style={{
                    maxWidth: '200px',
                    maxHeight: '250px',
                    borderRadius: 10,
                  }}
                />
              ) : (
                ''
              )}
            </EvidenceThumbnail>
            <EvidenceThumbnail>
              {job.taskevidences.length > 0 && job.taskevidences[0].audio ? (
                <AudioPlayer audio={job.taskevidences[0].audio} />
              ) : (
                ''
              )}
            </EvidenceThumbnail> */}
            {(selectedTab === "approved" || selectedTab === "rejected") && (
              <>
                {job.employee_note && (
                  <LabelWithTextSection margin="20px 0 0 0">
                    <CommonText $label fontSize="13px">
                      {t("employee_note")}
                    </CommonText>
                    <CommonText name fontSize="15px">
                      {job.employee_note}
                    </CommonText>
                  </LabelWithTextSection>
                )}
                {job.supervisor_note && (
                  <LabelWithTextSection>
                    <CommonText $label fontSize="13px">
                      {t("supervisor_note")}
                    </CommonText>
                    <CommonText name fontSize="15px">
                      {job.supervisor_note}
                    </CommonText>
                  </LabelWithTextSection>
                )}
              </>
            )}

            {localStorage.getItem("selected_organization_role") !==
              "employee" &&
            !localStorage.getItem("viewer_role") &&
            selectedTab === "submitted" &&
            job.assignees &&
            job.assignees.length > 0 ? (
              <>
                <NoteSection>
                  <CommonText name title fontSize="14px">
                    {t("note")}
                  </CommonText>
                </NoteSection>

                {job.employee_note && (
                  <CommonText name fontSize="15px">
                    {job.employee_note}
                  </CommonText>
                )}

                <ReviewSection style={{ paddingLeft: "20px" }}>
                  <InputField
                    type="text"
                    name="supervisor_note"
                    placeholder={t("type_your_message_here")}
                    value={selectedNoteCard === job.id ? supervisorNote : ""}
                    onChange={(e) => onSupervisorNoteChange(e)}
                    onClick={() => selectedCard(job.id)}
                  />
                  {/* <SearchBox
                    searchTermName=""
                    searchInput={searchTerm}
                    onInputChange={onSearchTermChange}
                    handleSearch={handleSearchByName}
                    handleClearSearch={handleClearSearch}
                  /> */}
                  <ButtonSection>
                    <WhiteButton
                      type="delete"
                      borderColor="red"
                      onClick={() =>
                        updateTaskStatusRequest(
                          job.id,
                          job.approval,
                          "rejected",
                          supervisorNote
                        )
                      }
                    >
                      {t("reject")}
                    </WhiteButton>
                    <ColoredButton
                      onClick={() =>
                        updateTaskStatusRequest(
                          job.id,
                          job.approval,
                          "approved",
                          supervisorNote
                        )
                      }
                      type="black"
                      height="40px"
                      width="max-content"
                    >
                      {t("approve")}
                    </ColoredButton>
                  </ButtonSection>
                </ReviewSection>
              </>
            ) : null}
          </Container>
        ))
      ) : (
        <NoDataComponent
          title={t("no_jobs_found")}
          padding="5vh 0"
          imageHeight="140px"
        />
      )}
    </>
  );
};

export default ApprovalTasksList;
