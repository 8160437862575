import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment-timezone";
import { getRandomColor } from "../../utils/getRandomColor";
import CommonTooltip from "../../components/Tooltip/CommonTooltip";

// border-color when hover, green: #20BEAD, yellow: #FAB242, violet: #6F7AFF, blue: #4D94FB

const TimeDiv = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadii.button};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.font.inputLabel.size};
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  background: ${() => getRandomColor()};
  padding: 4px 7px;
  border: ${({theme}) => `1px solid ${theme.colors.main.white}`};
  &:hover{
    border: 1px solid #3472C7;
  }
`;

const TimeBlankDiv = styled.div`
  height: 36px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.font.pageText.size};
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
  background: ${({ theme }) => theme.colors.main.white};
`;
const ShiftTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;
const ShiftTime = styled.div`
  font-size: 11px;
`;
const ViewMore = styled.div`
  position: absolute;
  top: 15px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  z-index: 99;
  background-color: ${({ theme }) => theme.font.pageText.colorSecondary};
  color: ${({ theme }) => theme.colors.main.white};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DailyShift = (props) => {
  const { shifts } = props;
  return (
    <>
      {shifts.length === 0 ? (
        <TimeBlankDiv />
      ) : (
        <TimeDiv style={{ cursor: "pointer" }} onClick={()=> console.log("clicked")}>
          <ShiftTitle>{shifts.title}</ShiftTitle>
          {/* <ShiftTime>
            {moment(shifts.event.start).format("h:mm A")} -{" "}
            {moment(shifts.event.end).format("h:mm A")}
          </ShiftTime> */}
          {/* {shifts.event.isFirst && shifts.event.totalShiftsOfDate > 1 && (
            <ViewMore> +{shifts.event.totalShiftsOfDate - 1} </ViewMore>
          )} */}
        </TimeDiv>
      )}
    </>
  );
};

export default DailyShift;
