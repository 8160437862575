import {
  ADD_CLIENT_LOCATION,
  CLEAR_CLIENT_LOCATIONS,
  CLEAR_CREATED_CUSTOMER_INFO,
  CREATE_CLIENT,
  CREATE_CUSTOMER_BULK,
  DELETE_CLIENT,
  DELETE_CLIENT_LOCATION,
  GET_CLIENTS_LIST,
  GET_CLIENTS_SHORT_LIST,
  GET_CLIENT_DETAILS,
  GET_CLIENT_LOCATIONS,
  GET_GHL_CONTACT_LIST,
  UPDATE_CLIENT,
  UPDATE_CLIENT_LOCATION,
} from '../../modules/constants';

export function getClientList(value) {
  return {
    type: GET_CLIENTS_LIST,
    payload: value,
  };
}

export function getGHLContactList(value) {
  return {
    type: GET_GHL_CONTACT_LIST,
    payload: value,
  };
}

export function clearClientLocationsList() {
  return {
    type: CLEAR_CLIENT_LOCATIONS,
  };
}

export function clearCreatedCustomerInfo() {
  return {
    type: CLEAR_CREATED_CUSTOMER_INFO,
  };
}

export function getClientShortList(value) {
  return {
    type: GET_CLIENTS_SHORT_LIST,
    payload: value,
  };
}

export function getClientDetails(value) {
  return {
    type: GET_CLIENT_DETAILS,
    payload: value,
  };
}

export function createClient(data) {
  return {
    type: CREATE_CLIENT,
    payload: data,
  };
}

export function createCustomerBulk(data) {
  return {
    type: CREATE_CUSTOMER_BULK,
    payload: data,
  };
}

export function updateClient(data) {
  return {
    type: UPDATE_CLIENT,
    payload: data,
  };
}

export function deleteClient(value) {
  return {
    type: DELETE_CLIENT,
    payload: value,
  };
}

export function addClientLocation(value) {
  return {
    type: ADD_CLIENT_LOCATION,
    payload: value,
  };
}

export function getClientLocation(value) {
  return {
    type: GET_CLIENT_LOCATIONS,
    payload: value,
  };
}

export function updateClientLocation(value) {
  return {
    type: UPDATE_CLIENT_LOCATION,
    payload: value,
  };
}

export function deleteClientLocation(value) {
  return {
    type: DELETE_CLIENT_LOCATION,
    payload: value,
  };
}

export const actions = {
  createClient,
  updateClient,
  deleteClient,
  getClientList,
  getClientDetails,
  addClientLocation,
  getClientLocation,
  updateClientLocation,
  deleteClientLocation,
  clearClientLocationsList,
  clearCreatedCustomerInfo,
};
