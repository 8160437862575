import moment from 'moment';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import avatar5 from '../../../assets/img/avatar_5.svg';
import audioIcon from '../../../assets/img/icons/Audio.svg';
import imageIcon from '../../../assets/img/icons/Image.svg';
import Chat from '../../../assets/img/icons/chat.svg';
import Bean from '../../../assets/img/icons/delete.svg';
import plusIcon from '../../../assets/img/icons/plus_white.svg';
import tick from '../../../assets/img/icons/tick-white-bold.svg';
import evidence from '../../../assets/img/screenshot_1.svg';
import BackButtonComponent from '../../../components/BackButtonComponent/BackButtonComponent';
import { CardText } from '../../../components/DashboardCards/cardStyles';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import { ListUpperSection } from '../../../styledComponents/ProjectsNew';
import {
  ButtonIcon,
  ColoredButton,
  ColoredButtonWithIcon,
  PrimaryButton,
  WhiteButton,
  WhiteButtonWithIcon,
} from '../../../styledComponents/buttons';
import {
  ClientLocationsTableHeaderContainer,
  ClientLocationsTableRowContainer,
  GeneralInfoItem,
  GeneralInfoSection,
  InnerDivTooltip,
  ToolTipContainer,
  TooltipText,
} from '../../../styledComponents/clients';
import {
  AssignListText,
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonText,
  Container,
  ContentHeader,
  FirstWordRound,
  FormAlert,
  HeaderContainer,
  InputLabel,
  PageTitle,
} from '../../../styledComponents/common';
import { CommonImage, TableText } from '../../../styledComponents/common';
import { MemberNameDiv } from '../../../styledComponents/members';
import { TableItem } from '../../../styledComponents/teams';
import { checkHtml, checkUrl } from '../../../utils/helper';
import { getMapCircle } from '../../../utils/helper';
import { toHHMMSS } from '../../../utils/helper';
import {
  CardBlock,
  GreenCircle,
  InfoSectionCard,
  LateTag,
  TimelineBorder,
  TimelineContainer,
  TimelineInfoContainer,
  TimelineTextSection,
  UserActivityContainer,
} from '../../FieldServices/RouteMapNew/routeMapStyles';
import { CommImageNameComp } from '../../Members/CommonComponents';
import { CommFirstRoundLetterComp } from '../../Teams/CommonComponents';
import DeleteJobModal from '../DeleteJobModal';
import AudioPlayerModal from '../JobApproval/AudioPlayer';
import EvidenceModal from '../JobApproval/EvidenceModal';
import {
  EvidenceIcon,
  EvidenceIconSection,
  StatusBar,
} from '../JobApproval/taskApprovalStyle';
import {
  CallSection,
  EmployeeInfoBlock,
  EvidenceThumbnail,
  LabelTextBlock,
  NoteSection,
  TaskInfoSection,
} from './taskDetailsStyles';

const JobDetails = props => {
  const {
    selectedOrganization,
    location,
    history,

    //Job Details
    getJobDetails,
    getJobIsLoading,
    jobDetails,

    //Job Delete
    jobDeleteLoading,
    deleteJob,
  } = props;

  const role = localStorage.getItem('selected_organization_role');
  const user_id = localStorage.getItem('user_id');

  const { t, i18n } = useTranslation();
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState(null);

  //Image Modal
  const [popupOpen, setPopupOpen] = useState(false);
  const [evidenceImage, setEvidenceImage] = useState(null);

  //Audio Modal
  const [popupOpenAudio, setPopupOpenAudio] = useState(false);
  const [evidenceAudio, setEvidenceAudio] = useState(null);

  // ToolTip
  const [selectIndex, setSelectIndex] = useState(-1);
  const [toolTip, setToolTip] = useState(-1);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      if (location.search) {
        const queryParsed = queryString.parse(location.search);
        let payload = {
          organization_id: selectedOrganization.id,
          workOrder_id: queryParsed.workOrder_id,
          job_id: queryParsed.id,
        };
        getJobDetails(payload);
      }
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (jobDetails && jobDetails.location && jobDetails.location.id) {
      const baseUrl = `https://maps.googleapis.com/maps/api/staticmap?key=${process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY}&size=600x300&zoom=15&scale=1&language=eng`;
      const markers = `&markers=color:red%7C${jobDetails.location.latitude},${jobDetails.location.longitude}`;
      const path = `&path=fillcolor:0x6f7aff33%7Cweight:2%7Ccolor:0x6f7affff${getMapCircle(
        jobDetails.location.latitude,
        jobDetails.location.longitude,
        jobDetails.location.radius,
      )}`;
      const url = `${baseUrl}${markers}${path}`;

      setImageUrl(url);
      setIsLoading(false);
    }
  }, [jobDetails]);

  const toggle = () => setModal(!modal);

  const editJobPage = () => {
    if (location.search) {
      const queryParsed = queryString.parse(location.search);
      const workOrder_id = queryParsed.workOrder_id;
      const job_id = queryParsed.id;
      history.push(`/user/edit-job?id=${job_id}&workOrder_id=${workOrder_id}`);
    }
  };

  const handleJobDelete = () => {
    if (selectedOrganization && selectedOrganization.id) {
      if (location.search) {
        const queryParsed = queryString.parse(location.search);
        let payload = {
          organization_id: selectedOrganization.id,
          workOrder_id: queryParsed.workOrder_id,
          job_id: queryParsed.id,
        };
        deleteJob(payload);
      }
      setTimeout(() => {
        setModal(!modal);
        history.push({
          pathname: '/user/jobs',
          state: { from: 'details' },
        });
      }, 500);
    }
  };
  const popupToggle = image => {
    setEvidenceImage(image);
    setPopupOpen(!popupOpen);
  };

  const popupToggleAudio = audio => {
    setEvidenceAudio(audio);
    setPopupOpenAudio(!popupOpenAudio);
  };

  const toolTipChange = (value, index) => {
    setToolTip(value);
    setSelectIndex(index);
  };

  return (
    <div className="content">
      <EvidenceModal
        isOpen={popupOpen}
        toggle={popupToggle}
        evidence={evidenceImage ? evidenceImage : ''}
      />
      <AudioPlayerModal
        isOpen={popupOpenAudio}
        toggle={popupToggleAudio}
        audio={evidenceAudio ? evidenceAudio : ''}
      />
      <PageTitle>Job Details</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => props.history.push('/user/jobs')}
          subTitle="Back to all jobs"
        />
      </ContentHeader>
      <Container padding="30px" gap="20px">
        {getJobIsLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            <ListUpperSection style={{ gridTemplateColumns: `1fr auto auto` }}>
              <CommonText name title fontSize="16px">
                Job: {jobDetails ? jobDetails.name : ''}
              </CommonText>
              {selectedOrganization &&
              selectedOrganization.role &&
              (selectedOrganization.role === 'admin' ||
                selectedOrganization.role === 'owner') &&
              jobDetails &&
              jobDetails.status &&
              (jobDetails.status === 'unscheduled' ||
                jobDetails.status === 'ready to dispatch' ||
                jobDetails.status === 'totally missed') ? (
                <WhiteButtonWithIcon
                  type="delete"
                  fontSize="14px"
                  onClick={toggle}
                >
                  <ButtonIcon src={Bean} alt="" />
                  {t('delete_job')}
                </WhiteButtonWithIcon>
              ) : (
                <></>
              )}
              <DeleteJobModal
                isOpen={modal}
                toggle={toggle}
                handleDelete={handleJobDelete}
                isLoading={jobDeleteLoading}
              />
              {/* <WhiteButton onClick={editJobPage}>Edit Job</WhiteButton> */}
            </ListUpperSection>
            <TaskInfoSection>
              {/* <StatusBar status="warning">Completed</StatusBar> */}
              {jobDetails && jobDetails.required_start_date && (
                <LabelTextBlock>
                  <>
                    <InputLabel>Start-End:</InputLabel>
                    <CommonText name>
                      {jobDetails
                        ? moment(jobDetails.start_time).format('h:mm A')
                        : ''}
                      {'-'}
                      {jobDetails
                        ? moment(jobDetails.end_time).format('h:mm A')
                        : ''}
                      {' , '}{' '}
                      {jobDetails
                        ? moment(jobDetails.start_time).format('DD MMM YYYY')
                        : ''}
                    </CommonText>
                  </>
                </LabelTextBlock>
              )}
              {jobDetails &&
                jobDetails.work_order &&
                jobDetails.work_order.id && (
                  <>
                    <LabelTextBlock>
                      <InputLabel>Work Order:</InputLabel>
                      <CommonText
                        name
                        onClick={() =>
                          props.history.push(
                            `/user/details-work-order?id=${jobDetails.work_order.id}`,
                          )
                        }
                        style={{ cursor: 'pointer' }}
                      >
                        {jobDetails.work_order.name}
                      </CommonText>
                    </LabelTextBlock>
                    <LabelTextBlock>
                      <InputLabel>Customer:</InputLabel>
                      <CommonText
                        name
                        onClick={() =>
                          props.history.push(
                            `/user/customer-details?id=${jobDetails.work_order.client_id}`,
                          )
                        }
                        style={{ cursor: 'pointer' }}
                      >
                        {jobDetails.work_order.client_name}
                      </CommonText>
                    </LabelTextBlock>
                    {/* {jobDetails.work_order.client_name && (
                      <>
                        <LabelTextBlock>
                          <InputLabel>Customer:</InputLabel>
                          <div
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              history.push(
                                `/user/customer-details?id=${jobDetails.work_order.client_id}}`,
                              )
                            }
                          >
                            <CommFirstRoundLetterComp
                              text={jobDetails.work_order.client_name}
                              backColor="3"
                              underline={false}
                            />
                          </div>
                        </LabelTextBlock>
                      </>
                    )} */}
                  </>
                )}

              <LabelTextBlock>
                <InputLabel>Job progress:</InputLabel>
                <CommonText name>
                  {jobDetails && jobDetails.percentage_complete
                    ? jobDetails.percentage_complete
                    : '0.00'}
                  %
                </CommonText>
              </LabelTextBlock>
            </TaskInfoSection>
            <CommonText>
              Description: {jobDetails ? jobDetails.description : ''}
            </CommonText>

            <div style={{ marginBottom: '15px' }}>
              <CommonText
                name
                title
                fontSize="16px"
                style={{ marginBottom: '15px' }}
              >
                Location
              </CommonText>
              {jobDetails && jobDetails.location !== null ? (
                <>
                  <TaskInfoSection style={{ marginBottom: '15px' }}>
                    <LabelTextBlock>
                      <InputLabel>Location Address:</InputLabel>
                      <CommonText name>
                        {jobDetails && jobDetails.location.address}
                      </CommonText>
                    </LabelTextBlock>
                  </TaskInfoSection>
                  {isLoading ? (
                    <ComponentCircleLoader />
                  ) : (
                    <div>
                      <CommonImage
                        style={{ borderRadius: '5px' }}
                        src={imageUrl}
                        alt="map"
                      />
                    </div>
                  )}
                </>
              ) : (
                <GeneralInfoSection>
                  <GeneralInfoItem>
                    <CommonText title>{t('no_address_found')}</CommonText>
                    <CommonText $label fontWeight="500">
                      {t('no_name_set')}
                    </CommonText>
                  </GeneralInfoItem>
                </GeneralInfoSection>
              )}
            </div>

            {jobDetails && jobDetails.assignees.length > 0 && (
              <>
                <CommonText>Assignee List</CommonText>
                <ClientLocationsTableHeaderContainer columns={6}>
                  <TableItem>
                    <TableText>{t('assignees')}</TableText>
                  </TableItem>
                  <TableItem>
                    <TableText>{t('checked_in')}</TableText>
                  </TableItem>
                  <TableItem>
                    <TableText>{t('checked_out')}</TableText>
                  </TableItem>
                  <TableItem>
                    <TableText>{t('worked')}</TableText>
                  </TableItem>
                  <TableItem>
                    <TableText>{t('status')}</TableText>
                  </TableItem>
                  <TableItem>
                    <TableText>{t('evidence')}</TableText>
                  </TableItem>
                </ClientLocationsTableHeaderContainer>

                {jobDetails.assignees.map((assignee, index) => {
                  if (role === 'member' && user_id !== assignee.id) {
                    return null;
                  }
                  return (
                    <>
                      <ClientLocationsTableRowContainer columns={6}>
                        <TableItem>
                          <div key={assignee.id}>
                            <MemberNameDiv
                              onClick={() =>
                                props.history.push(
                                  `/user/member-details?id=${assignee.id}`,
                                )
                              }
                            >
                              <CommImageNameComp
                                imgSource={assignee.avatar || avatar5}
                                name={`${assignee.first_name} ${assignee.last_name}`}
                                size="36px"
                              />
                            </MemberNameDiv>
                          </div>
                        </TableItem>
                        <TableItem>
                          <CommonText>
                            {assignee.checkedin && assignee.checkedin.timestamp
                              ? moment
                                  .unix(assignee.checkedin.timestamp)
                                  .format('h:mm a')
                              : '-'}
                            {assignee.checkedin &&
                              assignee.checkedin.timestamp &&
                              (moment
                                .unix(assignee.checkedin.timestamp)
                                .isAfter(moment(jobDetails.start_time)) ? (
                                <LateTag>{t('late')}</LateTag>
                              ) : (
                                <GreenCircle>
                                  <img src={tick} alt="" width="100%" />
                                </GreenCircle>
                              ))}
                          </CommonText>
                        </TableItem>
                        <TableItem>
                          <CommonText>
                            {assignee.checkedout &&
                            assignee.checkedout.timestamp
                              ? moment
                                  .unix(assignee.checkedout.timestamp)
                                  .format('h:mm a')
                              : '-'}
                            {assignee.checkedout &&
                              assignee.checkedout.timestamp &&
                              (moment
                                .unix(assignee.checkedout.timestamp)
                                .isAfter(moment(jobDetails.end_time)) ? (
                                <LateTag>{t('late')}</LateTag>
                              ) : (
                                <GreenCircle>
                                  <img src={tick} alt="" width="100%" />
                                </GreenCircle>
                              ))}
                          </CommonText>
                        </TableItem>
                        <TableItem>
                          <CommonText>
                            {assignee.checkedin &&
                            assignee.checkedin.timestamp &&
                            assignee.checkedout &&
                            assignee.checkedout.timestamp
                              ? toHHMMSS(
                                  assignee.checkedout.timestamp -
                                    assignee.checkedin.timestamp,
                                )
                              : '-'}
                          </CommonText>
                        </TableItem>
                        <TableItem>
                          <StatusBar
                            status={
                              assignee.status === 'Completed'
                                ? 'success'
                                : assignee.status === 'Missed'
                                ? 'error'
                                : 'warning'
                            }
                            style={{
                              width: '60%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {assignee.status ? assignee.status : '-'}
                          </StatusBar>
                        </TableItem>
                        <TableItem>
                          <EvidenceIconSection>
                            {assignee.evidence && assignee.evidence.image && (
                              <EvidenceIcon
                                onClick={() =>
                                  popupToggle(assignee.evidence.image)
                                }
                                onMouseEnter={() => toolTipChange(1, index)}
                                onMouseLeave={() => toolTipChange(-1)}
                              >
                                <CommonImage
                                  src={imageIcon}
                                  alt=""
                                  size="28px"
                                />
                                {selectIndex === index && toolTip === 1 && (
                                  <ToolTipContainer
                                    style={{
                                      position: 'relative',
                                      top: '-60px',
                                    }}
                                  >
                                    <InnerDivTooltip>
                                      <img src={Chat} alt="chat" width="64px" />
                                      <TooltipText>Image</TooltipText>
                                    </InnerDivTooltip>
                                  </ToolTipContainer>
                                )}
                              </EvidenceIcon>
                            )}
                            {assignee.evidence && assignee.evidence.audio && (
                              <EvidenceIcon
                                onClick={() =>
                                  popupToggleAudio(assignee.evidence.audio)
                                }
                                onMouseEnter={() => toolTipChange(2, index)}
                                onMouseLeave={() => toolTipChange(-1)}
                              >
                                <CommonImage
                                  src={audioIcon}
                                  alt=""
                                  size="25px"
                                />
                                {selectIndex === index && toolTip === 2 && (
                                  <ToolTipContainer
                                    style={{
                                      position: 'relative',
                                      top: '-57px',
                                    }}
                                  >
                                    <InnerDivTooltip>
                                      <img src={Chat} alt="chat" width="64px" />
                                      <TooltipText>Audio</TooltipText>
                                    </InnerDivTooltip>
                                  </ToolTipContainer>
                                )}
                              </EvidenceIcon>
                            )}
                          </EvidenceIconSection>
                        </TableItem>
                      </ClientLocationsTableRowContainer>
                    </>
                  );
                })}
              </>
            )}
            {jobDetails &&
              (jobDetails.employee_note || jobDetails.supervisor_note) && (
                <NoteSection>
                  {jobDetails && jobDetails.employee_note && (
                    <>
                      <CommonText name title fontSize="16px">
                        {t('employee_note')}
                      </CommonText>
                      <CommonText>{jobDetails.employee_note}</CommonText>
                    </>
                  )}
                  {jobDetails && jobDetails.supervisor_note && (
                    <>
                      <CommonText name title fontSize="16px">
                        {t('supervisor_note')}
                      </CommonText>
                      <CommonText>{jobDetails.supervisor_note}</CommonText>
                    </>
                  )}
                </NoteSection>
              )}
          </>
        )}
      </Container>
    </div>
  );
};

export default JobDetails;
