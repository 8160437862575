import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import plusIcon from '../../assets/img/icons/plus_white.svg';
import CreateProjectDropdown from '../../components/DropdownStyle/CreateProjectDropdown';
import Select from '../../components/ReactSelectDropdown';
import { filterOption } from '../../components/ReactSelectDropdown/ReactSelectDropdown';
import {
  ButtonIcon,
  ColoredButtonWithIcon,
} from '../../styledComponents/buttons';
import {
  FilterLabel,
  HeaderContainer,
  InputField,
  InputWithLabelSection,
  PageTitle,
} from '../../styledComponents/common';
import { CommonGrid } from '../../styledComponents/common';
import AddLocationModal from '../TasksNew/CreateTask/AddLocationModal';
import StaticTaskLocationModal from './StaticTaskLocationModal';
import TaskLocationsTable from './TaskLocationsTable';

const TaskLocations = props => {
  const { t } = useTranslation();

  const {
    getTaskLocation,
    addTaskLocation,

    locationListIsLoading,
    addTaskLocationLoading,

    locationsList,
    createdTaskLocationInfo,

    getOfficesShortList,
    officesShortList,
  } = props;

  const [taskLocations, setTaskLocations] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [addLocationModalOpen, setAddLocationModalOpen] = useState(false);
  const [seeLocationModalOpen, setSeeLocationModalOpen] = useState(false);
  const [selectedTaskLocation, setSelectedTaskLocation] = useState(null);
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [officeOptions, setOfficeOptions] = useState([]);

  useEffect(() => {
    setSelectedOffice({
      label: t('all_offices'),
      value: null,
    });
    getTaskLocation();
    getOfficesShortList();
  }, []);

  useEffect(() => {
    if (officesShortList && officesShortList.length > 0) {
      let newArr = [
        {
          label: t('all_offices'),
          value: null,
        },
      ];
      officesShortList.forEach((office, i) => {
        newArr.push({
          label: office.name,
          value: office.id,
        });
      });
      setOfficeOptions(newArr);
    }
  }, [officesShortList]);

  useEffect(() => {
    if (locationsList && locationsList.length > 0) {
      setTaskLocations(locationsList);
    } else if (locationsList && locationsList.length === 0) {
      setTaskLocations([]);
    }
  }, [locationsList]);

  useEffect(() => {
    if (
      searchTerm &&
      searchTerm !== '' &&
      locationsList &&
      locationsList.length > 0
    ) {
      const originalList = JSON.parse(JSON.stringify(locationsList));
      const filteredList = [];
      originalList.forEach(e => {
        if (
          searchTerm &&
          e.name.toLowerCase().indexOf(searchTerm.toLowerCase().trim()) === -1
        ) {
          return;
        }
        filteredList.push(e);
      });
      setTaskLocations(filteredList);
    } else if (searchTerm === '') {
      setTaskLocations(locationsList);
    }
  }, [searchTerm]);

  const onSearchTermChange = e => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const selectOffice = e => {
    const payload = {};
    if (e && e.value) {
      payload.office_id = e.value;
    }
    setSearchTerm('');
    getTaskLocation(payload);

    setSelectedOffice(e);
  };

  const handleShowLocation = location => {
    setSelectedTaskLocation(location);

    setTimeout(() => {
      setSeeLocationModalOpen(true);
    }, 300);
  };

  return (
    <div className="content">
      <PageTitle>{t('task_locations')}</PageTitle>
      <HeaderContainer
        columns={
          localStorage.getItem('user_role') === 'admin'
            ? '500px auto'
            : '250px auto'
        }
      >
        <CommonGrid
          columns="180px 250px"
          gap="15px"
          justifyContent="flex-start"
        >
          {localStorage.getItem('user_role') === 'admin' && (
            <InputWithLabelSection>
              <FilterLabel>{t('office')}</FilterLabel>

              <Select
                isSearchable
                value={selectedOffice}
                options={officeOptions}
                placeholder="Select Office..."
                onChange={e => {
                  selectOffice(e);
                }}
                styles={CreateProjectDropdown()}
                // clearable
                filterOption={filterOption}
              />
            </InputWithLabelSection>
          )}
          <InputWithLabelSection>
            <FilterLabel>{t('search_location')}</FilterLabel>
            <InputField
              type="text"
              placeholder={t('search_by_location_name')}
              value={searchTerm}
              onChange={e => onSearchTermChange(e)}
            />
          </InputWithLabelSection>
        </CommonGrid>
        <ColoredButtonWithIcon onClick={() => setAddLocationModalOpen(true)}>
          <ButtonIcon src={plusIcon} alt="" />
          {t('add_new_location')}
        </ColoredButtonWithIcon>
      </HeaderContainer>
      <AddLocationModal
        isOpen={addLocationModalOpen}
        toggle={() => setAddLocationModalOpen(false)}
        addTaskLocation={addTaskLocation}
        addTaskLocationLoading={addTaskLocationLoading}
        createdTaskLocationInfo={createdTaskLocationInfo}
        getOfficesShortList={getOfficesShortList}
        officesShortList={officesShortList}
      />
      <StaticTaskLocationModal
        isOpen={seeLocationModalOpen}
        toggle={() => {
          setSeeLocationModalOpen(false);
          setSelectedTaskLocation(null);
        }}
        taskLocation={selectedTaskLocation}
      />
      <TaskLocationsTable
        locationsList={taskLocations}
        isLoading={locationListIsLoading}
        handleShowLocation={handleShowLocation}
      />
    </div>
  );
};

export default TaskLocations;
