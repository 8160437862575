import {
  SEND_GHL_AUTHENTICATION_CODE,
  SEND_GHL_AUTHENTICATION_CODE_FAILURE,
  SEND_GHL_AUTHENTICATION_CODE_RESET,
  SEND_GHL_AUTHENTICATION_CODE_SUCCESS,
} from '../../modules/constants';
import { createNotification } from '../../modules/notificationManager';

export const sendGHLAuthenticationCodeRequestHandler = state => {
  return {
    ...state,
    isLoading: true,
  };
};

export const sendGHLAuthenticationCodeRequestSuccessHandler = (
  state,
  action,
) => {
  const {
    payload: { data },
  } = action;

  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      token: data.data.token,
      userData: data.data,
      redirect: true,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const sendGHLAuthenticationCodeRequestFailureHandler = (
  state,
  action,
) => {
  if (action.payload.response === undefined) {
    createNotification(
      'error',
      'Something went wrong! Please try again later.',
      3000,
    );
    return {
      ...state,
      isLoading: false,
      redirect: false,
    };
  }
  const {
    payload: {
      response: { data },
    },
  } = action;
  if (data.detail) {
    createNotification('error', data.detail, 3000);
  }
  return {
    ...state,
    isLoading: false,
    redirect: false,
  };
};

const resetSendGHLAuthenticationCodeHandler = (state, action) => {
  return {
    ...initialState,
  };
};

const ACTION_HANDLERS = {
  [SEND_GHL_AUTHENTICATION_CODE]: sendGHLAuthenticationCodeRequestHandler,
  [SEND_GHL_AUTHENTICATION_CODE_SUCCESS]:
    sendGHLAuthenticationCodeRequestSuccessHandler,
  [SEND_GHL_AUTHENTICATION_CODE_FAILURE]:
    sendGHLAuthenticationCodeRequestFailureHandler,

  [SEND_GHL_AUTHENTICATION_CODE_RESET]: resetSendGHLAuthenticationCodeHandler,
};

const initialState = {
  isLoading: false,
  redirect: false,
  token: null,
  userData: null,
};

export default function ghlAuthReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
