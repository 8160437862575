import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Modal, ModalBody } from 'reactstrap';

import CreateProjectDropdown from '../../../components/DropdownStyle/CreateProjectDropdown';
import ButtonTextLoader from '../../../components/Loaders/ButtonTextLoader';
import Select from '../../../components/ReactSelectDropdown';
import { ColoredButton, WhiteButton } from '../../../styledComponents/buttons';
import { AddClientContainerNew } from '../../../styledComponents/clients';
import {
  CardTitle,
  CommonFlex,
  CommonGrid,
  Container,
  ContentHeader,
  FormAlert,
  InputField,
  InputLabel,
  InputWithLabelSection,
  PageTitle,
} from '../../../styledComponents/common';
import { ModalButtonSection } from '../../../styledComponents/members';
import { RedStar } from '../../../styledComponents/members';
import { checkHtml, checkUrl, validateEmail } from '../../../utils/helper';

const ClientModal = ({
  isOpen,
  toggle,
  selectedOrganization,
  createClient,
  isClientLoading,
  createdClientId,
  createdClientInfo,
  setSelectedClient,
}) => {
  const { t } = useTranslation();
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [saveErrors, setSaveErrors] = useState({});

  useEffect(() => {
    if (createdClientId !== null) {
      if (createdClientInfo && createdClientInfo.id) {
        setSelectedClient({
          value: createdClientInfo.id,
          label: createdClientInfo.name,
        });
      }
      setClientName('');
      setClientEmail('');
      setPhoneNumber('');
      toggle();
    }
  }, [createdClientId]);

  const onClientNameChange = e => {
    const { value } = e.target;
    setClientName(value);
    if (saveErrors && saveErrors['clientName']) {
      delete saveErrors['clientName'];
    }
  };

  const onEmailChange = e => {
    const { value } = e.target;
    setClientEmail(value);
    if (saveErrors && saveErrors['clientEmail']) {
      delete saveErrors['clientEmail'];
    }
  };

  const handleSave = () => {
    if (checkError()) {
      if (selectedOrganization && selectedOrganization.id) {
        let payload = {
          organization_id: selectedOrganization.id,
          name: clientName,
          // phone_number: phoneNumber ? phoneNumber : null,
          phone_number: phoneNumber
            ? phoneNumber.startsWith('+')
              ? phoneNumber
              : '+' + phoneNumber
            : null,
          email: clientEmail ? clientEmail : null,
          // office_location: officeLocation ? officeLocation : null,
        };
        // console.log("client information ", payload)
        createClient(payload);
      }
    }
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!clientName.trim()) {
      formIsValid = false;
      saveErrors['clientName'] = t('please_enter_customer_name');
    } else if (clientName.length > 256) {
      formIsValid = false;
      saveErrors['clientName'] = t(
        'please_enter_customer_name_within_256_characters',
      );
    } else if (checkUrl(clientName)) {
      formIsValid = false;
      saveErrors['clientName'] = t('client_name_can_not_contain_url');
    } else if (checkHtml(clientName)) {
      formIsValid = false;
      saveErrors['clientName'] = t('invalid_client_name');
    }
    if (!validateEmail(clientEmail)) {
      formIsValid = false;
      saveErrors['clientEmail'] = t('please_enter_a_valid_email_address');
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ marginTop: `25vh`, maxWidth: '700px' }}
    >
      <ModalBody style={{ padding: `20px` }}>
        <PageTitle
          fontSize="16px"
          style={{ paddingLeft: `10px`, paddingBottom: `15px` }}
        >
          {t('add_customer_details')}
        </PageTitle>
        <>
          <AddClientContainerNew
            style={{ paddingLeft: `10px`, paddingBottom: `15px`, width: '50%' }}
          >
            <InputWithLabelSection>
              <InputLabel>
                {t('customer_name')} <RedStar>*</RedStar>
              </InputLabel>
              <InputField
                type="text"
                placeholder={t('type_customer_name_here')}
                value={clientName}
                onChange={e => onClientNameChange(e)}
                error={
                  saveErrors &&
                  saveErrors['clientName'] &&
                  saveErrors['clientName'] !== undefined
                }
              />
              {saveErrors &&
                saveErrors['clientName'] &&
                saveErrors['clientName'] !== undefined && (
                  <FormAlert margin="0 0 0 5px">
                    {saveErrors['clientName']}
                  </FormAlert>
                )}
            </InputWithLabelSection>
            <InputWithLabelSection>
              <InputLabel>
                {t('email_address')} <RedStar>*</RedStar>
              </InputLabel>
              <InputField
                type="text"
                placeholder={t('type_email_address_here')}
                value={clientEmail}
                onChange={e => onEmailChange(e)}
                error={
                  saveErrors &&
                  saveErrors['clientEmail'] &&
                  saveErrors['clientEmail'] !== undefined
                }
              />
              {saveErrors &&
                saveErrors['clientEmail'] &&
                saveErrors['clientEmail'] !== undefined && (
                  <FormAlert margin="0 0 0 5px">
                    {saveErrors['clientEmail']}
                  </FormAlert>
                )}
            </InputWithLabelSection>
            <InputWithLabelSection>
              <InputLabel>{t('contact_number')}</InputLabel>
              <PhoneInput
                enableSearch
                country={'us'}
                value={phoneNumber}
                onChange={phone => setPhoneNumber(phone)}
                inputClass="phone-number-input"
                buttonClass="phone-number-flag-dropdown"
              />
            </InputWithLabelSection>
          </AddClientContainerNew>
        </>

        <ModalButtonSection
          style={{ justifyContent: 'start', paddingLeft: `10px` }}
        >
          <WhiteButton type="cancel" onClick={toggle}>
            Cancel
          </WhiteButton>
          <ColoredButton
            padding={isClientLoading && '0 24px'}
            onClick={!isClientLoading ? () => handleSave() : ''}
            // disabled={
            //   hourlyRatesType.value === 'work_order_hourly' &&
            //   !workOrderHourlyRate
            // }
          >
            {isClientLoading ? (
              <ButtonTextLoader loadingText="Saving" fontSize="13px" />
            ) : (
              t('add_customer')
            )}
          </ColoredButton>
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default ClientModal;
