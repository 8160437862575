import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageUploading from 'react-images-uploading';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import hidePassword from '../../../assets/img/common/password_hide.svg';
import viewPassword from '../../../assets/img/common/password_show.svg';
import BackButtonComponent from '../../../components/BackButtonComponent/BackButtonComponent';
import AddSingleMemberDropdown from '../../../components/CustomDropdown/AddSingleMemberDropdown';
import CreateProjectDropdown from '../../../components/DropdownStyle/CreateProjectDropdown';
import ButtonTextLoader from '../../../components/Loaders/ButtonTextLoader';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import Select from '../../../components/ReactSelectDropdown';
import { filterOption } from '../../../components/ReactSelectDropdown/ReactSelectDropdown';
import { ClearSearchButton } from '../../../components/Search/searchStyles';
import DatePicker from '../../../components/SingleDatePicker/SingleDatePicker';
import { ShowPasswordIcon } from '../../../styledComponents/authLayout';
import {
  ColoredButton,
  PrimaryButton,
  RadiusButton,
} from '../../../styledComponents/buttons';
import {
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonImage,
  CommonText,
  Container,
  ContentHeader,
  FormAlert,
  InputField,
  InputLabel,
  InputTextArea,
  InputWithLabelSection,
  PageTitle,
  RedStar,
} from '../../../styledComponents/common';
import {
  UploadButtonContainer,
  UploadImageButton,
} from '../../../styledComponents/members';
import {
  checkHtml,
  checkUrl,
  password_validate,
  validateEmail,
} from '../../../utils/helper';
import { InputSection } from '../../TasksNew/CreateTask/createTaskStyles';

const AddMember = ({
  history,
  location,
  isLoading,
  getOfficesShortList,
  officesShortList,
  createMemberLoading,
  addMember,
  createdMemberInfo,
  updateMember,
  updateMemberLoading,
  updatedData,
  getMemberDetails,
  memberDetails,
  selectedOrganization,
}) => {
  const { t, i18n } = useTranslation();
  const [memberId, setMemberId] = useState(null);
  const [selectedOffice, setSelectedOffice] = useState('');
  const [officeOptions, setOfficeOptions] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const roleList = [
    {
      label: 'Admin',
      value: 'admin',
    },
    {
      label: 'GM',
      value: 'gm',
    },
    {
      label: 'AGM',
      value: 'agm',
    },
    {
      label: 'Supervisor',
      value: 'supervisor',
    },
    {
      label: 'Employee',
      value: 'employee',
    },

    {
      label: 'Member',
      value: 'member',
    },

    {
      label: 'Owner',
      value: 'owner',
    },
  ];
  const [roleOptions, setRoleOptions] = useState([]);
  const [userCode, setUserCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [images, setImages] = useState([]);
  const [imageUpdated, setImageUpdated] = useState(false);

  const [saveErrors, setSaveErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (location.search) {
      const queryParsed = queryString.parse(location.search);
      if (queryParsed.id) {
        setMemberId(queryParsed.id);
        getMemberDetails({
          organization_id:
            selectedOrganization && selectedOrganization.id
              ? selectedOrganization.id
              : '',
          user_id: queryParsed.id,
        });
      }
    }
    getOfficesShortList();
    let newOptions = [];
    let isMatched = false;
    roleList &&
      roleList.map((role, i) => {
        if (localStorage.getItem('user_role') === 'admin' || isMatched) {
          newOptions.push(role);
        }
        if (role.value === localStorage.getItem('user_role')) {
          isMatched = true;
        }
      });
    setRoleOptions(newOptions);
  }, []);

  console.log('memberDetails ', memberDetails);

  useEffect(() => {
    if (memberId && memberDetails) {
      // console.log(memberDetails);
      memberDetails.user.first_name &&
        setFirstName(memberDetails.user.first_name);
      memberDetails.user.last_name && setLastName(memberDetails.user.last_name);
      memberDetails.office &&
        memberDetails.office.id &&
        setSelectedOffice({
          label: memberDetails.office.name,
          value: memberDetails.office.id,
        });
      memberDetails.user.email && setEmail(memberDetails.user.email);
      memberDetails.role &&
        roleOptions &&
        roleOptions.length > 1 &&
        roleOptions.map(role => {
          role.value === memberDetails.role && setSelectedRole(role);
        });
      memberDetails.user_code && setUserCode(memberDetails.user_code);
      memberDetails.user.phone && setPhoneNumber(memberDetails.user.phone);
      memberDetails.avatar &&
        setImages([
          {
            data_url: memberDetails.avatar,
          },
        ]);
    }
  }, [memberDetails]);

  useEffect(() => {
    if (officesShortList && officesShortList.length === 1) {
      setSelectedOffice({
        label: officesShortList[0].name,
        value: officesShortList[0].id,
      });
    } else if (officesShortList && officesShortList.length > 0) {
      let newArr = [];
      officesShortList.forEach((office, i) => {
        newArr.push({
          label: office.name,
          value: office.id,
        });
      });
      setOfficeOptions(newArr);
    }
  }, [officesShortList]);

  useEffect(() => {
    if (roleOptions && roleOptions.length === 1) {
      setSelectedRole(roleOptions[0]);
    }
  }, [roleOptions]);

  useEffect(() => {
    // console.log(createdMemberInfo);
    if (createdMemberInfo && isSubmitted) {
      history.push({
        pathname: '/user/members',
        state: { from: 'create' },
      });
    }
  }, [createdMemberInfo]);

  useEffect(() => {
    // console.log(updatedData);
    if (updatedData && isSubmitted) {
      history.push({
        pathname: '/user/members',
        state: { from: 'update' },
      });
    }
  }, [updatedData]);

  const onOfficeChange = e => {
    setSelectedOffice(e);
  };

  const onFirstNameChange = e => {
    let errors = { ...saveErrors };
    delete errors['firstName'];
    setSaveErrors(errors);
    setFirstName(e.target.value);
  };

  const onLastNameChange = e => {
    let errors = { ...saveErrors };
    delete errors['lastName'];
    setSaveErrors(errors);
    setLastName(e.target.value);
  };

  const onEmailChange = e => {
    let errors = { ...saveErrors };
    delete errors['email'];
    setSaveErrors(errors);
    setEmail(e.target.value);
  };

  const onPasswordChange = e => {
    let errors = { ...saveErrors };
    delete errors['password'];
    setSaveErrors(errors);
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onConfirmPasswordChange = e => {
    let errors = { ...saveErrors };
    delete errors['confirmPassword'];
    setSaveErrors(errors);
    setConfirmPassword(e.target.value);
  };

  const onRoleChange = e => {
    setSelectedRole(e);
  };

  const onUserCodeChange = e => {
    let errors = { ...saveErrors };
    delete errors['userCode'];
    setSaveErrors(errors);
    setUserCode(e.target.value);
  };

  const onChangeImage = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList);
    setImages(imageList);
    if (memberId) {
      setImageUpdated(true);
    }
  };

  const onUpdateImage = () => {
    if (memberId) {
      setImageUpdated(true);
    }
  };

  const handleSave = status => {
    // console.log('called');
    if (checkError()) {
      let payload = {
        first_name: firstName,
        last_name: lastName,
        // office: selectedOffice.value,
        email: email.trim(),
        password: password,
        // role: selectedRole.value,
        // user_code: userCode,
        phone: phoneNumber,
      };
      if (status === 'update') {
        payload.user_id = memberId;
        if (imageUpdated) {
          images && images.length > 0 && images[0].file
            ? (payload.image = images[0].file)
            : (payload.image = '');
        }
        // updateMember(payload);
      } else {
        images && images.length > 0 && images[0].file
          ? (payload.image = images[0].file)
          : (payload.image = null);
        addMember(payload);
      }
      setIsSubmitted(true);
    }
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!firstName.trim()) {
      formIsValid = false;
      saveErrors['firstName'] = 'First name is required';
    } else if (checkUrl(firstName)) {
      formIsValid = false;
      saveErrors['firstName'] = 'First name can not contain url.';
    } else if (checkHtml(firstName)) {
      formIsValid = false;
      saveErrors['firstName'] = 'Invalid First name';
    } else if (firstName.length > 80) {
      formIsValid = false;
      saveErrors['firstName'] = 'Maximum length 80 characters';
    }

    if (checkUrl(lastName)) {
      formIsValid = false;
      saveErrors['lastName'] = 'Last name can not contain url.';
    } else if (checkHtml(lastName)) {
      formIsValid = false;
      saveErrors['lastName'] = 'Invalid Last name';
    } else if (lastName.length > 80) {
      formIsValid = false;
      saveErrors['lastName'] = 'Maximum length 80 characters';
    }

    if (!selectedOffice.value.trim()) {
      formIsValid = false;
      saveErrors['office'] = 'Office must be selected.';
    }

    if (!selectedRole.value.trim()) {
      formIsValid = false;
      saveErrors['role'] = 'Role must be selected.';
    }

    if (!email.trim()) {
      formIsValid = false;
      saveErrors['email'] = 'email is required';
    } else if (!validateEmail(email.trim())) {
      formIsValid = false;
      saveErrors['email'] = 'Please enter a valid email address';
    }

    if (!memberId) {
      if (!password.trim()) {
        formIsValid = false;
        saveErrors['password'] = 'password is required';
      } else if (password.length < 7) {
        formIsValid = false;
        saveErrors['password'] = 'Password can not be less than 7 characters.';
      } else if (password.length > 32) {
        formIsValid = false;
        saveErrors['password'] = 'Password can not be more than 32 characters.';
      }
      if (password !== confirmPassword) {
        formIsValid = false;
        saveErrors['confirmPassword'] = 'password does not match';
      }
    } else {
      if (password.trim() && password.length < 7) {
        formIsValid = false;
        saveErrors['password'] = 'Password can not be less than 7 characters.';
      } else if (password.length > 32) {
        formIsValid = false;
        saveErrors['password'] = 'Password can not be more than 32 characters.';
      }
      if (password !== confirmPassword) {
        formIsValid = false;
        saveErrors['confirmPassword'] = 'password does not match';
      }
    }

    // if (!userCode.trim()) {
    //   formIsValid = false;
    //   saveErrors['userCode'] = 'User Code is required';
    // } else
    if (checkUrl(userCode)) {
      formIsValid = false;
      saveErrors['userCode'] = 'User Code can not contain url.';
    } else if (checkHtml(userCode)) {
      formIsValid = false;
      saveErrors['userCode'] = 'Invalid User Code';
    } else if (userCode.length > 80) {
      formIsValid = false;
      saveErrors['userCode'] = 'Maximum length 80 characters';
    }
    // console.log(saveErrors);
    // console.log(formIsValid);
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  return (
    <div className="content">
      {isLoading || updateMemberLoading || createMemberLoading ? (
        <ComponentCircleLoader />
      ) : (
        <>
          <PageTitle>
            {memberId && memberId.length > 1 ? t('update_user') : t('add_user')}
          </PageTitle>
          <ContentHeader>
            <BackButtonComponent
              onClick={() => history.push('/user/members')}
              subTitle={t('back_to_all_members')}
            />
          </ContentHeader>
          <Container
            padding="30px"
            gap="20px"
            style={{ minHeight: `80vh`, alignContent: `start` }}
          >
            <CommonText name title fontSize="16px">
              {memberId && memberId.length > 1
                ? t('update_member_details')
                : t('add_member_details')}
            </CommonText>
            <InputSection>
              <InputWithLabelSection>
                <InputLabel>
                  {t('first_name')} <RedStar>*</RedStar>
                </InputLabel>
                <InputField
                  type="text"
                  placeholder={t('type_first_name')}
                  value={firstName}
                  onChange={e => onFirstNameChange(e)}
                  style={
                    saveErrors &&
                    saveErrors[`firstName`] && {
                      border: `1px solid #fe5969`,
                      backgroundColor: `rgba(252, 87, 104, 0.05)`,
                    }
                  }
                />
                {saveErrors && saveErrors['firstName'] && (
                  <FormAlert>{saveErrors['firstName']}</FormAlert>
                )}
              </InputWithLabelSection>

              <InputWithLabelSection>
                <InputLabel>{t('last_name')}</InputLabel>
                <InputField
                  type="text"
                  placeholder={t('type_last_name')}
                  value={lastName}
                  onChange={e => onLastNameChange(e)}
                  style={
                    saveErrors &&
                    saveErrors[`lastName`] && {
                      border: `1px solid #fe5969`,
                      backgroundColor: `rgba(252, 87, 104, 0.05)`,
                    }
                  }
                />
                {saveErrors && saveErrors['lastName'] && (
                  <FormAlert>{saveErrors['lastName']}</FormAlert>
                )}
              </InputWithLabelSection>

              {/* <InputWithLabelSection>
                <InputLabel>
                  {t('office')} <RedStar>*</RedStar>
                </InputLabel>
                <Select
                  isSearchable
                  value={selectedOffice}
                  options={officeOptions}
                  placeholder={t('select_office')}
                  isDisabled={officesShortList && officesShortList.length === 1}
                  onChange={e => {
                    onOfficeChange(e);
                  }}
                  styles={CreateProjectDropdown()}
                  filterOption={filterOption}
                />
                {saveErrors && saveErrors['office'] && (
                  <FormAlert>{saveErrors['office']}</FormAlert>
                )}
              </InputWithLabelSection> */}

              <InputWithLabelSection>
                <InputLabel>
                  {t('role')} <RedStar>*</RedStar>
                </InputLabel>
                <Select
                  isSearchable
                  value={selectedRole}
                  options={roleOptions}
                  placeholder={t('select_role')}
                  isDisabled={roleOptions && roleOptions.length === 1}
                  onChange={e => {
                    onRoleChange(e);
                  }}
                  styles={CreateProjectDropdown()}
                />
                {saveErrors && saveErrors['role'] && (
                  <FormAlert>{saveErrors['role']}</FormAlert>
                )}
              </InputWithLabelSection>

              <InputWithLabelSection>
                <InputLabel>
                  {t('email')} <RedStar>*</RedStar>
                </InputLabel>
                <InputField
                  type="email"
                  placeholder={t('type_email')}
                  value={email}
                  onChange={e => onEmailChange(e)}
                  style={
                    saveErrors &&
                    saveErrors[`email`] && {
                      border: `1px solid #fe5969`,
                      backgroundColor: `rgba(252, 87, 104, 0.05)`,
                    }
                  }
                />
                {saveErrors && saveErrors['email'] && (
                  <FormAlert>{saveErrors['email']}</FormAlert>
                )}
              </InputWithLabelSection>

              <InputWithLabelSection style={{ position: `relative` }}>
                <InputLabel>
                  {t('password')} <RedStar>*</RedStar>
                </InputLabel>
                <InputField
                  type={showPassword ? 'text' : 'password'}
                  placeholder={t('type_password')}
                  value={password}
                  onChange={e => onPasswordChange(e)}
                  style={
                    saveErrors &&
                    saveErrors[`password`] && {
                      border: `1px solid #fe5969`,
                      backgroundColor: `rgba(252, 87, 104, 0.05)`,
                    }
                  }
                />
                {saveErrors && saveErrors['password'] && (
                  <FormAlert>{saveErrors['password']}</FormAlert>
                )}
                <ShowPasswordIcon
                  style={{ bottom: `6px` }}
                  src={showPassword ? hidePassword : viewPassword}
                  alt=""
                  onClick={e => toggleShowPassword(e)}
                />
              </InputWithLabelSection>

              <InputWithLabelSection style={{ position: `relative` }}>
                <InputLabel>
                  {t('confirm_password')} <RedStar>*</RedStar>
                </InputLabel>
                <InputField
                  type={showPassword ? 'text' : 'password'}
                  placeholder={t('type_password_again')}
                  value={confirmPassword}
                  onChange={e => onConfirmPasswordChange(e)}
                  style={
                    saveErrors &&
                    saveErrors[`confirmPassword`] && {
                      border: `1px solid #fe5969`,
                      backgroundColor: `rgba(252, 87, 104, 0.05)`,
                    }
                  }
                />
                {saveErrors && saveErrors['confirmPassword'] && (
                  <FormAlert>{saveErrors['confirmPassword']}</FormAlert>
                )}
                <ShowPasswordIcon
                  style={{ bottom: `6px` }}
                  src={showPassword ? hidePassword : viewPassword}
                  alt=""
                  onClick={e => toggleShowPassword(e)}
                />
              </InputWithLabelSection>

              {/* <InputWithLabelSection>
                <InputLabel>
                  {t('user_id')} <RedStar>*</RedStar>
                </InputLabel>
                <InputField
                  type="text"
                  placeholder={t('type_user_id')}
                  value={userCode}
                  onChange={e => onUserCodeChange(e)}
                  style={
                    saveErrors &&
                    saveErrors[`userCode`] && {
                      border: `1px solid #fe5969`,
                      backgroundColor: `rgba(252, 87, 104, 0.05)`,
                    }
                  }
                />
                {saveErrors && saveErrors['userCode'] && (
                  <FormAlert>{saveErrors['userCode']}</FormAlert>
                )}
              </InputWithLabelSection> */}

              <InputWithLabelSection>
                <InputLabel>
                  {t('phone_number')}
                  <RedStar>*</RedStar>
                </InputLabel>
                <PhoneInput
                  enableSearch
                  country={'sa'}
                  value={phoneNumber}
                  onChange={phone => setPhoneNumber(phone)}
                  inputClass="phone-number-input"
                  buttonClass="phone-number-flag-dropdown"
                />
                {saveErrors && saveErrors['phoneNumber'] && (
                  <FormAlert>{saveErrors['phoneNumber']}</FormAlert>
                )}
              </InputWithLabelSection>

              <InputWithLabelSection>
                <InputLabel>{t('profile_picture')}</InputLabel>
                <ImageUploading
                  value={images}
                  onChange={onChangeImage}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <UploadButtonContainer
                      style={{ gridTemplateColumns: `1fr` }}
                    >
                      <UploadImageButton
                        style={isDragging ? { color: 'red' } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        {t('click_or_drop_here_to_upload')}
                      </UploadImageButton>
                      {/* <ColoredButton type="delete" onClick={onImageRemoveAll}>Remove image</ColoredButton> */}
                      {imageList.map((image, index) => (
                        <div
                          key={index}
                          style={{ gridColumn: `1 / span 2` }}
                          className="image-item"
                        >
                          <img src={image['data_url']} alt="" width="100" />
                          <UploadButtonContainer style={{ marginTop: `20px` }}>
                            <ColoredButton
                              type="blue"
                              onClick={() => {
                                onImageUpdate(index);
                                onUpdateImage();
                              }}
                            >
                              {t('update')}
                            </ColoredButton>
                            <ColoredButton
                              type="delete"
                              onClick={() => {
                                onImageRemove(index);
                                onUpdateImage();
                              }}
                            >
                              {t('remove')}
                            </ColoredButton>
                          </UploadButtonContainer>
                        </div>
                      ))}
                    </UploadButtonContainer>
                  )}
                </ImageUploading>
                {saveErrors && saveErrors['phoneNumber'] && (
                  <FormAlert>{saveErrors['phoneNumber']}</FormAlert>
                )}
              </InputWithLabelSection>
              {memberId && memberId.length > 1 ? (
                <PrimaryButton
                  disabled={
                    !firstName ||
                    !email ||
                    !phoneNumber
                  }
                  onClick={() => handleSave('update')}
                >
                  {updateMemberLoading ? (
                    <ButtonTextLoader
                      loadingText={t('update_user')}
                      fontSize="13px"
                    />
                  ) : (
                    t('update_user')
                  )}
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  disabled={
                    !firstName ||
                    !selectedOffice ||
                    !email ||
                    !password ||
                    !confirmPassword ||
                    !selectedRole ||
                    !userCode ||
                    !phoneNumber
                  }
                  onClick={() => handleSave()}
                >
                  {createMemberLoading ? (
                    <ButtonTextLoader
                      loadingText={t('create_a_new_user')}
                      fontSize="13px"
                    />
                  ) : (
                    t('create_a_new_user')
                  )}
                </PrimaryButton>
              )}
            </InputSection>
          </Container>
        </>
      )}
    </div>
  );
};

export default AddMember;
