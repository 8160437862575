import moment from 'moment';
import { Fragment, useEffect, useRef, useState } from 'react';

import deleteWhite from '../../../assets/img/icons/delete_white.svg';
import Info from '../../../assets/img/icons/info.svg';
import Plus from '../../../assets/img/icons/plus_white.svg';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import NoDataComponent from '../../../components/NoDataComponent/NoDataComponent';
import CommonTooltip from '../../../components/Tooltip/CommonTooltip';
import {
  ButtonIcon,
  ColoredButtonWithIcon,
  WhiteButton,
} from '../../../styledComponents/buttons';
import {
  CommonGrid,
  CommonText,
  Container,
  PageTitle,
} from '../../../styledComponents/common';
import { ActivityTextSection } from '../../../styledComponents/members';
import {
  CalendarContainer,
  DeleteButton,
  InfoButton,
  TimeSlot,
  TimeSlotCard,
  TimeSlotInnerCard,
  TimeSlotSmallInfo,
  TimeText,
  TimesheetTooltipContainer,
  UpperTotalHour,
} from '../../../styledComponents/timesheet';
import { toHHMMSS } from '../../../utils/helper';
import TimesheetFilter from '../TimesheetFilter';
import DeleteTimeModal from './DeleteTimeModal';
import ManualTimeModal from './ManualTimeModal';
import { getDuration, getDurationInMinutes, getHours } from './helperFunctions';
import { timeList } from './timeList';

const TimesheetDetailsPopup = ({
  index,
  data,
  startPosition,
  getDuration,
  toggleDeleteTimeModal,
  setInfoPopup,
}) => {
  const [hover, setHover] = useState(false);
  const popUpRef = useRef(null);

  //outside click function
  useOutsideClickHandler(popUpRef);

  function useOutsideClickHandler(popUpRef) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (popUpRef.current && !popUpRef.current.contains(event.target)) {
          setInfoPopup(-1);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [popUpRef]);
  }

  const onHoverChange = value => {
    setHover(value);
  };
  return (
    <TimeSlotCard
      index={index}
      top={data.top - 85}
      height={75}
      startPosition={startPosition}
      style={{
        left: '200px',
        zIndex: '5',
      }}
      ref={popUpRef}
    >
      <TimeSlotInnerCard
        onMouseEnter={() => onHoverChange(true)}
        onMouseLeave={() => onHoverChange(false)}
      >
        <ActivityTextSection>
          <CommonText name fontSize="14px" fontWeight="600">
            {data.work_order ? data.work_order.name : 'No work order'}
          </CommonText>
          <CommonText fontSize="12px" fontWeight="600">
            {data.job ? data.job.name : 'No job'}
          </CommonText>
        </ActivityTextSection>
        <ActivityTextSection>
          <CommonText name fontSize="14px" fontWeight="600">
            {data.start_time.format('h:mm A')}
          </CommonText>
          <CommonText fontSize="12px" fontWeight="600">
            Start Time
          </CommonText>
        </ActivityTextSection>
        <ActivityTextSection>
          <CommonText name fontSize="14px" fontWeight="600">
            {data.end_time.format('h:mm A')}
          </CommonText>
          <CommonText fontSize="12px" fontWeight="600">
            End Time
          </CommonText>
        </ActivityTextSection>
        <ActivityTextSection>
          <CommonText name fontSize="14px" fontWeight="600">
            {getDuration(data.duration)}
          </CommonText>
          <CommonText fontSize="12px" fontWeight="600">
            Total Time
          </CommonText>
        </ActivityTextSection>
      </TimeSlotInnerCard>
    </TimeSlotCard>
  );
};

const Daily = props => {
  const [manualTimeModalVisible, setManualTimeModalVisible] = useState(false);
  const [deleteTimesheetModalVisible, setDeleteTimesheetModalVisible] =
    useState(false);
  const [selectedDate, setSelectedDate] = useState(
    moment().subtract(1, 'days').format('YYYY-MM-DD'),
  );
  const isYesterday = moment(selectedDate).isSame(moment().subtract(1, 'day'), 'day');
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedTask, setSelectedTask] = useState('');
  const [selectedMember, setSelectedMember] = useState('');
  const [timesheetList, setTimesheetList] = useState([]);
  const [durations, setDurations] = useState([]);
  const [selectIndex, setSelectIndex] = useState(-1);
  const [currentTimesheet, setCurrentTimesheet] = useState(null);
  const [infoPopup, setInfoPopup] = useState(-1);

  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [selectedOffice, setSelectedOffice] = useState({
    label: 'All Offices',
    value: null,
  });

  const {
    selectedOrganization,
    getDailyTimesheets,
    timesheet,
    createDailyTimesheets,
    manualTimeAdded,
    clearDailyTimesheetAdded,
    deleteDailyTimesheets,
    projectsList,
    getProjectAssigneeList,
    projectAssigneeList,
    projectTaskList,

    getOrganizationMembersList,
    orgMembersList,
    membersList,
    manualTimeLoading,
    deleteTimesheetLoading,
    isLoading,

    //member short list
    memberShortListIsLoading,
    orgMembersShortList,
    getOrganizationMembersShortList,

    //Office
    getOfficeDetails,
    officeDetails,
    getOfficesShortList,
    officesShortList,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      let payload = {
        organization_id: selectedOrganization.id,
        date: selectedDate,
      };
      // if (selectedOrganization.timezone) {
      //   setSelectedTimezone({
      //     type: 'Organization',
      //     timezone: selectedOrganization.timezone,
      //     offset: selectedOrganization.timezoneoffset,
      //   });
      //   payload.timezone = selectedOrganization.timezone;
      // }

      setSelectedTask('');
      setSelectedMember('');

      getDailyTimesheets(payload);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (timesheet && timesheet.length > 0) {
      let list = [];
      let timeDurations = [];
      timesheet.map(time => {
        let timeSlot = {};

        let compareTime = moment('00:00', 'HH:mm');

        let startTime = time.start_timestamp.format('HH:mm');

        timeSlot.id = time.id;
        timeSlot.start_time = time.start_timestamp;
        timeSlot.end_time = time.end_timestamp;
        timeSlot.duration = time.duration;
        timeSlot.work_order = time.work_order;
        timeSlot.job = time.job;
        // timeDurations.push(moment(toHHMMSS(duration)).format("HH:mm:ss"));
        timeDurations.push(time.duration);

        let startTimeDuration = moment.utc(
          moment(startTime, 'HH:mm').diff(moment(compareTime)),
        );
        let startTimeHour = parseInt(
          moment.duration(startTimeDuration).asHours(),
        );
        let startTimeMinutes = parseInt(
          moment.duration(startTimeDuration).asMinutes() % 60,
        );

        timeSlot.top = parseInt((startTimeHour + startTimeMinutes / 60) * 75);

        let hours = parseInt(toHHMMSS(time.duration).split(':')[0]);
        let minutes = parseInt(toHHMMSS(time.duration).split(':')[1]);
        if (parseInt((hours + minutes / 60) * 75) === 0) {
          timeSlot.height = 1;
        } else {
          timeSlot.height = parseInt((hours + minutes / 60) * 75);
        }
        list.push(timeSlot);
      });
      setTimesheetList(list);
      setDurations(timeDurations);
    } else if (timesheet && timesheet.length === 0) {
      setTimesheetList([]);
      setDurations([]);
    }
  }, [timesheet]);

  // const selectProject = e => {
  //   let organizationId = selectedOrganization ? selectedOrganization.id : '';
  //   if (organizationId) {
  //     const payload = {
  //       organization_id: organizationId,
  //       date: selectedDate,
  //     };
  //     if (selectedMember && e.value) {
  //       payload.user_id = selectedMember.id;
  //     }
  //     if (e && e.value) {
  //       payload.project_id = e.value;
  //     }
  //     if (selectedTimezone && selectedTimezone.timezone) {
  //       payload.timezone = selectedTimezone.timezone;
  //     }
  //     getDailyTimesheets(payload);
  //   }

  //   if (
  //     e &&
  //     e.value &&
  //     selectedProject &&
  //     selectedProject.value &&
  //     e.value !== selectedProject.value
  //   ) {
  //     setSelectedProject(e);
  //     setSelectedTask('');
  //   } else if ((e && !e.value) || e == null) {
  //     setSelectedMember('');
  //     setSelectedTask('');
  //     setSelectedProject(e);
  //   } else {
  //     setSelectedProject(e);
  //   }
  // };
  // const selectTask = e => {
  //   let organizationId = selectedOrganization ? selectedOrganization.id : '';
  //   if (organizationId) {
  //     const payload = {
  //       organization_id: organizationId,
  //       date: selectedDate,
  //     };
  //     if (selectedMember) {
  //       payload.user_id = selectedMember.id;
  //     }
  //     if (e && e.value) {
  //       payload.task_id = e.value;
  //     }
  //     if (selectedTimezone && selectedTimezone.timezone) {
  //       payload.timezone = selectedTimezone.timezone;
  //     }
  //     getDailyTimesheets(payload);
  //   }
  //   setSelectedTask(e);
  // };
  const selectTeamMember = e => {
    let organizationId = selectedOrganization ? selectedOrganization.id : '';
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        date: selectedDate,
        user_id: e.id,
      };
      // if (selectedProject && selectedProject.value) {
      //   payload.project_id = selectedProject.value;
      // }
      // if (selectedTask && selectedTask.value) {
      //   payload.task_id = selectedTask.value;
      // }
      // if (
      //   selectedTimezone &&
      //   selectedTimezone.type === 'Member' &&
      //   e.timezone &&
      //   e.timezone.tz
      // ) {
      //   payload.timezone = e.timezone.tz;
      //   setSelectedTimezone({
      //     type: 'Member',
      //     timezone: e.timezone.tz,
      //     offset: e.timezone.offset,
      //   });
      // } else if (
      //   selectedTimezone &&
      //   selectedTimezone.type === 'Organization' &&
      //   selectedTimezone.timezone
      // ) {
      //   payload.timezone = selectedTimezone.timezone;
      // }
      setSelectedMember(e);
      getDailyTimesheets(payload);
    }
  };

  const clearMember = () => {
    let organizationId = selectedOrganization ? selectedOrganization.id : '';
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        date: selectedDate,
      };
      // if (selectedProject && selectedProject.value) {
      //   payload.project_id = selectedProject.value;
      // }
      // if (selectedTask && selectedTask.value) {
      //   payload.task_id = selectedTask.value;
      // }
      // if (selectedOrganization.timezone) {
      //   setSelectedTimezone({
      //     type: 'Organization',
      //     timezone: selectedOrganization.timezone,
      //     offset: selectedOrganization.timezoneoffset,
      //   });
      //   payload.timezone = selectedOrganization.timezone;
      // }
      getDailyTimesheets(payload);
    }
    setSelectedMember('');
  };



  const singleDateChange = (direction) => {
    const newDate = direction === "right"
      ? moment(selectedDate).add(1, "days")
      : moment(selectedDate).subtract(1, "days");
    selectDate(newDate);
  };
  

  const selectDate = date => {
    let organizationId = selectedOrganization ? selectedOrganization.id : '';
    setSelectedDate(date.format('YYYY-MM-DD'));
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        date: date.format('YYYY-MM-DD'),
      };
      // if (selectedProject && selectedProject.value) {
      //   payload.project_id = selectedProject.value;
      // }
      // if (selectedTask && selectedTask.value) {
      //   payload.task_id = selectedTask.value;
      // }
      if (selectedMember && selectedMember.id !== '') {
        payload.user_id = selectedMember.id;
      }
      if (selectedTimezone && selectedTimezone.timezone) {
        payload.timezone = selectedTimezone.timezone;
      }
      getDailyTimesheets(payload);
    }
  };

  const selectOffice = e => {
    if (e && e.value && selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        office_id: e.value,
      };

      getOfficeDetails(payload);
    }

    setSelectedMember('');
    // getDailyTimesheets(payload);
    setSelectedOffice(e);
  };

  // const onTimezoneSelect = timezone => {
  //   let organizationId = selectedOrganization ? selectedOrganization.id : '';
  //   setSelectedTimezone(timezone);
  //   if (organizationId) {
  //     const payload = {
  //       organization_id: organizationId,
  //       date: selectedDate,
  //     };
  //     if (selectedProject && selectedProject.value) {
  //       payload.project_id = selectedProject.value;
  //     }
  //     if (selectedTask && selectedTask.value) {
  //       payload.task_id = selectedTask.value;
  //     }
  //     if (selectedMember && selectedMember.id !== '') {
  //       payload.user_id = selectedMember.id;
  //     }
  //     if (timezone) {
  //       payload.timezone = timezone.timezone;
  //     }
  //     getDailyTimesheets(payload);
  //   }
  // };

  const onHoverChange = index => {
    setSelectIndex(index);
  };

  const getTimesheetList = () => {
    setTimeout(() => {
      let organizationId = selectedOrganization ? selectedOrganization.id : '';
      if (organizationId) {
        const payload = {
          organization_id: organizationId,
          date: selectedDate,
        };
        if (selectedMember) {
          payload.user_id = selectedMember.id;
        }
        // if (selectedTimezone && selectedTimezone.timezone) {
        //   payload.timezone = selectedTimezone.timezone;
        // }
        getDailyTimesheets(payload);
      }
    }, 1000);
  };

  const handleAddTimesheet = payload => {
    createDailyTimesheets(payload);
  };

  useEffect(() => {
    if (manualTimeAdded) {
      setManualTimeModalVisible(false);
      getTimesheetList();

      setTimeout(() => {
        clearDailyTimesheetAdded();
      }, 500);
    }
  }, [manualTimeAdded]);

  const handleDeleteTimesheet = (id, startTimestamp, endTimestamp, reason) => {
    if (selectedOrganization && selectedOrganization.id) {
      const userId = localStorage.getItem('user_id');
      const payload = {
        organization_id: selectedOrganization.id,
        timesheet_id: id,
        start: moment(startTimestamp).utc().unix(),
        end: moment(endTimestamp).utc().unix(),
        comment: reason,
      };
      if (
        selectedOrganization.role &&
        selectedOrganization.role !== 'member' &&
        selectedMember &&
        selectedMember.id &&
        userId &&
        userId !== selectedMember.id
      ) {
        payload.user_id = selectedMember.id;
      }
      console.log('🚀 ~ handleDeleteTimesheet ~ payload:', payload);

      deleteDailyTimesheets(payload);
    }
    setTimeout(() => {
      setDeleteTimesheetModalVisible(false);
      getTimesheetList();
    }, 800);
  };

  const toggleManualTimeModal = () => {
    setManualTimeModalVisible(!manualTimeModalVisible);
  };

  const toggleDeleteTimeModal = timesheet => {
    if (!deleteTimesheetModalVisible) {
      setCurrentTimesheet(timesheet);
    } else {
      setCurrentTimesheet(null);
    }
    setDeleteTimesheetModalVisible(!deleteTimesheetModalVisible);
  };

  const toggleInfoPopup = index => {
    setInfoPopup(index);
  };

  return (
    <div className="content">
      <ManualTimeModal
        isOpen={manualTimeModalVisible}
        toggle={toggleManualTimeModal}
        manualTimeAdded={manualTimeAdded}
        selectedOrganization={selectedOrganization}
        getProjectAssigneeList={getProjectAssigneeList}
        projectAssigneeList={projectAssigneeList}
        getOrganizationMembersList={getOrganizationMembersShortList}
        organizationMembersList={orgMembersShortList}
        membersList={membersList}
        handleAddTimesheet={handleAddTimesheet}
        isLoading={manualTimeLoading}
        filterSelectedDate={selectedDate}
        filterSelectedMember={selectedMember}
      />
      <DeleteTimeModal
        isOpen={deleteTimesheetModalVisible}
        toggle={toggleDeleteTimeModal}
        currentTimesheet={currentTimesheet}
        handleDeleteTimesheet={handleDeleteTimesheet}
        isLoading={deleteTimesheetLoading}
        timezone={selectedTimezone && selectedTimezone.timezone}
      />
      <CommonGrid alignItem="center">
        <PageTitle>Daily Timesheet</PageTitle>
        {(selectedOrganization &&
          selectedOrganization.configuration &&
          selectedOrganization.configuration.allow_user_add_manual_timesheet) ||
        (selectedOrganization &&
          selectedOrganization.role &&
          selectedOrganization.role !== 'member') ? (
          <CommonGrid>
            <ColoredButtonWithIcon
              style={{ alignSelf: 'flex-end' }}
              onClick={() => toggleManualTimeModal()}
            >
              <ButtonIcon src={Plus} alt="" />
              Add Manual Time
            </ColoredButtonWithIcon>
          </CommonGrid>
        ) : null}
      </CommonGrid>
      <TimesheetFilter
        page="daily"
        selectedDate={moment(selectedDate)}
        onDateChange={selectDate}
        // selectedProject={selectedProject}
        // selectProject={selectProject}
        // selectedTask={selectedTask}
        // selectTask={selectTask}
        selectedMember={selectedMember}
        selectMember={selectTeamMember}
        clearMember={clearMember}
        getOfficesShortList={getOfficesShortList}
        officesShortList={officesShortList}
        selectedOffice={selectedOffice}
        selectOffice={selectOffice}
        getOrganizationMembersList={getOrganizationMembersShortList}
        organizationMembersList={orgMembersShortList}
        selectedOrganization={selectedOrganization}
        // selectTimezone={onTimezoneSelect}
        // selectedTimezone={selectedTimezone}
        officeDetails={officeDetails}
        isYesterday={isYesterday}
        singleDateChange={singleDateChange}
      />
      <Container style={{ padding: `30px` }}>
        {isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            {timesheetList && timesheetList.length > 0 && (
              <>
                <CalendarContainer>
                  <div></div>
                  <CommonGrid
                    justifyContent="start"
                    gap="0 40px"
                    columns="auto auto auto"
                  >
                    <ActivityTextSection>
                      <CommonText style={{ textAlign: `center` }}>
                        {moment(selectedDate).format('ddd')}
                      </CommonText>
                      <WhiteButton
                        cursor="auto"
                        height="45px"
                        width="45px"
                        padding="0"
                        borderColor="#20bead"
                        hoverBorderColor="#20bead"
                      >
                        {moment(selectedDate).format('DD')}
                      </WhiteButton>
                    </ActivityTextSection>
                    <UpperTotalHour>
                      <CommonText $label>Total Hours</CommonText>
                      <CommonText title>
                        {durations && durations.length > 0
                          ? getHours(
                              toHHMMSS(
                                // durations
                                //   .slice(1)
                                //   .reduce(
                                //     (prev, cur) =>
                                //       moment.duration(cur).add(prev),
                                //     moment.duration(durations[0])
                                //   )
                                //   .asMilliseconds() / 1000
                                durations
                                  .slice(1)
                                  .reduce(
                                    (prev, cur) => cur + prev,
                                    durations[0],
                                  ),
                              ),
                            )
                          : '0 hour 00 minute'}
                      </CommonText>
                    </UpperTotalHour>
                  </CommonGrid>
                </CalendarContainer>
                <CalendarContainer style={{ marginTop: `40px` }}>
                  {timeList.map(
                    (time, index) =>
                      timesheetList &&
                      timesheetList.length > 0 &&
                      index >
                        moment(timesheetList[0].start_time).hours() - 1 && (
                        <Fragment key={index}>
                          <CommonText margin="0">{time.time}</CommonText>
                          <TimeSlot
                            index={index}
                            startPosition={moment(
                              timesheetList[0].start_time,
                            ).hours()}
                          ></TimeSlot>
                        </Fragment>
                      ),
                  )}
                  {timesheetList &&
                    timesheetList.length > 0 &&
                    timesheetList.map((card, index) => (
                      <Fragment key={index}>
                        {infoPopup === index && (
                          <TimesheetDetailsPopup
                            index={index}
                            data={card}
                            startPosition={timesheetList[0].start_time.hours()}
                            getDuration={getDuration}
                            toggleDeleteTimeModal={toggleDeleteTimeModal}
                            setInfoPopup={setInfoPopup}
                          />
                        )}
                        <TimeSlotCard
                          key={index}
                          top={card.top}
                          height={card.height}
                          index={index}
                          startPosition={timesheetList[0].start_time.hours()}
                          small={
                            getDurationInMinutes(card.duration) < 20
                              ? true
                              : false
                          }
                        >
                          {getDurationInMinutes(card.duration) < 20 ? (
                            <TimeSlotSmallInfo
                              index={index}
                              top={parseInt(card.height / 2)}
                              onClick={() => toggleInfoPopup(index)}
                            >
                              <img src={Info} alt="" />
                            </TimeSlotSmallInfo>
                          ) : (
                            <TimeSlotInnerCard
                              onMouseEnter={() => onHoverChange(index)}
                              onMouseLeave={() => onHoverChange(-1)}
                            >
                              <ActivityTextSection>
                                <CommonText name title>
                                  {card.work_order
                                    ? card.work_order.name
                                    : 'No Work Order'}
                                </CommonText>
                                {getDurationInMinutes(card.duration) > 40 && (
                                  <CommonText fontSize="12px" title>
                                    {card.job ? card.job.name : 'No Job'}
                                  </CommonText>
                                )}
                              </ActivityTextSection>
                              <ActivityTextSection>
                                <CommonText name title>
                                  {card.start_time.format('h:mm A')}
                                </CommonText>
                                {getDurationInMinutes(card.duration) > 40 && (
                                  <CommonText fontSize="12px" title>
                                    Start Time
                                  </CommonText>
                                )}
                              </ActivityTextSection>
                              <ActivityTextSection>
                                <CommonText name title>
                                  {card.end_time.format('h:mm A')}
                                </CommonText>
                                {getDurationInMinutes(card.duration) > 40 && (
                                  <CommonText fontSize="12px" title>
                                    End Time
                                  </CommonText>
                                )}
                              </ActivityTextSection>
                              <ActivityTextSection>
                                <CommonText name title>
                                  {getDuration(card.duration)}
                                </CommonText>
                                {getDurationInMinutes(card.duration) > 40 && (
                                  <CommonText fontSize="12px" title>
                                    Total Time
                                  </CommonText>
                                )}
                              </ActivityTextSection>
                              {selectIndex === index && (
                                <>
                                  <DeleteButton
                                    onClick={() => toggleDeleteTimeModal(card)}
                                  >
                                    <img
                                      src={deleteWhite}
                                      style={{
                                        width: '16px',
                                        height: '16px',
                                      }}
                                      alt="delete"
                                    />
                                    <TimesheetTooltipContainer>
                                      <CommonTooltip
                                        tooltipText="Delete"
                                        left="-50px"
                                        top="-46px"
                                        width="85px"
                                        height="25px"
                                      />
                                    </TimesheetTooltipContainer>
                                  </DeleteButton>
                                  {getDurationInMinutes(card.duration) <= 40 &&
                                    getDurationInMinutes(card.duration) >=
                                      20 && (
                                      <InfoButton
                                        index={index}
                                        onClick={() => toggleInfoPopup(index)}
                                      >
                                        <img src={Info} alt="" />
                                        <TimesheetTooltipContainer>
                                          <CommonTooltip
                                            tooltipText="Details"
                                            left="-50px"
                                            top="-46px"
                                            width="85px"
                                            height="25px"
                                          />
                                        </TimesheetTooltipContainer>
                                      </InfoButton>
                                    )}
                                </>
                              )}
                            </TimeSlotInnerCard>
                          )}
                        </TimeSlotCard>
                      </Fragment>
                    ))}
                </CalendarContainer>
              </>
            )}
            {timesheetList && timesheetList.length === 0 && (
              <NoDataComponent title="There is no timesheet for this date!" />
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default Daily;
