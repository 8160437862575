import { connect } from "react-redux";

import PayrollFilter from "./PayrollFilter";

//Actions
import {
  getPayablesData,
  getHourlyPaymentHistory,
  getOnetimePaymentHistory,
} from "./payrollActions";
import {
  getOrganizationMembersShortList,
} from "../Projects/projectsActions";
import {
  getOfficeDetails
} from "../OfficesNew/officesActions"

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  orgMembersShortList: state.project.orgMembersShortList,
  officeMembersList: state.office.officeMembersList,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getPayablesData: (details) => dispatch(getPayablesData({ ...details })),
  getHourlyPaymentHistory: (details) =>
    dispatch(getHourlyPaymentHistory({ ...details })),
  getOnetimePaymentHistory: (details) =>
    dispatch(getOnetimePaymentHistory({ ...details })),
  getOrganizationMembersShortList: (details) =>
    dispatch(getOrganizationMembersShortList({ ...details })),
  getOfficeDetails: (details) => dispatch(getOfficeDetails({ ...details })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayrollFilter);
