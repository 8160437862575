import { css } from '@emotion/core';
import base64 from 'base-64';
import moment from 'moment';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import styled from 'styled-components';

import highLevelLogo from '../../assets/img/common/high_level_logo.png';
import connectIcon from '../../assets/img/icons/refresh.svg';
import fieldServicelyLogo from '../../assets/img/logo/logo.svg';
import { createNotification } from '../../modules/notificationManager';
import { CommonFlex, CommonImage } from '../../styledComponents/common';
import api from '../../utils/api';

const customCss = css`
  margin: 5px;
  text-align: center;
`;

const Container = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.main.whiteBg};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoadingText = styled.p`
  font-size: 24px;
`;

const TextLoader = ({ loading }) => {
  return (
    <div className="sweet-loading">
      <PulseLoader
        size={8}
        color={'#2f394e'}
        loading={loading}
        css={customCss}
      />
    </div>
  );
};

const GHLAuth = props => {
  const [loading, setLoading] = useState(true);
  const [login, setLogin] = useState(false);
  const [success, setSuccess] = useState(false);

  const {
    history,
    sendGHLAuthenticationCode,
    resetSendGHLAuthenticationCode,
    redirect,
    token,
    userData,
  } = props;

  console.log("redirect", redirect);
  console.log("token", token);
  console.log("userData", userData);

  useEffect(() => {
    if (props.location.search) {
      const queryParsed = queryString.parse(props.location.search);
      if (queryParsed && queryParsed.code) {
        const code = queryParsed.code;
        sendGHLAuthenticationCode({
          code,
        });
      } else {
        setLogin(true);
        createNotification(
          'error',
          'Could not parse authentication code',
          3000,
        );
        setTimeout(() => {
          history.push('/auth/login');
        }, 2000);
      }
    }

    return () => {
      resetSendGHLAuthenticationCode();
    };
  }, []);

  useEffect(() => {
    if (redirect) {
      setLoading(false);
      if (token && userData) {
        localStorage.setItem('access_token', token);
        localStorage.setItem('user_id', userData.user_id);
        localStorage.setItem('user_profile', userData.user_profile.id);
        localStorage.setItem(
          'user_fullName',
          `${userData.user_profile.first_name}${
            userData.user_profile.last_name
              ? ` ${userData.user_profile.last_name}`
              : ``
          }`,
        );
        localStorage.setItem('email', userData.email);
        setSuccess(true);
        setTimeout(() => {
          history.push('/user/members?ghl_members=true');
        }, 2000);
      } else {
        history.push('/auth/login');
      }

      setTimeout(() => {
        resetSendGHLAuthenticationCode();
      }, 2000);
    }
  }, [redirect]);

  return (
    <Container>
      <CommonFlex margin="0 0 40px 0">
        <CommonImage
          src={fieldServicelyLogo}
          style={{ height: '50px', width: '221px' }}
        />
        {/* <CommonImage src={connectIcon} size="50px" /> */}
        {/* <CommonImage
          src={highLevelLogo}
          style={{ height: '50px', width: '221px' }}
        /> */}
      </CommonFlex>
      <CommonFlex margin="30px 0 0">
        <LoadingText>
          {loading
            ? `Sit tight, we are getting things ready for you`
            : success
            ? `Congratulations! Welcome to Fieldservicely`
            : login
            ? `Something went wrong in the process!! Please try again.`
            : ``}
        </LoadingText>
        {loading && <TextLoader loading />}
      </CommonFlex>
    </Container>
  );
};

export default GHLAuth;
