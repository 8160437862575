import styled, { css } from "styled-components";

export const TabLeftGrid = styled.div`
  width: 130%; /* Set your desired width */
  margin-right: 5%; /* Add margin or adjust as needed */
  
  /* Media query for smaller screens */
  @media (max-width: 800px) {
    width: 100%; /* Adjust for smaller screens */
    margin-right: 0; /* Remove margin on smaller screens if needed */
  }

  /* Media query for even smaller screens */
  @media (max-width: 767px) {
    width: 100%; /* Adjust for even smaller screens */
    margin-right: 0; /* Remove margin on smaller screens if needed */
    grid-row-gap: 15px;
  }
`;

export const AddOfficeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ customGridGap }) => customGridGap || "20px 7%"};
  max-width: 760px;
  justify-content: start;
  align-content: space-between;
  margin-top: 25px;
  padding-left: ${({ padding }) => padding || "0"};
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

export const AssigneeSection = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 25px;
  align-content: center;
  justify-content: start;
  margin-top: 20px;
  @media (max-width: 350px) {
    grid-column-gap: 15px;
  }
`;


export const AssigneeRoundImageSection = styled.div`
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  ${({ visibleMembers }) =>
    visibleMembers
      ? css`
          left: ${({ position, visibleMembers }) =>
            position < visibleMembers + 1
              ? `-${position * 15}px`
              : `-${visibleMembers * 15}px`};
        `
      : css`
          left: ${({ position }) =>
            position < 5 ? `-${position * 15}px` : `-60px`};
        `}
`;

export const RoundImageCross = styled.div`
  position: absolute;
  z-index: 1;
  top: -5px;
  right: -5px;
  width: ${({ size }) => size || "19px"};
  height: ${({ size }) => size || "19px"};
  border-radius: 50%;
  display: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background: #979797;
  border: 2px solid ${({ theme }) => theme.colors.main.white};
  padding: 4px;
  &:hover {
    background: ${({ theme }) => theme.colors.main.error};
  }
`;

export const RoundImageTooltip = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: ${({ left }) => left || `-45px`};
  top: ${({ top }) => top || `-36px`};
  padding: 7px 7px;
  border-radius: 8px;
  font-size: 12px;
  color: ${({ textColor }) => textColor || `#fff`};
  border: ${({ border }) => border || `1px solid #1f2939`};
  background-color: ${({ bgColor }) => bgColor || `#1f2939`};
  min-width: ${({ width }) => width || `130px`};
  height: ${({ height }) => height || `30px`};
  z-index: 5;
  &::before,
  ::after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &::after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: ${({ bgColor }) => bgColor || `#1f2939`};
    border-width: 5px;
    margin-left: -5px;
  }
  &::before {
    border-color: rgba(194, 204, 225, 0);
    border-top-color: ${({ bgColor }) => bgColor || `#1f2939`};
    border-width: 6px;
    margin-left: -6px;
  }
`;

export const AssigneeRoundImage = styled.div`
  position: relative;
  right: ${({ index }) => (index === "more" ? `-15px` : `-${index * 15}px`)};
  width: ${({ size }) => size || "40px"};
  height: ${({ size }) => size || "40px"};
  border-radius: 50%;
  background-color: ${({ index, theme, bgColor }) =>
    bgColor
      ? bgColor
      : index === "more"
      ? theme.colors.border.secondary
      : theme.colors.main.white};
  line-height: ${({ size }) => size || "40px"};
  text-align: center;
  color: ${({ theme }) => theme.colors.main.white};
  font-size: 10px;
  cursor: pointer;
  &:hover ${RoundImageCross} {
    display: grid;
  }
  &:hover ${RoundImageTooltip} {
    display: flex;
  }
`;

export const RoundPersonImage = styled.img`
  width: ${({ size }) => size || `40px`};
  height: ${({ size }) => size || `40px`};
  border-radius: 50%;
  border: 1px solid
    ${({ borderColor, theme }) =>
      borderColor ? borderColor : theme.colors.main.white};
`;

export const officeMemberDiv = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: ${({ columns }) => columns || `40px auto`};
  grid-column-gap: 12px;
  padding: ${({ padding }) => padding || "10px 15px"};
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  min-height: 82px;
  align-items: center;
`;

export const officeMemberAvatar = styled.img`
  height: ${({ size }) => size || "40px"};
  width: ${({ size }) => size || "40px"};
  border-radius: 50%;
`;

export const NameRoleSection = styled.div`
  display: grid;
  grid-row-gap: 8px;
  justify-content: flex-start;
`;

export const OfficeMemberDivContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: 12px;
`;

export const CrossIcon = styled.img`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`;

export const officeMemberRole = styled.div`
  background-color: ${({ bgColor }) => bgColor || `#ECF0FC`};
  padding: 4px 10px;
  border-radius: 14px;
  color: ${({ color }) => color || `#9A96F9`};
  font-size: 12px;
  white-space: nowrap;
  text-align: center;
`;