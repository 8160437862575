import {
  SEND_GHL_AUTHENTICATION_CODE,
  SEND_GHL_AUTHENTICATION_CODE_RESET,
} from '../../modules/constants';

export function sendGHLAuthenticationCode(payload) {
  return {
    type: SEND_GHL_AUTHENTICATION_CODE,
    payload,
  };
}

export function resetSendGHLAuthenticationCode() {
  return {
    type: SEND_GHL_AUTHENTICATION_CODE_RESET,
  };
}
