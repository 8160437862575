import { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment-timezone";
import SingleDatePicker from "../../../components/SingleDatePicker/SingleDatePicker";

import {
  CardTitle,
  InputWithLabelSection,
  InputLabel,
  RedStar,
  FormAlert,
  CommonText,
  ColoredText,
} from "../../../styledComponents/common";
import { WhiteButton, PrimaryButton } from "../../../styledComponents/buttons";

import TimeRangeComponent from "./TimeRangeComponent";
import TimezoneDropdown from "./ManualTimeTimezone";

import { Modal, ModalBody } from "reactstrap";

import Select from "../../../components/ReactSelectDropdown";
import ProjectDropdown from "../../../components/CommonFilter/ProjectDropdown";
import TaskDropdown from "../../../components/CommonFilter/TaskDropdown";
import JobDropdown from "../../../components/CommonFilter/JobDropdown";

import { checkUrl, checkHtml } from "../../../utils/helper";

import ModalDropdownStyle from "../../../components/DropdownStyle/ModalDropdownStyle";
import ButtonTextLoader from "../../../components/Loaders/ButtonTextLoader";

const CustomModal = styled(Modal)`
  max-width: 540px;
  margin-top: 0;

  @media screen and (min-width: 1400px) {
    margin-top: 10vh;
  }
`;
const ManualTimeModalBody = styled(ModalBody)`
  padding: 30px;
`;
const ModalInputSection = styled(InputWithLabelSection)`
  margin-top: 15px;
`;
const DateTimeContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 45%;
  justify-content: space-between;
`;
const CommentTextArea = styled.textarea`
  min-height: 98px;
  background: ${({ theme }) => theme.colors.main.whiteBg};
  border: 1px solid
    ${({ borderColor, theme, error }) =>
      error
        ? theme.input.borderColor.error
        : borderColor
        ? borderColor
        : theme.input.borderColor.default};
  border-radius: ${({ theme }) => theme.input.borderRadius};
  padding: 10px;
  color: ${({ textColor, theme }) => textColor || theme.input.textColor};
  font-size: ${({ fontSize, theme }) => fontSize || theme.input.fontSize};
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.input.placeholder.color};
  }
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.input.placeholder.color};
  }
  &:hover {
    border-color: ${({ hoverBorderColor, theme, error }) =>
      !error && (hoverBorderColor || theme.input.borderColor.hover)};
    box-shadow: ${({ theme, error }) => !error && theme.input.focusShadow};
  }
  &:focus {
    outline: none;
    border-color: ${({ focusBorderColor, theme, error }) =>
      !error && (focusBorderColor || theme.input.borderColor.focus)};
    box-shadow: ${({ theme, error }) => !error && theme.input.focusShadow};
  }
`;
const ModalButtonSection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  grid-column-gap: 12px;
  margin-top: 20px;
`;

export const getHoursAndMinutes = (mins) => {
  const hours = Math.floor(mins / 60);
  const minutes = mins % 60;
  return hours <= 0
    ? minutes === 1
      ? `${minutes} minute`
      : `${minutes} minutes`
    : hours === 1
    ? `${hours} hour ${minutes} minutes`
    : `${hours} hours ${minutes} minutes`;
};

const ManualTimeModal = (props) => {
  const [memberOptions, setMemberOptions] = useState("");
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [comment, setComment] = useState("");

  const [saveErrors, setSaveErrors] = useState({});

  const {
    isOpen,
    toggle,
    isLoading,

    selectedOrganization,
    getOrganizationMembersList,
    organizationMembersList,
    getProjectAssigneeList,
    projectAssigneeList,
    manualTimeAdded,

    filterSelectedDate,
    filterSelectedMember,

    handleAddTimesheet,

    //jobShortList
    getjobsShortList,
    isLoadingJobs,
    jobShortList,
  } = props;

  

  useEffect(() => {
    if (isOpen) {
      if (selectedOrganization && selectedOrganization.id) {
        const payload = { organization_id: selectedOrganization.id };
        getOrganizationMembersList(payload);
        // payload.type= 'scheduled'
        // getjobsShortList(payload);
      }
      if (filterSelectedMember && filterSelectedMember.id) {
        selectMember(filterSelectedMember);
      } else if (organizationMembersList && organizationMembersList.length > 0) {
        const userId = localStorage.getItem("user_id");
        organizationMembersList.some((member) => {
          if (member.id === userId) {
            selectMember(member);
            return true;
          }
          return false;
        });
      }
    }
  }, [isOpen]);

  // useEffect(() => {
  //   if (selectedOrganization && selectedOrganization.id) {
  //     if (selectedOrganization.timezone) {
  //       setSelectedTimezone({
  //         type: "Organization",
  //         timezone: selectedOrganization.timezone,
  //         offset: selectedOrganization.timezoneoffset,
  //       });
  //     }
  //     // let payload = {
  //     //   organization_id: selectedOrganization.id,
  //     // };
  //     // props.getOrganizationMembersList(payload);
  //   }
  // }, [selectedOrganization]);

  // useEffect(() => {
  //   getMemberOptions();
  // }, [organizationMembersList]);

  useEffect(() => {
    if (organizationMembersList && organizationMembersList.length > 0) {
      let list = [];
      organizationMembersList.map((assignee) => {
        list.push({
          label: assignee.name,
          value: assignee.id,
          avatar: assignee.avatar,
        });
      });
      setMemberOptions(list);
    } else if (organizationMembersList && organizationMembersList.length === 0) {
      setMemberOptions([]);
    }
  }, [organizationMembersList]);

  useEffect(() => {
    if (manualTimeAdded) {
      clearStates();
    }
  }, [manualTimeAdded]);

  useEffect(() => {
    if (filterSelectedDate) {
      setSelectedDate(filterSelectedDate);
    }
  }, [filterSelectedDate]);

  // const getMemberOptions = () => {
  //   if (organizationMembersList && organizationMembersList.length > 0) {
  //     const userId = localStorage.getItem("user_id");
  //     let list = [];
  //     organizationMembersList.map((member) => {
  //       list.push({
  //         label: member.name,
  //         value: member.id,
  //         avatar: member.avatar,
  //         timezone: member.timezone,
  //       });
  //     });
  //     if (
  //       selectedOrganization &&
  //       selectedOrganization.role &&
  //       !(
  //         selectedOrganization.role === "admin" ||
  //         selectedOrganization.role === "owner"
  //       )
  //     ) {
  //       if (userId) {
  //         list = list.filter((item) => item.value === userId);
  //       }
  //     }
  //     setMemberOptions(list);
  //   } else if (organizationMembersList && organizationMembersList.length === 0) {
  //     setMemberOptions([]);
  //   }
  // };

  // const selectProject = (project) => {
  //   const { selectedOrganization } = props;
  //   if (selectedOrganization) {
  //     const payload = {
  //       organization_id: selectedOrganization.id,
  //       project_id: project.value,
  //     };
  //     if (!selectedMember || (selectedMember && !selectedMember.id)) {
  //       getProjectAssigneeList(payload);
  //     }
  //   }
  //   setSelectedProject(project);
  //   setSelectedJob(null);
  // };
  const selectJob = (job) => {
    setSelectedJob(job);
  };
  const selectMember = (member) => {

    if (member && member.id) {
      setSelectedMember(member);
    } else if (member && member.value) {
      setSelectedMember({
        id: member.value,
        name: member.label,
        avatar: member.avatar,
        timezone: member.timezone,
      });
    } else {
      setSelectedMember(null);
    }
    setSelectedJob(null);

    if (
      member.timezone &&
      selectedTimezone &&
      selectedTimezone.type === "Member"
    ) {
      setSelectedTimezone({
        type: "Member",
        timezone: member.timezone.tz,
        offset: member.timezone.offset,
      });
    }
  };

  const onTimezoneSelect = (timezone) => {
    setSelectedTimezone(timezone);
  };

  const handleStartTime = (time) => {
    if (time) {
      const start_time = time.replace(/ /g, "");
      setStartTime(moment(start_time, "h:mmA").format("HH:mm:ss"));
    } else {
      setStartTime("");
    }
  };
  const handleEndTime = (time) => {
    if (time) {
      const end_time = time.replace(/ /g, "");
      setEndTime(moment(end_time, "h:mmA").format("HH:mm:ss"));
    } else {
      setEndTime("");
    }
  };

  const onDateSelect = (date) => {
    setSelectedDate(moment(date).format("YYYY-MM-DD"));
  };
  const handleAddTime = () => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      checkCommentError()
    ) {
      let start = `${selectedDate}T${startTime}`;
      let end = `${selectedDate}T${endTime}`;
      // if (
      //   selectedTimezone &&
      //   selectedTimezone.type === "Member" &&
      //   selectedTimezone.timezone &&
      //   selectedOrganization.timezone
      // ) {
      //   start = moment
      //     .tz(start, selectedTimezone.timezone)
      //     .tz(selectedOrganization.timezone)
      //     .format("YYYY-MM-DDTHH:mm:ss");
      //   end = moment
      //     .tz(end, selectedTimezone.timezone)
      //     .tz(selectedOrganization.timezone)
      //     .format("YYYY-MM-DDTHH:mm:ss");
      // }
      let payload = {
        organization_id: selectedOrganization.id,
        // project: selectedProject.value,
        start_time: start,
        end_time: end,
        comment: comment,
      };
      if (selectedMember && selectedMember.id) {
        payload.user_id = selectedMember.id;
      }
      if (selectedJob && selectedJob.value) {
        payload.job_id = selectedJob.value;
        payload.work_order_id = selectedJob.workorder_id; 
      }
      // console.log("payload ", payload);
      handleAddTimesheet(payload);
    }
  };

  const checkCommentError = () => {
    let formIsValid = true;
    const saveErrors = {};
    if (checkUrl(comment)) {
      formIsValid = false;
      saveErrors["comment"] = "Comment can not contain url.";
    } else if (checkHtml(comment)) {
      formIsValid = false;
      saveErrors["comment"] = "Invalid input in comment.";
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const checkError = () => {
    let formIsValid = true;
    if (!selectedMember || (selectedMember && !selectedMember.id)) {
      formIsValid = false;
    }  else if (!selectedDate) {
      formIsValid = false;
    } else if (!startTime || !endTime) {
      formIsValid = false;
    } else if (!comment.trim()) {
      formIsValid = false;
    }
    return formIsValid;
  };

  const toggleModal = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
      };
      getOrganizationMembersList(payload);
    }
    clearStates();
    toggle();
  };

  const clearStates = () => {
    setSelectedProject(null);
    setSelectedJob(null);
    setSelectedDate(filterSelectedDate || moment().format("YYYY-MM-DD"));
    setStartTime("");
    setEndTime("");
    setComment("");
    // setTaskOptions([]);

    setSaveErrors({});
    if (selectedOrganization && selectedOrganization.timezone) {
      setSelectedTimezone({
        type: "Organization",
        timezone: selectedOrganization.timezone,
        offset: selectedOrganization.timezoneoffset,
      });
    }
  };

  return (
    <CustomModal isOpen={isOpen} toggle={toggleModal}>
      <ManualTimeModalBody>
        <CardTitle>Add Manual Time</CardTitle>
        <ModalInputSection gap="8px">
          <InputLabel>
            People <RedStar>*</RedStar>
          </InputLabel>

          <Select
            isSearchable
            placeholder="Select People"
            value={
              selectedMember && selectedMember.id
                ? {
                    value: selectedMember.id,
                    label: selectedMember.name,
                  }
                : null
            }
            options={memberOptions}
            onChange={(e) => {
              selectMember(e);
            }}
            styles={ModalDropdownStyle()}
          />
        </ModalInputSection>
        {/* <ModalInputSection gap="8px">
          <InputLabel>
            Project <RedStar>*</RedStar>
          </InputLabel>

          <ProjectDropdown
            selectedOrganization={selectedOrganization}
            selectedProject={selectedProject}
            selectProject={selectProject}
            selectedMember={selectedMember}
            style={ModalDropdownStyle()}
          />
        </ModalInputSection> */}
        <ModalInputSection gap="8px">
          <InputLabel>Job</InputLabel>
          <JobDropdown
            selectedOrganization={selectedOrganization}
            selectedJob={selectedJob}
            selectJob={selectJob}
            selectedMember={selectedMember}
            style={ModalDropdownStyle()}
          />
        </ModalInputSection>
        {/* <ModalInputSection gap="8px">
          <InputLabel>Timezone</InputLabel>

          <TimezoneDropdown
            selectedOrganization={selectedOrganization}
            selectedMember={selectedMember}
            selectTimezone={onTimezoneSelect}
            selectedTimezone={selectedTimezone}
          />
        </ModalInputSection> */}
        <DateTimeContainer>
          <ModalInputSection gap="8px">
            <InputLabel>
              Select Date <RedStar>*</RedStar>
            </InputLabel>
            <SingleDatePicker
              dateDisplayFormat="DD/MM/YYYY"
              placeholder="Select Date"
              date={selectedDate ? moment(selectedDate) : null}
              onDateChange={onDateSelect}
              allowPreviousDates
              disableFutureDates
            />
          </ModalInputSection>
          <ModalInputSection gap="8px">
            <InputLabel>
              Time Range <RedStar>*</RedStar>
            </InputLabel>

            <TimeRangeComponent
              setStartTime={handleStartTime}
              setEndTime={handleEndTime}
            />
          </ModalInputSection>
        </DateTimeContainer>
        {startTime && endTime && (
          <DateTimeContainer>
            <ModalInputSection gap="8px"></ModalInputSection>
            {moment(endTime, "HH:mm:ss").isAfter(
              moment(startTime, "HH:mm:ss")
            ) ? (
              <ColoredText type="blue" fontSize="13px" margin="8px 0 0 5px">
                {getHoursAndMinutes(
                  moment(endTime, "HH:mm:ss").diff(
                    moment(startTime, "HH:mm:ss"),
                    "minutes"
                  )
                )}
              </ColoredText>
            ) : (
              <ColoredText type="error" fontSize="11.5px" margin="8px 0 0 5px">
                End time must be after start time
              </ColoredText>
            )}
          </DateTimeContainer>
        )}

        <ModalInputSection gap="8px">
          <InputLabel>
            Why do you want to? <RedStar>*</RedStar>
          </InputLabel>

          <CommentTextArea
            id="comment"
            placeholder="Type your reason here..."
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
              setSaveErrors({});
            }}
            error={
              saveErrors &&
              saveErrors["comment"] &&
              saveErrors["comment"] !== undefined
            }
          />
          {saveErrors &&
            saveErrors["comment"] &&
            saveErrors["comment"] !== undefined && (
              <FormAlert margin="0 0 0 5px">{saveErrors["comment"]}</FormAlert>
            )}
        </ModalInputSection>
        <ModalButtonSection>
          <WhiteButton type="cancel" onClick={toggleModal}>
            Cancel
          </WhiteButton>
          <PrimaryButton
            padding={isLoading ? "0 15px" : "0 35px"}
            disabled={!checkError()}
            onClick={() => handleAddTime()}
          >
            {isLoading ? (
              <ButtonTextLoader loadingText="Saving" fontSize="13px" />
            ) : (
              "Save"
            )}
          </PrimaryButton>
        </ModalButtonSection>
      </ManualTimeModalBody>
    </CustomModal>
  );
};

export default ManualTimeModal;
