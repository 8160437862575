import { connect } from 'react-redux';

import {
  addClientLocation,
  clearClientLocationsList,
  getClientList,
  getClientLocation,
  getClientShortList,
  updateClientLocation,
} from '../../ClientsNew/clientsActions';
import { getOfficesShortList } from '../../Offices/officesActions';
import {
  getOrganizationMembersList,
  getOrganizationMembersShortList,
} from '../../Projects/projectsActions';
import {
  createJobRequest,
  getWorkOrderShortList,
  updateJobRequest,
} from '../../WorkOrderNew/workOrderActions';
import {
  addJobAssignee,
  deleteJob,
  getJobDetails,
  removeJobAssignee,
} from '../jobsActions';
import {
  addTaskLocation,
  createTask,
  getTaskLocation,
  getTaskType,
} from '../tasksActions';
import EditJob from './EditJob';

// Store props to map with current state
const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,
  orgMembersList: state.project.orgMembersList,
  orgMembersShortList: state.project.orgMembersShortList,
  officesShortList: state.office.officesShortList,
  memberShortListIsLoading: state.project.memberShortListIsLoading,
  isLoading: state.task.isLoading,
  addTaskLocationLoading: state.task.addTaskLocationLoading,
  createdTaskInfo: state.task.createdTaskInfo,
  locationsList: state.task.locationsList,
  createdTaskLocationInfo: state.task.createdTaskLocationInfo,
  taskTypeIsLoading: state.task.taskTypeIsLoading,
  taskTypeList: state.task.taskTypeList,
  //workordershort
  workOrderShortList: state.workorder.workOrderShortList,
  //Customer Location
  clientLocationList: state.client.clientLocationList,
  getclientLocalMapLoading: state.client.getclientLocalMapLoading,
  addOrUpdateclientLocalMapLoading:
    state.client.addOrUpdateclientLocalMapLoading,
  createdClientLocationInfo: state.client.createdClientLocationInfo,
  //job
  createdJob: state.workorder.createdJob,
  createOrUpdateJobIsLoading: state.workorder.createOrUpdateJobIsLoading,

  // job Details
  getJobIsLoading: state.jobs.getJobIsLoading,
  jobDetails: state.jobs.jobDetails,
  //assignees
  JobMemberIsLoading: state.jobs.JobMemberIsLoading,
  loadingjobId: state.jobs.loadingjobId,
  jobAssigneeUpdate: state.jobs.jobAssigneeUpdate,
  //Delete Job
  deletingJobId: state.jobs.deletingJobId,
  jobDeleteLoading: state.jobs.jobDeleteLoading,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getOrganizationMembersList: params =>
    dispatch(getOrganizationMembersList(params)),
  getOrganizationMembersShortList: params =>
    dispatch(getOrganizationMembersShortList(params)),
  createTask: params => dispatch(createTask(params)),
  getTaskLocation: params => dispatch(getTaskLocation(params)),
  addTaskLocation: params => dispatch(addTaskLocation(params)),
  getOfficesShortList: params => dispatch(getOfficesShortList(params)),
  getTaskType: params => dispatch(getTaskType(params)),
  getWorkOrderShortList: params => dispatch(getWorkOrderShortList(params)),
  getClientLocation: details => dispatch(getClientLocation({ ...details })),
  addClientLocation: details => dispatch(addClientLocation({ ...details })),
  updateClientLocation: details =>
    dispatch(updateClientLocation({ ...details })),
  createJobRequest: details => dispatch(createJobRequest({ ...details })),
  getJobDetails: details => dispatch(getJobDetails({ ...details })),
  updateJobRequest: details => dispatch(updateJobRequest({ ...details })),
  addJobAssignee: details => dispatch(addJobAssignee({ ...details })),
  removeJobAssignee: details => dispatch(removeJobAssignee({ ...details })),
  deleteJob: details => dispatch(deleteJob({ ...details })),
  clearClientLocationsList: () => dispatch(clearClientLocationsList()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(EditJob);
