import { connect } from 'react-redux';

import { getOfficesShortList } from '../Offices/officesActions';
import { getOrganizationMembersList } from '../Projects/projectsActions';
import { getOrganizationMembersShortList } from '../Projects/projectsActions';
import { updateMemberwiseTimesheetApprovalSettings } from '../Settings/TimesheetSettings/timesheetSettingsActions';
import Members from './Members';
// Actions
import {
  clearMemberInvited,
  deleteMemberInvitation,
  getGHLCustomerList,
  getInvitedMembersList,
  getMembersList,
  inviteMember,
  inviteMemberBulk,
  removeMember,
  updateMember,
} from './membersActions';

// Store props to map with current state
const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,
  orgMembersShortList: state.project.orgMembersShortList,
  memberShortListIsLoading: state.project.memberShortListIsLoading,
  orgMembersList: state.project.orgMembersList,
  isLoading: state.member.isLoading,
  deleteInvitationLoading: state.member.deleteInvitationLoading,
  deleteMemberLoading: state.member.deleteMemberLoading,
  membersList: state.member.members,
  updatedData: state.member.updatedData,
  invitedMembersList: state.member.invitedMembersList,
  officesShortList: state.office.officesShortList,
  //Member Invitation
  inviteMemberLoading: state.member.inviteMemberLoading,
  memberInvited: state.member.memberInvited,
  //ghl employee data
  ghlSyncMemberIsLoading: state.member.ghlSyncMemberIsLoading,
  ghlEmployeeList: state.member.ghlEmployeeList,
  // pagination
  totalMembersCount: state.member.totalMembersCount,
  pageSize: state.member.pageSize,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getOrganizationMembersShortList: params =>
    dispatch(getOrganizationMembersShortList(params)),
  getOrganizationMembersList: payload =>
    dispatch(getOrganizationMembersList(payload)),
  getMembersList: details => dispatch(getMembersList({ ...details })),
  getGHLCustomerList: payload => dispatch(getGHLCustomerList({ ...payload })),
  getInvitedMembersList: details =>
    dispatch(getInvitedMembersList({ ...details })),
  inviteMember: details => dispatch(inviteMember({ ...details })),
  inviteMemberBulk: details => dispatch(inviteMemberBulk({ ...details })),
  removeMember: details => dispatch(removeMember({ ...details })),
  getOfficesShortList: details => dispatch(getOfficesShortList({ ...details })),
  deleteMemberInvitation: details =>
    dispatch(deleteMemberInvitation({ ...details })),

  // settings
  updateMemberwiseTimesheetApprovalSettings: details =>
    dispatch(updateMemberwiseTimesheetApprovalSettings({ ...details })),
  updateMember: details => dispatch(updateMember({ ...details })),
  clearMemberInvited: () => dispatch(clearMemberInvited()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Members);
