import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LeftArrow_3 from '../../../assets/img/icons/LeftArrow_3.svg';
import RightArrow_3 from '../../../assets/img/icons/RightArrow_3.svg';
import AddSingleMemberDropdown from '../../../components/CustomDropdown/AddSingleMemberDropdown';
import CreateProjectDropdown from '../../../components/DropdownStyle/CreateProjectDropdown';
import Select from '../../../components/ReactSelectDropdown';
import { filterOption } from '../../../components/ReactSelectDropdown/ReactSelectDropdown';
import SearchWithButton from '../../../components/Search/SearchWithButton';
import DatePicker from '../../../components/SingleDatePicker/SingleDatePicker';
import { TabButton } from '../../../styledComponents/buttons';
import { FilterButton } from '../../../styledComponents/buttons';
import {
  CommonFlex,
  FilterLabel,
  IconArrow,
  InputWithLabelSection,
} from '../../../styledComponents/common';
import { TaskFilterContainer } from '../../../styledComponents/taskApproval';
import {
  TabButtonContainer,
  TabSearchGrid,
} from '../../../styledComponents/taskApproval';

const TaskFilter = props => {
  const { t } = useTranslation();
  const [officeOptions, setOfficeOptions] = useState([]);
  const [workOrderOptions, setWorkOrderOptions] = useState([]);

  const {
    selectedOrganization,
    getOrganizationMembersList,
    organizationMembersList,
    getOfficesShortList,
    officesShortList,
    selectedOffice,
    selectOffice,
    // workorder
    getWorkOrderShortList,
    workOrderShortList,
    selectedWorkOrder,
    selectWorkOrder,
    //
    selectedMember,
    clearMember,
    selectedDate,
    handleDateChange,
    handleSearchByName,
    handleClearSearch,
    searchTerm,
    onSearchTermChange,
    handleSelectedTab,
    selectedTab,
    singleDateChange,
    selectToday,
    isToday,
  } = props;

  

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      getWorkOrderShortList({ organization_id: selectedOrganization.id });
    }
  }, [selectedOrganization]);

  // useEffect(() => {
  //   // getOfficesShortList();
  //   console.log('call from filter useEffect');
  //   getOrganizationMembersList({ office_id: null });
  // }, []);

  // useEffect(() => {
  //   if (officesShortList && officesShortList.length === 1) {
  //     getOrganizationMembersList({ office_id: officesShortList[0].id });
  //   }
  //   if (officesShortList && officesShortList.length > 1) {
  //     let newArr = [
  //       {
  //         label: t('all_offices'),
  //         value: null,
  //       },
  //     ];
  //     officesShortList.forEach((office, i) => {
  //       newArr.push({
  //         label: office.name,
  //         value: office.id,
  //       });
  //     });
  //     setOfficeOptions(newArr);
  //   }
  // }, [officesShortList]);

  useEffect(() => {
    if (workOrderShortList && workOrderShortList.length > 0) {
      let newArr = [
        {
          label: t('all_workorders'),
          value: null,
        },
      ];
      workOrderShortList.forEach((workorder, i) => {
        newArr.push({
          label: workorder.name,
          value: workorder.id,
        });
      });
      setWorkOrderOptions(newArr);
    }
  }, [workOrderShortList]);

  // useEffect(() => {
  //   if (
  //     selectedOffice &&
  //     selectedOffice.value &&
  //     officesShortList &&
  //     officesShortList.length > 1
  //   ) {
  //     getOrganizationMembersList({ office_id: selectedOffice.value });
  //     console.log(selectedOffice);
  //   }
  // }, [selectedOffice]);

  const onMemberSelect = person => {};

  return (
    <TaskFilterContainer columns="auto minmax(210px, 240px) minmax(220px, 240px) minmax(344px, 354px)">
      <TabSearchGrid>
        <InputWithLabelSection>
          <FilterLabel>{t('type')}</FilterLabel>
          <TabButtonContainer columns="auto auto auto">
            <TabButton
              selected={selectedTab}
              index={'submitted'}
              onClick={() => handleSelectedTab('submitted')}
            >
              {t('submitted')}
            </TabButton>
            <TabButton
              selected={selectedTab}
              index={'approved'}
              onClick={() => handleSelectedTab('approved')}
            >
              {t('approved')}
            </TabButton>
            <TabButton
              selected={selectedTab}
              index={'rejected'}
              onClick={() => handleSelectedTab('rejected')}
            >
              {t('rejected')}
            </TabButton>
          </TabButtonContainer>
        </InputWithLabelSection>
      </TabSearchGrid>
      <InputWithLabelSection>
        <FilterLabel>{t('work_order')}</FilterLabel>
        <Select
          isSearchable
          value={selectedWorkOrder}
          options={workOrderOptions}
          placeholder="Select WorkOrder"
          onChange={e => {
            selectWorkOrder(e);
          }}
          styles={CreateProjectDropdown()}
          filterOption={filterOption}
        />
      </InputWithLabelSection>
      {/* <InputWithLabelSection>
        <FilterLabel>{t("assignee")}</FilterLabel>
        <div style={{ width: `40px` }}>
          <AddSingleMemberDropdown
            assigneeManage={selectedMember}
            updateState={onMemberSelect}
            membersList={organizationMembersList}
            clearAssignee={() => clearMember()}
          />
        </div>
      </InputWithLabelSection> */}
      <SearchWithButton
        searchTermName={t('job_name')}
        searchInput={searchTerm}
        onInputChange={onSearchTermChange}
        handleSearch={handleSearchByName}
        handleClearSearch={handleClearSearch}
      />
      <InputWithLabelSection>
        <FilterLabel>{t('date')}</FilterLabel>
        <CommonFlex gap="2px">
          <DatePicker
            id="clockInOutDate"
            date={selectedDate}
            dateDisplayFormat="DD/MM/YYYY"
            onDateChange={handleDateChange}
            placeholder="Select date..."
            disableFutureDates
          />
          <CommonFlex gap="0px" margin="1.5px 0 0 0">
            <IconArrow
              onClick={() => {
                singleDateChange('left');
              }}
              padding="5px"
              size="40px"
              src={LeftArrow_3}
            />
            <IconArrow
              margin="0 0 0 2px"
              onClick={() => {
                if (!isToday) {
                  singleDateChange('right');
                }
              }}
              padding="5px"
              size="40px"
              src={RightArrow_3}
              disabled={isToday}
            />
          </CommonFlex>
          <div style={{ margin: '0 0 0 14px' }}>
            <FilterButton
              type="gray"
              padding="10px"
              onClick={() => selectToday()}
            >
              Today
            </FilterButton>
          </div>
        </CommonFlex>
      </InputWithLabelSection>
    </TaskFilterContainer>
  );
};

export default TaskFilter;
