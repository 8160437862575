import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import evidence from "../../../assets/img/screenshot_1.svg";
import BottomPagination from "../../../components/Pagination/BottomPagination/BottomPagination";
import TopPagination from "../../../components/Pagination/TopPagination/TopPagination";
import { HeaderContainer, PageTitle } from "../../../styledComponents/common";
import ApprovalTaskList from "./ApprovalTaskList";
import EvidenceModal from "./EvidenceModal";
import TaskFilter from "./TaskFilter";

const JobApproval = (props) => {
  const { t, i18n } = useTranslation();

  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState("");
  const [selectedDate, setSelectedDate] = useState(moment(new Date()));
  const isToday = moment(selectedDate).isSame(moment(), 'day');
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [selectedTab, setSelectedTab] = useState("submitted");

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState("");
  const [expandedTaskId, setExpandedTaskId] = useState(null);
  const [evidenceImage, setEvidenceImage] = useState(null);

  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);

  const {
    selectedOrganization,
    getOrganizationMembersList,
    orgMembersList,
    loadingTaskAssigneeId,
    isLoading,
    officesShortList,
    getOfficesShortList,
    getTasksList,
    updateTaskApproval,
    createTask,
    totalTasksCount,
    pageSize,
    history,
    //jobs
    getjobsList,
    jobList,
    isLoadingJobs,
    totaljobsCount,
    pageSizeJob,
    //workorder short list
    getWorkOrderShortList,
    workOrderShortList,
    //
    updateJobApproval,
  } = props;

  // console.log("job list pending ", jobList);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      getjobsList({
        organization_id: selectedOrganization.id,
        paginate: true,
        start_date: selectedDate.format("YYYY-MM-DD"),
        end_date: selectedDate.format("YYYY-MM-DD"),
        approval_status: "pending",
      });
    }
  }, [selectedOrganization]);

  useEffect(() => {
    setSelectedOffice({
      label: t("All WorkOrders"),
      value: null,
    });
    // getTasksList({
    //   date: selectedDate.format("YYYY-MM-DD"),
    //   approval_status: "pending",
    // });
  }, []);

  const handleSearchByName = () => {
    const payload = {
      start_date: selectedDate.format("YYYY-MM-DD"),
      end_date: selectedDate.format("YYYY-MM-DD"),
      page: 1,
      approval_status: selectedTab === "submitted" ? "pending" : selectedTab,
    };
    if (searchTerm && searchTerm.trim()) {
      payload.job_name = searchTerm.toLowerCase().trim();
      setCurrentSearchedTerm(searchTerm);
    } else {
      setCurrentSearchedTerm("");
    }
    if (selectedWorkOrder && selectedWorkOrder.value) {
      payload.work_order_id = selectedWorkOrder.value;
    }
    if (selectedOrganization && selectedOrganization.id) {
      payload.organization_id = selectedOrganization.id;
      getjobsList(payload);
    }
    setCurrentPageNumber(1);
  };

  const handleClearSearch = () => {
    if (currentSearchedTerm) {
      const payload = {
        start_date: selectedDate.format("YYYY-MM-DD"),
        end_date: selectedDate.format("YYYY-MM-DD"),
        page: 1,
        approval_status: selectedTab === "submitted" ? "pending" : selectedTab,
      };
      if (selectedWorkOrder && selectedWorkOrder.value) {
        payload.work_order_id = selectedWorkOrder.value;
      }

      if (selectedOrganization && selectedOrganization.id) {
        payload.organization_id = selectedOrganization.id;
        getjobsList(payload);
      }
      setCurrentPageNumber(1);
    }
    setTimeout(() => {
      setSearchTerm("");
      setCurrentSearchedTerm("");
    }, 100);
  };

  const handlePageChange = (page) => {
    const payload = {
      organization_id:
        selectedOrganization && selectedOrganization.id
          ? selectedOrganization.id
          : "",
      start_date: selectedDate.format("YYYY-MM-DD"),
      end_date: selectedDate.format("YYYY-MM-DD"),
      page,
      approval_status: selectedTab === "submitted" ? "pending" : selectedTab,
    };
    if (selectedWorkOrder && selectedWorkOrder.value) {
      payload.work_order_id = selectedWorkOrder.value;
    }
    if (searchTerm && searchTerm.trim()) {
      payload.job_name = searchTerm.toLowerCase().trim();
    }

    getjobsList(payload);
    setCurrentPageNumber(page);
  };

  const selectOffice = (e) => {
    const payload = {
      start_date: selectedDate.format("YYYY-MM-DD"),
      end_date: selectedDate.format("YYYY-MM-DD"),
      page: 1,
      approval_status: selectedTab === "submitted" ? "pending" : selectedTab,
    };
    if (e && e.value) {
      payload.office_id = e.value;
    }
    if (selectedMember && selectedMember.id) {
      payload.assignee_id = selectedMember.id;
    }

    getTasksList(payload);
    setCurrentPageNumber(1);
    setSearchTerm("");
    setCurrentSearchedTerm("");
    setSelectedOffice(e);
  };

  const selectWorkOrder = (e) => {
    const payload = {
      start_date: selectedDate.format("YYYY-MM-DD"),
      end_date: selectedDate.format("YYYY-MM-DD"),
      page: 1,
      approval_status: selectedTab === "submitted" ? "pending" : selectedTab,
    };
    if (e && e.value) {
      payload.work_order_id = e.value;
    }
    // if (searchTerm && searchTerm.trim()) {
    //   payload.job_name = searchTerm.toLowerCase().trim();
    // }

    if (selectedOrganization && selectedOrganization.id) {
      payload.organization_id = selectedOrganization.id;
      getjobsList(payload);
    }

    setCurrentPageNumber(1);
    setSearchTerm("");
    setCurrentSearchedTerm("");
    setSelectedWorkOrder(e);
  };

  const selectMember = (person) => {
    if (person.id) {
      const payload = {
        start_date: selectedDate.format("YYYY-MM-DD"),
        end_date: selectedDate.format("YYYY-MM-DD"),
        assignee_id: person.id,
        page: 1,
        approval_status: selectedTab === "submitted" ? "pending" : selectedTab,
      };
      if (selectedOffice && selectedOffice.value) {
        payload.office_id = selectedOffice.value;
      }

      // getTasksList(payload);
      setSelectedMember(person);
      setSearchTerm("");
      setCurrentSearchedTerm("");
      setCurrentPageNumber(1);
    }
  };

  const clearMember = () => {
    const payload = {
      start_date: selectedDate.format("YYYY-MM-DD"),
      end_date: selectedDate.format("YYYY-MM-DD"),
      page: 1,
      approval_status: selectedTab === "submitted" ? "pending" : selectedTab,
    };
    if (selectedOffice && selectedOffice.value) {
      payload.office_id = selectedOffice.value;
    }

    // getTasksList(payload);
    setSearchTerm("");
    setCurrentSearchedTerm("");
    setCurrentPageNumber(1);
    setSelectedMember("");
  };

  const onSearchTermChange = (e) => {
    const { value } = e.target;
    if (value !== "") {
      setSelectedMember("");
    }
    setSearchTerm(value);
  };
  const handleDateChange = (date) => {
    if (date) {
      const payload = {
        start_date: moment(date).format("YYYY-MM-DD"),
        end_date: moment(date).format("YYYY-MM-DD"),
        page: 1,
        approval_status: selectedTab === "submitted" ? "pending" : selectedTab,
      };
      if (selectedWorkOrder && selectedWorkOrder.value) {
        payload.work_order_id = selectedWorkOrder.value;
      }
      if (searchTerm && searchTerm.trim()) {
        payload.job_name = searchTerm.toLowerCase().trim();
        setCurrentSearchedTerm(searchTerm);
      } else {
        setCurrentSearchedTerm("");
      }
      if (selectedOrganization && selectedOrganization.id) {
        payload.organization_id = selectedOrganization.id;
        getjobsList(payload);
      }
    }
    setCurrentPageNumber(1);
    setSelectedDate(date);
  };

  const singleDateChange = (direction) => {
    const newDate = direction === "right"
      ? moment(selectedDate).add(1, "days")
      : moment(selectedDate).subtract(1, "days");
      handleDateChange(newDate);
  };

  const selectToday = () =>{
    const date = moment();
    handleDateChange(date);
  }

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };

  const handleSelectedTab = (selectedTab) => {
    const payload = {
      start_date: selectedDate.format("YYYY-MM-DD"),
      end_date: selectedDate.format("YYYY-MM-DD"),
      page: 1,
      approval_status: selectedTab === "submitted" ? "pending" : selectedTab,
    };
    if (searchTerm && searchTerm.trim()) {
      payload.job_name = searchTerm.toLowerCase().trim();
      setCurrentSearchedTerm(searchTerm);
    } else {
      setCurrentSearchedTerm("");
    }
    if (selectedWorkOrder && selectedWorkOrder.value) {
      payload.work_order_id = selectedWorkOrder.value;
    }
    if (selectedOrganization && selectedOrganization.id) {
      payload.organization_id = selectedOrganization.id;
      getjobsList(payload);
    }
    setCurrentPageNumber(1);
    setSelectedTab(selectedTab);
  };

  const updateTaskStatusRequest = (
    task_id,
    approval,
    status,
    supervisorNote
  ) => {
    const details = {
      status,
    };
    if (supervisorNote.trim()) {
      details.supervisor_note = supervisorNote;
    }
    const payload = {
      job_id: task_id,
      approval_id: approval.id,
      details,
    };
    if (selectedOrganization && selectedOrganization.id) {
      payload.organization_id = selectedOrganization.id;
      updateJobApproval(payload);
    }
    setSearchTerm("");
    // setTimeout(() => {
    //   refreshTaskComponent();
    // }, 350);
  };

  // const refreshTaskComponent = () => {
  //   const payload = {
  //     date: selectedDate.format("YYYY-MM-DD"),
  //     page: 1,
  //     approval_status: selectedTab === "submitted" ? "pending" : selectedTab,
  //   };
  //   if (searchTerm && searchTerm.trim()) {
  //     payload.task_name = searchTerm.toLowerCase().trim();
  //     setCurrentSearchedTerm(searchTerm);
  //   } else {
  //     setCurrentSearchedTerm("");
  //   }
  //   if (selectedOffice && selectedOffice.value) {
  //     payload.office_id = selectedOffice.value;
  //   }
  //   if (selectedMember && selectedMember.id) {
  //     payload.assignee_id = selectedMember.id;
  //   }

  //   getTasksList(payload);
  //   setCurrentPageNumber(1);
  // };

  return (
    <div className="content">
      <PageTitle>{t("submitted_jobs_for_apprroval")}</PageTitle>
      <HeaderContainer>
        <TaskFilter
          selectedOrganization={selectedOrganization}
          getOrganizationMembersList={getOrganizationMembersList}
          organizationMembersList={orgMembersList}
          //
          officesShortList={officesShortList}
          getOfficesShortList={getOfficesShortList}
          selectedOffice={selectedOffice}
          selectOffice={selectOffice}
          //workorder
          getWorkOrderShortList={getWorkOrderShortList}
          workOrderShortList={workOrderShortList}
          selectedWorkOrder={selectedWorkOrder}
          selectWorkOrder={selectWorkOrder}
          //
          selectedMember={selectedMember}
          selectMember={selectMember}
          clearMember={clearMember}
          searchTerm={searchTerm}
          onSearchTermChange={onSearchTermChange}
          selectedDate={selectedDate}
          handleDateChange={handleDateChange}
          handleSearchByName={handleSearchByName}
          handleClearSearch={handleClearSearch}
          handleSelectedTab={handleSelectedTab}
          selectedTab={selectedTab}
          selectToday={selectToday}
          singleDateChange={singleDateChange}
          isToday={isToday}
        />
      </HeaderContainer>
      {/* handle page change need to be changed */}
      {totaljobsCount && totaljobsCount > 0 && pageSizeJob ? (
        <TopPagination
          itemName="jobs"
          totalCount={totaljobsCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={pageSizeJob}
        />
      ) : null}
      <ApprovalTaskList
        selectedOrganization={selectedOrganization}
        loadingTaskAssigneeId={loadingTaskAssigneeId}
        expandedTaskId={expandedTaskId}
        setExpandedTaskId={setExpandedTaskId}
        history={history}
        jobList={jobList}
        isLoading={isLoadingJobs}
        createTask={createTask}
        popupOpen={popupOpen}
        setPopupOpen={setPopupOpen}
        handlePageChange={handlePageChange}
        updateTaskStatusRequest={updateTaskStatusRequest}
        selectedTab={selectedTab}
      />
      {totaljobsCount && totaljobsCount > 0 && pageSizeJob ? (
        <BottomPagination
          totalCount={totaljobsCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={pageSizeJob}
        />
      ) : null}
    </div>
  );
};

export default JobApproval;
