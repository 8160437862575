import moment from 'moment';
import { useEffect, useState } from 'react';

import avatar5 from '../../../assets/img/avatar_5.svg';
import NoDataComponent from '../../../components/NoDataComponent/NoDataComponent';
import {
  CenteredTooltip,
  SingleAssigneeRoundImage,
} from '../../../styledComponents/common';
import {
  SingleItemTime,
  SingleItemTotal,
  SingleItemUserAvatar,
  SingleItemUserDiv,
  SingleItemUserName,
  TableBodySingleItemContainer,
  TableHeadDate,
  TableHeadDateContainer,
  TableHeadDayText,
  TableHeadText,
  WeeklyDetailsTableBodyContainer,
  WeeklyDetailsTableContainer,
  WeeklyDetailsTableHeadContainer,
} from '../../../styledComponents/timesheet';
import { getHours, toHHMMSS } from '../../../utils/helper';

const getDates = date => {
  const dateList = [];
  let startDate = moment(date).startOf('isoWeek');
  while (moment(startDate).isSameOrBefore(moment(date).endOf('isoWeek'))) {
    dateList.push(startDate);
    startDate = moment(startDate).add(1, 'days');
  }
  return dateList;
};

const getTimes = date => {
  const times = [];
  let startDate = moment(date).startOf('isoWeek');
  while (moment(startDate).isSameOrBefore(moment(date).endOf('isoWeek'))) {
    times.push({
      date: startDate,
      hours: '0 h 00 m',
    });
    startDate = moment(startDate).add(1, 'days');
  }
  return times;
};

const SingleRow = props => {
  const { memberData, handleGetDetails, getHours, setDetailsUserData } = props;

  return (
    <TableBodySingleItemContainer>
      <SingleItemUserDiv>
        <SingleAssigneeRoundImage>
          <SingleItemUserAvatar src={memberData.avatar} alt="" />
          <CenteredTooltip left="calc(100% + 3px)" top="2px">
            {memberData.name}
          </CenteredTooltip>
        </SingleAssigneeRoundImage>

        {/* <SingleItemUserName>{memberData.name}</SingleItemUserName> */}
      </SingleItemUserDiv>
      {memberData.timesheet && memberData.timesheet.length > 0 && (
        <>
          {memberData.timesheet.map((timesheet, index) => (
            <SingleItemTime
              key={index}
              fontSize="13.5px"
              onClick={() => {
                if (timesheet.hours !== '0 h 00 m') {
                  handleGetDetails(timesheet.date, memberData.user_id);
                  setDetailsUserData({
                    id: memberData.user_id,
                    name: memberData.name,
                    avatar: memberData.avatar,
                    date: timesheet.date,
                    totalTime: timesheet.hours,
                  });
                }
              }}
              hasTime={timesheet.hours !== '0 h 00 m'}
            >
              {timesheet.hours === '0 h 00 m' ? '-' : timesheet.hours}
            </SingleItemTime>
          ))}
          <SingleItemTime>
            <SingleItemTotal fontSize="13.5px">
              {memberData.durations
                ? getHours(
                    toHHMMSS(
                      memberData.durations
                        .slice(1)
                        .reduce(
                          (prev, cur) => cur + prev,
                          memberData.durations[0],
                        ),
                    ),
                  )
                : '0 h 00 m'}
            </SingleItemTotal>
          </SingleItemTime>
        </>
      )}
    </TableBodySingleItemContainer>
  );
};

const WeeklyAdminView = props => {
  const [timesheetsData, setTimesheetsData] = useState([]);
  const [dates, setDates] = useState([]);

  const {
    selectedDate,
    membersList,
    timesheetsList,

    getTimesheetDetails,
    detailsUserData,
    setDetailsUserData,
  } = props;


  useEffect(() => {
    setDates(getDates(selectedDate));
  }, []);

  useEffect(() => {
    setDates(getDates(selectedDate));
  }, [selectedDate]);

  useEffect(() => {
    if (membersList && membersList.length > 0 && selectedDate) {
      const timesheets = [];
      membersList.forEach(member => {
        const memberData = {
          user_id: member.id,
          name: member.name,
          avatar: member.avatar || avatar5,
        };
        const times = getTimes(selectedDate);
        if (
          timesheetsList &&
          timesheetsList !== {} &&
          timesheetsList.hasOwnProperty(member.id)
        ) {
          const durations = [];
          timesheetsList[member.id].forEach(timesheet => {
            durations.push(timesheet.duration);
            times.some(item => {
              if (moment(item.date).format('YYYY-MM-DD') === timesheet.date) {
                item.hours = getHours(toHHMMSS(timesheet.duration));
                return true;
              }
              return false;
            });
            if (
              detailsUserData &&
              detailsUserData.id === member.id &&
              detailsUserData.date &&
              moment(detailsUserData.date).format('YYYY-MM-DD') ===
                timesheet.date
            ) {
              setDetailsUserData({
                ...detailsUserData,
                totalTime: getHours(toHHMMSS(timesheet.duration)),
              });
            }
          });
          memberData.durations = durations;
          memberData.timesheet = [...times];
        } else {
          memberData.timesheet = times;
        }
        timesheets.push({ ...memberData });
      });
      setTimesheetsData([...timesheets]);
    } else if (membersList && membersList.length === 0) {
      // no data
      setTimesheetsData([]);
    }
  }, [timesheetsList, membersList]);

  return (
    <WeeklyDetailsTableContainer>
      {timesheetsData && timesheetsData.length > 0 ? (
        <>
          <WeeklyDetailsTableHeadContainer>
            <TableHeadDateContainer>
              <TableHeadText margin="0 0 0 0">Members</TableHeadText>
              <TableHeadText
                fontSize="16px"
                fontWeight="600"
                margin="5px 0 0 0"
                color="#3d4d69"
              >
                {timesheetsData.length}
              </TableHeadText>
            </TableHeadDateContainer>
            {dates &&
              dates.length > 0 &&
              dates.map((date, index) => (
                <TableHeadDateContainer key={index}>
                  <TableHeadDayText>{date.format('ddd')}</TableHeadDayText>
                  <TableHeadDate>{date.format('DD')}</TableHeadDate>
                </TableHeadDateContainer>
              ))}
            <TableHeadText>Total Hours</TableHeadText>
          </WeeklyDetailsTableHeadContainer>
          <WeeklyDetailsTableBodyContainer>
            {timesheetsData.map((member, index) => (
              <SingleRow
                key={index}
                memberData={member}
                getHours={getHours}
                handleGetDetails={getTimesheetDetails}
                setDetailsUserData={setDetailsUserData}
              />
            ))}
          </WeeklyDetailsTableBodyContainer>
        </>
      ) : timesheetsData && timesheetsData.length === 0 ? (
        <NoDataComponent title="There is no timesheet for the selected week" />
      ) : null}
    </WeeklyDetailsTableContainer>
  );
};

export default WeeklyAdminView;
