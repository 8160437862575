import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import styled from 'styled-components';

export const LoginText = styled.span`
  font-size: 16px;
  color: #33475b;
`;

export const LoginLoaderContainer = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.main.whiteBg};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ForgotPassword = styled(Link)`
  font-size: 15px;
  color: #43425d;
  text-decoration: none;
`;

export const LoginPageLink = styled.a`
  font-size: 15px;
  color: #33475b;
  text-decoration: none;
`;

export const EmailSentAlert = styled(Alert)`
  background: rgba(2, 146, 170, 0.1) !important;
  color: #33475b;
  height: 50px;
  font-size: 15px;
  margin-top: 15px;
  text-align: center;
`;
