import React from 'react';

import ComparisonCard from './ComparisonCard';
import JobsStatsCard from './JobsStatsCard';

const TotalMissedjobs = ({ current_week, previous_week }) => {
  return (
    <JobsStatsCard
      cardTitle="Missed Jobs"
      datasets={{ current_week: current_week, previous_week: previous_week }}
    />
  );
};

export default TotalMissedjobs;
