import {
  //new apis
  GET_DASHBOARD_STATUS,
  GET_DASHBOARD_TASK_PROGRESS,
  GET_DASHBOARD_PERFORMANCE,
  GET_DASHBOARD_LATE_COUNT,
  DASHBOARD_TOP_LOW_PERFORMERS,
  GET_DASHBOARD_USER_ANALYTICS,
  GET_DASHBOARD_JOBS_TODAY,
  GET_DASHBOARD_EMPLOYEE_STATUS,
  GET_DASHBOARD_TOP_WORKORDER,
  GET_DASHBOARD_JOB_STATUS,
  GET_DASHBOARD_PREVIOUS_WEEK_JOB_STATUS,
  GET_DASHBOARD_CURRENT_JOBS,
  GET_DASHBOARD_RECENT_INVOICES,
} from "../../modules/constants";

// ------------------------------------
// Actions
// ------------------------------------

export function getDashboardJobsToday(value) {
  return {
    type: GET_DASHBOARD_JOBS_TODAY,
    payload: value,
  };
}

export function getDashboardEmployeeStatus(value) {
  return {
    type: GET_DASHBOARD_EMPLOYEE_STATUS,
    payload: value,
  };
}

export function getDashboardTopWorkOder(value) {
  return {
    type: GET_DASHBOARD_TOP_WORKORDER,
    payload: value,
  };
}

export function getDashboardJobStatus(value) {
  return {
    type: GET_DASHBOARD_JOB_STATUS,
    payload: value,
  };
}

export function getDashboardPreviousWeekJobStatus(value) {
  return {
    type: GET_DASHBOARD_PREVIOUS_WEEK_JOB_STATUS,
    payload: value,
  };
}

export function getDashboardCurrentJobs(value) {
  return {
    type: GET_DASHBOARD_CURRENT_JOBS,
    payload: value,
  };
}

export function getDashboardRecentInvoices(value) {
  return {
    type: GET_DASHBOARD_RECENT_INVOICES,
    payload: value,
  };
}

export function getDashboardStatus(value) {
  return {
    type: GET_DASHBOARD_STATUS,
    payload: value,
  };
}
export function getDashboardTaskProgress(value) {
  return {
    type: GET_DASHBOARD_TASK_PROGRESS,
    payload: value,
  };
}
export function getDashboardPerformance(value) {
  return {
    type: GET_DASHBOARD_PERFORMANCE,
    payload: value,
  };
}
export function getDashboardLateCount(value) {
  return {
    type: GET_DASHBOARD_LATE_COUNT,
    payload: value,
  };
}
export function getDashboardTopLowPerformers(value) {
  return {
    type: DASHBOARD_TOP_LOW_PERFORMERS,
    payload: value,
  };
}
export function getDashboardUserAnalytics(value) {
  return {
    type: GET_DASHBOARD_USER_ANALYTICS,
    payload: value,
  };
}
