import React, { useEffect, useState, useTransition } from "react";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import PhoneInput from 'react-phone-input-2';
import { call } from 'redux-saga/effects';
import alertIcon from "../../assets/img/icons/alert.svg";
import addIcon from "../../assets/img/icons/plus.svg";
import DropdownStyles from "../../components/DropdownStyle/DropdownStyle";
import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";
import Select from "../../components/ReactSelectDropdown";
import { PrimaryButton, WhiteButton } from "../../styledComponents/buttons";
import {
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonText,
  InputField,
  InputLabel,
  InputWithLabelSection,
} from "../../styledComponents/common";
import {
  CommonFlexMember,
} from "../../styledComponents/members";
import { ButtonIcon } from "../../styledComponents/buttons";
import {
  checkHtml,
  checkUrl,
  handleValidation,
  validateEmail,
} from "../../utils/helper";
import { timezones } from "../../utils/timezone";
import UserProfileEmailModal from "./UserProfileEmailModal";
import {
  AddEmailDiv,
  AlertIcon,
  DefaultEmailBadge,
  EmailDiv,
  EmailOptionButton,
  EmailValue,
  FormFeedback,
  NotVerified,
  ProfileGrid,
} from "./profileStyles";
import userFormFields from "./userFormFields";
import Call from '../../assets/img/icons/call.svg';

const UserProfileEdit = (props) => {
  const [updatedParamsState, setupdatedParamsState] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    timezone: "",
  });
  const [filesState, setfilesState] = useState("");
  const [addEmailState, setaddEmailState] = useState(false);
  const [newEmailState, setnewEmailState] = useState("");
  const [emailErrorState, setemailErrorState] = useState("");
  const [emailDeleteInfoState, setemailDeleteInfoState] = useState(null);
  const [makeEmailDefaultInfoState, setmakeEmailDefaultInfoState] = useState(
    null
  );
  const [verificationSentState, setverificationSentState] = useState(false);
  const [isNumberChanged, setIsNumberChanged] = useState(false);
  const [validationMessagesState, setvalidationMessagesState] = useState({});
  const [prevProps, setprevProps] = useState(props);
  const { t } = useTranslation();

  const filterOptions = (options, filterString, values) => {
    return options.filter(
      (x) => x.label.includes(filterString) || x.metadata.includes(filterString)
    );
  };

  useEffect(() => {
    setprevProps(props);
    let updatedParams = {
      phone: props.phone,
      first_name: props.first_name,
      last_name: props.last_name ? props.last_name : ``,
      timezone: {
        label: props.timezone,
        value: props.timezone,
      },
    };
    setupdatedParamsState(updatedParams);
  }, []);

  useEffect(() => {
    if (makeEmailDefaultInfoState != null) {
      confirmMakeEmailDefault();
    }
  }, [makeEmailDefaultInfoState]);

  useEffect(() => {
    const { requestUserEmails, getUserEmails } = props;
    if (
      requestUserEmails !== prevProps.requestUserEmails &&
      requestUserEmails === true
    ) {
      getUserEmails();
      // console.log("To Chack Email", props);
    }
  }, [props.requestUserEmails, props.getUserEmails]);

  useEffect(() => {
    if (
      props.first_name !== prevProps.first_name ||
      props.last_name !== prevProps.last_name ||
      props.timezone !== prevProps.timezone
    ) {
      let updatedParams = {
        first_name: props.first_name,
        last_name: props.last_name ? props.last_name : ``,
        timezone: { label: props.timezone, value: props.timezone },
      };
      setupdatedParamsState(updatedParams);
    }
  }, [props.first_name, props.last_name, props.timezone]);

  const checkError = () => {
    let formIsValid = true;
    const updatedParams = { ...updatedParamsState };
    const validationMessages = { ...validationMessagesState };
    if (checkUrl(updatedParams.first_name)) {
      formIsValid = false;
      validationMessages.first_name = "First name can not contain url.";
    } else if (checkHtml(updatedParams.first_name)) {
      formIsValid = false;
      validationMessages.first_name = "Invalid input in first name.";
    }

    if (checkUrl(updatedParams.last_name)) {
      formIsValid = false;
      validationMessages.last_name = "First name can not contain url.";
    } else if (checkHtml(updatedParams.last_name)) {
      formIsValid = false;
      validationMessages.last_name = "Invalid input in last name.";
    }
    setvalidationMessagesState({ ...validationMessages });
    return formIsValid;
  };

  const handleSave = (e) => {
    e.preventDefault();
    const files = filesState;
    const updatedParams = updatedParamsState;
    const validationMessages = validationMessagesState;

    const { phone, ...paramData } = updatedParams;
    for (let name in paramData) {
      setTimeout(() => handleValidations(name, paramData[name]), 200);
    }
    if (
      files ||
      (updatedParams.first_name &&
        !validationMessages.first_name &&
        !validationMessages.last_name &&
        !validationMessages.phone)
    ) {
      if (checkError()) {
        const { handleSave } = props;
        let payload = {};
        payload.first_name = updatedParams.first_name;
        if (updatedParams.last_name) {
          payload.last_name = updatedParams.last_name;
        }
        if (updatedParams.phone) {
          payload.phone = updatedParams.phone;
        } else {
          payload.phone = null;
        }
        if (updatedParams.timezone) {
          payload.timezone = updatedParams.timezone.value;
          payload.timezoneOffset = updatedParams.timezone.offset;
        } else {
          payload.timezone = null;
        }
        if (files) {
          payload.image = files.base64;
        }
        handleSave(payload);
      }
    }
  };

  const handleValidations = (name, value) => {
    if (name !== "timezone" && name !== "last_name") {
      const validationMessages = { ...validationMessagesState };
      const el = userFormFields.find((v) => {
        return v.fieldName === name;
      });
      const result = handleValidation(name, el.type, value, el.options);
      let validationMessagesLast = { ...validationMessages };

      if (result.isValid) {
        delete validationMessagesLast[name];
      } else {
        validationMessagesLast = { ...validationMessagesLast, [name]: result };
      }
      setvalidationMessagesState(validationMessagesLast);
    }
  };
  //nedd to re check
  const handleChange = (e, type) => {
    const updatedParams = { ...updatedParamsState };
    const name = !e.target && type ? type : e.target.name;
    const value = e.target ? e.target.value : e.value;
    //alert(name, value, updatedParams);
    if (type === "timezone") {
      updatedParams.timezone = e;
    } else {
      switch (name) {
        case "first_name":
          updatedParams.first_name = value;
          break;
        case "last_name":
          updatedParams.last_name = value;
          break;
        case "phone":
          updatedParams.phone = value;
          break;
      }
    }

    setupdatedParamsState(updatedParams);
  };
  //Email Fuctions
  const handleAddEmail = () => {
    setaddEmailState(true);
    setverificationSentState(false);
  };

  const onNewEmailChange = (e) => {
    setnewEmailState(e.target.value);
    setemailErrorState("");
  };

  const addNewEmail = () => {
    const newEmail = newEmailState;
    const addEmail = props.addEmail;
    if (newEmail === "") {
      setemailErrorState("Email Can't be empty");
    } else if (!validateEmail(newEmail)) {
      setemailErrorState("please enter a valid email address");
    } else {
      let payload = {
        email: newEmail,
      };
      addEmail(payload);
      setnewEmailState("");
      setaddEmailState(false);
      setverificationSentState(true);
      props.getUserEmails();
    }
  };

  const cancelAddEmail = () => {
    setaddEmailState(false);
  };

  const handleEmailDelete = (id) => {
    setemailDeleteInfoState(id);
  };

  const cancelEmailDelete = () => {
    setemailDeleteInfoState(null);
  };

  const confirmEmailDelete = () => {
    const emailDeleteInfo = emailDeleteInfoState;
    const deleteEmail = props.deleteEmail;
    const payload = {
      id: emailDeleteInfo,
    };
    deleteEmail(payload);
    setemailDeleteInfoState(null);
  };

  const makeEmailDefault = (item) => {
    setmakeEmailDefaultInfoState(item);
  };

  const confirmMakeEmailDefault = () => {
    const makeEmailDefaultInfo = makeEmailDefaultInfoState;
    const { makeEmailDefault, history, loginProfileCreatedReset } = props;
    const payload = {
      id: makeEmailDefaultInfo.id,
      email: makeEmailDefaultInfo.email,
    };
    makeEmailDefault(payload);
    setmakeEmailDefaultInfoState(null);
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_role");
    localStorage.removeItem("viewer_role");
    localStorage.removeItem("user_profile");
    localStorage.removeItem("user_fullName");
    localStorage.removeItem("selected_organization");
    localStorage.removeItem("selected_organization_id");
    localStorage.removeItem("selected_organization_role");
    localStorage.removeItem("selected_organization_plan");
    localStorage.removeItem("email");
    localStorage.removeItem("created_at");
    localStorage.removeItem("is_owner");
    localStorage.removeItem("user_timezone");
    history.push("/auth/login");
    loginProfileCreatedReset();
  };

  const cancelMakeEmailDefault = () => {
    setmakeEmailDefaultInfoState(null);
  };
  const toggle = () => {
    setemailDeleteInfoState(null);
  };
  return (
    <>
      <UserProfileEmailModal
        isOpen={emailDeleteInfoState != null}
        toggle={toggle}
        confirmEmailDelete={confirmEmailDelete}
      />
      <div style={{ marginTop: "20px" }}>
        <ProfileGrid alginItems="start">
          <InputWithLabelSection>
            <InputLabel>{t("your_first_name")}</InputLabel>
            <InputField
              type="text"
              name="first_name"
              id="firstName"
              defaultValue={updatedParamsState.first_name}
              borderColor={validationMessagesState.first_name && "#fe5969"}
              onChange={(e) => handleChange(e)}
              // disabled
            />
            {validationMessagesState.first_name && (
              <FormFeedback>
                {validationMessagesState.first_name.message}
              </FormFeedback>
            )}
          </InputWithLabelSection>
          <InputWithLabelSection>
            <InputLabel>{t("your_last_name")}</InputLabel>
            <InputField
              type="text"
              name="last_name"
              id="lastName"
              defaultValue={updatedParamsState.last_name}
              borderColor={validationMessagesState.last_name && "#fe5969"}
              onChange={(e) => handleChange(e)}
              // disabled
            />
            {validationMessagesState.last_name && (
              <FormFeedback>
                {validationMessagesState.last_name.message}
              </FormFeedback>
            )}
          </InputWithLabelSection>

          <InputWithLabelSection>
            <InputLabel>{t("timezone")}</InputLabel>
            <Select
              value={
                updatedParamsState.timezone
                  ? {
                      label: updatedParamsState.timezone.value,
                      value: updatedParamsState.timezone.value,
                    }
                  : props.timezone
                  ? { label: props.timezone, value: props.timezone }
                  : ""
              }
              name="timezone"
              placeholder="Select timezone"
              options={timezones}
              filterOptions={filterOptions}
              onChange={(e) => handleChange(e, "timezone")}
              styles={DropdownStyles(null)}
            />
          </InputWithLabelSection>
          <InputWithLabelSection>
            <InputLabel>Contact Number</InputLabel>
              <CommonFlex gap="6px">
                <ButtonIcon src={Call} alt="" width="18px" />
                <PhoneInput
                  enableSearch
                  country={"us"}
                  value={updatedParamsState.phone}
                  onChange={(phone) => handleChange({value : phone}, "phone")}
                  placeholder={updatedParamsState.phone ? "" : "Add phone number"}
                  inputClass="phone-number-input"
                  buttonClass="phone-number-flag-dropdown"
                />
              </CommonFlex>
          </InputWithLabelSection>
        </ProfileGrid>
        <EmailDiv>
          <InputWithLabelSection>
            <InputLabel>{t("email_address")}</InputLabel>
            <div>
              {props.emails &&
                props.emails.length > 0 &&
                props.emails.map((item, i) => {
                  return (
                    <ProfileGrid
                      alginItems="start"
                      key={item.id}
                      margin="0 0 15px 0"
                    >
                      <EmailValue>{item.email}</EmailValue>
                      {item.default ? (
                        <DefaultEmailBadge>{t("default")}</DefaultEmailBadge>
                      ) : !item.verified ? (
                        <NotVerified>
                          <AlertIcon src={alertIcon} alt="" />
                          <CommonText fontSize="12px" color="#fab242">
                            {t("not_verified")}
                          </CommonText>
                        </NotVerified>
                      ) : (
                        <ProfileGrid emailOption alginItems="start">
                          <EmailOptionButton
                            onClick={() => makeEmailDefault(item)}
                          >
                            {t("make_default")}
                          </EmailOptionButton>
                          {!item.default && (
                            <EmailOptionButton
                              deleteEmail
                              onClick={() => handleEmailDelete(item.id)}
                            >
                              {t("delete")}
                            </EmailOptionButton>
                          )}
                        </ProfileGrid>
                      )}
                    </ProfileGrid>
                  );
                })}
              {addEmailState ? (
                <>
                  <ProfileGrid>
                    <AddEmailDiv>
                      <InputField
                        height="38px"
                        type="text"
                        name="last_name"
                        id="lastName"
                        placeholder="Add a new email…"
                        value={newEmailState}
                        onChange={(e) => onNewEmailChange(e)}
                        borderColor={emailErrorState && "#fe5969"}
                      />
                      {emailErrorState && emailErrorState !== "" && (
                        <FormFeedback>{emailErrorState}</FormFeedback>
                      )}
                    </AddEmailDiv>
                    <CommonFlex gap="12px">
                      <WhiteButton
                        type="cancel"
                        width="110px"
                        height="38px"
                        onClick={() => cancelAddEmail()}
                      >
                        {t("cancel")}
                      </WhiteButton>
                      <PrimaryButton
                        padding="0 15px"
                        width="110px"
                        height="38px"
                        disabled={!newEmailState}
                        onClick={() => addNewEmail()}
                      >
                        {t("add_email")}
                      </PrimaryButton>
                    </CommonFlex>
                  </ProfileGrid>
                </>
              ) : (
                <CommonGrid
                  columns="20px auto"
                  gap="8px"
                  margin="20px 0 0 0"
                  justifyContent="start"
                  alignItem="center"
                  style={{ cursor: `pointer` }}
                  onClick={() => handleAddEmail()}
                >
                  <img src={addIcon} alt="plus" />
                  <ColoredText fontSize="14px" primary>
                    {t("add_another_email")}
                  </ColoredText>
                </CommonGrid>
              )}
            </div>
          </InputWithLabelSection>
        </EmailDiv>
        <ProfileGrid margin="25px 0 0 0">
          <PrimaryButton
            width="fit-content"
            padding={props.updateIsLoading ? "0 25px" : "0 50px"}
            onClick={handleSave}
          >
            {props.updateIsLoading ? (
              <ButtonTextLoader loadingText="Saving.." fontSize="13px" />
            ) : (
              "Save"
            )}
          </PrimaryButton>
        </ProfileGrid>
      </div>
    </>
  );
};

export default UserProfileEdit;
