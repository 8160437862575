import {
  DASHBOARD_TOP_LOW_PERFORMERS,
  DASHBOARD_TOP_LOW_PERFORMERS_FAILURE,
  DASHBOARD_TOP_LOW_PERFORMERS_SUCCESS,
  GET_DASHBOARD_CURRENT_JOBS,
  GET_DASHBOARD_CURRENT_JOBS_FAILURE,
  GET_DASHBOARD_CURRENT_JOBS_SUCCESS,
  GET_DASHBOARD_EMPLOYEE_STATUS,
  GET_DASHBOARD_EMPLOYEE_STATUS_FAILURE,
  GET_DASHBOARD_EMPLOYEE_STATUS_SUCCESS,
  GET_DASHBOARD_JOBS_TODAY,
  GET_DASHBOARD_JOBS_TODAY_FAILURE,
  GET_DASHBOARD_JOBS_TODAY_SUCCESS,
  GET_DASHBOARD_JOB_STATUS,
  GET_DASHBOARD_JOB_STATUS_FAILURE,
  GET_DASHBOARD_JOB_STATUS_SUCCESS,
  GET_DASHBOARD_LATE_COUNT,
  GET_DASHBOARD_LATE_COUNT_FAILURE,
  GET_DASHBOARD_LATE_COUNT_SUCCESS,
  GET_DASHBOARD_PERFORMANCE,
  GET_DASHBOARD_PERFORMANCE_FAILURE,
  GET_DASHBOARD_PERFORMANCE_SUCCESS,
  GET_DASHBOARD_PREVIOUS_WEEK_JOB_STATUS,
  GET_DASHBOARD_PREVIOUS_WEEK_JOB_STATUS_FAILURE,
  GET_DASHBOARD_PREVIOUS_WEEK_JOB_STATUS_SUCCESS,
  GET_DASHBOARD_RECENT_INVOICES,
  GET_DASHBOARD_RECENT_INVOICES_FAILURE,
  GET_DASHBOARD_RECENT_INVOICES_SUCCESS, //new apis
  GET_DASHBOARD_STATUS,
  GET_DASHBOARD_STATUS_FAILURE,
  GET_DASHBOARD_STATUS_SUCCESS,
  GET_DASHBOARD_TASK_PROGRESS,
  GET_DASHBOARD_TASK_PROGRESS_FAILURE,
  GET_DASHBOARD_TASK_PROGRESS_SUCCESS,
  GET_DASHBOARD_TOP_WORKORDER,
  GET_DASHBOARD_TOP_WORKORDER_FAILURE,
  GET_DASHBOARD_TOP_WORKORDER_SUCCESS,
  GET_DASHBOARD_USER_ANALYTICS,
  GET_DASHBOARD_USER_ANALYTICS_FAILURE,
  GET_DASHBOARD_USER_ANALYTICS_SUCCESS,
  LOGIN_PROFILE_CREATED_RESET,
} from '../../modules/constants';

// ------------------------------------
// Action handler methods
// ------------------------------------

//New Apis

//JobsToday
export const handleDashboardJobsToday = (state, action) => {
  return {
    ...state,
    jobsTodayIsloading: true,
  };
};

export const handleDashboardJobsTodaySuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      jobsTodayIsloading: false,
      jobsToday: data.data,
    };
  }
  return {
    ...state,
    jobsTodayIsloading: false,
  };
};

export const handleDashboardJobsTodayFailure = (state, action) => {
  return {
    ...state,
    jobsTodayIsloading: false,
    jobsToday: null,
  };
};

// Employee's Status (Online & Offline)

export const handleDashboardEmployeeStatus = (state, action) => {
  return {
    ...state,
    employeeStatusIsloading: true,
  };
};

export const handleDashboardEmployeeStatusSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      employeeStatusIsloading: false,
      employeStatus: data.data,
    };
  }
  return {
    ...state,
    employeeStatusIsloading: false,
  };
};

export const handleDashboardEmployeeStatusFailure = (state, action) => {
  return {
    ...state,
    employeeStatusIsloading: false,
    employeStatus: null,
  };
};

//Top WorkOrder

export const handleDashboardTopWorkOrder = (state, action) => {
  return {
    ...state,
    topWorkOrderIsloading: true,
  };
};

export const handleDashboardTopWorkOrderSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      topWorkOrderIsloading: false,
      topWorkOrder: data.data,
    };
  }
  return {
    ...state,
    topWorkOrderIsloading: false,
  };
};

export const handleDashboardTopWorkOrderFailure = (state, action) => {
  return {
    ...state,
    topWorkOrderIsloading: false,
    topWorkOrder: null,
  };
};

//Job Status
export const handleDashboardJobStatus = (state, action) => {
  return {
    ...state,
    jobStatusIsloading: true,
  };
};

export const handleDashboardJobStatusSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      jobStatusIsloading: false,
      job_status: data.data,
    };
  }
  return {
    ...state,
    jobStatusIsloading: false,
  };
};

export const handleDashboardJobStatusFailure = (state, action) => {
  return {
    ...state,
    jobStatusIsloading: false,
    job_status: null,
  };
};

//Job Status previous week

export const handleDashboardPreviousWeekJobStatus = (state, action) => {
  return {
    ...state,
    jobStatusPreviousWeekIsloading: true,
  };
};

export const handleDashboardPreviousWeekJobStatusSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      jobStatusPreviousWeekIsloading: false,
      jobStatusPreviousWeek: data.data,
    };
  }
  return {
    ...state,
    jobStatusPreviousWeekIsloading: false,
  };
};

export const handleDashboardPreviousWeekJobStatusFailure = (state, action) => {
  return {
    ...state,
    jobStatusPreviousWeekIsloading: false,
    jobStatusPreviousWeek: null,
  };
};

//Current jobs Summary

export const handleDashboardCurrentJobs = (state, action) => {
  return {
    ...state,
    currentJobsIsloading: true,
  };
};
export const handleDashboardCurrentJobsSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      currentJobsIsloading: false,
      currentJobs: data.data,
    };
  }
  return {
    ...state,
    currentJobsIsloading: false,
  };
};
export const handleDashboardCurrentJobsFailure = (state, action) => {
  return {
    ...state,
    currentJobsIsloading: false,
    currentJobs: null,
  };
};

// Recent Invoices
export const handleDashboardRecentInvoices = (state, action) => {
  return {
    ...state,
    recentInvoicesIsloading: true,
  };
};

export const handleDashboardRecentInvoicesSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      recentInvoicesIsloading: false,
      recentInvoices: data.data,
    };
  }
  return {
    ...state,
    recentInvoicesIsloading: false,
  };
};

export const handleDashboardRecentInvoicesFailure = (state, action) => {
  return {
    ...state,
    recentInvoicesIsloading: false,
    recentInvoices: null,
  };
};
//Old Apis
export const handleDashboardStatus = (state, action) => {
  return {
    ...state,
    employeeStatusIsloading: true,
  };
};
export const handleDashboardStatusSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      employeeStatusIsloading: false,
      employee_status: data.data,
    };
  }
  return {
    ...state,
    employeeStatusIsloading: false,
  };
};
export const handleDashboardStatusFailure = (state, action) => {
  return {
    ...state,
    employeeStatusIsloading: false,
    employee_status: null,
  };
};

export const handleDashboardTaskProgress = (state, action) => {
  return {
    ...state,
    taskProgressIsLoading: true,
  };
};
export const handleDashboardTaskProgressSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      taskProgressIsLoading: false,
      task_progress_overview: data.data.task_progress_overview,
    };
  }
  return {
    ...state,
    taskProgressIsLoading: false,
  };
};
export const handleDashboardTaskProgressFailure = (state, action) => {
  return {
    ...state,
    taskProgressIsLoading: false,
    task_progress_overview: null,
  };
};

export const handleDashboardTopLowPerformers = (state, action) => {
  return {
    ...state,
    topLowPerformersIsLoading: true,
  };
};
export const handleDashboardTopLowPerformersSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      topLowPerformersIsLoading: false,
      top_low_performers: data.data,
    };
  }
  return {
    ...state,
    topLowPerformersIsLoading: false,
  };
};
export const handleDashboardTopLowPerformersFailure = (state, action) => {
  return {
    ...state,
    topLowPerformersIsLoading: false,
    top_low_performers: null,
  };
};

export const handleDashboardLateCount = (state, action) => {
  return {
    ...state,
    lateCountIsloading: true,
  };
};
export const handleDashboardLateCountSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      lateCountIsloading: false,
      late_count_comparison: data.data,
    };
  }
  return {
    ...state,
    lateCountIsloading: false,
  };
};
export const handleDashboardLateCountFailure = (state, action) => {
  return {
    ...state,
    lateCountIsloading: false,
    late_count_comparison: null,
  };
};

export const handleDashboardPerformance = (state, action) => {
  return {
    ...state,
    performanceComparisonIsLoading: true,
  };
};
export const handleDashboardPerformanceSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      performance_comparison: data.data,
      performanceComparisonIsLoading: false,
    };
  }
  return {
    ...state,
    performanceComparisonIsLoading: false,
  };
};
export const handleDashboardPerformanceFailure = (state, action) => {
  return {
    ...state,
    performanceComparisonIsLoading: false,
    performance_comparison: null,
  };
};

export const handleDashboardUserAnalyticsRequest = (state, action) => {
  return {
    ...state,
    userAnalyticsIsLoading: true,
  };
};
export const handleDashboardUserAnalyticsRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      userAnalytics: data.data,
      userAnalyticsIsLoading: false,
    };
  }
  return {
    ...state,
    userAnalyticsIsLoading: false,
  };
};
export const handleDashboardUserAnalyticsRequestFailure = (state, action) => {
  return {
    ...state,
    userAnalyticsIsLoading: false,
    userAnalytics: null,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  //new apis
  [GET_DASHBOARD_JOBS_TODAY]: handleDashboardJobsToday,
  [GET_DASHBOARD_JOBS_TODAY_SUCCESS]: handleDashboardJobsTodaySuccess,
  [GET_DASHBOARD_JOBS_TODAY_FAILURE]: handleDashboardJobsTodayFailure,

  [GET_DASHBOARD_EMPLOYEE_STATUS]: handleDashboardEmployeeStatus,
  [GET_DASHBOARD_EMPLOYEE_STATUS_SUCCESS]: handleDashboardEmployeeStatusSuccess,
  [GET_DASHBOARD_EMPLOYEE_STATUS_FAILURE]: handleDashboardEmployeeStatusFailure,

  [GET_DASHBOARD_TOP_WORKORDER]: handleDashboardTopWorkOrder,
  [GET_DASHBOARD_TOP_WORKORDER_SUCCESS]: handleDashboardTopWorkOrderSuccess,
  [GET_DASHBOARD_TOP_WORKORDER_FAILURE]: handleDashboardTopWorkOrderFailure,

  [GET_DASHBOARD_JOB_STATUS]: handleDashboardJobStatus,
  [GET_DASHBOARD_JOB_STATUS_SUCCESS]: handleDashboardJobStatusSuccess,
  [GET_DASHBOARD_JOB_STATUS_FAILURE]: handleDashboardJobStatusFailure,

  [GET_DASHBOARD_PREVIOUS_WEEK_JOB_STATUS]:
    handleDashboardPreviousWeekJobStatus,
  [GET_DASHBOARD_PREVIOUS_WEEK_JOB_STATUS_SUCCESS]:
    handleDashboardPreviousWeekJobStatusSuccess,
  [GET_DASHBOARD_PREVIOUS_WEEK_JOB_STATUS_FAILURE]:
    handleDashboardPreviousWeekJobStatusFailure,

  [GET_DASHBOARD_CURRENT_JOBS]: handleDashboardCurrentJobs,
  [GET_DASHBOARD_CURRENT_JOBS_SUCCESS]: handleDashboardCurrentJobsSuccess,
  [GET_DASHBOARD_CURRENT_JOBS_FAILURE]: handleDashboardCurrentJobsFailure,

  [GET_DASHBOARD_RECENT_INVOICES]: handleDashboardRecentInvoices,
  [GET_DASHBOARD_RECENT_INVOICES_SUCCESS]: handleDashboardRecentInvoicesSuccess,
  [GET_DASHBOARD_RECENT_INVOICES_FAILURE]: handleDashboardRecentInvoicesFailure,

  //Old Apis
  // [GET_DASHBOARD_STATUS]: handleDashboardStatus,
  // [GET_DASHBOARD_STATUS_SUCCESS]: handleDashboardStatusSuccess,
  // [GET_DASHBOARD_STATUS_FAILURE]: handleDashboardStatusFailure,

  [GET_DASHBOARD_TASK_PROGRESS]: handleDashboardTaskProgress,
  [GET_DASHBOARD_TASK_PROGRESS_SUCCESS]: handleDashboardTaskProgressSuccess,
  [GET_DASHBOARD_TASK_PROGRESS_FAILURE]: handleDashboardTaskProgressFailure,

  [DASHBOARD_TOP_LOW_PERFORMERS]: handleDashboardTopLowPerformers,
  [DASHBOARD_TOP_LOW_PERFORMERS_SUCCESS]:
    handleDashboardTopLowPerformersSuccess,
  [DASHBOARD_TOP_LOW_PERFORMERS_FAILURE]:
    handleDashboardTopLowPerformersFailure,

  [GET_DASHBOARD_LATE_COUNT]: handleDashboardLateCount,
  [GET_DASHBOARD_LATE_COUNT_SUCCESS]: handleDashboardLateCountSuccess,
  [GET_DASHBOARD_LATE_COUNT_FAILURE]: handleDashboardLateCountFailure,

  [GET_DASHBOARD_PERFORMANCE]: handleDashboardPerformance,
  [GET_DASHBOARD_PERFORMANCE_SUCCESS]: handleDashboardPerformanceSuccess,
  [GET_DASHBOARD_PERFORMANCE_FAILURE]: handleDashboardPerformanceFailure,

  [GET_DASHBOARD_USER_ANALYTICS]: handleDashboardUserAnalyticsRequest,
  [GET_DASHBOARD_USER_ANALYTICS_SUCCESS]:
    handleDashboardUserAnalyticsRequestSuccess,
  [GET_DASHBOARD_USER_ANALYTICS_FAILURE]:
    handleDashboardUserAnalyticsRequestFailure,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  //New Apis
  jobsTodayIsloading: false,
  jobsToday: null,

  employeeStatusIsloading: false,
  employeStatus: null,

  topWorkOrderIsloading: false,
  topWorkOrder: null,

  jobStatusIsloading: false,
  job_status: null,

  jobStatusPreviousWeekIsloading: false,
  jobStatusPreviousWeek: null,

  currentJobsIsloading: false,
  currentJobs: null,

  recentInvoicesIsloading: false,
  recentInvoices: null,

  //Old Apis
  employee_status: null,
  taskProgressIsLoading: false,
  task_progress_overview: [],
  performanceComparisonIsLoading: false,
  performance_comparison: [],
  lateCountIsloading: false,
  late_count_comparison: [],
  topLowPerformersIsLoading: false,
  top_low_performers: null,
};

export default function authReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
