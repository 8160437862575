import { connect } from "react-redux";

// Component
import SignUp from "./SignUp";
// Actions
import { signUp, resetSignUp } from "./signUpActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  signup: state.signUp,
  auth: state.auth,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  signUp: (details) => dispatch(signUp({ ...details })),
  resetSignUp: () => dispatch(resetSignUp()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
