import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import styled from 'styled-components';

import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import { WhiteButton } from '../../../styledComponents/buttons';
import { AddClientContainerNew } from '../../../styledComponents/clients';
import {
  CommonGrid,
  CommonText,
  Container,
  InputLabel,
  InputWithLabelSection,
} from '../../../styledComponents/common';
import { CommonImage } from '../../../styledComponents/common';
import { getMapCircle } from '../../../utils/helper';
import { DataAndMapContainer } from '../../FieldServices/JobSites/jobSitesStyles';
import { MapAndAlertContainer } from '../../FieldServices/RouteMap/routeMapStyles';

const StaticTaskLocationModal = props => {
  const { isOpen, toggle, taskLocation, locationIndex } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (taskLocation && taskLocation.id) {
      setIsLoading(true);

      const baseUrl = `https://maps.googleapis.com/maps/api/staticmap?key=${process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY}&size=640x512&zoom=16&scale=1&language=ar`;
      const markers = `&markers=color:red%7C${taskLocation.latitude},${taskLocation.longitude}`;
      const path = `&path=fillcolor:0x6f7aff33%7Cweight:2%7Ccolor:0x6f7affff${getMapCircle(
        taskLocation.latitude,
        taskLocation.longitude,
        // taskLocation.radius,
      )}`;
      const url = `${baseUrl}${markers}${path}`;

      setImageUrl(url);
      setIsLoading(false);
    }
  }, [taskLocation]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      style={{ maxWidth: '800px', maxHeight: '580px' }}
    >
      <ModalBody style={{ padding: `10px` }}>
        {isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <DataAndMapContainer columns="35% 65%">
            {taskLocation?.name && (
              <AddClientContainerNew padding="10px">
                <div>
                  <InputWithLabelSection>
                    <InputLabel fontSize="15px" fontWeight="bold">
                      Service Location {locationIndex}
                    </InputLabel>
                  </InputWithLabelSection>

                  <InputWithLabelSection margin="50px 0 0 0" gap="10px">
                    <InputLabel>Location Name</InputLabel>
                    <CommonText fontSize="12px" fontWeight="600">
                      {taskLocation.name}
                    </CommonText>
                  </InputWithLabelSection>

                  <InputWithLabelSection margin="20px 0 0 0" gap="10px">
                    <InputLabel>Location Address</InputLabel>
                    <CommonText fontSize="12px" fontWeight="600">
                      {taskLocation.address}
                    </CommonText>
                  </InputWithLabelSection>

                  <InputWithLabelSection margin="20px 0 0 0" gap="10px">
                    <InputLabel>Radius</InputLabel>
                    <CommonText fontSize="12px" fontWeight="600">
                      {taskLocation.radius}
                    </CommonText>
                  </InputWithLabelSection>
                </div>

                <CommonGrid
                  justifyContent="unset"
                  // padding="-20px 0 0 0"
                  // margin="30px 0 0"
                  gap="0"
                >
                  <WhiteButton width="100px" type="cancel"  onClick={toggle}>
                    {t('close')}
                  </WhiteButton>
                </CommonGrid>
              </AddClientContainerNew>
            )}
           
            <CommonImage src={imageUrl} alt="map" />
          </DataAndMapContainer>
        )}
      </ModalBody>
    </Modal>
  );
};

export default StaticTaskLocationModal;
