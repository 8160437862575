//Constants
import {
  CREATE_JOB,
  CREATE_JOB_FAILURE,
  CREATE_JOB_SUCCESS,
  DELETE_WORK_ORDER_JOB,
  DELETE_WORK_ORDER_JOB_SUCCESS,
  DELETE_WORK_ORDER_JOB_FAILURE,
  CREATE_WORK_ORDER,
  CREATE_WORK_ORDER_BILL,
  CREATE_WORK_ORDER_BILL_FAILURE,
  CREATE_WORK_ORDER_BILL_SUCCESS,
  CREATE_WORK_ORDER_BUDGET,
  CREATE_WORK_ORDER_BUDGET_FAILURE,
  CREATE_WORK_ORDER_BUDGET_SUCCESS,
  CREATE_WORK_ORDER_FAILURE,
  CREATE_WORK_ORDER_SUCCESS,
  DELETE_WORK_ORDER,
  DELETE_WORK_ORDER_FAILURE,
  DELETE_WORK_ORDER_SUCCESS,
  DUPLICATE_WORK_ORDER,
  DUPLICATE_WORK_ORDER_FAILURE,
  DUPLICATE_WORK_ORDER_SUCCESS,
  GET_JOBS_UNDER_WORKORDER,
  GET_JOBS_UNDER_WORKORDER_FAILURE,
  GET_JOBS_UNDER_WORKORDER_SUCCESS,
  GET_JOB_DETAILS,
  GET_JOB_DETAILS_FAILURE,
  GET_JOB_DETAILS_SUCCESS,
  GET_WORKORDER_BUDGET_AND_BILLABLE,
  GET_WORKORDER_BUDGET_AND_BILLABLE_FAILURE,
  GET_WORKORDER_BUDGET_AND_BILLABLE_SUCCESS,
  GET_WORK_ORDER,
  GET_WORK_ORDER_BILL,
  GET_WORK_ORDER_BILL_FAILURE,
  GET_WORK_ORDER_BILL_SUCCESS,
  GET_WORK_ORDER_BUDGET,
  GET_WORK_ORDER_BUDGET_FAILURE,
  GET_WORK_ORDER_BUDGET_SUCCESS,
  GET_WORK_ORDER_FAILURE,
  GET_WORK_ORDER_SUCCESS,
  UPDATE_JOB,
  UPDATE_JOB_FAILURE,
  UPDATE_JOB_SUCCESS,
  UPDATE_WORK_ORDER,
  UPDATE_WORK_ORDER_BILL,
  UPDATE_WORK_ORDER_BILL_FAILURE,
  UPDATE_WORK_ORDER_BILL_SUCCESS,
  UPDATE_WORK_ORDER_BUDGET,
  UPDATE_WORK_ORDER_BUDGET_FAILURE,
  UPDATE_WORK_ORDER_BUDGET_SUCCESS,
  UPDATE_WORK_ORDER_FAILURE,
  UPDATE_WORK_ORDER_SUCCESS,
  WORK_ORDER_LIST,
  WORK_ORDER_LIST_FAILURE,
  WORK_ORDER_LIST_SUCCESS,
  CLEAR_WORK_ORDER_BUDGET,
  CLEAR_WORK_ORDER_BILLING,
  WORK_ORDER_SHORT,
  WORK_ORDER_SHORT_SUCCESS,
  WORK_ORDER_SHORT_FAILURE,
  CLEAR_WORK_ORDER_ID,
} from '../../modules/constants';
import { createNotification } from '../../modules/notificationManager';

const noInternetMsg =
  'No Internet Connection! Please connect to the internet and try again';



export const createWorkOrderHandler = (state, action) => {
  return {
    ...state,
    createWorkOrderIsLoading: true,
  };
};
export const createWorkOrderSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    createNotification('success', 'Work order created successfully', 2000);
    return {
      ...state,
      createWorkOrderIsLoading: false,
      createdWorkOrderId: data.data.id,
    };
  }
  return {
    ...state,
    createWorkOrderIsLoading: false,
    createdWorkOrderId: null,
  };
};
export const createWorkOrderFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification('error', noInternetMsg, 3000);
  } else {
    createNotification(
      'error',
      'Failed to create work order. Please try again.',
      4000,
    );
  }

  return {
    ...state,
    createWorkOrderIsLoading: false,
    createdWorkOrderId: null,
  };
};

// Job Create

export const createJobHandler = (state, action) => {
  return {
    ...state,
    createOrUpdateJobIsLoading: true,
    createdJob: false,
  };
};
export const createJobSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    createNotification('success', 'Job created successfully', 2000);
    return {
      ...state,
      createOrUpdateJobIsLoading: false,
      createdJobId: data.data.id,
      createdJob: true,
      jobsUnderWorkOrderList: [...state.jobsUnderWorkOrderList, data.data],
    };
  }
  return {
    ...state,
    createOrUpdateJobIsLoading: false,
    createdJob: false,
    createdJobId: null,
  };
};
export const createJobFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification('error', noInternetMsg, 3000);
  } else {
    createNotification(
      'error',
      'Failed to create job. Please try again.',
      4000,
    );
  }

  return {
    ...state,
    createOrUpdateJobIsLoading: false,
    createdJobId: null,
    createdJob: false,
  };
};

//DELETE WORK ORDER JOB
export const deleteWorkOrderJobHandler = (state, action) => {
  return {
    ...state,
    workOrderJobDeleteIsLoading: true,
    deleteJobCallback: false,
  };
};
export const deleteWorkOrderJobSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 204) {
    createNotification("success", "shift deleted successfully", 3000);
    return {
      ...state,
      workOrderJobDeleteIsLoading: false,
      deleteJobCallback: true,
    };
  }
  return {
    ...state,
    workOrderJobDeleteIsLoading: false,
    deleteJobCallback: false,
  };
};
export const deleteWorkOrderJobFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (
    response &&
    response.status === 403 &&
    response.data &&
    response.data.error &&
    response.data.error === "cannot delete past schedule"
  ) {
    createNotification("error", `Past time cannot be deleted`, 5000);
    return {
      ...state,
      workOrderJobDeleteIsLoading: false,
      deleteJobCallback: false,
    };
  }
  createNotification(
    "error",
    "Could not delete shift. Please try again!",
    3000
  );
  return {
    ...state,
    workOrderJobDeleteIsLoading: false,
    deleteJobCallback: false,
  };
};

// get Work Order
export const getWorkOrderHandler = (state, action) => {
  return {
    ...state,
    getWorkOrderIsLoading: true,
  };
};

export const getWorkOrderSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      getWorkOrderIsLoading: false,
      workOrderDetails: data.data,
    };
  }
  return {
    ...state,
    getWorkOrderIsLoading: false,
    workOrderDetails: null,
  };
};

export const getWorkOrderFailureHandler = (state, action) => {
  createNotification(
    'error',
    'Could not fetch Work Order. Please try again!',
    3000,
  );
  return {
    ...state,
    getWorkOrderIsLoading: false,
    workOrderDetails: null,
  };
};

// get workOrder List Under one organization

export const getWorkOrderListHandler = (state, action) => {
  return {
    ...state,
    getWorkOrderListIsLoading: true,
  };
};

export const getWorkOrderListSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      duplicateWorkOrderId: null,
      getWorkOrderListIsLoading: false,
      WorkOrderList: data.data.results,
      pageSizeWorkOrder: data.data.page_size,
      totalWorkOrderCount: data.data.count,
      jobsUnderWorkOrderList: [],
      duplicateWorkOrderId: null,
    };
  }
  return {
    ...state,
    duplicateWorkOrderId: null,
    getWorkOrderListIsLoading: false,
    WorkOrderList: [],
    pageSizeWorkOrder: null,
    totalWorkOrderCount: null,
    jobsUnderWorkOrderList: [],
    createdWorkOrderId: null,
    duplicateWorkOrderId: null,
  };
};

export const getWorkOrderListFailureHandler = (state, action) => {
  createNotification(
    'error',
    'Could not fetch Work Order List. Please try again!',
    3000,
  );
  return {
    ...state,
    duplicateWorkOrderId: null,
    getWorkOrderListIsLoading: false,
    WorkOrderList: [],
    pageSizeWorkOrder: null,
    totalWorkOrderCount: null,
    jobsUnderWorkOrderList: [],
    duplicateWorkOrderId: null,
  };
};

// Get All jobs Under One WorkOrder

export const getJobsUnderWorkOrderHandler = (state, action) => {
  return {
    ...state,
    getJobsUnderWorkOrderIsLoading: true,
  };
};
export const getJobsUnderWorkOderSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      getJobsUnderWorkOrderIsLoading: false,
      jobsUnderWorkOrderList: data.data.results,
      pageSizeJobs: data.data.page_size,
      totalJobsCount: data.data.count,
    };
  }
  return {
    ...state,
    getJobsUnderWorkOrderIsLoading: false,
    jobsUnderWorkOrderList: [],
    pageSizeJobs: null,
    totalJobsCount: null,
  };
};
export const getJobsUnderWorkOrderFailureHandler = (state, action) => {
  return {
    ...state,
    getJobsUnderWorkOrderIsLoading: false,
    jobsUnderWorkOrderList: [],
    pageSizeJobs: null,
    totalJobsCount: null,
  };
};
// Update Work Order
export const updateWorkOrderRequest = (state, action) => {
  return {
    ...state,
    UpdateWorkOrderLoading: true,
  };
};

export const updateWorkOrderRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200 && data.data) {
    createNotification('success', 'Work Order updated successfully', 3000);
    return {
      ...state,
      UpdateWorkOrderLoading: false,
    };
  }
  return {
    ...state,
    UpdateWorkOrderLoading: false,
  };
};

export const updateWorkOrderRequestFailure = (state, action) => {
  createNotification(
    'error',
    'Could not update Work Order. Please try again!',
    3000,
  );
  return {
    ...state,
    UpdateWorkOrderLoading: false,
  };
};

// update Job
export const updateJobRequest = (state, action) => {
  return {
    ...state,
    createOrUpdateJobIsLoading: true,
    jobUpdate: false,
  };
};

export const updateJobRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200 && data.data) {
    createNotification('success', 'Job updated successfully', 3000);
    const { jobsUnderWorkOrderList } = state;
    const updatedList = [...jobsUnderWorkOrderList];
    const index = updatedList.findIndex(job => job.id === data.data.id);

    if (index !== -1) {
      updatedList[index] = data.data;
    }

    return {
      ...state,
      createOrUpdateJobIsLoading: false,
      jobUpdate: true,
      jobsUnderWorkOrderList: updatedList,
    };
  }
  return {
    ...state,
    createOrUpdateJobIsLoading: false,
    jobUpdate: false,
  };
};

export const updateJobRequestFailure = (state, action) => {
  createNotification('error', 'Could not update job. Please try again!', 3000);
  return {
    ...state,
    createOrUpdateJobIsLoading: false,
    jobUpdate: false,
  };
};

// Delete client
export const deleteWorkOrderHandler = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    workOrderDeleteLoading: true,
  };
};

export const deleteWorkOrderSuccessHandler = (state, action) => {
  const {
    payload: { workorder_id },
  } = action;
  if (state.WorkOrderList && state.WorkOrderList.length) {
    createNotification('success', 'Work order deleted successfully', 3000);
    return {
      ...state,
      workOrderDeleteLoading: false,
      duplicateWorkOrderId: null,
      WorkOrderList: state.WorkOrderList.filter(
        workOrder => workOrder.id !== workorder_id,
      ),
    };
  }
  return {
    ...state,
    workOrderDeleteLoading: false,
  };
};
export const deleteWorkOrderFailureHandler = (state, action) => {
  createNotification('error', 'Could not delete. Please try again!', 3000);
  return {
    ...state,
    workOrderDeleteLoading: false,
  };
};

// Duplicate Work Order
export const duplicateWorkOrderHandler = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    getWorkOrderDuplicateListIsLoading: true,
    duplicateWorkOrderId: null,
  };
};

export const duplicateWorkOrderSuccessHandler = (state, action) => {
  const {
    payload: { data, workOrder_id },
  } = action;
  if (data.status === 200 && data.data) {
    createNotification('success', 'Work Order duplicated successfully', 3000);
    const workorders = JSON.parse(JSON.stringify(state.WorkOrderList));
    if (workorders && workorders.length > 0) {
      const workOrderIndex = workorders.findIndex(
        workorder => workorder.id === workOrder_id,
      );
      if (workOrderIndex > -1) {
        workorders.splice(workOrderIndex, 0, { ...data.data, managed: true });
      }
    }
    return {
      ...state,
      getWorkOrderDuplicateListIsLoading: false,
      WorkOrderList: [...workorders],
      duplicateWorkOrderId: data.data.id,
    };
  }
  return {
    ...state,
    getWorkOrderDuplicateListIsLoading: false,
    duplicateWorkOrderId: null,
  };
};

export const duplicateWorkOrderFailureHandler = (state, action) => {
  createNotification(
    'error',
    'Could not duplicate Work Order. Please try again!',
    3000,
  );
  return {
    ...state,
    getWorkOrderDuplicateListIsLoading: false,
    duplicateWorkOrderId: null,
  };
};
// Billing and budgeting
export const getWorkOrderBudgetAndBillableRequestHandler = (state, action) => {
  return {
    ...state,
    workOrderBudgetAndBillableIsLoading: true,
  };
};
export const getWorkOrderBudgetAndBillableRequestSuccessHandler = (
  state,
  action,
) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      workOrderBudgetAndBillableIsLoading: false,
      workOrderBudgetAndBillable: data.data,
    };
  }
  return {
    ...state,
    workOrderBudgetAndBillableIsLoading: false,
    workOrderBudgetAndBillable: null,
  };
};
export const getWorkOrderBudgetAndBillableRequestFailureHandler = (
  state,
  action,
) => {
  const {
    payload: { response },
  } = action;
  return {
    ...state,
    workOrderBudgetAndBillableIsLoading: false,
    workOrderBudgetAndBillable: null,
  };
};

//billing

export const addWorkOrderBillingRequestHandler = (state, action) => {
  return {
    ...state,
    saveBillingLoading: true,
    isBillAddOrUpdate: false,
  };
};
export const addWorkOrderBillingRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    createNotification("success", "Work Order billing added successfully!", 3000);
    return {
      ...state,
      saveBillingLoading: false,
      WorkOrderBilling: data.data,
      isBillAddOrUpdate: true,
    };
  }
  return {
    ...state,
    saveBillingLoading: false,
    isBillAddOrUpdate: false,
  };
};
export const addworkOrderBillingRequestFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification("error", noInternetMsg, 3000);
  } else {
    let errorMsg;
    switch (response.status) {
      case 400:
        if (response.data.workorder_hourly && response.data.workorder_hourly.rate) {
          errorMsg = response.data.workorder_hourly.rate[0];
        } else if ((errorMsg = response.data.billable_amount)) {
          errorMsg = response.data.billable_amount[0];
        } else {
          errorMsg = "Failed to add workorder billing. please try again.";
        }
        break;
      case 403:
        errorMsg = "Sorry! you do not have permission perform this action";
        break;
      default:
        errorMsg = "Failed to add workorder billing. please try again.";
    }
    if (errorMsg) {
      createNotification("error", errorMsg, 4000);
    }
  }
  return {
    ...state,
    saveBillingLoading: false,
    isBillAddOrUpdate: false,
  };
};

// get billing

export const getWorkOrderBillingRequestHandler = (state, action) => {
  return {
    ...state,
    getBillingLoading: false,
    WorkOrderBilling: null,
  };
};
export const getWorkOrderBillingRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      getBillingLoading: false,
      WorkOrderBilling: data.data,
    };
  }
  return {
    ...state,
    getBillingLoading: false,
    WorkOrderBilling: null,
  };
};
export const getWorkOrderBillingRequestFailureHandler = (state, action) => {
  return {
    ...state,
    getBillingLoading: false,
    WorkOrderBilling: null,
  };
};

//update billing

export const updateWorkOrderBillingRequestHandler = (state, action) => {
  return {
    ...state,
    saveBillingLoading: true,
    isBillAddOrUpdate: false,
  };
};
export const updateWorkOrderBillingRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data) {
    createNotification(
      "success",
      "Work Order billing updated successfully!",
      3000
    );
    return {
      ...state,
      saveBillingLoading: false,
      isBillAddOrUpdate: true,
      WorkOrderBilling: data,
    };
  }
  return {
    ...state,
    saveBillingLoading: false,
    isBillAddOrUpdate: false,
  };
};
export const updateWorkOrderBillingRequestFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification("error", noInternetMsg, 3000);
  } else {
    let errorMsg;
    switch (response.status) {
      case 400:
        if (response.data.workorder_hourly && response.data.workorder_hourly.rate) {
          errorMsg = response.data.workorder_hourly.rate[0];
        } else if ((errorMsg = response.data.billable_amount)) {
          errorMsg = response.data.billable_amount[0];
        } else {
          errorMsg = "Failed to update workorder billing. please try again.";
        }
        break;
      case 403:
        errorMsg = "Sorry! you do not have permission perform this action";
        break;
      default:
        errorMsg = "Failed to update workorder billing. please try again.";
    }
    if (errorMsg) {
      createNotification("error", errorMsg, 4000);
    }
  }
  return {
    ...state,
    saveBillingLoading: false,
    isBillAddOrUpdate: false,
  };
};


//Add Budget

export const addWorkOrderBudgetRequestHandler = (state, action) => {
  return {
    ...state,
    saveBudgetLoading: true,
    isBudgetAddOrUpdate: false,
  };
};
export const addWorkOrderBudgetRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    createNotification("success", "Work Order budget added successfully!", 4000);
    return {
      ...state,
      saveBudgetLoading: false,
      isBudgetAddOrUpdate: true,
      workOrderBudget: data.data,
    };
  }
  return {
    ...state,
    saveBudgetLoading: false,
    isBudgetAddOrUpdate: false,
  };
};
export const addWorkOrderBudgetRequestFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification("error", noInternetMsg, 3000);
  } else {
    let errorMsg;
    switch (response.status) {
      case 400:
        if (response.data.budget_amount) {
          errorMsg = response.data.budget_amount[0];
        } else if ((errorMsg = response.data.budget_hours)) {
          errorMsg = response.data.budget_hours[0];
        } else {
          errorMsg = "Failed to add Work Order budget. please try again.";
        }
        break;
      case 403:
        errorMsg = "Sorry! you do not have permission perform this action";
        break;
      default:
        errorMsg = "Failed to add project budget. please try again.";
    }
    if (errorMsg) {
      createNotification("error", errorMsg, 4000);
    }
  }
  return {
    ...state,
    saveBudgetLoading: false,
    isBudgetAddOrUpdate: false,
  };
};

// Get Budget
export const getWorkOrderBudgetRequestHandler = (state, action) => {
  return {
    ...state,
    getBudgetLoading: true,
    workOrderBudget: null,
  };
};
export const getWorkOrderBudgetRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      getBudgetLoading: false,
      workOrderBudget: data.data,
    };
  }
  return {
    ...state,
    getBudgetLoading: false,
    workOrderBudget: null,
  };
};
export const getWorkOrderBudgetRequestFailureHandler = (state, action) => {
  return {
    ...state,
    getBudgetLoading: false,
    workOrderBudget: null,
  };
};

// Update Budget
export const updateWorkOrderBudgetRequestHandler = (state, action) => {
  return {
    ...state,
    saveBudgetLoading: true,
    isBudgetAddOrUpdate: false,
  };
};
export const updateWorkOrderBudgetRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data) {
    createNotification("success", "Work Order budget updated successfully!", 4000);
    return {
      ...state,
      saveBudgetLoading: false,
      isBudgetAddOrUpdate: true,
      workOrderBudget: data,
    };
  }
  return {
    ...state,
    saveBudgetLoading: false,
    isBudgetAddOrUpdate: false,
  };
};
export const updateWorkOrderBudgetRequestFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification("error", noInternetMsg, 3000);
  } else {
    let errorMsg;
    switch (response.status) {
      case 400:
        if (response.data.budget_amount) {
          errorMsg = response.data.budget_amount[0];
        } else if ((errorMsg = response.data.budget_hours)) {
          errorMsg = response.data.budget_hours[0];
        } else {
          errorMsg = "Failed to update work order budget. please try again.";
        }
        break;
      case 403:
        errorMsg = "Sorry! you do not have permission perform this action";
        break;
      default:
        errorMsg = "Failed to update project budget. please try again.";
    }
    if (errorMsg) {
      createNotification("error", errorMsg, 4000);
    }
  }
  return {
    ...state,
    saveBudgetLoading: false,
    isBudgetAddOrUpdate: false,
  };
};

export const clearWorkOrderBudgetRequestHandler = (state, action) => {

  return {
    ...state,
    workOrderBudget: null,
  };
};
export const clearWorkOrderBillingRequestHandler = (state, action) => {

  return {
    ...state,
    WorkOrderBilling: null,
  };
};

export const clearWorkOrderIdRequestHandler = (state, action) => {

  return {
    ...state,
    createdWorkOrderId: null,
  };
};


//WorkOder Short List

export const workOrderShortListRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const workOrderShortListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      workOrderShortList: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    workOrderShortList: [],
  };
};
export const workOrderShortListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    workOrderShortList: [],
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  //Get Work Order
  [GET_WORK_ORDER]: getWorkOrderHandler,
  [GET_WORK_ORDER_SUCCESS]: getWorkOrderSuccessHandler,
  [GET_WORK_ORDER_FAILURE]: getWorkOrderFailureHandler,



  //Add Work Order
  [CREATE_WORK_ORDER]: createWorkOrderHandler,
  [CREATE_WORK_ORDER_SUCCESS]: createWorkOrderSuccessHandler,
  [CREATE_WORK_ORDER_FAILURE]: createWorkOrderFailureHandler,

  //Clear WorkOrder Id
  [CLEAR_WORK_ORDER_ID]: clearWorkOrderIdRequestHandler,

  //Billing
  [CREATE_WORK_ORDER_BILL]: addWorkOrderBillingRequestHandler,
  [CREATE_WORK_ORDER_BILL_SUCCESS]: addWorkOrderBillingRequestSuccessHandler,
  [CREATE_WORK_ORDER_BILL_FAILURE]: addworkOrderBillingRequestFailureHandler,

  [GET_WORK_ORDER_BILL]: getWorkOrderBillingRequestHandler,
  [GET_WORK_ORDER_BILL_SUCCESS]: getWorkOrderBillingRequestSuccessHandler,
  [GET_WORK_ORDER_BILL_FAILURE]: getWorkOrderBillingRequestFailureHandler,

  [UPDATE_WORK_ORDER_BILL]: updateWorkOrderBillingRequestHandler,
  [UPDATE_WORK_ORDER_BILL_SUCCESS]: updateWorkOrderBillingRequestSuccessHandler,
  [UPDATE_WORK_ORDER_BILL_FAILURE]: updateWorkOrderBillingRequestFailureHandler,
    
  [CLEAR_WORK_ORDER_BILLING]: clearWorkOrderBillingRequestHandler,
  

  //Budgeting
  [CREATE_WORK_ORDER_BUDGET]: addWorkOrderBudgetRequestHandler,
  [CREATE_WORK_ORDER_BUDGET_SUCCESS]: addWorkOrderBudgetRequestSuccessHandler,
  [CREATE_WORK_ORDER_BUDGET_FAILURE]: addWorkOrderBudgetRequestFailureHandler,

  [GET_WORK_ORDER_BUDGET]: getWorkOrderBudgetRequestHandler,
  [GET_WORK_ORDER_BUDGET_SUCCESS]: getWorkOrderBudgetRequestSuccessHandler,
  [GET_WORK_ORDER_BUDGET_FAILURE]: getWorkOrderBudgetRequestFailureHandler,

  [UPDATE_WORK_ORDER_BUDGET]: updateWorkOrderBudgetRequestHandler,
  [UPDATE_WORK_ORDER_BUDGET_SUCCESS]: updateWorkOrderBudgetRequestSuccessHandler,
  [UPDATE_WORK_ORDER_BUDGET_FAILURE]: updateWorkOrderBudgetRequestFailureHandler,

  [CLEAR_WORK_ORDER_BUDGET]: clearWorkOrderBudgetRequestHandler,

  [WORK_ORDER_LIST]: getWorkOrderListHandler,
  [WORK_ORDER_LIST_SUCCESS]: getWorkOrderListSuccessHandler,
  [WORK_ORDER_LIST_FAILURE]: getWorkOrderListFailureHandler,

  [WORK_ORDER_SHORT]: workOrderShortListRequestHandler,
  [WORK_ORDER_SHORT_SUCCESS]: workOrderShortListRequestSuccessHandler,
  [WORK_ORDER_SHORT_FAILURE]: workOrderShortListRequestFailureHandler,

  [UPDATE_WORK_ORDER]: updateWorkOrderRequest,
  [UPDATE_WORK_ORDER_SUCCESS]: updateWorkOrderRequestSuccess,
  [UPDATE_WORK_ORDER_FAILURE]: updateWorkOrderRequestFailure,

  [DELETE_WORK_ORDER]: deleteWorkOrderHandler,
  [DELETE_WORK_ORDER_SUCCESS]: deleteWorkOrderSuccessHandler,
  [DELETE_WORK_ORDER_FAILURE]: deleteWorkOrderFailureHandler,

  [DUPLICATE_WORK_ORDER]: duplicateWorkOrderHandler,
  [DUPLICATE_WORK_ORDER_SUCCESS]: duplicateWorkOrderSuccessHandler,
  [DUPLICATE_WORK_ORDER_FAILURE]: duplicateWorkOrderFailureHandler,

  [GET_WORKORDER_BUDGET_AND_BILLABLE]:
    getWorkOrderBudgetAndBillableRequestHandler,
  [GET_WORKORDER_BUDGET_AND_BILLABLE_SUCCESS]:
    getWorkOrderBudgetAndBillableRequestSuccessHandler,
  [GET_WORKORDER_BUDGET_AND_BILLABLE_FAILURE]:
    getWorkOrderBudgetAndBillableRequestFailureHandler,

  //Create Job
  [CREATE_JOB]: createJobHandler,
  [CREATE_JOB_SUCCESS]: createJobSuccessHandler,
  [CREATE_JOB_FAILURE]: createJobFailureHandler,

  //delete work-order job
  [DELETE_WORK_ORDER_JOB]: deleteWorkOrderJobHandler,
  [DELETE_WORK_ORDER_JOB_SUCCESS]: deleteWorkOrderJobSuccessHandler,
  [DELETE_WORK_ORDER_JOB_FAILURE]: deleteWorkOrderJobFailureHandler,

  //Update Job
  [UPDATE_JOB]: updateJobRequest,
  [UPDATE_JOB_SUCCESS]: updateJobRequestSuccess,
  [UPDATE_JOB_FAILURE]: updateJobRequestFailure,

  //Jobs Under one WorkOrder

  [GET_JOBS_UNDER_WORKORDER]: getJobsUnderWorkOrderHandler,
  [GET_JOBS_UNDER_WORKORDER_SUCCESS]: getJobsUnderWorkOderSuccessHandler,
  [GET_JOBS_UNDER_WORKORDER_FAILURE]: getJobsUnderWorkOrderFailureHandler,
};

// default initial state
const initialState = {
  // loading
  isLoading: false,
  createWorkOrderIsLoading: false,
  UpdateWorkOrderLoading: false,
  createOrUpdateJobIsLoading: false,
  workOrderJobDeleteIsLoading: false,

  createdWorkOrderId: null,
  createdJobId: null,
  createdJob: false,
  deleteJobCallback: false,

  //Jobs Under One WorkOrder
  getJobsUnderWorkOrderIsLoading: false,
  jobUpdate: false,
  jobsUnderWorkOrderList: [],
  totalJobsCount: null,
  pageSizeJobs: null,

  // WorkOrder List
  getWorkOrderListIsLoading: false,
  WorkOrderList: [],
  workOrderShortList: [],
  pageSizeWorkOrder: null,
  totalWorkOrderCount: null,

  //WorkOrder Details
  workOrderDetails: null,
  getWorkOrderIsLoading: false,

  //WorkOrder List
  workOrderDeleteLoading: false,

  getWorkOrderDuplicateListIsLoading: false,
  duplicateWorkOrderId: null,

  //Billing and budgeting
  workOrderBudgetAndBillableIsLoading: false,
  workOrderBudgetAndBillable: null,

  //billing
  saveBillingLoading: false,
  WorkOrderBilling:null,
  getBillingLoading: false,
  isBillAddOrUpdate: false,

  //Budget
  saveBudgetLoading: false,
  workOrderBudget: null,
  getBudgetLoading: false,
  isBudgetAddOrUpdate: false,
};

export default function workOrderReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
