import { forwardRef, useEffect, useState } from 'react';
import useDynamicRefs from 'use-dynamic-refs';

import crossIcon from '../../../../assets/img/icons/cross_black.svg';
import plusIcon from '../../../../assets/img/icons/plus-2.svg';
import nextIcon from '../../../../assets/img/onboardingImg/right_arrow_green.svg';
import ButtonLoader from '../../../../components/DropdownLoader/DropdownLoader';
import Select from '../../../../components/ReactSelectDropdown';
import { PrimaryButton } from '../../../../styledComponents/buttons';
import { validateEmail } from '../../../../utils/helper';
import {
  AddMoreProjectsDiv,
  AddMoreProjectsText,
  InputCrossIcon,
  InputCrossIconDiv,
  InviteMembersContainer,
  OnboardingForm,
  OnboardingFormAlert,
  OnboardingInput,
  OnboardingInputContainer,
  OnboardingInputLabel,
  OnboardingInputWithCrossButton,
  PlusIcon,
} from '../onboardingStepsStyles';
import PreviousButton from './PreviousButton';
import TimezoneDropdownStyle from './timezoneDropdownStyle';

const InviteMembers = forwardRef((props, ref) => {
  const [saveErrors, setSaveErrors] = useState({});
  const [projectList, setProjectList] = useState([]);
  const [emails, setEmails] = useState([
    { value: '', error: false, project_names: [] },
  ]);

  const [getRef, setRef] = useDynamicRefs();

  // useEffect(() => {
  //   const projects = [];
  //   if (props.projects && props.projects.length > 0) {
  //     props.projects.map((item, index) => {
  //       projects.push({
  //         label: item.name,
  //         value: index,
  //       });
  //     });
  //   }
  //   setProjectList(projects);
  // }, [props.projects]);

  const handleEmailChange = (i, event) => {
    const emailValues = [...emails];
    const { value } = event.target;
    setSaveErrors({});
    if (value === '' || value === null) {
      emailValues[i].error = true;
    } else if (!validateEmail(value)) {
      emailValues[i].error = true;
    } else {
      emailValues[i].error = false;
    }
    emailValues[i].value = value;
    setEmails(emailValues);
  };

  // const handleInviteeProjects = (i, event) => {
  //   const emailValues = [...emails];
  //   emailValues[i].project_names = event;
  //   setEmails(emailValues);
  // };

  const handleEmailAdd = () => {
    const emailValues = [...emails];
    let invalidEmail = false;
    setSaveErrors({});
    // eslint-disable-next-line
    emailValues.map(email => {
      if (!email.value || email.error) {
        invalidEmail = true;
      }
    });
    if (invalidEmail) {
      setSaveErrors({
        email: 'Invalid or empty emails exist. Please discard them first.',
      });
    } else {
      emailValues.push({ value: '', error: false, project_names: [] });
      setEmails(emailValues);
      setTimeout(() => {
        if (
          getRef(`emailInput-${emails.length}`) &&
          getRef(`emailInput-${emails.length}`).current
        ) {
          getRef(`emailInput-${emails.length}`).current.focus();
        }
      }, 100);
    }
  };

  const handleEmailRemove = i => {
    const emailValues = [...emails];
    setSaveErrors({});
    if (emailValues.length > 1) {
      emailValues.splice(i, 1);
      setEmails(emailValues);
    }
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!props.organization) {
      // props.handleBack('project');
      props.handleBack('invite-member');
      return false;
    }
    // if (!props.projects || !props.projects.length) {
    //   props.handleBack('pricing');
    //   return false;
    // } else {
    const emailValues = [...emails];
    let invalidEmail = false;
    if (emailValues && emailValues.length > 0) {
      emailValues.map((email, index) => {
        if (emailValues.length - 1 === index && emails.length > 1) {
          if (email.error) {
            invalidEmail = true;
          }
        } else if (email.value === null || email.error) {
          invalidEmail = true;
        }
      });
    }
    if (invalidEmail) {
      saveErrors['email'] =
        'Invalid of empty email exists. Please correct them or discard them first.';
      formIsValid = false;
    }
    // }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const handleNext = e => {
    e.preventDefault();
    if (checkError()) {
      const invitations = [];
      if (emails && emails.length > 0) {
        emails.map(email => {
          if (email.value) {
            let item = { email: email.value, projects: [] };
            // if (email.project_names && email.project_names.length > 0) {
            //   email.project_names.map((project) => {
            //     item.projects.push(project.label);
            //   });
            // }
            invitations.push(item);
          }
        });
      }
      props.handleSubmitData(invitations);
    }
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      handleNext();
    }
  };

  return (
    <InviteMembersContainer ref={ref}>
      <PreviousButton handleClick={props.handleBack} type="invite-member" />
      <OnboardingForm onKeyDown={handleKeyPress}>
        <OnboardingInputContainer>
          <OnboardingInputLabel>
            Invite members to work in your team
          </OnboardingInputLabel>
          {emails &&
            emails.length > 0 &&
            emails.map((email, index) => (
              <OnboardingInputWithCrossButton key={index}>
                <div style={{ width: '85%', marginTop: '10px' }}>
                  <OnboardingInput
                    type="email"
                    ref={setRef(`emailInput-${index}`)}
                    alert={email.error}
                    value={email.value}
                    placeholder="Email address"
                    onChange={e => handleEmailChange(index, e)}
                    style={{ width: '100%' }}
                  />
                  {/* <Select
                    name="project"
                    placeholder="Select project..."
                    isMulti
                    value={email.project_names}
                    options={projectList || []}
                    onChange={(e) => handleInviteeProjects(index, e)}
                    styles={TimezoneDropdownStyle()}
                  /> */}
                </div>
                {emails.length > 1 && (
                  <InputCrossIconDiv onClick={() => handleEmailRemove(index)}>
                    <InputCrossIcon src={crossIcon} alt="" />
                  </InputCrossIconDiv>
                )}
              </OnboardingInputWithCrossButton>
            ))}
          {saveErrors &&
            Object.keys(saveErrors).length !== 0 &&
            saveErrors.email && (
              <OnboardingFormAlert>{saveErrors.email}</OnboardingFormAlert>
            )}
        </OnboardingInputContainer>
        {!emails ||
          (emails && emails.length < 3 && (
            <AddMoreProjectsDiv onClick={() => handleEmailAdd()}>
              <PlusIcon src={plusIcon} alt="" />
              <AddMoreProjectsText>Invite More Members</AddMoreProjectsText>
            </AddMoreProjectsDiv>
          ))}
        <PrimaryButton
          height="50px"
          fontSize="16px"
          padding={props.isLoading ? '0 75px' : '0 50px'}
          style={{ marginTop: '40px' }}
          onClick={e => handleNext(e)}
        >
          {props.isLoading ? (
            <ButtonLoader color="#fff" loading={props.isLoading} />
          ) : (
            `Get Started`
          )}
        </PrimaryButton>
      </OnboardingForm>
    </InviteMembersContainer>
  );
});

export default InviteMembers;
