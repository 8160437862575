import { connect } from 'react-redux';

import {
  getOfficeDetails,
  getOfficesShortList,
} from '../../OfficesNew/officesActions';
import {
  getOrganizationMembersList,
  getOrganizationMembersShortList,
} from '../../Projects/projectsActions';
// Actions
import {
  clearTimesheetList,
  exportMonthlyTimeSheet,
  getDailyTimesheets,
  getOtherTimesheets,
} from '../timesheetActions';
// Component
import TimesheetMonthly from './TimesheetMonthly';

// Store props to map with current state
const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,
  isLoading: state.timesheet.isLoading,
  dailyTimesheetLoading: state.timesheet.dailyTimesheetLoading,
  dailyTimesheets: state.timesheet.dailyList,
  timesheetsList: state.timesheet.list,
  profileData: state.profile.profileData,
  //member short list
  orgMembersShortList: state.project.orgMembersShortList,
  memberShortListIsLoading: state.project.memberShortListIsLoading,
  //office
  officesShortList: state.office.officesShortList,
  officeDetails: state.office.officeDetails,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getDailyTimesheets: payload => dispatch(getDailyTimesheets(payload)),
  getOtherTimesheets: payload => dispatch(getOtherTimesheets(payload)),
  exportMonthlyTimeSheet: payload => dispatch(exportMonthlyTimeSheet(payload)),
  getOfficesShortList: payload => dispatch(getOfficesShortList(payload)),
  getOrganizationMembersList: payload =>
    dispatch(getOrganizationMembersList(payload)),
  clearTimesheetList: () => dispatch(clearTimesheetList()),
  getOrganizationMembersShortList: params =>
    dispatch(getOrganizationMembersShortList(params)),
  getOfficeDetails: payload => dispatch(getOfficeDetails(payload)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(TimesheetMonthly);
