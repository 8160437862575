import styled from "styled-components";

export const TeamEditIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: ${({ size }) => size || `40px`};
  height: ${({ size }) => size || `40px`};

  cursor: pointer;
`;

export const TabAndSearchRow = styled.div`
  display: grid;
  grid-template-columns: auto 270px;
  grid-column-gap: 20px;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 30px;
`;

export const TeamMembersContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 220px);
  grid-gap: 15px;
  padding: 0 ${props => (props.paddingLeft !== undefined ? props.paddingLeft : '30px')};
`;
export const TeamMemberCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => padding || "15px"};
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.main.whiteBg};
  min-height: 210px;
  justify-content: center;
  align-items: center;
`;

export const TeamProjectsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  padding: 0;
`;
export const TeamProjectsTableRow = styled.div`
  display: grid;
  grid-template-columns: 25% 10% 100px 140px;
  grid-column-gap: 30px;
  justify-content: start;
  padding: 0 30px;
  :nth-of-type(even) {
    background-color: #f8f9fc;
  }
  @media (max-width: 1250px) {
    grid-column-gap: 10px;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 200px 100px 120px 140px;
  }
`;
