//Effects
import { all, call, put, takeLatest } from 'redux-saga/effects';

//Constants
import {
  ADD_CLIENT_LOCATION,
  ADD_CLIENT_LOCATION_FAILURE,
  ADD_CLIENT_LOCATION_SUCCESS,
  CREATE_CLIENT,
  CREATE_CLIENT_FAILURE,
  CREATE_CLIENT_SUCCESS,
  CREATE_CUSTOMER_BULK,
  CREATE_CUSTOMER_BULK_FAILURE,
  CREATE_CUSTOMER_BULK_SUCCESS,
  DELETE_CLIENT,
  DELETE_CLIENT_FAILURE,
  DELETE_CLIENT_LOCATION,
  DELETE_CLIENT_LOCATION_FAILURE,
  DELETE_CLIENT_LOCATION_SUCCESS,
  DELETE_CLIENT_SUCCESS,
  GET_CLIENTS_LIST,
  GET_CLIENTS_LIST_FAILURE,
  GET_CLIENTS_LIST_SUCCESS,
  GET_CLIENTS_SHORT_LIST,
  GET_CLIENTS_SHORT_LIST_FAILURE,
  GET_CLIENTS_SHORT_LIST_SUCCESS,
  GET_CLIENT_DETAILS,
  GET_CLIENT_DETAILS_FAILURE,
  GET_CLIENT_DETAILS_SUCCESS,
  GET_CLIENT_LOCATIONS,
  GET_CLIENT_LOCATIONS_FAILURE,
  GET_CLIENT_LOCATIONS_SUCCESS,
  GET_GHL_CONTACT_LIST,
  GET_GHL_CONTACT_LIST_FAILURE,
  GET_GHL_CONTACT_LIST_SUCCESS,
  UPDATE_CLIENT,
  UPDATE_CLIENT_FAILURE,
  UPDATE_CLIENT_LOCATION,
  UPDATE_CLIENT_LOCATION_FAILURE,
  UPDATE_CLIENT_LOCATION_SUCCESS,
  UPDATE_CLIENT_SUCCESS,
} from '../../modules/constants';
//Service
import { defaultApi } from '../../utils/axiosApi';

export function* getClientList({ payload }) {
  try {
    const { organization_id, page, name } = payload;
    // let url = `${organization_id}/clients/`;
    let url = `${organization_id}/clients/${page ? `?page=${page}` : ''}${
      name ? `&name=${name}` : ''
    }`;
    const response = yield defaultApi(url, 'GET');
    yield put({
      type: GET_CLIENTS_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_CLIENTS_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* getGHLContactList({ payload }) {
  const { organization_id } = payload;
  try {
    const url = `/authentication/${organization_id}/ghl-contacts/`;
    const method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_GHL_CONTACT_LIST_SUCCESS,
      payload: { data: response, notify: 'GET' },
    });
  } catch (err) {
    yield put({
      type: GET_GHL_CONTACT_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* getClientShortList({ payload }) {
  try {
    const { organization_id } = payload;
    let url = `${organization_id}/clients/short/`;
    // let url = `${organization_id}/clients/${page? `?page=${page}`:''}${name ? `&name=${name}` : ''}`;
    const response = yield defaultApi(url, 'GET');

    yield put({
      type: GET_CLIENTS_SHORT_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_CLIENTS_SHORT_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* getClientDetails({ payload }) {
  try {
    const { organization_id, client_id } = payload;
    let url = `${organization_id}/clients/${client_id}/`;
    let method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_CLIENT_DETAILS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_CLIENT_DETAILS_FAILURE,
      payload: err,
    });
  }
}

export function* handleCreateClient({ payload }) {
  try {
    const { organization_id, ...details } = payload;
    let url = `${organization_id}/clients/`;
    let method = 'POST';
    const response = yield defaultApi(url, method, details);
    yield put({
      type: CREATE_CLIENT_SUCCESS,
      payload: { data: response, notify: 'POST' },
    });
  } catch (err) {
    yield put({
      type: CREATE_CLIENT_FAILURE,
      payload: err,
    });
  }
}

export function* handleCreateCustomerBulk({ payload }) {
  try {
    const { organization_id, file, ...details } = payload; // Extract file from payload
    let formData = new FormData();
    formData.append('file', file); // Append the file data to FormData
    // Append other details if needed
    // for (const key in details) {
    //   formData.append(key, details[key]);
    // }

    let url = `${organization_id}/clients/file_bulk_create/`;
    let method = 'POST';
    // Send FormData instead of regular JSON payload
    const response = yield call(defaultApi, url, method, formData);
    yield call(getClientList, { payload });
    yield put({
      type: CREATE_CUSTOMER_BULK_SUCCESS,
      payload: { data: response, notify: 'POST' },
    });
  } catch (err) {
    yield put({
      type: CREATE_CUSTOMER_BULK_FAILURE,
      payload: err,
    });
  }
}

export function* handleUpdateClient({ payload }) {
  try {
    const { organization_id, client_id, getList, ...details } = payload;
    let url = `${organization_id}/clients/${client_id}/`;
    let method = 'PATCH';
    const response = yield defaultApi(url, method, details);
    if (getList) {
      yield call(getClientList, { payload });
    } else {
      yield call(getClientDetails, { payload });
    }
    yield put({
      type: UPDATE_CLIENT_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: UPDATE_CLIENT_FAILURE,
      payload: err,
    });
  }
}

export function* handleDeleteClient({ payload }) {
  try {
    const { organization_id, client_id } = payload;
    let url = `${organization_id}/clients/${client_id}/`;
    let method = 'DELETE';
    const response = yield defaultApi(url, method);
    yield put({
      type: DELETE_CLIENT_SUCCESS,
      payload: { data: response, client_id, notify: method },
    });
  } catch (err) {
    yield put({
      type: DELETE_CLIENT_FAILURE,
      payload: err,
    });
  }
}

export function* handleAddClientLocation({ payload }) {
  try {
    const { organizationID, ...newPayload } = payload;
    const url = `${organizationID}/clients/locations/`;
    const method = 'POST';
    const response = yield defaultApi(url, method, newPayload);

    yield put({
      type: ADD_CLIENT_LOCATION_SUCCESS,
      payload: { data: response, notify: 'POST' },
    });
  } catch (err) {
    yield put({
      type: ADD_CLIENT_LOCATION_FAILURE,
      payload: err,
    });
  }
}

export function* handleGetClientLocations({ payload }) {
  try {
    const { organization_id, client_id } = payload;
    const url = `${organization_id}/clients/locations/${
      client_id ? `?client_id=${client_id}` : ''
    }`;

    // const url = `/client_locations/${client_id ? `?client_id=${client_id}` : ''}`;
    const response = yield defaultApi(url, 'GET');

    yield put({
      type: GET_CLIENT_LOCATIONS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_CLIENT_LOCATIONS_FAILURE,
      payload: err,
    });
  }
}

export function* handleUpdateClientLocation({ payload }) {
  try {
    const { organizationID, ...newPayload } = payload;
    const { id, client } = payload;
    const url = `${organizationID}/clients/locations/${id}/${
      client ? `?client_id=${client}` : ''
    }`;
    let method = 'PATCH';
    const response = yield defaultApi(url, method, newPayload);

    yield put({
      type: UPDATE_CLIENT_LOCATION_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: UPDATE_CLIENT_LOCATION_FAILURE,
      payload: err,
    });
  }
}

export function* handleDeleteClientlocation({ payload }) {
  try {
    const { organization_id, client_id, location_id } = payload;
    let url = `${organization_id}/clients/locations/${location_id}/${
      client_id ? `?client_id=${client_id}` : ''
    }`;
    let method = 'DELETE';
    const response = yield defaultApi(url, method);
    yield put({
      type: DELETE_CLIENT_LOCATION_SUCCESS,
      payload: { data: response, location_id, notify: method },
    });
  } catch (err) {
    yield put({
      type: DELETE_CLIENT_LOCATION_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_CLIENTS_LIST, getClientList),
    takeLatest(GET_GHL_CONTACT_LIST, getGHLContactList),
    takeLatest(GET_CLIENTS_SHORT_LIST, getClientShortList),
    takeLatest(GET_CLIENT_DETAILS, getClientDetails),
    takeLatest(CREATE_CLIENT, handleCreateClient),
    takeLatest(CREATE_CUSTOMER_BULK, handleCreateCustomerBulk),
    takeLatest(UPDATE_CLIENT, handleUpdateClient),
    takeLatest(DELETE_CLIENT, handleDeleteClient),
    takeLatest(ADD_CLIENT_LOCATION, handleAddClientLocation),
    takeLatest(GET_CLIENT_LOCATIONS, handleGetClientLocations),
    takeLatest(UPDATE_CLIENT_LOCATION, handleUpdateClientLocation),
    takeLatest(DELETE_CLIENT_LOCATION, handleDeleteClientlocation),
  ]);
}
