import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import { CommonGrid, CommonText } from '../../styledComponents/common';
import {
  FragmentContainerTopWorkOrder,
  SmallCardContentJobToday,
  SmallCardLabel,
  Value,
} from '../../styledComponents/dashboard';
import {
  DataDiv,
  DataDivHeaderWrapper,
  SmallCardContainer,
} from './cardStyles';

const TopWorkOrdersCard = ({ workOrdersSummary, isLoading }) => {
  const formatHoursAndMinutes = (hours) => {
    const totalMinutes = Math.round(hours * 60);
    const formattedHours = Math.floor(totalMinutes / 60);
    const formattedMinutes = totalMinutes % 60;
    return `${formattedHours}h ${formattedMinutes}m`;
};
  return (
    <SmallCardContainer laptopGridColumn="span 1" tabletGridColumn="span 1">
      {isLoading ? (
        <ComponentCircleLoader padding="7vh" />
      ) : (
        <>
          {workOrdersSummary ? (
            <>
              <DataDiv>
                <DataDivHeaderWrapper>
                  <SmallCardLabel fontSize="16px" style={{ fontWeight: '600' }}>
                    Top 4 Workorders by Hours
                  </SmallCardLabel>
                </DataDivHeaderWrapper>
                <SmallCardContentJobToday style={{ marginTop: '20px' }}>
                  {/* {workOrdersSummary.map((item, index) => (
                    <FragmentContainerTopWorkOrder key={index} index={index}>
                      <span style={{paddingLeft: '15px'}}>{item.work_order_name}</span>
                      <Value index={index}>{item.work_order_hour.toFixed(2)}</Value>
                    </FragmentContainerTopWorkOrder>
                  ))} */}
                  {Array.from({ length: 4 }).map((_, index) => {
                    const item = workOrdersSummary[index];
                    return (
                      <FragmentContainerTopWorkOrder
                        key={index}
                        index={index}
                        workOrdersSummary={workOrdersSummary}
                      >
                        <span style={{ paddingLeft: '15px' }}>
                          {item ? item.work_order_name : ''}
                        </span>
                        {item && (
                          <Value index={index}>
                            {formatHoursAndMinutes(item.work_order_hour)}
                          </Value>
                        )}
                      </FragmentContainerTopWorkOrder>
                    );
                  })}
                </SmallCardContentJobToday>
              </DataDiv>
            </>
          ) : null}
        </>
      )}
    </SmallCardContainer>
  );
};

export default TopWorkOrdersCard;
