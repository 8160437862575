import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import { CommonGrid, CommonText } from '../../styledComponents/common';
import {
  FragmentContainer,
  SmallCardContentJobToday,
  SmallCardLabel,
  Value,
} from '../../styledComponents/dashboard';
import {
  DataDiv,
  DataDivHeaderWrapper,
  SmallCardContainer,
} from './cardStyles';

const JobTodayCard = ({ jobTodaySummary, isLoading }) => {
  return (
    <SmallCardContainer laptopGridColumn="span 1" tabletGridColumn="span 1">
      {isLoading ? (
        <ComponentCircleLoader padding="7vh" />
      ) : (
        <>
          {jobTodaySummary ? (
            <>
              <DataDiv>
                <DataDivHeaderWrapper>
                  <SmallCardLabel fontSize="16px" style={{ fontWeight: '600' }}>
                    Today
                  </SmallCardLabel>
                </DataDivHeaderWrapper>
                <SmallCardContentJobToday style={{ marginTop: '20px' }}>
                  {/* {Object.entries(jobTodaySummary).map(
                    ([label, value], index) => (
                      <FragmentContainer key={index} index={index}>
                        <span style={{ paddingLeft: '15px' }}>{label}</span>
                        <Value index={index}>
                          {value < 10 ? `0${value}` : value}
                        </Value>
                      </FragmentContainer>
                    ),
                  )} */}
                  <FragmentContainer index="0">
                    <span style={{ paddingLeft: '15px' }}>Jobs Done</span>
                    <Value>
                      {jobTodaySummary['completed'] < 10
                        ? `0${jobTodaySummary['completed']}`
                        : jobTodaySummary['completed']}
                    </Value>
                  </FragmentContainer>
                  <FragmentContainer index="1">
                    <span style={{ paddingLeft: '15px' }}>Jobs Canceled</span>
                    <Value>
                      {jobTodaySummary['cancelled'] < 10
                        ? `0${jobTodaySummary['cancelled']}`
                        : jobTodaySummary['cancelled']}
                    </Value>
                  </FragmentContainer>
                  <FragmentContainer index="2">
                    <span style={{ paddingLeft: '15px' }}>Jobs Created</span>
                    <Value>
                      {jobTodaySummary['created'] < 10
                        ? `0${jobTodaySummary['created']}`
                        : jobTodaySummary['created']}
                    </Value>
                  </FragmentContainer>
                  <FragmentContainer index="3">
                    <span style={{ paddingLeft: '15px' }}>
                      New Customer Created
                    </span>
                    <Value>
                      {jobTodaySummary['new_customers'] < 10
                        ? `0${jobTodaySummary['new_customers']}`
                        : jobTodaySummary['new_customers']}
                    </Value>
                  </FragmentContainer>
                </SmallCardContentJobToday>
              </DataDiv>
            </>
          ) : null}
        </>
      )}
    </SmallCardContainer>
  );
};

export default JobTodayCard;
