import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Avatar5 from '../../../assets/img/avatar_5.svg';
import crossIcon from '../../../assets/img/icons/cross_black.svg';
import PlusBlack from '../../../assets/img/icons/plus_black_2.svg';
import BackButtonComponent from '../../../components/BackButtonComponent/BackButtonComponent';
import DropdownLoader from '../../../components/DropdownLoader/DropdownLoader';
import ButtonTextLoader from '../../../components/Loaders/ButtonTextLoader';
import {
  ButtonIcon,
  PrimaryButton,
  WhiteButton,
  WhiteButtonWithIcon,
} from '../../../styledComponents/buttons';
import {
  GeneralInfoItem,
  GeneralInfoSection,
  HoveredSection,
  InnerDivTooltip,
  RoundHover,
  ToolTipContainer,
  TooltipText,
} from '../../../styledComponents/clients';
import {
  CardTitle,
  CommonFlex,
  CommonGrid,
  CommonText,
  Container,
  ContentHeader,
  FormAlert,
  InputField,
  InputLabel,
  InputSection,
  InputWithLabelSection,
  ManagerBadge,
  PageTitle,
} from '../../../styledComponents/common';
import { ButtonFlexContainer } from '../../../styledComponents/members';
import { RedStar } from '../../../styledComponents/members';
import {
  AddOfficeContainer,
  AssigneeSection,
  CrossIcon,
  NameRoleSection,
  OfficeMemberDivContainer,
  officeMemberAvatar,
  officeMemberDiv,
  officeMemberRole,
} from '../../../styledComponents/offices';
import { checkHtml, checkUrl, validateEmail } from '../../../utils/helper';
import StaticTaskLocationModal from '../../ClientsNew/ClientDetails/StaticTaskLocationModal';
import { DataAndMapContainer } from '../../FieldServices/JobSites/jobSitesStyles';
import { MapAndAlertContainer } from '../../FieldServices/RouteMap/routeMapStyles';
import { AddAssigneeDropdown } from '../AddAssigneeDropdown';
import AddLocationModal from '../AddLocationModal';
import OfficeMembers from './OfficeMembers';

const AddOffice = props => {
  const [officeName, setOfficeName] = useState('');
  const [assignees, setAssignees] = useState([]);
  const [visibility, setVisibility] = useState(0);
  const [saveErrors, setSaveErrors] = useState({});

  const [popupOpen, setPopupOpen] = useState(false);
  const [action, setAction] = useState('add');
  const { t } = useTranslation();
  const [locationInfo, setLocationInfo] = useState(null);
  const [chnagedOfficeName, setChnagedOfficeName] = useState(false);
  const [staticLocationModalOpen, setStaticLocationModalOpen] = useState(false);
  const [selectedStaticTaskLocation, setSelectedStaticTaskLocation] =
    useState(null);

  const clearState = () => {
    props.clearCreatedOfficeId();
    setOfficeName('');
    setAssignees([]);
    setVisibility(0);
    setAction('add');
    setLocationInfo(null);
    setChnagedOfficeName(false);
    setStaticLocationModalOpen(false);
  };

  const back = () => {
    clearState();
    props.history.push('/user/offices');
  };

  const onOfficeNameChange = e => {
    const { value } = e.target;
    setChnagedOfficeName(true);
    setOfficeName(value);

    if (saveErrors && saveErrors['officeName']) {
      delete saveErrors['officetName'];
    }
  };

  useEffect(() => {
    if (props.officeLocation && props.officeLocation[0]) {
      setLocationInfo(props.officeLocation[0]);
    } else if (props.officeLocation !== null) {
      setLocationInfo(props.officeLocation);
    }
  }, [props.officeLocation, props.officeLocationCreateUpdateSuccess]);

  const cancelFunction = () => {
    props.history.push({
      pathname: '/user/offices',
      state: { from: 'create' },
    });
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!officeName.trim()) {
      formIsValid = false;
      saveErrors['officeName'] = t('please_enter_office_name');
    } else if (checkUrl(officeName)) {
      formIsValid = false;
      saveErrors['officeName'] = t('office_name_can_not_contain_url');
    } else if (checkHtml(officeName)) {
      formIsValid = false;
      saveErrors['officeName'] = t('invalid_office_name');
    } else if (officeName.length > 256) {
      formIsValid = false;
      saveErrors['officeName'] = t('please_enter_office_name_within_256_characters');
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  useEffect(() => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      let payload = {
        organization_id: props.selectedOrganization.id,
      };
      props.getOrganizationMembersShortList(payload);
    }
  }, [props.selectedOrganization]);

  useEffect(() => {
    if (props.officeMembersList && props.officeMembersList.length > 0) {
      let membersList = [];
      props.officeMembersList.map(member => {
        membersList.push({
          id: member.id,
          userId: member.user.id,
          name: `${member.user.first_name}${
            member.user.last_name ? ` ${member.user.last_name}` : ``
          }`,
          email: member.user.email,
          manageTeam: member.can_manage
            ? { value: 'manager', label: 'Team Manager' }
            : { value: 'member', label: 'Team Member' },
          avatar: member.user.avatar ? member.user.avatar : Avatar5,
          can_manage: member.can_manage,
        });
      });
      setAssignees(membersList);
    } else if (
      props.officeMembersList &&
      props.officeMembersList.length === 0
    ) {
      setAssignees([]);
    }
  }, [props.officeMembersList]);

  useEffect(() => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      props.createdOfficeId
    ) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        office_id: props.createdOfficeId,
      };
      props.getOfficeDetails(payload);
    }
  }, [props.selectedOrganization, props.createdOfficeId]);

  const updateAssingeeList = person => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      props.createdOfficeId
    ) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        office_id: props.createdOfficeId,
        user_id: person.id,
      };
      props.addOfficeMember(payload);
    }
  };

  const handleDeleteMember = assigneeId => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      props.createdOfficeId &&
      assigneeId
    ) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        office_id: props.createdOfficeId,
        user_id: assigneeId,
      };
      props.removeOfficeMember(payload);
    }
  };

  const handleSave = () => {
    if (checkError()) {
      if (props.selectedOrganization && props.selectedOrganization.id) {
        let payload = {
          organization_id: props.selectedOrganization.id,
          name: officeName,
        };
        props.createOffice(payload);

        // setTimeout(() => {
        //   props.history.push({
        //     pathname: "/user/offices",
        //     state: { from: "create" },
        //   });
        // }, 800);
      }
    }
  };

  const showAssignee = state => {
    setVisibility(state);
  };

  const handleUpdateOfficeMember = (canManage, memberId) => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      props.createdOfficeId &&
      memberId
    ) {
      const payload = {
        organization_id: props.selectedOrganization.id,
        office_id: props.createdOfficeId,
        member_id: memberId,
        can_manage: canManage,
      };
      props.updateOfficeMember(payload);
    }
  };

  const handleRemoveOfficeMember = memberId => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      props.createdOfficeId &&
      memberId
    ) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        office_id: props.createdOfficeId,
        user_id: memberId,
        // getDetails: true,
      };
      props.removeOfficeMember(payload);
    }
  };

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
    setAction('add');
  };

  const popupToggleUpdate = () => {
    setAction('update');
    setPopupOpen(!popupOpen);
  };


  const handleSaveNameUpdate = () => {
    if (chnagedOfficeName) {
      if (checkError()) {
        if (props.selectedOrganization && props.selectedOrganization.id) {
          let payload = {
            organizationID: props.selectedOrganization.id,
            office_id: props.createdOfficeId,
            name: officeName,
          };
          props.updateOfficeInfo(payload);
          clearState();
          setTimeout(() => {
            props.history.push({
              pathname: '/user/offices',
              // state: { from: "create" },
            });
          }, 600);
        }
      }
    }
    else{
      back();
    }
    
  };

  const handleShowLocation = () => {
    setTimeout(() => {
      setStaticLocationModalOpen(true);
    }, 300);
  };

  return (
    <div className="content">
      <StaticTaskLocationModal
        isOpen={staticLocationModalOpen}
        toggle={() => {
          setStaticLocationModalOpen(false);
          setSelectedStaticTaskLocation(null);
        }}
        taskLocation={locationInfo !== null ? locationInfo : ''}
      />
      <PageTitle>{t('offices')}</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => back()}
          subTitle="Back to all offces"
        />
      </ContentHeader>
      <Container style={{ padding: `30px` }}>
        <CardTitle>{t('add_office_details')}</CardTitle>

        <InputSection>
          <InputLabel>
            {t('office_name')} <RedStar>*</RedStar>
          </InputLabel>
          <InputField
            style={{ marginTop: '10px' }}
            width="25%"
            type="text"
            placeholder={t('type_office_name')}
            value={officeName}
            onChange={e => onOfficeNameChange(e)}
            error={
              saveErrors &&
              saveErrors['officeName'] &&
              saveErrors['officeName'] !== undefined
            }
          />
          {saveErrors &&
            saveErrors['officeName'] &&
            saveErrors['officeName'] !== undefined && (
              <FormAlert margin="0 0 0 5px">
                {saveErrors['officeName']}
              </FormAlert>
            )}
        </InputSection>

        <CommonFlex gap="15px" margin="20px 0 0 0">
          {/* <WhiteButton type="cancel" onClick={() => cancelFunction()}>
                Cancel
              </WhiteButton> */}

          {!props.createdOfficeId && (
            <>
              <PrimaryButton
                width="25%"
                padding={props.isLoading ? '0 15px' : '0 35px'}
                onClick={!props.isLoading ? () => handleSave() : ''}
              >
                {props.isLoading ? (
                  <ButtonTextLoader loadingText="Saving" fontSize="13px" />
                ) : (
                  t('create_office')
                )}
              </PrimaryButton>
            </>
          )}
        </CommonFlex>

        {props.createdOfficeId && (
          <>
            <AssigneeSection>
              <CommonText $label>{t('assignee')}</CommonText>

              <AddAssigneeDropdown
                isLoading={props.memberShortListIsLoading}
                assignees={assignees}
                visibilityChange={showAssignee}
                updateState={updateAssingeeList}
                membersList={props.orgMembersShortList}
                getMembersList={props.getOrganizationMembersShortList}
                selectedOrganization={props.selectedOrganization}
                assigneeRemove={handleDeleteMember}
                officeMemberIsLoading={props.officeMemberIsLoading}
                loadingMemberId={props.loadingMemberId}
                office_id={props.createdOfficeId}
              />
            </AssigneeSection>

            {assignees && assignees.length > 0 && (
              <div style={{ marginTop: '25px', marginBottom: '25px' }}>
                <CommonText $label margin="0 0 15px 0">
                  Office Members
                </CommonText>

                <OfficeMembers
                  selectedOrganization={props.selectedOrganization}
                  officeId={props.createdOfficeId}
                  officeMemberListLoading={props.officeMemberListLoading}
                  getOfficeMembersList={props.getOfficeMembersList}
                  membersList={props.officeMembersList}
                  updateMember={handleUpdateOfficeMember}
                  deleteMember={handleRemoveOfficeMember}
                  officeMemberIsLoading={props.officeMemberIsLoading}
                  loadingMemberId={props.loadingMemberId}
                  history={props.history}
                />
              </div>
            )}

            <CommonText margin="10px 0" $label>
              {t('location')}
            </CommonText>
            {props.officeLocation && props.officeLocation.length !== 0 ? (
              <>
                <GeneralInfoSection
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleShowLocation()}
                >
                  <GeneralInfoItem>
                    <CommonText title>
                      {locationInfo && locationInfo.address
                        ? locationInfo.address
                        : ''}
                    </CommonText>
                    <CommonText $label fontWeight="500">
                      {locationInfo && locationInfo.name
                        ? locationInfo.name
                        : ''}
                    </CommonText>
                  </GeneralInfoItem>
                </GeneralInfoSection>
                <ButtonFlexContainer>
                  <WhiteButtonWithIcon onClick={() => popupToggleUpdate()}>
                    <ButtonIcon src={PlusBlack} alt="" />
                    {t('change_location')}
                  </WhiteButtonWithIcon>
                </ButtonFlexContainer>
              </>
            ) : (
              <>
                <GeneralInfoSection>
                  <GeneralInfoItem>
                    <CommonText title>{t('no_address_found')}</CommonText>
                    <CommonText $label fontWeight="500">
                      {t('no_name_set')}
                    </CommonText>
                  </GeneralInfoItem>
                </GeneralInfoSection>
                <ButtonFlexContainer>
                  <WhiteButtonWithIcon onClick={() => popupToggle()}>
                    <ButtonIcon src={PlusBlack} alt="" />
                    {t('add_location')}
                  </WhiteButtonWithIcon>
                </ButtonFlexContainer>
              </>
            )}

            <div style={{ margin: '10px  0 0 0' }}>
              <PrimaryButton
                onClick={() => handleSaveNameUpdate()}
                padding="0 30px"
              >
                Save & Close
              </PrimaryButton>
            </div>
          </>
        )}

        {locationInfo && locationInfo.name ? (
          props.selectedOrganization && props.selectedOrganization.id ? (
            <AddLocationModal
              isOpen={popupOpen}
              toggle={popupToggleUpdate}
              addOfficeLocation={props.updateOfficeInfo}
              addOrUpdateOfficeLocalMapLoading={
                props.addOrUpdateOfficeLocalMapLoading
              }
              createdOfficeLocationInfo={props.officeLocation}
              officeLocationCreateUpdateSuccess={
                props.officeLocationCreateUpdateSuccess
              }
              organization_id={props.selectedOrganization.id}
              office_id={props.createdOfficeId}
              id={locationInfo.id}
              action={action}
              name={locationInfo.name}
              address={locationInfo.address}
              radius={locationInfo.radius}
              latitude={locationInfo.latitude}
              longitude={locationInfo.longitude}
            />
          ) : null
        ) : props.selectedOrganization && props.selectedOrganization.id ? (
          <AddLocationModal
            isOpen={popupOpen}
            toggle={popupToggle}
            addOfficeLocation={props.addOfficeLocation}
            addOrUpdateOfficeLocalMapLoading={
              props.addOrUpdateOfficeLocalMapLoading
            }
            createdOfficeLocationInfo={props.officeLocation}
            organization_id={props.selectedOrganization.id}
            office_id={props.createdOfficeId}
            action={action}
          />
        ) : null}
      </Container>
    </div>
  );
};

export default AddOffice;
