import {
  //Get project
  GET_PROJECT_LIST,
  GET_PROJECT_SHORT_LIST,
  GET_PROJECT_DETAILS,
  GET_PROJECT_EXPENSES,
  GET_PROJECT_YEARLY_EXPENSES,
  GET_PROJECT_BUDGET_AND_BILLABLE,
  //create
  CREATE_PROJECT_REQUEST,
  CLEAR_CREATED_PROJECT,
  DUPLICATE_PROJECT_REQUEST,
  UPDATE_PROJECT_REQUEST,
  CLEAR_EDITED_PROJECT,
  CLEAR_PROJECT_ACCESS_DENIED,
  GET_ORGANIZATION_MEMBERS_LIST,
  GET_ORGANIZATION_MEMBERS_SHORT_LIST,
  ADD_TEAM_TO_PROJECT,
  ADD_PROJECT_ASSIGNEE,
  UPDATE_PROJECT_ASSIGNEE,
  DELETE_PROJECT_ASSIGNEE,
  GET_PROJECT_ASSIGNEE_LIST,
  CLEAR_PROJECT_ASSIGNEE_LIST,
  GET_PROJECT_TASK_LIST,
  PROJECT_ADD_TASK,
  PROJECT_EDIT_TASK,
  PROJECT_DELETE_TASK,
  GET_TASK_ASSIGNEE_LIST,
  ADD_TASK_ASSIGNEE,
  DELETE_TASK_ASSIGNEE,
  // Delete Project
  DELETE_PROJECT_REQUEST,
  //Billing and Budget
  GET_PROJECT_BUDGET,
  ADD_PROJECT_BUDGET,
  UPDATE_PROJECT_BUDGET,
  CLEAR_PROJECT_BUDGET,
  GET_PROJECT_BILLING,
  ADD_PROJECT_BILLING,
  UPDATE_PROJECT_BILLING,
  CLEAR_PROJECT_BILLING,
  //invoices
  GET_PROJECT_INVOICES,
} from "../../modules/constants";

// ------------------------------------
// Actions
// ------------------------------------

export function getProjectList(value) {
  return {
    type: GET_PROJECT_LIST,
    payload: value,
  };
}

export function getProjectShortList(value) {
  return {
    type: GET_PROJECT_SHORT_LIST,
    payload: value,
  };
}

export function getProjectDetails(value) {
  return {
    type: GET_PROJECT_DETAILS,
    payload: value,
  };
}

export function getProjectBudgetAndBillable(value) {
  return {
    type: GET_PROJECT_BUDGET_AND_BILLABLE,
    payload: value,
  };
}
export function getProjectExpenses(value) {
  return {
    type: GET_PROJECT_EXPENSES,
    payload: value,
  };
}

export function getProjectYearlyExpenses(value) {
  return {
    type: GET_PROJECT_YEARLY_EXPENSES,
    payload: value,
  };
}

export function createProject(value) {
  return {
    type: CREATE_PROJECT_REQUEST,
    payload: value,
  };
}
export function clearCreatedProject() {
  return {
    type: CLEAR_CREATED_PROJECT,
  };
}

export function duplicateProject(value) {
  return {
    type: DUPLICATE_PROJECT_REQUEST,
    payload: value,
  };
}

export function updateProject(value) {
  return {
    type: UPDATE_PROJECT_REQUEST,
    payload: value,
  };
}

export function deleteProject(value) {
  return {
    type: DELETE_PROJECT_REQUEST,
    payload: value,
  };
}

export function clearEditedProject() {
  return {
    type: CLEAR_EDITED_PROJECT,
  };
}

export function clearProjectAccessDenied() {
  return {
    type: CLEAR_PROJECT_ACCESS_DENIED,
  };
}

export function getOrganizationMembersList(value) {
  return {
    type: GET_ORGANIZATION_MEMBERS_LIST,
    payload: value,
  };
}

export function getOrganizationMembersShortList(value) {
  return {
    type: GET_ORGANIZATION_MEMBERS_SHORT_LIST,
    payload: value,
  };
}

export function addTeamToProject(value) {
  return {
    type: ADD_TEAM_TO_PROJECT,
    payload: value,
  };
}

export function addProjectAssignee(value) {
  return {
    type: ADD_PROJECT_ASSIGNEE,
    payload: value,
  };
}

export function updateProjectAssignee(value) {
  return {
    type: UPDATE_PROJECT_ASSIGNEE,
    payload: value,
  };
}

export function deleteProjectAssignee(value) {
  return {
    type: DELETE_PROJECT_ASSIGNEE,
    payload: value,
  };
}

export function getProjectAssigneeList(value) {
  return {
    type: GET_PROJECT_ASSIGNEE_LIST,
    payload: value,
  };
}

export function clearProjectAssigneeList() {
  return {
    type: CLEAR_PROJECT_ASSIGNEE_LIST,
  };
}

export function getProjectTaskList(value) {
  return {
    type: GET_PROJECT_TASK_LIST,
    payload: value,
  };
}

export function projectAddTask(value) {
  return {
    type: PROJECT_ADD_TASK,
    payload: value,
  };
}

export function projectEditTask(value) {
  return {
    type: PROJECT_EDIT_TASK,
    payload: value,
  };
}

export function projectDeleteTask(value) {
  return {
    type: PROJECT_DELETE_TASK,
    payload: value,
  };
}

export function getTaskAssigneeList(value) {
  return {
    type: GET_TASK_ASSIGNEE_LIST,
    payload: value,
  };
}

export function addTaskAssignee(value) {
  return {
    type: ADD_TASK_ASSIGNEE,
    payload: value,
  };
}

export function deleteTaskAssignee(value) {
  return {
    type: DELETE_TASK_ASSIGNEE,
    payload: value,
  };
}

export function getProjectBudget(value) {
  return {
    type: GET_PROJECT_BUDGET,
    payload: value,
  };
}

export function addProjectBudget(value) {
  return {
    type: ADD_PROJECT_BUDGET,
    payload: value,
  };
}

export function updateProjectBudget(value) {
  return {
    type: UPDATE_PROJECT_BUDGET,
    payload: value,
  };
}

export function clearProjectBudget() {
  return {
    type: CLEAR_PROJECT_BUDGET,
  };
}

export function getProjectBilling(value) {
  return {
    type: GET_PROJECT_BILLING,
    payload: value,
  };
}

export function addProjectBilling(value) {
  return {
    type: ADD_PROJECT_BILLING,
    payload: value,
  };
}

export function updateProjectBilling(value) {
  return {
    type: UPDATE_PROJECT_BILLING,
    payload: value,
  };
}

export function clearProjectBilling() {
  return {
    type: CLEAR_PROJECT_BILLING,
  };
}

export function getProjectInvoices(value) {
  return {
    type: GET_PROJECT_INVOICES,
    payload: value,
  };
}
