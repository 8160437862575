import { connect } from 'react-redux';

import { getIntegrationsFeatures, updateIntegrationsFeatures } from '../integrationsActions';
import GHLSettings from './GHLSettings';

const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,
  isLoadingFeatures: state.integrations.isLoadingFeatures,
  featuresData: state.integrations.featuresData,
});

const mapDispatchToProps = dispatch => ({
  getIntegrationsFeatures: payload =>
    dispatch(getIntegrationsFeatures(payload)),
  updateIntegrationsFeatures: payload =>
    dispatch(updateIntegrationsFeatures(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GHLSettings);
