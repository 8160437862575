import {
  AuthPageLeftContainer,
  LogoImg,
  SloganText,
  AuthPageArtwork,
} from "../../styledComponents/authLayout";
import { CommonFlex, CommonImage } from "../../styledComponents/common";

import Logo from "../../assets/img/logo/logo.svg";
import AuthArtwork from "../../assets/img/common/auth_artwork.svg";
import AlbaitLogo from "../../assets/img/logo/albait-logo.png";

const AuthPageLeftSection = () => {
  return (
    <AuthPageLeftContainer>
      <CommonFlex gap="40px">
        <LogoImg src={Logo} alt="" />
        {/* <CommonImage src={AlbaitLogo} alt="" style={{ width: "150px" }} /> */}
      </CommonFlex>
      <SloganText>Field Service Management App</SloganText>
      <AuthPageArtwork src={AuthArtwork} alt="" />
    </AuthPageLeftContainer>
  );
};

export default AuthPageLeftSection;
