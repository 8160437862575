import {useState, useEffect} from "react";

import {
    PageTitle,
    ContentHeader,
    Container,
    CardTitle,
    CommonText,
    CommonGrid, InputLabel, RedStar, InputWithLabelSection, InputField, TimeInputField, TableText,
} from "../../../styledComponents/common";
import {
    OrgSettingsSection,
    SettingPropertySection,
    ToggleButton,
    ToggleButtonRound,
} from "../ActivitySettings/activitySettingsStyles";

import MembersTable from "./MembersTable";

import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import {TimeRangeSection} from "../../TasksNew/CreateTask/createTaskStyles";
import TimeRangeComponent from "../../Timesheet/Daily/TimeRangeComponent";
import TextInput from "../../../components/FloatingLabelInput/FloatingLabelInput";
import {TableItem} from "../../../styledComponents/teams";
import {ClockInOutTableHeader, ClockInOutTableHeaderNew, ClockInOutTableRow} from "../../ClockInOut/clockInOutStyles";
import {MemberNameDiv} from "../../../styledComponents/members";
import {CommImageNameComp} from "../../Members/CommonComponents";
import avatar5 from "../../../assets/img/avatar_5.svg";
import moment from "moment-timezone";

const TimeClockSection = (props) => {
    const {geofenceTimeClock, handleUpdateGeofenceSettings} = props;
    return (
        <Container padding="25px" style={{margin: "20px 0"}}>
            <CommonText name title margin="0 0 10px 0">
                Geofence Time Clock
            </CommonText>
            <SettingPropertySection>
                <CommonGrid columns="45% 55%" gap="2em">
                    <CommonText $label fontWeight="500" margin="0">
                        Manage geofence time clock of your organization
                    </CommonText>
                    <CommonGrid columns="36px 30px" gap="10px" justifyContent="start">
                        <ToggleButton
                            onClick={() => handleUpdateGeofenceSettings(!geofenceTimeClock)}
                            active={geofenceTimeClock}
                        >
                            <ToggleButtonRound active={geofenceTimeClock}/>
                        </ToggleButton>
                        <CommonText
                            $label={!geofenceTimeClock}
                            fontWeight="500"
                            fontSize="14px"
                            margin="0"
                        >
                            {geofenceTimeClock ? "ON" : "OFF"}
                        </CommonText>
                    </CommonGrid>
                </CommonGrid>
            </SettingPropertySection>
        </Container>
    );
};

const AttendanceClockSection = (props) => {
    const {geofenceTimeClock, handleUpdateGeofenceSettings} = props;
    const handleStartTime = () => {

    }
    const handleEndTime = () => {

    }
    return (
        <Container padding="25px" style={{margin: "20px 0"}}>
            <CommonText $label fontWeight="500" margin="0">
                Set office time and boundaries to track late attendance
            </CommonText>

            <SettingPropertySection style={{margin: "20px 0"}}>
                <CommonGrid columns="45% 55%" gap="2em" alignItem={"center"}>
                    <CommonText name title margin="0 0 10px 0">
                        Office Timing
                    </CommonText>
                    <CommonGrid columns="36px 30px" gap="10px" justifyContent="start">
                        <InputWithLabelSection>
                            <TimeRangeSection>
                                <InputLabel>
                                    Start Time
                                </InputLabel>
                                <InputLabel>
                                    End Time
                                </InputLabel>
                            </TimeRangeSection>
                            <TimeRangeComponent
                                setStartTime={handleStartTime}
                                setEndTime={handleEndTime}
                            />
                        </InputWithLabelSection>
                    </CommonGrid>
                </CommonGrid>
                <SingleTimeDurationSection/>
            </SettingPropertySection>
        </Container>
    );
};

const SingleTimeDurationSectionDiv = () => {
    return (
        <Container padding=" 0px 25px" style={{margin: "5px 0"}}>
            <SettingPropertySection style={{margin: "5px 0"}}>
                <SingleTimeDurationSection/>
            </SettingPropertySection>
        </Container>
    );
}
const SingleTimeDurationSection = (props) => {
    return (
        <SettingPropertySection style={{margin: "20px 0"}}>
            <CommonGrid columns="45% 55%" gap="2em" alignItem={"center"}>
                <CommonText name title margin="0 0 10px 0">
                    Accepted Late Duration
                </CommonText>
                <CommonGrid gap="10px" justifyContent="start" alignItem={"center"}>
                    <TimeInputField
                        type="text"
                        placeholder="20"
                        width="70px"
                    />
                    <CommonText $label fontWeight="500" margin="0 10px">
                        minutes
                    </CommonText>

                </CommonGrid>

            </CommonGrid>
        </SettingPropertySection>
    );
};


function ConfigurableMemberTable(props) {
    const {geofenceTimeClock} = props;

    return (
        <Container padding="25px" style={{margin: "20px 0"}}>
            <ClockInOutTableHeaderNew>
                <TableItem>
                    <TableText>Member Name</TableText>
                </TableItem>
                <TableItem>
                    <TableText>Enable Geofence Time Clock</TableText>
                </TableItem>
            </ClockInOutTableHeaderNew>
            <ClockInOutTableRow>
                <TableItem>
                    <MemberNameDiv>
                        <CommImageNameComp
                            imgSource={avatar5}
                            size="36px"
                        />
                    </MemberNameDiv>
                </TableItem>
                <TableItem>
                    <CommonGrid columns="36px 30px" gap="10px" justifyContent="start">
                        <ToggleButton
                            active={geofenceTimeClock}
                        >
                            <ToggleButtonRound active={geofenceTimeClock}/>
                        </ToggleButton>
                        <CommonText
                            $label={!geofenceTimeClock}
                            fontWeight="500"
                            fontSize="14px"
                            margin="0"
                        >
                            {geofenceTimeClock ? "ON" : "OFF"}
                        </CommonText>
                    </CommonGrid>
                </TableItem>
            </ClockInOutTableRow>
            <ClockInOutTableRow>
                <TableItem>
                    <MemberNameDiv>
                        <CommImageNameComp
                            imgSource={avatar5}
                            size="36px"
                        />
                    </MemberNameDiv>
                </TableItem>
                <TableItem>
                    <CommonGrid columns="36px 30px" gap="10px" justifyContent="start">
                        <ToggleButton
                            active={geofenceTimeClock}
                        >
                            <ToggleButtonRound active={geofenceTimeClock}/>
                        </ToggleButton>
                        <CommonText
                            $label={!geofenceTimeClock}
                            fontWeight="500"
                            fontSize="14px"
                            margin="0"
                        >
                            {geofenceTimeClock ? "ON" : "OFF"}
                        </CommonText>
                    </CommonGrid>
                </TableItem>
            </ClockInOutTableRow>
        </Container>
    );
}

const GeofenceSettings = (props) => {
    // pagination
    const [currentPageNumber, setCurrentPageNumber] = useState(1);

    const {
        isLoading,
        history,
        selectedOrganization,
        updateGeofenceSettings,

        getMembersSettingsList,
        updateMembersSettingsList,

        membersListLoading,
        membersSettingsList,

        // pagination
        totalMembersCount,
    } = props;

    useEffect(() => {
        if (selectedOrganization && selectedOrganization.id) {
            setCurrentPageNumber(1);
            const payload = {
                organization_id: selectedOrganization.id,
            };

            if (
                !membersSettingsList ||
                (membersSettingsList && membersSettingsList.length === 0)
            ) {
                getMembersSettingsList(payload);
            }
        }
    }, [selectedOrganization]);

    const handleUpdateGeofenceSettings = (value) => {
        if (
            selectedOrganization &&
            selectedOrganization.id &&
            selectedOrganization.configuration &&
            selectedOrganization.configuration.id
        ) {
            const payload = {
                organization_id: selectedOrganization.id,
                configuration_id: selectedOrganization.configuration.id,
                geofence_time_clock: value,
            };
            updateGeofenceSettings(payload);
        }
    };

    const handleUpdateMemberSettings = (value) => {
        if (
            selectedOrganization &&
            selectedOrganization.id &&
            selectedOrganization.configuration &&
            selectedOrganization.configuration.id &&
            value != null
        ) {
            const payload = {
                organization_id: selectedOrganization.id,
                member_id: value.id,
                geofence_time_clock: !value.settings,
            };
            updateMembersSettingsList(payload);
        }
    };

    const handlePageChange = (page) => {
        if (selectedOrganization && selectedOrganization.id) {
            const payload = {
                organization_id: selectedOrganization.id,
                page,
            };

            getMembersSettingsList(payload);
        }
        setCurrentPageNumber(page);
    };

    return (
        <div className="content">
            <PageTitle>Geofence Time Clock Settings</PageTitle>
            <ContentHeader>
                <BackButtonComponent
                    onClick={() => history.push("/user/settings")}
                    subTitle="Back to settings"
                />
            </ContentHeader>

            <Container padding="0">
                {isLoading ? (
                    <ComponentCircleLoader/>
                ) : (
                    <>
                        <OrgSettingsSection>
                            <CardTitle>Configure Geofence time clock settings</CardTitle>
                            <TimeClockSection
                                geofenceTimeClock={
                                    selectedOrganization &&
                                    selectedOrganization.configuration &&
                                    selectedOrganization.configuration.geofence_time_clock
                                }
                                handleUpdateGeofenceSettings={handleUpdateGeofenceSettings}
                            />
                        </OrgSettingsSection>
                        <OrgSettingsSection>
                            <CardTitle>Attendance Time Settings</CardTitle>
                            <AttendanceClockSection
                                geofenceTimeClock={
                                    selectedOrganization &&
                                    selectedOrganization.configuration &&
                                    selectedOrganization.configuration.geofence_time_clock
                                }
                                handleUpdateGeofenceSettings={handleUpdateGeofenceSettings}
                            />
                        </OrgSettingsSection>
                        <OrgSettingsSection>
                            <SingleTimeDurationSectionDiv/>
                        </OrgSettingsSection>
                        <OrgSettingsSection>
                            <CardTitle>Configure Individual Member</CardTitle>
                            <ConfigurableMemberTable>
                                geofenceTimeClock={}

                            </ConfigurableMemberTable>

                        </OrgSettingsSection>


                    </>
                )}
            </Container>
        </div>
    );
};

export default GeofenceSettings;
