import { css } from '@emotion/core';
import base64 from 'base-64';
import moment from 'moment';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import styled from 'styled-components';

import { createNotification } from '../../modules/notificationManager';
import api from '../../utils/api';

const customCss = css`
  margin: 5px;
  text-align: center;
`;

const InvitationContainer = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.main.whiteBg};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingText = styled.p`
  font-size: 24px;
`;

const TextLoader = props => {
  return (
    <div className="sweet-loading">
      <PulseLoader
        size={8}
        color={'#2f394e'}
        loading={props.loading}
        css={customCss}
      />
    </div>
  );
};

const AcceptInvitation = props => {
  const [loading, setLoading] = useState(true);
  const [login, setLogin] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (props.location.search) {
      const queryParsed = queryString.parse(props.location.search);
      if (queryParsed) {
        let key = queryParsed.key;
        let JWS = JSON.parse(base64.decode(key.split('.')[0]));
        let payload = JSON.parse(base64.decode(key.split('.')[1]));
        let organization_id =
          payload && payload.organization ? payload.organization : '';
        let error = '';
        if (!JWS) {
          error = 'Invalid Link!';
        } else if (JWS) {
          if (JWS.alg && JWS.alg !== 'HS256') {
            error = 'Invalid Link!';
          } else if (JWS.typ && JWS.typ !== 'JWS') {
            error = 'Invalid Link!';
          }
        }
        if (payload && payload.exp) {
          if (moment.utc().isAfter(moment.unix(payload.exp))) {
            error = 'Link Expired!';
          }
        }
        if (error) {
          createNotification('error', error, 3000);
          props.history.push('/auth/login');
        }
        if (organization_id) {
          api({
            url: `${organization_id}/members/check-account/`,
            method: 'POST',
            data: { key },
          })
            .then(response => {
              if (response.status === 200 && response.data) {
                if (response.data.signup) {
                  if (localStorage.getItem('access_token') !== null) {
                    // createNotification(
                    //   "error",
                    //   "Sign out first to create account from invitation link",
                    //   5000
                    // );
                    localStorage.clear();
                  }
                  setTimeout(() => {
                    props.history.push(
                      `/auth/register` + props.location.search,
                    );
                  }, 2000);
                } else {
                  api({
                    url: `${organization_id}/members/`,
                    method: 'POST',
                    data: { key },
                  })
                    .then(response => {
                      setLoading(false);
                      setSuccess(true);
                      setTimeout(() => {
                        if (
                          response.status === 201 &&
                          response.data &&
                          response.data.token
                        ) {
                          localStorage.setItem(
                            'access_token',
                            response.data.token,
                          );
                          localStorage.setItem(
                            'user_id',
                            response.data.user.id,
                          );
                          localStorage.setItem(
                            'user_profile',
                            response.data.profile_id,
                          );
                          localStorage.setItem(
                            'user_fullName',
                            `${response.data.user.first_name}${
                              response.data.user.last_name
                                ? ` ${response.data.user.last_name}`
                                : ``
                            }`,
                          );
                          localStorage.setItem(
                            'email',
                            response.data.user.email,
                          );
                          setTimeout(() => {
                            props.history.push({
                              pathname: '/user/jobs',
                              state: { message: 'user-onboard' },
                            });
                          }, 2000);
                        }
                        props.history.push('/auth/login');
                        createNotification('success', 'Invitation accepted!');
                      }, 2000);
                    })
                    .catch(err => {
                      setLoading(false);
                      setLogin(true);
                      setTimeout(() => props.history.push('/auth/login'), 2000);
                      createNotification(
                        'error',
                        'Something went wrong!! Please try again.',
                        2000,
                      );
                    });
                }
              }
            })
            .catch(err => {
              setLoading(false);
              setLogin(true);
              setTimeout(() => props.history.push('/auth/login'), 2000);
              createNotification(
                'error',
                'Something went wrong!! Please try again.',
                2000,
              );
            });
        }
        // else {
        //   if (localStorage.getItem("access_token") !== null) {
        //     createNotification(
        //       "error",
        //       "Sign out first to create account from invitation link",
        //       5000
        //     );
        //   }
        //   props.history.push(`/auth/register` + props.location.search);
        // }
      }
    }
  }, []);
  return (
    <InvitationContainer>
      <LoadingText>
        {loading
          ? `Your activation is in process`
          : success
          ? `Activation successful!`
          : login
          ? `Something went wrong in the process!! Please try again.`
          : ``}
      </LoadingText>
      {loading && <TextLoader loading />}
    </InvitationContainer>
  );
};

export default AcceptInvitation;
