import {
  CLEAR_OFFICE_CREATED_UPDATED,
  CLEAR_OFFICE_DETAILS,
  CREATE_OFFICE,
  GET_OFFICES_LIST,
  GET_OFFICES_LIST_SHORT,
  SET_OFFICE_DETAILS,
  UPDATE_OFFICE,
  GET_OFFICE_MEMBERS_LIST,
  ADD_OFFICE_MEMBER,
  UPDATE_OFFICE_MEMBER,
  REMOVE_OFFICE_MEMBER,
  DELETE_OFFICE,
  GET_OFFICES_DETAILS,
  ADD_OR_UPDATE_OFFICE_LOCATION,
  ADD_OFFICE_LOCATION,
  UPDATE_OFFICE_LOCATION,
  CLEAR_OFFICE_ID,
} from '../../modules/constants';

export function clearCreatedOfficeId() {
  return {
    type: CLEAR_OFFICE_ID,
  };
}

export function updateOfficeInfo(value) {
  return {
    type: ADD_OR_UPDATE_OFFICE_LOCATION,
    payload: value,
  };
}

export function addOfficeLocation(value) {
  return {
    type: ADD_OFFICE_LOCATION,
    payload: value,
  };
}

export function updateOfficeLocation(value) {
  return {
    type: UPDATE_OFFICE_LOCATION,
    payload: value,
  };
}

export function getOfficeDetails(value) {
  return {
    type: GET_OFFICES_DETAILS,
    payload: value,
  };
}

export function deleteOffice(value) {
  return {
    type: DELETE_OFFICE,
    payload: value,
  };
}

export function getOfficeMembersList(value) {
  return {
    type: GET_OFFICE_MEMBERS_LIST,
    payload: value,
  };
}

export function addOfficeMember(value) {
  return {
    type: ADD_OFFICE_MEMBER,
    payload: value,
  };
}

export function getOfficesList(value) {
  return {
    type: GET_OFFICES_LIST,
    payload: value,
  };
}

export function getOfficesShortList(value) {
  return {
    type: GET_OFFICES_LIST_SHORT,
    payload: value,
  };
}

export function createOffice(value) {
  return {
    type: CREATE_OFFICE,
    payload: value,
  };
}

export function updateOffice(value) {
  return {
    type: UPDATE_OFFICE,
    payload: value,
  };
}

export function updateOfficeMember(value) {
  return {
    type: UPDATE_OFFICE_MEMBER,
    payload: value,
  };
}

export function setOfficeDetails(value) {
  return {
    type: SET_OFFICE_DETAILS,
    payload: value,
  };
}

export function clearOfficeDetails() {
  return {
    type: CLEAR_OFFICE_DETAILS,
  };
}

export function clearOfficeCreatedUpdated() {
  return {
    type: CLEAR_OFFICE_CREATED_UPDATED,
  };
}

export function removeOfficeMember(value) {
  return {
    type: REMOVE_OFFICE_MEMBER,
    payload: value,
  };
}