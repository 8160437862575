import BackendExportModal from 'custom_modules/ReportsNew/BackendExportModal';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ColoredButton,
  TabButton,
  TabButtonContainer,
} from '../../styledComponents/buttons';
import { CommonFlex, PageTitle } from '../../styledComponents/common';
import {
  PayrollHistoryTab,
  PayrollHistoryTabContainer,
} from '../../styledComponents/payroll';
import FixedAmount from './FixedAmount';
import PayrollHistoryTable from './PayrollHistoryTable';
import PayrollTable from './PayrollTable';
import PayrollFilter from './filterContainer';

const Payroll = ({
  officesShortList,
  selectedOrganization,
  paymentHistoryIsLoading,
  getHourlyPaymentHistory,
  getOnetimePaymentHistory,
  getOrganizationMembersShortList,
  getOfficeDetails,
  getPayablesData,
  getOfficesShortList,
  hourlyPaymentHistory,
  onetimePaymentHistory,
  sendOneTimePayment,
  orgMembersShortList,
  officeMembersList,
  oneTimePaymentData,
  clearOneTimePaymentData,
  paymentIsLoading,
  payablesData,
  sendHourlyPayment,
  payablesIsLoading,
  exportPayablesData,
}) => {
  const { t } = useTranslation();
  const [tabSelect, setTabSelect] = useState('hourly');
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days'));
  const [endDate, setEndDate] = useState(moment());
  const [sortType, setSortType] = useState({
    value: 'oldToNew',
    label: 'Date (Old to New)',
  });
  const [payrollHistoryType, setPayrollHistoryType] = useState('hourly_basis');
  const [officeOptions, setOfficeOptions] = useState([
    {
      label: t('all_offices'),
      value: null,
    },
  ]);
  const [selectedOffice, setSelectedOffice] = useState({
    label: t('all_offices'),
    value: null,
  });
  const [selectedMember, setSelectedMember] = useState('');

  const [timeFormat, setTimeFormat] = useState('1h5m');
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const onMemberSelect = person => {
    setCurrentPageNumber(1);
    setSelectedMember(person);
  };
  const toggle = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };
  useEffect(() => {
    if (selectedOrganization?.id) {
      getOfficesShortList({
        organization_id: selectedOrganization.id,
      });
      setSelectedMember('');
      setSortType({
        value: 'oldToNew',
        label: 'Date (Old to New)',
      });
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (officesShortList && officesShortList.length > 0) {
      let newArr = [
        {
          label: t('all_offices'),
          value: null,
        },
      ];
      officesShortList.forEach((office, i) => {
        newArr.push({
          label: office.name,
          value: office.id,
        });
      });
      setOfficeOptions(newArr);
    }
  }, [officesShortList]);

  const clearFilters = () => {
    console.log('clearFilters');
    setSelectedOffice({
      label: t('all_offices'),
      value: null,
    });
    setSelectedMember('');
    setSortType({
      value: 'oldToNew',
      label: 'Date (Old to New)',
    });
  };

  const changeSortType = type => {
    setSortType(type);
  };

  const onTabSwitch = value => {
    setTabSelect(value);
  };

  const changeHistoryType = value => {
    setPayrollHistoryType(value);
  };

  const onDateSelect = today => {
    let organizationId = selectedOrganization ? selectedOrganization.id : '';
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start: today
          ? moment().format('YYYY-MM-DD')
          : moment(startDate).format('YYYY-MM-DD'),
        end: today
          ? moment().format('YYYY-MM-DD')
          : moment(endDate).format('YYYY-MM-DD'),
      };
      if (selectedOffice && selectedOffice.value) {
        payload.office_id = selectedOffice.value;
      }
      if (selectedMember && selectedMember.id) {
        payload.user_ids = [selectedMember.id];
      }
      getPayablesData(payload);
      if (today) {
        setStartDate(moment());
        setEndDate(moment());
      }
    }
  };

  const onDateSelectHistory = today => {
    const organizationId = selectedOrganization ? selectedOrganization.id : '';
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: today
          ? moment().format('YYYY-MM-DD')
          : moment(startDate).format('YYYY-MM-DD'),
        end_date: today
          ? moment().format('YYYY-MM-DD')
          : moment(endDate).format('YYYY-MM-DD'),
      };
      if (selectedOffice && selectedOffice.value) {
        payload.office_id = selectedOffice.value;
      }
      if (selectedMember && selectedMember.id) {
        payload.user_ids = [selectedMember.id];
      }
      if (payrollHistoryType === 'hourly_basis') {
        getHourlyPaymentHistory(payload);
      } else if (payrollHistoryType === 'fixed_amount') {
        getOnetimePaymentHistory(payload);
      }
      if (today) {
        setStartDate(moment());
        setEndDate(moment());
      }
    }
  };

  const changeDateRange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const selectLastWeek = () => {
    setStartDate(moment().subtract(6, 'days'));
    setEndDate(moment());
  };

  const selectLastMonth = () => {
    setStartDate(moment().subtract(29, 'days'));
    setEndDate(moment());
  };
  const handleExportClockInOutList = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization ? selectedOrganization.id : '',
        start: moment(startDate).format('YYYY-MM-DD'),
        end: moment(endDate).format('YYYY-MM-DD'),
        time_format: timeFormat,
      };
      if (selectedOffice && selectedOffice.value) {
        payload.office_id = selectedOffice.value;
      }
      if (selectedMember && selectedMember.id) {
        payload.user_ids = [selectedMember.id];
      }
      exportPayablesData(payload);
    }
    toggle();
  };
  useEffect(() => {
    if (startDate && !endDate) {
      setEndDate(startDate);
    }
    // if (startDate && endDate) {
    //   const maxEndDate = moment(startDate).add(3, 'months');
    //   if (moment(endDate).diff(moment(startDate), 'months', true) > 3) {
    //     setEndDate(maxEndDate);
    //   }
    // }
  }, [startDate, endDate]);
  return (
    <div className="content">
      {' '}
      <BackendExportModal
        isOpen={isExportModalOpen}
        toggle={toggle}
        excelExport={handleExportClockInOutList}
        title="Payroll"
        timeFormat={timeFormat}
        setTimeFormat={setTimeFormat}
      />
      <PageTitle>Payroll</PageTitle>
      <div style={{ display: `grid`, marginTop: '30px' }}>
        <TabButtonContainer columns="auto auto auto" margin="0 auto 0 0">
          <TabButton
            onClick={() => onTabSwitch('hourly')}
            selected={tabSelect}
            index={'hourly'}
          >
            Send Hourly Payment
          </TabButton>
          <TabButton
            onClick={() => onTabSwitch('fixed')}
            selected={tabSelect}
            index={'fixed'}
          >
            Send Fixed Amount
          </TabButton>
          <TabButton
            onClick={() => onTabSwitch('history')}
            selected={tabSelect}
            index={'history'}
          >
            History
          </TabButton>
        </TabButtonContainer>

        {tabSelect === 'history' && (
          <PayrollHistoryTabContainer>
            <PayrollHistoryTab
              onClick={() => changeHistoryType('hourly_basis')}
              index={'hourly_basis'}
              payrollHistoryType={payrollHistoryType}
            >
              Hourly Basis
            </PayrollHistoryTab>
            <PayrollHistoryTab
              onClick={() => changeHistoryType('fixed_amount')}
              index={'fixed_amount'}
              payrollHistoryType={payrollHistoryType}
            >
              Fixed Amount
            </PayrollHistoryTab>
          </PayrollHistoryTabContainer>
        )}

        {tabSelect === 'hourly' && (
          <>
            <CommonFlex alignItems="end" justifyContent="space-between">
              <PayrollFilter
                tabSelect={tabSelect}
                startDate={startDate}
                endDate={endDate}
                onDateSelect={onDateSelect}
                changeDateRange={changeDateRange}
                selectLastWeek={selectLastWeek}
                selectLastMonth={selectLastMonth}
                sortType={sortType}
                changeSortType={changeSortType}
                officeOptions={officeOptions}
                selectedOffice={selectedOffice}
                setSelectedOffice={setSelectedOffice}
                selectedMember={selectedMember}
                setSelectedMember={setSelectedMember}
              />
              {(selectedOrganization?.role === 'admin' ||
                selectedOrganization?.role === 'owner' ||
                selectedOrganization?.role === 'team_leader') &&
                payablesData?.length > 0 && (
                  <ColoredButton
                    style={{
                      marginBottom: '1px',
                    }}
                    type="gray"
                    onClick={() => toggle()}
                  >
                    Export
                  </ColoredButton>
                )}
            </CommonFlex>
            <PayrollTable
              tabSelect={tabSelect}
              startDate={startDate}
              endDate={endDate}
              selectedOrganization={selectedOrganization}
              getPayablesData={getPayablesData}
              sortType={sortType}
              payablesData={payablesData}
              sendHourlyPayment={sendHourlyPayment}
              isLoading={payablesIsLoading}
              paymentIsLoading={paymentIsLoading}
            />
          </>
        )}

        {tabSelect === 'fixed' && (
          <FixedAmount
            selectedOrganization={selectedOrganization}
            sendOneTimePayment={sendOneTimePayment}
            getOrganizationMembersList={getOrganizationMembersShortList}
            membersList={orgMembersShortList}
            officeMembersList={officeMembersList}
            officeOptions={officeOptions}
            getOfficeDetails={getOfficeDetails}
            oneTimePaymentData={oneTimePaymentData}
            clearOneTimePaymentData={clearOneTimePaymentData}
            setTabSelect={setTabSelect}
            setHistoryType={setPayrollHistoryType}
            paymentIsLoading={paymentIsLoading}
          />
        )}

        {tabSelect === 'history' && (
          <>
            <PayrollFilter
              tabSelect={tabSelect}
              startDate={startDate}
              endDate={endDate}
              onDateSelect={onDateSelectHistory}
              changeDateRange={changeDateRange}
              selectLastWeek={selectLastWeek}
              selectLastMonth={selectLastMonth}
              sortType={sortType}
              changeSortType={changeSortType}
              historyType={payrollHistoryType}
              officeOptions={officeOptions}
              selectedOffice={selectedOffice}
              setSelectedOffice={setSelectedOffice}
              selectedMember={selectedMember}
              setSelectedMember={setSelectedMember}
            />
            <PayrollHistoryTable
              historyType={payrollHistoryType}
              setHistoryType={setPayrollHistoryType}
              startDate={startDate}
              endDate={endDate}
              sortType={sortType}
              getHourlyPaymentHistory={getHourlyPaymentHistory}
              getOnetimePaymentHistory={getOnetimePaymentHistory}
              hourlyPaymentHistory={hourlyPaymentHistory}
              onetimePaymentHistory={onetimePaymentHistory}
              selectedOrganization={selectedOrganization}
              isLoading={paymentHistoryIsLoading}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Payroll;
