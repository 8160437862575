import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import avatar5 from '../../assets/img/avatar_5.svg';
import audioIcon from '../../assets/img/icons/Audio.svg';
import imageIcon from '../../assets/img/icons/Image.svg';
import arrowWhiteIcon from '../../assets/img/icons/arrow_right_white.svg';
import Chat from '../../assets/img/icons/chat.svg';
import deleteIcon from '../../assets/img/icons/delete.svg';
import duplicateIcon from '../../assets/img/icons/duplicate-new.svg';
import editIcon from '../../assets/img/icons/edit-pen.svg';
import tick from '../../assets/img/icons/tick-white-bold.svg';
import { CardText } from '../../components/DashboardCards/cardStyles';
import DropdownLoader from '../../components/DropdownLoader/DropdownLoader';
import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent';
import AssigneeTooltip from '../../components/Tooltip/AssigneeTooltip';
import CommonTooltip from '../../components/Tooltip/CommonTooltip';
import TaskActionTooltip from '../../components/Tooltip/TaskActionTooltip';
import {
  ActionSection,
  BudgetCollapseSection,
  ClientDeadlineGrid,
  ListUpperSection,
  ProjectDuplicateDiv,
  ProjectDuplicateIcon,
  ProjectItemCount,
  ProjectListItem,
  ProjectListSection,
  ProjectTableContainer,
  ProjectTableContainerNew,
  ProjectTitleDiv,
  TableItem,
  TableRowAndBudgetCollapseContainer,
} from '../../styledComponents/ProjectsNew';
import { PrimaryButton } from '../../styledComponents/buttons';
import {
  ClientLocationsTableHeaderContainer,
  ClientLocationsTableRowContainer,
  GeneralInfoItem,
  GeneralInfoSection,
  HoveredSection2,
  InnerDivTooltip,
  RoundHover,
  ToolTipContainer,
  TooltipText,
} from '../../styledComponents/clients';
import {
  AssignListText,
  ColoredText,
  CommonImage,
  CommonText,
  Container,
  ShowMoreAssigneeList,
  TableText,
} from '../../styledComponents/common';
import {
  AssigneeRoundImage,
  AssigneeRoundImageSection,
  RoundImageCross,
  RoundPersonImage,
} from '../../styledComponents/createProject';
import { MemberNameDiv } from '../../styledComponents/members';
import {
  IntegrationIcon,
  IntegrationIconDiv,
} from '../../styledComponents/tasks';
import { checkHtml, checkUrl } from '../../utils/helper';
import { toHHMMSS } from '../../utils/helper';
import {
  DeleteButton,
  EditButton,
} from '../FieldServices/JobSites/jobSitesStyles';
import {
  CardBlock,
  GreenCircle,
  LateTag,
} from '../FieldServices/RouteMapNew/routeMapStyles';
import { CommImageNameComp } from '../Members/CommonComponents';
import {
  ExpandIcon,
  ProjectExpandButton,
  ProjectListStatusBadge,
} from '../Projects/projectsStyles';
import { CommDropdownComp } from '../Tasks/AddAssigneeDropdown';
import AudioPlayerModal from './JobApproval/AudioPlayer';
import EvidenceModal from './JobApproval/EvidenceModal';
import {
  CallSection,
  EmployeeInfoBlock,
  EvidenceIcon,
  EvidenceIconSection,
  EvidenceThumbnail,
  LabelTextBlock,
  NoteSection,
  StatusBar,
} from './JobApproval/taskApprovalStyle';
import {
  ActionIconSection,
  ApprovalBadge,
  AssigneeRoundImg,
  CollapseSection,
  RoundIcon,
  TaskInfoSection,
  TaskStatusButton,
} from './tasksStyles';

const JobList = props => {
  const [duplicateTooltip, setDuplicateTooltip] = useState(null);
  const role = localStorage.getItem('selected_organization_role');
  const user_id = localStorage.getItem('user_id');

  const {
    selectedOrganization,
    expandedTaskId,
    setExpandedTaskId,
    // visibilityChange,
    loadingTaskAssigneeId,
    toggleDeleteModal,
    isLoading,
    jobList,
    history,
  } = props;

  const projectBudgetAndBillableIsLoading = false;
  const [isCheckInOut, setIsCheckInOut] = useState(false);
  const [moreAssignee, setMoreAssignee] = useState(false);
  const [selectedRow, setSelectedRow] = useState(-1);

  const moreRef1 = useRef(null);
  const moreRef2 = useRef(null);
  const [visibility, setVisibility] = useState(0);
  //Image Modal
  const [popupOpen, setPopupOpen] = useState(false);
  const [evidenceImage, setEvidenceImage] = useState(null);

  //Audio Modal
  const [popupOpenAudio, setPopupOpenAudio] = useState(false);
  const [evidenceAudio, setEvidenceAudio] = useState(null);

  // ToolTip
  const [selectIndex, setSelectIndex] = useState(-1);
  const [toolTip, setToolTip] = useState(-1);

  useEffect(() => {
    if (jobList && jobList.length > 0) {
      jobList.forEach(item => {
        const assigneesData = [...item.assignees];

        assigneesData.forEach((assignee, index) => {
          const checkedin = item.checkinout.find(
            obj => obj.event === 'checkin' && obj.user === assignee.id,
          );
          const checkedout = item.checkinout.find(
            obj => obj.event === 'checkout' && obj.user === assignee.id,
          );
          const evidence = item.evidences.find(
            obj => obj.created_by === assignee.id,
          );

          if (checkedin) {
            assigneesData[index].checkedin = checkedin;
          }
          if (checkedout) {
            assigneesData[index].checkedout = checkedout;
          }
          /* 
          +------------+-------------+---------------+-----------------+-------------+
          | Checked In | Checked Out | Job End Time  | Assignee Status | Results     |
          +------------+-------------+---------------+-----------------+-------------+
          | True       |             |               | False           | In Progress |
          +------------+-------------+---------------+-----------------+-------------+
          | True       | True        |               | True            | Completed   |
          +------------+-------------+---------------+-----------------+-------------+
          | False      | False       | In Range      | False           | To Do       |
          +------------+-------------+---------------+-----------------+-------------+
          | False      | False       | crossed       | false           | Missed      |
          +------------+-------------+---------------+-----------------+-------------+
    */
          if (assignee.assignee_status) {
            if (checkedin && checkedout) {
              assigneesData[index].status = 'Completed';
            }
          } else {
            if (checkedin) {
              if (!checkedout) {
                assigneesData[index].status = 'In Progress';
              }
            } else {
              if (
                !checkedout &&
                moment().unix() < moment(item.required_end_date).unix()
              ) {
                assigneesData[index].status = 'To Do';
              } else {
                assigneesData[index].status = 'Missed';
              }
            }
          }

          if (evidence) {
            assigneesData[index].evidence = evidence;
          }
        });
      });
    }
  }, [jobList]);

  const popupToggle = image => {
    setEvidenceImage(image);
    setPopupOpen(!popupOpen);
  };

  const popupToggleAudio = audio => {
    setEvidenceAudio(audio);
    setPopupOpenAudio(!popupOpenAudio);
  };

  const showMoreAssignee = index => {
    setMoreAssignee(true);
    setSelectedRow(index);
  };

  const toolTipChange = (value, index) => {
    setToolTip(value);
    setSelectIndex(index);
  };

  const visibilityChange = state => {
    setVisibility(state);
  };

  //function
  useOutsideClickHandler(moreRef1, moreRef2);
  function useOutsideClickHandler(moreRef1, moreRef2) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          moreRef1.current &&
          !moreRef1.current.contains(event.target) &&
          moreRef2.current &&
          !moreRef2.current.contains(event.target)
        ) {
          setMoreAssignee(false);
          visibilityChange(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [moreRef1, moreRef2, moreAssignee]);
  }

  const updateAssingeeList = (person, projectId, taskId) => {
    // if (
    //   selectedOrganization &&
    //   selectedOrganization.id &&
    //   projectId &&
    //   taskId &&
    //   person &&
    //   person.id
    // ) {
    //   const payload = {
    //     organization_id: selectedOrganization.id,
    //     project_id: projectId,
    //     task_id: taskId,
    //     user_id: person.id,
    //   };
    //   addTaskAssignee(payload);
    // }
  };

  const handleDeleteAssignee = (assigneeId, projectId, taskId) => {
    // if (
    //   selectedOrganization &&
    //   selectedOrganization.id &&
    //   projectId &&
    //   taskId &&
    //   assigneeId
    // ) {
    //   const payload = {
    //     organization_id: selectedOrganization.id,
    //     project_id: projectId,
    //     task_id: taskId,
    //     user_id: assigneeId,
    //   };
    //   deleteTaskAssignee(payload);
    // }
  };

  const handleExpandBudgetSection = taskId => {
    if (expandedTaskId && expandedTaskId === taskId) {
      setExpandedTaskId(null);
    } else {
      setExpandedTaskId(taskId);
    }
  };

  const { t, i18n } = useTranslation();

  return (
    <Container style={{ paddingBottom: '20px' }}>
      <EvidenceModal
        isOpen={popupOpen}
        toggle={popupToggle}
        evidence={evidenceImage ? evidenceImage : ''}
      />
      <AudioPlayerModal
        isOpen={popupOpenAudio}
        toggle={popupToggleAudio}
        audio={evidenceAudio ? evidenceAudio : ''}
      />
      {isLoading ? (
        <ComponentCircleLoader />
      ) : jobList && jobList.length > 0 ? (
        <>
          <ProjectTableContainerNew heading>
            <TableItem>
              <TableText>{t('job_name')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('customer')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('assignee')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('start_end')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('status')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('approval')}</TableText>
            </TableItem>
            <TableItem />
          </ProjectTableContainerNew>
          {jobList.map((task, index) => (
            <TableRowAndBudgetCollapseContainer key={task.id}>
              <ProjectTableContainerNew>
                <TableItem
                  style={{
                    gridTemplateColumns: 'auto auto',
                    alignItems: 'center',
                    gridColumnGap: '10px',
                  }}
                >
                  <TableText
                    hoverPointer
                    hoverUnderline
                    name
                    fontWeight={
                      expandedTaskId && expandedTaskId === task.id && '600'
                    }
                    onClick={() =>
                      history.push(
                        `/user/job-details?id=${task.id}&workOrder_id=${task.work_order.id}`,
                      )
                    }
                  >
                    {task.name}
                  </TableText>
                </TableItem>
                <TableItem
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    history.push(
                      `/user/customer-details?id=${task.work_order.client_id}`,
                    )
                  }
                >
                  <TableText name>
                    {task.work_order.client_name || 'N/A'}
                  </TableText>
                </TableItem>
                {/* <TableItem padding="10px 0 10px 10px">
                  {task.assignees &&
                  task.assignees.length > 0 &&
                  task.assignees[0].id ? (
                    <AssigneeRoundImg
                      image={task.assignees[0].avatar || avatar5}
                    >
                      <AssigneeTooltip
                        toolTipText={`${task.assignees[0].first_name}${
                          task.assignees[0].last_name
                            ? ` ${task.assignees[0].last_name}`
                            : ``
                        }`}
                        phone={task.assignees[0].phone}
                        top="-60px"
                        left="-55px"
                      />
                    </AssigneeRoundImg>
                  ) : null}
                </TableItem> */}
                <TableItem style={{ cursor: 'pointer' }}>
                  <AssigneeRoundImageSection
                    position={task.assignees.length}
                    style={{ marginRight: `auto` }}
                  >
                    {task.assignees && task.assignees.length > 3 && (
                      <AssigneeRoundImage
                        index="more"
                        onClick={() => {
                          showMoreAssignee(index);
                          visibilityChange(true);
                        }}
                        ref={moreRef2}
                        style={{
                          border: `1px solid #fff`,
                          paddingLeft: '3px',
                        }}
                      >
                        {`+${task.assignees.length - 3}`}
                      </AssigneeRoundImage>
                    )}
                    <>
                      {task.assignees && task.assignees.length > 0 ? (
                        <>
                          {task.assignees
                            .filter((person, index) => index < 3)
                            .map((filteredPerson, index) => (
                              <AssigneeRoundImage
                                key={index}
                                index={
                                  task.assignees.length < 4
                                    ? index + 1
                                    : index + 2
                                }
                              >
                                <AssigneeTooltip
                                  toolTipText={`${filteredPerson.first_name} ${filteredPerson.last_name}`}
                                />

                                <RoundPersonImage
                                  src={
                                    filteredPerson.avatar
                                      ? filteredPerson.avatar
                                      : avatar5
                                  }
                                  alt=""
                                />
                              </AssigneeRoundImage>
                            ))}
                          {moreAssignee && selectedRow === index && (
                            <ShowMoreAssigneeList ref={moreRef1}>
                              {task.assignees.map((member, index) => (
                                <Fragment key={index}>
                                  <AssigneeRoundImage index={0} size="30px">
                                    <RoundPersonImage
                                      src={
                                        member.avatar ? member.avatar : avatar5
                                      }
                                      alt=""
                                      size="30px"
                                      borderColor="#c2cce1"
                                    />
                                  </AssigneeRoundImage>
                                  <AssignListText>
                                    {member.first_name} {member.last_name}
                                  </AssignListText>
                                </Fragment>
                              ))}
                            </ShowMoreAssigneeList>
                          )}
                        </>
                      ) : (
                        <CommonText>-</CommonText>
                      )}
                    </>
                  </AssigneeRoundImageSection>
                </TableItem>
                <TableItem>
                  <TableText name>
                    {task.start_time &&
                      moment(task.start_time).format('h:mm a')}{' '}
                    - {task.end_time && moment(task.end_time).format('h:mm a')}
                  </TableText>
                </TableItem>
                <TableItem>
                  <TaskStatusButton
                    status={task.status}
                    style={{ minWidth: '120px' }}
                  >
                    {task.status === 'unscheduled'
                      ? t('Unscheduled')
                      : task.status === 'ready to dispatch'
                      ? t('Ready to dispatch')
                      : task.status === 'completed'
                      ? t('completed')
                      : task.status === 'partially complete'
                      ? t('partially_completed')
                      : t('totally_missed')}
                  </TaskStatusButton>
                </TableItem>
                <TableItem>
                  {(task.status === 'completed' ||
                    task.status === 'partially complete') &&
                    task.approval &&
                    task.approval.status && (
                      <ApprovalBadge status={task.approval.status}>
                        {t(task.approval.status)}
                      </ApprovalBadge>
                    )}
                </TableItem>
                <TableItem>
                  <ActionIconSection>
                    {/* {task.status &&
                    (task.status === "unscheduled" ||
                      task.status === "ready to dispatch") ? (
                      <RoundIcon
                        type="edit"
                        onClick={() =>
                          history.push(
                            `/user/edit-job?id=${task.id}&workOrder_id=${task.work_order.id}`
                          )
                        }
                      >
                        <CommonImage src={editIcon} alt="" size="16px" />
                        <TaskActionTooltip toolTipText={t("edit_job")} />
                      </RoundIcon>
                    ) : (
                      <div />
                    )} */}
                    <div />
                    {/* <RoundIcon
                        type="duplicate"
                        onClick={() => duplicateTask(task)}
                      >
                        <CommonImage src={duplicateIcon} alt="" size="16px" />
                        <TaskActionTooltip toolTipText={t('duplicate')} />
                      </RoundIcon> */}

                    {selectedOrganization &&
                    selectedOrganization.role &&
                    (selectedOrganization.role === 'admin' ||
                      selectedOrganization.role === 'owner') &&
                    task.status &&
                    (task.status === 'unscheduled' ||
                      task.status === 'ready to dispatch' ||
                      task.status === 'totally missed') ? (
                      <RoundIcon
                        onClick={() =>
                          toggleDeleteModal(task.id, task.work_order.id)
                        }
                      >
                        <CommonImage src={deleteIcon} alt="" size="16px" />
                        <TaskActionTooltip toolTipText={t('delete_job')} />
                      </RoundIcon>
                    ) : (
                      <div />
                    )}
                  </ActionIconSection>
                </TableItem>
                {/* Expansion Section start */}
                <TableItem rightAlign>
                  {task.status === 'unscheduled' ? (
                    ''
                  ) : (
                    <>
                      <ProjectExpandButton
                        onClick={() => handleExpandBudgetSection(task.id)}
                      >
                        <ExpandIcon
                          src={arrowWhiteIcon}
                          alt=""
                          open={expandedTaskId && expandedTaskId === task.id}
                        />
                      </ProjectExpandButton>
                    </>
                  )}
                </TableItem>
              </ProjectTableContainerNew>
              <CollapseSection
                open={expandedTaskId && expandedTaskId === task.id}
              >
                <div
                  style={{
                    padding: '30px',
                    borderTop: '1px solid #C2CCE1',
                    borderBottom: '1px solid #C2CCE1',
                    boxSizing: 'border-box',
                  }}
                >
                  {task.assignees.length > 0 && (
                    <>
                      <ClientLocationsTableHeaderContainer columns={6}>
                        <TableItem>
                          <TableText>{t('assignees')}</TableText>
                        </TableItem>
                        <TableItem>
                          <TableText>{t('checked_in')}</TableText>
                        </TableItem>
                        <TableItem>
                          <TableText>{t('checked_out')}</TableText>
                        </TableItem>
                        <TableItem>
                          <TableText>{t('worked')}</TableText>
                        </TableItem>
                        <TableItem>
                          <TableText>{t('status')}</TableText>
                        </TableItem>
                        <TableItem>
                          <TableText>{t('evidence')}</TableText>
                        </TableItem>
                      </ClientLocationsTableHeaderContainer>

                      {task.assignees.map((assignee, index) => {
                        if (role === 'member' && user_id !== assignee.id) {
                          return null;
                        }
                        return (
                          <Fragment key={assignee.id}>
                            <ClientLocationsTableRowContainer columns={6}>
                              <TableItem>
                                <div key={assignee.id}>
                                  <MemberNameDiv
                                    onClick={() =>
                                      props.history.push(
                                        `/user/member-details?id=${assignee.id}`,
                                      )
                                    }
                                  >
                                    <CommImageNameComp
                                      imgSource={assignee.avatar || avatar5}
                                      name={`${assignee.first_name} ${assignee.last_name}`}
                                      size="36px"
                                    />
                                  </MemberNameDiv>
                                </div>
                              </TableItem>
                              <TableItem>
                                <CommonText>
                                  {assignee.checkedin &&
                                  assignee.checkedin.timestamp
                                    ? moment
                                        .unix(assignee.checkedin.timestamp)
                                        .format('h:mm a')
                                    : '-'}
                                  {assignee.checkedin &&
                                    assignee.checkedin.timestamp &&
                                    (moment
                                      .unix(assignee.checkedin.timestamp)
                                      .isAfter(moment(task.start_time)) ? (
                                      <LateTag>{t('late')}</LateTag>
                                    ) : (
                                      <GreenCircle>
                                        <img src={tick} alt="" width="100%" />
                                      </GreenCircle>
                                    ))}
                                </CommonText>
                              </TableItem>
                              <TableItem>
                                <CommonText>
                                  {assignee.checkedout &&
                                  assignee.checkedout.timestamp
                                    ? moment
                                        .unix(assignee.checkedout.timestamp)
                                        .format('h:mm a')
                                    : '-'}
                                  {assignee.checkedout &&
                                    assignee.checkedout.timestamp &&
                                    (moment
                                      .unix(assignee.checkedout.timestamp)
                                      .isAfter(moment(task.end_time)) ? (
                                      <LateTag>{t('late')}</LateTag>
                                    ) : (
                                      <GreenCircle>
                                        <img src={tick} alt="" width="100%" />
                                      </GreenCircle>
                                    ))}
                                </CommonText>
                              </TableItem>
                              <TableItem>
                                <CommonText>
                                  {assignee.checkedin &&
                                  assignee.checkedin.timestamp &&
                                  assignee.checkedout &&
                                  assignee.checkedout.timestamp
                                    ? toHHMMSS(
                                        assignee.checkedout.timestamp -
                                          assignee.checkedin.timestamp,
                                      )
                                    : '-'}
                                </CommonText>
                              </TableItem>
                              <TableItem>
                                <StatusBar
                                  status={
                                    assignee.status === 'Completed'
                                      ? 'success'
                                      : assignee.status === 'Missed'
                                      ? 'error'
                                      : 'warning'
                                  }
                                  style={{
                                    minWidth: '100px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  {assignee.status ? assignee.status : '-'}
                                </StatusBar>
                              </TableItem>
                              <TableItem>
                                <EvidenceIconSection>
                                  {assignee.evidence &&
                                    assignee.evidence.image && (
                                      <EvidenceIcon
                                        onClick={() =>
                                          popupToggle(assignee.evidence.image)
                                        }
                                        onMouseEnter={() =>
                                          toolTipChange(1, index)
                                        }
                                        onMouseLeave={() => toolTipChange(-1)}
                                      >
                                        <CommonImage
                                          src={imageIcon}
                                          alt=""
                                          size="28px"
                                        />
                                        {selectIndex === index &&
                                          toolTip === 1 && (
                                            <ToolTipContainer
                                              style={{
                                                position: 'relative',
                                                top: '-60px',
                                              }}
                                            >
                                              <InnerDivTooltip>
                                                <img
                                                  src={Chat}
                                                  alt="chat"
                                                  width="64px"
                                                />
                                                <TooltipText>Image</TooltipText>
                                              </InnerDivTooltip>
                                            </ToolTipContainer>
                                          )}
                                      </EvidenceIcon>
                                    )}
                                  {assignee.evidence &&
                                    assignee.evidence.audio && (
                                      <EvidenceIcon
                                        onClick={() =>
                                          popupToggleAudio(
                                            assignee.evidence.audio,
                                          )
                                        }
                                        onMouseEnter={() =>
                                          toolTipChange(2, index)
                                        }
                                        onMouseLeave={() => toolTipChange(-1)}
                                      >
                                        <CommonImage
                                          src={audioIcon}
                                          alt=""
                                          size="25px"
                                        />
                                        {selectIndex === index &&
                                          toolTip === 2 && (
                                            <ToolTipContainer
                                              style={{
                                                position: 'relative',
                                                top: '-57px',
                                              }}
                                            >
                                              <InnerDivTooltip>
                                                <img
                                                  src={Chat}
                                                  alt="chat"
                                                  width="64px"
                                                />
                                                <TooltipText>Audio</TooltipText>
                                              </InnerDivTooltip>
                                            </ToolTipContainer>
                                          )}
                                      </EvidenceIcon>
                                    )}
                                </EvidenceIconSection>
                              </TableItem>
                            </ClientLocationsTableRowContainer>
                          </Fragment>
                        );
                      })}
                    </>
                  )}
                </div>
              </CollapseSection>
            </TableRowAndBudgetCollapseContainer>
          ))}
        </>
      ) : (
        <NoDataComponent
          title={t('no_jobs_found')}
          padding="5vh 0"
          imageHeight="140px"
        />
      )}
    </Container>
  );
};

export default JobList;
