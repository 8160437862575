import { useEffect } from "react";

import {
  OnboardingStepsContainer,
  OnboardingArtworkDiv,
  OnboardingArtwork,
  SelectRoleContainer,
  RoleOption,
  RoleOptionImage,
  RoleOptionBadge,
} from "../OnboardingSteps/onboardingStepsStyles";
import { CommonText, CommonFlex } from "../../../styledComponents/common";

import artwork from "../../../assets/img/onboardingImg/background-artwork.png";
import plusIcon from "../../../assets/img/icons/plus-2.svg";
import arrowIcon from "../../../assets/img/icons/arrow-right.svg";

const AddOrJoinOrg = ({ joinRequest, getJoinRequests, history }) => {
  useEffect(() => {
    getJoinRequests();
  }, []);

  useEffect(() => {
    if (joinRequest && joinRequest.length > 0) {
      if (
        joinRequest[joinRequest.length - 1].status &&
        joinRequest[joinRequest.length - 1].status === "accepted"
      ) {
        history.push({
          pathname: "/user/jobs",
          state: { message: "user-onboard" },
        });
      } else {
        history.push("/setup/request-to-join");
      }
    }
  }, [joinRequest]);

  const handleJoinOrg = () => {
    history.push("/setup/request-to-join");
  };
  return (
    <OnboardingStepsContainer addOrJoin>
      <SelectRoleContainer>
        <CommonText name title fontSize="24px" mobileFontSize="16px">
          Hi, let us help complete your initial setup
        </CommonText>
        <CommonText
          $label
          fontSize="18px"
          mobileFontSize="15px"
          fontWeight="500"
          margin="45px 0 0"
        >
          Do you want to create or join an organization?
        </CommonText>
        <CommonFlex margin="25px 0 0" gap="25px">
          <RoleOption
            onClick={() => {
              localStorage.setItem("is_owner", true);
              history.push("/setup/onboarding");
            }}
          >
            <RoleOptionImage src={plusIcon} alt="" />
            <CommonText
              title
              fontSize="16px"
              mobileFontSize="15px"
              margin="25px 0 0 0"
              style={{ textAlign: "center" }}
            >
              Create A New
              <br /> Organization
            </CommonText>
            <RoleOptionBadge color="#9fdbd6" />
            <CommonText $label fontSize="12px" fontWeight="500" margin="0">
              For Business Owner/Admin
            </CommonText>
          </RoleOption>
          <RoleOption onClick={() => handleJoinOrg()}>
            <RoleOptionImage src={arrowIcon} alt="" />
            <CommonText
              title
              fontSize="16px"
              mobileFontSize="15px"
              margin="25px 0 0 0"
              style={{ textAlign: "center" }}
            >
              Join An Existing Organization
            </CommonText>
            <RoleOptionBadge color="#FADA91" />
            <CommonText $label fontSize="12px" fontWeight="500" margin="0">
              For Team Member/Employee
            </CommonText>
          </RoleOption>
        </CommonFlex>
      </SelectRoleContainer>
      <OnboardingArtworkDiv>
        <OnboardingArtwork src={artwork} alt="" />
      </OnboardingArtworkDiv>
    </OnboardingStepsContainer>
  );
};

export default AddOrJoinOrg;
