import styled from "styled-components";

export const ThemesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 35px);
  gap: 12px;
  justify-content: start;
`
export const ThemeBox = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  cursor: pointer;
  background-color: ${({color}) => color || `#D4F4EE`};
  &:hover{
    border-color: #4D94FB;
  }
`
export const WeekDaysContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 45px);
  gap: 10px;
  justify-content: start;
`
export const WeekdayBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 8px;
  border: ${({active, theme}) => active ? `1px solid ${theme.colors.main.primary}` : `1px solid ${theme.colors.border.secondary}`};
  font-size: 12px;
  font-weight : 500;
  color: ${({active, theme}) => active ? theme.colors.main.white : theme.colors.main.textMain};
  background-color: ${({active, theme}) => active ?  theme.colors.main.primary : theme.colors.main.white};
  cursor: pointer;
  &:hover{
    border-color: ${({theme}) => theme.colors.main.primary};
    box-shadow: ${({theme}) => `0 0 0 4px ${theme.colors.main.primaryLight}`};
  }
`
export const AdditionalOptionText = styled.p`
  margin: 0 30px;
  position: relative;
  top: -11px;
  background-color: ${({ theme }) => theme.colors.main.white};
  padding: 0 10px;
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
  font-weight: ${({theme}) => theme.fontWeights.semibold};
`;

export const RepeatSection = styled.div`
  display: grid;
  grid-template-columns: minmax(230px, 260px);
  gap: 15px;
  padding-left: 40px;
  margin: 7px 0 0 2px;
  justify-content: start;
  align-content: start;
  border-left: ${({theme}) => `2px solid ${theme.colors.border.secondaryLight}`};
`

export const MonthDateContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 35px);
  gap: 10px;
  justify-content: start;
`
export const MonthDateBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  border: ${({active, theme}) => active ? `1px solid ${theme.colors.main.primary}` : `1px solid ${theme.colors.border.secondary}`};
  font-size: ${({fontSize}) => fontSize || `12px`};
  font-weight : 600;
  color: ${({active, theme}) => active ? theme.colors.main.white : theme.colors.main.textMain};
  background-color: ${({active, theme}) => active ?  theme.colors.main.primary : theme.colors.main.white};
  cursor: pointer;
  &:hover{
    border-color: ${({theme}) => theme.colors.main.primary};
    box-shadow: ${({theme}) => `0 0 0 4px ${theme.colors.main.primaryLight}`};
  }
`
export const WeekNumberBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
`
export const ScheduleText = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 10px 15px;
  border-radius: 4px;
  background-color: #e4e7ebb0;
  font-style: italic;
  font-size: 12px;
  min-width: 370px;
  border-left: ${({theme}) => `10px solid ${theme.colors.main.primary}`};
`
export const DurationContainer = styled.div`
  display: flex;
  gap: 10px;
`
export const DurationText = styled.p`
  color: ${({theme}) => theme.colors.main.primary};
  font-size: 12px;
  margin: 0;
`