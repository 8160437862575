import { connect } from 'react-redux';

// Actions
import {
  addClientLocation,
  deleteClient,
  deleteClientLocation,
  getClientDetails,
  getClientLocation,
  updateClient,
  updateClientLocation,
} from '../clientsActions';
// Component
import ClientDetails from './ClientDetails';

// import {
//   getProjectShortList,
//   updateProject,
// } from "../../Projects/projectsActions";

// Store props to map with current state
const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,
  isLoading: state.client.isLoading,
  projectListIsLoading: state.project.isLoading,
  clientDetails: state.client.clientDetails,
  clientDeleteLoading: state.client.clientDeleteLoading,
  clientLocationDeleteLoading: state.client.clientLocationDeleteLoading,
  clientLocationList: state.client.clientLocationList,
  addOrUpdateclientLocalMapLoading:
    state.client.addOrUpdateclientLocalMapLoading,
  createdClientLocationInfo: state.client.createdClientLocationInfo,
  clientLocationCreateUpdateSuccess:
    state.client.clientLocationCreateUpdateSuccess,
  createdClientId: state.client.createdClientId,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getClientDetails: details => dispatch(getClientDetails({ ...details })),
  updateClient: details => dispatch(updateClient({ ...details })),
  deleteClient: details => dispatch(deleteClient({ ...details })),
  addClientLocation: details => dispatch(addClientLocation({ ...details })),
  getClientLocation: details => dispatch(getClientLocation({ ...details })),
  updateClientLocation: details =>
    dispatch(updateClientLocation({ ...details })),
  deleteClientLocation: details =>
    dispatch(deleteClientLocation({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(ClientDetails);
