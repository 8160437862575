import { connect } from 'react-redux';

import { getOfficesShortList } from '../../../OfficesNew/officesActions';
import {
  getOrganizationMembersList,
  getOrganizationMembersShortList,
} from '../../../Projects/projectsActions';
import {
  getRouteMapLocations,
  getSingleEmployeeClockInOutList,
  getTaskCheckInOutList,
} from '../routeMapActions';
import RouteMapFilter from './RouteMapFilter';

const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,
  memberShortListIsLoading: state.project.memberShortListIsLoading,
  orgMembersList: state.project.orgMembersList,
  orgMembersShortList: state.project.orgMembersShortList,
  officesShortList: state.office.officesShortList,
});

const mapDispatchToProps = dispatch => ({
  getRouteMapLocations: payload => dispatch(getRouteMapLocations(payload)),
  getOfficesShortList: payload => dispatch(getOfficesShortList(payload)),
  getOrganizationMembersList: details =>
    dispatch(getOrganizationMembersList({ ...details })),
  getOrganizationMembersShortList: payload =>
    dispatch(getOrganizationMembersShortList(payload)),
  getSingleEmployeeClockInOutList: payload =>
    dispatch(getSingleEmployeeClockInOutList(payload)),
  getTaskCheckInOutList: payload => dispatch(getTaskCheckInOutList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RouteMapFilter);
