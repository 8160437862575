import { useEffect, useRef } from "react";
import {
  ShowMoreAssigneeList,
  AssignListText,
} from "../../styledComponents/common";

const ProjectListPopup = ({ projectList }) => {
  const moreRef1 = useRef(null);

  useOutsideClickHandler(moreRef1);

  function useOutsideClickHandler(moreRef1) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (moreRef1.current && !moreRef1.current.contains(event.target)) {
          // setMoreAssignee(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [moreRef1]);
  }
  return (
    <ShowMoreAssigneeList
      ref={moreRef1}
      top="unset"
      left="-100px"
      bottom="38px"
      columns="auto"
    >
      {projectList.map((project, index) => (
        <AssignListText style={{ padding: "5px" }} key={index}>
          {project.name}
        </AssignListText>
      ))}
    </ShowMoreAssigneeList>
  );
};

export default ProjectListPopup;
