import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import BackButtonComponent from '../../../components/BackButtonComponent/BackButtonComponent';
import ButtonTextLoader from '../../../components/Loaders/ButtonTextLoader';
import { PrimaryButton, WhiteButton } from '../../../styledComponents/buttons';
import {
  AddClientContainer,
  AddClientContainerNew,
} from '../../../styledComponents/clients';
import {
  CardTitle,
  CommonFlex,
  CommonGrid,
  Container,
  ContentHeader,
  FormAlert,
  InputField,
  InputLabel,
  InputWithLabelSection,
  PageTitle,
} from '../../../styledComponents/common';
import { RedStar } from '../../../styledComponents/members';
import { checkHtml, checkUrl, validateEmail } from '../../../utils/helper';
import { DataAndMapContainer } from '../../FieldServices/JobSites/jobSitesStyles';
import { MapAndAlertContainer } from '../../FieldServices/RouteMap/routeMapStyles';

// import ClientMap from './ClientMap';

const AddClient = props => {
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [officeLocation, setOfficeLocation] = useState('');
  const [saveErrors, setSaveErrors] = useState({});
  const [save, setSave] = useState(false);
  const { t } = useTranslation();

  const { createdClientId } = props;

  const clearStates = () => {
    setClientName('');
    setClientEmail('');
    setPhoneNumber('');
    setSaveErrors({});
  };

  useEffect(() => {
    if (save && createdClientId !== null) {
      clearStates();
      props.history.push(`/user/customer-details?id=${createdClientId}`);
    }
  }, [createdClientId]);

  const onClientNameChange = e => {
    const { value } = e.target;
    setClientName(value);
    if (saveErrors && saveErrors['clientName']) {
      delete saveErrors['clientName'];
    }
  };

  const onEmailChange = e => {
    const { value } = e.target;
    setClientEmail(value);
    if (saveErrors && saveErrors['clientEmail']) {
      delete saveErrors['clientEmail'];
    }
  };

  const onAddressChange = e => {
    const { value } = e.target;
    setOfficeLocation(value);
  };

  const cancelFunction = () => {
    props.history.push({
      pathname: '/user/customers',
      state: { from: 'create' },
    });
  };

  const handleSave = () => {
    if (checkError()) {
      if (props.selectedOrganization && props.selectedOrganization.id) {
        let payload = {
          organization_id: props.selectedOrganization.id,
          name: clientName,
          // phone_number: phoneNumber ? phoneNumber : null,
          phone_number: phoneNumber
            ? phoneNumber.startsWith('+')
              ? phoneNumber
              : '+' + phoneNumber
            : null,
          email: clientEmail ? clientEmail : null,
          office_location: officeLocation ? officeLocation : null,
        };
        props.createClient(payload);
        setSave(true);
        // setTimeout(() => {
        //   props.history.push({
        //     pathname: "/user/customers",
        //     state: { from: "create" },
        //   });
        // }, 800);
      }
    }
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!clientName.trim()) {
      formIsValid = false;
      saveErrors['clientName'] = t('please_enter_customer_name');
    } else if (clientName.length > 256) {
      formIsValid = false;
      saveErrors['clientName'] = t(
        'please_enter_customer_name_within_256_characters',
      );
    } else if (checkUrl(clientName)) {
      formIsValid = false;
      saveErrors['clientName'] = t('client_name_can_not_contain_url');
    } else if (checkHtml(clientName)) {
      formIsValid = false;
      saveErrors['clientName'] = t('invalid_client_name');
    }
    if (!validateEmail(clientEmail)) {
      formIsValid = false;
      saveErrors['clientEmail'] = t('please_enter_a_valid_email_address');
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  return (
    <div className="content">
      <PageTitle>{t('customers')}</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => props.history.push('/user/customers')}
          subTitle="Back to all customers"
        />
      </ContentHeader>

      <Container style={{ padding: `30px` }}>
        <CardTitle>{t('add_customer_details')}</CardTitle>
        <DataAndMapContainer columns="30% 70%">
          <AddClientContainerNew>
            <InputWithLabelSection>
              <InputLabel>
                {t('customer_name')} <RedStar>*</RedStar>
              </InputLabel>
              <InputField
                type="text"
                placeholder={t('type_customer_name_here')}
                value={clientName}
                onChange={e => onClientNameChange(e)}
                error={
                  saveErrors &&
                  saveErrors['clientName'] &&
                  saveErrors['clientName'] !== undefined
                }
              />
              {saveErrors &&
                saveErrors['clientName'] &&
                saveErrors['clientName'] !== undefined && (
                  <FormAlert margin="0 0 0 5px">
                    {saveErrors['clientName']}
                  </FormAlert>
                )}
            </InputWithLabelSection>

            <InputWithLabelSection>
              <InputLabel>
                {t('email_address')} <RedStar>*</RedStar>
              </InputLabel>
              <InputField
                type="text"
                placeholder={t('type_email_address_here')}
                value={clientEmail}
                onChange={e => onEmailChange(e)}
                error={
                  saveErrors &&
                  saveErrors['clientEmail'] &&
                  saveErrors['clientEmail'] !== undefined
                }
              />
              {saveErrors &&
                saveErrors['clientEmail'] &&
                saveErrors['clientEmail'] !== undefined && (
                  <FormAlert margin="0 0 0 5px">
                    {saveErrors['clientEmail']}
                  </FormAlert>
                )}
            </InputWithLabelSection>

            <InputWithLabelSection>
              <InputLabel>{t('contact_number')}</InputLabel>
              <PhoneInput
                enableSearch
                country={'us'}
                value={phoneNumber}
                onChange={phone => setPhoneNumber(phone)}
                inputClass="phone-number-input"
                buttonClass="phone-number-flag-dropdown"
              />
            </InputWithLabelSection>

            {/* <InputWithLabelSection>
              <InputLabel>Location Name<RedStar>*</RedStar></InputLabel>
              <InputField
                type="text"
                placeholder="Type location name"
                value={officeLocation}
                onChange={(e) => onAddressChange(e)}
              />
            </InputWithLabelSection> */}

            <CommonFlex gap="15px" margin="20px 0 0 0">
              {/* <WhiteButton type="cancel" onClick={() => cancelFunction()}>
                Cancel
              </WhiteButton> */}
              <PrimaryButton
                width="100%"
                padding={props.isLoading ? '0 15px' : '0 35px'}
                onClick={!props.isLoading ? () => handleSave() : ''}
              >
                {props.isLoading ? (
                  <ButtonTextLoader loadingText="Saving" fontSize="13px" />
                ) : (
                  t('add_customer')
                )}
              </PrimaryButton>
            </CommonFlex>
          </AddClientContainerNew>
          <MapAndAlertContainer></MapAndAlertContainer>
        </DataAndMapContainer>
      </Container>
    </div>
  );
};

export default AddClient;
