import AddMultipleAssigneeDropdown from 'components/CustomDropdown/AddMultipleAssigneeDropdown';
import { workOrderShortListRequestFailureHandler } from 'custom_modules/WorkOrderNew/workOrderReducers';
import moment from 'moment';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Bean from '../../../assets/img/icons/delete.svg';
import Plus from '../../../assets/img/icons/plus.svg';
import BackButtonComponent from '../../../components/BackButtonComponent/BackButtonComponent';
import AddSingleMemberDropdown from '../../../components/CustomDropdown/AddSingleMemberDropdown';
import CreateProjectDropdown from '../../../components/DropdownStyle/CreateProjectDropdown';
import ButtonTextLoader from '../../../components/Loaders/ButtonTextLoader';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import Select from '../../../components/ReactSelectDropdown';
import { filterOption } from '../../../components/ReactSelectDropdown/ReactSelectDropdown';
import { ClearSearchButton } from '../../../components/Search/searchStyles';
import DatePicker from '../../../components/SingleDatePicker/SingleDatePicker';
import {
  ButtonIcon,
  PrimaryButton,
  RadiusButton,
  WhiteButtonWithIcon,
} from '../../../styledComponents/buttons';
import {
  CardTitle,
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonImage,
  CommonText,
  Container,
  ContentHeader,
  FirstWordRound,
  FormAlert,
  InputField,
  InputLabel,
  InputTextArea,
  InputWithLabelSection,
  PageTitle,
  RedStar,
} from '../../../styledComponents/common';
import {
  ToggleButton,
  ToggleButtonRound,
} from '../../../styledComponents/invoice';
import {
  ButtonFlexContainer,
  PaddingBox,
  UpperTitleButtonSection,
} from '../../../styledComponents/members';
import { checkHtml, checkUrl } from '../../../utils/helper';
import AddLocationModal from '../../ClientsNew/ClientDetails/AddLocationModal';
import { CommFirstRoundLetterComp } from '../../Teams/CommonComponents';
import TimeRangeComponent from '../../Timesheet/Daily/TimeRangeComponent';
import { AddAssigneeDropdown } from '../AddAssigneeDropdown';
import {
  AssigneeSection,
  InputSection,
  TimeRangeSection,
} from '../CreateJob/createTaskStyles';
import DeleteJobModal from '../DeleteJobModal';

const EditJob = ({
  selectedOrganization,
  isLoading,
  orgMembersList,
  orgMembersShortList,
  officesShortList,
  addTaskLocationLoading,
  createdTaskInfo,
  locationsList,
  createdTaskLocationInfo,
  memberShortListIsLoading,
  getOrganizationMembersList,
  getOrganizationMembersShortList,
  createTask,
  getTaskLocation,
  addTaskLocation,
  getOfficesShortList,
  getTaskType,
  taskTypeIsLoading,
  taskTypeList,
  history,

  //customer location
  clientLocationList,
  clearClientLocationsList,
  getclientLocalMapLoading,
  addOrUpdateclientLocalMapLoading,
  createdClientLocationInfo,

  getClientLocation,
  addClientLocation,
  updateClientLocation,

  //job
  createJobRequest,
  createdJob,
  createOrUpdateJobIsLoading,

  location,
  getJobDetails,

  // job Details
  jobDetails,
  getJobIsLoading,

  //Update Job
  updateJobRequest,

  //add and remove assignee
  addJobAssignee,
  removeJobAssignee,
  JobMemberIsLoading,
  loadingjobId,

  jobAssigneeUpdate,

  //Delete Job
  deleteJob,
  deletingJobId,
  jobDeleteLoading,
}) => {
  const { t, i18n } = useTranslation();

  const [jobName, setJobName] = useState('');
  const [selectedOffice, setSelectedOffice] = useState('');
  const [selectedWorkOrder, setSelectedWorkOrder] = useState('');
  const [officeOptions, setOfficeOptions] = useState([]);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [requiresApproval, setRequiresApproval] = useState(false);
  const [taskDescription, setTaskDescription] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [jobLocationOptions, setJobLocationOptions] = useState([]);
  // const [radiusInput, setRadiusInput] = useState(100);
  // const [isCustomRadius, setIsCustomRadius] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment(new Date()));
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [selectedTaskType, setSelectedTaskType] = useState('');
  const [popupOpen, setPopupOpen] = useState(false);

  const [saveErrors, setSaveErrors] = useState({});
  const radiusOptions = [20, 50, 100];
  const [statusOptions, setStatusOptions] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [workOrderOptions, setWorkOrderOptions] = useState([]);
  const [clientId, setClientId] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [jobId, setJobId] = useState(null);
  const [visibility, setVisibility] = useState(0);

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const [addLocationButton, setAddLocationButton] = useState(false);

  const jobStatusOptions = [
    {
      value: 'unscheduled',
      label: t('unscheduled'),
    },
    {
      value: 'ready to dispatch',
      label: t('ready_to_dispatch'),
    },
  ];

  const mapStatusToOption = status => {
    switch (status) {
      case 'unscheduled':
        return { value: 'unscheduled', label: 'Unscheduled' };
      case 'ready to dispatch':
        return { value: 'ready to dispatch', label: 'Ready to dispatch' };
      default:
        return { value: '', label: 'unknown' };
    }
  };

  // useEffect(() => {
  //   setSelectedDate(moment(new Date()));
  //   setStartTime('');
  //   setEndTime('');
  //   setSelectedAssignees([]);
  // }, [jobDetails]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      if (location.search) {
        const queryParsed = queryString.parse(location.search);
        let payload = {
          organization_id: selectedOrganization.id,
          workOrder_id: queryParsed.workOrder_id,
          job_id: queryParsed.id,
        };
        setJobId(queryParsed.id);

        getJobDetails(payload);
      }
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (jobDetails && !jobAssigneeUpdate) {
      setJobName(jobDetails.name);
      setSelectedWorkOrder({
        label: jobDetails.work_order.name,
        value: jobDetails.work_order.id,
        client_id: jobDetails.work_order.client_id,
        client_name: jobDetails.work_order.client_name,
      });
      if (jobDetails && jobDetails.description) {
        setTaskDescription(jobDetails.description);
      }

      if (jobDetails.location && jobDetails.location.id) {
        setSelectedLocation({
          value: jobDetails.location.id,
          label: jobDetails.location.name,
        });
      }
      if (jobDetails.location === null) {
        setSelectedLocation(null);
      }

      setSelectedTaskType(mapStatusToOption(jobDetails.status));

      if (jobDetails.required_start_date) {
        setSelectedDate(moment(jobDetails.required_start_date));
        setStartTime(moment(jobDetails.required_start_date).format('HH:mm:ss'));
      }
      if (jobDetails.required_end_date) {
        setEndTime(moment(jobDetails.required_end_date).format('HH:mm:ss'));
      }

      if (jobDetails.assignees && jobDetails.assignees.length > 0) {
        let newArr = [];
        jobDetails.assignees.forEach((assignee, i) => {
          newArr.push({
            ...assignee,
            name:
              `${assignee.first_name} ${assignee.last_name}`.trim() ||
              assignee.first_name,
            // user_id: assignee.id,
          });
        });

        setSelectedAssignees(newArr);
      }

      if (jobDetails.assignees && jobDetails.assignees.length === 0) {
        setSelectedAssignees([]);
      }

      setClientId(jobDetails.work_order.client_id);
    }
  }, [jobDetails]);

  useEffect(() => {
    if (jobDetails && jobAssigneeUpdate) {
      if (jobDetails.assignees && jobDetails.assignees.length > 0) {
        let newArr = [];
        jobDetails.assignees.forEach((assignee, i) => {
          newArr.push({
            ...assignee,
            name:
              `${assignee.first_name} ${assignee.last_name}`.trim() ||
              assignee.first_name,
            // user_id: assignee.id,
          });
        });

        setSelectedAssignees(newArr);
      }

      if (jobDetails.assignees && jobDetails.assignees.length === 0) {
        setSelectedAssignees([]);
      }
    }
  }, [jobDetails, jobAssigneeUpdate]);

  useEffect(() => {
    if (createdClientLocationInfo && createdClientLocationInfo.id) {
      setSelectedLocation({
        value: createdClientLocationInfo.id,
        label: createdClientLocationInfo.name,
      });
    }
  }, [createdClientLocationInfo]);

  useEffect(() => {
    setAddLocationButton(false);
    if (selectedOrganization && selectedOrganization.id) {
      getOrganizationMembersShortList({
        organization_id: selectedOrganization.id,
      });
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id && clientId) {
      getClientLocation({
        organization_id: selectedOrganization.id,
        client_id: clientId,
      });
    }
  }, [clientId]);

  useEffect(() => {
    if (clientLocationList && clientLocationList.length > 0) {
      const options = [];
      clientLocationList.map(item => {
        options.push({
          value: item.id,
          label: item.name,
        });
      });
      // options.unshift({ label: 'Select Location...', value: '' });
      setJobLocationOptions(options);
    } else if (clientLocationList && clientLocationList.length === 0) {
      setJobLocationOptions([]);
    }
  }, [clientLocationList]);

  useEffect(() => {
    if (taskTypeList && taskTypeList.length > 0) {
      let newArr = [];
      if (i18n.language && i18n.language === 'en') {
        taskTypeList.forEach(type => {
          newArr.push({
            label: type.name,
            value: type.id,
          });
        });
      } else {
        taskTypeList.forEach(type => {
          newArr.push({
            label: type.arabic_name,
            value: type.id,
          });
        });
      }
      setStatusOptions(newArr);
    }
  }, [taskTypeList]);

  // useEffect(() => {
  //   if (
  //     selectedOffice &&
  //     selectedOffice.label &&
  //     jobLocationOptions &&
  //     jobLocationOptions.length > 0
  //   ) {
  //     jobLocationOptions.forEach(location => {
  //       if (
  //         location.label === selectedOffice.label &&
  //         location.office &&
  //         location.office.id === selectedOffice.value
  //       ) {
  //         setSelectedLocation(location);
  //       }
  //     });
  //   }
  // }, [selectedOffice, jobLocationOptions]);

  useEffect(() => {
    if (createdJob && isSubmitted) {
      history.push({
        pathname: '/user/jobs',
        state: { from: 'create' },
      });
    }
  }, [createdJob]);

  const onTaskNameChange = e => {
    let errors = { ...saveErrors };
    delete errors['jobName'];
    setSaveErrors(errors);
    setJobName(e.target.value);
  };

  const onOfficeChange = e => {
    setSelectedOffice(e);
  };

  const onWorkOrderChange = e => {
    setSelectedWorkOrder(e);
    setClientId(e.client_id);
  };

  const approvalToggle = () => {
    setRequiresApproval(!requiresApproval);
  };

  const showAssignee = state => {
    setVisibility(state);
  };

  const updateAssingeeList = person => {
    if (selectedOrganization && selectedOrganization.id) {
      const queryParsed = queryString.parse(location.search);
      let payload = {
        organization_id: selectedOrganization.id,
        workOrder_id: queryParsed.workOrder_id,
        job_id: queryParsed.id,
        user_id: person.id,
      };

      addJobAssignee(payload);
    }
  };

  const handleDeleteMember = assigneeId => {
    if (selectedOrganization && selectedOrganization.id) {
      const queryParsed = queryString.parse(location.search);
      let payload = {
        organization_id: selectedOrganization.id,
        workOrder_id: queryParsed.workOrder_id,
        job_id: queryParsed.id,
        user_id: assigneeId,
      };
      removeJobAssignee(payload);
    }
  };

  const onTaskDescriptionChange = e => {
    let errors = { ...saveErrors };
    delete errors['taskDescription'];
    setSaveErrors(errors);
    setTaskDescription(e.target.value);
  };

  const onLocationChange = e => {
    setSelectedLocation(e);
  };

  const onDateChange = date => {
    setSelectedDate(date);
  };

  const handleStartTime = time => {
    if (time) {
      let errors = { ...saveErrors };
      delete errors['timeDifference'];
      setSaveErrors(errors);
      const start_time = time.replace(/ /g, '');
      setStartTime(moment(start_time, 'h:mmA').format('HH:mm:ss'));
    } else {
      setStartTime('');
    }
  };
  const handleEndTime = time => {
    if (time) {
      let errors = { ...saveErrors };
      delete errors['timeDifference'];
      setSaveErrors(errors);
      const end_time = time.replace(/ /g, '');
      setEndTime(moment(end_time, 'h:mmA').format('HH:mm:ss'));
    } else {
      setEndTime('');
    }
  };

  const onTaskTypeChange = e => {
    setSelectedTaskType(e);
  };

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };

  const handleSave = () => {
    if (checkError() && !createOrUpdateJobIsLoading) {
      if (
        selectedOrganization &&
        selectedOrganization.id &&
        selectedWorkOrder &&
        selectedWorkOrder.value
      ) {
        let assigneeIds = [];
        if (selectedAssignees && selectedAssignees.length > 0) {
          selectedAssignees.forEach(element => {
            assigneeIds.push(element.user_id);
          });
        }

        let payload = {
          organization_id: selectedOrganization.id,
          name: jobName,
          workOrder_id: selectedWorkOrder.value,
        };

        // if (assigneeIds && assigneeIds.length > 0) {
        //   payload.assignees = assigneeIds;
        // }

        // if (assigneeIds && assigneeIds.length === 0) {
        //   payload.assignees = [];
        // }

        if (taskDescription && taskDescription !== '') {
          payload.description = taskDescription;
        }

        if (selectedTaskType && selectedTaskType.value) {
          payload.status = selectedTaskType.value;
        }

        if (selectedLocation && selectedLocation !== '') {
          payload.location_id = selectedLocation.value;
        }

        if (
          selectedTaskType &&
          selectedTaskType.value === 'ready to dispatch'
        ) {
          payload.required_start_date = selectedDate.format("YYYY-MM-DDTHH:mm:ss");
          payload.start_time = moment(
            selectedDate.format("YYYY-MM-DD") + " " + startTime,
            "YYYY-MM-DD h:mmA"
          ).format();
          payload.end_time = moment(
            selectedDate.format("YYYY-MM-DD") + " " + endTime,
            "YYYY-MM-DD h:mmA"
          ).format();
        }
        if (location.search) {
          const queryParsed = queryString.parse(location.search);
          payload.job_id = queryParsed.id;
        }

        updateJobRequest(payload);
        setIsSubmitted(true);

        setTimeout(() => {
          history.push('/user/jobs');
        }, 1200);

      }
    }
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!jobName.trim()) {
      formIsValid = false;
      saveErrors['jobName'] = 'Job name is required';
    } else if (checkUrl(jobName)) {
      formIsValid = false;
      saveErrors['jobName'] = 'Job name can not contain url.';
    } else if (checkHtml(jobName)) {
      formIsValid = false;
      saveErrors['jobName'] = 'Invalid Job name';
    } else if (jobName.length > 255) {
      formIsValid = false;
      saveErrors['jobName'] = 'Maximum length 255 characters';
    }

    if (checkUrl(taskDescription)) {
      formIsValid = false;
      saveErrors['taskDescription'] = 'Job description can not contain url.';
    } else if (checkHtml(taskDescription)) {
      formIsValid = false;
      saveErrors['taskDescription'] = 'Invalid Job description';
    } else if (taskDescription.length > 512) {
      formIsValid = false;
      saveErrors['taskDescription'] = 'Maximum length 512 characters';
    }
    if (selectedTaskType && selectedTaskType.value === 'ready to dispatch') {
      if (selectedAssignees.length === 0) {
        formIsValid = false;
        saveErrors['selectedAssignees'] = 'Assignee is required';
      }
      if (!selectedDate) {
        formIsValid = false;
      }
      if (!startTime || !endTime) {
        formIsValid = false;
      }
      if (!selectedDate) {
        formIsValid = false;
      }
      if (!startTime || !endTime) {
        formIsValid = false;
      }

      if (
        moment(selectedDate.format('YYYY-MM-DD') + ' ' + endTime).diff(
          moment(selectedDate.format('YYYY-MM-DD') + ' ' + startTime),
        ) < 0.5
      ) {
        formIsValid = false;
        saveErrors['timeDifference'] = 'End time must be after start time';
      } else if (
        moment(selectedDate.format('YYYY-MM-DD') + ' ' + endTime).diff(
          moment(selectedDate.format('YYYY-MM-DD') + ' ' + startTime),
        ) < 1800000
      ) {
        formIsValid = false;
        saveErrors['timeDifference'] =
          'Minmium 30 minutes time difference required';
      }
      // if (
      //   moment(selectedDate.format('YYYY-MM-DD') + ' ' + startTime).diff(
      //     moment(),
      //   ) < 1
      // ) {
      //   formIsValid = false;
      //   saveErrors['timeDifference'] = "Past time can't be set";
      // }
    }

    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const handleJobDelete = () => {
    if (selectedOrganization && selectedOrganization.id) {
      let payload = {
        organization_id: selectedOrganization.id,
        workOrder_id: selectedWorkOrder.value,
      };
      if (location.search) {
        const queryParsed = queryString.parse(location.search);
        payload.job_id = queryParsed.id;
      }

      deleteJob(payload);
      setTimeout(() => {
        setModal(!modal);
        history.push({
          pathname: '/user/jobs',
          state: { from: 'details' },
        });
      }, 500);
    }
  };

  const clearStates = () => {
    clearClientLocationsList();
    setSelectedLocation(null);
    setSelectedDate(moment(new Date()));
    setJobName('');
    setSelectedWorkOrder('');
    setSelectedOffice('');
    setTaskDescription('');
    setJobLocationOptions([]);
    setSelectedTaskType('');
    setSaveErrors({});
    setStartTime('');
    setEndTime('');
    setClientId([]);
    setJobId(null);
    setSelectedAssignees([]);
    history.push('/user/jobs');
  };

  const locationInputOpen = () => {
    setAddLocationButton(true);
  };

  return (
    <div className="content">
      {clientId && selectedOrganization && selectedOrganization.id ? (
        <AddLocationModal
          isOpen={popupOpen}
          toggle={popupToggle}
          addClientLocation={addClientLocation}
          addOrUpdateclientLocalMapLoading={addOrUpdateclientLocalMapLoading}
          createdClientLocationInfo={createdClientLocationInfo}
          organization_id={selectedOrganization.id}
          client_id={clientId ? clientId : null}
          action="add"
        />
      ) : null}
      <PageTitle>{t('edit_job')}</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={clearStates}
          subTitle={t('back_to_all_jobs')}
        />
      </ContentHeader>
      <Container padding="30px" gap="20px">
        <UpperTitleButtonSection padding="0 0">
          <CardTitle>{t('add_job_details')}</CardTitle>
          <UpperTitleButtonSection padding="0 0" gap="0">
            <WhiteButtonWithIcon type="delete" fontSize="14px" onClick={toggle}>
              <ButtonIcon src={Bean} alt="" />
              {t('delete_job')}
            </WhiteButtonWithIcon>
            <DeleteJobModal
              isOpen={modal}
              toggle={toggle}
              handleDelete={handleJobDelete}
              isLoading={jobDeleteLoading}
            />
          </UpperTitleButtonSection>
        </UpperTitleButtonSection>
        {/* <CommonText name title fontSize="16px">
          {t('add_job_details')}
        </CommonText> */}
        {getJobIsLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            <InputSection>
              <InputWithLabelSection>
                <InputLabel>
                  {t('job_name')} <RedStar>*</RedStar>
                </InputLabel>
                <InputField
                  type="text"
                  placeholder={t('type_task_name')}
                  value={jobName}
                  onChange={e => onTaskNameChange(e)}
                  style={
                    saveErrors &&
                    saveErrors[`jobName`] && {
                      border: `1px solid #fe5969`,
                      backgroundColor: `rgba(252, 87, 104, 0.05)`,
                    }
                  }
                />
                {saveErrors && saveErrors['jobName'] && (
                  <FormAlert>{saveErrors['jobName']}</FormAlert>
                )}
              </InputWithLabelSection>

              <InputWithLabelSection>
                <InputLabel>
                  {t('work_order')} <RedStar>*</RedStar>
                </InputLabel>
                <Select
                  isSearchable
                  value={selectedWorkOrder}
                  isDisabled={true}
                  //options={workOrderOptions}
                  //placeholder={t('select_work_order')}
                  //isDisabled={officesShortList && officesShortList.length === 0}
                  // onChange={e => {
                  //   onWorkOrderChange(e);
                  // }}
                  styles={CreateProjectDropdown()}
                  filterOption={filterOption}
                />
              </InputWithLabelSection>

              <InputWithLabelSection>
                <InputLabel>{t('job_status')}</InputLabel>
                <Select
                  isSearchable
                  value={selectedTaskType}
                  options={jobStatusOptions}
                  placeholder={t('select_type')}
                  onChange={e => {
                    onTaskTypeChange(e);
                  }}
                  styles={CreateProjectDropdown()}
                />
              </InputWithLabelSection>
              {selectedWorkOrder.client_name && (
                <>
                  <InputWithLabelSection>
                    <InputLabel>{t('customer')}</InputLabel>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        history.push(
                          `/user/customer-details?id=${selectedWorkOrder.client_id}`,
                        )
                      }
                    >
                      <CommFirstRoundLetterComp
                        text={selectedWorkOrder.client_name}
                        backColor="3"
                        underline={false}
                      />
                    </div>
                  </InputWithLabelSection>
                </>
              )}

              <div />

              {/* <InputWithLabelSection>
            <InputLabel>Requires Approval</InputLabel>
            <CommonFlex>
              <ToggleButton
                onClick={() => approvalToggle()}
                active={requiresApproval}
              >
                <ToggleButtonRound active={requiresApproval} />
              </ToggleButton>
              <InputLabel>{requiresApproval ? `Yes` : `No`}</InputLabel>
            </CommonFlex>
          </InputWithLabelSection> */}

              <InputWithLabelSection style={{ gridColumn: `1 / span 2` }}>
                <InputLabel>{t('description')}</InputLabel>
                <InputTextArea
                  text="Description"
                  cols="35"
                  rows="7"
                  value={taskDescription}
                  placeholder={t('type_description_here')}
                  onChange={e => onTaskDescriptionChange(e)}
                  height="auto"
                  style={
                    saveErrors &&
                    saveErrors[`taskDescription`] && {
                      border: `1px solid #fe5969`,
                      backgroundColor: `rgba(252, 87, 104, 0.05)`,
                    }
                  }
                ></InputTextArea>
                {saveErrors &&
                  saveErrors['taskDescription'] &&
                  saveErrors['taskDescription'] !== undefined && (
                    <FormAlert>{saveErrors['taskDescription']}</FormAlert>
                  )}
              </InputWithLabelSection>
              {selectedLocation === null && !addLocationButton ? (
                <InputSection marginLeft="30px">
                  <div style={{ width: '60%' }}>
                    <InputLabel style={{ marginBottom: '10px' }}>
                      {t('location')}{' '}
                    </InputLabel>
                    <CommonText
                      fontSize="13px"
                      color="#2266EF"
                      margin="20px 0 0 0"
                      fontWeight="550"
                      style={{ cursor: 'pointer' }}
                      onClick={() => locationInputOpen()}
                    >
                      <ButtonIcon src={Plus} alt="" /> Add Location
                    </CommonText>
                  </div>
                </InputSection>
              ) : (
                <InputWithLabelSection>
                  <CommonGrid>
                    <InputLabel>
                      {t('location')} <RedStar>*</RedStar>
                    </InputLabel>
                    <ColoredText
                      fontSize="12px"
                      type="success"
                      hover
                      semibold
                      style={{ cursor: `pointer` }}
                      onClick={() => popupToggle()}
                    >
                      {t('add_new_location')}
                    </ColoredText>
                  </CommonGrid>
                  <Select
                    isSearchable
                    value={selectedLocation}
                    options={jobLocationOptions}
                    placeholder={t('select_location')}
                    onChange={e => {
                      onLocationChange(e);
                    }}
                    styles={CreateProjectDropdown()}
                    filterOption={filterOption}
                  />
                </InputWithLabelSection>
              )}

              {(selectedTaskType === '' ||
                selectedTaskType.value === 'unscheduled') && <br />}
              {selectedTaskType.value === 'ready to dispatch' && (
                <>
                  <AssigneeSection>
                    <InputLabel>{t('assignee')}</InputLabel>

                    {/* <AddMultipleAssigneeDropdown
                      numberOfVisibleMembers={8}
                      isLoading={memberShortListIsLoading}
                      assigneesList={selectedAssignees}
                      membersList={orgMembersList}
                      handleAssign={handleAssignMember}
                      handleRemove={handleRemoveMember}
                    /> */}

                    <AddAssigneeDropdown
                      isLoading={memberShortListIsLoading}
                      assignees={selectedAssignees}
                      visibilityChange={showAssignee}
                      updateState={updateAssingeeList}
                      membersList={orgMembersShortList}
                      getMembersList={getOrganizationMembersShortList}
                      selectedOrganization={selectedOrganization}
                      assigneeRemove={handleDeleteMember}
                      officeMemberIsLoading={JobMemberIsLoading}
                      loadingMemberId={loadingjobId}
                    />

                    {saveErrors && saveErrors['selectedAssignees'] && (
                      <FormAlert>{saveErrors['selectedAssignees']}</FormAlert>
                    )}
                  </AssigneeSection>

                  <InputWithLabelSection>
                    <InputLabel>{t('select_date')}</InputLabel>
                    <DatePicker
                      id="createIssuedDate"
                      date={selectedDate}
                      onDateChange={onDateChange}
                      dateDisplayFormat={'DD/MM/YYYY'}
                      placeholder={'Select date'}
                    />
                  </InputWithLabelSection>

                  <InputWithLabelSection>
                    <TimeRangeSection>
                      <InputLabel>
                        {t('start_time')}
                        <RedStar>*</RedStar>
                      </InputLabel>
                      <InputLabel>
                        {t('end_time')}
                        <RedStar>*</RedStar>
                      </InputLabel>
                    </TimeRangeSection>
                    <TimeRangeComponent
                      setStartTime={handleStartTime}
                      setEndTime={handleEndTime}
                      initialStartTime={
                        jobDetails &&
                        jobDetails.required_start_date &&
                        moment(jobDetails.required_start_date).format('HH:mm')
                      }
                      initialEndTime={
                        jobDetails &&
                        jobDetails.required_end_date &&
                        moment(jobDetails.required_end_date).format('HH:mm')
                      }
                    />
                    {saveErrors &&
                      saveErrors['timeDifference'] &&
                      saveErrors['timeDifference'] !== undefined && (
                        <FormAlert>{saveErrors['timeDifference']}</FormAlert>
                      )}
                  </InputWithLabelSection>
                </>
              )}

              <PrimaryButton
                disabled={!jobName || !selectedWorkOrder}
                onClick={() => handleSave()}
                margin="20px 0 0 0"
              >
                {createOrUpdateJobIsLoading ? (
                  <ButtonTextLoader
                    loadingText={t('updating')}
                    fontSize="13px"
                  />
                ) : (
                  t('update_job')
                )}
              </PrimaryButton>
              <div />
            </InputSection>
          </>
        )}
      </Container>
    </div>
  );
};

export default EditJob;
