//Actions
import { getOrganizationMembersShortList } from 'custom_modules/Projects/projectsActions';
import { connect } from 'react-redux';

import {
  getOfficeDetails,
  getOfficesShortList,
} from '../OfficesNew/officesActions';
import Payroll from './Payroll';
import {
  clearOneTimePaymentData,
  exportPayablesData,
  getHourlyPaymentHistory,
  getOnetimePaymentHistory,
  getPayablesData,
  sendHourlyPayment,
  sendOneTimePayment,
} from './payrollActions';

// Store props to map with current state
const mapStateToProps = state => ({
  paymentIsLoading: state.payroll.paymentIsLoading,
  payablesIsLoading: state.payroll.payablesIsLoading,
  paymentHistoryIsLoading: state.payroll.paymentHistoryIsLoading,
  selectedOrganization: state.organization.selectedOrganization,
  payablesData: state.payroll.payablesData,
  hourlyPaymentHistory: state.payroll.hourlyPaymentHistory,
  onetimePaymentHistory: state.payroll.onetimePaymentHistory,
  oneTimePaymentData: state.payroll.oneTimePaymentData,
  officesShortList: state.office.officesShortList,
  officeMembersList: state.office.officeMembersList,
  orgMembersShortList: state.project.orgMembersShortList,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getPayablesData: details => dispatch(getPayablesData({ ...details })),
  sendOneTimePayment: details => dispatch(sendOneTimePayment({ ...details })),
  sendHourlyPayment: details => dispatch(sendHourlyPayment({ ...details })),
  getHourlyPaymentHistory: details =>
    dispatch(getHourlyPaymentHistory({ ...details })),
  getOnetimePaymentHistory: details =>
    dispatch(getOnetimePaymentHistory({ ...details })),
  getOrganizationMembersShortList: details =>
    dispatch(getOrganizationMembersShortList({ ...details })),
  clearOneTimePaymentData: () => dispatch(clearOneTimePaymentData()),
  getOfficesShortList: params => dispatch(getOfficesShortList(params)),
  getOfficeDetails: params => dispatch(getOfficeDetails(params)),
  exportPayablesData: params => dispatch(exportPayablesData(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payroll);
