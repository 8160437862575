import {
  CommonText,
  CommonFlex,
  Container,
} from "../../../../styledComponents/common";
import {
  ColoredButtonWithIcon,
  ButtonIcon,
} from "../../../../styledComponents/buttons";
import { ProjectListTableHeader, ProjectListTableRow } from "./pmDetailsStyles";

import SearchWithButton from "../../../../components/Search/SearchWithButton";

import refreshIcon from "../../../../assets/img/icons/refresh_white.svg";

const projectsList = [
  "Common Box",
  "Davidson Chambers",
  "Project AGI",
  "Production Development",
  "Independent Contractor",
];

const PMDetails = (props) => {
  return (
    <>
      <CommonFlex justifyContent="space-between" margin="40px 0 0">
        <CommonFlex>
          <CommonText fontWeight="600">Synced Projects</CommonText>
          <SearchWithButton noLabel />
        </CommonFlex>
        <ColoredButtonWithIcon type="blue">
          <ButtonIcon src={refreshIcon} />
          Sync Projects & Tasks
        </ColoredButtonWithIcon>
      </CommonFlex>
      <Container>
        <ProjectListTableHeader>
          <CommonText>Project Name</CommonText>
        </ProjectListTableHeader>
        {projectsList &&
          projectsList.length &&
          projectsList.map((project, index) => {
            return (
              <ProjectListTableRow key={index}>
                <CommonText>{project}</CommonText>
              </ProjectListTableRow>
            );
          })}
      </Container>
    </>
  );
};

export default PMDetails;
