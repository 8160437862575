import moment from "moment-timezone";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import { WhiteButton, ColoredButton } from "styledComponents/buttons";

import "../../assets/css/shift-schedule.css";
import avatar5 from "../../assets/img/avatar_5.svg";
import LeftArrow from "../../assets/img/icons/arrow_left_white.svg";
import RightArrow from "../../assets/img/icons/arrow_right_white.svg";
import ComponentCircleLoader from "../../components/Loaders/ComponentCircleLoader";
import useWindowDimensions from "../../components/CustomHooks/GetWindowDimentions";
import { CommonGrid, PageTitle } from "../../styledComponents/common";
import {
  ScheduleContainer,
  TotalTimeContainer,
  TotalTimeDiv,
  TotalTimeLabel,
  TotalTimeText,
} from "../../styledComponents/timesheet";
import { getHours, toHHMMSS } from "../../utils/helper";
import TimesheetFilter from "../Timesheet/TimesheetFilter";
import CreateShift from "./CreateShift/createShiftContainer";
import DailyShift from "./DailyShift";
import DeleteShiftPopup from "./DeleteShiftPopup";
import EditShift from "./EditShift/editShiftContainer";
import ShiftDetailsPopup from "./ShiftDetailsPopup";
import ShowMorePopup from "./ShowMorePopup";
import CreateOptionPopup from "./CreateOptionPopup";

moment.locale("en", {
  week: {
    dow: 1,
    doy: 4,
  },
});
const localizer = momentLocalizer(moment);

const CalendarContainer = styled.div`
  height: 700px;
`;
const CalendarToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;
const MonthPickerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const NextPrevButton = styled.div`
  width: 36px;
  height: 36px;
  background-color: ${({ theme }) => theme.colors.main.primary};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.hover.primary};
  }
`;
const MonthChangeIcon = styled.img`
  width: 18px;
  height: 18px;
`;
const MonthPickerInput = styled.div`
  margin: 0px 15px;
  font-size: 13px;
  text-align: center;
  border: none;
  &:focus {
    outline: none;
  }
`;
const ViewButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const ViewButton = styled.div`
  display: flex;
  align-self: center;
  border-radius: ${({ theme }) => theme.button.borderRadius};
  border: ${({ theme }) => `1px solid ${theme.button.primary.bgColor}`};
  background-color: ${({ active, theme }) =>
    active ? theme.button.primary.bgColor : theme.colors.main.white};
  font-size: ${({ theme }) => theme.button.fontSize};
  color: ${({ active, theme }) =>
    active ? theme.colors.main.white : theme.button.primary.bgColor};
  padding: 6px 18px;
  cursor: pointer;
  /* &:hover {
    background-color: ${({ active }) => !active && `#eaf0f5`};
  } */
`;
const getTimes = (date) => {
  let startDate = moment(date).startOf("month");
  const endDate = moment(date).endOf("month");
  const times = [];
  while (
    startDate.isSameOrBefore(endDate) &&
    startDate.isSameOrBefore(moment())
  ) {
    times.push({
      title: "0h 00m",
      start: startDate.toDate(),
      end: startDate.toDate(),
      allDay: true,
    });
    startDate = moment(startDate).add(1, "day");
  }
  return times;
};

const CustomMonthPickerInput = ({ value, onClick }) => (
  <MonthPickerInput onClick={onClick}>{value}</MonthPickerInput>
);

const CustomToolbar = (props) => {
  const { goToBack, goToNext, view, onViewChange, selectedDate } = props;

  return (
    <CalendarToolbarContainer>
      <MonthPickerContainer>
        <NextPrevButton onClick={() => goToBack()}>
          <MonthChangeIcon src={LeftArrow} alt="" />
        </NextPrevButton>
        {/* <DatePicker
          selected={moment(selectedDate).toDate()}
          onChange={date => onMonthSelect(date)}
          customInput={<CustomMonthPickerInput />}
          dateFormat="MMMM, yyyy"
          showMonthYearPicker
          popperModifiers={{
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: 'viewport',
            },
          }}
        /> */}
        <NextPrevButton onClick={() => goToNext()}>
          <MonthChangeIcon src={RightArrow} alt="" />
        </NextPrevButton>
      </MonthPickerContainer>

      <PageTitle fontSize="15px">
        {" "}
        {view === "month"
          ? moment(selectedDate).format("MMMM YYYY")
          : view === "week"
          ? `${moment(selectedDate)
              .startOf("week")
              .format("DD MMM")} - ${moment(selectedDate)
              .endOf("week")
              .format("DD MMM , YYYY")}`
          : moment(selectedDate).format("LL")}{" "}
      </PageTitle>

      <ViewButtonContainer>
        <ViewButton
          active={view === "month"}
          onClick={() => onViewChange(Views.MONTH)}
        >
          Month
        </ViewButton>
        <ViewButton
          active={view === "week"}
          onClick={() => onViewChange(Views.WEEK)}
        >
          Week
        </ViewButton>
        <ViewButton
          active={view === "day"}
          onClick={() => onViewChange(Views.DAY)}
        >
          Day
        </ViewButton>
      </ViewButtonContainer>
    </CalendarToolbarContainer>
  );
};

const ShiftSchedule = (props) => {
  const [selectedMember, setSelectedMember] = useState("");
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [selectedShift, setSelectedShift] = useState(null);
  const [shiftsOnSelectedDate, setShiftsOnSelectedDate] = useState([]);
  const [selectedDateOfShowMore, setSelectedDateOfShowMore] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [ShiftsList, setShiftsList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showMoreIsOpen, setShowMoreIsOpen] = useState(false);
  const [createIsOpen, setCreateIsOpen] = useState(false);
  const [createOptionIsOpen, setCreateOptionIsOpen] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(null);

  const [view, setView] = useState(Views.MONTH);
  const { height, width } = useWindowDimensions();

  const {
    isLoading,
    selectedOrganization,
    officeShiftsList,
    jobShiftsList,
    deleteCallback,
    editCallback,
    editIsLoading,
    deleteIsLoading,
    createIsLoading,
    createdShiftInfo,
    history,
    deleteWorkOrderJob,
    workOrderJobDeleteIsLoading,
    deleteJobCallback,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      props.getOfficeShiftsList({
        organization_id: selectedOrganization.id,
        start_date: moment(selectedDate).startOf("month").format("YYYY-MM-DD"),
        end_date: moment(selectedDate).endOf("month").format("YYYY-MM-DD"),
      });
      props.getJobShiftsList({
        organization_id: selectedOrganization.id,
        start_date: moment(selectedDate).startOf("month").format("YYYY-MM-DD"),
        end_date: moment(selectedDate).endOf("month").format("YYYY-MM-DD"),
      });
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (
      createdShiftInfo &&
      !createIsLoading &&
      isCreated &&
      selectedOrganization &&
      selectedOrganization.id
    ) {
      onCreateChange(false);
      createToggle();
      let payload = {
        organization_id: selectedOrganization.id,
        start_date: moment(selectedDate).startOf("month").format("YYYY-MM-DD"),
        end_date: moment(selectedDate).endOf("month").format("YYYY-MM-DD"),
      };
      props.getOfficeShiftsList(payload);
      props.getJobShiftsList(payload);
    }
    return () => {
      onCreateChange(false);
    };
  }, [createdShiftInfo]);

  useEffect(() => {
    if (
      deleteCallback &&
      !deleteIsLoading &&
      isDeleted &&
      selectedOrganization &&
      selectedOrganization.id
    ) {
      onDeleteChange(false);
      deleteToggle();
      let payload = {
        organization_id: selectedOrganization.id,
        start_date: moment(selectedDate).startOf("month").format("YYYY-MM-DD"),
        end_date: moment(selectedDate).endOf("month").format("YYYY-MM-DD"),
      };
      props.getOfficeShiftsList(payload);
      props.getJobShiftsList(payload);
    }
  }, [deleteCallback]);

  useEffect(() => {
    if (
      deleteJobCallback &&
      !workOrderJobDeleteIsLoading &&
      isDeleted &&
      selectedOrganization &&
      selectedOrganization.id
    ) {
      onDeleteChange(false);
      deleteToggle();
      let payload = {
        organization_id: selectedOrganization.id,
        start_date: moment(selectedDate).startOf("month").format("YYYY-MM-DD"),
        end_date: moment(selectedDate).endOf("month").format("YYYY-MM-DD"),
      };
      props.getOfficeShiftsList(payload);
      props.getJobShiftsList(payload);
    }
  }, [deleteJobCallback]);

  useEffect(() => {
    if (
      editCallback &&
      !editIsLoading &&
      isEdited &&
      selectedOrganization &&
      selectedOrganization.id
    ) {
      onEditChange(false);
      editToggle();
      let payload = {
        organization_id: selectedOrganization.id,
        start_date: moment(selectedDate).startOf("month").format("YYYY-MM-DD"),
        end_date: moment(selectedDate).endOf("month").format("YYYY-MM-DD"),
      };
      props.getOfficeShiftsList(payload);
      props.getJobShiftsList(payload);
    }
    return () => {
      onEditChange(false);
    };
  }, [editCallback]);

  useEffect(() => {
    if (
      (officeShiftsList && officeShiftsList.length > 0) ||
      (jobShiftsList && jobShiftsList.length > 0)
    ) {
      setShiftsList([...officeShiftsList, ...jobShiftsList]);
    }
  }, [officeShiftsList, jobShiftsList]);

  const eventPropGetter = useCallback(
    (event) => ({
      ...(event && {
        style: {
          backgroundColor: event.theme_color ? event.theme_color : `#D2E4FE`,
          color: "#1f2939",
          fontSize: "12px",
          fontWeight: "700",
          borderRight:
            event.work_order && event.work_order.id && "6px solid #1158E5",
        },
      }),
    }),
    []
  );

  const selectTeamMember = (e) => {
    // let organizationId = selectedOrganization ? selectedOrganization.id : "";
    // const startDate = moment(selectedDate)
    //   .startOf("month")
    //   .format("YYYY-MM-DD");
    // const endDate = moment(selectedDate).endOf("month").format("YYYY-MM-DD");
    // if (organizationId) {
    //   const payload = {
    //     organization_id: organizationId,
    //     start_date: startDate,
    //     end_date: endDate,
    //     user_id: e.id,
    //   };
    //   if (selectedProject && selectedProject.value) {
    //     payload.project_id = selectedProject.value;
    //   }
    //   if (selectedTask && selectedTask.value) {
    //     payload.task_id = selectedTask.value;
    //   }
    //   if (
    //     selectedTimezone &&
    //     selectedTimezone.type === "Member" &&
    //     e.timezone &&
    //     e.timezone.tz
    //   ) {
    //     payload.timezone = e.timezone.tz;
    //     setSelectedTimezone({
    //       type: "Member",
    //       timezone: e.timezone.tz,
    //       offset: e.timezone.offset,
    //     });
    //   }
    //   setSelectedMember(e);
    //   getOtherTimesheets(payload);
    // }
  };

  const clearMember = () => {
    // let organizationId = selectedOrganization ? selectedOrganization.id : "";
    // const startDate = moment(selectedDate)
    //   .startOf("month")
    //   .format("YYYY-MM-DD");
    // const endDate = moment(selectedDate).endOf("month").format("YYYY-MM-DD");
    // if (organizationId) {
    //   const payload = {
    //     organization_id: organizationId,
    //     start_date: startDate,
    //     end_date: endDate,
    //   };
    //   if (selectedProject && selectedProject.value) {
    //     payload.project_id = selectedProject.value;
    //   }
    //   if (selectedTask && selectedTask.value) {
    //     payload.task_id = selectedTask.value;
    //   }
    //   if (selectedOrganization.timezone) {
    //     setSelectedTimezone({
    //       type: "Organization",
    //       timezone: selectedOrganization.timezone,
    //       offset: selectedOrganization.timezoneoffset,
    //     });
    //   }
    //   getOtherTimesheets(payload);
    // }
    // setSelectedMember("");
  };

  const goToNext = () => {
    if (selectedOrganization && selectedOrganization.id) {
      let currentMonthStart = moment(selectedDate)
        .startOf("month")
        .format("YYYY-MM-DD");
      let currentMonthEnd = moment(selectedDate)
        .endOf("month")
        .format("YYYY-MM-DD");
      let nextMonthDate = moment(selectedDate).add(1, "M").format("YYYY-MM-DD");
      let nextMonthStart = moment(nextMonthDate)
        .startOf("month")
        .format("YYYY-MM-DD");
      let nextMonthEnd = moment(nextMonthDate)
        .endOf("month")
        .format("YYYY-MM-DD");
      let nextWeekDate = moment(selectedDate)
        .add(1, "weeks")
        .format("YYYY-MM-DD");
      let nextWeekEnd = moment(nextWeekDate).endOf("week").format("YYYY-MM-DD");
      let nextDate = moment(selectedDate).add(1, "days").format("YYYY-MM-DD");
      let payload = {
        organization_id: selectedOrganization.id,
        start_date: nextMonthStart,
        end_date: nextMonthEnd,
      };
      if (view === "month") {
        props.getOfficeShiftsList(payload);
        props.getJobShiftsList(payload);
        setSelectedDate(nextMonthDate);
      } else if (view === "week") {
        if (moment(nextWeekEnd).isAfter(currentMonthEnd)) {
          props.getOfficeShiftsList(payload);
          props.getJobShiftsList(payload);
        }
        setSelectedDate(nextWeekDate);
      } else if (view === "day") {
        if (moment(nextDate).isAfter(currentMonthEnd)) {
          props.getOfficeShiftsList(payload);
          props.getJobShiftsList(payload);
        }
        setSelectedDate(nextDate);
      }
    }
  };
  const goToBack = () => {
    if (selectedOrganization && selectedOrganization.id) {
      let currentMonthStart = moment(selectedDate)
        .startOf("month")
        .format("YYYY-MM-DD");
      let currentMonthEnd = moment(selectedDate)
        .endOf("month")
        .format("YYYY-MM-DD");
      let prevMonthDate = moment(selectedDate)
        .subtract(1, "M")
        .format("YYYY-MM-DD");
      let prevMonthStart = moment(prevMonthDate)
        .startOf("month")
        .format("YYYY-MM-DD");
      let prevMonthEnd = moment(prevMonthDate)
        .endOf("month")
        .format("YYYY-MM-DD");
      let prevWeekDate = moment(selectedDate)
        .subtract(1, "weeks")
        .format("YYYY-MM-DD");
      let prevWeekStart = moment(prevWeekDate)
        .startOf("week")
        .format("YYYY-MM-DD");
      let prevDate = moment(selectedDate)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      let payload = {
        organization_id: selectedOrganization.id,
        start_date: prevMonthStart,
        end_date: prevMonthEnd,
      };
      if (view === "month") {
        props.getOfficeShiftsList(payload);
        props.getJobShiftsList(payload);
        setSelectedDate(prevMonthDate);
      } else if (view === "week") {
        if (moment(prevWeekStart).isBefore(currentMonthStart)) {
          props.getOfficeShiftsList(payload);
          props.getJobShiftsList(payload);
        }
        setSelectedDate(prevWeekDate);
      } else if (view === "day") {
        if (moment(prevDate).isBefore(currentMonthStart)) {
          props.getOfficeShiftsList(payload);
          props.getJobShiftsList(payload);
        }
        setSelectedDate(prevDate);
      }
    }
  };
  const onMonthSelect = (date) => {
    // let organizationId = selectedOrganization ? selectedOrganization.id : "";
    // const startDate = moment(date).format("YYYY-MM-DD");
    // const endDate = moment(date).endOf("month").format("YYYY-MM-DD");
    // setSelectedDate(date);
    // if (organizationId) {
    //   const payload = {
    //     organization_id: organizationId,
    //     start_date: startDate,
    //     end_date: endDate,
    //   };
    //   if (selectedProject && selectedProject.value) {
    //     payload.project_id = selectedProject.value;
    //   }
    //   if (selectedTask && selectedTask.value) {
    //     payload.task_id = selectedTask.value;
    //   }
    //   if (selectedMember && selectedMember.id) {
    //     payload.user_id = selectedMember.id;
    //   }
    //   if (selectedTimezone && selectedTimezone.type === "Member") {
    //     payload.timezone = selectedTimezone.timezone;
    //   }
    //   getOtherTimesheets(payload);
    // }
    // setSelectedDate(moment(date).format("YYYY-MM-DD"));
  };

  const onTimezoneSelect = (timezone) => {
    // let organizationId = selectedOrganization ? selectedOrganization.id : "";
    // const startDate = moment(selectedDate)
    //   .startOf("month")
    //   .format("YYYY-MM-DD");
    // const endDate = moment(selectedDate).endOf("month").format("YYYY-MM-DD");
    // setSelectedTimezone(timezone);
    // if (organizationId) {
    //   const payload = {
    //     organization_id: organizationId,
    //     start_date: startDate,
    //     end_date: endDate,
    //   };
    //   if (selectedProject && selectedProject.value) {
    //     payload.project_id = selectedProject.value;
    //   }
    //   if (selectedTask && selectedTask.value) {
    //     payload.task_id = selectedTask.value;
    //   }
    //   if (selectedMember && selectedMember.id !== "") {
    //     payload.user_id = selectedMember.id;
    //   }
    //   if (timezone && timezone.type === "Member") {
    //     payload.timezone = timezone.timezone;
    //   }
    //   getOtherTimesheets(payload);
    // }
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const showMoreToggle = () => {
    setShowMoreIsOpen(!showMoreIsOpen);
  };

  const createToggle = () => {
    setCreateIsOpen(!createIsOpen);
  };

  const createOptionToggle = () => {
    setCreateOptionIsOpen(!createOptionIsOpen);
  };

  const editToggle = () => {
    setEditIsOpen(!editIsOpen);
  };

  const deleteToggle = () => {
    setDeleteIsOpen(!deleteIsOpen);
  };

  const onCreateChange = (value) => {
    setIsCreated(value);
  };

  const onDeleteChange = (value) => {
    setIsDeleted(value);
  };

  const onEditChange = (value) => {
    setIsEdited(value);
  };

  const showDetails = (event) => {
    toggle();
    setSelectedShift(event);
  };

  const onShowMore = useCallback((events, date) => {
    setShiftsOnSelectedDate(events);
    setSelectedDateOfShowMore(date);
    showMoreToggle();
  }, []);

  const onSelectSlot = useCallback((slotInfo) => {
    if (
      selectedOrganization &&
      selectedOrganization.role &&
      (selectedOrganization.role === "admin" ||
        selectedOrganization.role === "owner") &&
      slotInfo &&
      slotInfo.slots &&
      slotInfo.slots[0]
    ) {
      setSelectedStartDate(slotInfo.slots[0]);
      createOptionToggle();
    }
  }, []);

  const onView = useCallback((newView) => setView(newView), [setView]);

  return (
    <div className="content">
      <ShiftDetailsPopup
        selectedOrganization={selectedOrganization}
        isOpen={isOpen}
        toggle={toggle}
        shift={selectedShift}
        deleteToggle={deleteToggle}
        editToggle={editToggle}
        history={history}
      />
      <ShowMorePopup
        selectedOrganization={selectedOrganization}
        isOpen={showMoreIsOpen}
        toggle={showMoreToggle}
        shifts={shiftsOnSelectedDate}
        selectedDate={selectedDateOfShowMore}
        deleteToggle={deleteToggle}
        editToggle={editToggle}
        setSelectedShift={setSelectedShift}
        history={history}
      />
      <CreateOptionPopup
        isOpen={createOptionIsOpen}
        toggle={createOptionToggle}
        createToggle={createToggle}
        history={history}
        selectedStartDate={selectedStartDate}
      />
      <CreateShift
        isOpen={createIsOpen}
        toggle={createToggle}
        selectedStartDate={selectedStartDate}
        onCreateChange={onCreateChange}
      />
      <EditShift
        isOpen={editIsOpen}
        toggle={editToggle}
        shift={selectedShift}
        onEditChange={onEditChange}
      />
      {selectedOrganization && selectedOrganization.id && (
        <DeleteShiftPopup
          isOpen={deleteIsOpen}
          toggle={deleteToggle}
          shift={selectedShift}
          organization_id={selectedOrganization.id}
          deleteShifts={props.deleteShifts}
          deleteCallback={deleteCallback}
          deleteIsLoading={deleteIsLoading}
          onDeleteChange={onDeleteChange}
          isDeleted={isDeleted}
          deleteWorkOrderJob={deleteWorkOrderJob}
          workOrderJobDeleteIsLoading={workOrderJobDeleteIsLoading}
        />
      )}

      <CommonGrid alignItem="end">
        {/* <TimesheetFilter
          page="monthly"
          selectedMember={selectedMember}
          selectMember={selectTeamMember}
          clearMember={clearMember}
          selectTimezone={onTimezoneSelect}
          selectedTimezone={selectedTimezone}
        /> */}
        <PageTitle>Shift & Schedule</PageTitle>
        {selectedOrganization &&
        selectedOrganization.role &&
        (selectedOrganization.role === "admin" ||
          selectedOrganization.role === "owner") ? (
          <CommonGrid>
            <WhiteButton primary onClick={() => createToggle()}>
              Create Office Schedule
            </WhiteButton>
            <ColoredButton
              onClick={() =>
                history.push({
                  pathname: "/user/create-job",
                  state: { jobStatus: "ready-to-dispatch" },
                })
              }
            >
              Create Job Schedule
            </ColoredButton>
          </CommonGrid>
        ) : null}
      </CommonGrid>
      <ScheduleContainer>
        {isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <CalendarContainer>
            <Calendar
              className="schedule-calendar"
              localizer={localizer}
              startAccessor={(event) => moment(event.start_time).toDate()}
              endAccessor={(event) => moment(event.end_time).toDate()}
              titleAccessor={(event) =>
                event.name
                  ? `${
                      view === "month"
                        ? moment(event.start_time).format("h:mm a")
                        : ``
                    } ${
                      view === "month"
                        ? width > 1550
                          ? event.name.length > 15
                            ? `${event.name.slice(0, 14)}...`
                            : event.name
                          : event.name.length > 11
                          ? `${event.name.slice(0, 10)}...`
                          : event.name
                        : event.name
                    }`
                  : moment(event.start_time).format("h:mm a")
              }
              events={ShiftsList}
              date={moment(selectedDate).toDate()}
              onNavigate={() => {}}
              views={["month", "week", "day"]}
              // defaultView="week"
              drilldownView="null"
              culture="en-GB"
              formats={{ dateFormat: "D" }}
              selectable
              onSelectEvent={(event) => showDetails(event)}
              // popupOffset={{x: 10, y: 20}}
              components={{
                toolbar: () => {
                  return (
                    <CustomToolbar
                      goToNext={goToNext}
                      goToBack={goToBack}
                      view={view}
                      onViewChange={onView}
                      selectedDate={selectedDate}
                    />
                  );
                },
              }}
              tooltipAccessor={(event) => `click to view details`}
              eventPropGetter={eventPropGetter}
              onShowMore={onShowMore}
              onSelectSlot={onSelectSlot}
              view={view}
            />
          </CalendarContainer>
        )}
      </ScheduleContainer>
    </div>
  );
};

export default ShiftSchedule;
