import { connect } from 'react-redux';

import {
  getOrganizationMembersList,
  getOrganizationMembersShortList,
} from '../../Projects/projectsActions';
import {
  getTimesheetApprovalAwaitingList,
  getTimesheetApprovalHistory,
} from '../timesheetApprovalActions';
import ApprovalFilter from './ApprovalFilter';

// Store props to map with current state
const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,
  organizationMembersList: state.project.orgMembersShortList,

  // pagination
  paginationSize: state.timesheetApproval.paginationSize,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getOrganizationMembersList: payload =>
    dispatch(getOrganizationMembersList(payload)),
  getOrganizationMembersShortList: params =>
    dispatch(getOrganizationMembersShortList(params)),
  getTimesheetApprovalAwaitingList: payload =>
    dispatch(getTimesheetApprovalAwaitingList(payload)),
  getTimesheetApprovalHistory: payload =>
    dispatch(getTimesheetApprovalHistory(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalFilter);
