import moment from 'moment/moment';

import {
  DELETE_JOB,
  DELETE_JOB_FAILURE,
  DELETE_JOB_SUCCESS,
  GET_JOBS_LIST,
  GET_JOBS_LIST_FAILURE,
  GET_JOBS_LIST_SUCCESS,
  GET_JOBS_SHORT_LIST,
  GET_JOBS_SHORT_LIST_FAILURE,
  GET_JOBS_SHORT_LIST_SUCCESS,
  GET_JOB_DETAILS,
  GET_JOB_DETAILS_FAILURE,
  GET_JOB_DETAILS_SUCCESS,
  JOB_ASSIGNEE_ADD,
  JOB_ASSIGNEE_ADD_FAILURE,
  JOB_ASSIGNEE_ADD_SUCCESS,
  JOB_ASSIGNEE_REMOVE,
  JOB_ASSIGNEE_REMOVE_FAILURE,
  JOB_ASSIGNEE_REMOVE_SUCCESS,
  UPDATE_JOB_STATUS,
  UPDATE_JOB_STATUS_FAILURE,
  UPDATE_JOB_STATUS_SUCCESS,
} from '../../modules/constants';
import { createNotification } from '../../modules/notificationManager';

export const jobListRequestHandler = (state, action) => {
  return {
    ...state,
    isLoadingJobs: true,
  };
};
export const jobListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    const list = JSON.parse(JSON.stringify(data.data.results));
    list.forEach(item => {
      const assigneesData = [...item.assignees];

      assigneesData.forEach((assignee, index) => {
        const checkedin = item.checkinout.find(
          obj => obj.event === 'checkin' && obj.user === assignee.id,
        );
        const checkedout = item.checkinout.find(
          obj => obj.event === 'checkout' && obj.user === assignee.id,
        );
        const evidence = item.evidences.find(
          obj => obj.created_by === assignee.id,
        );

        if (checkedin) {
          assigneesData[index].checkedin = checkedin;
        }
        if (checkedout) {
          assigneesData[index].checkedout = checkedout;
        }
        /* 
        +------------+-------------+---------------+-----------------+-------------+
        | Checked In | Checked Out | Job End Time  | Assignee Status | Results     |
        +------------+-------------+---------------+-----------------+-------------+
        | True       |             |               | False           | In Progress |
        +------------+-------------+---------------+-----------------+-------------+
        | True       | True        |               | True            | Completed   |
        +------------+-------------+---------------+-----------------+-------------+
        | False      | False       | In Range      | False           | To Do       |
        +------------+-------------+---------------+-----------------+-------------+
        | False      | False       | crossed       | false           | Missed      |
        +------------+-------------+---------------+-----------------+-------------+
  */
        if (assignee.assignee_status) {
          if (checkedin && checkedout) {
            assigneesData[index].status = 'Completed';
          }
        } else {
          if (checkedin) {
            if (!checkedout) {
              assigneesData[index].status = 'In Progress';
            }
          } else {
            if (
              !checkedout &&
              moment().unix() < moment(item.required_end_date).unix()
            ) {
              assigneesData[index].status = 'To Do';
            } else {
              assigneesData[index].status = 'Missed';
            }
          }
        }

        if (evidence) {
          assigneesData[index].evidence = evidence;
        }
      });
    });

    //just test purpose will remove it later
    // const employeeNoteText = 'Some text';
    // list.forEach(item => {
    //   item.employee_note = employeeNoteText;
    // });
    return {
      ...state,
      isLoadingJobs: false,
      totaljobsCount: data.data.count,
      pageSizeJob: data.data.page_size,
      jobList: list,
    };
  }
  return {
    ...state,
    isLoadingJobs: false,
    totaljobsCount: null,
    pageSizeJob: null,
    jobList: [],
  };
};
export const jobListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoadingJobs: false,
    totaljobsCount: null,
    pageSizeJob: null,
    jobList: [],
  };
};

// get Job details
export const jobDetailsRequestHandler = (state, action) => {
  return {
    ...state,
    getJobIsLoading: true,
  };
};

export const jobDetailsRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    const details = JSON.parse(JSON.stringify(data.data));
    const assigneesData = [...details.assignees];
    assigneesData.forEach((assignee, index) => {
      //check in/out
      const checkedin = details.checkinout.find(
        obj => obj.event === 'checkin' && obj.user === assignee.id,
      );
      const checkedout = details.checkinout.find(
        obj => obj.event === 'checkout' && obj.user === assignee.id,
      );
      const evidence = details.evidences.find(
        obj => obj.created_by === assignee.id,
      );

      if (checkedin) {
        assigneesData[index].checkedin = checkedin;
      }
      if (checkedout) {
        assigneesData[index].checkedout = checkedout;
      }
      // evidence
      if (evidence) {
        assigneesData[index].evidence = evidence;
      }
      //assignee status
      if (assignee.assignee_status) {
        if (checkedin && checkedout) {
          assigneesData[index].status = 'Completed';
        }
      } else {
        if (checkedin) {
          if (!checkedout) {
            assigneesData[index].status = 'In Progress';
          }
        } else {
          if (
            !checkedout &&
            moment().unix() < moment(details.required_end_date).unix()
          ) {
            assigneesData[index].status = 'To Do';
          } else {
            assigneesData[index].status = 'Missed';
          }
        }
      }
    });
    return {
      ...state,
      getJobIsLoading: false,
      jobDetails: details,
    };
  }
  return {
    ...state,
    getJobIsLoading: false,
    jobDetails: null,
  };
};

export const jobDetailsRequestFailureHandler = (state, action) => {
  createNotification(
    'error',
    'Could not fetch Job Details. Please try again!',
    3000,
  );
  return {
    ...state,
    getJobIsLoading: false,
    jobDetails: null,
  };
};

//remove assignee
export const removeJobAssigneeRequestHandler = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    JobMemberIsLoading: true,
    loadingjobId: payload.user_id,
  };
};

export const removeJobMemberRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
    userData,
  } = action;
  if (data && data.status === 204 && userData && userData.userId) {
    createNotification('success', 'Assignee removed successfully', 3000);
    if (state.jobDetails) {
      const details = { ...state.jobDetails };
      const filteredList = details.assignees.filter(
        member => member.id !== userData.userId,
      );
      details.assignees = [...filteredList];
      return {
        ...state,
        jobDetails: details,
        jobAssigneeUpdate: true,
        JobMemberIsLoading: false,
        loadingjobId: null,
      };
    }
    return {
      ...state,
      JobMemberIsLoading: false,
      loadingjobId: null,
    };
  }
  return {
    ...state,
    JobMemberIsLoading: false,
    loadingjobId: null,
  };
};

export const removeJobMemberRequestFailureHandler = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification('error', 'Something went wrong', 3000);
    return {
      ...state,
      JobMemberIsLoading: false,
      loadingjobId: null,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification('error', 'Failed!! please try again', 2000);
  }
  return {
    ...state,
    JobMemberIsLoading: false,
    loadingjobId: null,
  };
};

//add assignee
export const addJobAssigneeRequestHandler = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    JobMemberIsLoading: true,
    loadingjobId: payload.user_id,
    jobAssigneeUpdate: false,
  };
};

export const addJobAssigneeRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    createNotification('success', 'Assignee added successfully', 3000);
    if (state.jobDetails) {
      const details = { ...state.jobDetails };

      details.assignees = [...details.assignees, data.data.user];
      // console.log("job details from reducer", details)
      // console.log("response ", data.data)
      return {
        ...state,
        JobMemberIsLoading: false,
        loadingjobId: null,
        jobDetails: details,
        jobAssigneeUpdate: true,
      };
    }
    return {
      ...state,
      JobMemberIsLoading: false,
      loadingjobId: null,
      jobAssigneeUpdate: false,
    };
  }
  return {
    ...state,
    JobMemberIsLoading: false,
    loadingjobId: null,
    jobAssigneeUpdate: false,
  };
};

export const addJobAssigneeRequestFailureHandler = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification('error', 'Something went wrong', 3000);
    return {
      ...state,
      JobMemberIsLoading: false,
      loadingjobId: null,
      jobAssigneeUpdate: false,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification('error', 'Failed!! please try again', 2000);
  }
  return {
    ...state,
    JobMemberIsLoading: false,
    loadingjobId: null,
    jobAssigneeUpdate: false,
  };
};

// Delete Job
export const deleteJobHandler = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    jobDeleteLoading: true,
    deletingJobId: null,
  };
};
export const deleteJobSuccessHandler = (state, action) => {
  const {
    payload: { job_id },
  } = action;
  createNotification('success', 'Job deleted successfully', 3000);
    return {
      ...state,
      jobDeleteLoading: false,
      deletingJobId: job_id,
    };
};
export const deleteJobFailureHandler = (state, action) => {
  createNotification('error', 'Could not delete. Please try again!', 3000);
  return {
    ...state,
    jobDeleteLoading: false,
    deletingJobId: null,
  };
};

export const jobShortListRequestHandler = (state, action) => {
  return {
    ...state,
    isLoadingJobs: true,
  };
};
export const jobShortListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoadingJobs: false,
      jobShortList: data.data,
    };
  }
  return {
    ...state,
    isLoadingJobs: false,
    jobShortList: null,
  };
};
export const jobShortListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoadingJobs: false,
    jobShortList: null,
  };
};

//Job Status Update
export const jobUpdateRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const jobUpdateRequestSuccessHandler = (state, action) => {
  const {
    payload: { data, job_id },
  } = action;
  if (data && data.status === 200) {
    createNotification('success', 'Job updated successfully', 2000);
    const currentList = JSON.parse(JSON.stringify(state.jobList));

    return {
      ...state,
      isLoading: false,
      jobList: currentList.filter(job => job.id !== job_id),
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const jobUpdateRequestFailureHandler = (state, action) => {
  console.log('hit form reducer failure', action);
  if (action.payload.data === undefined) {
    createNotification(
      'error',
      'Something went wrong! Please try again.',
      3000,
    );
    return {
      ...state,
      isLoading: false,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification('error', 'Failed!! please try again', 2000);
  }
  return {
    ...state,
    isLoading: false,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_JOBS_LIST]: jobListRequestHandler,
  [GET_JOBS_LIST_SUCCESS]: jobListRequestSuccessHandler,
  [GET_JOBS_LIST_FAILURE]: jobListRequestFailureHandler,

  [DELETE_JOB]: deleteJobHandler,
  [DELETE_JOB_SUCCESS]: deleteJobSuccessHandler,
  [DELETE_JOB_FAILURE]: deleteJobFailureHandler,

  [GET_JOBS_SHORT_LIST]: jobShortListRequestHandler,
  [GET_JOBS_SHORT_LIST_FAILURE]: jobShortListRequestFailureHandler,
  [GET_JOBS_SHORT_LIST_SUCCESS]: jobShortListRequestSuccessHandler,

  [GET_JOB_DETAILS]: jobDetailsRequestHandler,
  [GET_JOB_DETAILS_SUCCESS]: jobDetailsRequestSuccessHandler,
  [GET_JOB_DETAILS_FAILURE]: jobDetailsRequestFailureHandler,

  [JOB_ASSIGNEE_REMOVE]: removeJobAssigneeRequestHandler,
  [JOB_ASSIGNEE_REMOVE_SUCCESS]: removeJobMemberRequestSuccessHandler,
  [JOB_ASSIGNEE_REMOVE_FAILURE]: removeJobMemberRequestFailureHandler,

  [JOB_ASSIGNEE_ADD]: addJobAssigneeRequestHandler,
  [JOB_ASSIGNEE_ADD_SUCCESS]: addJobAssigneeRequestSuccessHandler,
  [JOB_ASSIGNEE_ADD_FAILURE]: addJobAssigneeRequestFailureHandler,

  [UPDATE_JOB_STATUS]: jobUpdateRequestHandler,
  [UPDATE_JOB_STATUS_SUCCESS]: jobUpdateRequestSuccessHandler,
  [UPDATE_JOB_STATUS_FAILURE]: jobUpdateRequestFailureHandler,
};

// default initial state
const initialState = {
  isLoadingJobs: false,
  totaljobsCount: null,
  pageSizeJob: null,
  jobList: [],
  jobShortList: null,

  //Job details
  getJobIsLoading: false,
  jobDetails: null,

  JobMemberIsLoading: false,
  loadingjobId: null,

  jobAssigneeUpdate: false,

  jobDeleteLoading: false,
  deletingJobId: null,
  //job status
  isLoading: false,
};

export default function jobsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
