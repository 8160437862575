import React, { useState } from 'react';
import { Button, Modal, ModalBody, Spinner } from 'reactstrap';

const EvidenceModal = ({ isOpen, toggle, evidence }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <>
      {!imageLoaded && isOpen && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderTopColor: 'transparent',
            borderRadius: '50%',
            opacity: '50%',
          }}
        >
          <Spinner color="primary" />
        </div>
      )}
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        style={{
          marginTop: '5vh',
          width: '70vw',
          maxWidth: '512px',
          visibility: imageLoaded ? 'visible' : 'hidden',
        }}
      >
        <ModalBody
          style={{ padding: '10px', display: 'grid', position: 'relative' }}
        >
          <Button
            close
            onClick={toggle}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: '10px',
            }}
          >
            <span aria-hidden>&times;</span>
          </Button>

          <img
            src={evidence}
            alt=""
            style={{
              maxHeight: '70vh',
              margin: '0 auto',
              visibility: imageLoaded ? 'visible' : 'hidden',
            }}
            onLoad={handleImageLoad}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default EvidenceModal;
