import React from "react";
import styled from "styled-components";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Col,
  Label,
  Input,
  Button,
} from "reactstrap";
import { WhiteButton, ModalDeleteButton } from "../../styledComponents/buttons";
import {
  CardTitle,
  InputWithLabelSection,
  InputLabel,
  InputField,
  RedStar,
  FormAlert,
  CommonText,
  CommonFlex,
} from "../../styledComponents/common";
import { ColorCircle } from "../../styledComponents/dashboard";
import { CheckboxDiv, CheckboxTickIcon } from "../../styledComponents/settings";

import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";

import tickWhite from "../../assets/img/icons/tick_2.svg";

const CustomModal = styled(Modal)`
  max-width: 600px;
  margin-top: 0;

  @media screen and (min-width: 1400px) {
    margin-top: 10vh;
  }
`;
const CustomModalBody = styled(ModalBody)`
  padding: 30px;
`;
const ModalButtonSection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-end;
  grid-column-gap: 12px;
  margin-top: 25px;
`;

const FeaturesSection = styled.div`
  margin: 10px 0 20px;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.main.whiteBg};
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  border-radius: ${({ theme }) => theme.borderRadii.card};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 10px;
  justify-content: space-between;
`;
const LineDiv = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  box-sizing: none;
  width: 111%;
  margin-left: -29px;
`;

const colors = ["#73A8FC", "#3ECC92", "#B6B4FA", "#F59198", "#F9DD8F"];

const features = [
  "Timesheets",
  "Projects",
  "Tasks",
  "Teams",
  "Clients",
  "Members",
  "Invoices",
  "Screenshots",
  "Apps",
  "URLs",
  "Locations",
];

class OrganizationDeleteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      agreed: false,
    };
    this.toggle = this.toggle.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handlePasswordChange(e) {
    const { value } = e.target;
    this.setState({
      password: value,
    });
  }

  handleCheck() {
    this.setState({
      agreed: !this.state.agreed,
    });
  }

  handleDelete() {
    this.props.deleteOrg(this.state.password, this.state.agreed);
    this.setState({
      password: "",
      agreed: false,
    });
  }

  toggle() {
    this.setState({
      password: "",
      agreed: false,
    });
    this.props.toggle();
  }

  render() {
    const { isOpen, organization } = this.props;
    let title = "Delete";
    if (organization) {
      title = `Delete "${organization.name}"`;
    }
    return (
      <CustomModal isOpen={isOpen} toggle={this.toggle}>
        <CustomModalBody>
          <CardTitle>{title}</CardTitle>
          <CommonText margin="20px 0 0">
            Following features will also be deleted with your organization:
          </CommonText>
          <FeaturesSection>
            {features.map((feature, index) => (
              <CommonFlex key={index} gap="8px">
                <ColorCircle background={colors[index % 5]} size="10px" />
                <CommonText fontSize="13px">{feature}</CommonText>
              </CommonFlex>
            ))}
          </FeaturesSection>
          <LineDiv />
          <CommonText title fontSize="15px" margin="20px 0">
            Provide your password so that we can be sure its you. If you change
            your mind, contact our support to stop the deletion process.
          </CommonText>
          <InputWithLabelSection>
            <InputLabel>
              Your current password <RedStar>*</RedStar>
            </InputLabel>
            <InputField
              width="60%"
              type="password"
              value={this.state.password}
              onChange={(e) => {
                this.handlePasswordChange(e);
              }}
            />
          </InputWithLabelSection>
          <CommonFlex margin="10px 0 0 0" gap="10px">
            <CheckboxDiv
              active={this.state.agreed}
              onClick={() => this.handleCheck()}
            >
              <CheckboxTickIcon src={tickWhite} alt="" />
            </CheckboxDiv>
            <CommonText $label fontWeight="500">
              I know the consequences and agree to continue
            </CommonText>
          </CommonFlex>
          <ModalButtonSection>
            <WhiteButton type="cancel" onClick={this.toggle}>
              Cancel
            </WhiteButton>
            <ModalDeleteButton
              type="delete"
              onClick={() => this.handleDelete()}
              disabled={!this.state.agreed || this.state.password.length < 1}
            >
              Delete
            </ModalDeleteButton>
          </ModalButtonSection>
        </CustomModalBody>
      </CustomModal>
    );
  }
}

export default OrganizationDeleteModal;
