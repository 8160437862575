import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import GoogleMap from '../../../components/ReactGoogleMapsApi/GoogleMap';
import { TabSearchGrid } from '../../../styledComponents/ProjectsNew';
import {
  TabButton,
  TabButtonContainer,
} from '../../../styledComponents/buttons';
import {
  Container,
  FilterLabel,
  HeaderContainer,
  InputWithLabelSection,
  PageTitle,
} from '../../../styledComponents/common';
import {
  MapAndAlertContainer,
  MapTopAlert,
} from '../RouteMapNew/routeMapStyles';

const LiveLocation = ({
  liveLocationList,
  getLatestLocationList,
  selectedOrganization,
  liveLocationListLoading,
}) => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState('live');
  const [awayUsers, setAwayUsers] = useState([]);
  const [liveUsers, setLiveUsers] = useState([]);

  const [userCurrentLocation, setUserCurrentLocation] = useState(null);

  const onTabSwitch = value => {
    setSelectedTab(value);
  };

  useEffect(() => {
    fetch('https://hutils.loxal.net/whois')
      .then(resp => resp.json())
      .then(res => {
        if (res && res.ip) {
          setUserCurrentLocation({
            lat: res.latitude,
            lng: res.longitude,
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  },[])



  useEffect(() => {
    setAwayUsers(
      liveLocationList?.filter(item => {
        if (!item?.latest_location?.timestamp) return false;
        // const latestLocationTime = new Date(
        //   item.latest_location.timestamp * 1000
        // );
        const latestLocationTime = moment.unix(item.latest_location.timestamp);
        const currentTime = moment();
        const diff = currentTime.diff(latestLocationTime, 'minutes');
        return diff > 30;

        // const currentTime = new Date();
        // const diff = Math.abs(currentTime - latestLocationTime);
        // const minutes = Math.floor(diff / 1000 / 60);
        // return minutes > 30;
      }),
    );
    setLiveUsers(
      liveLocationList?.filter(item => {
        if (!item?.latest_location?.timestamp) return false;
        const latestLocationTime = moment.unix(item.latest_location.timestamp);
        const currentTime = moment();
        const diff = currentTime.diff(latestLocationTime, 'minutes');
        return diff <= 30;
        // const latestLocationTime = new Date(
        //   item.latest_location.timestamp * 1000
        // );
        // const currentTime = new Date();
        // const diff = Math.abs(currentTime - latestLocationTime);
        // const minutes = Math.floor(diff / 1000 / 60);
        // return minutes <= 30;
      }),
    );
  }, [liveLocationList]);

  useEffect(() => {
    // const startOfDay = new Date();
    // startOfDay.setHours(0, 0, 0, 0);

    // const endOfDay = new Date();
    // endOfDay.setHours(23, 59, 59, 999);
    if (selectedOrganization && selectedOrganization.id) {
      getLatestLocationList({
        organization_id: selectedOrganization.id,
        from: moment().startOf('day').unix(),
        to: moment().endOf('day').unix(),
      });
    }
  }, [selectedOrganization]);

  return (
    <div className="content">
      <PageTitle>{t('live_location')}</PageTitle>
      <HeaderContainer margin="20px 0">
        <TabSearchGrid columns="auto auto">
          <InputWithLabelSection>
            <FilterLabel>{t('type')}</FilterLabel>
            <TabButtonContainer>
              <TabButton
                onClick={() => onTabSwitch('live')}
                selected={selectedTab}
                index="live"
              >
                {t('live')}
              </TabButton>
              <TabButton
                onClick={() => onTabSwitch('away')}
                selected={selectedTab}
                index="away"
              >
                {t('last_detected_position')}
              </TabButton>
            </TabButtonContainer>
          </InputWithLabelSection>
        </TabSearchGrid>
      </HeaderContainer>
      <MapAndAlertContainer>
        {liveLocationListLoading ? (
          <MapTopAlert fontSize="15px" height="48px">
            <ComponentCircleLoader padding="10px 0" size={16} color="#fff" />
          </MapTopAlert>
        ) : selectedTab === 'away' && awayUsers && awayUsers.length === 0 ? (
          <MapTopAlert fontSize="15px" height="48px">
            {t('no_one_is_offline_now')}
          </MapTopAlert>
        ) : selectedTab === 'live' && liveUsers && liveUsers.length === 0 ? (
          <MapTopAlert fontSize="15px" height="48px">
            {t('no_one_is_live_now')}
          </MapTopAlert>
        ) : null}
        <GoogleMap
          userList={selectedTab === 'away' ? awayUsers : liveUsers}
          isAwayUsers={selectedTab === 'away'}
          userCurrentLocation={userCurrentLocation}
        />
      </MapAndAlertContainer>
    </div>
  );
};

export default LiveLocation;
