//Effects
import { all, put, takeLatest } from 'redux-saga/effects';

//Constants
import {
  DASHBOARD_TOP_LOW_PERFORMERS,
  DASHBOARD_TOP_LOW_PERFORMERS_FAILURE,
  DASHBOARD_TOP_LOW_PERFORMERS_SUCCESS,
  GET_DASHBOARD_CURRENT_JOBS,
  GET_DASHBOARD_CURRENT_JOBS_FAILURE,
  GET_DASHBOARD_CURRENT_JOBS_SUCCESS,
  GET_DASHBOARD_EMPLOYEE_STATUS,
  GET_DASHBOARD_EMPLOYEE_STATUS_FAILURE,
  GET_DASHBOARD_EMPLOYEE_STATUS_SUCCESS,
  GET_DASHBOARD_JOBS_TODAY,
  GET_DASHBOARD_JOBS_TODAY_FAILURE,
  GET_DASHBOARD_JOBS_TODAY_SUCCESS,
  GET_DASHBOARD_JOB_STATUS,
  GET_DASHBOARD_JOB_STATUS_FAILURE,
  GET_DASHBOARD_JOB_STATUS_SUCCESS,
  GET_DASHBOARD_LATE_COUNT,
  GET_DASHBOARD_LATE_COUNT_FAILURE,
  GET_DASHBOARD_LATE_COUNT_SUCCESS,
  GET_DASHBOARD_PERFORMANCE,
  GET_DASHBOARD_PERFORMANCE_FAILURE,
  GET_DASHBOARD_PERFORMANCE_SUCCESS,
  GET_DASHBOARD_PREVIOUS_WEEK_JOB_STATUS,
  GET_DASHBOARD_PREVIOUS_WEEK_JOB_STATUS_FAILURE,
  GET_DASHBOARD_PREVIOUS_WEEK_JOB_STATUS_SUCCESS,
  GET_DASHBOARD_RECENT_INVOICES,
  GET_DASHBOARD_RECENT_INVOICES_FAILURE,
  GET_DASHBOARD_RECENT_INVOICES_SUCCESS,
  GET_DASHBOARD_STATUS,
  GET_DASHBOARD_STATUS_FAILURE,
  GET_DASHBOARD_STATUS_SUCCESS,
  GET_DASHBOARD_TASK_PROGRESS,
  GET_DASHBOARD_TASK_PROGRESS_FAILURE,
  GET_DASHBOARD_TASK_PROGRESS_SUCCESS,
  GET_DASHBOARD_TOP_WORKORDER,
  GET_DASHBOARD_TOP_WORKORDER_FAILURE,
  GET_DASHBOARD_TOP_WORKORDER_SUCCESS,
  GET_DASHBOARD_USER_ANALYTICS,
  GET_DASHBOARD_USER_ANALYTICS_FAILURE,
  GET_DASHBOARD_USER_ANALYTICS_SUCCESS,
} from '../../modules/constants';
//Service
import { defaultApi } from '../../utils/axiosApi';

//New Api calls

export function* dashboardJobsTodayRequest({ payload }) {
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/jobs/today/`;
    let method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_DASHBOARD_JOBS_TODAY_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_DASHBOARD_JOBS_TODAY_FAILURE,
      payload: err,
    });
  }
}

export function* dashboardEmployeeStatusRequest({ payload }) {
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/jobs/online_count/`;
    let method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_DASHBOARD_EMPLOYEE_STATUS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_DASHBOARD_EMPLOYEE_STATUS_FAILURE,
      payload: err,
    });
  }
}

export function* dashboardTopWorkOderRequest({ payload }) {
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/timesheets/top_work_orders/`;
    let method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_DASHBOARD_TOP_WORKORDER_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_DASHBOARD_TOP_WORKORDER_FAILURE,
      payload: err,
    });
  }
}

export function* dashboardJobStatusRequest({ payload }) {
  const { organization_id, min_start_date, max_start_date } = payload;
  try {
    const url = `${organization_id}/jobs/overview/?min_start_date=${min_start_date}&max_start_date=${max_start_date}`;
    let method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_DASHBOARD_JOB_STATUS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_DASHBOARD_JOB_STATUS_FAILURE,
      payload: err,
    });
  }
}

export function* dashboardPreviousWeekJobStatusRequest({ payload }) {
  const { organization_id, min_start_date, max_start_date } = payload;
  try {
    const url = `${organization_id}/jobs/overview/?min_start_date=${min_start_date}&max_start_date=${max_start_date}`;
    let method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_DASHBOARD_PREVIOUS_WEEK_JOB_STATUS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_DASHBOARD_PREVIOUS_WEEK_JOB_STATUS_FAILURE,
      payload: err,
    });
  }
}

export function* dashboardCurrentWeekJobsRequest({ payload }) {
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/jobs/current_jobs/`;
    let method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_DASHBOARD_CURRENT_JOBS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_DASHBOARD_CURRENT_JOBS_FAILURE,
      payload: err,
    });
  }
}

export function* dashboardRecentInvoicesRequest({ payload }) {
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/invoices/recent_invoices/`;
    let method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_DASHBOARD_RECENT_INVOICES_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_DASHBOARD_RECENT_INVOICES_FAILURE,
      payload: err,
    });
  }
}

//old api calls
export function* dashboardStatusRequest({ payload }) {
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/field-service/dashboard/status/`;
    let method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_DASHBOARD_STATUS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_DASHBOARD_STATUS_FAILURE,
      payload: err,
    });
  }
}

export function* dashboardTaskProgressRequest({ payload }) {
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/field-service/dashboard/task-progress/`;
    let method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_DASHBOARD_TASK_PROGRESS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_DASHBOARD_TASK_PROGRESS_FAILURE,
      payload: err,
    });
  }
}

export function* dashboardPeformanceRequest({ payload }) {
  // const {date} = payload;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/field-service/dashboard/performance/`;
    let method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_DASHBOARD_PERFORMANCE_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_DASHBOARD_PERFORMANCE_FAILURE,
      payload: err,
    });
  }
}
export function* dashboardLateCountRequest({ payload }) {
  // const { date } = payload;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/field-service/dashboard/late-count/`;
    let method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_DASHBOARD_LATE_COUNT_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_DASHBOARD_LATE_COUNT_FAILURE,
      payload: err,
    });
  }
}

export function* dashboardTopLowPerformersRequest({ payload }) {
  // const { } = payload;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/field-service/dashboard/top-low-performers/`;
    let method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: DASHBOARD_TOP_LOW_PERFORMERS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: DASHBOARD_TOP_LOW_PERFORMERS_FAILURE,
      payload: err,
    });
  }
}

export function* dashboardUserAnalyticsRequest({ payload }) {
  // const { date } = payload;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/field-service/dashboard/today-activity/`;
    let method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_DASHBOARD_USER_ANALYTICS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_DASHBOARD_USER_ANALYTICS_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    //new api
    takeLatest(GET_DASHBOARD_STATUS, dashboardStatusRequest),
    takeLatest(GET_DASHBOARD_TASK_PROGRESS, dashboardTaskProgressRequest),
    takeLatest(GET_DASHBOARD_PERFORMANCE, dashboardPeformanceRequest),
    takeLatest(GET_DASHBOARD_LATE_COUNT, dashboardLateCountRequest),
    takeLatest(DASHBOARD_TOP_LOW_PERFORMERS, dashboardTopLowPerformersRequest),
    takeLatest(GET_DASHBOARD_USER_ANALYTICS, dashboardUserAnalyticsRequest),
    takeLatest(GET_DASHBOARD_JOBS_TODAY, dashboardJobsTodayRequest),
    takeLatest(GET_DASHBOARD_EMPLOYEE_STATUS, dashboardEmployeeStatusRequest),
    takeLatest(GET_DASHBOARD_TOP_WORKORDER, dashboardTopWorkOderRequest),
    takeLatest(GET_DASHBOARD_JOB_STATUS, dashboardJobStatusRequest),
    takeLatest(GET_DASHBOARD_PREVIOUS_WEEK_JOB_STATUS,dashboardPreviousWeekJobStatusRequest),
    takeLatest(GET_DASHBOARD_CURRENT_JOBS, dashboardCurrentWeekJobsRequest),
    takeLatest(GET_DASHBOARD_RECENT_INVOICES, dashboardRecentInvoicesRequest),
  ]);
}
