import moment from "moment";
import { toHHMMSS } from "./helper";

const getHoursLabel = (time) => {
  const hours = time.split(":");
  return `${hours[0]}h ${hours[1]}m`;
};
const drawBlankBar = (
  ctx,
  x,
  y,
  width,
  height,
  radius,
  fill,
  stroke,
  fillColor
) => {
  if (typeof stroke === "undefined") {
    stroke = true;
  }
  if (typeof radius === "undefined") {
    radius = 5;
  }
  if (typeof radius === "number") {
    radius = { tl: radius, tr: radius, br: radius, bl: radius };
  } else {
    var defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
    for (var side in defaultRadius) {
      radius[side] = radius[side] || defaultRadius[side];
    }
  }
  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(
    x + width,
    y + height,
    x + width - radius.br,
    y + height
  );
  ctx.lineTo(x + radius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();
  if (fill) {
    ctx.fillStyle = fillColor || "red";
    ctx.fill();
  }
  if (stroke) {
    ctx.stroke();
  }
};

export const drawBlankBarPlugin = () => {
  return {
    id: "custom_blank_bar",
    beforeDraw: (chart) => {
      const ctx = chart.ctx;
      const xAxis = chart.scales["x"];
      const yAxis = chart.scales["y"];
      const barWidth = chart.getDatasetMeta(0).data[0].width;
      ctx.save();
      chart.data.labels.forEach((l, i) => {
        const value = chart.data.datasets[0].data[i];
        if (value === 0) {
          const x = xAxis.getPixelForValue(l) - barWidth / 2;
          drawBlankBar(
            ctx,
            x,
            yAxis.bottom - yAxis.height,
            barWidth,
            yAxis.height,
            4,
            true,
            false,
            "#f3f5f9"
          );
        }
      });
      ctx.restore();
    },
  };
};
export const projectChartOptions = (type, currency) => {
  return {
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#E4F1EF",
        titleFont: {
          size: 11,
          weight: "normal",
        },
        titleColor: "rgba(47, 57, 78, 0.7)",
        bodyFont: {
          size: 13,
          weight: "normal",
        },
        bodyColor: "#2f394e",
        borderColor: "#20bead",
        borderWidth: 1,
        displayColors: false,
        caretPadding: 8,
        callbacks: {
          label: (item) => {
            if (type === "cost") {
              return `Cost: ${currency} ${item.dataset.data[item.dataIndex]}`;
            } else if (type === "time-spent") {
              return `Time spent: ${getHoursLabel(
                toHHMMSS(Math.floor(item.dataset.data[item.dataIndex] * 3600))
              )}`;
            }
          },
        },
      },
    },
    scales: {
      x: {
        grid: { display: false },
      },
      y: {
        min: 0,
        beginAtZero: true,
        grid: {
          display: true,
          color: "rgba(224, 229, 240,0.5)",
        },
        ticks: { min: 0 },
      },
    },
  };
};
export const reportChartOptions = () => {
  return {
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#2f394e",
        titleFont: {
          size: 11,
          weight: "normal",
        },
        titleColor: "#fff",
        bodyFont: {
          size: 12,
          weight: "normal",
        },
        bodyColor: "#fff",
        displayColors: false,
        caretPadding: 8,
        callbacks: {
          label: (item) => {
            return `Average Activity: ${item.dataset.data[item.dataIndex]}%`;
          },
        },
      },
    },
    scales: {
      y: {
        min: 0,
        // max: 100,
        beginAtZero: true,
        ticks: {
          color: "rgba(47, 57, 78, 0.7)",
        },
        grid: {
          color: "#F5F6F9",
        },
      },
      x: {
        grid: {
          display: false,
          color: "#F5F6F9",
        },
        ticks: {
          color: "rgba(47, 57, 78, 0.7)",
        },
      },
    },
  };
};
export const totalHourCardChartOptions = (options) => {
  return {
    maintainAspectRatio: false,
    responsive: true,
    layout: {
      padding: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        xAlign: "center",
        yAlign: "bottom",
        backgroundColor: "#1F2939",
        titleColor: "#fff",
        bodyColor: "#fff",
        displayColors: false,
        caretPadding: 8,
        // callbacks: {
        //   title: () => {
        //     return null;
        //   },
        //   label: (item) => {
        //     if (options.activity) {
        //       return `${item.dataset.data[item.dataIndex]}%`;
        //     }
        //     return `${getHoursLabel(
        //       toHHMMSS(item.dataset.data[item.dataIndex])
        //     )}`;
        //   },
        // },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: options.tick,
          color: "rgba(61, 77, 105, 0.7)",
          font: {
            size: 12,
          },
          padding: 0,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };
};
export const topProjectsCardChartOptions = () => {
  return {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: { display: false },
      title: { display: false },
      tooltip: {
        displayColors: false,
        caretPadding: 8,
        // callbacks: {
        //   label: (item) => {
        //     return `# of tasks: ${getHoursLabel(
        //       toHHMMSS(Math.floor(item.dataset.data[item.dataIndex]))
        //     )}`;
        //   },
        // },
      },
    },
    cutout: "50%",
  };
};
