import { connect } from "react-redux";

import Timeline from "./Timeline";

import { getRouteMapLocations } from "../routeMapActions";

const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  organizationMembersList: state.project.orgMembersList,
  orgMembersShortList: state.project.orgMembersShortList,
  memberShortListIsLoading: state.project.memberShortListIsLoading,
  officesShortList: state.office.officesShortList,
});

const mapDispatchToProps = (dispatch) => ({
  getRouteMapLocations: (payload) => dispatch(getRouteMapLocations(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Timeline);
