//Effects
import { all, delay, put, takeLatest, throttle } from 'redux-saga/effects';

//Constants
import {
  ENABLE_INTEGRATION_WORKFLOW,
  ENABLE_INTEGRATION_WORKFLOW_FAILURE,
  ENABLE_INTEGRATION_WORKFLOW_SUCCESS,
  GET_ALL_INTEGRATIONS_LIST,
  GET_ALL_INTEGRATIONS_LIST_FAILURE,
  GET_ALL_INTEGRATIONS_LIST_SUCCESS,
  GET_ENABLED_INTEGRATIONS_LIST,
  GET_ENABLED_INTEGRATIONS_LIST_FAILURE,
  GET_ENABLED_INTEGRATIONS_LIST_SUCCESS,
  GET_INTEGRATIONS_FEATURES,
  GET_INTEGRATIONS_FEATURES_FAILURE,
  GET_INTEGRATIONS_FEATURES_SUCCESS,
  GET_INTEGRATION_ENABLED_WORKFLOWS_LIST,
  GET_INTEGRATION_ENABLED_WORKFLOWS_LIST_FAILURE,
  GET_INTEGRATION_ENABLED_WORKFLOWS_LIST_SUCCESS,
  GET_INTEGRATION_WORKFLOWS_LIST,
  GET_INTEGRATION_WORKFLOWS_LIST_FAILURE,
  GET_INTEGRATION_WORKFLOWS_LIST_SUCCESS,
  RUN_INTEGRATION_WORKFLOW,
  RUN_INTEGRATION_WORKFLOW_FAILURE,
  RUN_INTEGRATION_WORKFLOW_SUCCESS,
  UPDATE_INTEGRATIONS_FEATURES,
  UPDATE_INTEGRATIONS_FEATURES_FAILURE,
  UPDATE_INTEGRATIONS_FEATURES_SUCCESS,
} from '../../modules/constants';
//Service
import { defaultApi } from '../../utils/axiosApi';

export function* getIntegrationsFeaturesRequest({ payload }) {
  if (!payload) return;
  const { organization_id, name } = payload;
  try {
    const url = `${organization_id}/integrations/feature-settings/?name=${name}`;
    const response = yield defaultApi(url, 'GET');
    yield put({
      type: GET_INTEGRATIONS_FEATURES_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_INTEGRATIONS_FEATURES_FAILURE,
      payload: err,
    });
  }
}

export function* updateIntegrationsFeaturesRequest({ payload }) {
  if (!payload) return;
  const { organization_id, name, id, ...details } = payload;
  try {
    const url = `${organization_id}/integrations/feature-settings/${id}/?name=${name}`;
    const response = yield defaultApi(url, 'PATCH', details);
    yield put({
      type: UPDATE_INTEGRATIONS_FEATURES_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: UPDATE_INTEGRATIONS_FEATURES_FAILURE,
      payload: err,
    });
  }
}

export function* getAllIntegrationsListRequest({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/integrations/`;
    const response = yield defaultApi(url, 'GET');
    yield put({
      type: GET_ALL_INTEGRATIONS_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_ALL_INTEGRATIONS_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* getIntegrationWorkflowsListRequest({ payload }) {
  if (!payload) return;
  const { organization_id, integration_id } = payload;
  try {
    const url = `${organization_id}/integrations/${integration_id}/workflows/`;
    const response = yield defaultApi(url, 'GET');
    yield put({
      type: GET_INTEGRATION_WORKFLOWS_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_INTEGRATION_WORKFLOWS_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* getEnabledIntegrationsListRequest({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/integrations/enabled/`;
    const response = yield defaultApi(url, 'GET');
    yield put({
      type: GET_ENABLED_INTEGRATIONS_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_ENABLED_INTEGRATIONS_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* getIntegrationEnabledWorkflowsListRequest({ payload }) {
  if (!payload) return;
  const { organization_id, enabled_integration_id } = payload;
  try {
    const url = `${organization_id}/integrations/enabled/${enabled_integration_id}/workflows/enabled/`;
    const response = yield defaultApi(url, 'GET');
    yield put({
      type: GET_INTEGRATION_ENABLED_WORKFLOWS_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_INTEGRATION_ENABLED_WORKFLOWS_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* enableIntegrationWorkflowRequest({ payload }) {
  if (!payload) return;
  const { organization_id, enabled_integration_id, workflow_id } = payload;
  try {
    const url = `${organization_id}/integrations/${enabled_integration_id}/workflows/${workflow_id}/`;
    const response = yield defaultApi(url, 'POST');
    yield put({
      type: ENABLE_INTEGRATION_WORKFLOW_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: ENABLE_INTEGRATION_WORKFLOW_FAILURE,
      payload: err,
    });
  }
}

export function* runIntegrationWorkflowRequest({ payload }) {
  if (!payload) return;
  const { organization_id, ...data } = payload;
  try {
    const url = `${organization_id}/integrations/run/`;
    const response = yield defaultApi(url, 'POST', data);
    yield delay(1500);
    yield put({
      type: RUN_INTEGRATION_WORKFLOW_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: RUN_INTEGRATION_WORKFLOW_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_INTEGRATIONS_FEATURES, getIntegrationsFeaturesRequest),
    takeLatest(UPDATE_INTEGRATIONS_FEATURES, updateIntegrationsFeaturesRequest),
    // Get available integrations list
    takeLatest(GET_ALL_INTEGRATIONS_LIST, getAllIntegrationsListRequest),
    takeLatest(
      GET_INTEGRATION_WORKFLOWS_LIST,
      getIntegrationWorkflowsListRequest,
    ),
    takeLatest(
      GET_ENABLED_INTEGRATIONS_LIST,
      getEnabledIntegrationsListRequest,
    ),
    takeLatest(
      GET_INTEGRATION_ENABLED_WORKFLOWS_LIST,
      getIntegrationEnabledWorkflowsListRequest,
    ),
    takeLatest(ENABLE_INTEGRATION_WORKFLOW, enableIntegrationWorkflowRequest),
    throttle(15000, RUN_INTEGRATION_WORKFLOW, runIntegrationWorkflowRequest),
  ]);
}
