import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";

import excelIcon from "../../assets/img/icons/excel-green.svg";
import plusIcon from "../../assets/img/icons/plus_white.svg";
import ProjectDropdown from "../../components/CommonFilter/ProjectDropdown";
import FilterDropDownStyle from "../../components/DropdownStyle/FilterDropDownStyle";
import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";
import BottomPagination from "../../components/Pagination/BottomPagination/BottomPagination";
import TopPagination from "../../components/Pagination/TopPagination/TopPagination";
import Select from "../../components/ReactSelectDropdown";
import {
  ButtonIcon,
  ColoredButton,
  ColoredButtonWithIcon,
  PrimaryButton,
  WhiteButton,
  WhiteButtonWithIcon,
} from "../../styledComponents/buttons";
import {
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonText,
  FormAlert,
  HeaderContainer,
  PageTitle,
} from "../../styledComponents/common";
import { exportToExcel } from "../../utils/exportToExcel";
import { checkHtml, checkUrl } from "../../utils/helper";
import DeleteJobModal from "./DeleteJobModal";
import DeleteTaskModal from "./DeleteTaskModal";
import JobFilter from "./JobFilter";
import JobList from "./JobList";

const Jobs = (props) => {
  const { t } = useTranslation();

  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);
  const [selectedMember, setSelectedMember] = useState("");
  const [taskStatus, setTaskStatus] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState("");
  const [expandedTaskId, setExpandedTaskId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [jobIdDelete, setJobIdDelete] = useState(null);
  const [selectedWorkOrderId, setSelectedWorkOrderId] = useState(null);

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const {
    selectedOrganization,
    getOrganizationMembersShortList,
    orgMembersShortList,
    loadingTaskAssigneeId,
    history,
    //jobs
    getjobsList,
    jobList,
    isLoadingJobs,
    totaljobsCount,
    pageSizeJob,
    //workorder short
    getWorkOrderShortList,
    workOrderShortList,
    //delete job
    deleteJob,
    jobDeleteLoading,
    deletingJobId,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      getjobsList({
        organization_id: selectedOrganization.id,
        paginate: true,
        start_date: moment().format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      });
      setSelectedWorkOrder({
        label: t("all_workorders"),
        value: null,
      });
      setTaskStatus({
        label: t("all_status without unscheduled"),
        value: "all",
      });
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id && deletingJobId) {
      const payload = {
        paginate: true,
        page: 1,
      };
      if (selectedWorkOrder && selectedWorkOrder.value) {
        payload.work_order_id = selectedWorkOrder.value;
      }
      if (selectedMember && selectedMember.id) {
        payload.assignee_id = selectedMember.id;
      }
      if (taskStatus && taskStatus.value && taskStatus.value !== "all") {
        payload.status = taskStatus.value;
      }
      if (searchTerm && searchTerm.trim()) {
        payload.job_name = searchTerm.toLowerCase().trim();
      }
      if (selectedDate) {
        payload.start_date = selectedDate.format("YYYY-MM-DD");
        payload.end_date = selectedDate.format("YYYY-MM-DD");
      }
      payload.organization_id = selectedOrganization.id;
      getjobsList(payload);
    }
  }, [deletingJobId]);

  const handleSearchByName = () => {
    const payload = {
      paginate: true,
      page: 1,
    };
    if (searchTerm && searchTerm.trim()) {
      payload.job_name = searchTerm.toLowerCase().trim();
      setCurrentSearchedTerm(searchTerm);
    } else {
      setCurrentSearchedTerm("");
    }
    if (selectedWorkOrder && selectedWorkOrder.value) {
      payload.work_order_id = selectedWorkOrder.value;
    }
    if (selectedMember && selectedMember.id) {
      payload.assignee_id = selectedMember.id;
    }
    if (taskStatus && taskStatus.value && taskStatus.value !== "all") {
      payload.status = taskStatus.value;
    }
    if (selectedDate) {
      payload.start_date = selectedDate.format("YYYY-MM-DD");
      payload.end_date = selectedDate.format("YYYY-MM-DD");
    }
    // if (taskStatus && taskStatus.value) {
    //   if (taskStatus.value !== 'unscheduled') {
    //     payload.date = selectedDate.format('YYYY-MM-DD');
    //   }
    // }
    if (selectedOrganization && selectedOrganization.id) {
      payload.organization_id = selectedOrganization.id;
      getjobsList(payload);
    }

    setCurrentPageNumber(1);
  };
  const handleClearSearch = () => {
    if (currentSearchedTerm) {
      const payload = {
        paginate: true,
        // date: selectedDate.format('YYYY-MM-DD'),
        page: 1,
      };

      if (selectedMember && selectedMember.id) {
        payload.assignee_id = selectedMember.id;
      }
      if (taskStatus && taskStatus.value && taskStatus.value !== "all") {
        payload.status = taskStatus.value;
      }
      // if (taskStatus && taskStatus.value) {
      //   if (taskStatus.value !== 'unscheduled') {
      //     payload.date = selectedDate.format('YYYY-MM-DD');
      //   }
      // }
      if (selectedWorkOrder && selectedWorkOrder.value) {
        payload.work_order_id = selectedWorkOrder.value;
      }
      if (selectedDate) {
        payload.start_date = selectedDate.format("YYYY-MM-DD");
        payload.end_date = selectedDate.format("YYYY-MM-DD");
      }
      if (selectedOrganization && selectedOrganization.id) {
        payload.organization_id = selectedOrganization.id;
        // console.log('date from clear search');
        getjobsList(payload);
      }

      setCurrentPageNumber(1);
    }
    setTimeout(() => {
      setSearchTerm("");
      setCurrentSearchedTerm("");
    }, 100);
  };

  const handlePageChange = (page) => {
    const payload = {
      organization_id:
        selectedOrganization && selectedOrganization.id
          ? selectedOrganization.id
          : "",
      paginate: true,
      page,
    };
    if (selectedWorkOrder && selectedWorkOrder.value) {
      payload.work_order_id = selectedWorkOrder.value;
    }

    if (selectedMember && selectedMember.id) {
      payload.assignee_id = selectedMember.id;
    }
    if (searchTerm && searchTerm.trim()) {
      payload.job_name = searchTerm.toLowerCase().trim();
    }
    if (taskStatus && taskStatus.value && taskStatus.value !== "all") {
      payload.status = taskStatus.value;
    }
    if (selectedDate) {
      payload.start_date = selectedDate.format("YYYY-MM-DD");
      payload.end_date = selectedDate.format("YYYY-MM-DD");
    }
    // if (taskStatus && taskStatus.value) {
    //   if (taskStatus.value !== 'unscheduled') {
    //     payload.date = selectedDate.format('YYYY-MM-DD');
    //   }
    // }

    getjobsList(payload);
    setCurrentPageNumber(page);
  };

  const selectWorkOrder = (e) => {
    const payload = {
      paginate: true,
      // date: selectedDate.format('YYYY-MM-DD'),
      page: 1,
    };
    if (e && e.value) {
      payload.work_order_id = e.value;
    }
    // if (searchTerm && searchTerm.trim()) {
    //   payload.job_name = searchTerm.toLowerCase().trim();
    // }
    // if (selectedMember && selectedMember.id) {
    //   payload.assignee_id = selectedMember.id;
    // }

    if (taskStatus && taskStatus.value !== "all") {
      payload.status = taskStatus.value;
    }
    if (selectedDate && taskStatus && taskStatus.value !== "unscheduled") {
      payload.start_date = selectedDate.format("YYYY-MM-DD");
      payload.end_date = selectedDate.format("YYYY-MM-DD");
    }
    if (selectedOrganization && selectedOrganization.id) {
      payload.organization_id = selectedOrganization.id;
      getjobsList(payload);
    }

    setCurrentPageNumber(1);
    setSearchTerm("");
    setCurrentSearchedTerm("");
    setSelectedMember("");
    setSelectedWorkOrder(e);
  };

  const selectMember = (person) => {
    if (person.id) {
      const payload = {
        paginate: true,
        assignee_id: person.id,
        page: 1,
      };

      if (selectedWorkOrder && selectedWorkOrder.value) {
        payload.work_order_id = selectedWorkOrder.value;
      }

      if (selectedMember && selectedMember.id) {
        payload.assignee_id = selectedMember.id;
      }

      if (taskStatus && taskStatus.value !== "all") {
        payload.status = taskStatus.value;
      }

      if (selectedDate) {
        payload.start_date = selectedDate.format("YYYY-MM-DD");
        payload.end_date = selectedDate.format("YYYY-MM-DD");
      }

      // if (taskStatus && taskStatus.value) {
      //   if (taskStatus.value !== 'unscheduled') {
      //     payload.date = selectedDate.format('YYYY-MM-DD');
      //   }
      // }

      if (selectedOrganization && selectedOrganization.id) {
        payload.organization_id = selectedOrganization.id;
        // console.log('date from selected member');
        getjobsList(payload);
      }

      setSelectedMember(person);
      setSearchTerm("");
      setCurrentSearchedTerm("");
      setCurrentPageNumber(1);
    }
  };

  const clearMember = () => {
    const payload = {
      paginate: true,
      page: 1,
    };

    if (selectedWorkOrder && selectedWorkOrder.value) {
      payload.work_order_id = selectedWorkOrder.value;
    }

    if (selectedDate) {
      payload.start_date = selectedDate.format("YYYY-MM-DD");
      payload.end_date = selectedDate.format("YYYY-MM-DD");
    }

    // if (selectedMember && selectedMember.id) {
    //   payload.assignee_id = selectedMember.id;
    // }

    if (taskStatus && taskStatus.value !== "all") {
      payload.status = taskStatus.value;
    }

    // if (taskStatus && taskStatus.value !== 'unscheduled') {
    //   payload.start_date = selectedDate.format('YYYY-MM-DD');
    //   payload.end_date = selectedDate.format('YYYY-MM-DD');
    // }

    if (selectedOrganization && selectedOrganization.id) {
      payload.organization_id = selectedOrganization.id;
      getjobsList(payload);
    }

    // setSearchTerm('');
    // setCurrentSearchedTerm('');
    setCurrentPageNumber(1);
    setSelectedMember("");
  };

  const onSearchTermChange = (e) => {
    const { value } = e.target;
    if (value !== "") {
      setSelectedMember("");
    }
    setSearchTerm(value);
  };

  const handleStatusChange = (e) => {
    const payload = {
      paginate: true,
      page: 1,
    };
    if (e && e.value) {
      if (e.value !== "all") {
        payload.status = e.value;
      }
    }

    if (selectedDate) {
      payload.start_date = selectedDate.format("YYYY-MM-DD");
      payload.end_date = selectedDate.format("YYYY-MM-DD");
    }

    if (selectedWorkOrder && selectedWorkOrder.value) {
      payload.work_order_id = selectedWorkOrder.value;
    }

    if (searchTerm && searchTerm.trim()) {
      payload.job_name = searchTerm.toLowerCase().trim();
      setCurrentSearchedTerm(searchTerm);
    } else {
      setCurrentSearchedTerm("");
    }

    if (selectedMember && selectedMember.id) {
      payload.assignee_id = selectedMember.id;
    }
    if (selectedOrganization && selectedOrganization.id) {
      payload.organization_id = selectedOrganization.id;
      getjobsList(payload);
    }

    setCurrentPageNumber(1);
    setSearchTerm("");
    setCurrentSearchedTerm("");
    setTaskStatus(e);
  };

  const handleDateChange = (date) => {
    const payload = {
      paginate: true,
      page: 1,
    };
    if (selectedWorkOrder && selectedWorkOrder.value) {
      payload.work_order_id = selectedWorkOrder.value;
    }
    if (searchTerm && searchTerm.trim()) {
      payload.job_name = searchTerm.toLowerCase().trim();
      setCurrentSearchedTerm(searchTerm);
    } else {
      setCurrentSearchedTerm("");
    }

    if (selectedMember && selectedMember.id) {
      payload.assignee_id = selectedMember.id;
    }
    if (searchTerm && searchTerm.trim()) {
      payload.job_name = searchTerm.toLowerCase().trim();
    }
    if (taskStatus && taskStatus.value && taskStatus.value !== "all") {
      payload.status = taskStatus.value;
    }

    payload.start_date = moment(date).format("YYYY-MM-DD");
    payload.end_date = moment(date).format("YYYY-MM-DD");

    if (selectedOrganization && selectedOrganization.id) {
      payload.organization_id = selectedOrganization.id;
      getjobsList(payload);
    }
    setCurrentPageNumber(1);
    setSelectedDate(date);
  };

  const toggleDeleteModal = (jobId, workOrderId) => {
    setIsOpen(!isOpen);
    if (jobId && workOrderId) {
      setJobIdDelete(jobId);
      setSelectedWorkOrderId(workOrderId);
    }
  };

  const handleJobDelete = () => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      jobIdDelete &&
      selectedWorkOrderId
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        workOrder_id: selectedWorkOrderId,
        job_id: jobIdDelete,
      };
      // console.log("payload for deleting job", payload);
      deleteJob(payload);
      toggleDeleteModal();
    }
  };

  return (
    <div className="content">
      <DeleteJobModal
        isOpen={isOpen}
        toggle={() => toggleDeleteModal()}
        handleDelete={handleJobDelete}
        isLoading={jobDeleteLoading}
      />
      <CommonGrid alignItem="center">
        <PageTitle>{t("all_jobs")}</PageTitle>
        {selectedOrganization &&
          selectedOrganization.role &&
          (selectedOrganization.role === "admin" ||
        selectedOrganization.role === "owner") ? (
          <CommonFlex gap="12px">
            <ColoredButtonWithIcon
              onClick={() => history.push(`/user/create-job`)}
            >
              <ButtonIcon src={plusIcon} alt="" />
              {t("create_job")}
            </ColoredButtonWithIcon>
          </CommonFlex>
        ) : (
          <> </>
        )}
      </CommonGrid>
      <HeaderContainer columns="1fr">
        <JobFilter
          selectedOrganization={
            selectedOrganization && selectedOrganization.id
              ? selectedOrganization
              : ""
          }
          getOrganizationMembersList={getOrganizationMembersShortList}
          organizationMembersList={orgMembersShortList}
          //WorkOrder short list
          workOrderShortList={workOrderShortList}
          getWorkOrderShortList={getWorkOrderShortList}
          selectedWorkOrder={selectedWorkOrder}
          setSelectedWorkOrder={setSelectedWorkOrder}
          selectWorkOrder={selectWorkOrder}
          selectedMember={selectedMember}
          selectMember={selectMember}
          clearMember={clearMember}
          searchTerm={searchTerm}
          onSearchTermChange={onSearchTermChange}
          taskStatus={taskStatus}
          handleStatusChange={handleStatusChange}
          selectedDate={selectedDate}
          handleDateChange={handleDateChange}
          handleSearchByName={handleSearchByName}
          handleClearSearch={handleClearSearch}
        />
      </HeaderContainer>

      {totaljobsCount && totaljobsCount > 0 && pageSizeJob ? (
        <TopPagination
          itemName={t("jobs")}
          totalCount={totaljobsCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={pageSizeJob}
        />
      ) : null}

      <JobList
        selectedOrganization={selectedOrganization}
        loadingTaskAssigneeId={loadingTaskAssigneeId}
        expandedTaskId={expandedTaskId}
        setExpandedTaskId={setExpandedTaskId}
        toggleDeleteModal={toggleDeleteModal}
        history={history}
        jobList={jobList}
        isLoading={isLoadingJobs}
      />

      {totaljobsCount && totaljobsCount > 0 && pageSizeJob ? (
        <BottomPagination
          totalCount={totaljobsCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={pageSizeJob}
        />
      ) : null}
    </div>
  );
};

export default Jobs;
