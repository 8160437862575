import { connect } from 'react-redux';

import { getjobsShortList } from '../../Jobs/jobsActions';
import {
  getOfficeDetails,
  getOfficesShortList,
} from '../../OfficesNew/officesActions';
import {
  getOrganizationMembersList,
  getOrganizationMembersShortList,
} from '../../Projects/projectsActions';
// Actions
import {
  clearDailyTimesheetAdded,
  createDailyTimesheets,
  getDailyTimesheets,
  deleteDailyTimesheets,
} from '../timesheetActions';
// Component
import TimesheetDaily from './TimesheetDaily';

// Store props to map with current state
const mapStateToProps = state => ({
  isLoading: state.timesheet.dailyTimesheetLoading,
  selectedOrganization: state.organization.selectedOrganization,
  timesheet: state.timesheet.dailyList,
  orgMembersList: state.project.orgMembersList,
  //member short list
  orgMembersShortList: state.project.orgMembersShortList,
  memberShortListIsLoading: state.project.memberShortListIsLoading,
  officesShortList: state.office.officesShortList,
  isLoadingJobs: state.jobs.isLoadingJobs,
  jobShortList: state.jobs.jobShortList,
  //office
  officesShortList: state.office.officesShortList,
  officeDetails: state.office.officeDetails,
  //
  manualTimeLoading: state.timesheet.manualTimeLoading,
  manualTimeAdded: state.timesheet.manualTimeAdded,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getDailyTimesheets: params => dispatch(getDailyTimesheets(params)),
  getOfficesShortList: params => dispatch(getOfficesShortList(params)),
  getOrganizationMembersList: params =>
    dispatch(getOrganizationMembersList(params)),
  getOrganizationMembersShortList: params =>
    dispatch(getOrganizationMembersShortList(params)),
  createDailyTimesheets: params => dispatch(createDailyTimesheets(params)),
  getOfficeDetails: payload => dispatch(getOfficeDetails(payload)),
  clearDailyTimesheetAdded: () => dispatch(clearDailyTimesheetAdded()),
  deleteDailyTimesheets: (details) =>
    dispatch(deleteDailyTimesheets({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(TimesheetDaily);
