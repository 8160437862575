import { useState, useEffect } from "react";
import moment from "moment-timezone";

import { PageTitle, Container } from "../../styledComponents/common";
import { TabButtonContainer, TabButton } from "../../styledComponents/buttons";

import ApprovalFilter from "./ApprovalFilter/filterContainer";

import AwaitingApprovals from "./AwaitingApprovals/awaitingApprovalsContainer";
import ApprovalHistory from "./ApprovalHistory/historyContainer";

const TimesheetApproval = (props) => {
  const [selectedTab, setSelectedTab] = useState("awaiting");
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "day").startOf("isoWeek")
  );
  const [endDate, setEndDate] = useState(
    moment().subtract(1, "day").endOf("isoWeek")
  );
  const [sortType, setSortType] = useState({
    value: "oldToNew",
    label: "Date (Old to New)",
  });

  // const [selectedProject, setSelectedProject] = useState("");
  const [selectedMember, setSelectedMember] = useState(null);

  const {
    selectedOrganization,
    getTimesheetApprovalAwaitingList,
    getTimesheetApprovalHistory,

    // pagination
    paginationSize,
    loadMoreTimesheetApprovalList,
    clearLoadMoreTimesheetApprovalList,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      clearFilters();
    }

    return () => {
      clearLoadMoreTimesheetApprovalList();
    };
  }, [selectedOrganization]);

  useEffect(() => {
    clearFilters();
  }, [selectedTab]);

  const clearFilters = () => {
    setSelectedMember("");
    setSortType({
      value: "oldToNew",
      label: "Date (Old to New)",
    });
  };

  const changeDateRange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };
  const selectLastWeek = () => {
    setStartDate(moment().subtract(6, "days"));
    setEndDate(moment());
  };
  const selectLastMonth = () => {
    setStartDate(moment().subtract(29, "days"));
    setEndDate(moment());
  };

  const onDateSelect = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        offset: 0,
        limit: paginationSize,
      };
      if (selectedMember && selectedMember.id) {
        payload.user_id = selectedMember.id;
      }
      if (selectedTab === "awaiting") {
        getTimesheetApprovalAwaitingList(payload);
      } else {
        getTimesheetApprovalHistory(payload);
      }
    }
  };

  const handleSeeMore = (date, currentOffset, type) => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        start_date: moment(date).format("YYYY-MM-DD"),
        end_date: moment(date).format("YYYY-MM-DD"),
        offset: currentOffset,
        limit: paginationSize,
        type,
      };
      loadMoreTimesheetApprovalList(payload);
    }
  };

  const handleTabSelect = (tabName) => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        offset: 0,
        limit: paginationSize,
      };
      if (tabName === "awaiting") {
        getTimesheetApprovalAwaitingList(payload);
      } else {
        getTimesheetApprovalHistory(payload);
      }
    }
    setSelectedTab(tabName);
  };

  const changeSortType = (type) => {
    setSortType(type);
  };

  return (
    <div className="content">
      <PageTitle>Timesheet Approval</PageTitle>
      <TabButtonContainer width="max-content" margin="30px auto 0 0">
        <TabButton
          selected={selectedTab}
          index={"awaiting"}
          onClick={() => handleTabSelect("awaiting")}
        >
          Awaiting
        </TabButton>
        <TabButton
          selected={selectedTab}
          index={"history"}
          onClick={() => handleTabSelect("history")}
        >
          History
        </TabButton>
      </TabButtonContainer>

      <ApprovalFilter
        selectedTab={selectedTab}
        startDate={startDate}
        endDate={endDate}
        onDateSelect={onDateSelect}
        changeDateRange={changeDateRange}
        selectLastWeek={selectLastWeek}
        selectLastMonth={selectLastMonth}
        sortType={sortType}
        changeSortType={changeSortType}
        selectedMember={selectedMember}
        setSelectedMember={setSelectedMember}
        disableCurrentAndFutureDates
      />
      {selectedTab && selectedTab === "history" ? (
        <ApprovalHistory
          startDate={startDate}
          endDate={endDate}
          handleSeeMore={handleSeeMore}
        />
      ) : (
        <AwaitingApprovals
          startDate={startDate}
          endDate={endDate}
          handleSeeMore={handleSeeMore}
        />
      )}
    </div>
  );
};

export default TimesheetApproval;
