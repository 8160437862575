import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Plus from '../../../assets/img/icons/plus.svg';
import PlusBlack from '../../../assets/img/icons/plus_black_2.svg';
import BackButtonComponent from '../../../components/BackButtonComponent/BackButtonComponent';
import FilterDropDownStyle from '../../../components/DropdownStyle/FilterDropDownStyle';
import ButtonTextLoader from '../../../components/Loaders/ButtonTextLoader';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import Select from '../../../components/ReactSelectDropdown';
import RightSlidingPanel from '../../../components/RightSlidingPanel/RightSlidingPanel';
import {
  CardTextData,
  ViewLessCard,
} from '../../../styledComponents/ProjectsNew';
import {
  ButtonIcon,
  PrimaryButton,
  WhiteButton,
  WhiteButtonWithIcon,
} from '../../../styledComponents/buttons';
import { GeneralInfoItem } from '../../../styledComponents/clients';
import {
  CardTitle,
  CommonFlex,
  CommonGrid,
  CommonText,
  Container,
  ContentHeader,
  DividerLine,
  FormAlert,
  InputField,
  InputLabel,
  InputSection,
  InputTextArea,
  InputWithLabelSection,
  ManagerBadge,
  PageTitle,
} from '../../../styledComponents/common';
// import Plus from "../../../assets/img/icons/plus.svg";
import { ButtonFlexContainer } from '../../../styledComponents/members';
import { RedStar, SingleSection } from '../../../styledComponents/members';
import { colors } from '../../../styles/light/colors';
import { currencySymbolMap } from '../../../utils/currencies';
import { checkHtml, checkUrl } from '../../../utils/helper';
// import { AddAssigneeDropdown } from '../AddAssigneeDropdown';
import AddLocationModal from '../../ClientsNew/ClientDetails/AddLocationModal';
import ShiftModal from '../EditWorkOrder/ShiftModal';
import ShiftModalCreateJob from '../EditWorkOrder/ShiftModalCreateJob';
import BillingModal from './BillingModal';
import BudgetModal from './BudgetModal';
import ClientModal from './ClientModal';

const CreateWorkOrder = props => {
  const [workOrderName, setWorkOrderName] = useState('');
  const [jobName, setJobName] = useState('');
  const [jobId, setJobId] = useState(null);
  const [saveErrors, setSaveErrors] = useState({});
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [clientOptions, setClientOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [status, setStatus] = useState({ value: 'open', label: 'Open' });
  const [jobStatus, setJobStatus] = useState('');
  const [description, setDescription] = useState('');
  const [assignees, setAssignees] = useState([]);
  const [action, setAction] = useState('Add');
  const [shiftAction, setShiftAction] = useState('Add');
  const [jobAssignee, setJobAssignee] = useState(null);
  const statusOptions = [
    { value: 'open', label: 'Open' },
    { value: 'in progress', label: 'In Progress' },
    { value: 'complete', label: 'Complete' },
  ];
  const jobStatusOptions = [
    { value: 'unscheduled', label: 'Unscheduled' },
    { value: 'ready to dispatch', label: 'Ready to dispatch' },
  ];
  const { t } = useTranslation();
  const [jobPanelIsOpen, setJobPanelIsOpen] = useState(false);
  //add Location
  const [popupOpen, setPopupOpen] = useState(false);

  //Billing
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const [isBudgetModalOpen, setIsBudgetModalOpen] = useState(false);

  const [addLocationButton, setAddLocationButton] = useState(false);

  const [selectedDate, setSelectedDate] = useState(moment(new Date()));
  const [requiredStartDate, setRequiredStartDate] = useState(null);
  const [requiredEndDate, setRequiredEndDate] = useState(null);

  const [isShiftModalOpen, setIsShiftModalOpen] = useState(false);
  const [isShiftModalCreateJobOpen, setIsShiftModalCreateJobOpen] =
    useState(false);

  // Client Modal
  const [isClietModalOpen, setIsClientModalOpen] = useState(false);

  const {
    selectedOrganization,
    getClientList,
    getClientShortList,
    clientList,
    clientShortList,
    createdWorkOrderId,
    createWorkOrderIsLoading,
    UpdateWorkOrderLoading,
    updateWorkOrder,
    jobUpdate,
    createWorkOrderRequest,
    createJobRequest,
    createdJobId,
    createdJob,
    updateJobRequest,
    createOrUpdateJobIsLoading,
    getJobsUnderWorkOrderRequest,
    //Jobs Under One WorkOrder
    getJobsUnderWorkOrderIsLoading,
    jobsUnderWorkOrderList,
    totalJobsCount,
    pageSizeJobs,
    //client Locations
    getClientLocation,
    clientLocationList,
    getclientLocalMapLoading,
    addClientLocation,
    addOrUpdateclientLocalMapLoading,
    createdClientLocationInfo,
    //billing and budgeting
    createWorkOrderBill,
    getWorkOrderBill,
    updateWorkOrderBill,
    createWorkOrderBudget,
    getWorkOrderBudget,
    updateWorkOrderBudget,
    //billing and budgeting states
    saveBillingLoading,
    WorkOrderBilling,
    getBillingLoading,
    isBillAddOrUpdate,
    clearWorkOrderBilling,

    saveBudgetLoading,
    workOrderBudget,
    getBudgetLoading,
    isBudgetAddOrUpdate,
    clearWorkOrderBudget,

    //shift
    getOrganizationMembersShortList,
    memberShortListIsLoading,
    orgMembersShortList,

    addJobAssignee,
    removeJobAssignee,
    getJobDetails,
    loadingjobId,
    JobMemberIsLoading,
    jobDetails,
    getJobIsLoading,

    clearWorkOrderId,

    //client
    createClient,
    isClientLoading,
    createdClientId,
    createdClientInfo,
    clearCreatedCustomerInfo,
  } = props;

  useEffect(() => {
    return () => {
      clearCreatedCustomerInfo();
      clearWorkOrderId();
      clearWorkOrderBilling();
      clearWorkOrderBudget();
    };
  }, []);

  useEffect(() => {
    if (createdClientLocationInfo && createdClientLocationInfo.id) {
      setSelectedLocation({
        value: createdClientLocationInfo.id,
        label: createdClientLocationInfo.name,
      });
    }
  }, [createdClientLocationInfo]);

  useEffect(() => {
    if (createdJob) {
      setAction('Update');
      setJobId(createdJobId);
      toggleJobDetailsPanel(false);
    }
  }, [createdJob]);

  useEffect(() => {
    if (jobUpdate) {
      toggleJobDetailsPanel(false);
    }
  }, [jobUpdate]);

  useEffect(() => {
    if (isBillAddOrUpdate) {
      setIsBillingModalOpen(false);
    }
  }, [isBillAddOrUpdate]);

  useEffect(() => {
    if (isBudgetAddOrUpdate) {
      setIsBudgetModalOpen(false);
    }
  }, [isBudgetAddOrUpdate]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      getClientShortList({ organization_id: selectedOrganization.id });
    }
  }, [selectedOrganization]);

  // useEffect(() => {
  //   if (selectedOrganization && selectedOrganization.id) {
  //     getClientShortList({ organization_id: selectedOrganization.id });
  //     if (createdWorkOrderId) {
  //       getJobsUnderWorkOrderRequest({
  //         organization_id: selectedOrganization.id,
  //         workOrder_id: createdWorkOrderId,
  //       });
  //     }
  //   }
  // }, [selectedOrganization, createdWorkOrderId]);

  useEffect(() => {
    //console.log(selectedClient)
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      selectedClient &&
      selectedClient.value
    ) {
      getClientLocation({
        organization_id: selectedOrganization.id,
        client_id: selectedClient.value,
      });
    }
  }, [selectedClient]);

  useEffect(() => {
    if (clientLocationList && clientLocationList.length > 0) {
      const options = [];
      clientLocationList.map(item => {
        options.push({
          value: item.id,
          label: item.name,
        });
      });
      // options.unshift({ label: 'Select Location...', value: '' });
      setLocationOptions(options);
    } else if (clientLocationList && clientLocationList.length === 0) {
      setLocationOptions([]);
    }
  }, [clientLocationList]);

  useEffect(() => {
    console.log('');
    if (clientShortList && clientShortList.length > 0) {
      const options = [];
      clientShortList.map(item => {
        options.push({
          value: item.id,
          label: item.name,
        });
      });
      options.unshift({ label: 'Select Customer', value: '' });
      setClientOptions(options);
    } else if (clientShortList && clientShortList.length === 0) {
      setClientOptions([]);
    }
  }, [clientShortList]);

  // console.log('client short list', clientShortList);

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
    // setAction("add");
  };

  const onSearchTermChange = e => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const handleClearSearch = () => {
    if (
      currentSearchedTerm &&
      selectedOrganization &&
      selectedOrganization.id
    ) {
      // const payload = {
      //   organization_id: selectedOrganization.id,
      //   page: 1,
      // };
      if (selectedClient && selectedClient.value) {
        payload.client_id = selectedClient.value;
      }
      // getProjectList(payload);

      setCurrentPageNumber(1);
    }
    setTimeout(() => {
      setSearchTerm('');
      setCurrentSearchedTerm('');
    }, 100);
  };

  const onClientSelect = e => {
    console.log('selected customer', e);
    if (selectedOrganization && selectedOrganization.id) {
      // const payload = {
      //   organization_id: selectedOrganization.id,
      //   page: 1,
      // };
      // if (searchTerm && searchTerm.trim()) {
      //   payload.name = searchTerm.toLowerCase().trim();
      // }
      if (e && e.value) {
        // payload.client_id = e.value;
        setSelectedClient(e);
      } else {
        setSelectedClient(null);
      }
      // getProjectList(payload);

      // setCurrentPageNumber(1);
    }
  };

  const onLocationSelect = e => {
    setSelectedLocation(e);
  };

  const onStatusSelect = e => {
    setStatus(e);
  };

  const onJobStatusSelect = e => {
    // console.log(e);
    setJobStatus(e);
  };

  const onDescriptionChange = e => {
    const { value } = e.target;
    setDescription(value);
  };

  const onWorkOrderNameChange = e => {
    const { value } = e.target;
    setWorkOrderName(value);

    if (saveErrors && saveErrors['workOrderName']) {
      delete saveErrors['workOrderName'];
    }
  };

  const onJobNameChange = e => {
    const { value } = e.target;
    setJobName(value);

    if (saveErrors && saveErrors['jobName']) {
      delete saveErrors['jobName'];
    }
  };

  const checkJobNameError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!jobName.trim()) {
      formIsValid = false;
      saveErrors['jobName'] = 'Job name is required';
    } else if (checkUrl(jobName)) {
      formIsValid = false;
      saveErrors['jobName'] = 'Job name cannot contain url';
    } else if (checkHtml(jobName)) {
      formIsValid = false;
      saveErrors['jobName'] = 'Invalid Job Name';
    } else if (
      jobStatus.value === 'ready to dispatch' &&
      action === 'Add' &&
      (assignees.length === 0 ||
        requiredStartDate === null ||
        requiredEndDate === null)
    ) {
      formIsValid = false;
      saveErrors['assignee'] =
        'Ready to dispatch job must have start & end time and assignees';
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;

    if (!workOrderName.trim()) {
      formIsValid = false;
      saveErrors['workOrderName'] = 'Work order name is required';
    } else if (checkUrl(workOrderName)) {
      formIsValid = false;
      saveErrors['workOrderName'] = 'Work order name cannot contain url';
    } else if (checkHtml(workOrderName)) {
      formIsValid = false;
      saveErrors['workOrderName'] = 'Invalid Work Order Name';
    } else if (selectedClient === null) {
      formIsValid = false;
      saveErrors['client'] = 'Work order must have a customer';
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const updateWorkOrderHandler = () => {
    if (selectedOrganization && selectedOrganization.id && createdWorkOrderId) {
      let payload = {
        organization_id: selectedOrganization.id,
        workOrder_id: createdWorkOrderId,
        client_id: selectedClient ? selectedClient.value : null,
      };
      updateWorkOrder(payload);
    }
  };

  const handleSave = () => {
    if (checkError()) {
      if (selectedOrganization && selectedOrganization.id) {
        let payload = {
          organization_id: selectedOrganization.id,
          name: workOrderName,
          client_id: selectedClient ? selectedClient.value : null,
          status: status ? status.value : null,
        };
        // console.log('payload for work order ', payload);
        createWorkOrderRequest(payload);
      }
    }
  };

  const handleJobSave = save => {
    if (checkJobNameError()) {
      if (selectedOrganization && selectedOrganization.id) {
        let payload = {
          organization_id: selectedOrganization.id,
          workOrder_id: createdWorkOrderId,
          name: jobName,
          status: jobStatus ? jobStatus.value : null,
          description: description ? description : null,
        };

        if (action === 'Add') {
          if (selectedLocation && selectedLocation.value) {
            payload.location = selectedLocation.value;
          }
          if (jobStatus.value === 'unscheduled') {
            payload.assignees = [];
          }
          if (jobStatus.value === 'ready to dispatch') {
            let assigneeIds = [];
            if (assignees && assignees.length > 0) {
              assignees.forEach(element => {
                assigneeIds.push(element.id);
              });
            }
            payload.assignees = assigneeIds;
            payload.required_start_date = requiredStartDate;
            payload.required_end_date = requiredEndDate;
          }
          createJobRequest(payload);
        }
        if (action === 'Update') {
          if (jobId) {
            payload.job_id = jobId;
          }

          if (selectedLocation && selectedLocation.value) {
            payload.location_id = selectedLocation.value;
          }
          if (jobStatus.value === 'ready to dispatch') {
            payload.required_start_date = requiredStartDate;
            payload.required_end_date = requiredEndDate;
          }
          updateJobRequest(payload);
        }
        if (save === 'close') {
          toggleJobDetailsPanel(false);
        }
      }
    }
  };

  const mapStatusToOption = status => {
    switch (status) {
      case 'unscheduled':
        return { value: 'unscheduled', label: 'Unscheduled' };
      case 'ready to dispatch':
        return { value: 'ready to dispatch', label: 'Ready to dispatch' };
      default:
        return { value: '', label: 'Unknown' };
    }
  };

  const selectedJob = job => {
    setJobId(job.id);
    setJobName(job.name);
    setJobStatus(mapStatusToOption(job.status));
    setDescription(
      job.description !== null && job.description !== '' ? job.description : '',
    );
    setSelectedLocation({
      value: job.location ? job.location.id : null,
      label: job.location ? job.location.name : null,
    });
    setAction('Update');

    //for shift update/add
    setJobAssignee(
      job.assignees && job.assignees.length > 0 ? job.assignees : null,
    );
    setSelectedDate(
      job.required_start_date !== null
        ? job.required_start_date
        : moment(new Date()),
    );
    setRequiredStartDate(
      job.required_start_date !== null ? job.required_start_date : null,
    );
    setRequiredEndDate(
      job.required_end_date !== null ? job.required_end_date : null,
    );
    setShiftAction(job.required_start_date !== null ? 'Update' : 'Add');
    setAddLocationButton(false);

    toggleJobDetailsPanel(true);
  };

  const clearJobDetails = () => {
    setJobName('');
    setJobStatus({ value: '', label: '' }); // Adjust with your initial value for job status
    setDescription('');
    setSelectedLocation(null);
    setAction('Add'); // Adjust with your initial value for action\
    setAssignees([]);
    setSelectedDate(moment(new Date()));
    setRequiredStartDate(null);
    setRequiredEndDate(null);
    setAddLocationButton(false);
    toggleJobDetailsPanel(true);
    setSaveErrors({});
  };

  const locationInputOpen = () => {
    setAddLocationButton(true);
  };

  const toggleJobDetailsPanel = value => {
    setJobPanelIsOpen(value);
  };

  const toggleBillingModal = workorderId => {
    setIsBillingModalOpen(!isBillingModalOpen);
  };

  const toggleBudgetModal = () => {
    setIsBudgetModalOpen(!isBudgetModalOpen);
  };

  const toggleClientModal = () => {
    setIsClientModalOpen(!isClietModalOpen);
  };

  const handleWorkOrderAddBilling = data => {
    if (selectedOrganization && selectedOrganization.id && createdWorkOrderId) {
      const payload = {
        organization_id: selectedOrganization.id,
        workorder_id: createdWorkOrderId,
      };
      // console.log('payload ', payload);
      // console.log('data ', data);
      // props.deleteWorkOrderRequest(payload);
      // setTimeout(() => {
      //   setIsDeleteModalOpen(!isDeleteModalOpen);
      //   props.history.push({
      //     pathname: '/user/work-order',
      //     state: { from: 'details' },
      //   });
      // }, 500);
    }
  };

  function calculateFormattedAmount(amount) {
    const calculatedValue = amount / 100;
    const formattedAmount = calculatedValue.toFixed(2);
    return `${formattedAmount}`;
  }

  const toggleShiftModal = () => {
    setIsShiftModalOpen(!isShiftModalOpen);
  };

  const toggleShiftModalCreateJob = () => {
    setIsShiftModalCreateJobOpen(!isShiftModalCreateJobOpen);
  };

  const renderAddLocationSection = () => {
    return (
      <InputSection marginLeft="30px">
        <div style={{ width: '60%' }}>
          <InputLabel style={{ marginBottom: '10px' }}>
            {t('location')}{' '}
          </InputLabel>
          <CommonText
            fontSize="13px"
            color={colors['blueText']}
            margin="20px 0 0 0"
            fontWeight="550"
            style={{ cursor: 'pointer' }}
            onClick={() => locationInputOpen()}
          >
            <ButtonIcon src={Plus} alt="" /> Add Location
          </CommonText>
        </div>
      </InputSection>
    );
  };

  const renderLocationSection = () => {
    return (
      <InputSection marginLeft="30px">
        <div style={{ width: '60%' }}>
          <InputLabel>
            {t('location')}{' '}
            <span
              onClick={() => popupToggle()}
              style={{
                float: 'right',
                color: colors['blueText'],
                fontWeight: '600',
                textAlign: 'left',
                cursor: 'pointer',
                fontSize: '12px',
              }}
            >
              {t('add_new_location')}
            </span>
          </InputLabel>
        </div>

        {clientLocationList && clientLocationList.length > 0 ? (
          getclientLocalMapLoading ? (
            <CommonText width="25%">
              <ButtonTextLoader
                color={colors['greyLodder']}
                loadingText={`loading`}
                fontSize="13px"
                justifyContent="flex-start"
              />
            </CommonText>
          ) : (
            <InputWithLabelSection style={{ width: '60%', marginTop: '10px' }}>
              <Select
                value={selectedLocation}
                options={locationOptions}
                onChange={e => onLocationSelect(e)}
                placeholder="Select Location..."
                styles={FilterDropDownStyle({
                  height: '40px',
                })}
              />
            </InputWithLabelSection>
          )
        ) : (
          <CommonText margin="20px 0 0 0">
            No location previously set for this customer
          </CommonText>
        )}
      </InputSection>
    );
  };

  const clearStates = () => {
    clearCreatedCustomerInfo();
    clearWorkOrderId();
    clearWorkOrderBilling();
    clearWorkOrderBudget();
    props.history.push('/user/work-order');
  };

  // console.log('selectedClient ', selectedClient);
  // console.log('status ', status);
  // console.log('createdWorkOrderId ', createdWorkOrderId);

  return (
    <div className="content">
      <ClientModal
        isOpen={isClietModalOpen}
        toggle={toggleClientModal}
        selectedOrganization={
          selectedOrganization && selectedOrganization.id
            ? selectedOrganization
            : ''
        }
        createClient={createClient}
        isClientLoading={isClientLoading}
        createdClientId={createdClientId}
        createdClientInfo={createdClientInfo}
        setSelectedClient={setSelectedClient}
      />
      <BillingModal
        isOpen={isBillingModalOpen}
        toggle={() => setIsBillingModalOpen(!isBillingModalOpen)}
        currency="USD"
        organization_id={
          selectedOrganization && selectedOrganization.id
            ? selectedOrganization.id
            : ''
        }
        workorder_id={createdWorkOrderId ? createdWorkOrderId : ''}
        createWorkOrderBill={createWorkOrderBill}
        getWorkOrderBill={getWorkOrderBill}
        updateWorkOrderBill={updateWorkOrderBill}
        createWorkOrderBudget={createWorkOrderBudget}
        getWorkOrderBudget={getWorkOrderBudget}
        updateWorkOrderBudget={updateWorkOrderBudget}
        saveBillingLoading={saveBillingLoading}
        WorkOrderBilling={WorkOrderBilling}
        getBillingLoadin={getBillingLoading}
        clearWorkOrderBilling={clearWorkOrderBilling}
        saveBudgetLoading={saveBudgetLoading}
        workOrderBudget={workOrderBudget}
        getBudgetLoading={getBudgetLoading}
        clearWorkOrderBudget={clearWorkOrderBudget}
      />

      <BudgetModal
        isOpen={isBudgetModalOpen}
        toggle={() => setIsBudgetModalOpen(!isBudgetModalOpen)}
        currency="USD"
        organization_id={
          selectedOrganization && selectedOrganization.id
            ? selectedOrganization.id
            : ''
        }
        workorder_id={createdWorkOrderId ? createdWorkOrderId : ''}
        createWorkOrderBudget={createWorkOrderBudget}
        getWorkOrderBudget={getWorkOrderBudget}
        updateWorkOrderBudget={updateWorkOrderBudget}
        saveBudgetLoading={saveBudgetLoading}
        workOrderBudget={workOrderBudget}
        getBudgetLoading={getBudgetLoading}
        clearWorkOrderBudget={clearWorkOrderBudget}
      />

      <ShiftModal
        isOpen={isShiftModalOpen}
        toggle={() => setIsShiftModalOpen(!isShiftModalOpen)}
        getOrganizationMembersShortList={getOrganizationMembersShortList}
        memberShortListIsLoading={memberShortListIsLoading}
        orgMembersShortList={orgMembersShortList}
        selectedOrganization={
          selectedOrganization && selectedOrganization.id
            ? selectedOrganization
            : null
        }
        workorder_id={createdWorkOrderId ? createdWorkOrderId : null}
        job_id={jobId ? jobId : null}
        jobAssignee={jobAssignee}
        requiredStartDate={requiredStartDate}
        requiredEndDate={requiredEndDate}
        shiftAction={shiftAction}
        createOrUpdateJobIsLoading={createOrUpdateJobIsLoading}
        addJobAssignee={addJobAssignee}
        removeJobAssignee={removeJobAssignee}
        getJobDetails={getJobDetails}
        loadingjobId={loadingjobId}
        JobMemberIsLoading={JobMemberIsLoading}
        jobDetails={jobDetails}
        getJobIsLoading={getJobIsLoading}
        updateJobRequest={updateJobRequest}
        //new implementation
        setRequiredStartDate={setRequiredStartDate}
        setRequiredEndDate={setRequiredEndDate}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />

      <ShiftModalCreateJob
        isOpen={isShiftModalCreateJobOpen}
        toggle={() => setIsShiftModalCreateJobOpen(!isShiftModalCreateJobOpen)}
        getOrganizationMembersShortList={getOrganizationMembersShortList}
        memberShortListIsLoading={memberShortListIsLoading}
        orgMembersShortList={orgMembersShortList}
        selectedOrganization={
          selectedOrganization && selectedOrganization.id
            ? selectedOrganization
            : null
        }
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        requiredStartDate={requiredStartDate}
        requiredEndDate={requiredEndDate}
        assignees={assignees}
        setAssignees={setAssignees}
        setRequiredStartDate={setRequiredStartDate}
        setRequiredEndDate={setRequiredEndDate}
      />
      <PageTitle>{t('work_order')}</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={clearStates}
          subTitle="Back to all work orders"
        />
      </ContentHeader>
      <Container style={{ padding: `30px` }}>
        <CardTitle>{t('add_work_order_details')}</CardTitle>
        {selectedOrganization && selectedOrganization.id ? (
          <AddLocationModal
            isOpen={popupOpen}
            toggle={popupToggle}
            addClientLocation={addClientLocation}
            addOrUpdateclientLocalMapLoading={addOrUpdateclientLocalMapLoading}
            createdClientLocationInfo={createdClientLocationInfo}
            organization_id={selectedOrganization.id}
            client_id={
              selectedClient && selectedClient.value
                ? selectedClient.value
                : null
            }
            action="add"
          />
        ) : null}
        <InputSection>
          <InputLabel style={{ marginBottom: '10px' }}>
            {t('work_order')} <RedStar>*</RedStar>
          </InputLabel>
          <InputField
            marginTop="10px"
            width="25%"
            type="text"
            placeholder="Type word order"
            value={workOrderName}
            onChange={e => onWorkOrderNameChange(e)}
            error={
              saveErrors &&
              saveErrors['workOrderName'] &&
              saveErrors['workOrderName'] !== undefined
            }
          />
          {saveErrors &&
            saveErrors['workOrderName'] &&
            saveErrors['workOrderName'] !== undefined && (
              <FormAlert margin="0 0 0 5px">
                {saveErrors['workOrderName']}
              </FormAlert>
            )}
        </InputSection>
        <InputSection>
          <div style={{ width: '25%' }}>
            <InputLabel>
              {t('customer')} <RedStar>*</RedStar>
              <span
                onClick={() => toggleClientModal()}
                style={{
                  float: 'right',
                  color: colors['blueText'],
                  fontWeight: '600',
                  textAlign: 'left',
                  cursor: 'pointer',
                  textAlign: 'right',
                }}
              >
                <ButtonIcon style={{marginRight:"2px"}}  src={Plus} alt="" /> 
                {t('add_new_customer')}
              </span>
            </InputLabel>
          </div>

          <InputWithLabelSection style={{ width: '25%', marginTop: '10px' }}>
            <Select
              value={selectedClient}
              options={clientOptions}
              onChange={e => onClientSelect(e)}
              placeholder="Select Customer..."
              styles={FilterDropDownStyle({ height: '40px' })}
            />
          </InputWithLabelSection>
          {saveErrors && saveErrors['client'] && (
            <FormAlert margin="0 0 0 5px">{saveErrors['client']}</FormAlert>
          )}
        </InputSection>
        <InputSection>
          <InputLabel>{t('status')}</InputLabel>
          <InputWithLabelSection style={{ width: '25%', marginTop: '10px' }}>
            <Select
              value={status}
              options={statusOptions}
              onChange={e => onStatusSelect(e)}
              // placeholder="Select Status..."
              styles={FilterDropDownStyle({ height: '40px' })}
            />
          </InputWithLabelSection>
        </InputSection>
        {!createdWorkOrderId && (
          <CommonFlex gap="15px" margin="20px 0 0 0">
            <PrimaryButton
              width="25%"
              padding={createWorkOrderIsLoading ? '0 15px' : '0 35px'}
              onClick={() => handleSave()}
            >
              {createWorkOrderIsLoading ? (
                <ButtonTextLoader loadingText="Saving" fontSize="13px" />
              ) : (
                t('add_work_order')
              )}
            </PrimaryButton>
          </CommonFlex>
        )}
        <DividerLine margin="20px 0 10px" />
        {createdWorkOrderId && (
          <>
            <CommonText
              fontWeight="600"
              fontSize="13x"
              margin="0 0 15px"
              $label
            >
              Jobs
            </CommonText>
            {getJobsUnderWorkOrderIsLoading ? (
              <CommonText width="15%">
                <ButtonTextLoader
                  justifyContent="flex-start"
                  color={colors['greyLodder']}
                  loadingText={`loading`}
                  fontSize="13px"
                />
              </CommonText>
            ) : (
              jobsUnderWorkOrderList && (
                <SingleSection padding="0px">
                  <CommonFlex wrap="wrap">
                    {jobsUnderWorkOrderList.map((job, id) => (
                      <WhiteButtonWithIcon
                        key={id}
                        fontSize="12px"
                        onClick={() => selectedJob(job)}
                      >
                        {job.name}
                      </WhiteButtonWithIcon>
                    ))}
                  </CommonFlex>
                </SingleSection>
              )
            )}

            <InputSection>
              <CommonText
                fontSize="13px"
                color={colors['blueText']}
                margin="10px 0 0 0"
                fontWeight="550"
                style={{ cursor: 'pointer' }}
                onClick={() => clearJobDetails()}
              >
                <ButtonIcon src={Plus} alt="" /> {t('add_job')}
              </CommonText>
            </InputSection>
            <DividerLine margin="25px 0 10px" />
            <InputSection >
              <InputLabel fontWeight="600" fontSize="13x">
                {t('billing')}
              </InputLabel>
              {WorkOrderBilling && WorkOrderBilling.id ? (
                <ViewLessCard
                  style={{ width: '25%', margin: '15px 0px 8px 0px' }}
                >
                  <div>
                    <CardTextData margin="4px 0 0" fontSize="14px">
                      {WorkOrderBilling.billable_type === 'hourly_basis' &&
                        WorkOrderBilling.hourly_rate_based_on ===
                          'work_order_hourly' && (
                          <>
                            <CommonText
                              style={{ padding: '0px 0px 5px 0px' }}
                              fontSize="12px"
                              title
                            >
                              Billing Details
                            </CommonText>
                            <CommonText fontSize="12px">
                              Type: Hourly Basis
                            </CommonText>
                            <CommonText fontSize="12px">
                              Rate: {currencySymbolMap['USD']}
                              {/* {WorkOrderBilling.work_order_hourly.rate.toFixed(
                                2,
                              )} */}
                              {calculateFormattedAmount(
                                WorkOrderBilling.work_order_hourly.rate,
                              )}
                            </CommonText>
                          </>
                        )}
                      {WorkOrderBilling.billable_type === 'hourly_basis' &&
                        WorkOrderBilling.hourly_rate_based_on ===
                          'person_hourly' && (
                          <>
                            <CommonText
                              style={{ padding: '0px 0px 5px 0px' }}
                              fontSize="12px"
                            >
                              Billing Details
                            </CommonText>
                            <CommonText fontSize="12px">
                              Type: Person Hourly
                            </CommonText>
                          </>
                        )}

                      {WorkOrderBilling.billable_type === 'fixed_price' && (
                        <>
                          <CommonText fontSize="12px">
                            Billable Amount
                          </CommonText>
                          {currencySymbolMap['USD']}
                          {calculateFormattedAmount(
                            WorkOrderBilling.billable_amount,
                          )}
                        </>
                      )}
                    </CardTextData>
                  </div>
                </ViewLessCard>
              ) : (
                <GeneralInfoItem
                  margin="15px 0 0 0"
                  minHeight="35px"
                  width="35%"
                  backColor={8}
                >
                  <CommonText fontSize="11px">
                    Use billing to set up your project billing on an hourly
                    basis or fixed rate.
                  </CommonText>
                </GeneralInfoItem>
              )}

              <CommonText
                fontSize="13px"
                color={colors['blueText']}
                margin="10px 0 0 0"
                fontWeight="550"
                style={{ cursor: 'pointer' }}
                onClick={() => toggleBillingModal(createdWorkOrderId)}
              >
                <ButtonIcon src={Plus} alt="" />
                {WorkOrderBilling && WorkOrderBilling.id
                  ? t('update_biling')
                  : t('add_biling')}
              </CommonText>
            </InputSection>

            <DividerLine margin="25px 0 10px" />

            <InputSection>
              <InputLabel fontWeight="600" fontSize="13x">
                {t('budget')}
              </InputLabel>

              {workOrderBudget && workOrderBudget.id ? (
                <ViewLessCard
                  style={{ width: '25%', margin: '13px 0px 8px 0px' }}
                >
                  <div>
                    <CardTextData margin="4px 0 0" fontSize="14px">
                      {workOrderBudget.budget_type === 'work_order_fees' &&
                        workOrderBudget.budget_hourly_based_on ===
                          'bill_rate' && (
                          <>
                            <CommonText
                              style={{ padding: '0px 0px 5px 0px' }}
                              fontSize="13px"
                              title
                            >
                              Budget Details
                            </CommonText>
                            <CommonText fontSize="12px">
                              Budget Type: Total Work Order Cost
                            </CommonText>
                            <CommonText fontSize="12px">
                              Based On: Employee Bill Rate
                            </CommonText>
                            <CommonText fontSize="12px">
                              Budget Amount: {currencySymbolMap['USD']}
                              {calculateFormattedAmount(
                                workOrderBudget.budget_amount,
                              )}
                            </CommonText>
                          </>
                        )}
                      {workOrderBudget.budget_type === 'work_order_fees' &&
                        workOrderBudget.budget_hourly_based_on ===
                          'pay_rate' && (
                          <>
                            <CommonText
                              style={{ padding: '0px 0px 5px 0px' }}
                              fontSize="13px"
                              title
                            >
                              Budget Details
                            </CommonText>
                            <CommonText fontSize="12px">
                              Budget Type: Total Work Order Cost
                            </CommonText>
                            <CommonText fontSize="12px">
                              Based On: Employee Pay Rate
                            </CommonText>
                            <CommonText fontSize="12px">
                              Budget Amount: {currencySymbolMap['USD']}
                              {calculateFormattedAmount(
                                workOrderBudget.budget_amount,
                              )}
                            </CommonText>
                          </>
                        )}

                      {workOrderBudget.budget_type === 'work_order_hours' && (
                        <>
                          <CommonText fontSize="13px" title>
                            Budget Details
                          </CommonText>
                          <CommonText fontSize="12px">
                            Budget Type: Total Work Order Hours
                          </CommonText>
                          <CommonText fontSize="12px">
                            Hours: {workOrderBudget.budget_hours}
                          </CommonText>
                        </>
                      )}
                    </CardTextData>
                  </div>
                </ViewLessCard>
              ) : (
                <GeneralInfoItem
                  margin="15px 0 0 0"
                  minHeight="35px"
                  width="35%"
                  backColor={8}
                >
                  <CommonText fontSize="11px">
                    Use Budget to set up your project budget to get notified on
                    milestones, keep track of the project's cost and
                    profitability.
                  </CommonText>
                </GeneralInfoItem>
              )}

              <CommonText
                fontSize="13px"
                color={colors['blueText']}
                margin="20px 0 0 0"
                fontWeight="550"
                style={{ cursor: 'pointer' }}
                onClick={() => toggleBudgetModal()}
              >
                <ButtonIcon src={Plus} alt="" />{' '}
                {workOrderBudget && workOrderBudget.id
                  ? t('update_budget')
                  : t('add_budget')}
              </CommonText>
            </InputSection>

            {/* Right Side Pannel */}

            <RightSlidingPanel
              isOpen={jobPanelIsOpen}
              closePanel={() => toggleJobDetailsPanel(false)}
              width="500px"
              overlayZIndex={1000}
              contentZIndex={1001}
            >
              <PageTitle style={{ padding: `30px 0 0 30px` }} fontSize="16px">
                {t('add_job_details')}
              </PageTitle>
              <>
                <InputSection marginLeft="30px">
                  <InputLabel>
                    {t('job_name')} <RedStar>*</RedStar>
                  </InputLabel>
                  <InputField
                    width="60%"
                    marginTop="10px"
                    type="text"
                    placeholder="Type job name here..."
                    value={jobName}
                    onChange={e => onJobNameChange(e)}
                    error={
                      saveErrors &&
                      saveErrors['jobName'] &&
                      saveErrors['jobName'] !== undefined
                    }
                  />
                  {saveErrors &&
                    saveErrors['jobName'] &&
                    saveErrors['jobName'] !== undefined && (
                      <FormAlert margin="0 0 0 5px">
                        {saveErrors['jobName']}
                      </FormAlert>
                    )}
                </InputSection>

                <InputSection marginLeft="30px">
                  <InputLabel>{t('job_status')}</InputLabel>
                  <InputWithLabelSection
                    style={{ width: '60%', marginTop: '10px' }}
                  >
                    <Select
                      value={jobStatus}
                      options={jobStatusOptions}
                      onChange={e => onJobStatusSelect(e)}
                      placeholder="Select Stage..."
                      styles={FilterDropDownStyle({ height: '40px' })}
                    />
                  </InputWithLabelSection>
                </InputSection>
                <InputSection marginLeft="30px">
                  <InputLabel>{t('description')}</InputLabel>
                  <InputTextArea
                    text="Description"
                    cols="30"
                    rows="5"
                    height="50%"
                    width="80%"
                    value={description}
                    placeholder="Type description here..."
                    onChange={e => onDescriptionChange(e)}
                    style={{ marginTop: '10px' }}
                  ></InputTextArea>
                </InputSection>

                {selectedClient && (
                  <>
                    <DividerLine margin="20px 0 20px" />

                    {action === 'Add' &&
                      (addLocationButton
                        ? renderLocationSection()
                        : renderAddLocationSection())}

                    {action === 'Update' &&
                      (selectedLocation && selectedLocation.value === null
                        ? addLocationButton
                          ? renderLocationSection()
                          : renderAddLocationSection()
                        : renderLocationSection())}
                  </>
                )}

                {jobStatus.value === 'ready to dispatch' && (
                  <>
                    <DividerLine margin="20px 0 20px" />

                    <CommonText title style={{ marginLeft: '30px' }}>
                      {t('shift')}
                    </CommonText>

                    {saveErrors &&
                      saveErrors['assignee'] &&
                      (assignees.length === 0 ||
                        requiredStartDate === null ||
                        requiredEndDate === null) && (
                        <FormAlert margin="5px 0 0 30px">
                          {saveErrors['assignee']}
                        </FormAlert>
                      )}

                    {selectedDate != null &&
                      requiredStartDate != null &&
                      requiredEndDate != null && (
                        <ViewLessCard
                          style={{
                            width: '60%',
                            marginLeft: '30px',
                            marginTop: '15px',
                          }}
                        >
                          <CardTextData fontSize="14px">
                            <CommonText
                              fontSize="12.5px"
                              style={{ marginBottom: '10px' }}
                            >
                              Selected Date:{' '}
                              {moment(selectedDate).format('YYYY-MM-DD')}
                            </CommonText>
                            <CommonText fontSize="12.5px">
                              Start Time:{' '}
                              {moment(requiredStartDate).format('h:mm a')}
                            </CommonText>
                            <CommonText fontSize="12.5px">
                              End Time:{' '}
                              {moment(requiredEndDate).format('h:mm a')}
                            </CommonText>
                          </CardTextData>
                        </ViewLessCard>
                      )}

                    <InputSection marginLeft="30px">
                      <CommonText
                        fontSize="13px"
                        color={colors['blueText']}
                        margin="10px 0 0 0"
                        fontWeight="550"
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          action === 'Add'
                            ? toggleShiftModalCreateJob()
                            : toggleShiftModal()
                        }
                      >
                        <ButtonIcon src={Plus} alt="" />{' '}
                        {selectedDate != null &&
                        requiredStartDate != null &&
                        requiredEndDate != null
                          ? t('update_shift')
                          : t('add_shift')}
                      </CommonText>
                    </InputSection>
                  </>
                )}

                <CommonFlex margin="30px 0px 0 30px" gap="15px">
                  <WhiteButton
                    onClick={() => toggleJobDetailsPanel(false)}
                    width="25%"
                  >
                    {t('cancel')}
                  </WhiteButton>
                  <PrimaryButton
                    // disabled={!jobName && (jobStatus.value === 'ready to dispatch' && assignees.length === 0)}
                    disabled={jobName === ''}
                    onClick={() => handleJobSave()}
                    width="40%"
                  >
                    {createOrUpdateJobIsLoading || UpdateWorkOrderLoading ? (
                      <ButtonTextLoader
                        loadingText={`${
                          action === 'Update' ? 'Updating' : 'Adding'
                        } `}
                        fontSize="13px"
                      />
                    ) : (
                      `${action === 'Update' ? 'Update' : 'Save'} Job Deatils`
                    )}
                  </PrimaryButton>
                </CommonFlex>
              </>
            </RightSlidingPanel>
          </>
        )}
      </Container>
    </div>
  );
};

export default CreateWorkOrder;
