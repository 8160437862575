import {
  DONT_SHOW_ORGANIZATION_WIZARD_SUCCESS,
  LOGIN_PROFILE_CREATED_RESET,
  LOGIN_REDIRECT_RESET,
  LOGIN_REQUEST,
  LOGIN_REQUEST_FAILURE,
  LOGIN_REQUEST_SUCCESS,
  REGISTER_REQUEST_SUCCESS,
} from '../../modules/constants';
import { createNotification } from '../../modules/notificationManager';

// ------------------------------------
// Action handler methods
// ------------------------------------
export const handleLoginRequest = (state, action) => {
  return {
    ...state,
    errorMessage: '',
    isLoading: true,
  };
};
// handle the login request and redirect to the next page
// check in login component
export const handleLoginRequestSuccess = (state, action) => {
  const payload = action.payload;
  if (payload.status === 200) {
    if (payload.data.confirmed === false) {
      createNotification('error', 'Please, verify your email to login!', 4000);
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
        notVerified: true,
        auth: payload.data,
        redirect: true,
      };
    } else {
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
        auth: payload.data,
        redirect: true,
      };
    }
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const handleLoginRequestFailure = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification('error', 'No internet connection found', 2000);
    return {
      ...state,
      isLoading: false,
      redirect: false,
    };
  } else {
    if (
      response.data &&
      response.data.non_field_errors &&
      response.data.non_field_errors.length > 0
    ) {
      return {
        ...state,
        isLoading: false,
        redirect: false,
        errorMessage: response.data.non_field_errors[0],
      };
    } else {
      return {
        ...state,
        isLoading: false,
        redirect: false,
      };
    }
  }
};

export const handleLoginRedirectReset = (state, action) => {
  return {
    ...state,
    isLoading: false,
    redirect: false,
    notVerified: false,
  };
};

export const handleRegisterAuthSuccess = (state, action) => {
  return {
    ...state,
    auth: action.payload.data.data.auth,
  };
};

export const handleLogoutRequest = (state, action) => {
  const resetState = { ...state, ...initialState };
  return {
    ...resetState,
  };
};

export const handleDontShowWizardAgain = (state, action) => {
  if (
    state.auth &&
    state.auth.onboardcompletion &&
    state.auth.onboardcompletion.dont_show_again !== undefined
  ) {
    let newState = JSON.parse(JSON.stringify(state));
    newState.auth.onboardcompletion = action.payload.data;
    return {
      ...state,
      ...newState,
    };
  }
  return {
    ...state,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [LOGIN_REQUEST]: handleLoginRequest,
  [LOGIN_REQUEST_SUCCESS]: handleLoginRequestSuccess,
  [LOGIN_REQUEST_FAILURE]: handleLoginRequestFailure,
  [LOGIN_REDIRECT_RESET]: handleLoginRedirectReset,
  [REGISTER_REQUEST_SUCCESS]: handleRegisterAuthSuccess,
  [DONT_SHOW_ORGANIZATION_WIZARD_SUCCESS]: handleDontShowWizardAgain,
  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  auth: {},
  redirect: false,
  isLoading: false,
  notVerified: false,
  errorMessage: '',
};

export default function authReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
