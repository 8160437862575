import { connect } from 'react-redux';

import GHLAuth from './GHLAuth';
import {
  resetSendGHLAuthenticationCode,
  sendGHLAuthenticationCode,
} from './ghlAuthActions';

const mapStateToProps = state => ({
  isLoading: state.ghlAuth.isLoading,
  redirect: state.ghlAuth.redirect,
  token: state.ghlAuth.token,
  userData: state.ghlAuth.userData,
});

const mapDispatchToProps = dispatch => ({
  sendGHLAuthenticationCode: payload =>
    dispatch(sendGHLAuthenticationCode({ ...payload })),

  resetSendGHLAuthenticationCode: () =>
    dispatch(resetSendGHLAuthenticationCode()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GHLAuth);
