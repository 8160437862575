import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Plus from '../../assets/img/icons/plus_white.svg';
import BottomPagination from '../../components/Pagination/BottomPagination/BottomPagination';
import TopPagination from '../../components/Pagination/TopPagination/TopPagination';
import SearchWithButton from '../../components/Search/SearchWithButton';
import {
  ButtonIcon,
  ColoredButtonWithIcon,
  TabButton,
  TabButtonContainer,
} from '../../styledComponents/buttons';
import {
  CommonGrid,
  FilterLabel,
  HeaderContainer,
  InputField,
  InputWithLabelSection,
  PageTitle,
} from '../../styledComponents/common';
import { TabLeftGrid } from '../../styledComponents/members';
import DeleteInvitationModal from './DeleteInvitationModal';
import GHLSyncModal from './GHLSyncModal';
import MemberDeleteModal from './MemberDeleteModal';
import MembersFilter from './MembersFilter';
import MembersTable from './MembersTable';

const Members = props => {
  const { t } = useTranslation();
  const [memberList, setMemberList] = useState([]);
  const [invitedMemberList, setInvitedMemberList] = useState([]);
  const [activeTab, setActiveTab] = useState('active');

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isDeleteInvitationModalVisible, setIsDeleteInvitationModalVisible] =
    useState(false);
  const [memberDeleteId, setMemberDeleteId] = useState('');
  const [invitationDeleteId, setInvitationDeleteId] = useState('');

  // filter related state
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState('');

  const [selectedRole, setSelectedRole] = useState({
    label: 'All Roles',
    value: null,
  });

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  //GHL employee modal
  const [isGHLSyncModalOpen, setIsGHLSyncModalOpen] = useState(false);
  const [filteredGHLEmployee, setFilteredGHLEmployee] = useState([]);

  // ghl first time login

  const {
    location,
    history,
    selectedOrganization,
    officesShortList,
    getOfficesShortList,
    getMembersList,
    getOrganizationMembersShortList,
    getInvitedMembersList,
    memberShortListIsLoading,
    membersList,
    orgMembersShortList,
    invitedMembersList,
    inviteMemberBulk,
    memberInvited,
    clearMemberInvited,
    inviteMemberLoading,
    isLoading,
    updateMember,
    updatedData,
    updateMemberwiseTimesheetApprovalSettings,
    //ghl employee data
    getGHLCustomerList,
    ghlSyncMemberIsLoading,
    ghlEmployeeList,
    // pagination
    totalMembersCount,
    pageSize,
  } = props;


  useEffect(() => {
    if (orgMembersShortList && ghlEmployeeList) {
      const filteredList = ghlEmployeeList.filter(employee => {
        const emailExists = orgMembersShortList.some(
          orgMember => orgMember.email === employee.email,
        );
        return !emailExists;
      });
      setFilteredGHLEmployee(filteredList);
    }
  }, [orgMembersShortList, ghlEmployeeList]);

  useEffect(() => {
    if (location && location.search) {
      let queryParsed = queryString.parse(location.search);
      if (queryParsed.tab && queryParsed.tab === 'invited') {
        setActiveTab('invited');
      }
      if (
        queryParsed.ghl_members &&
        selectedOrganization &&
        selectedOrganization.id
      ) {
        ghlCustomerFetch();
      }
    }
  }, [location, selectedOrganization]);

  useEffect(() => {
    setSelectedOffice({
      label: t('all_offices'),
      value: null,
    });
    if (selectedOrganization && selectedOrganization.id) {
      setCurrentPageNumber(1);
      getMembersList({
        organization_id: selectedOrganization.id,
        page: 1,
      });
      if (activeTab === 'invited') {
        getInvitedMembersList({
          organization_id: selectedOrganization.id,
        });
      }
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      if (activeTab === 'invited') {
        setSearchTerm('');

        setCurrentSearchedTerm('');
      }
    }
  }, [activeTab]);

  useEffect(() => {
    if (membersList && membersList.length > 0) {
      setMemberList(membersList);
    } else if (membersList && membersList.length === 0) {
      setMemberList([]);
    }
  }, [membersList]);

  useEffect(() => {
    if (invitedMembersList && invitedMembersList.length > 0) {
      setInvitedMemberList(invitedMembersList);
    } else if (invitedMembersList && invitedMembersList.length === 0) {
      setInvitedMemberList([]);
    }
  }, [invitedMembersList]);

  const onTabSwitch = value => {
    if (value === 'invited') {
      if (selectedOrganization && selectedOrganization.id) {
        getInvitedMembersList({
          organization_id: selectedOrganization.id,
        });
      }
    }
    setActiveTab(value);
  };

  const GHLSyncToggle = () => {
    setIsGHLSyncModalOpen(!isGHLSyncModalOpen);
  };

  const ghlCustomerFetch = () => {
    console.log('hitted here 2');
    if (selectedOrganization && selectedOrganization.id) {
      console.log('hitted here 3');
      const payload = {
        organization_id: selectedOrganization.id,
      };
      console.log('hitted here 4');
      getGHLCustomerList(payload);
      getOrganizationMembersShortList(payload);
      GHLSyncToggle();
    }
  };

  const handleSearchByName = () => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      activeTab === 'active'
    ) {
      setCurrentPageNumber(1);
      const payload = {
        organization_id: selectedOrganization.id,
        page: 1,
      };
      if (searchTerm && searchTerm.trim()) {
        payload.name = searchTerm.toLowerCase().trim();
        setCurrentSearchedTerm(searchTerm);
      } else {
        setCurrentSearchedTerm('');
      }
      if (selectedOffice && selectedOffice.value) {
        payload.office_id = selectedOffice.value;
      }
      getMembersList(payload);
    }
  };

  const handleClearSearch = () => {
    if (
      currentSearchedTerm &&
      selectedOrganization &&
      selectedOrganization.id
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        page: 1,
      };

      if (selectedOffice && selectedOffice.value) {
        payload.office_id = selectedOffice.value;
      }

      getMembersList(payload);
      setCurrentPageNumber(1);
    }
    setTimeout(() => {
      setSearchTerm('');
      setCurrentSearchedTerm('');
    }, 100);
  };

  const handlePageChange = page => {
    // console.log(page);
    if (selectedOrganization && selectedOrganization.id) {
    }
    const payload = {
      page: page,
    };
    if (selectedOrganization && selectedOrganization.id) {
      payload.organization_id = selectedOrganization.id;
    }
    if (selectedOffice && selectedOffice.value) {
      payload.office_id = selectedOffice.value;
    }
    if (searchTerm && searchTerm.trim()) {
      payload.name = searchTerm.toLowerCase().trim();
    }

    getMembersList(payload);
    // props.getOrganizationMembersList(payload);
    setCurrentPageNumber(page);
  };

  const selectOffice = e => {
    const payload = {
      page: 1,
    };
    if (e && e.value) {
      payload.office_id = e.value;
    }
    if (selectedRole && selectedRole.value) {
      payload.role = selectedRole.value;
    }
    getMembersList(payload);
    setCurrentPageNumber(1);
    setSearchTerm('');
    setCurrentSearchedTerm('');
    setCurrentUserCodeSearchedTerm('');
    setSelectedOffice(e);
  };

  const onSearchTermChange = e => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const onGeoFenceTimeClockChange = (index, user_id, value) => {
    let newArr = [...memberList];
    newArr.map((member, i) => {
      if (index === i) {
        member.geofence_time_clock = value;
      }
    });
    setMemberList(newArr);
    updateMember({
      user_id: user_id,
      geofence_time_clock: value,
    });
  };

  return (
    <div className="content">
      {/* <MemberDeleteModal
        isOpen={isDeleteModalVisible}
        toggle={() => setIsDeleteModalVisible(!isDeleteModalVisible)}
        handleDelete={handleDeleteMember}
        isLoading={deleteMemberLoading}
      />
      <DeleteInvitationModal
        isOpen={isDeleteInvitationModalVisible}
        toggle={() =>
          setIsDeleteInvitationModalVisible(!isDeleteInvitationModalVisible)
        }
        handleDelete={handleDeleteInvitation}
        isLoading={deleteInvitationLoading}
      /> */}
      <GHLSyncModal
        isOpen={isGHLSyncModalOpen}
        toggle={GHLSyncToggle}
        memberShortListIsLoading={memberShortListIsLoading}
        ghlSyncMemberIsLoading={ghlSyncMemberIsLoading}
        filteredGHLEmployee={filteredGHLEmployee}
        inviteMemberBulk={inviteMemberBulk}
        memberInvited={memberInvited}
        inviteMemberLoading={inviteMemberLoading}
        selectedOrganization={selectedOrganization}
        clearMemberInvited={clearMemberInvited}
      />
      <CommonGrid alignItem="center">
        <PageTitle>{t('members')}</PageTitle>
        {/* {localStorage.getItem('user_role') &&
          localStorage.getItem('user_role') === 'admin' &&
          !localStorage.getItem('viewer_role') && (
            <ColoredButtonWithIcon
              onClick={() => history.push('/user/member-add-update')}
            >
              <ButtonIcon src={Plus} />
              {t('add_user')}
            </ColoredButtonWithIcon>
          )} */}
        {/* {selectedOrganization &&
        (selectedOrganization.role === 'owner' ||
          selectedOrganization.role === 'admin') ? (
          <ColoredButtonWithIcon
            onClick={() => history.push('/user/member-invitation')}
          >
            <ButtonIcon src={Plus} alt="" />
            Invite Member
          </ColoredButtonWithIcon>
        ) : null} */}
      </CommonGrid>

      <MembersFilter
        selectedOrganization={selectedOrganization}
        officesShortList={officesShortList}
        getOfficesShortList={getOfficesShortList}
        selectedOffice={selectedOffice}
        selectOffice={selectOffice}
        searchTerm={searchTerm}
        onSearchTermChange={onSearchTermChange}
        handleSearchByName={handleSearchByName}
        handleClearSearch={handleClearSearch}
        ghlCustomerFetch={ghlCustomerFetch}
        history={history}
      />

      {totalMembersCount && totalMembersCount > 0 && pageSize ? (
        <TopPagination
          itemName={t('members')}
          totalCount={totalMembersCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}

      <MembersTable
        membersList={memberList}
        history={history}
        searchTerm={searchTerm}
        isLoading={isLoading}
        onGeoFenceTimeClockChange={onGeoFenceTimeClockChange}
        selectedOrganization={selectedOrganization}
        updateMemberwiseTimesheetApprovalSettings={
          updateMemberwiseTimesheetApprovalSettings
        }
      />
      {totalMembersCount && totalMembersCount > 0 && pageSize ? (
        <BottomPagination
          totalCount={totalMembersCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}
    </div>
  );
};

export default Members;
