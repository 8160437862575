import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";

//Deafult CSS files
import "bootstrap/dist/css/bootstrap.css";
// import "assets/scss/paper-dashboard.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-virtualized/styles.css";
import "lightgallery.js/dist/css/lightgallery.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-dates/lib/css/_datepicker.css";
import "assets/scss/paper-dashboard.scss";
//Store
import { store, persistor } from "./configureStore";

//Persist
import { PersistGate } from "redux-persist/integration/react";

//Provider
import { Provider, ReactReduxContext } from "react-redux";

import { ToastContainer, Slide } from "react-toastify";

//react-dates
import "react-dates/initialize";

import { ConnectedRouter } from "connected-react-router";
import App from "./App";
import moment from "moment";
import momentLocalizer from "react-widgets-moment";
import "react-widgets/dist/css/react-widgets.css";
//import "react-table/react-table.css";
import { checkToken } from "./custom_modules/SignUp/signUpActions";

import { ThemeProvider } from "styled-components";
import { GlobalStyles, lightTheme } from "./styles";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import { Suspense } from "react";
import Cookies from "universal-cookie";

// import 'moment/locale/ar';

momentLocalizer(moment);
//cookies test
const cookies = new Cookies();
const language =
  cookies.get("language") != undefined ? cookies.get("language") : "en";
//import i18next configuration
i18next
  .use(HttpApi)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["ar", "en"],
    fallbackLng: "en",
    lng: language,
    debug: false,
    // Options for language detector
    // detection: {
    //   order: ['path', 'cookie', 'htmlTag'],
    //   caches: ['cookie'],
    // },
    react: { useSuspense: false },
    backend: {
      loadPath: "/language/{{lng}}/translation.json",
    },
  });

const hist = createBrowserHistory();
ReactDOM.render(
  <Provider store={store} context={ReactReduxContext}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={lightTheme}>
        <GlobalStyles language={language} />
        <ConnectedRouter history={hist} context={ReactReduxContext}>
          <App />
        </ConnectedRouter>
        <ToastContainer
          position="top-right"
          autoClose={4000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          transition={Slide}
          pauseOnHover
        />
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

if (localStorage.getItem("access_token")) {
  store.dispatch(checkToken());
}
