import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";

import avatar5 from "../../assets/img/avatar_5.svg";
import crossIcon from "../../assets/img/icons/cross_black.svg";
import deleteIcon from "../../assets/img/icons/delete_white.svg";
import editIcon from "../../assets/img/icons/edit-white-shift.svg";
import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";
import RoundImageNameComp from "../../components/RoundImageName/RoundImageNameComp";
import {
  ColoredButton,
  PrimaryButton,
  SecondaryButton,
  WhiteButton,
} from "../../styledComponents/buttons";
import {
  CardTitle,
  CommonGrid,
  CommonText,
  InputLabel,
  StyledLink,
} from "../../styledComponents/common";
import { UserSettings } from "../../styledComponents/members";
import { getRandomColor } from "../../utils/getRandomColor";
import { formatDurationToHoursMinutes, getFullName } from "../../utils/helper";

const SlotContainer = styled.div`
  display: grid;
  gap: 20px;
  margin-top: 20px;
`;

const ColoredCircle = styled.div`
  position: absolute;
  top: -12px;
  right: ${(props) => (props.edit ? `52px` : `20px`)};
  display: none;
  z-index: 5;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: ${(props) => (props.edit ? `#7F9DBF` : `#FE5969`)};
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const TimeDiv = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadii.button};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.font.inputLabel.size};
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  background: ${({ bgColor }) => bgColor};
  padding: 15px;
  &:hover ${ColoredCircle} {
    display: grid;
  }
`;
const TextSection = styled.div`
  display: grid;
  gap: 5px;
`;
const ClosePopup = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
  cursor: pointer;
`;
const ButtonContainer = styled.div`
  display: flex;
  gap: 30px;
  justify-content: space-between;
  margin-top: 30px;
`;

export const RadioButton = styled.div`
  display: flex;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid;
  border-color: ${({ active, theme }) =>
    active ? theme.colors.main.primary : theme.colors.main.textMain};
  background-color: ${({ active, theme }) =>
    active ? theme.colors.main.primary : theme.colors.main.white};
  cursor: pointer;
`;

const DeleteShiftPopup = ({
  isOpen,
  toggle,
  shift,
  organization_id,
  deleteShifts,
  deleteCallback,
  deleteIsLoading,
  onDeleteChange,
  isDeleted,
  deleteWorkOrderJob,
  workOrderJobDeleteIsLoading,
}) => {
  const [recurring, setRecurring] = useState(false);

  useEffect(() => {
    if(isOpen){
      setRecurring(false);
    }
  }, [isOpen])
  

  const onChangeDeleteType = (value) => {
    setRecurring(value);
  };

  const handleDeleteShift = () => {
    if (shift && shift.id && shift.work_order && shift.work_order.id) {
      const payload = {
        organization_id: organization_id,
        work_order_id: shift.work_order.id,
        job_id: shift.id,
        recurring: recurring,
      };
      deleteWorkOrderJob(payload);
      onDeleteChange(true);
    } else if (shift && shift.id) {
      const payload = {
        organization_id: organization_id,
        shift_id: shift.id,
        recurring: recurring,
      };
      deleteShifts(payload);
      onDeleteChange(true);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ marginTop: `15vh`, maxWidth: `375px` }}
      backdrop="static"
    >
      <ModalBody style={{ padding: `20px` }}>
        <CardTitle margin="0 0 20px 10px">Delete Shift</CardTitle>
        <ClosePopup onClick={() => toggle()}>
          <img src={crossIcon} alt="" width="30px" />
        </ClosePopup>
        {shift && Object.keys(shift).length > 0 && (
          <SlotContainer>
            <TimeDiv
              bgColor={shift && shift.theme_color ? shift.theme_color : `#FFF`}
            >
              <TextSection>
                <CardTitle fontSize="14px" fontWeight="600">
                  {shift.name}
                </CardTitle>
                <CommonText name fontSize="13px">
                  <span style={{ fontWeight: `600` }}>Type: </span>
                  {shift.work_order && shift.work_order.id
                    ? "Job Schedule"
                    : "Office Schedule"}
                </CommonText>
                <CommonText name fontSize="13px">
                  <span style={{ fontWeight: `600` }}>Slot: </span>{" "}
                  {shift.start_time &&
                    moment(shift.start_time).format("h:mm a")}{" "}
                  - {shift.end_time && moment(shift.end_time).format("h:mm a")}{" "}
                  ,{" "}
                  {shift.start_time &&
                    moment(shift.start_time).format("dddd, D MMM YYYY")}
                </CommonText>
                <CommonText name fontSize="13px">
                  Assigned Member :{" "}
                  {shift.assignees &&
                    shift.assignees.length > 0 &&
                    shift.assignees.map(
                      (assignee, i) =>
                        `${getFullName(assignee)} ${
                          shift.assignees.length - 1 > i ? ", " : ""
                        }`
                    )}
                </CommonText>
              </TextSection>
            </TimeDiv>
            {shift.recurring && (
              <>
                <CardTitle fontSize="16px" margin="0 0 0 10px">
                  Select Delete Type
                </CardTitle>
                <UserSettings style={{ gridTemplateColumns: `auto` }}>
                  <UserSettings
                    style={{ cursor: `pointer`, marginRight: `auto` }}
                    onClick={() => onChangeDeleteType(false)}
                  >
                    <RadioButton active={!recurring} />
                    <CommonText name fontSize="15px">
                      Only this shift
                    </CommonText>
                  </UserSettings>
                  <UserSettings
                    style={{ cursor: `pointer`, marginRight: `auto` }}
                    onClick={() => onChangeDeleteType(true)}
                  >
                    <RadioButton active={recurring} />
                    <CommonText name fontSize="15px">
                      {" "}
                      Selected shift and all future shifts
                    </CommonText>
                  </UserSettings>
                </UserSettings>
              </>
            )}
            <ButtonContainer>
              <WhiteButton color="#434343" onClick={() => toggle()}>
                Cancel
              </WhiteButton>
              <ColoredButton type="delete" onClick={() => handleDeleteShift()}>
                {deleteIsLoading || workOrderJobDeleteIsLoading ? (
                  <ButtonTextLoader loadingText="Deleting" fontSize="13px" />
                ) : (
                  "Delete"
                )}
              </ColoredButton>
            </ButtonContainer>
          </SlotContainer>
        )}
      </ModalBody>
    </Modal>
  );
};

export default DeleteShiftPopup;
