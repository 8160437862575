import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

//  ---- Projcts main page
export const TabSearchGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || "auto 280px 230px"};
  justify-content: start;
  grid-column-gap: 15px;
  @media (max-width: 767px) {
    grid-template-columns: auto;
    justify-content: start;
    grid-row-gap: 10px;
  }
`;
export const ProjectButtonContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ isAdmin }) =>
    isAdmin ? `auto auto auto` : `auto auto`};
  justify-content: start;
  grid-column-gap: 5px;
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  padding: 3px;
  background-color: ${({ theme }) => theme.colors.main.white};
`;
export const ProjectButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  font-size: 13px;
  font-weight: ${({ projectType, index, theme }) =>
    index === projectType
      ? theme.fontWeights.semibold
      : theme.fontWeights.regular};
  padding: 0 12px;
  border-radius: ${({ theme }) => theme.button.borderRadius};
  color: ${({ index, projectType, theme }) =>
    index === projectType
      ? theme.colors.main.white
      : theme.colors.main.textMain};
  background-color: ${({ index, projectType, theme }) =>
    index == projectType ? theme.colors.main.primary : theme.colors.main.white};
  cursor: pointer;
  border: 1px solid
    ${({ index, projectType, theme }) =>
      index == projectType ? theme.colors.main.primary : "transparent"};

  &:hover {
    border-color: ${({ theme }) => theme.colors.main.primary};
  }
`;
export const ProjectRightButtonContainer = styled.div`
  display: grid;
  /*grid-template-columns: 1fr 1fr auto;*/
  grid-template-columns: auto;
  justify-content: end;
  grid-column-gap: 15px;
  @media (max-width: 500px) {
    justify-content: center;
    grid-template-columns: auto;
    grid-gap: 15px;
  }
`;
export const GrayButton = styled.div`
  padding: 13px 30px;
  background-color: #7f9dbf;
  color: white;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  @media (max-width: 767px) {
    padding: 10px 25px;
  }
`;
export const Plus = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

// ----- PROJECT TABLE -----
export const TableRowAndBudgetCollapseContainer = styled.div`
  :nth-of-type(even) {
    background-color: ${({ heading }) => !heading && `#f8f9fc`};
  }
`;
export const ProjectTableContainer = styled.div`
  display: grid;
  grid-template-columns: 1.5fr minmax(170px, 190px) 80px 1fr minmax(80px, 90px) 100px minmax(
      150px,
      1fr
    );
  /* grid-template-columns:
    1.3fr minmax(170px, 190px) 70px 1fr minmax(80px, 90px) 100px minmax(150px, 1fr)
    45px; */
  grid-column-gap: 15px;
  justify-content: space-between;
  padding: 0 30px;
  border-bottom: ${({ heading, theme }) =>
    heading && `1px solid ${theme.colors.border.secondary}`};

  :nth-of-type(even) {
    background-color: ${({ heading }) => !heading && `#f8f9fc`};
  }
`;

export const ProjectTableContainerNew = styled.div`
  display: grid;
  /* grid-template-columns:
    1.5fr minmax(170px, 190px) 80px 1fr minmax(80px, 90px) 100px minmax(150px, 1fr); */
  grid-template-columns:
    1.1fr minmax(80px, 120px) 180px 1.3fr minmax(100px, 120px) 120px minmax(
      150px,
      1fr
    )
    45px;
  grid-column-gap: 15px;
  justify-content: space-between;
  padding: 0 30px;
  border-bottom: ${({ heading, theme }) =>
    heading && `1px solid ${theme.colors.border.secondary}`};
  :nth-of-type(even) {
    background-color: ${({ heading }) => !heading && `#f8f9fc`};
  }
  @media (max-width: 1480px) {
    grid-template-columns: minmax(120px, 150px) minmax(80px, 110px) 150px 135px minmax(
        100px,
        120px
      ) 120px minmax(90px, 110px) 45px;
  }
`;

export const BudgetCollapseSection = styled.div`
  display: grid;
  min-height: ${({ open }) => (open ? `150px` : `0`)};
  transition: min-height 0.5s ease;
`;
export const TableItem = styled.div`
  display: grid;
  padding: ${(props) => (props.buttonPadding ? `8px 0` : `15px 0`)};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  align-content: center;
  justify-self: ${(props) => (props.rightAlign ? "end" : "start")};
  ${({ hoverPointer }) =>
    hoverPointer &&
    css`
      cursor: pointer;
    `}
`;

// ----- PROJECT LIST -----
export const ProjectListSection = styled.div`
  display: grid;
  grid-template-columns: 100%;
`;
export const ProjectListItem = styled.div`
  padding: ${({ padding }) => padding || "25px"};
  border: 1px solid #c2cce1;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #fff;
  @media (max-width: 500px) {
    padding: 0 20px;
  }
`;
export const ListUpperSection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 30px;
  @media (max-width: 767px) {
    grid-template-columns: auto;
    grid-row-gap: 15px;
  }
  @media (max-width: 500px) {
    justify-content: center;
  }
`;
export const ProjectTitleDiv = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || `auto auto auto`};
  justify-content: start;
  grid-column-gap: ${({ columnGap }) => columnGap || `5px`};
  margin-bottom: 10px;
`;
export const ClientDeadlineGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 15px;
  justify-content: start;
  align-items: center;
  @media (max-width: 1024px) {
    grid-template-columns: auto;
    grid-row-gap: 10px;
  }
`;
export const ProjectItemCount = styled.div`
  position: relative;
  top: -7px;
  font-size: 9px;
  font-weight: ${({ fontWeight }) => fontWeight || 600};
  color: #fff;
  border-radius: 4px;
  background-color: #20bead;
  padding: 3px 7px;
`;
export const ActionSection = styled.div`
  display: grid;
  grid-template-columns: ${({ hasEditAccess, columns }) =>
    columns || hasEditAccess ? `105px 100px 120px` : `120px`};
  /* grid-template-columns: 150px; */
  justify-content: end;
  grid-column-gap: 12px;
  @media (max-width: 767px) {
    justify-content: start;
  }
  @media (max-width: 349px) {
    grid-template-columns: 80%;
    grid-gap: 15px;
  }
`;

// ----- VIEW LESS -----

export const ViewLessCardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
  padding: ${({ padding }) => padding || "15px 30px 30px"};
  @media (max-width: 1210px) {
    grid-template-columns: auto 1fr 1fr auto 1fr;
  }
  @media (max-width: 1024px) {
    grid-column-gap: 10px;
  }
  @media (max-width: 767px) {
    display: flex;
    flex-flow: row wrap;
    grid-gap: 10px;
  }
  @media (max-width: 420px) {
    display: grid;
    grid-template-columns: 80%;
    grid-gap: 10px;
    justify-content: center;
  }
`;
export const ViewLessCard = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-column-gap: 10px;
  /* align-content: center;
  justify-content: center; */
  padding: 15px;
  border: 1px solid #e4e7eb;
  background: #fcfdfe;
  border-radius: 6px;
  /* ${({ details }) =>
    details &&
    css`
      text-align: center;
    `} */
  @media (max-width: 420px) {
    justify-content: start;
  }
`;
export const CardTextData = styled.p`
  margin: ${({ margin }) => margin || "auto 0"};
  text-align: ${({ centered }) => centered && "center"};
  color: ${({ textColor }) => textColor || "#2f394e"};
  font-size: ${({ fontSize }) => fontSize || `16px`};
  font-weight: 600;
`;
export const CicrularBarSection = styled.div`
  display: grid;
  align-content: center;
`;

// ----- VIEW DETAILS -----

export const ViewDetailsContainer = styled.div`
  padding: 0 30px;
  display: grid;
  grid-template-columns: 80%;
  justify-content: center;
  grid-row-gap: 20px;
`;
export const DetailTitleSelectSection = styled.div`
  display: grid;
  grid-template-columns: auto 150px;
  grid-column-gap: 15px;
  justify-content: space-between;
  padding: 15px 0;
`;
export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;
export const ChartContainer = styled.div`
  display: grid;
  grid-template-columns: 70%;
  grid-row-gap: 20px;
  justify-content: center;
  max-width: 800px;
  padding: 10px 0;
`;
export const LinearProgressBarTrack = styled.div`
  display: grid;
  grid-template-columns: ${(props) => `${props.percent}%`};
  justify-content: start;
  background-color: #e4e7eb;
  border-radius: 20px;
  height: 6px;
  margin-top: 10px;
`;
export const LinearProgressBarTrain = styled.div`
  height: 6px;
  border-radius: 20px;
  background-color: #20bead;
`;
export const NewInvoice = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
  align-items: center;
  justify-content: center;
  color: #20bead;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
`;

// Invoice Section
export const InvoiceSectionContainer = styled.div`
  padding: 20px 0px;
`;
export const InvoiceSectionTitle = styled.div`
  padding-left: 30px;
  font-size: 16px;
  font-weight: 600;
`;
export const ProjectInvoiceTableContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 100px 1fr minmax(100px, 120px) 160px;
  grid-column-gap: 20px;
  justify-content: start;
  padding: 0 30px;
  border-bottom: ${(props) => props.heading && `1px solid #c2cce1`};
  :nth-of-type(odd) {
    background-color: ${(props) => !props.heading && `#f8f9fc`};
  }
`;
export const InvoiceTableItem = styled.div`
  display: grid;
  padding: ${({ buttonPadding }) => (buttonPadding ? `8px 0` : `15px 0`)};
  align-content: center;
  ${({ hoverPointer }) =>
    hoverPointer &&
    css`
      cursor: pointer;
    `}
`;

// ----- TASK LIST -----

export const TaskListContaier = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 20px 30px;
  align-content: center;
  justify-content: space-between;
  max-width: 1200px;
  padding: 30px 0;
`;
export const AssigneeRoundImageSection = styled.div`
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  left: ${(props) =>
    props.position < 4 ? `-${props.position * 15}px` : `-45px`};
`;

// No data graph

export const NoDataGraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NoDataGraph = styled.img``;

export const NoDataText = styled.div`
  font-size: ${({ fontSize, theme }) => fontSize || theme.font.pageText.size};
  color: ${({ color, theme }) => color || theme.font.otherLabel.color};
  margin-top: 15px;
`;

// Duplicate
export const ProjectDuplicateDiv = styled.div`
  margin-left: 8px;
`;

export const ProjectDuplicateIcon = styled.img`
  cursor: pointer;
  height: 18px;
  width: 18px;
`;

export const BudgetLeftSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`;

export const ProjectCardLabel = styled.span`
  color: rgba(47, 57, 78, 0.7);
  font-size: 13px;
`;

export const ProjectCardText = styled.span`
  font-size: ${({ fontSize }) => fontSize || `13px`};
  font-weight: ${({ fontWeight }) => fontWeight || "500"};
  color: #2f394e;
`;
