import styled from "styled-components";
export const RightAlignedSpan = styled.span`
  color: green;
  font-weight: bold;
  text-align: right;
  padding-left: 10px; /* Adjust the value according to your preference */
`;

// export const TooltipContainerWorkOrder = styled.div`
//   position: absolute;
//   top: ${({ top }) => (top ? top : '44.3%')};
//   background-color: #313131;
//   padding: 8px;
//   border: 1px solid #ccc;
//   border-radius: 6px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

//   &::before {
//     content: '';
//     position: absolute;
//     top: 100%; /* Position it just below the tooltip */
//     left: 50%;
//     border: solid transparent;
//     border-width: 5px; /* Adjust the size of the arrow */
//     border-top-color: #313131; /* Set arrow color to match tooltip background */
//     transform: translateX(-50%);
//   }
// `;

export const TooltipContainerWorkOrder = styled.div`
  position: absolute;
  // top: 44.3%;
  display: inline-block;
  background-color: #313131;
  padding: 8px;
  margin: 8px;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &::before {
    content: '';
    position: absolute;
    bottom: 100%; /* Position it just below the tooltip */
    left: 50%;
    border: solid transparent;
    border-width: 5px; /* Adjust the size of the arrow */
    border-top-color: #313131; /* Set arrow color to match tooltip background */
    transform: translateX(-50%) rotate(180deg); /* Rotate the arrow */
    display: block;
  }
`;













