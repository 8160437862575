import Papa from 'papaparse';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import styled from 'styled-components';

import CSVDownload from '../../assets/img/icons/csv-download.svg';
import CSVFileName from '../../assets/img/icons/csv-file-name.svg';
import CSVInvalidRow from '../../assets/img/icons/csv-invalid-row.svg';
import CSVValidRows from '../../assets/img/icons/csv-valid-rows.svg';
import FileGroup from '../../assets/img/icons/file-group.svg';
import Plus from '../../assets/img/icons/plus_white.svg';
import ButtonTextLoader from '../../components/Loaders/ButtonTextLoader';
import { ColoredButton, WhiteButton } from '../../styledComponents/buttons';
import {
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonImage,
  CommonText,
} from '../../styledComponents/common';
import { ModalButtonSection } from '../../styledComponents/members';
import { validateEmail } from '../../utils/helper';

const ImportCustomerModal = ({
  isOpen,
  toggle,
  isLoading,
  selectedOrganization,
  createCustomerBulk,
  bulkCustomerCreated,
}) => {
  // Styled components for the round-shaped container
  const RoundContainer = styled.div`
    background-color: #f2f2f2;
    border-radius: 50%; /* Make it round */
    width: 200px; /* Adjust width as needed */
    height: 200px; /* Adjust height as needed */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative; /* For positioning the Plus icon */
    margin-right: 25px;
  `;
  const PlusIcon = styled.img`
    align-self: center;
    width: 35px; /* Adjust width as needed */
    height: 35px; /* Adjust height as needed */
    border-radius: 50%; /* Make it round */
    padding: 5px;
    background-color: #3b86fb; /* Set background color to blue */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
  `;
  const CircularProgress = styled.div`
    position: relative;
    width: 100px;
    height: 100px;
    margin-left: 55px;
  `;

  const Circle = styled.svg`
    width: 100%;
    height: 100%;
  `;

  const Track = styled.circle`
    fill: none;
    stroke: #f2f2f2; /* Color for the track */
    stroke-width: 8;
  `;

  const Progress = styled.circle`
    fill: none;
    stroke: #3b86fb; /* Color for the progress */
    stroke-width: 8;
    stroke-linecap: round;
    stroke-dasharray: 314; /* Circumference of a circle with radius 50 */
    stroke-dashoffset: ${props => 314 - (props.progress / 100) * 314};
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    transition: stroke-dashoffset 0.5s ease;
  `;

  const Text = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: bold;
    color: #1f1f1f;
  `;


  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [progress, setProgress] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [invalidRowCount, setInvalidRowCount] = useState(0);
  const [invalidRows, setInvalidRows] = useState([]);
  const [validRowsData, setValidRowsData] = useState([]);

  useEffect(() => {
    console.log('hit 1');
    if (bulkCustomerCreated) {
      console.log('hit 2');
      handleCancel();
      toggle();
    }
  }, [bulkCustomerCreated]);

  const handleFileSelect = () => {
    // Trigger the click event of the hidden file input
    setErrorMessage('');
    fileInputRef.current.click();
  };

  //template CSV
  const handleDownloadTemplate = () => {
    // Define the CSV content with headers and data
    const csvContent = 'name,email,phone_number\n';

    // Create a Blob from the data
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a download link
    const url = URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = url;
    link.download = 'customer_template.csv'; // Set the desired file name

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger the click event to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);

    // Revoke the Blob URL to free up resources
    URL.revokeObjectURL(url);
  };

  const handleFileChange = event => {
    setErrorMessage(''); // Clear error message when parsing a new file
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      // No file selected
      return;
    }

    const fileName = selectedFile.name;
    const extension = fileName.split('.').pop().toLowerCase();
    const fileSizeInMB = selectedFile.size / (1024 * 1024); // Convert bytes to MB
    setFileName(fileName);

    // Check if the file extension is allowed
    if (extension !== 'csv' && extension !== 'xlsx') {
      setErrorMessage('Invalid file format. Please select a CSV or XLSX file.');
      return;
    }

    if (fileSizeInMB > 2) {
      setErrorMessage('File size must be less than 2MB');
      return;
    }

    let uniqueEmails = new Set(); // To track unique emails
    let newInvalidRows = []; // To store invalid rows

    // Use Papa Parse to parse the file
    Papa.parse(selectedFile, {
      header: false, // Since we're not using header names directly
      skipEmptyLines: true,
      delimiter: '\t', // Specify tab as the delimiter
      beforeFirstChunk: function (chunk) {
        // Here, we'll process each chunk of data before parsing
        // We'll remove rows with missing or invalid data and ensure email uniqueness

        // Split the chunk into individual rows
        const rows = chunk.split('\n');
        const validRows = [];
        const uniqueEmails = new Set();
        validRows.push(rows[0]);

        // Process each row
        for (let i = 1; i < rows.length; i++) {
          const row = rows[i];

          // Parse the row data as comma-separated values
          const rowDataArray = row.split(',');

          // Extract name and email from the rowDataArray
          const name = rowDataArray[0] ? rowDataArray[0].trim() : ''; // First value before the first comma
          const email = rowDataArray[1] ? rowDataArray[1].trim() : ''; // Second value after the first comma, if exists
          const phone_number = rowDataArray[2] ? rowDataArray[2].trim() : '';
          const normalizedEmail = email.toLowerCase();

          // Check if all required fields are present and valid
          if (
            name &&
            validateEmail(normalizedEmail) &&
            !uniqueEmails.has(normalizedEmail)
          ) {
            uniqueEmails.add(normalizedEmail);
            validRows.push(row);
          } else {
            newInvalidRows.push(`${name},${email},${phone_number},${i + 1}`);
          }
        }
        setProgress(50); // Set progress to 50% after parsing
        const newRowCount = validRows.length - 1; // Subtract 1 for header row
        if (newRowCount === 0) {
          setErrorMessage(
            'No valid customer rows found. Please follow template.',
          );
          setRowCount(0);
          return;
        } else {
          setErrorMessage('');
          setInvalidRows(newInvalidRows); // Update invalidRows state
          setInvalidRowCount(newInvalidRows.length - 1);
          setRowCount(newRowCount); // Update rowCount state
          // Join the valid rows back into a single chunk of data
          return validRows.join('\n');
        }
      },
      complete: function (results) {
        // At this point, all unwanted rows have been removed
        console.log('Parsed file:', results.data);
        setValidRowsData(results.data);
        setProgress(100); // Set progress to 100% after parsing
        // Further processing if needed
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      },
      error: function (error) {
        console.error('Parsing error:', error);
        setErrorMessage('Error parsing file.');
        setProgress(0); // Reset progress on error
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      },
    });
  };

  // Drag and Drop Feature

  const handleDragOver = e => {
    e.preventDefault();
  };
  const handleDrop = e => {
    e.preventDefault();
    setErrorMessage('');
    const droppedFiles = e.dataTransfer.files;
    // Create a synthetic event to mimic the behavior of the file input event
    const syntheticEvent = { target: { files: droppedFiles } };

    handleFileChange(syntheticEvent);
  };

  const handleDownloadInvalidRows = () => {
    // Define the CSV content with headers and data
    let csvContent = 'name,email,phone_number,row_index\n';

    // Append each invalid row to the CSV content, excluding the last row
    // invalidRows.slice(0, invalidRows.length - 1).forEach(row => {
    //   csvContent += `${row}\n`;
    // });

    invalidRows.forEach(row => {
      csvContent += `${row}\n`;
    });

    // Create a Blob from the data
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a download link
    const url = URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = url;
    link.download = 'invalid_rows.csv'; // Set the desired file name

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger the click event to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);

    // Revoke the Blob URL to free up resources
    URL.revokeObjectURL(url);
  };

  const handleValidRows = () => {
    // Define the CSV content with headers and data
    let csvContent = '';

    validRowsData.forEach(row => {
      csvContent += `${row}\n`;
    });

    // Create a Blob from the data
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a File object with the Blob and specify the filename
    const file = new File([blob], 'valid_rows.csv', { type: 'text/csv' });

    // If selectedOrganization and selectedOrganization.id exist, create the payload
    if (selectedOrganization && selectedOrganization.id && file) {
      let payload = {
        organization_id: selectedOrganization.id,
        file: file, // Sending the File directly as part of the payload
      };

      createCustomerBulk(payload); // Assuming createCustomerBulk is a saga action
    } else {
      console.error('Missing organization ID or file data');
      // Handle missing organization ID or file data
    }
  };

  const handleCancel = () => {
    // Clear the file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    // Reset the error message and progress
    setFileName('');
    setInvalidRowCount(0);
    setRowCount(0);
    setInvalidRows(0);
    setErrorMessage('');
    setProgress(0);
    setValidRowsData([]);
    // Close the modal
    toggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ marginTop: `25vh`, maxWidth: '650px' }}
    >
      <ModalBody
        onDragOver={handleDragOver}
        onDragEnter={handleDragOver}
        onDragLeave={handleDragOver}
        onDrop={handleDrop}
        style={{ padding: `20px` }}
      >
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <button className="close" onClick={toggle}>
          &times;
        </button>
        <CommonText
          fontSize="16px"
          fontWeight="600"
          style={{ textAlign: `center` }}
          margin="20px 0 0 0"
        >
          Upload a CSV or XLSX file to import customer profiles
        </CommonText>
        {errorMessage && (
          <ColoredText
            type="error"
            style={{ textAlign: `center`, marginTop: '10px' }}
          >
            {errorMessage}
          </ColoredText>
        )}
        <CommonGrid columns="auto auto" alignItem="center">
          <CommonImage
            src={FileGroup}
            alt="FileGroup Icon"
            size="240px"
            margin="0 0 0 25px"
            style={{
              alignSelf: 'center',
            }}
          />
          {progress > 0 && rowCount > 0 ? (
            <div style={{ marginTop: '12px', marginRight: '20px' }}>
              {/* <CircularProgress>
                <Circle>
                  <Track cx="50" cy="50" r="45" />
                  <Progress progress={progress} cx="50" cy="50" r="45" />
                </Circle>
                <Text>{progress}%</Text>
              </CircularProgress> */}
              <CommonFlex gap="15px">
                <CommonImage
                  src={CSVFileName}
                  alt="CSVFileName"
                  size="18px"
                  style={{
                    alignSelf: 'center',
                  }}
                />
                <CommonText>File Name: {fileName}</CommonText>
              </CommonFlex>
              {invalidRowCount && invalidRowCount > 0 ? (
                <CommonFlex margin="5px 0 0 0" gap="15px">
                  <CommonImage
                    src={CSVInvalidRow}
                    alt="CSVInvalidRow"
                    size="18px"
                    style={{
                      alignSelf: 'center',
                    }}
                  />
                  <CommonText>
                    {invalidRowCount} out of {invalidRowCount + rowCount} rows
                    found invalid
                  </CommonText>
                </CommonFlex>
              ) : null}
              <CommonFlex gap="15px" margin="5px 0 0 0">
                <CommonImage
                  src={CSVValidRows}
                  alt="CSVValidRows"
                  size="18px"
                  style={{
                    alignSelf: 'center',
                  }}
                />
                <CommonText>{`${rowCount} ${
                  rowCount === 1 ? 'customer' : 'customers'
                } will be added`}</CommonText>
              </CommonFlex>

              {invalidRowCount && invalidRowCount > 0 ? (
                <CommonFlex gap="15px" margin="20px 0 0 0">
                  <CommonImage
                    src={CSVDownload}
                    alt="CSVDownload"
                    size="18px"
                    style={{
                      alignSelf: 'center',
                    }}
                  />
                  <CommonText
                    onClick={handleDownloadInvalidRows}
                    fontWeight="560"
                    style={{ color: '#ff8033', cursor: 'pointer' }}
                    // margin="5px 0 0 0"
                  >
                    Download Invalid Rows
                  </CommonText>
                </CommonFlex>
              ) : null}

              <CommonFlex gap="15px" margin="20px 0 0 0">
                <WhiteButton onClick={handleCancel}>Cancel</WhiteButton>
                <ColoredButton onClick={handleValidRows}>
                  {isLoading ? (
                    <ButtonTextLoader loadingText="Uploading" fontSize="13px" />
                  ) : (
                    'Proceed'
                  )}
                </ColoredButton>
              </CommonFlex>
            </div>
          ) : (
            <RoundContainer>
              <PlusIcon
                style={{ cursor: 'pointer' }}
                onClick={handleFileSelect}
                src={Plus}
                alt="Plus"
              />
              <CommonText
                style={{ cursor: 'pointer' }}
                fontWeight="650"
                title
                margin="15px 0 0 0"
                onClick={handleFileSelect}
              >
                Select or drag file
              </CommonText>
              <CommonText
                onClick={handleDownloadTemplate}
                fontWeight="580"
                style={{ color: '#ff8033', cursor: 'pointer' }}
                margin="5px 0 0 0"
              >
                Download template
              </CommonText>
            </RoundContainer>
          )}
        </CommonGrid>
      </ModalBody>
    </Modal>
  );
};

export default ImportCustomerModal;
