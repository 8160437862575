import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import styled from 'styled-components';

import invitation_sent from '../../assets/img/invitation_sent.svg';
import ButtonTextLoader from '../../components/Loaders/ButtonTextLoader';
import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import {
  ColoredButton,
  PrimaryButton,
  SecondaryButton,
  WhiteButton,
} from '../../styledComponents/buttons';
import { ClientLocationsTableRowContainer } from '../../styledComponents/clients';
import {
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonImage,
  CommonText,
  InputField,
} from '../../styledComponents/common';
import { ModalButtonSection } from '../../styledComponents/members';
import { colors } from '../../styles/light/colors';
import { CommFirstRoundLetterComp } from '../Teams/CommonComponents';

const GHLSyncModal = ({
  selectedOrganization,
  isOpen,
  toggle,
  memberShortListIsLoading,
  ghlSyncMemberIsLoading,
  filteredGHLEmployee,
  inviteMemberBulk,
  memberInvited,
  inviteMemberLoading,
  clearMemberInvited,
}) => {
  const { t } = useTranslation();
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [sendInvitationClicked, setSendInvitationClicked] = useState(false);

  const handleSearchChange = event => {
    setSearchInput(event.target.value);
  };

  useEffect(() => {
    if (memberInvited && sendInvitationClicked) {
      setSelectedEmployees([]);
      setSearchInput('');
      setTimeout(() => {
        toggle();
        setSendInvitationClicked(false);
        clearMemberInvited();
      }, 3000);
    }
  }, [memberInvited]);

  const sendInvitation = () => {
    if (selectedOrganization && selectedOrganization.id) {
      setSendInvitationClicked(true);
      let csvContent = '';
      selectedEmployees.forEach(row => {
        csvContent += `${row}\n`;
      });
      // Create a Blob from the data
      const blob = new Blob([csvContent], { type: 'text/csv' });
      // Create a File object with the Blob and specify the filename
      const file = new File([blob], 'valid_rows.csv', { type: 'text/csv' });

      let payload = {
        organization_id: selectedOrganization.id,
        file: file, // Sending the File directly as part of the payload
      };
      inviteMemberBulk(payload);
    }
  };

  const handleCheckboxChange = email => {
    if (selectedEmployees.includes(email)) {
      setSelectedEmployees(selectedEmployees.filter(emp => emp !== email));
    } else {
      setSelectedEmployees([...selectedEmployees, email]);
    }
  };
  const handleSelectAll = () => {
    const allEmails = filteredGHLEmployee.map(employee => employee.email);
    setSelectedEmployees(allEmails);
  };

  const EmployeeCheckbox = ({ name, email, checked, onChange, index }) => {
    return (
      <CommonGrid
        columns="0.15fr 1.8fr 2fr"
        style={{
          marginTop: '11px',
          backgroundColor: checked ? '#f8f9fc' : '',
        }}
      >
        <input
          style={{ transform: 'scale(0.7)' }}
          type="checkbox"
          checked={checked}
          onChange={onChange}
        />

        <CommFirstRoundLetterComp
          text={name}
          backColor={index}
          underline={false}
        />

        <CommonText>{email}</CommonText>
      </CommonGrid>
    );
  };

  // Filter the employee list based on the search input
  const filteredEmployeeList = filteredGHLEmployee.filter(
    employee =>
      employee.email.toLowerCase().includes(searchInput.toLowerCase()) ||
      employee.name.toLowerCase().includes(searchInput.toLowerCase()),
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ marginTop: `25vh`, maxWidth: '600px' }}
    >
      {memberShortListIsLoading || ghlSyncMemberIsLoading ? (
        <ComponentCircleLoader />
      ) : (
        <ModalBody style={{ padding: `20px` }}>
          {filteredGHLEmployee && filteredGHLEmployee.length > 0 ? (
            !memberInvited ? (
              <>
                <CommonText style={{ paddingLeft: `15px` }}>
                  Invite Team Members
                </CommonText>
                <div
                  style={{ position: 'relative', margin: '15px 0 10px 15px' }}
                >
                  <InputField
                    type="text"
                    placeholder="Search team members by name or email"
                    value={searchInput}
                    onChange={handleSearchChange}
                    style={{
                      paddingRight: '30px',
                      width: '100%',
                      height: '35px',
                      borderRadius: '7px',
                      border: '1px solid #ccc',
                      paddingLeft: '10px',
                    }} // Add paddingRight to accommodate the clear button
                  />
                  {searchInput && (
                    <button
                      onClick={() => setSearchInput('')} // Clear the search input when the button is clicked
                      style={{
                        position: 'absolute',
                        right: '5px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                    >
                      &#x2715;{' '}
                      {/* Unicode representation of the "x" character */}
                    </button>
                  )}
                </div>

                <div
                  style={{
                    maxHeight: '350px',
                    overflowY: 'auto',
                    padding: `10px 0 0 15px`,
                  }}
                >
                  {filteredEmployeeList.map((employee, index) => (
                    <EmployeeCheckbox
                      key={employee.email}
                      index={index}
                      name={employee.name}
                      email={employee.email}
                      checked={selectedEmployees.includes(employee.email)}
                      onChange={() => handleCheckboxChange(employee.email)}
                    />
                  ))}
                </div>

                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <WhiteButton
                    color={colors.blue}
                    style={{
                      borderColor: '#c6d7f0',
                      margin: '20px 0 0 0',
                    }}
                    fontWeight="601"
                    onMouseOver={e => (e.target.style.borderColor = colors.blue)}
                    onMouseOut={e => (e.target.style.borderColor = colors.grayMedium)}
                    onClick={handleSelectAll}
                  >
                    Select All
                  </WhiteButton>
                  <span>
                    <CommonFlex justifyContent="flex-end" margin="20px 0 0 0">
                      <CommonText>
                        {selectedEmployees.length} Selected
                      </CommonText>
                      <PrimaryButton
                        type="blue"
                        padding={inviteMemberLoading ? '0 15px' : '0 30px'}
                        onClick={() => sendInvitation()}
                        disabled={selectedEmployees.length === 0 ? true : false}
                      >
                        {inviteMemberLoading ? (
                          <ButtonTextLoader
                            loadingText="Sending Invitation"
                            fontSize="13px"
                          />
                        ) : (
                          'Send Invitation'
                        )}
                      </PrimaryButton>
                    </CommonFlex>
                  </span>
                </div>
                <CommonText
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'underline',

                    paddingLeft: '15px',
                  }}
                  color={colors.blueMedium}
                  onClick={toggle}
                  margin="15px 0 0 0"
                >
                  I will send invitation later
                </CommonText>
              </>
            ) : (
              <>
                <CommonImage
                  src={invitation_sent}
                  style={{
                    marginLeft: '200px',
                    marginTop: '100px',
                  }}
                />
                <CommonText
                  fontWeight="605"
                  fontSize="15px"
                  style={{
                    marginLeft: '170px',
                    marginTop: '20px',
                    marginBottom: '150px',
                  }}
                >
                  Invitation sent successfully!
                </CommonText>
              </>
            )
          ) : (
            <CommonText>There is no employee</CommonText>
          )}
        </ModalBody>
      )}
    </Modal>
  );
};

export default GHLSyncModal;
