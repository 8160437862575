import {
  CREATE_SHIFT,
  DELETE_SHIFT,
  EDIT_SHIFT,
  EDIT_SHIFT_ASSIGNEES_ADD,
  EDIT_SHIFT_ASSIGNEES_REMOVE,
  GET_OFFICE_SHIFTS_LIST,
  GET_JOB_SHIFTS_LIST,
  GET_SHIFT_DETAILS,
} from '../../modules/constants';

export function getOfficeShiftsList(value) {
  return {
    type: GET_OFFICE_SHIFTS_LIST,
    payload: value,
  };
}

export function getJobShiftsList(value) {
  return {
    type: GET_JOB_SHIFTS_LIST,
    payload: value,
  };
}

export function getShiftDetails(value) {
  return {
    type: GET_SHIFT_DETAILS,
    payload: value,
  };
}

export function createShift(value) {
  return {
    type: CREATE_SHIFT,
    payload: value,
  };
}

export function editShift(value) {
  return {
    type: EDIT_SHIFT,
    payload: value,
  };
}

export function editShiftAssigneesAdd(value) {
  return {
    type: EDIT_SHIFT_ASSIGNEES_ADD,
    payload: value,
  };
}

export function editShiftAssigneesRemove(value) {
  return {
    type: EDIT_SHIFT_ASSIGNEES_REMOVE,
    payload: value,
  };
}

export function deleteShifts(value) {
  return {
    type: DELETE_SHIFT,
    payload: value,
  };
}
