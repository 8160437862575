import { connect } from "react-redux";
import ShiftSchedule from "./ShiftSchedule";

//Actions
import {
  getOfficeShiftsList,
  getJobShiftsList,
  deleteShifts,
} from "./shiftScheduleActions";
import { deleteWorkOrderJob } from "../WorkOrderNew/workOrderActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  officeShiftsList: state.schedule.officeShiftsList,
  jobShiftsList: state.schedule.jobShiftsList,
  deleteCallback: state.schedule.deleteCallback,
  editCallback: state.schedule.editCallback,
  createdShiftInfo: state.schedule.createdShiftInfo,
  jobShortList: state.jobs.jobShortList,
  deleteJobCallback: state.workorder.deleteJobCallback,
  
  isLoading: state.schedule.isLoading,
  createIsLoading: state.schedule.createIsLoading,
  deleteIsLoading: state.schedule.deleteIsLoading,
  workOrderJobDeleteIsLoading: state.workorder.workOrderJobDeleteIsLoading,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getOfficeShiftsList: (details) => dispatch(getOfficeShiftsList({ ...details })),
  getJobShiftsList: (details) => dispatch(getJobShiftsList({ ...details })),
  deleteShifts: (details) => dispatch(deleteShifts({ ...details })),
  deleteWorkOrderJob: (details) => dispatch(deleteWorkOrderJob({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(ShiftSchedule);