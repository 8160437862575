import { useLoadScript } from '@react-google-maps/api';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Plus from '../../assets/img/icons/plus_white.svg';
import crossWhite from '../../assets/img/icons/remove_assignee.svg';
import BackButtonComponent from '../../components/BackButtonComponent/BackButtonComponent';
import AddSingleMemberDropdown from '../../components/CustomDropdown/AddSingleMemberDropdown';
import { CardText } from '../../components/DashboardCards/cardStyles';
import CreateProjectDropdown from '../../components/DropdownStyle/CreateProjectDropdown';
import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import BottomPagination from '../../components/Pagination/BottomPagination/BottomPagination';
import TopPagination from '../../components/Pagination/TopPagination/TopPagination';
import Select from '../../components/ReactSelectDropdown';
import SearchWithButton from '../../components/Search/SearchWithButton';
import { ClearSearchButton } from '../../components/Search/searchStyles';
import DatePicker from '../../components/SingleDatePicker/SingleDatePicker';
import useAutocompleteService from '../../customHooks/usePlacesAutocompleteService';
import { ListUpperSection } from '../../styledComponents/ProjectsNew';
import {
  ButtonIcon,
  ColoredButton,
  ColoredButtonWithIcon,
  PrimaryButton,
  RadiusButton,
  WhiteButton,
} from '../../styledComponents/buttons';
import {
  AssignListText,
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonImage,
  CommonText,
  Container,
  ContentHeader,
  FilterLabel,
  FormAlert,
  HeaderContainer,
  InputField,
  InputLabel,
  InputWithLabelSection,
  PageTitle,
  RedStar,
} from '../../styledComponents/common';
import { TabLeftGrid } from '../../styledComponents/offices';
import { checkHtml, checkUrl } from '../../utils/helper';
import { DataAndMapContainer } from '../FieldServices/JobSites/jobSitesStyles';
import { MapAndAlertContainer } from '../FieldServices/RouteMap/routeMapStyles';
import { CommImageNameComp } from '../Members/CommonComponents';
import TimeRangeComponent from '../Timesheet/Daily/TimeRangeComponent';
import AddOffice from './AddOffice';
import DeleteOfficeModal from './DeleteOfficeModal';
import OfficeList from './OfficesList';
import OfficesMap from './OfficesMap';
import {
  InfoMapSection,
  InfoSection,
  Tabsection,
  TitleTag,
} from './officesStyles';

const Offices = props => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState('list');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  //search
  const [searchTerm, setSearchTerm] = useState('');
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState('');
  // delete
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteOfficeId, setDeleteOfficeId] = useState(null);

  const [selectedOffice, setSelectedOffice] = useState(null);

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const {
    isLoading,
    getOfficesList,
    officesList,
    history,
    clearOfficeCreatedUpdated,
  } = props;

  useEffect(() => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        page: 1,
      };
      getOfficesList(payload);
    }
  }, [props.selectedOrganization]);

  const handleSearchByName = () => {
    const payload = {
      page: 1,
    };

    if (searchTerm && searchTerm.trim()) {
      payload.name = searchTerm.toLowerCase().trim();
      setCurrentSearchedTerm(searchTerm);
    } else {
      setCurrentSearchedTerm('');
    }
    if (props.selectedOrganization && props.selectedOrganization.id) {
      payload.organization_id = props.selectedOrganization.id;
      getOfficesList(payload);
    }

    setCurrentPageNumber(1);
  };

  const handleClearSearch = () => {
    if (currentSearchedTerm) {
      const payload = {
        page: 1,
      };

      if (props.selectedOrganization && props.selectedOrganization.id) {
        payload.organization_id = props.selectedOrganization.id;
        getOfficesList(payload);
      }

      setCurrentPageNumber(1);
    }
    setTimeout(() => {
      setSearchTerm('');
      setCurrentSearchedTerm('');
    }, 100);
  };

  const onSearchTermChange = e => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const toggle = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const handleOfficeDelete = officeId => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        office_id: officeId,
      };
      props.deleteOffice(payload);
      setIsDeleteModalOpen(!isDeleteModalOpen);
    }
  };

  const handlePageChange = page => {
    // console.log(page);
    if (props.selectedOrganization && props.selectedOrganization.id) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        page: page,
      };
      getOfficesList(payload);
    }

    setCurrentPageNumber(page);
  };

  return (
    <div className="content">
      <PageTitle>{t('offices')}</PageTitle>
      <HeaderContainer>
        <TabLeftGrid>
          <SearchWithButton
            itemName={t('office')}
            searchTermName={t('office_name')}
            searchInput={searchTerm}
            onInputChange={onSearchTermChange}
            handleSearch={handleSearchByName}
            handleClearSearch={handleClearSearch}
          />
        </TabLeftGrid>
        {props.selectedOrganization &&
          (props.selectedOrganization.role === 'admin' ||
            props.selectedOrganization.role === 'owner') && (
            <ColoredButtonWithIcon
              onClick={() => props.history.push('/user/add-office')}
            >
              <ButtonIcon src={Plus} alt="" />
              {t('create_office')}
            </ColoredButtonWithIcon>
          )}
      </HeaderContainer>

      {currentPage === 'details' && (
        <ContentHeader>
          <BackButtonComponent
            onClick={() => handleChangePage('list')}
            subTitle={t('back_to_all_offices')}
          />
        </ContentHeader>
      )}

      {props.totalOfficesCount &&
      props.totalOfficesCount > 0 &&
      props.pageSize ? (
        <TopPagination
          itemName={t('offices')}
          totalCount={props.totalOfficesCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={props.pageSize}
        />
      ) : null}

      <OfficeList
        searchTerm={searchTerm}
        officeList={props.officesList}
        history={props.history}
        modalToggle={toggle}
        isModalOpen={isDeleteModalOpen}
        handleDelete={handleOfficeDelete}
        isLoading={props.isLoading}
        clientUpdateLoading={false}
        updatingClientId={null}
        clientDeleteLoading={false}
        deletingClientId={null}
        PageNumber={currentPageNumber}
        selectedOrganization={props.selectedOrganization}
      />

      {props.totalOfficesCount &&
      props.totalOfficesCount > 0 &&
      props.pageSize ? (
        <BottomPagination
          totalCount={props.totalOfficesCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={props.pageSize}
        />
      ) : null}
    </div>
  );
};

export default Offices;
