import { Fragment, useState, useEffect } from "react";
import CreateProjectDropdown from "../../../components/DropdownStyle/CreateProjectDropdown";
import Select from "../../../components/ReactSelectDropdown";
import moment from "moment";
import DatePicker from "../../../components/SingleDatePicker/SingleDatePicker";
import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import AddClientPopup from "../AddClientPopup";
import SendMailPopup from "../SendMailPopup";
import queryString from "query-string";

import ProjectDropdown from "../../../components/CommonFilter/ProjectDropdown";

import {
  GeneralDetailsInputContainer,
  FormAlert,
  Cross,
  CrossIcon,
  BillingQues,
  BillingTooltip,
  BillingTooltipText,
} from "../../../styledComponents/createProject";
import {
  PageTitle,
  Container,
  CardTitle,
  CommonText,
  TableText,
  InputWithLabelSection,
  InputLabel,
  InputField,
  RedStar,
  CommonGrid,
  ColoredText,
} from "../../../styledComponents/common";
import {
  WhiteButton,
  ColoredButton,
  PrimaryButton,
} from "../../../styledComponents/buttons";
import {
  CreateInvoiceSubTitle,
  CreateInvoiceItemContainer,
  ToggleButton,
  ToggleButtonRound,
  AddItemContainer,
  AddItem,
  CalculationContainer,
  TaxTypeButton,
  ReorderSelectSection,
  SubmitButtonContainer,
  SaveCrossSection,
  SaveSection,
} from "../../../styledComponents/invoice";

import {
  currenciesWithSymbols,
  currencySymbolMap,
} from "../../../utils/currencies";

import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import ButtonTextLoader from "../../../components/Loaders/ButtonTextLoader";
import DotLoader from "../../../components/DropdownLoader/DropdownLoader";

import Plus from "../../../assets/img/icons/plus.svg";
import crossIcon from "../../../assets/img/icons/cross_black.svg";
import reorder from "../../../assets/img/reorder.svg";

const EditInvoice = (props) => {
  const [changedInvoiceInfo, setChangedInvoiceInfo] = useState(false);

  const [selectClient, setSelectClient] = useState("");
  const [clientOptions, setClientOptions] = useState([]);

  const [invoiceNumber, setInvoiceNumber] = useState("");

  const [issuedDate, setIssuedDate] = useState(moment(new Date()));

  const [dueDate, setDueDate] = useState(null);

  const [poValue, setPoValue] = useState("");

  const [currency, setCurrency] = useState({
    label: "USD ($) - United States Dollar",
    value: "USD",
  });

  const [itemArray, setItemArray] = useState([]);

  const [itemIndex, setItemIndex] = useState(-1);

  const [tax1, setTax1] = useState({
    percent: true,
    name: "",
    value: 0,
    isChanged: false,
  });
  const [tax2, setTax2] = useState({
    percent: true,
    name: "",
    value: 0,
    isChanged: false,
  });
  const [discount, setDiscount] = useState({
    percent: true,
    value: 0,
    isChanged: false,
  });

  const itemTypeOptions = [
    { value: "service", label: "Service" },
    { value: "product", label: "Product" },
  ];

  const [totalCalc, setTotalCalc] = useState(0);
  const [grandTotalValue, setGrandTotalValue] = useState(0);

  const [saveErrors, setSaveErrors] = useState({});

  const [popupOpen, setPopupOpen] = useState(false);
  const [sendEmailOpen, setSendEmailOpen] = useState(false);
  const [toolTipActive, setToolTipActive] = useState(-1);
  const [sentState, setSentState] = useState(false);
  const [linkedItemIndex, setLinkedItemIndex] = useState(null);
  const [createdItemindex, setCreatedItemindex] = useState(-1);

  // for tax loader
  const [loadingTaxId, setLoadingTaxId] = useState(null);

  useEffect(() => {
    return () => {
      props.clearInvoiceLinkedProject();
    };
  }, []);

  useEffect(() => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      let payload = {
        organization_id: props.selectedOrganization.id,
      };
      props.getClientList(payload);

      if (props.location.search) {
        const queryParsed = queryString.parse(props.location.search);
        payload.invoice_id = queryParsed.id;
        props.getInvoiceDetails(payload);
        props.getInvoiceHistory(payload);
      }
    }
  }, [props.selectedOrganization]);

  useEffect(() => {
    const { invoiceDetails } = props;
    if (invoiceDetails && invoiceDetails.id) {
      if (invoiceDetails.client && invoiceDetails.client.id) {
        setSelectClient({
          value: invoiceDetails.client.id,
          label: invoiceDetails.client.name,
        });
      }

      setInvoiceNumber(invoiceDetails.invoice_number);
      if (invoiceDetails.po_number !== null) {
        setPoValue(invoiceDetails.po_number);
      }
      setIssuedDate(moment(invoiceDetails.issued_date));
      if (invoiceDetails.due_date !== null) {
        setDueDate(moment(invoiceDetails.due_date));
      }
      if (invoiceDetails.currency) {
        setCurrency({
          label: `${invoiceDetails.currency} (${
            currencySymbolMap[invoiceDetails.currency]
          })`,
          value: invoiceDetails.currency,
        });
      }

      if (invoiceDetails.tax1) {
        setTax1({
          percent: invoiceDetails.tax1.type === "percent" ? true : false,
          name:
            invoiceDetails.tax1.name === null ? "" : invoiceDetails.tax1.name,
          value:
            invoiceDetails.tax1.type === "percent"
              ? invoiceDetails.tax1.percent_amount
              : invoiceDetails.tax1.fixed_amount,
        });
      }
      if (invoiceDetails.tax2) {
        setTax2({
          percent: invoiceDetails.tax2.type === "percent" ? true : false,
          name:
            invoiceDetails.tax2.name === null ? "" : invoiceDetails.tax2.name,
          value:
            invoiceDetails.tax2.type === "percent"
              ? invoiceDetails.tax2.percent_amount
              : invoiceDetails.tax2.fixed_amount,
        });
      }
      if (invoiceDetails.discount) {
        setDiscount({
          percent: invoiceDetails.discount.type === "percent" ? true : false,
          value:
            invoiceDetails.discount.type === "percent"
              ? invoiceDetails.discount.percent_amount
              : invoiceDetails.discount.fixed_amount,
        });
      }
      let newItems = [];
      invoiceDetails.items.map((item, index) => {
        let newItem = {};
        newItem.id = item.id;
        newItem.type = {
          value: item.type,
          label: item.type === "service" ? "Service" : "Product",
        };
        item.description !== null
          ? (newItem.description = item.description)
          : (newItem.description = "");
        newItem.quantity = item.quantity;
        newItem.unitPrice = item.unit_price;
        newItem.tax1 = item.tax1;
        newItem.tax2 = item.tax2;
        // item.linked_project !== null
        //   ? (newItem.linkedProject = {
        //       value: item.linked_project.id,
        //       label: item.linked_project.name,
        //     })
        //   : (newItem.linkedProject = "");
        newItems.push(newItem);
      });
      itemArray &&
        itemArray.length > 0 &&
        itemArray.map((item, index) => {
          if (!item.id && createdItemindex !== index) {
            newItems.push(item);
          }
        });
      setItemArray(newItems);
    }
  }, [props.invoiceDetails]);

  useEffect(() => {
    if (props.clientList && props.clientList.length > 0) {
      let options = [];
      props.clientList.map((item) => {
        if (props.createdClientId && props.createdClientId === item.id) {
          setSelectClient({
            value: props.createdClientId,
            label: item.name,
          });
        }
        if (item.is_active) {
          options.push({
            value: item.id,
            label: item.name,
          });
        }
      });
      setClientOptions(options);
    }
  }, [props.clientList]);

  useEffect(() => {
    totalCalculation();
  }, [itemArray]);

  useEffect(() => {
    grandTotalCalculation();
  }, [totalCalc, tax1, tax2, discount]);

  useEffect(() => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      let payload = {
        organization_id: props.selectedOrganization.id,
      };
      props.getClientList(payload);
    }
  }, [props.createdClientId]);

  useEffect(() => {
    if (props.callback) {
      setChangedInvoiceInfo(false);
    }
  }, [props.callback]);

  useEffect(() => {
    if (
      props.updatedInvoiceItemInfo !== null &&
      itemArray &&
      itemArray.length > 0 &&
      itemIndex >= 0
    ) {
      let newArray = [...itemArray];
      newArray[itemIndex].isChanged = false;
      setItemArray(newArray);
    }
  }, [props.updatedInvoiceItemInfo]);

  useEffect(() => {
    if (
      props.deletedInvoiceItem &&
      itemArray &&
      itemArray.length > 0 &&
      itemIndex !== -1
    ) {
      let newArray = [...itemArray];
      newArray.splice(itemIndex, 1);
      setItemArray(newArray);
    }
  }, [props.deletedInvoiceItem]);

  useEffect(() => {
    if (props.updatedInvoiceTax && props.updatedInvoiceTax.id) {
      if (props.invoiceDetails.tax1.id === props.updatedInvoiceTax.id) {
        setTax1({ ...tax1, isChanged: false });
      } else if (props.invoiceDetails.tax2.id === props.updatedInvoiceTax.id) {
        setTax2({ ...tax2, isChanged: false });
      }
    }
    setLoadingTaxId(null);
  }, [props.updatedInvoiceTax]);

  useEffect(() => {
    if (props.updatedInvoiceDiscount !== null && discount) {
      setDiscount({ ...discount, isChanged: false });
    }
  }, [props.updatedInvoiceDiscount]);

  useEffect(() => {
    if (
      props.sendInvoiceEmailInfo &&
      props.sendInvoiceEmailInfo.log_id &&
      sentState
    ) {
      props.isMailSendFromEdit({ data: true });
      props.history.push(`/user/invoice-preview?id=${props.invoiceDetails.id}`);
    }
  }, [props.sendInvoiceEmailInfo]);

  useEffect(() => {
    let newArr = [...itemArray];
    if (
      props.linkedProjectState &&
      linkedItemIndex !== null &&
      props.linkedProjectState.linked_project_id ==
        itemArray[linkedItemIndex].linkedProject.value
    ) {
      newArr[linkedItemIndex].unitPrice = parseFloat(
        props.linkedProjectState.unit_price
      ).toFixed(2);
      setItemArray(newArr);
    } else if (!props.linkedProjectState && linkedItemIndex !== null) {
      newArr[linkedItemIndex].unitPrice = 0;
      setItemArray(newArr);
    }
  }, [props.linkedProjectState, linkedItemIndex]);

  const onClientChange = (e) => {
    let errors = { ...saveErrors };
    delete errors["client"];
    setSaveErrors(errors);

    setSelectClient(e);
    if (!changedInvoiceInfo) {
      setChangedInvoiceInfo(true);
    }
  };

  const onInvoiceNumberChange = (e) => {
    setInvoiceNumber(e.target.value);
    if (!changedInvoiceInfo) {
      setChangedInvoiceInfo(true);
    }
  };

  const onIssuedDateChange = (date) => {
    setIssuedDate(date);
    if (!changedInvoiceInfo) {
      setChangedInvoiceInfo(true);
    }
  };

  const onDueDateChange = (date) => {
    setDueDate(date);
    if (!changedInvoiceInfo) {
      setChangedInvoiceInfo(true);
    }
  };

  const onPoValueChange = (e) => {
    setPoValue(e.target.value);
    if (!changedInvoiceInfo) {
      setChangedInvoiceInfo(true);
    }
  };

  const onCurrencyChange = (e) => {
    setCurrency(e);
  };

  const onItemTypeChange = (e, index) => {
    let newArr = [...itemArray];
    newArr[index].type = e;
    if (!newArr[index].isChanged) {
      newArr[index].isChanged = true;
    }
    setItemArray(newArr);
  };

  const onItemDescChange = (e, index) => {
    let errors = { ...saveErrors };
    delete errors[`itemDescription_${index}`];
    setSaveErrors(errors);

    let newArr = [...itemArray];
    newArr[index].description = e.target.value;
    if (!newArr[index].isChanged) {
      newArr[index].isChanged = true;
    }
    setItemArray(newArr);
  };

  const onItemQuantityChange = (e, index) => {
    let errors = { ...saveErrors };
    delete errors[`itemQuantity_${index}`];
    setSaveErrors(errors);

    let newArr = [...itemArray];
    newArr[index].quantity = e.target.value;
    if (!newArr[index].isChanged) {
      newArr[index].isChanged = true;
    }
    setItemArray(newArr);
  };

  const onItemUnitPriceChange = (e, index) => {
    let errors = { ...saveErrors };
    delete errors[`itemUnitPrice_${index}`];
    setSaveErrors(errors);

    let newArr = [...itemArray];
    newArr[index].unitPrice = e.target.value;
    if (!newArr[index].isChanged) {
      newArr[index].isChanged = true;
    }
    setItemArray(newArr);
  };

  const onItemTax1Change = (index) => {
    let newArr = [...itemArray];
    newArr[index].tax1 = !newArr[index].tax1;
    if (!newArr[index].isChanged) {
      newArr[index].isChanged = true;
    }
    setItemArray(newArr);
    grandTotalCalculation();
  };

  const onItemTax2Change = (index) => {
    let newArr = [...itemArray];
    newArr[index].tax2 = !newArr[index].tax2;
    if (!newArr[index].isChanged) {
      newArr[index].isChanged = true;
    }
    setItemArray(newArr);
    grandTotalCalculation();
  };

  const onItemLinkedProjectChange = (e, index) => {
    let newArr = [...itemArray];
    newArr[index].linkedProject = e;
    newArr[index].description = e.label;
    newArr[index].quantity = 1;
    if (!newArr[index].isChanged) {
      newArr[index].isChanged = true;
    }
    setItemArray(newArr);
    setLinkedItemIndex(index);

    if (props.selectedOrganization && props.selectedOrganization.id) {
      props.invoiceLinkProject({
        organization_id: props.selectedOrganization.id,
        linked_project_id: e.value,
      });
    }
  };

  const addItemToarray = () => {
    let newArray = [...itemArray];
    let newItem = {
      type: { value: "service", label: "Service" },
      description: "",
      quantity: 0,
      unitPrice: 0,
      tax1: true,
      tax2: true,
      linkedProject: "",
      isChanged: false,
    };
    newArray.push(newItem);
    setItemArray(newArray);
  };

  const handleDeleteItem = (index) => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      props.invoiceDetails.id &&
      itemArray[index].id
    ) {
      const payload = {
        organization_id: props.selectedOrganization.id,
        invoice_id: props.invoiceDetails.id,
        item_id: itemArray[index].id,
      };

      setItemIndex(index);
      props.deleteInvoiceItem(payload);
    } else {
      let newArray = [...itemArray];
      newArray.splice(index, 1);
      setItemArray(newArray);
    }
  };

  const onTax1NameChange = (e) => {
    const currentTax1 = { ...tax1 };
    currentTax1.name = e.target.value;
    if (!tax1.isChanged) {
      currentTax1.isChanged = true;
    }
    setTax1({ ...currentTax1 });
  };
  const onTax1ValueChange = (e) => {
    let errors = { ...saveErrors };
    delete errors[`tax1Value`];
    setSaveErrors(errors);

    const currentTax1 = { ...tax1 };
    currentTax1.value = e.target.value;
    if (!tax1.isChanged) {
      currentTax1.isChanged = true;
    }
    setTax1({ ...currentTax1 });
  };
  const toggleTax1CalcType = (value) => {
    const currentTax1 = { ...tax1 };
    currentTax1.percent = value;
    if (!tax1.isChanged) {
      currentTax1.isChanged = true;
    }
    setTax1({ ...currentTax1 });
  };

  const onTax2NameChange = (e) => {
    const currentTax2 = { ...tax2 };
    currentTax2.name = e.target.value;
    if (!tax2.isChanged) {
      currentTax2.isChanged = true;
    }
    setTax2({ ...currentTax2 });
  };
  const onTax2ValueChange = (e) => {
    let errors = { ...saveErrors };
    delete errors[`tax2Value`];
    setSaveErrors(errors);

    const currentTax2 = { ...tax2 };
    currentTax2.value = e.target.value;
    if (!tax2.isChanged) {
      currentTax2.isChanged = true;
    }
    setTax2({ ...currentTax2 });
  };
  const toggleTax2CalcType = (value) => {
    const currentTax2 = { ...tax2 };
    currentTax2.percent = value;
    if (!tax2.isChanged) {
      currentTax2.isChanged = true;
    }
    setTax2({ ...currentTax2 });
  };

  const onDiscountValueChange = (e) => {
    let errors = { ...saveErrors };
    delete errors[`discountValue`];
    delete errors[`discountFixed`];
    setSaveErrors(errors);

    const currentDiscount = { ...discount };
    currentDiscount.value = e.target.value;

    if (!discount.isChanged) {
      currentDiscount.isChanged = true;
    }

    setDiscount({ ...currentDiscount });
  };

  const toggleDiscountCalcType = (value) => {
    const currentDiscount = { ...discount };
    currentDiscount.percent = value;

    if (!discount.isChanged) {
      currentDiscount.isChanged = true;
    }

    setDiscount({ ...currentDiscount });
  };

  const totalCalculation = () => {
    if (itemArray && itemArray.length) {
      let total = 0;
      itemArray.map((item) => {
        total += item.quantity * item.unitPrice;
      });
      setTotalCalc(total.toFixed(2));
    }
  };

  const grandTotalCalculation = () => {
    const calcaulateTaxDiscount = (object, cost) => {
      if (object.percent) {
        return (object.value * cost) / 100;
      } else if (object.value) {
        return object.value;
      } else {
        return 0;
      }
    };

    if (itemArray && itemArray.length) {
      let totalTax = 0;
      itemArray.map((item, index) => {
        let itemCost = item.quantity * item.unitPrice;
        if (item.tax1) {
          totalTax += parseFloat(calcaulateTaxDiscount(tax1, itemCost));
        }
        if (item.tax2) {
          totalTax += parseFloat(calcaulateTaxDiscount(tax2, itemCost));
        }
      });

      let grandTotal =
        parseFloat(totalCalc) +
        totalTax -
        parseFloat(calcaulateTaxDiscount(discount, totalCalc));
      setGrandTotalValue(grandTotal.toFixed(2));
    }
  };

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };

  const sendEmailToggle = () => {
    setSendEmailOpen(!sendEmailOpen);
  };

  const onSend = () => {
    setSentState(true);
  };

  const HandleSendEmail = () => {
    sendEmailToggle();

    // if (
    //   props.selectedOrganization &&
    //   props.selectedOrganization.id &&
    //   props.invoiceDetails &&
    //   props.invoiceDetails.id
    // ) {
    //   const payload = {
    //     organization_id: props.selectedOrganization.id,
    //     invoice_id: props.invoiceDetails.id,
    //   };
    //   props.createInvoiceUpdateSendLog(payload);
    // }
  };

  const checkErrorInvoice = () => {
    let formIsValid = true;
    let saveErrors = {};
    if (selectClient === "") {
      saveErrors["client"] = "Client can not be empty";
      formIsValid = false;
      setChangedInvoiceInfo(false);
    }

    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const checkErrorInvoiceItem = (index) => {
    let formIsValid = true;
    let newSaveErrors = { ...saveErrors };

    if (
      itemArray[index].description &&
      itemArray[index].description.length > 512
    ) {
      newSaveErrors[`itemDescription_${index}`] =
        "Item Description can have maximum 512 characters";
      formIsValid = false;
    }
    if (
      itemArray[index].quantity !== undefined &&
      itemArray[index].quantity < 1
    ) {
      newSaveErrors[`itemQuantity_${index}`] =
        "Item Quantity can't be less than 1.";
      formIsValid = false;
    }
    if (
      itemArray[index].unitPrice !== undefined &&
      itemArray[index].unitPrice < 0
    ) {
      newSaveErrors[`itemUnitPrice_${index}`] =
        "Item unit-price can't be negative.";
      formIsValid = false;
    }

    setSaveErrors(newSaveErrors);
    return formIsValid;
  };

  const checkErrorTax1 = () => {
    let formIsValid = true;
    let newSaveErrors = { ...saveErrors };

    if (tax1 && tax1.value < 0) {
      newSaveErrors["tax1Value"] = "Tax 1 value can't be negative.";
      formIsValid = false;
      setTax1({ ...tax1, isChanged: false });
    }

    setSaveErrors(newSaveErrors);
    return formIsValid;
  };

  const checkErrorTax2 = () => {
    let formIsValid = true;
    let newSaveErrors = { ...saveErrors };

    if (tax2 && tax2.value < 0) {
      newSaveErrors["tax2Value"] = "Tax 2 value can't be negative.";
      formIsValid = false;
      setTax2({ ...tax2, isChanged: false });
    }

    setSaveErrors(newSaveErrors);
    return formIsValid;
  };

  const checkErrorDiscount = () => {
    let formIsValid = true;
    let newSaveErrors = { ...saveErrors };

    if (discount && discount.percent && parseFloat(discount.value) > 100) {
      newSaveErrors["discountValue"] =
        "Discount value can't be greater than 100%.";
      formIsValid = false;
      setDiscount({ ...discount, isChanged: false });
    } else if (discount && discount.value < 0) {
      newSaveErrors["discountValue"] = "Discount value can't be negative";
      formIsValid = false;
      setDiscount({ ...discount, isChanged: false });
    } else if (
      discount &&
      !discount.percent &&
      parseFloat(discount.value) > totalCalc
    ) {
      newSaveErrors["discountFixed"] =
        "Discount can't be greater than total amount";
      formIsValid = false;
      setDiscount({ ...discount, isChanged: false });
    }

    setSaveErrors(newSaveErrors);
    return formIsValid;
  };

  const handleEditInvoice = () => {
    if (checkErrorInvoice()) {
      if (
        props.selectedOrganization &&
        props.selectedOrganization.id &&
        props.invoiceDetails &&
        props.invoiceDetails.id
      ) {
        const payload = {
          organization_id: props.selectedOrganization.id,
          invoice_id: props.invoiceDetails.id,
          client_id: selectClient.value,
          invoice_number:
            invoiceNumber === ""
              ? `${props.selectedOrganization.name.substring(
                  0,
                  props.selectedOrganization.name.indexOf(" ")
                )}_${Math.ceil(Math.random() * 10000)}`
              : invoiceNumber,
          issued_date: issuedDate.format("YYYY-MM-DD"),
          due_date: dueDate !== null ? dueDate.format("YYYY-MM-DD") : dueDate,
          po_number: poValue === "" ? null : poValue,
          currency: (currency && currency.value) || "USD",
        };

        props.updateInvoice(payload);
      }
    }
  };

  const handleInvoiceItem = (index) => {
    if (checkErrorInvoiceItem(index)) {
      if (
        props.selectedOrganization &&
        props.selectedOrganization.id &&
        props.invoiceDetails &&
        props.invoiceDetails.id
      ) {
        let payload = {
          organization_id: props.selectedOrganization.id,
          invoice_id: props.invoiceDetails.id,
          type: itemArray[index].type.value,
          description:
            itemArray[index].description === ""
              ? null
              : itemArray[index].description,
          quantity: itemArray[index].quantity,
          unit_price: itemArray[index].unitPrice,
          tax1: itemArray[index].tax1,
          tax2: itemArray[index].tax2,
          linked_project_id:
            itemArray[index].linkedProject === ""
              ? null
              : itemArray[index].linkedProject.value,
        };
        if (itemArray[index].id) {
          payload.item_id = itemArray[index].id;
          props.updateInvoiceItem(payload);
        } else {
          setCreatedItemindex(index);
          props.createInvoiceItem(payload);
        }
        setItemIndex(index);
      }
    }
  };

  const handleEditInvoiceTax1 = () => {
    if (checkErrorTax1()) {
      if (
        props.selectedOrganization &&
        props.selectedOrganization.id &&
        props.invoiceDetails &&
        props.invoiceDetails.id &&
        props.invoiceDetails.tax1.id
      ) {
        const payload = {
          organization_id: props.selectedOrganization.id,
          invoice_id: props.invoiceDetails.id,
          tax_item_id: props.invoiceDetails.tax1.id,
          tax_field: "tax1",
          type: tax1.percent ? `percent` : `fixed`,
          [tax1.percent ? `percent_amount` : `fixed_amount`]: tax1.value,
          name: tax1.name,
        };

        props.updateInvoiceTax(payload);
        setLoadingTaxId(props.invoiceDetails.tax1.id);
      }
    }
  };

  const handleEditInvoiceTax2 = () => {
    if (checkErrorTax2()) {
      if (
        props.selectedOrganization &&
        props.selectedOrganization.id &&
        props.invoiceDetails &&
        props.invoiceDetails.id &&
        props.invoiceDetails.tax2.id
      ) {
        const payload = {
          organization_id: props.selectedOrganization.id,
          invoice_id: props.invoiceDetails.id,
          tax_item_id: props.invoiceDetails.tax2.id,
          tax_field: "tax2",
          type: tax1.percent ? `percent` : `fixed`,
          [tax1.percent ? `percent_amount` : `fixed_amount`]: tax2.value,
          name: tax2.name,
        };
        props.updateInvoiceTax(payload);
        setLoadingTaxId(props.invoiceDetails.tax2.id);
      }
    }
  };

  const handleEditInvoiceDiscount = () => {
    if (checkErrorDiscount()) {
      if (
        props.selectedOrganization &&
        props.selectedOrganization.id &&
        props.invoiceDetails &&
        props.invoiceDetails.id &&
        props.invoiceDetails.discount
      ) {
        const payload = {
          organization_id: props.selectedOrganization.id,
          invoice_id: props.invoiceDetails.id,
          discount_id: props.invoiceDetails.discount.id,
          discount: {
            type: discount.percent ? `percent` : `fixed`,
            [discount.percent ? `percent_amount` : `fixed_amount`]:
              discount.value,
          },
        };

        props.updateInvoiceDiscount(payload);
      }
    }
  };

  return (
    <div className="content">
      <PageTitle>Invoices</PageTitle>
      <div
        style={{
          display: `grid`,
          gridTemplateColumns: `auto`,
          justifyContent: `start`,
          marginTop: "20px",
        }}
      >
        <BackButtonComponent
          onClick={() => props.history.push("/user/invoices")}
          subTitle="Back to all invoices"
        />
      </div>
      <CreateInvoiceSubTitle>
        Detail information of your Invoice
      </CreateInvoiceSubTitle>

      {props.selectedOrganization && props.selectedOrganization.id && (
        <AddClientPopup
          isOpen={popupOpen}
          toggle={popupToggle}
          selectedOrganization={props.selectedOrganization}
          createClient={props.createClient}
          isLoading={props.clientIsLoading}
        />
      )}

      {props.selectedOrganization &&
        props.selectedOrganization.id &&
        props.invoiceDetails &&
        props.invoiceDetails.id &&
        props.profileData && (
          <SendMailPopup
            isOpen={sendEmailOpen}
            isLoading={props.sendMailLoading}
            toggle={sendEmailToggle}
            organization_id={props.selectedOrganization.id}
            organization_name={props.selectedOrganization.name}
            invoice_id={props.invoiceDetails.id}
            senderName={props.profileData.first_name}
            invoiceNumber={invoiceNumber}
            issuedDate={issuedDate.format("YYYY-MM-DD")}
            dueDate={dueDate === null ? dueDate : dueDate.format("YYYY-MM-DD")}
            email={
              props.invoiceDetails.client && props.invoiceDetails.client.email
            }
            log={props.updateInvoiceSendLog}
            createInvoiceUpdateSendLog={props.createInvoiceUpdateSendLog}
            sendInvoiceEmail={props.sendInvoiceEmail}
            onSend={onSend}
          />
        )}
      <Container padding="30px 0">
        {props.isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            <CardTitle style={{ paddingLeft: "30px" }} fontSize="16px">
              {invoiceNumber}
            </CardTitle>
            <CommonText margin="10px 30px 15px">
              {props.selectedOrganization &&
                props.selectedOrganization.id &&
                props.selectedOrganization.name}
            </CommonText>
            <GeneralDetailsInputContainer>
              <InputWithLabelSection>
                <CommonGrid>
                  <InputLabel>
                    Client <RedStar>*</RedStar>
                  </InputLabel>
                  <AddItem
                    columns="18px auto"
                    gap="5px"
                    onClick={() => popupToggle()}
                  >
                    <img src={Plus} alt="plus" />
                    <ColoredText fontSize="12px" primary hover>
                      Add Client
                    </ColoredText>
                  </AddItem>
                </CommonGrid>
                <Select
                  isSearchable
                  value={selectClient}
                  options={clientOptions}
                  placeholder="Select client..."
                  onChange={(e) => {
                    onClientChange(e);
                  }}
                  styles={CreateProjectDropdown(null)}
                />
                {saveErrors && saveErrors["client"] && (
                  <FormAlert>{saveErrors["client"]}</FormAlert>
                )}
              </InputWithLabelSection>
              <InputWithLabelSection>
                <InfoTooltip
                  redStar
                  label
                  fontSize="12px"
                  top="-75px"
                  mainText="Invoice Number "
                  toolTipText="The invoice number is a unique number. You can customize this as you need."
                />
                <InputField
                  type="text"
                  placeholder="Type invoice number"
                  value={invoiceNumber}
                  onChange={(e) => onInvoiceNumberChange(e)}
                />
              </InputWithLabelSection>
              <InputWithLabelSection>
                <InputLabel>PO Number</InputLabel>
                <InputField
                  type="text"
                  placeholder="Type..."
                  value={poValue}
                  onChange={(e) => onPoValueChange(e)}
                />
              </InputWithLabelSection>
              <InputWithLabelSection>
                <InputLabel>
                  Issued Date <RedStar>*</RedStar>
                </InputLabel>
                <DatePicker
                  id="createIssuedDate"
                  date={issuedDate}
                  onDateChange={onIssuedDateChange}
                  dateDisplayFormat={"DD/MM/YYYY"}
                  placeholder={"Select issued date"}
                  allowPreviousDates
                />
              </InputWithLabelSection>
              <InputWithLabelSection>
                <InputLabel>Due Date</InputLabel>
                <DatePicker
                  id="createDueDate"
                  date={dueDate}
                  onDateChange={onDueDateChange}
                  dateDisplayFormat={"DD/MM/YYYY"}
                  placeholder={"Select due date"}
                  allowPreviousDates
                />
              </InputWithLabelSection>
              <InputWithLabelSection>
                <InputLabel>Currency</InputLabel>
                <Select
                  isSearchable
                  value={currency}
                  options={currenciesWithSymbols}
                  placeholder="Select Currency..."
                  onChange={(e) => {
                    onCurrencyChange(e);
                    if (!changedInvoiceInfo) {
                      setChangedInvoiceInfo(true);
                    }
                  }}
                  styles={CreateProjectDropdown({
                    error: saveErrors["currency"],
                  })}
                />
                {saveErrors && saveErrors["currency"] && (
                  <FormAlert>{saveErrors["currency"]}</FormAlert>
                )}
              </InputWithLabelSection>
              {changedInvoiceInfo && (
                <SaveSection>
                  <PrimaryButton
                    padding={props.updateInvoiceLoading ? "0 20px" : "0 40px"}
                    onClick={() => handleEditInvoice()}
                  >
                    {props.updateInvoiceLoading ? (
                      <ButtonTextLoader loadingText="Saving" fontSize="13px" />
                    ) : (
                      "Save"
                    )}
                  </PrimaryButton>
                </SaveSection>
              )}
            </GeneralDetailsInputContainer>

            <CreateInvoiceItemContainer heading>
              <ReorderSelectSection heading>
                <div></div>
                <TableText>No.</TableText>
              </ReorderSelectSection>
              <TableText>Item Type</TableText>
              <TableText>Description</TableText>
              <TableText>Quantity</TableText>
              <TableText>Unit Price</TableText>
              <TableText>Amount</TableText>
              <TableText>{tax1 && tax1.name ? tax1.name : `Tax 1`}</TableText>
              <TableText>{tax2 && tax2.name ? tax2.name : `Tax 2`}</TableText>
              <div></div>
            </CreateInvoiceItemContainer>
            {itemArray &&
              itemArray.length > 0 &&
              itemArray.map((item, index) => (
                <Fragment key={index}>
                  <CreateInvoiceItemContainer>
                    <ReorderSelectSection heading>
                      <div>
                        <img src={reorder} />
                      </div>
                      <CommonText> {index + 1} </CommonText>
                    </ReorderSelectSection>
                    <Select
                      isSearchable
                      value={item.type}
                      options={itemTypeOptions}
                      placeholder="Select type..."
                      onChange={(e) => {
                        onItemTypeChange(e, index);
                      }}
                      indicatorIconSize="16px"
                      styles={CreateProjectDropdown({ height: "34px" })}
                    />
                    {item.linkedProject == "" ? (
                      <Fragment>
                        <InputField
                          height="34px"
                          type="text"
                          placeholder="Type description"
                          value={item.description}
                          onChange={(e) => onItemDescChange(e, index)}
                        />
                        <InputField
                          height="34px"
                          type="number"
                          min="0"
                          value={item.quantity}
                          onChange={(e) => onItemQuantityChange(e, index)}
                          onWheel={(e) => e.target.blur()}
                          style={
                            saveErrors &&
                            saveErrors[`itemQuantity_${index}`] && {
                              border: `1px solid #fe5969`,
                              backgroundColor: `rgba(252, 87, 104, 0.05)`,
                            }
                          }
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <CommonText> {item.description} </CommonText>
                        <CommonText> {item.quantity} </CommonText>
                      </Fragment>
                    )}
                    <InputField
                      height="34px"
                      type="number"
                      min="0"
                      value={item.unitPrice}
                      onChange={(e) => onItemUnitPriceChange(e, index)}
                      onWheel={(e) => e.target.blur()}
                      style={
                        saveErrors &&
                        saveErrors[`itemUnitPrice_${index}`] && {
                          border: `1px solid #fe5969`,
                          backgroundColor: `rgba(252, 87, 104, 0.05)`,
                        }
                      }
                    />
                    <CommonText>
                      {`${
                        currency &&
                        currency.value &&
                        currencySymbolMap[currency.value]
                      } ${(item.quantity * item.unitPrice).toFixed(2)}`}
                    </CommonText>
                    <ToggleButton
                      onClick={() => onItemTax1Change(index)}
                      active={item.tax1}
                    >
                      <ToggleButtonRound active={item.tax1} />
                    </ToggleButton>
                    <ToggleButton
                      onClick={() => onItemTax2Change(index)}
                      active={item.tax2}
                    >
                      <ToggleButtonRound active={item.tax2} />
                    </ToggleButton>
                    <SaveCrossSection>
                      {item.isChanged &&
                        item.quantity >= 1 &&
                        !saveErrors[`itemDescription_${index}`] &&
                        !saveErrors[`itemQuantity_${index}`] &&
                        !saveErrors[`itemUnitPrice_${index}`] && (
                          <PrimaryButton
                            fontSize="12px"
                            height="28px"
                            padding={
                              (props.createItemLoading ||
                                props.updateItemLoading) &&
                              itemIndex !== null &&
                              itemIndex === index
                                ? "0 5px"
                                : "0 18px"
                            }
                            onClick={() => handleInvoiceItem(index)}
                          >
                            {(props.createItemLoading ||
                              props.updateItemLoading) &&
                            itemIndex !== null &&
                            itemIndex === index ? (
                              <ButtonTextLoader
                                loadingText="Saving"
                                fontSize="11px"
                                size={2}
                              />
                            ) : (
                              "Save"
                            )}
                          </PrimaryButton>
                        )}
                      {props.deleteItemLoading &&
                      itemIndex !== null &&
                      itemIndex === index ? (
                        <DotLoader loading size={5} />
                      ) : (
                        <Cross
                          onClick={() => handleDeleteItem(index)}
                          size="26px"
                        >
                          <CrossIcon src={crossIcon} alt="" size="18px" />
                        </Cross>
                      )}
                    </SaveCrossSection>
                  </CreateInvoiceItemContainer>
                  {saveErrors && saveErrors[`itemDescription_${index}`] && (
                    <CreateInvoiceItemContainer
                      style={{ padding: `0 30px 10px 30px` }}
                    >
                      <div style={{ gridColumn: `3 / span 4` }}>
                        <FormAlert>
                          {saveErrors[`itemDescription_${index}`]}
                        </FormAlert>
                      </div>
                    </CreateInvoiceItemContainer>
                  )}
                  {saveErrors && saveErrors[`itemQuantity_${index}`] && (
                    <CreateInvoiceItemContainer
                      style={{ padding: `0 30px 10px 30px` }}
                    >
                      <FormAlert>
                        {saveErrors[`itemQuantity_${index}`]}
                      </FormAlert>
                    </CreateInvoiceItemContainer>
                  )}
                  <CreateInvoiceItemContainer
                    style={{ padding: `0 30px 10px 20px` }}
                    marginTop="10px"
                  >
                    <CommonGrid
                      justifyContent="start"
                      alignItems="center"
                      columns="auto 200px auto"
                      alignItem="center"
                      style={{ gridColumn: `3 / span 4` }}
                    >
                      <CommonText fontSize="13px">Linked Project</CommonText>
                      {props.linkProjectLoading &&
                      linkedItemIndex !== null &&
                      linkedItemIndex === index ? (
                        <DotLoader loading />
                      ) : (
                        <ProjectDropdown
                          selectedOrganization={props.selectedOrganization}
                          selectedProject={item.linkedProject}
                          selectProject={(e) =>
                            onItemLinkedProjectChange(e, index)
                          }
                          indicatorIconSize="18px"
                          style={CreateProjectDropdown({ height: "36px" })}
                        />
                      )}
                      <BillingQues
                        onMouseEnter={() => setToolTipActive(index)}
                        onMouseLeave={() => setToolTipActive(-1)}
                      >
                        ?
                        {toolTipActive === index && (
                          <BillingTooltip top="-75px">
                            <BillingTooltipText>
                              The invoice can be billed for specific project.
                              You can see this details in the project analysis
                              page.
                            </BillingTooltipText>
                          </BillingTooltip>
                        )}
                      </BillingQues>
                    </CommonGrid>
                  </CreateInvoiceItemContainer>
                </Fragment>
              ))}
            <AddItemContainer>
              <AddItem onClick={() => addItemToarray()}>
                <img src={Plus} alt="plus" />
                <ColoredText fontSize="14px" primary hover>
                  Add More items
                </ColoredText>
              </AddItem>
              {saveErrors && saveErrors["noItem"] && (
                <FormAlert>{saveErrors["noItem"]}</FormAlert>
              )}
            </AddItemContainer>

            <CalculationContainer style={{ marginTop: `20px` }}>
              <div />
              <TableText>Total</TableText>
              <ColoredText primary semibold>
                {" "}
                {totalCalc}{" "}
              </ColoredText>
            </CalculationContainer>
            <CalculationContainer style={{ padding: `10px 10px 0` }}>
              <div />
              <InputLabel>Tax Name</InputLabel>
              <InputLabel style={{ gridColumn: `4 / span 3` }}>
                In percent or fixed amount?
              </InputLabel>
            </CalculationContainer>
            <CalculationContainer>
              <CommonText>Tax 1</CommonText>
              <InputField
                type="text"
                placeholder="Type name"
                value={tax1.name}
                onChange={(e) => onTax1NameChange(e)}
              />
              <InputField
                type="number"
                min="0"
                value={tax1.value}
                onChange={(e) => onTax1ValueChange(e)}
                onWheel={(e) => e.target.blur()}
                style={
                  saveErrors &&
                  saveErrors["tax1Value"] && {
                    border: `1px solid #fe5969`,
                    backgroundColor: `rgba(252, 87, 104, 0.05)`,
                  }
                }
              />
              <TaxTypeButton
                percent
                active={tax1.percent}
                onClick={() => toggleTax1CalcType(true)}
              >
                %
              </TaxTypeButton>
              {currency && currency.value && (
                <TaxTypeButton
                  fontSize="12px"
                  active={!tax1.percent}
                  onClick={() => toggleTax1CalcType(false)}
                >
                  {currencySymbolMap[currency.value]}
                </TaxTypeButton>
              )}
              {tax1.isChanged ? (
                <div style={{ marginRight: `auto` }}>
                  <PrimaryButton
                    height="32px"
                    fontSize="12px"
                    padding={
                      props.updateTaxLoading &&
                      loadingTaxId &&
                      props.invoiceDetails.tax1 &&
                      props.invoiceDetails.tax1.id &&
                      loadingTaxId === props.invoiceDetails.tax1.id
                        ? "0 8px"
                        : "0 25px"
                    }
                    onClick={() => handleEditInvoiceTax1()}
                  >
                    {props.updateTaxLoading &&
                    loadingTaxId &&
                    props.invoiceDetails.tax1 &&
                    props.invoiceDetails.tax1.id &&
                    loadingTaxId === props.invoiceDetails.tax1.id ? (
                      <ButtonTextLoader loadingText="Saving" fontSize="12px" />
                    ) : (
                      "Save"
                    )}
                  </PrimaryButton>
                </div>
              ) : (
                <div />
              )}
              <CommonText>Tax 2</CommonText>
              <InputField
                type="text"
                placeholder="Type name"
                value={tax2.name}
                onChange={(e) => onTax2NameChange(e)}
              />
              <InputField
                type="number"
                min="0"
                value={tax2.value}
                onChange={(e) => onTax2ValueChange(e)}
                onWheel={(e) => e.target.blur()}
                style={
                  saveErrors &&
                  saveErrors["tax2Value"] && {
                    border: `1px solid #fe5969`,
                    backgroundColor: `rgba(252, 87, 104, 0.05)`,
                  }
                }
              />
              <TaxTypeButton
                percent
                active={tax2.percent}
                onClick={() => toggleTax2CalcType(true)}
              >
                %
              </TaxTypeButton>
              {currency && currency.value && (
                <TaxTypeButton
                  fontSize="12px"
                  active={!tax2.percent}
                  onClick={() => toggleTax2CalcType(false)}
                >
                  {currencySymbolMap[currency.value]}
                </TaxTypeButton>
              )}
              {tax2.isChanged ? (
                <div style={{ marginRight: `auto` }}>
                  <PrimaryButton
                    height="32px"
                    fontSize="12px"
                    padding={
                      props.updateTaxLoading &&
                      loadingTaxId &&
                      props.invoiceDetails.tax2 &&
                      props.invoiceDetails.tax2.id &&
                      loadingTaxId === props.invoiceDetails.tax2.id
                        ? "0 8px"
                        : "0 25px"
                    }
                    onClick={() => handleEditInvoiceTax2()}
                  >
                    {props.updateTaxLoading &&
                    loadingTaxId &&
                    props.invoiceDetails.tax2 &&
                    props.invoiceDetails.tax2.id &&
                    loadingTaxId === props.invoiceDetails.tax2.id ? (
                      <ButtonTextLoader loadingText="Saving" fontSize="12px" />
                    ) : (
                      "Save"
                    )}
                  </PrimaryButton>
                </div>
              ) : (
                <div />
              )}
              <div />
              <CommonText>Discount</CommonText>
              <InputField
                type="number"
                min="0"
                value={discount.value}
                onChange={(e) => onDiscountValueChange(e)}
                onWheel={(e) => e.target.blur()}
                style={
                  saveErrors &&
                  saveErrors["discountValue"] && {
                    border: `1px solid #fe5969`,
                    backgroundColor: `rgba(252, 87, 104, 0.05)`,
                  }
                }
              />
              <TaxTypeButton
                percent
                active={discount.percent}
                onClick={() => toggleDiscountCalcType(true)}
              >
                %
              </TaxTypeButton>
              {currency && currency.value && (
                <TaxTypeButton
                  fontSize="12px"
                  active={!discount.percent}
                  onClick={() => toggleDiscountCalcType(false)}
                >
                  {currencySymbolMap[currency.value]}
                </TaxTypeButton>
              )}
              {saveErrors && saveErrors["discountFixed"] ? (
                <FormAlert>{saveErrors["discountFixed"]}</FormAlert>
              ) : discount.isChanged ? (
                <div style={{ marginRight: `auto` }}>
                  <PrimaryButton
                    height="32px"
                    fontSize="12px"
                    padding={props.updateDiscountLoading ? "0 8px" : "0 25px"}
                    onClick={() => handleEditInvoiceDiscount()}
                  >
                    {props.updateDiscountLoading ? (
                      <ButtonTextLoader loadingText="Saving" fontSize="12px" />
                    ) : (
                      "Save"
                    )}
                  </PrimaryButton>
                </div>
              ) : (
                <div />
              )}
            </CalculationContainer>
            <CalculationContainer grand>
              <div />
              <TableText>Grand Total</TableText>
              <ColoredText primary semibold>
                {currency.value} ({currencySymbolMap[currency.value]}){" "}
                {grandTotalValue}
              </ColoredText>
            </CalculationContainer>
            <CalculationContainer style={{ marginTop: `30px` }} padding="0">
              <SubmitButtonContainer>
                <WhiteButton
                  type="cancel"
                  onClick={() => props.history.push("/user/invoices")}
                >
                  Go Back
                </WhiteButton>
                {props.invoiceDetails && props.invoiceDetails.id && (
                  <ColoredButton
                    type="grayMedium"
                    onClick={() =>
                      props.history.push(
                        `/user/invoice-preview?id=${props.invoiceDetails.id}`
                      )
                    }
                  >
                    Keep As Draft
                  </ColoredButton>
                )}
                <PrimaryButton onClick={() => HandleSendEmail()}>
                  Send Invoice
                </PrimaryButton>
              </SubmitButtonContainer>
            </CalculationContainer>
          </>
        )}
      </Container>
    </div>
  );
};

export default EditInvoice;
